import jwtDecode from 'jwt-decode'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { handleLoginSuccess } from '../../helper'
import SplashScreen from '../@components/splashscreen'
import authorize from './helpers/authorize'
import { fetchToken } from './helpers/fetchToken'
import { getCodeFromLocation } from './helpers/getCodeFromLocation'
import { getTokenFromLocation } from './helpers/getTokenFromLocation'
import { getVerifierState } from './helpers/getVerifierState'

export default ({
  clientId,
  provider,
  scopes = [],
  tokenEndpoint = `${process.env.RAZZLE_APP_AUTH_DOMAIN_NAME}/oauth2/token`,
  busyIndicator = <div />,
}) => {
  const context = createContext({})
  const { Provider } = context

  class Authenticated extends React.Component {
    static contextType = context

    componentDidMount() {
      const { ensureAuthenticated } = this.context
      ensureAuthenticated()
    }

    render() {
      const { token } = this.context
      const { children } = this.props

      if (!token) {
        return busyIndicator
      }
      return children
    }
  }

  const useToken = () => {
    const { token } = useContext(context)
    if (!token) {
      console.warn(
        'Trying to useToken() while not being authenticated.\nMake sure to useToken() only inside of an <Authenticated /> component.'
      )
    }
    return token
  }

  return {
    AuthContext: ({ children, history }) => {
      const [token] = useState(null)
      const [, setCookie] = useCookies(['token', 'accessToken'])
      const [loading, setLoading] = useState(false)

      // if we have no token, but code and verifier are present,
      // then we try to swap code for token
      useEffect(() => {
        console.log('aaa AuthContext did mount beg ')
        const code = getCodeFromLocation({ location: window.location })
        const state = getVerifierState({ clientId })
        const objectToken = getTokenFromLocation({
          location: window.location,
        })
        if (code && state) {
          console.log('aaa code && state ', code && state)
          setLoading(true)
          fetchToken({
            clientId,
            tokenEndpoint,
            code,
            state,
            fetch,
          }).then((response) => {
            setCookie('token', response.id_token, {
              path: '/',
            })
            setCookie('accessToken', response.access_token, {
              path: '/',
            })
            setCookie('refreshToken', response.refresh_token, {
              path: '/',
            })
            const tokenData1 = jwtDecode(response.id_token)
            handleLoginSuccess(tokenData1, history)
            setLoading(false)
          })
        } else if (objectToken) {
          console.log('aaa objectToken ', objectToken)
          setCookie('token', objectToken.IdToken, {
            path: '/',
          })
          setCookie('accessToken', objectToken.AccessToken, {
            path: '/',
          })
          setCookie('refreshToken', objectToken.RefreshToken, {
            path: '/',
          })
          const tokenData1 = jwtDecode(objectToken.IdToken)
          handleLoginSuccess(tokenData1, history)
          setLoading(false)
        } else {
          console.log('aaa empty code, state and objectToken ')
        }
        console.log('aaa AuthContext did mount end ')
      }, [])

      const ensureAuthenticated = () => {
        console.log('aaa ensureAuthenticated beg')
        const code = getCodeFromLocation({ location: window.location })
        console.log('aaa code ', code)
        if (!token && !code) {
          console.log('aaa !token && !code ', !token && !code)
          authorize({ provider, clientId, scopes })
        }
        console.log('aaa ensureAuthenticated end')
      }

      return (
        <Provider value={{ token, ensureAuthenticated }}>
          {loading ? <SplashScreen /> : children}
        </Provider>
      )
    },
    Authenticated,
    useToken,
  }
}
;((_data) => {})([React])
