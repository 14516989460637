export const AmericaStates = [
  { long_name: 'Alabama', short_name: 'AL' },
  { long_name: 'Alaska', short_name: 'AK' },
  { long_name: 'Arizona', short_name: 'AZ' },
  { long_name: 'Arkansas', short_name: 'AR' },
  { long_name: 'California', short_name: 'CA' },
  { long_name: 'Colorado', short_name: 'CO' },
  { long_name: 'Connecticut', short_name: 'CT' },
  { long_name: 'Delaware', short_name: 'DE' },
  { long_name: 'District of Columbia', short_name: 'DC' },
  { long_name: 'Florida', short_name: 'FL' },
  { long_name: 'Georgia', short_name: 'GA' },
  { long_name: 'Hawaii', short_name: 'HI' },
  { long_name: 'Iowa', short_name: 'IA' },
  { long_name: 'Idaho', short_name: 'ID' },
  { long_name: 'Illinois', short_name: 'IL' },
  { long_name: 'Indiana', short_name: 'IN' },
  { long_name: 'Kansas', short_name: 'KS' },
  { long_name: 'Kentucky', short_name: 'KY' },
  { long_name: 'Louisiana', short_name: 'LA' },
  { long_name: 'Massachusetts', short_name: 'MA' },
  { long_name: 'Maryland', short_name: 'MD' },
  { long_name: 'Maine', short_name: 'ME' },
  { long_name: 'Michigan', short_name: 'MI' },
  { long_name: 'Minnesota', short_name: 'MN' },
  { long_name: 'Missouri', short_name: 'MO' },
  { long_name: 'Mississippi', short_name: 'MS' },
  { long_name: 'Montana', short_name: 'MT' },
  { long_name: 'North Carolina', short_name: 'NC' },
  { long_name: 'North Dakota', short_name: 'ND' },
  { long_name: 'Nebraska', short_name: 'NE' },
  { long_name: 'New Hampshire', short_name: 'NH' },
  { long_name: 'New Jersey', short_name: 'NJ' },
  { long_name: 'New Mexico', short_name: 'NM' },
  { long_name: 'Nevada', short_name: 'NV' },
  { long_name: 'New York', short_name: 'NY' },
  { long_name: 'Ohio', short_name: 'OH' },
  { long_name: 'Oklahoma', short_name: 'OK' },
  { long_name: 'Oregon', short_name: 'OR' },
  { long_name: 'Pennsylvania', short_name: 'PA' },
  { long_name: 'Rhode Island', short_name: 'RI' },
  { long_name: 'South Carolina', short_name: 'SC' },
  { long_name: 'South Dakota', short_name: 'SD' },
  { long_name: 'Tennessee', short_name: 'TN' },
  { long_name: 'Texas', short_name: 'TX' },
  { long_name: 'Utah', short_name: 'UT' },
  { long_name: 'Virginia', short_name: 'VA' },
  { long_name: 'Vermont', short_name: 'VT' },
  { long_name: 'Washington', short_name: 'WA' },
  { long_name: 'Wisconsin', short_name: 'WI' },
  { long_name: 'West Virginia', short_name: 'WV' },
  { long_name: 'Wyoming', short_name: 'WY' },
]

export const CanadaStates = [
  { long_name: 'Alberta', short_name: 'AB' },
  { long_name: 'British Columbia', short_name: 'BC' },
  { long_name: 'Manitoba', short_name: 'MB' },
  { long_name: 'New Brunswick', short_name: 'NB' },
  { long_name: 'Newfoundland and Labrador', short_name: 'NL' },
  { long_name: 'Northwest Territories', short_name: 'NT' },
  { long_name: 'Nova Scotia', short_name: 'NS' },
  { long_name: 'Nunavut', short_name: 'NU' },
  { long_name: 'Ontario', short_name: 'ON' },
  { long_name: 'Prince Edward Island', short_name: 'PE' },
  { long_name: 'Québec', short_name: 'QC' },
  { long_name: 'Saskatchewan', short_name: 'SK' },
  { long_name: 'Yukon', short_name: 'YT' },
]

export const AmericaAndCanadaStates = [...AmericaStates, ...CanadaStates]

export const AmericaZipCodeRegx = /^[A-Z]\d[A-Z]\s\d[A-Z]\d$/
export const CanadaZipCodeRegx = /^\d{5}$/

export const REGX_EMAIL_CHIP =
  /^[a-zA-Z0-9]+[a-zA-Z0-9.\-_+]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/

export const regex = {
  emailRegex:
    /^(?!(.*\@){2,})(?!.*(\.\.))(?!\s)[^\.][\S]{1,}[^\.]@[\w\-]{2,}\.[a-zA-Z0-9]{1,}[\.a-zA-Z0-9]{0,}[a-zA-Z]$/,
}

export const checkValidEmail = (value) => {
  return REGX_EMAIL_CHIP.test(String(value).trim().toLowerCase())
}
