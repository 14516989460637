import DataContext from '@context'
import React from 'react'

const withContext = (Component) => {
  return (props) => {
    return (
      <DataContext.Consumer>
        {(context) => <Component {...props} context={context} />}
      </DataContext.Consumer>
    )
  }
}

export default withContext
;((_data) => {})([React])
