import { Button } from '@appComponent/button'
import { ItemSearch } from '@appComponent/input'
import { Small } from '@appComponent/typography'
import { companyGroupsAtom } from '@atoms/atoms'
import { UikFormInputGroup, UikInput } from '@components'
import apiRoute from '@constants/apiRoute'
import {
  checkMessageAuth,
  customAxios,
  formatRawData,
  getStr,
  specialCharacter,
} from '@helper'
import { Drawer } from 'antd'
import classnames from 'classnames'
import { useAtom } from 'jotai'
import _, { size, toLower } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { withContext } from '../../../../../../hoc'
import cls from './edit-driver.scss'
import { getActiveGroups, getActiveVendors } from '@@/service'
import { InputSearch } from '@@/App/@components/input'
import PhoneInput from 'react-phone-input-2'
import { regex } from '../../../../../../utils'

// @flow

function EditDriver({
  setListRecentlyEdittedIdDoc,
  refreshFn,
  onClose,
  first_name,
  last_name,
  id_vendor,
  vendor_name,
  emailDriver,
  idDriver,
  context,
  showEdit,
  setShowEdit,
  country_code,
}) {
  const initialErrors = {
    firstName: '',
    lastName: '',
    errorVendor: '',
    phone: '',
    email: '',
  }

  const initFirstName = `${formatRawData(first_name)}`
  const initLastName = `${formatRawData(last_name)}`
  const initCountryCode = `${country_code || '1'}`
  const initEmail = `${formatRawData(emailDriver)}`
  const initIdVendor = `${formatRawData(id_vendor)}`
  const initVendorName = `${formatRawData(vendor_name)}`
  const initVendorChecked = initIdVendor ? 'vendor' : 'employee'

  const [cookies, , removeCookie] = useCookies(['token'])
  const { token } = cookies
  const [error, setError] = useState(initialErrors)
  const [firstName, setFirstName] = useState(initFirstName)
  const [, setFullName] = useState('')
  const [lastName, setLastName] = useState(initLastName)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState(initEmail)
  const [isDispatching, setDispatch] = useState(false)
  // vendor list
  const [vendorChecked, setVendorChecked] = useState(initVendorChecked)
  const [vendor, setVendor] = useState(initVendorName)
  const [choseIdVendor, setChoseIdVendor] = useState(initIdVendor)
  const [errorVendor, setErrorVendor] = useState('')
  const [close, setClose] = useState(false)
  const [activeGroups, setActiveGroups] = useState([])
  const [activeVendors, setActiveVendors] = useState([])
  const [companyGroups, setCompanyGroups] = useAtom(companyGroupsAtom)
  const [idGroup, setIdGroup] = useState(null)
  const [textsearchgroup, setTextsearchgroup] = useState('')
  const [loadinggroup, setLoadinggroup] = useState(false)
  const [loadingvendor, setLoadingvendor] = useState(false)
  const [errorGetActiveGroups, setErrorGetActiveGroups] = useState(null)
  const [errorGetActiveVendors, setErrorGetActiveVendors] = useState(null)

  const [countUnfinishedLoads, setCountUnfinishedLoads] = useState(null)
  const [status, setStatus] = useState('')
  const [countryCode, setCountryCode] = useState(initCountryCode)
  const [country, setCountry] = useState('')
  const [countryDefault, setCountryDefault] = useState('us')

  const nameRef = useRef(null)

  const setEmailLower = useCallback((value) => {
    setEmail(toLower(value))
  }, [])

  useEffect(() => {
    if (showEdit === true) {
      setTimeout(() => {
        const el = document.querySelectorAll('input#firstName')[0]
        if (el) {
          el.focus()
        }
      }, 200)

      // get driver detail
      const getDriverDetail = async () => {
        const handleData = (group) => {
          setIdGroup(group.idGroup)
          setCountUnfinishedLoads(group.countUnfinishedLoads)
          setTextsearchgroup(group.name)
        }
        try {
          const resp = await customAxios(token).get(
            `${apiRoute.drivers.GET_DRIVER_BY_ID}/${idDriver}`
          )
          setFirstName(resp.data.data[0].first_name)
          setLastName(resp.data.data[0].last_name)

          setStatus(getStr(resp, 'data.data[0].status'))
          setFullName(getStr(resp, 'data.data[0].full_name'))
          handleData(_.get(resp, 'data.data[0].groups[0]') || {})
          setCountryDefault(resp.data.data[0].iso_code.toLowerCase() || 'us')
          setPhoneNumber(resp.data.data[0].phone_number)
          setCountry(resp.data.data[0].iso_code)
          // handleData(sampleDriverDetail[0].groups[0])
        } catch (err) {
          //
        }
      }

      getDriverDetail()
      // get driver detail end

      getActiveGroups(token, setCompanyGroups)
    }
  }, [showEdit])

  const handleChange = (e) => {
    e.persist()
    const eventValue = e.target.value
    switch (e.target.name) {
      case 'firstName': {
        if (error.firstName !== '') {
          setFirstName(eventValue.replace(specialCharacter(), ''))
          setError({ ...error, firstName: '' })
        } else {
          setFirstName(eventValue.replace(specialCharacter(), ''))
        }
        break
      }
      case 'lastName': {
        if (error.lastName !== '') {
          setLastName(eventValue.replace(specialCharacter(), ''))
          setError({ ...error, lastName: '' })
        } else {
          setLastName(eventValue.replace(specialCharacter(), ''))
        }
        break
      }
      case 'phone': {
        const regExPhoneNumber = /[0-9]{10}/g
        const regExRandom10 = /[^0-9()\s-]{1,}/g
        // prevent user from copying a group of string doesn't match the pattern of phonenumber only input
        if (regExRandom10.test(eventValue)) {
          setPhoneNumber('')
          break
        }
        // format 10 digits to the expected show format if user copy phonenumber from other place
        if (regExPhoneNumber.test(eventValue)) {
          const valuePhoneNumber = `(${eventValue.slice(
            0,
            3
          )}) ${eventValue.slice(3, 6)} - ${eventValue.slice(6, 10)}`
          setPhoneNumber(valuePhoneNumber)
          break
        }
        // normal case
        if (error.phone !== '') {
          setPhoneNumber(eventValue)
          setError({ ...error, phone: '' })
        } else {
          setPhoneNumber(eventValue)
        }
        break
      }
      case 'email': {
        if (error.email !== '') {
          setEmailLower(eventValue)
          setError({ ...error, email: '' })
        } else {
          setEmailLower(eventValue)
        }
        break
      }

      default:
        break
    }
  }
  const handleBlur = (e) => {
    switch (e.target.name) {
      case 'firstName': {
        setFirstName(firstName.trim())
        break
      }
      case 'lastName': {
        setLastName(lastName.trim())
        break
      }
      case 'email': {
        setEmailLower(email.trim())
        break
      }
      default:
        break
    }
  }
  const handleClose = () => {
    setClose(true)
    onClose()
  }
  const handleValidate = () => {
    let isValid = true
    const newError = { ...error }
    const emailTestRegex = regex.emailRegex.test(email)
    if (firstName === '') {
      newError.firstName = "First name can't be blank"
      isValid = false
    }
    if (lastName === '') {
      newError.lastName = "Last Name can't be blank"
      isValid = false
    }
    if (phoneNumber === '') {
      newError.phone = "Phone number can't be blank"
      isValid = false
    }
    if (phoneNumber.length > 1 && phoneNumber.length < 10) {
      newError.phone = 'Please enter a valid phone number'
      isValid = false
    } else if (phoneNumber.charAt(0) === '0') {
      newError.phone = 'Phone number does not exist'
      isValid = false
    }
    // if (email === '') {
    //   newError.email = "Email address can't be blank"
    //   isValid = false
    // }
    if (vendorChecked === 'vendor' && (errorVendor || choseIdVendor === '')) {
      newError.errorVendor = "Vendor can't be blank"
      setErrorVendor('error')
      isValid = false
    }
    if (!emailTestRegex && email !== '') {
      newError.email = 'Please enter a valid email address'
      isValid = false
    }
    setError(newError)
    return isValid
  }

  const handleImplementSave = async () => {
    const bodyRequest = {
      firstName,
      lastName,
      idVendor: vendorChecked === 'vendor' ? `${choseIdVendor}` : '',
      phoneNumber: phoneNumber.replace(/[()\s-]/gm, ''),
      email,
      isoCode: country.toUpperCase(),
      countryCode,
      idGroup: idGroup ? Number(idGroup) : '',
    }
    try {
      setDispatch(true)
      const { data: responseData } = await customAxios(token).put(
        `${apiRoute.drivers.EDIT_DRIVER}/${idDriver}`,
        bodyRequest
      )
      if (responseData.success) {
        setListRecentlyEdittedIdDoc([idDriver])
        setDispatch(false)
        context.message.success('Saved Successfully')
        refreshFn()
        setShowEdit(false)
      }
    } catch (err) {
      setDispatch(false)
      const errorMessage = err.response.data.messages
      if (checkMessageAuth(errorMessage)) {
        context.message.error('Please Login Again!')
        removeCookie('token', { path: '/' })
      } else {
        context.message.error(errorMessage)
      }
    }
  }

  const handleSave = () => {
    const isValid = handleValidate()
    if (isValid && !isDispatching) {
      handleImplementSave()
    }
  }

  const disableVerification = useMemo(() => {
    if (
      firstName === '' ||
      lastName === '' ||
      phoneNumber === '' ||
      (vendorChecked === 'vendor' && choseIdVendor === '') ||
      vendorChecked === null ||
      (vendorChecked === 'vendor' && vendor === '')
    ) {
      return true
    }
    return false
  }, [firstName, lastName, phoneNumber, vendorChecked, choseIdVendor, vendor])
  // vendor change

  const handleCheckRadio = (e) => {
    const { name } = e.target
    setVendorChecked(name)
  }

  const renderSelectGroup = () => {
    if (status === '2') {
      return null
    }
    if (size(companyGroups) <= 0) {
      return null
    }
    return (
      <div className={classnames(cls.form_group, 'driver_group_select')}>
        <label htmlFor="inputupload_document_file">
          <Small>Group (Optional)</Small>
        </label>
        <InputSearch
          error={errorGetActiveGroups}
          disabled={countUnfinishedLoads >= 1}
          getListFn={getActiveGroups.bind(
            null,
            token,
            setActiveGroups,
            setLoadinggroup,
            setErrorGetActiveGroups
          )}
          idData={idGroup}
          idField="id_group"
          list={activeGroups}
          loadingData={loadinggroup}
          nameField="name"
          noDataText="No group"
          setIdData={setIdGroup}
          setValue={setTextsearchgroup}
          showPopup={showEdit}
          value={textsearchgroup}
        >
          {({
            item,
            o,
            index,
            setIdData,
            setShow,
            setValue,
            idField,
            nameField,
          }) => (
            <ItemSearch
              idField={idField}
              index={index}
              item={item}
              nameField={nameField}
              o={o}
              setIdData={setIdData}
              setShow={setShow}
              setValue={setValue}
            />
          )}
        </InputSearch>
      </div>
    )
  }

  const renderSelectVendor = () => (
    <div className={classnames(cls.form_group, 'driver_group_select')}>
      <label htmlFor="inputupload_document_file">
        <Small>Vendor Name</Small>
      </label>
      <InputSearch
        error={errorGetActiveVendors}
        getListFn={getActiveVendors.bind(
          null,
          token,
          setActiveVendors,
          setLoadingvendor,
          setErrorGetActiveVendors
        )}
        idData={choseIdVendor}
        idField="idVendor"
        list={activeVendors}
        loadingData={loadingvendor}
        nameField="name"
        noDataText="No vendor"
        setIdData={setChoseIdVendor}
        setValue={setVendor}
        showPopup={showEdit}
        value={vendor}
      >
        {({
          item,
          o,
          index,
          setIdData,
          setShow,
          setValue,
          idField,
          nameField,
        }) => (
          <ItemSearch
            idField={idField}
            index={index}
            item={item}
            nameField={nameField}
            o={o}
            setIdData={setIdData}
            setShow={setShow}
            setValue={setValue}
          />
        )}
      </InputSearch>
    </div>
  )
  return (
    <Drawer
      className="wr_drawer_add2"
      destroyOnClose
      footer={
        <div className="wr_footer_add_load ">
          <Button onClick={handleClose} stateClass="Secondary" width={74}>
            Cancel
          </Button>

          <Button
            onClick={handleSave}
            stateClass={disableVerification ? 'Disabled' : 'Primary'}
            width={58}
          >
            Save
          </Button>
        </div>
      }
      maskClosable={false}
      onClose={handleClose}
      title="Edit Driver"
      visible={showEdit}
      width={368}
    >
      <div
        className={classnames([cls.content], { [cls.close]: close === true })}
      >
        <form className={cls.form_edit_driver}>
          <UikFormInputGroup style={{ margin: '0 0 8px 0' }}>
            <label className={cls.label} htmlFor="input">
              First Name
            </label>
            <UikInput
              autoComplete="off"
              className={classnames(cls.input_form, {
                [cls.error]: error.firstName !== '',
              })}
              id="firstName"
              maxLength="20"
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              ref={nameRef}
              // errorMessage={error.firstName}
              type="text"
              value={firstName}
            />
            {error.firstName && (
              <p className={cls.errorMsg}>{error.firstName}</p>
            )}
          </UikFormInputGroup>
          <UikFormInputGroup style={{ margin: '0 0 8px 0' }}>
            <label className={cls.label} htmlFor="input">
              Last Name
            </label>
            <UikInput
              autoComplete="off"
              className={classnames(cls.input_form, {
                [cls.error]: error.lastName !== '',
              })}
              id="lastName"
              maxLength="20"
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              // errorMessage={error.lastName}
              type="text"
              value={lastName}
            />
            {error.lastName && <p className={cls.errorMsg}>{error.lastName}</p>}
          </UikFormInputGroup>
          <UikFormInputGroup className={cls.radioContainer}>
            <span className={cls.spanRadioEmployee}>
              <input
                checked={vendorChecked === 'employee'}
                className={cls.employee}
                id="radioEmployee"
                name="employee"
                onChange={handleCheckRadio}
                type="radio"
              />
              <label className={cls.employeeLabel} htmlFor="radioEmployee">
                Employee/Owner Operator
              </label>
            </span>

            <span className={cls.spanRadioVendor}>
              <input
                checked={vendorChecked === 'vendor'}
                className={cls.vendor}
                id="radioVendor"
                name="vendor"
                onChange={handleCheckRadio}
                type="radio"
              />
              <label className={cls.vendorLabel} htmlFor="radioVendor">
                Vendor
              </label>
            </span>
          </UikFormInputGroup>
          {vendorChecked === 'vendor' && (
            <React.Fragment>{renderSelectVendor()}</React.Fragment>
          )}
          <UikFormInputGroup style={{ margin: '0 0 8px 0' }}>
            <label className={cls.label} htmlFor="inputphone_editdriver">
              Mobile Phone Number
            </label>

            <PhoneInput
              className="w-[320px]"
              buttonClass="buttonClass"
              containerClass="containerClass"
              country={countryDefault || 'us'}
              countryCodeEditable={false}
              disableCountryGuess
              dropdownClass="dropdownClass"
              inputClass={classnames(
                'inputClass text-[14px] leading-[150%] rounded-[4px] h-[38px] focus:border-borderInput',
                {
                  [cls.errorDetection]: error.phone !== '',
                }
              )}
              masks={{
                us: '...-...-....',
                mx: '..-....-....',
                ca: '...-...-....',
              }}
              onChange={(value, data) => {
                setCountry(data.countryCode)
                setCountryCode(data.dialCode)
                setPhoneNumber(value.slice(data.dialCode.length))
              }}
              onlyCountries={['us', 'mx', 'ca']}
              value={`${countryCode}${phoneNumber}`}
            />

            {error.phone && <p className={cls.errorMsg}>{error.phone}</p>}
          </UikFormInputGroup>
          <UikFormInputGroup style={{ margin: '0 0 8px 0' }}>
            <label className={cls.label} htmlFor="input">
              E-mail Address (Optional)
            </label>
            <UikInput
              autoComplete="off"
              className={classnames(cls.input_form, {
                [cls.error]: error.email !== '',
              })}
              id="email"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              // errorMessage={error.email}
              type="text"
              value={email}
            />
            {error.email && <p className={cls.errorMsg}>{error.email}</p>}
          </UikFormInputGroup>
          {renderSelectGroup()}
        </form>
      </div>
    </Drawer>
  )
}

export default withContext(EditDriver)
;((_data) => {})([React])
