import { ArrowDropdown } from '@appComponent/icon'
import LoadingSkeleton from '@appComponent/loadingSkeleton'
import { formatNumberWithoutPrefix } from '@helper'
import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import cls from './common.scss'

function formatReturnValue(input, threshold) {
  if (!input && input !== 0) {
    return ''
  }
  if (input.length <= threshold + 1) {
    return input
  }
  if (input.length > threshold) {
    return input.slice(0, threshold).concat('...')
  }
  return input
}

const InputSearch = ({
  id,
  label,
  trip_index,
  truck_value,
  onHandleChangeTruckNumber,
  onHandleClickTruck,
  driver_value,
  onHandleChangeDriverName,
  onHandleClickDriver,
  listTruckActive,
  listDriverActive,
  loadingTruck,
  loadingDriver,
  onHandleErrorTruck,
  onHandleErrorDriver,
  onHandleClickIconDriver,
  onHandleClickIconTruck,
}) => {
  let input_value = ''

  const truckNumberRef = useRef(null)
  const truckElementRef = useRef([])
  const listTruckNumberRef = useRef(null)

  const driverNameRef = useRef(null)
  const driverElementRef = useRef([])
  const listDriverNameRef = useRef(null)

  const [showListTruck, setShowListTruck] = useState(false)
  const [isErrorTruck, setErrorTruck] = useState(false)
  const [showListDriver, setShowlistDriver] = useState(false)
  const [isErrorDriver, setErrorDriver] = useState(false)

  const [isChosenDriver, setChosenDriver] = useState(false)
  const [isChosenTruck, setChosenTruck] = useState(false)

  const [truckCursor, setTruckCursor] = useState(0)
  const [driverCursor, setDriverCursor] = useState(0)
  const [isClickedIconDriver, setIsClickedIconDriver] = useState(false)
  const [isClickedIconTruck, setIsClickedIconTruck] = useState(false)

  const [arrowDirection, setArrowDirection] = useState(null)

  useEffect(() => {
    if (isClickedIconDriver) {
      onHandleClickIconDriver()
      setShowlistDriver(true)
    }
    // if (error) {
    //   onHandleErrorDriver('')
    // }
  }, [isClickedIconDriver])
  useEffect(() => {
    if (isClickedIconTruck) {
      onHandleClickIconTruck()
      setShowListTruck(true)
    }
    // if (error) {
    //   onHandleErrorTruck('')
    // }
  }, [isClickedIconTruck])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  useEffect(() => {
    if (
      listTruckNumberRef &&
      listTruckNumberRef.current &&
      truckElementRef &&
      truckElementRef.current &&
      showListTruck &&
      arrowDirection === 'down' &&
      truckCursor % 7 === 0
    ) {
      listTruckNumberRef.current.scrollTo(
        0,
        truckElementRef.current[truckCursor].offsetTop
      )
    } else if (
      listTruckNumberRef &&
      listTruckNumberRef.current &&
      truckElementRef &&
      truckElementRef.current &&
      showListTruck &&
      arrowDirection === 'up' &&
      (truckCursor + 1) % 7 === 0
    ) {
      listTruckNumberRef.current.scrollTo(
        0,
        truckElementRef.current[truckCursor].offsetTop - 266
      )
    }
    if (
      listDriverNameRef &&
      listDriverNameRef.current &&
      driverElementRef &&
      driverElementRef.current &&
      showListDriver &&
      arrowDirection === 'down' &&
      driverCursor % 5 === 0
    ) {
      listDriverNameRef.current.scrollTo(
        0,
        driverElementRef.current[driverCursor].offsetTop
      )
    } else if (
      listDriverNameRef &&
      listDriverNameRef.current &&
      driverElementRef &&
      driverElementRef.current &&
      showListDriver &&
      arrowDirection === 'up' &&
      (driverCursor + 1) % 5 === 0
    ) {
      listDriverNameRef.current.scrollTo(
        0,
        driverElementRef.current[driverCursor].offsetTop - 200
      )
    }
  })

  const handleClickOutside = (event) => {
    if (
      truckNumberRef.current &&
      listTruckNumberRef.current &&
      !truckNumberRef.current.contains(event.target) &&
      !listTruckNumberRef.current.contains(event.target) &&
      showListTruck
    ) {
      setShowListTruck(false)
      if (truck_value.number) {
        setErrorTruck(true)
        onHandleErrorTruck(trip_index, 'error')
      }
      return
    }
    if (
      driverNameRef.current &&
      listDriverNameRef.current &&
      !driverNameRef.current.contains(event.target) &&
      !listDriverNameRef.current.contains(event.target) &&
      showListDriver
    ) {
      setShowlistDriver(false)
      if (driver_value.full_name) {
        setErrorDriver(true)
        onHandleErrorDriver(trip_index, 'error')
      }
    }
  }
  switch (id) {
    case `truck_number_${trip_index}`:
      input_value = truck_value.number
      break

    case `driver_name_${trip_index}`:
      input_value = driver_value.full_name
      break

    default:
      break
  }
  const handleChange = (e) => {
    switch (id) {
      case `truck_number_${trip_index}`:
        if (e.target.value === '') {
          setErrorTruck(false)
          onHandleErrorTruck(trip_index, '')
        }
        setChosenTruck(false)
        setShowListTruck(true)
        onHandleChangeTruckNumber(e, trip_index)
        break

      case `driver_name_${trip_index}`:
        if (e.target.value === '') {
          setErrorDriver(false)
          onHandleErrorDriver(trip_index, '')
        }
        setChosenDriver(false)
        setShowlistDriver(true)
        onHandleChangeDriverName(e, trip_index)
        break

      default:
        break
    }
  }

  // truck
  const handleFocusTruck = (e) => {
    if (e.target.value !== '' && !truck_value.isChosen) {
      setShowListTruck(true)
    }
  }
  const handleBlurTruckInput = (e) => {
    if (e.target.value === '') {
      setShowListTruck(false)
    } else {
      if (!isChosenTruck && !truck_value.isChosen) {
        setErrorTruck(true)
        onHandleErrorTruck(trip_index, 'error')
      } else {
        setErrorTruck(false)
        onHandleErrorTruck(trip_index, '')
      }
      setShowListTruck(false)
    }
  }
  const handleKeyDownTruckNumber = (e) => {
    const selectionLength = listTruckActive.length
    const truckValue = listTruckActive[truckCursor]
    function chooseTruck() {
      setErrorTruck(false)
      setShowListTruck(false)
      onHandleErrorTruck(trip_index, '')
      onHandleClickTruck({ ...truckValue, isChosen: true }, trip_index)
      setChosenTruck(true)
    }
    if (e.keyCode === 40 && truckCursor < selectionLength - 1) {
      e.preventDefault()
      if (arrowDirection !== 'down') {
        setArrowDirection('down')
      }
      setTruckCursor(truckCursor + 1)
    }
    if (e.keyCode === 38 && truckCursor > 0) {
      e.preventDefault()
      if (arrowDirection !== 'up') {
        setArrowDirection('up')
      }
      setTruckCursor(truckCursor - 1)
    }
    if (e.keyCode === 13 && showListTruck) {
      if (e.target.value === '' || !truckValue) {
        return
      }
      chooseTruck()
    }
    if (e.keyCode === 9 && showListTruck && selectionLength > 0) {
      chooseTruck()
    }
  }
  const handleMouseOverTruck = (index) => () => {
    setTruckCursor(index)
  }

  // driver
  const handleFocusDriver = (e) => {
    if (e.target.value !== '' && !driver_value.isChosen) {
      setShowlistDriver(true)
    }
  }
  const handleBlurDriverInput = (e) => {
    if (e.target.value === '') {
      setShowlistDriver(false)
    } else {
      if (!isChosenDriver && !driver_value.isChosen) {
        setErrorDriver(true)
        onHandleErrorDriver(trip_index, 'error')
      } else {
        setErrorDriver(false)
        onHandleErrorDriver(trip_index, '')
      }
      setShowlistDriver(false)
    }
  }
  const handleKeyDownDriverName = (e) => {
    const selectionLength = listDriverActive.length
    const driver = listDriverActive[driverCursor]
    function chooseDriver() {
      setErrorDriver(false)
      setShowlistDriver(false)
      onHandleErrorDriver(trip_index, '')
      onHandleClickDriver({ ...driver, isChosen: true }, trip_index)
      setChosenDriver(true)
    }
    if (e.keyCode === 40 && driverCursor < selectionLength - 1) {
      e.preventDefault()
      if (arrowDirection !== 'down') {
        setArrowDirection('down')
      }
      setDriverCursor(driverCursor + 1)
    }
    if (e.keyCode === 38 && driverCursor > 0) {
      e.preventDefault()
      if (arrowDirection !== 'up') {
        setArrowDirection('up')
      }
      setDriverCursor(driverCursor - 1)
    }
    if (e.keyCode === 13 && showListDriver) {
      if (e.target.value === '' || !driver) {
        return
      }
      chooseDriver()
    }
    if (e.keyCode === 9 && showListDriver && selectionLength > 0) {
      chooseDriver()
    }
  }
  const handleMouseOverDriver = (index) => () => {
    setDriverCursor(index)
  }

  const handleClickTruck = (truckValue) => () => {
    setChosenTruck(true)
    setShowListTruck(false)
    setIsClickedIconTruck(false)
    setErrorTruck(false)
    onHandleErrorTruck(trip_index, '')
    onHandleClickTruck({ ...truckValue, isChosen: true }, trip_index)
  }
  const handleClickDriver = (driver) => () => {
    setChosenDriver(true)
    setShowlistDriver(false)
    setIsClickedIconDriver(false)
    setErrorDriver(false)
    onHandleErrorDriver(trip_index, '')
    onHandleClickDriver({ ...driver, isChosen: true }, trip_index)
  }

  const handleClickIconDropdownDriver = () => {
    setIsClickedIconDriver(!isClickedIconDriver)
    if (isClickedIconDriver) {
      // onHandleErrorDriver(trip_index, '')
      setErrorDriver(false)
    }
  }
  const handleClickIconDropdownTruck = () => {
    setIsClickedIconTruck(!isClickedIconTruck)
    if (isClickedIconTruck) {
      // onHandleErrorTruck(trip_index, '')
      setErrorTruck(false)
    }
  }

  if (id === `truck_number_${trip_index}`) {
    return (
      <div className={cls.truck_number}>
        <label className={cls.label}>{label}</label>
        <input
          autoComplete="off"
          className={classnames({
            [cls.error_border]: isErrorTruck,
          })}
          id={id}
          maxLength="15"
          name={id}
          onBlur={handleBlurTruckInput}
          onChange={handleChange}
          onFocus={handleFocusTruck}
          onKeyDown={handleKeyDownTruckNumber}
          ref={truckNumberRef}
          type="text"
          value={input_value}
        />
        <span
          className={cls.dropdown_icon}
          onClick={handleClickIconDropdownTruck}
        >
          <ArrowDropdown />
        </span>
        {showListTruck && loadingTruck && (
          <div className={cls.loading_wrapper}>
            <span className={cls.truck_name_loading}>
              <LoadingSkeleton />
            </span>
          </div>
        )}
        {showListTruck && !loadingTruck && listTruckActive.length === 0 && (
          <div className={cls.no_data_found}>No Data</div>
        )}
        {showListTruck && !loadingTruck && listTruckActive.length > 0 && (
          <ul className={cls.truck_list} ref={listTruckNumberRef}>
            {listTruckActive.map((item, index) => (
              <li
                className={classnames(cls.truck_sc, {
                  [cls.activeItem]: truckCursor === index,
                })}
                key={item.id_truck}
                onClick={handleClickTruck(item)}
                onMouseOver={handleMouseOverTruck(index)}
                ref={(el) => (truckElementRef.current[index] = el)}
              >
                {item.number}
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
  if (id === `driver_name_${trip_index}`) {
    return (
      <div className={cls.driver_name}>
        <label className={cls.label}>{label}</label>
        <input
          autoComplete="off"
          className={classnames({
            [cls.error_border]: isErrorDriver,
          })}
          id={id}
          name={id}
          onBlur={handleBlurDriverInput}
          onChange={handleChange}
          onFocus={handleFocusDriver}
          onKeyDown={handleKeyDownDriverName}
          ref={driverNameRef}
          type="text"
          value={input_value}
        />
        <span
          className={cls.dropdown_icon}
          onClick={handleClickIconDropdownDriver}
        >
          <ArrowDropdown />
        </span>
        {showListDriver && loadingDriver && (
          <div className={cls.loading_wrapper}>
            <span className={cls.driver_name_loading}>
              <LoadingSkeleton style={{ height: '18px' }} />
            </span>
            <span className={cls.driver_phone_number_loading}>
              <LoadingSkeleton style={{ height: '18px' }} />
            </span>
          </div>
        )}
        {showListDriver && !loadingDriver && listDriverActive.length === 0 && (
          <div className={cls.no_data_found}>No Data</div>
        )}
        {showListDriver && listDriverActive.length > 0 && (
          <ul className={cls.driver_list} ref={listDriverNameRef}>
            {listDriverActive.map((item, index) => (
              <li
                className={classnames(cls.driver_sc, {
                  [cls.activeItem]: driverCursor === index,
                })}
                key={index}
                onClick={handleClickDriver(item)}
                onMouseOver={handleMouseOverDriver(index)}
                ref={(el) => (driverElementRef.current[index] = el)}
              >
                <div className={cls.info_driver_wrapper}>
                  <span className={cls.driver_name}>
                    {`${formatReturnValue(
                      item.first_name + ' ' + item.last_name,
                      28
                    )}`}
                  </span>
                  <span className={cls.phone_number_driver}>
                    {formatNumberWithoutPrefix(item.phone_number)}{' '}
                    {item.vendors &&
                      ` - ${formatReturnValue(item.vendors.name, 17)}`}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
}
export default InputSearch
;((_data) => {})([React])
