import { formatLeadingZero } from '@helper'
import classnames from 'classnames'
import clsx from 'clsx'
import { isFunction } from 'lodash'
import 'rc-time-picker/assets/index.css'
import React, { useEffect, useRef, useState } from 'react'
import cls from './common.scss'

const checkTimeValid = (value) => {
  if (value.length !== 5) {
    return true
  }
  const hourStr = value.substr(0, 2)
  const minStr = value.substr(3, 2)
  if (
    Number(hourStr) >= 24 ||
    Number(minStr) >= 60 ||
    Number(hourStr) < 0 ||
    Number(minStr) < 0
  ) {
    return false
  }
  return true
}

const DeleteTime = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cls.delete_button}
    height="16"
    viewBox="0 0 24 24"
    width="16"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
  </svg>
)

const TimePickerComponent = ({
  resetErrorTime,
  resetErrorDateAndTime,
  error,
  className,

  type,
  timeValue,
  onHandleChangeTime,
  tabIndex,
  disabled,
}) => {
  const inputRef = useRef(null)
  const timeSelectionRef = useRef(null)
  const [timeInput, setTimeInput] = useState('')
  const [isShowTimePicker, setShowTimePicker] = useState(false)
  useEffect(() => {
    setTimeInput(timeValue || '')
  }, [timeValue])
  useEffect(() => {
    if (!timeInput) {
      if (isFunction(resetErrorDateAndTime)) {
        resetErrorDateAndTime()
      }
    } else {
      if (isFunction(resetErrorTime)) {
        resetErrorTime()
      }
    }
  }, [timeInput])

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside)
    window.addEventListener('touchstart', handleClickOutside)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
      window.removeEventListener('touchend', handleClickOutside)
    }
  })
  const handleClickOutside = (e) => {
    if (
      inputRef.current &&
      timeSelectionRef.current &&
      timeSelectionRef.current.contains(e.target)
    ) {
      inputRef.current.blur()
      return
    }
    if (
      inputRef.current &&
      timeSelectionRef.current &&
      isShowTimePicker &&
      !timeSelectionRef.current.contains(e.target) &&
      !inputRef.current.contains(e.target)
    ) {
      inputRef.current.blur()
      setShowTimePicker(false)
    }
  }

  const handleChangeTime = (e) => {
    e.persist()
    const value = e.target.value.replace(/[^(\d\:)]/g, '')
    const hour = value.slice(0, 2)
    const minute = value.slice(3, 5)
    console.log(hour, minute)
    if (value.length === 2) {
      setTimeInput(value.concat(':'))
      onHandleChangeTime(value.concat(':'))
    } else if (value.length === 3) {
      setTimeInput(value.slice(0, 2))
      onHandleChangeTime(value.slice(0, 2))
    } else if (hour > 23 || minute > 60) {
      setTimeInput('')
      onHandleChangeTime('')
    } else {
      setTimeInput(value)
      onHandleChangeTime(value)
    }
  }
  const onHandleClick = (timeType, value) => () => {
    const d = new Date()
    const hour = d.getHours()
    const min = d.getMinutes()
    if (timeType === 'hour') {
      if (!timeInput) {
        // const sentValue = value.concat(`:${formatLeadingZero(min)}`)
        const sentValue = value.concat(':00')
        setTimeInput(sentValue)
        onHandleChangeTime(sentValue)
      } else if (timeInput.length === 2) {
        const sentValue = value.concat(Number(min))
        setTimeInput(sentValue)
        onHandleChangeTime(sentValue)
      } else {
        const sentValue = value.concat(timeInput.slice(2))
        setTimeInput(sentValue)
        onHandleChangeTime(sentValue)
      }
    }
    if (timeType === 'minute') {
      if (!timeInput) {
        const sentValue = formatLeadingZero(hour).toString().concat(`:${value}`)
        setTimeInput(sentValue)
        onHandleChangeTime(sentValue)
      } else if (timeInput.length === 3) {
        const sentValue = timeInput.concat(value)
        setTimeInput(sentValue)
        onHandleChangeTime(sentValue)
      } else {
        const sentValue = timeInput.slice(0, 3).concat(value)
        setTimeInput(sentValue)
        onHandleChangeTime(sentValue)
      }
    }
  }
  const handleFocus = () => {
    setShowTimePicker(true)
  }
  const handleClickInput = () => {
    setShowTimePicker(true)
  }

  const handleKeyDown = (e) => {
    const { value } = e.target
    if (e.keyCode === 8 && value.length === 3) {
      setTimeInput(value.slice(0, 2))
    }
    if (e.keyCode === 9 && inputRef.current) {
      const holdState = inputRef.current.value
      setShowTimePicker(false)
      setTimeInput(holdState)
    }
  }
  const handleDeleteTimePicker = () => {
    setTimeInput('')
    onHandleChangeTime('')
  }

  return (
    <div
      className={classnames(cls.earliest_time, {
        [cls.earliest_time]: type === 'earliest_time',
        [cls.latest_time]: type === 'latest_time',
        [cls.earliest_time]: type === 'arrival_time',
        [cls.earliest_time]: type === 'departure_time',
      })}
    >
      <input
        autoComplete="off"
        className={clsx(className, error ? cls.error : '  ')}
        disabled={disabled}
        maxLength="5"
        onBlur={() => {
          const valid = checkTimeValid(timeInput)
          if (valid === false) {
            setTimeInput('')
            onHandleChangeTime('')
          }
        }}
        onChange={handleChangeTime}
        onClick={handleClickInput}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        tabIndex={tabIndex || '0'}
        value={timeInput}
      />
      {timeInput && !disabled && (
        <DeleteTime onClick={handleDeleteTimePicker} />
      )}
      {isShowTimePicker && (
        <div className={cls.time_picker_wrapper} ref={timeSelectionRef}>
          <HourPicker onHandleClick={onHandleClick} timeValue={timeValue} />
          <MinutePicker onHandleClick={onHandleClick} timeValue={timeValue} />
        </div>
      )}
    </div>
  )
}

const HourPicker = ({ onHandleClick, timeValue }) => {
  let hourStr = ''
  if (timeValue) {
    hourStr = timeValue.substr(0, 2)
  }
  const date = new Date()
  const hour = date.getHours()
  const hourRef = useRef(null)
  useEffect(() => {
    if (hourRef.current) {
      hourRef.current.scrollTo(0, 30 * hour - 30)
    }
  }, [])
  return (
    <ul className={cls.hour_wrapper} ref={hourRef}>
      {Array.from({ length: 25 }, (v, k) => k).map((item) => (
        <HourItem
          hour={hour}
          hourStr={hourStr}
          item={item}
          key={item}
          onHandleClick={onHandleClick}
        />
      ))}
    </ul>
  )
}

const HourItem = ({ item, hour, hourStr, onHandleClick }) => {
  const liHourRef = useRef()

  useEffect(() => {
    if (hourStr === formatLeadingZero(item)) {
      liHourRef.current.parentNode.style.overflowY = 'auto'
      // liHourRef.current.scrollIntoView({ behavior: 'smooth' })
      //
      liHourRef.current.parentNode.scroll({
        top: liHourRef.current.offsetTop,
        behavior: 'smooth',
      })
      liHourRef.current.parentNode.style.overflowY = 'hidden'
      // debugger
    }
  }, [hourStr, item])
  if (item === 24) {
    return (
      <li
        className={cls.spacer}
        onMouseEnter={() => {
          liHourRef.current.parentNode.style.overflowY = 'auto'
        }}
        onMouseLeave={() => {
          liHourRef.current.parentNode.style.overflowY = 'hidden'
        }}
        ref={liHourRef}
      />
    )
  }

  return (
    <li
      className={classnames({
        [cls.currentHour]: hour === item,
        [cls.active]: hourStr === formatLeadingZero(item),
      })}
      id={`hour_${item}`}
      onClick={onHandleClick('hour', formatLeadingZero(item))}
      onMouseEnter={() => {
        liHourRef.current.parentNode.style.overflowY = 'auto'
      }}
      onMouseLeave={() => {
        liHourRef.current.parentNode.style.overflowY = 'hidden'
      }}
      ref={liHourRef}
    >
      {formatLeadingZero(item)}
    </li>
  )
}

const MinutePicker = ({ onHandleClick, timeValue }) => {
  let minStr = ''
  if (timeValue) {
    minStr = timeValue.substr(3, 2)
  }
  const date = new Date()
  const min = date.getMinutes()
  const minuteRef = useRef(null)
  useEffect(() => {
    if (minuteRef.current) {
      minuteRef.current.scrollTo(0, 30 * min - 30)
    }
  }, [])
  return (
    <ul className={cls.minute_wrapper} ref={minuteRef}>
      {[0, 30].map((item, index) => (
        <li
          className={classnames({
            [cls.currentMinute]: min === item,
            [cls.active]: minStr === formatLeadingZero(item),
          })}
          id={`minute_${index}`}
          key={index}
          onClick={onHandleClick('minute', formatLeadingZero(item))}
        >
          {formatLeadingZero(item)}
        </li>
      ))}
    </ul>
  )
}

export default TimePickerComponent
;((_data) => {})([React])
