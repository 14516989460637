import {
  UikButton,
  UikFormInputGroup,
  UikHeadline,
  UikInput,
} from '@components'
import apiRoute from '@constants/apiRoute'
import { customAxios } from '@helper'
import { withContext } from '@hoc'
import axios from 'axios'
import classnames from 'classnames'
import { isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import logo from '../../../driverDocAssets/DriverDocMainLogo.png'
import Footer from '../../@components/Footer'
import ShowEye from '../../@components/iconComponent'
import SplashScreen from '../../@components/splashscreen'
import { H6 } from '../../@components/typography'
import cls from './reset-password.scss'

const publicIp = require('public-ip')

function ResetPass({ context, ...props }) {
  const initialErrors = {
    password: '',
    confirmPassword: '',
  }
  const [loadingPage, setLoadingPage] = useState(true)
  const { id } = props.match.params
  const [cookies, setCookie] = useCookies(['token'])
  const { token } = cookies
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(initialErrors)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [typePassword, setTypePassword] = useState('password')
  const [typeConfirmPassword, setTypeConfirmPassword] = useState('password')

  const [errorSubmit] = useState('')

  const resetPassword = async (id) => {
    setLoadingPage(true)
    const ipPublic = await publicIp.v4()
    const bodyRequest = {
      browserVersion: navigator.userAgent,
      ipPublic,
    }
    if (!isNil(id)) {
      axios
        .post(`${apiRoute.Reset}/${id}`, bodyRequest)
        .then((response) => {
          if (response.data.data[0].jwtAdminToken) {
            setLoadingPage(false)
            setCookie('token', response.data.data[0].jwtAdminToken, {
              path: '/',
            })
          } else {
            setLoadingPage(false)
          }
        })
        .catch((_error) => {
          setLoadingPage(false)
          setCookie('token', `${id}`, {
            path: '/',
          })
          props.history.push('/forget-Password')
        })
    }
  }
  useEffect(() => {
    resetPassword(id)
  }, [id])

  const handleChange = (e) => {
    const errPassword = {
      password: '',
      confirmPassword: '',
    }
    switch (e.target.name) {
      case 'password':
        setError(errPassword)
        setPassword(e.target.value)
        break

      case 'confirmPassword':
        setError(errPassword)
        setConfirmPassword(e.target.value)
        break
      default:
        break
    }
  }
  const handleBlur = (e) => {
    const errPassword = {
      password: '',
      confirmPassword: '',
    }
    switch (e.target.name) {
      case 'password':
        setError(errPassword)
        setPassword(e.target.value.trim())
        break

      case 'confirmPassword':
        setError(errPassword)
        setConfirmPassword(e.target.value.trim())
        break
      default:
        break
    }
  }

  const handleTogglePass = () => {
    if (typePassword === 'password') {
      setTypePassword('text')
    } else if (typePassword === 'text') {
      setTypePassword('password')
    }
  }

  const handleToggleConfirmPassword = () => {
    if (typeConfirmPassword === 'password') {
      setTypeConfirmPassword('text')
    } else if (typeConfirmPassword === 'text') {
      setTypeConfirmPassword('password')
    }
  }
  const handleDisabled = () => {
    if (
      password === '' ||
      confirmPassword === '' ||
      error.password ||
      error.confirmPassword
    ) {
      return true
    }
    return false
  }

  const handleSubmit = async () => {
    const _checkPass = document.getElementById('password')
    const _checkConfirm = document.getElementById('confirmPassword')
    const passwordTest = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/
    if (password === '' || password === undefined) {
      const errPassword = {
        password: "Password can't be blank",
        confirmPassword: '',
      }
      _checkPass.focus()
      setError(errPassword)
    } else if (!passwordTest.test(password) || password.length < 8) {
      const errPassword = {
        password:
          'Please choose a stronger password: Minimum 8 digits, 1 number, 1 uppercase.',
        confirmPassword: '',
      }
      _checkPass.focus()
      setError(errPassword)
    } else if (confirmPassword !== password) {
      const errConfirmPass = {
        password: '',
        confirmPassword: "Password confirmation doesn't match Password",
      }
      _checkConfirm.focus()
      setError(errConfirmPass)
    } else {
      setLoading(true)
      const bodyRequest = {
        passWord: password.trim(),
        confirmPassword,
      }
      try {
        const { data: dataResponse } = await customAxios(token).post(
          `${apiRoute.forgetPassword}`,
          bodyRequest
        )
        if (dataResponse.success) {
          context.message.success('Saved Successfully')
          setTimeout(() => {
            props.history.push('/')
            setCookie('token', '', {
              path: '/',
            })
          }, 2000)
        } else {
          setLoading(false)
        }
      } catch (err) {
        setLoading(false)
      }
    }
  }
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit()
    }
  }
  if (loadingPage && id) {
    return <SplashScreen />
  }
  return (
    <div className={classnames(cls.Reset, cls.p)}>
      <div className={classnames(cls.img_left, cls.c, cls.c1)}>
        <img alt="" className={cls.img_logo} src={logo} />
      </div>
      <div className={classnames(cls.reset_password, cls.c, cls.c2)}>
        <div className={cls.content_reset}>
          <div className={cls.form_right}>
            <UikHeadline className={cls.headline}>
              <H6>Reset Password</H6>
            </UikHeadline>
            <UikFormInputGroup className={cls.gr_password}>
              <label className={cls.labelPassword}>Password</label>
              <UikInput
                autoComplete="off"
                className={cls.ip_password}
                errorMessage={error.password}
                id="password"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="Password"
                type={typePassword}
                value={password.replace(/[\s]/gm, '')}
              />
              <ShowEye
                className={cls.toggle_password}
                onClick={handleTogglePass}
              />
            </UikFormInputGroup>
            <UikFormInputGroup className={cls.gr_confirm}>
              <label className={cls.labelConfirm}>Confirm Password</label>
              <UikInput
                className={cls.ip_confirm_password}
                errorMessage={error.confirmPassword}
                id="confirmPassword"
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="Confirm Password"
                type={typeConfirmPassword}
                value={confirmPassword.replace(/[\s]/gm, '')}
              />

              <ShowEye
                className={cls.toggle_confirm_password}
                onClick={handleToggleConfirmPassword}
              />
            </UikFormInputGroup>
            <div className={cls.form_button}>
              {' '}
              <UikButton
                className={
                  handleDisabled() ? cls.btn_submit_disabled : cls.btn_submit
                }
                disabled={handleDisabled()}
                isLoading={isLoading}
                onClick={handleSubmit}
              >
                <span className={cls.value}>Submit</span>
              </UikButton>
            </div>

            {errorSubmit && <p style={{ color: 'red' }}>{errorSubmit}</p>}
          </div>
        </div>
      </div>
      <div className={classnames(cls.footer_reset, cls.c, cls.c3)}>
        <Footer />
      </div>
    </div>
  )
}

export default withContext(ResetPass)
;((_data) => {})([React])
