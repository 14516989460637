import { Button } from '@appComponent/button'
import { ArrowSelect, DeleteSearch2 } from '@appComponent/icon'
import { Input2, InputFile2, Textarea2 } from '@appComponent/input'
import { Small } from '@appComponent/typography'
import usePrevious from '@appComponent/usePrevious'
import { UikTopBar, UikTopBarSection, UikTopBarTitle } from '@components'
import apiRoute from '@constants/apiRoute'
import { customAxios, getErrorMessage, getStr, getTimeOffset } from '@helper'
import { useGroups } from '@hooks'
import { Drawer, Modal as ModalAnt, Select } from 'antd'
import classnames from 'classnames'
import _, { identity, size } from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'
import { getDocumentTypes } from '../../../../service'
import cls from './documents.scss'
import { InputSearchLoadNumberWhenUploadDoc } from '@@/App/@components/input'
import { ModalConfirmArchiveOrininalDocs } from './ModalConfirmArchiveOrininalDocs'

const { Option } = Select

const DrawerUploadDocument = identity(
  ({
    setCurrentId,
    showUploadDoc,
    setShowUploadDoc,
    token,
    context,
    set_time_request,
    location,
  }) => {
    const { listIdCheckedGroups, groups } = useGroups()
    const [isChosen, setIsChosen] = useState(false)
    const [idLoad, setIdLoad] = useState(null)
    const [idTrip, setIdTrip] = useState(null)
    const [documentTypeList, setDocumentTypeList] = useState([])
    const [idDocumentTypeSelected, setIdDocumentTypeSelected] =
      useState(undefined)
    const [documentNumber, setDocumentNumber] = useState('')

    const [documentFile, setDocumentFile] = useState(undefined)
    const [note, setNote] = useState('')
    const showUploadDocPrev = usePrevious(showUploadDoc)
    const [value, setValue] = useState('')

    const [savingOrDispatching, setSavingOrDispatching] = useState(false)

    const [confirmComplete, setConfirmComplete] = useState(undefined)
    const [, setDisableBtnsModalConfirm] = useState(false)
    const [showOutOfData, setShowOutOfData] = useState(false)
    const [idGroup, setIdGroup] = useState(null)
    const [showSpinner, setShowSpinner] = useState(false)

    const isLoadDocument = useMemo(() => {
      // load or non-load document
      if (_.isEmpty(documentTypeList) || !idDocumentTypeSelected) {
        return true
      }
      const find = documentTypeList.find(
        (o) => o.idDocumentType === idDocumentTypeSelected
      )
      if (find) {
        return find.loadDocument
      }
      return false
    }, [idDocumentTypeSelected, documentTypeList])

    const onClose = () => {
      resetFields()
      setShowUploadDoc(false)
    }

    const resetFields = () => {
      setIdDocumentTypeSelected(undefined)
      setValue('')
      setIdLoad(null)
      setIdTrip(null)
      setDocumentNumber('')
      setDocumentFile(undefined)
      setIsChosen(false)
      setNote('')
      setIdGroup(null)
      // reset scroll to top
      const antDrawerBody = Array.from(
        document.querySelectorAll('.wr_drawer_upload_doc .ant-drawer-body')
      )[0]
      if (antDrawerBody) {
        antDrawerBody.scrollTop = 0
      }
    }

    const handleClickUpload = async () => {
      // try {
      //   let a1 = documentFile

      //   let resp = await axios.put(
      //     'https://driver-doc-develop.s3.ap-southeast-1.amazonaws.com/81/Load/sss-102121/Proof%20of%20Delivery-kkk-102121085518979-test100.pdf?AWSAccessKeyId=AKIAQGA3LL7H77G4NKYN&Content-Type=application%2Fpdf&Expires=1635325438&Signature=PnIa%2F3uq3%2FbEVBL5ljt4XAIBKhY%3D',
      //     documentFile,
      //     {
      //       headers: {
      //         'Content-Type': documentFile.type,
      //         // 'Content-Type': 'application/octet-stream',
      //         // Authorization: '',
      //         // ContentType: 'application/pdf'
      //       },
      //     }
      //     // {
      //     //   transformRequest: [
      //     //     (data, headers) => {
      //     //       delete headers.common['Content-Type']
      //     //       return data
      //     //     },
      //     //   ],
      //     // }
      //   )

      // } catch (error) {
      //   //
      // }

      // return
      setSavingOrDispatching(true)
      const checkCanComplete = async (idLoad) => {
        try {
          const resp = await customAxios(token).get(
            `${
              apiRoute.loads.CAN_COMPLETE
            }?idLoads=${idLoad}&groups=${listIdCheckedGroups.join(',')}`
          )

          if (!resp.data.success) {
            return
          }
          if (_.get(resp, 'data.data[0].canComplete') === true) {
            setConfirmComplete(true)
          } else {
            uploadDoc(-1)
          }
        } catch (err) {
          uploadDoc(-1)
        }
      }
      if (idLoad) {
        checkCanComplete(idLoad)
      } else {
        uploadDoc(-1)
      }
    }

    const uploadDoc = async (confirm) => {
      try {
        setDisableBtnsModalConfirm(true)
        setSavingOrDispatching(true)
        const formData = new FormData()

        const loc = {
          latitude: location.lat,
          longitude: location.long,
        }

        formData.append('documentFile', documentFile)

        if (isLoadDocument) {
          formData.append('documentNumber', String(documentNumber))
          // handle load number filed
          if (idLoad) {
            formData.append('idLoad', String(idLoad))
          } else {
            formData.append('loadNumber', value ? String(value) : '')
            if (size(groups) === 0) {
              //
            } else if (size(groups) === 1) {
              formData.append('idGroup', String(groups[0].idGroup))
            } else {
              formData.append('idGroup', String(idGroup))
            }

            // both is empty => loadNumber: ""
          }
          if (idTrip) {
            formData.append('idTrip', String(idTrip))
          }
        } else if (size(groups) === 0) {
          //
        } else if (size(groups) === 1) {
          formData.append('idGroup', String(groups[0].idGroup))
        } else {
          formData.append('idGroup', String(idGroup))
        }

        formData.append('idDocumentType', String(idDocumentTypeSelected))
        formData.append('location', JSON.stringify(loc))

        formData.append('timeOffset', getTimeOffset())
        formData.append('note', String(note))

        if (confirm !== -1) {
          formData.append('isConfirm', confirm)
        }
        setShowSpinner(true)
        const resp = await customAxios(token).post(
          `${
            apiRoute.documents.UPLOAD_DOCUMENT
          }?groups=${listIdCheckedGroups.join(',')}`,
          formData
        )
        if (!resp.data.success) {
          context.message.error('Uploading Document Failed!')
          return
        }

        const outOfData = getStr(resp, 'data.data.loadCompleteErrorMessage')

        if (outOfData) {
          setShowOutOfData(true)
        } else {
          setShowUploadDoc(false)
        }
        context.message.success('Uploaded Successfully')

        set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))

        resetFields()
        setCurrentId(null)
      } catch (err) {
        context.message.error(getErrorMessage(err))
      } finally {
        setConfirmComplete(false)
        setShowSpinner(false)
        setTimeout(() => {
          setSavingOrDispatching(false)
          setDisableBtnsModalConfirm(false)
        }, 3888)
      }
    }

    useEffect(() => {
      if (showUploadDoc === true) {
        getDocumentTypes(token, setDocumentTypeList)
        setTimeout(() => {
          const el = document.querySelectorAll(
            '.wr_drawer_upload_doc .ant-drawer-body input'
          )[0]
          if (el) {
            el.focus()
          }
        }, 200)
      } else {
        resetFields()
      }
    }, [showUploadDoc])

    useEffect(() => {
      if (confirmComplete === true) {
        setTimeout(() => {
          const el = document.getElementById('upload_confirm_yes')
          if (el) {
            el.focus()
          }
        }, 200)
      }
    }, [confirmComplete])

    const isShowGroupField = useMemo(() => {
      if (_.isEmpty(documentTypeList)) {
        return false
      }
      const listIdNonLoadType = documentTypeList
        .filter((o) => o.loadDocument === false)
        .map((o) => o.idDocumentType)
      if (idLoad && !listIdNonLoadType.includes(idDocumentTypeSelected)) {
        return false
      }
      if (size(groups) <= 1) {
        return false
      }
      return true
    }, [idLoad, idDocumentTypeSelected, groups, documentTypeList])

    const isDisable = useMemo(() => {
      if (!idDocumentTypeSelected || !documentFile) {
        return true
      }

      if (isShowGroupField && !idGroup) {
        return true
      }
      return false
    }, [idDocumentTypeSelected, documentFile, idGroup, isShowGroupField])

    const renderSelectGroup = () => {
      if (!isShowGroupField) {
        return null
      }
      return (
        <div className={cls.form_group}>
          <label htmlFor="inputupload_document_file">
            <Small>Group</Small>
          </label>
          <Select
            clearIcon={<DeleteSearch2 />}
            id="inputupload_document_type"
            onChange={(value) => setIdGroup(value)}
            style={{ width: '100%' }}
            suffixIcon={<ArrowSelect />}
            value={idGroup ? Number(idGroup) : undefined}
          >
            {groups.map((group) => (
              <Option key={group.idGroup} value={group.idGroup}>
                {group.name}
              </Option>
            ))}
          </Select>
        </div>
      )
    }

    return (
      <Drawer
        bodyStyle={{ paddingBottom: 80 }}
        className={classnames(cls.wr_drawer_upload_doc, 'wr_drawer_upload_doc')}
        destroyOnClose
        footer={
          <div
            className={`wr_footer_add_load ${
              savingOrDispatching ? 'disabled1' : ''
            }`}
          >
            <Button
              onClick={savingOrDispatching ? null : onClose}
              stateClass={savingOrDispatching ? 'Disabled' : 'Secondary'}
              width={74}
            >
              Cancel
            </Button>
            <div className="wr_save_dispatch">
              <Button
                onClick={
                  isDisable || savingOrDispatching ? null : handleClickUpload
                }
                stateClass={
                  isDisable || savingOrDispatching ? 'Disabled' : 'Primary'
                }
                width={77}
              >
                Upload
              </Button>
            </div>
          </div>
        }
        maskClosable={false}
        onClose={onClose}
        title="Upload Document"
        visible={showUploadDoc}
        width={368}
      >
        {confirmComplete && (
          <ModalAnt
            centered
            closable={false}
            footer={null}
            title={null}
            visible
            width={398}
            wrapClassName="wr_modal_confirm"
          >
            <div className={cls.title2}>Load Complete</div>
            <div className={cls.body2}>Are you done with this Load?</div>
            <div className={cls.button_wrapper}>
              <Button
                onClick={() => {
                  // setConfirmComplete(false)
                  // return
                  uploadDoc(false)
                }}
                stateClass="Secondary"
                width={74}
              >
                No
              </Button>

              <Button
                id="edit_confirm_yes"
                onClick={() => {
                  // setConfirmComplete(false)
                  // return
                  uploadDoc(true)
                }}
                stateClass="Primary"
                width={74}
              >
                Yes
              </Button>
            </div>
          </ModalAnt>
        )}
        {showOutOfData && (
          <ModalAnt
            centered
            closable={false}
            footer={null}
            title={null}
            visible
            width={398}
            wrapClassName="wr_modal_out_of_data"
          >
            <div className={cls.modalRoot}>
              <div className={cls.modalMask} />
              <div className={cls.delete_wrapper}>
                <div className={cls.delete_load}>
                  <UikTopBar
                    className={cls.topbar}
                    style={{
                      border: 'none',
                      height: '49px',
                      justifyContent: 'center',
                    }}
                  >
                    <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                      <UikTopBarTitle className={cls.title_out_of_data}>
                        Out of Data
                      </UikTopBarTitle>
                    </UikTopBarSection>
                  </UikTopBar>
                </div>
                <div className={cls.wr_out_of_data_btn}>
                  <Button
                    onClick={() => {
                      setShowOutOfData(false)
                      setShowUploadDoc(false)
                    }}
                    stateClass="Primary"
                    width={74}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </ModalAnt>
        )}
        <div className={cls.form_group}>
          <label htmlFor="inputupload_document_type">
            <Small>Document Type</Small>
          </label>
          <Select
            clearIcon={<DeleteSearch2 />}
            id="inputupload_document_type"
            onChange={(value) => setIdDocumentTypeSelected(value)}
            style={{ width: '100%' }}
            suffixIcon={<ArrowSelect />}
            value={
              idDocumentTypeSelected
                ? Number(idDocumentTypeSelected)
                : undefined
            }
          >
            {documentTypeList.map((doc_type) => (
              <Option
                key={doc_type.idDocumentType}
                value={doc_type.idDocumentType}
              >
                {doc_type.name}
              </Option>
            ))}
          </Select>
        </div>

        {isLoadDocument && (
          <div className={cls.form_group}>
            <label htmlFor="inputupload_doc_number">
              <Small>Document# (Optional)</Small>
            </label>
            <Input2
              id="inputupload_doc_number"
              onChange={(e) => {
                setDocumentNumber(e.target.value)
              }}
              value={documentNumber}
            />
          </div>
        )}

        {/* TODO */}
        {isLoadDocument && (
          // load or non-load document
          <div className={cls.form_group}>
            <label htmlFor="inputupload_load_number">
              <Small>Load# (Optional)</Small>
            </label>
            <InputSearchLoadNumberWhenUploadDoc
              idLoad={idLoad}
              idTrip={idTrip}
              isChosen={isChosen}
              setIdLoad={setIdLoad}
              setIdTrip={setIdTrip}
              setIsChosen={setIsChosen}
              setValue={setValue}
              showUploadDoc={showUploadDoc}
              showUploadDocPrev={showUploadDocPrev}
              value={value}
              token={token}
              listIdCheckedGroups={listIdCheckedGroups}
            />
          </div>
        )}

        {renderSelectGroup()}

        <div className={cls.form_group}>
          <label htmlFor="inputupload_document_file">
            <Small>Document File</Small>
          </label>
          <InputFile2
            context={context}
            fileValue={documentFile}
            maxLengthFileName={31}
            setFileValue={setDocumentFile}
          />
        </div>

        <div className={cls.form_group}>
          <label htmlFor="inputupload_document_note">
            <Small>Note (Optional)</Small>
          </label>
          <Textarea2
            id="inputupload_document_note"
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
        </div>
        <ModalConfirmArchiveOrininalDocs
          loading={showSpinner}
          open={showSpinner}
        />
      </Drawer>
    )
  }
)

export default DrawerUploadDocument
;((_data) => {})([React])
