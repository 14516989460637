import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Redirect } from 'react-router-dom'

export default function withAuth(ProtectedComponent) {
  return function Component(props) {
    const [cookies] = useCookies(['token'])
    const [redirectLogin, setRedirectLogin] = useState(false)
    const [redirectPage, setRedirectPage] = useState(false)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
      console.log('aaa withAuth did mount beg')
      const { token } = cookies
      if (token) {
        console.log('aaa co token redirect to dashboad!!!!!!!!!!!!')
        setLoading(false)
        setRedirectPage(true)
      }
      if (_.isEmpty(token)) {
        console.log('aaa khong co token redirect to login (/) ')
        setLoading(false)
        setRedirectLogin(true)
      }
      console.log('aaa withAuth did mount end')
    }, [])

    if (loading) {
      setLoading(false)
    }
    if (redirectLogin) {
      return <Redirect to="/" />
    }
    if (redirectPage) {
      return <Redirect to="/page/dashboard" />
    }
    return (
      <React.Fragment>
        <ProtectedComponent {...props} />
      </React.Fragment>
    )
  }
}
;((_data) => {})([React])
