import { SpinnerIcon } from '@appComponent/spinnericon'
import { customAxios, getStr } from '@helper'
import { Tooltip } from 'antd'
import classnames from 'classnames'
import produce from 'immer'
import { get, identity, isEmpty } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'

import {
  IconAddCircleOutline,
  IconPeople,
  IconRemoveCircleOutline,
  IconRemoveCircleOutline24,
  IconSteeringWheel,
  IconXCircle24,
} from '@appComponent/icon'

import { Caption, CaptionBold, Small } from '@appComponent/typography'
import apiRoute from '@constants/apiRoute'

import clsInput from '@appComponent/input/input.scss'
import cls from './groups.scss'

const InputMember = identity(
  ({
    context,
    token,
    listMemberSelected,
    setListMemberSelected,
    groupName,
  }) => {
    const [value, setValue] = useState('')
    const [valueDebounce] = useDebounce(value, 300)
    const [listMembersDropDown, setListMembersDropDown] = useState([])
    const [showDropDown, setShowDropDown] = useState(false)
    const [loadingMember, setLoadingMember] = useState(false)
    const [index, setIndex] = useState(-1) // index current of dropdown item
    const inputref = useRef(null) // index current of dropdown item

    useEffect(() => {
      if (valueDebounce) {
        const getUsersAndDrivers = async (search) => {
          const handleData = (data) => {
            setListMembersDropDown(data)
          }

          try {
            setLoadingMember(true)
            const resp = await customAxios(token).get(
              `${apiRoute.groups.SEARCH_GROUP_MEMBERS}?page=1&limit=10000000&search=${search}`
            )
            if (!resp.data.success) {
              return
            }
            handleData(get(resp, 'data.data.members') || [])
          } catch (err) {
            //
          } finally {
            setLoadingMember(false)
          }
        }
        getUsersAndDrivers(valueDebounce)
      }
    }, [valueDebounce])

    const checkMemberExisted = (member, listMembers) => {
      let type = ''
      let existed = false

      if (member.idUser) {
        type = 'user'
        const listIdUser = listMembers
          .filter((o) => !!o.idUser)
          .map((o) => o.idUser)
        existed = listIdUser.includes(member.idUser)
      } else {
        type = 'driver'
        const listIdDriver = listMembers
          .filter((o) => !!o.idDriver)
          .map((o) => o.idDriver)
        existed = listIdDriver.includes(member.idDriver)
      }
      return { type, existed }
    }

    const handleClickMember = (
      member,
      alreadyinagroup = false,
      focus = true
    ) => {
      if (focus && inputref.current) {
        inputref.current.focus()
      }
      if (alreadyinagroup) {
        return
      }
      const { type, existed } = checkMemberExisted(member, listMemberSelected)

      if (existed) {
        // remove
        if (type === 'user') {
          const nextState = produce(listMemberSelected, (draft) => {
            const findIndex = draft
              .map((o) => o.idUser)
              .findIndex((o) => o === member.idUser)
            if (findIndex !== -1) {
              draft.splice(findIndex, 1)
            }
            return draft
          })
          setListMemberSelected(nextState)
        } else {
          const nextState = produce(listMemberSelected, (draft) => {
            const findIndex = draft
              .map((o) => o.idDriver)
              .findIndex((o) => o === member.idDriver)
            if (findIndex !== -1) {
              draft.splice(findIndex, 1)
            }
            return draft
          })
          setListMemberSelected(nextState)
        }
      } else {
        // push
        const nextState = produce(listMemberSelected, (draft) => {
          draft.unshift(member)
        })
        setListMemberSelected(nextState)
      }
    }

    const renderDropDownMember = () => {
      // ////////////////////////
      if (!showDropDown) {
        return null
      }
      if (!valueDebounce) {
        return null
      }

      if (loadingMember) {
        return (
          <div className={cls.dropdown_members}>
            <SpinnerIcon />
          </div>
        )
      }

      if (isEmpty(listMembersDropDown)) {
        return (
          <div className={cls.dropdown_members}>
            <div className="no_data">
              <Caption>No member</Caption>
            </div>
          </div>
        )
      }

      return (
        <div className={cls.dropdown_members}>
          {listMembersDropDown.map((member, i) => (
            <ItemMember
              checkMemberExisted={checkMemberExisted}
              handleClickMember={handleClickMember}
              i={i}
              index={index}
              key={`key-${String(member.idUser)}-${String(
                member.idDriver
              )}-${String(member.fullName)}`}
              listMemberSelected={listMemberSelected}
              member={member}
            />
          ))}
        </div>
      )

      // ////////////////////////
    }

    return (
      <React.Fragment>
        <div className={cls.wr_input_member}>
          <input
            className={classnames(clsInput.input, 'pr36')}
            id="input_group_lastname"
            onChange={(e) => {
              setValue(e.target.value)
            }}
            onFocus={() => setShowDropDown(true)}
            onKeyDown={(e) => {
              // //////////
              if (e.key === 'ArrowDown') {
                const len = listMembersDropDown.length
                if (len === 0) {
                  return
                }
                let newIndex
                if (index === listMembersDropDown.length - 1) {
                  newIndex = 0
                } else {
                  newIndex = index + 1
                }
                if (listMembersDropDown[newIndex]) {
                  setIndex(newIndex)
                }
                e.preventDefault()
              } else if (e.key === 'ArrowUp') {
                const len = listMembersDropDown.length
                if (len === 0) {
                  return
                }
                let newIndex
                if (index === 0) {
                  newIndex = listMembersDropDown.length - 1
                } else {
                  newIndex = index - 1
                }
                if (listMembersDropDown[newIndex]) {
                  setIndex(newIndex)
                }
                e.preventDefault()
              } else if (e.key === 'Tab') {
                // setShow(false)
              } else if (e.key === 'Enter') {
                const member = listMembersDropDown[index]
                if (!member) {
                  return
                }

                const alreadyinagroup =
                  member.countGroups >= 1 && !!member.idDriver
                handleClickMember(member, alreadyinagroup)
              }
              // //////////
            }}
            ref={inputref}
            value={value}
          />
          {!!value && (
            <IconXCircle24
              className={cls.icon_x_circle}
              onClick={() => {
                setValue('')
                setShowDropDown(false)
                setListMembersDropDown([])
              }}
            />
          )}
        </div>

        {renderDropDownMember()}

        <div className="mt24 mb24">
          <CaptionBold className="mr8">Members</CaptionBold>
          <Small>{listMemberSelected.length}</Small>
        </div>

        <div
          className={cls.list_selected_members}
          onClick={() => {
            // if (inputref.current) {
            //   inputref.current.focus()
            // }
          }}
        >
          {listMemberSelected.map((selectedMem) => (
            <div
              className={cls.member2}
              key={`key2-${String(selectedMem.idUser)}-${String(
                selectedMem.idDriver
              )}-${String(selectedMem.fullName)}`}
            >
              <div className={cls.member_icon2}>
                {selectedMem.idUser ? (
                  <Tooltip
                    className={cls.wr_note1}
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                    placement="top"
                    title="User"
                  >
                    <div className={cls.wr_note2}>
                      <IconPeople />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip
                    className={cls.wr_note1}
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                    placement="top"
                    title="Driver"
                  >
                    <div className={cls.wr_note2}>
                      <IconSteeringWheel />
                    </div>
                  </Tooltip>
                )}
              </div>
              <Caption className={cls.fullName2}>
                {getStr(selectedMem, 'fullName', '', 30)}
              </Caption>
              <div className={cls.icon_action2}>
                <IconRemoveCircleOutline24
                  onClick={() => {
                    if (
                      selectedMem.idDriver &&
                      selectedMem.countUnfinishedLoads >= 1
                    ) {
                      context.message.error(`
                        Unable to remove ${selectedMem.fullName} from ${groupName} due to outstanding load(s).
                      `)
                    } else {
                      handleClickMember(selectedMem, false, false)
                    }
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }
)

const ItemMember = ({
  member,
  i,
  index,
  handleClickMember,
  checkMemberExisted,
  listMemberSelected,
}) => {
  const itemRef = useRef()

  useEffect(() => {
    if (i === index) {
      const top = itemRef.current.offsetTop - 298
      const { parentNode } = itemRef.current

      parentNode.scroll({
        top,
      })
    }
  }, [i, index])

  const alreadyinagroup = useMemo(
    () => member.countGroups >= 1 && !!member.idDriver,
    [member]
  )

  return (
    <div ref={itemRef}>
      {i !== 0 && <div className={cls.line} />}

      <div
        className={classnames(cls.member, {
          [cls.selected]: checkMemberExisted(member, listMemberSelected)
            .existed,
          [cls.currentIndex]: index === i,
          [cls.alreadyinagroup]: alreadyinagroup,
        })}
        onClick={() => {
          handleClickMember(member, alreadyinagroup)
        }}
        onMouseEnter={() => {
          //
        }}
      >
        <div className={cls.member_icon}>
          {member.idUser ? <IconPeople /> : <IconSteeringWheel />}
        </div>

        <Caption className={cls.fullName}>
          {getStr(member, 'fullName', '', 33)}
        </Caption>

        {alreadyinagroup ? (
          <Small className={cls.alreadyinagroup}>Already in a group</Small>
        ) : (
          <div className={cls.icon_action}>
            {checkMemberExisted(member, listMemberSelected).existed ? (
              <IconRemoveCircleOutline />
            ) : (
              <IconAddCircleOutline />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default InputMember
;((_data) => {})([React])
