import classnames from 'classnames'
import * as React from 'react'

import { IconUser } from '../../../@components/icon'
import cls from './social-header-user.scss'

// @flow
type UikTopBarUserDropdownDisplayElementType = {
  className?: String,
  Component?: React.ElementType,
  name?: String,
  children?: React.Node,
  userName?: String,
}

const UikTopBarUserDropdownDisplayComponent = ({
  className,
  Component,
  ...rest
}: UikTopBarUserDropdownDisplayElementType) => (
  <Component className={classnames(cls.wrapper, className)} {...rest}>
    {/* <span className={cls.username}>{userName}</span> */}
    <IconUser className={cls.icon_user} />
    {/* <Uikon className={cls.dropdownArrow}>arrow_dropdown</Uikon> */}
  </Component>
)

UikTopBarUserDropdownDisplayComponent.defaultProps = {
  className: null,
  Component: 'div',
  children: null,
  userName: null,
  name: null,
}

export default UikTopBarUserDropdownDisplayComponent
;((_data) => {})([React])
