/* eslint-disable react/prop-types */
import {
  ArrowSelect,
  IconEclipseBlue,
  IconResetFile,
  IconSharp,
  IconX18,
} from '@appComponent/icon'
import { Caption, Small } from '@appComponent/typography'
import usePrevious from '@appComponent/usePrevious'
import apiRoute from '@constants/apiRoute'
import { checkNumber, customAxios, formatReturnValue, getStr } from '@helper'
import { useGroups } from '@hooks'
import classnames from 'classnames'
import clsx from 'clsx'
import _, { get, isFunction } from 'lodash'
import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { useDebounce } from 'use-debounce'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Tooltip } from 'antd'
import { formatPhoneNumber, getErrorMessage } from '../../../helper'
import { SpinnerIcon } from '../spinnericon'
import cls from './input.scss'
import { DriverStatusEnum } from '../../../constants'

export const Input2 = forwardRef(
  (
    {
      className,
      error,
      disabled,
      maxLength,
      name,
      value,
      onChange,
      tabIndex,
      id,
      onFocus,
      onKeyDown,
      placeholder,
      onBlur,
    },
    ref
  ) => (
    <div className="relative">
      <input
        onBlur={onBlur}
        autoComplete="off"
        className={classnames(
          cls.input,
          className,
          {
            [cls.error]: !!error,
          },
          'placeholder:text-Gray placeholder:text-sm'
        )}
        disabled={disabled || false}
        id={id}
        maxLength={maxLength}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        ref={ref}
        tabIndex={tabIndex || '0'}
        type={'text'}
        value={value}
      />
      {error && <div className="text-Red absolute left-0 text-xs">{error}</div>}
    </div>
  )
)

export const Input2WithReset = ({ iconright, ...props }) => (
  <div className={cls.wr_input}>
    <Input2 {...props} />
    {iconright && <div className={cls.wr_iconright}>{iconright}</div>}
  </div>
)
export const Input2Advanced = ({ iconDelete, iconright, ...props }) => (
  <div className={cls.wr_input}>
    <Input2 {...props} />
    {iconDelete && !props.disabled && (
      <div className={cls.wr_iconDelete}>{iconDelete}</div>
    )}
    {iconright && <div className={cls.wr_iconright}>{iconright}</div>}
  </div>
)

export const Textarea2 = ({
  value,
  onChange,
  tabIndex,
  id,
  rows = 3,
  className,
  height = 172,
  disabled = false,
}) => (
  <textarea
    disabled={disabled}
    className={classnames(cls.textarea, className)}
    id={id}
    onChange={onChange}
    rows={rows}
    style={{ height: `${height}px` }}
    tabIndex={tabIndex || '0'}
    value={value}
  >
    {value}
  </textarea>
)

export const Test1 = (Component) => <Component test={10} />

// export const Select2WithDataFetching = ({
//   containerref,
//   value,
//   onChange,
//   listOptions,
// }) => {
//   return (
//     <Select
//       clearIcon={<div>C</div>}
//       suffixIcon={<div>S</div>}
//       getPopupContainer={() => containerref.current}
//       allowClear={true}
//       value={value}
//       onChange={onChange}
//       showSearch
//       optionFilterProp="children"
//       filterOption={(input, option) => {
//         return (
//           option.children[0].props.children
//             .toLowerCase()
//             .indexOf(input.toLowerCase()) >= 0
//         )
//       }}
//     >
//       {drivers.map(option => {
//         return (
//           <Option key={option.id_driver} value={getStr(driver, 'id_driver')}>
//             <Caption className="driver_name">
//               {getStr(driver, 'full_name')}
//             </Caption>
//             <Small
//               style={{ borderBottom: '1px solid red!' }}
//               className="driver_name_sub"
//             >
//               <span>
//                 {formatNumberWithoutPrefix(getStr(driver, 'phone_number'))}
//               </span>
//               {getStr(driver, 'vendors.name') && (
//                 <span> - {getStr(driver, 'vendors.name')}</span>
//               )}
//             </Small>
//           </Option>
//         )
//       })}
//     </Select>
//   )
// }
//

export const InputSearchDriverWhenAddLoad = ({
  resetError,
  error,
  token,
  drivers,
  set_drivers,
  set_idDriver,
  idDriver,
  value,
  setValue,
  showAdd,
  showAddPrev,
  loadingDriver,
  setLoadingDriver,
  id,
}) => {
  const { listIdCheckedGroups } = useGroups()
  const [index, setIndex] = useState(-1)

  const [valueDebounce] = useDebounce(value, 300)
  const [show, setShow] = useState(false)

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    // clearSuggestions()
    setShow(false)
  })

  const getActiveDrivers = async (text) => {
    try {
      setLoadingDriver(true)
      const resp = await customAxios(token).get(
        `${
          apiRoute.drivers.ACTIVE_DRIVERS
        }text=${text}&groups=${listIdCheckedGroups.join(',')}`
      )
      set_drivers(resp.data.data)
    } catch (err) {
      //
    } finally {
      setLoadingDriver(false)
    }
  }

  useEffect(() => {
    if (showAdd === true && showAddPrev === true) {
      getActiveDrivers(valueDebounce)
    }
  }, [valueDebounce])

  useEffect(() => {
    setIndex(-1)
  }, [drivers])

  useEffect(() => {
    setIndex(-1)
  }, [show])

  useEffect(() => {
    if (isFunction(resetError)) {
      resetError()
    }
  }, [value])

  const renderDriverList = () => {
    if (!show) {
      return null
    }

    if (loadingDriver) {
      return (
        <div className={cls.wr_results}>
          <SpinnerIcon />
        </div>
      )
    }

    if (_.isEmpty(drivers)) {
      return (
        <div className={cls.wr_results}>
          <div className="no_data">
            <Caption>No driver</Caption>
          </div>
        </div>
      )
    }
    return (
      <div className={cls.wr_results} style={{ overflow: 'auto' }}>
        {drivers.map((driver, o) => (
          <ItemDriver
            driver={driver}
            index={index}
            key={driver.id_driver}
            o={o}
            setShow={setShow}
            setValue={setValue}
            set_idDriver={set_idDriver}
          />
        ))}
      </div>
    )
  }

  return (
    <div className={cls.wr_inputsearch2} ref={ref}>
      <input
        autoComplete="off"
        className={clsx(cls.input, error ? cls.error : '')}
        id={id}
        onBlur={() => {
          if (!idDriver) {
            setValue('')
          }
        }}
        onChange={(e) => {
          set_idDriver(null)
          setValue(e.target.value)
        }}
        onFocus={() => {
          getActiveDrivers(valueDebounce)
          setShow(true)
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            const len = drivers.length
            if (len === 0) {
              return
            }
            let newIndex
            if (index === drivers.length - 1) {
              newIndex = 0
            } else {
              newIndex = index + 1
            }
            if (drivers[newIndex]) {
              setIndex(newIndex)
            }
            e.preventDefault()
          } else if (e.key === 'ArrowUp') {
            const len = drivers.length
            if (len === 0) {
              return
            }
            let newIndex
            if (index === 0) {
              newIndex = drivers.length - 1
            } else {
              newIndex = index - 1
            }
            if (drivers[newIndex]) {
              setIndex(newIndex)
            }
            e.preventDefault()
          } else if (e.key === 'Tab') {
            setShow(false)
          } else if (e.key === 'Enter') {
            // handleSelect(drivers[index])()
            set_idDriver(drivers[index].id_driver)

            setValue(drivers[index].full_name)
            setShow(false)
            setIndex(-1)
          }
        }}
        tabIndex="0"
        type="text"
        value={value}
      />
      {renderDriverList()}

      <div
        className={cls.wr_arrow}
        onClick={() => {
          setShow(!show)
          if (!show) {
            getActiveDrivers(valueDebounce)
          }
        }}
      >
        <ArrowSelect />
      </div>
    </div>
  )
}

export const ItemDriver = ({
  o,
  index,
  set_idDriver,
  driver,
  setShow,
  setValue,
}) => {
  const driverRef = useRef()

  useEffect(() => {
    if (o === index) {
      // driverRef.current.parentNode.style.overflowY = 'auto'
      // driverRef.current.scrollIntoView({ behavior: 'smooth' })
      //

      driverRef.current.parentNode.scroll({
        top: driverRef.current.offsetTop - 100,
        // behavior: 'smooth',
      })
      // driverRef.current.parentNode.style.overflowY = 'hidden'
      // debugger
    }
  }, [o, index])

  return (
    <div
      className={classnames(cls.result, {
        [cls.selected]: o === index,
        [cls.driver]: driver,
      })}
      id={`driver-${o}`}
      onClick={() => {
        set_idDriver(driver.id_driver)
        setValue(driver.full_name)
        setShow(false)
      }}
      ref={driverRef}
      tabIndex="0"
    >
      <Caption block className={cls.driver_name}>
        {getStr(driver, 'full_name')}
      </Caption>
      <Small className={cls.driver_phone}>
        <span>
          {formatPhoneNumber(
            getStr(driver, 'country_code'),
            getStr(driver, 'phone_number')
          )}
        </span>
        {getStr(driver, 'vendors.name') && (
          <span> - {getStr(driver, 'vendors.name')}</span>
        )}
      </Small>
      {driver.status === DriverStatusEnum.PENDING && (
        <Tooltip
          className={cls.icon_eclipse_blue}
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
          placement="top"
          title="Pending"
        >
          <div className={cls.icon_eclipse_blue2}>
            <IconEclipseBlue />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export const InputSearchDriverWhenEditLoad = ({
  error,
  resetError,
  drivers,
  set_drivers,
  set_idDriver,
  idDriver,
  token,
  value,
  setValue,
  idForEdit,
  idForEditPrev,
  id,
  loadingDriver,
  setLoadingDriver,
}) => {
  const { listIdCheckedGroups } = useGroups()
  const [index, setIndex] = useState(-1)
  const [valueDebounce] = useDebounce(value, 300)
  const [show, setShow] = useState(false)

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    // clearSuggestions()
    setShow(false)
  })

  const getActiveDrivers = async (text) => {
    try {
      setLoadingDriver(true)
      const resp = await customAxios(token).get(
        `${
          apiRoute.drivers.ACTIVE_DRIVERS
        }text=${text}&groups=${listIdCheckedGroups.join(',')}`
      )
      set_drivers(resp.data.data)
    } catch (err) {
      //
    } finally {
      setLoadingDriver(false)
    }
  }

  useEffect(() => {
    if (
      checkNumber(idForEdit) &&
      checkNumber(idForEditPrev) &&
      idForEdit === idForEditPrev
    ) {
      getActiveDrivers(valueDebounce)
    }
  }, [valueDebounce])

  useEffect(() => {
    if (isFunction(resetError)) {
      resetError()
    }
  }, [value])

  useEffect(() => {
    setIndex(-1)
  }, [drivers])
  useEffect(() => {
    setIndex(-1)
  }, [show])

  const renderDriverList = () => {
    if (!show) {
      return null
    }

    if (loadingDriver) {
      return (
        <div className={cls.wr_results}>
          <SpinnerIcon />
        </div>
      )
    }

    if (_.isEmpty(drivers)) {
      return (
        <div className={cls.wr_results}>
          <div className="no_data">
            <Caption>No driver</Caption>
          </div>
        </div>
      )
    }
    return (
      <div className={cls.wr_results} style={{ overflow: 'auto' }}>
        {drivers.map((driver, o) => (
          <ItemDriver
            key={JSON.stringify(driver)}
            driver={driver}
            index={index}
            o={o}
            setShow={setShow}
            setValue={setValue}
            set_idDriver={set_idDriver}
          />
        ))}
      </div>
    )
  }

  return (
    <div className={cls.wr_inputsearch2} ref={ref}>
      <input
        autoComplete="off"
        className={clsx(cls.input, error ? cls.error : '')}
        id={id}
        onBlur={() => {
          if (!idDriver) {
            setValue('')
          }
        }}
        onChange={(e) => {
          set_idDriver(null)
          setValue(e.target.value)
        }}
        onFocus={() => {
          getActiveDrivers(value)
          setShow(true)
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            const len = drivers.length
            if (len === 0) {
              return
            }

            let newIndex
            if (index === drivers.length - 1) {
              newIndex = 0
            } else {
              newIndex = index + 1
            }
            if (drivers[newIndex]) {
              setIndex(newIndex)
            }
            e.preventDefault()
          } else if (e.key === 'ArrowUp') {
            const len = drivers.length
            if (len === 0) {
              return
            }
            let newIndex
            if (index === 0) {
              newIndex = drivers.length - 1
            } else {
              newIndex = index - 1
            }
            if (drivers[newIndex]) {
              setIndex(newIndex)
            }
            e.preventDefault()
          } else if (e.key === 'Tab') {
            setShow(false)
          } else if (e.key === 'Enter') {
            // handleSelect(drivers[index])()
            set_idDriver(drivers[index].id_driver)
            setValue(drivers[index].full_name)
            setShow(false)
            setIndex(-1)
          }
        }}
        type="text"
        value={value}
      />
      {renderDriverList()}

      <div
        className={cls.wr_arrow}
        onClick={() => {
          setShow(!show)
          if (!show) {
            getActiveDrivers(valueDebounce)
          }
        }}
      >
        <ArrowSelect />
      </div>
    </div>
  )
}

export const InputSearchLoadNumberWhenUploadDoc = ({
  // error,
  // loads,
  setIdLoad,
  idLoad,
  setIdTrip,
  // getActiveLoads,
  value,
  setValue,
  showUploadDoc,
  showUploadDocPrev,
  // fetchingLoad,
  isChosen,
  setIsChosen,
  id,
  disabled = false,
  token,
  listIdCheckedGroups = [],
  idDriver,
}) => {
  const [valueDebounce] = useDebounce(value, 300)
  const [show, setShow] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [limit, setLimit] = useState(10)
  const [index, setIndex] = useState(-1)
  const inputref = useRef()

  const [loading, setLoading] = useState(false)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [loadsData, setLoadsData] = useState([])
  const [loadsError, setLoadsError] = useState(null)

  const getLoads = async (searchText) => {
    if (!token) return

    try {
      setLoadsError(null)
      if (!isLoadMore) {
        setLoading(true)
      }

      const url = !!idDriver
        ? `${apiRoute.documents.SEARCH_LOAD_BY_ID_DRIVER_WHEN_REQUEST_DOC}/${idDriver}`
        : apiRoute.documents.GET_LOADS

      const resp = await customAxios(token).get(
        `${url}?search=${searchText}&groups=${listIdCheckedGroups.join(
          ','
        )}&page=1&limit=${limit}`
      )
      setLoading(false)
      const { data } = resp.data

      if (_.isEmpty(data)) {
        setLoadsData([])
      } else {
        const newData = _.unionBy([...loadsData, ...data], (obj) =>
          JSON.stringify(obj)
        )
        setLoadsData(newData, data)
      }
    } catch (error) {
      setLoading(false)
      setLoadsError(error)
    }
  }

  useEffect(() => {
    setIndex(-1)
  }, [loadsData])

  const ref = useOnclickOutside(() => {
    setShow(false)
    setHasMore(true)
    setLimit(10)

    const behaviour = 'reset'
    if (behaviour === 'autofill') {
      if (idLoad === null && value !== '') {
        // find if input text result in a load number
        const find = loadsData.find((o) => o.loadNumber === value)
        if (find) {
          setIdLoad(find.idLoad)
        }
        setIsChosen(true)
      }
    } else {
      // clear text input and idLoad

      if (isChosen === false) {
        setValue('')
        setIdLoad(null)
      }
    }
  })

  useEffect(() => {
    if (
      showUploadDoc === true &&
      showUploadDocPrev === true &&
      !!valueDebounce
    ) {
      if (!isChosen) {
        setShow(true)
        getLoads(valueDebounce)
      }
    }
  }, [valueDebounce, limit])

  useEffect(() => {
    if (!value) {
      setShow(false)
      setLimit(10)
      setLoadsData([])
      setHasMore(true)
      setIsLoadMore(false)
      setLoading(false)
    }
  }, [value])

  const handleChoseNewLoadNumber = () => {
    setIdTrip(null)
    setIdLoad(null)
    setShow(false)
    setIsChosen(true)
    setIndex(-1)
  }

  const handleChoseLoadNumber = (load) => {
    setIdLoad(load.id_load)
    if (load.id_trip) {
      setIdTrip(Number(load.id_trip))
    } else {
      setIdTrip(null)
    }
    if (load.trip_name) {
      setValue(`${load.load_number} - ${load.trip_name}`)
    } else {
      setValue(load.load_number)
    }
    setShow(false)
    setIsChosen(true)
    setIndex(-1)
  }

  const renderOptions = () => {
    if (value === 'Mixed' || !value || !show) {
      return null
    }

    // if (loading) {
    //   return (
    //     <div className={classnames(cls.result)} onClick={() => {}} tabIndex="0">
    //       <SpinnerIcon />
    //     </div>
    //   )
    // }

    if (loadsError) {
      return (
        <div className={classnames(cls.result)} onClick={() => {}} tabIndex="0">
          Error: {getErrorMessage(loadsError)}
        </div>
      )
    }

    if (_.isEmpty(loadsData) && !loading) {
      return (
        <div
          className={classnames(cls.result, {
            [cls.selected]: index === 0,
          })}
          onClick={() => {
            handleChoseNewLoadNumber()
          }}
          tabIndex="0"
          style={{
            flexWrap: 'wrap',
            gap: 4,
          }}
        >
          Load# <strong>{value}</strong> not found.{' '}
          <span className={cls.blue}>Add New Load</span>
        </div>
      )
    }
    return (
      <React.Fragment>
        {loadsData.map((load, o) => (
          <ItemLoadNumber
            handleChoseLoadNumber={handleChoseLoadNumber}
            index={index}
            key={o}
            load={load}
            o={o}
            setIdLoad={setIdLoad}
            setIsChosen={setIsChosen}
            setShow={setShow}
            setValue={setValue}
          />
        ))}
      </React.Fragment>
    )
  }

  const fetchMoreData = () => {
    setIsLoadMore(true)
    if (limit > loadsData.length) {
      setHasMore(false)
      setLimit(10)
    } else {
      setLimit(limit + 10)
      setHasMore(true)
    }
  }

  return (
    <div className={cls.wr_inputsearch2} ref={ref}>
      {isChosen && (
        <div className={cls.chosen_content}>
          <span>{formatReturnValue(value, 38)}</span>
          <button
            className="button_no_style"
            onClick={() => {
              setIdTrip(null)
              setIsChosen(false)
              setTimeout(() => {
                inputref.current.focus()
              }, 100)
              setValue('')
              setIdLoad(null)
            }}
          >
            <IconX18 />
          </button>
        </div>
      )}
      <input
        autoComplete="off"
        className={classnames(cls.input, {
          [cls.chosen]: isChosen,
        })}
        disabled={disabled}
        id={id}
        onChange={(e) => {
          const { value: newValue } = e.target

          if (value !== newValue) {
            setLoading(true)
            setLoadsData([])
          }

          setValue(newValue)
          setLimit(10)
          setIsLoadMore(false)
          setHasMore(true)
          if (newValue === '') {
            setIdLoad(undefined)
          }
        }}
        onFocus={(e) => {
          if (isChosen) {
            e.preventDefault()
            e.target.blur()
            return false
          }
          if (value) {
            // getActiveLoads(value)
            setShow(true)
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            const len = loadsData.length
            if (len === 0) {
              if (index === -1) {
                setIndex(0)
              }
            } else {
              let newIndex
              if (index === loadsData.length - 1) {
                newIndex = 0
              } else {
                newIndex = index + 1
              }
              if (loadsData[newIndex]) {
                setIndex(newIndex)
              }
            }

            e.preventDefault()
          } else if (e.key === 'ArrowUp') {
            const len = loadsData.length
            if (len === 0) {
              if (index === 0) {
                setIndex(-1)
              }
            } else {
              let newIndex
              if (index === 0) {
                newIndex = loadsData.length - 1
              } else {
                newIndex = index - 1
              }
              if (loadsData[newIndex]) {
                setIndex(newIndex)
              }
            }

            e.preventDefault()
          } else if (e.key === 'Tab') {
            setShow(false)
            if (!idLoad && !!value && !isChosen) {
              setValue('')
            }
          } else if (e.key === 'Enter') {
            if (index === -1) {
              return
            }
            if (_.isEmpty(loadsData)) {
              handleChoseNewLoadNumber()
            } else {
              handleChoseLoadNumber(loadsData[index])
            }
          }
        }}
        ref={inputref}
        tabIndex={isChosen ? '-1' : '0'}
        type="text"
        value={value}
      />
      {show && (
        <React.Fragment>
          {loading ? (
            <div className={cls.wr_results}>
              <div
                className={classnames(cls.result)}
                onClick={() => {}}
                tabIndex="0"
              >
                <SpinnerIcon />
              </div>
            </div>
          ) : (
            <React.Fragment>
              {_.size(loadsData) < 10 ? (
                <div className={cls.wr_results}>{renderOptions()}</div>
              ) : (
                <div
                  className={cls.wr_results}
                  style={{
                    overflowY: 'hidden',
                  }}
                >
                  <InfiniteScroll
                    dataLength={loadsData.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={
                      hasMore && loadsData.length === limit && <SpinnerIcon />
                    }
                    height={275}
                  >
                    {loadsData.map((load, o) => (
                      <ItemLoadNumber
                        handleChoseLoadNumber={handleChoseLoadNumber}
                        index={index}
                        key={o}
                        load={load}
                        o={o}
                        setIdLoad={setIdLoad}
                        setIsChosen={setIsChosen}
                        setShow={setShow}
                        setValue={setValue}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export const ItemLoadNumber = ({ o, load, index, handleChoseLoadNumber }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (o === index) {
      itemRef.current.parentNode.scroll({
        top: itemRef.current.offsetTop - 100,
      })
    }
  }, [o, index])

  return (
    <div
      className={classnames(cls.result, {
        [cls.selected]: o === index,
      })}
      id={`load-${o}`}
      key={`${String(load.id_load)}-${load.load_number}${String(
        load.id_trip
      )}-${load.trip_name}`}
      onClick={() => {
        handleChoseLoadNumber(load)
      }}
      ref={itemRef}
      tabIndex="0"
    >
      <IconSharp
        style={{
          display: 'inline-block',
          marginRight: '10px',
          flexShrink: 0,
        }}
      />{' '}
      <div>
        <span>Load# {load.load_number}</span>
        {!!load.trip_name && (
          <span>
            {' - '}
            {load.trip_name}
          </span>
        )}
      </div>
    </div>
  )
}

export const InputSearchLoadNumberWhenEditDoc = ({
  error,
  isMixed,
  loads,
  setIdLoad,
  idLoad,
  setIdTrip,
  getActiveLoads,
  value,
  setValue,
  showEditDoc,
  showEditDocPrev,
  fetchingLoad,
  isChosen,
  setIsChosen,
  id,
}) => {
  const [valueDebounce] = useDebounce(value, 300)
  const [show, setShow] = useState(false)
  const [index, setIndex] = useState(-1)
  const inputref = useRef()

  useEffect(() => {
    setIndex(-1)
  }, [loads])

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    // clearSuggestions()
    setShow(false)

    const behaviour = 'reset'
    if (behaviour === 'autofill') {
      if (idLoad === null && value !== '' && value !== 'Mixed') {
        // find if input text result in a load number
        const find = loads.find((o) => o.loadNumber === value)
        if (find) {
          setIdLoad(find.idLoad)
        }
        setIsChosen(true)
      }
    } else {
      // clear text input and idLoad
      if (isChosen === false && value !== 'Mixed') {
        if (isMixed) {
          // setValue('Mixed')
        } else {
          setValue('')
        }
        setIdLoad(null)
      }
    }
  })

  const handleChoseNewLoadNumber = () => {
    setIdTrip(null)
    setIdLoad(null)
    setShow(false)
    setIsChosen(true)
    setIndex(-1)
  }

  const handleChoseLoadNumber = (load) => {
    setIdLoad(load.id_load)
    if (load.id_trip) {
      setIdTrip(Number(load.id_trip))
    } else {
      setIdTrip(null)
    }
    if (load.trip_name) {
      setValue(`${load.load_number} - ${load.trip_name}`)
    } else {
      setValue(load.load_number)
    }
    setShow(false)
    setIsChosen(true)
    setIndex(-1)
  }

  const renderOptions = () => {
    if (value === 'Mixed' || !value || !show) {
      return null
    }

    if (fetchingLoad) {
      return (
        <div className={cls.wr_results}>
          <div
            className={classnames(cls.result)}
            onClick={() => {}}
            tabIndex="0"
          >
            <SpinnerIcon />
          </div>
        </div>
      )
    }

    if (error) {
      return (
        <div className={cls.wr_results}>
          <div
            className={classnames(cls.result)}
            onClick={() => {}}
            tabIndex="0"
          >
            Error: {getErrorMessage(error)}
          </div>
        </div>
      )
    }

    if (_.isEmpty(loads)) {
      return (
        <div className={cls.wr_results}>
          <div
            className={classnames(cls.result, {
              [cls.selected]: index === 0,
            })}
            onClick={handleChoseNewLoadNumber}
            tabIndex="0"
            style={{
              flexWrap: 'wrap',
              gap: 4,
            }}
          >
            Load# <strong>{value}</strong> not found.{' '}
            <span className={cls.blue}>Add New Load</span>
          </div>
        </div>
      )
    }
    return (
      <div className={cls.wr_results}>
        {loads.map((load, o) => (
          <ItemLoadNumber
            handleChoseLoadNumber={handleChoseLoadNumber}
            index={index}
            key={load.idLoad}
            load={load}
            o={o}
            setIdLoad={setIdLoad}
            setIsChosen={setIsChosen}
            setShow={setShow}
            setValue={setValue}
          />
        ))}
      </div>
    )
  }

  useEffect(() => {
    if (showEditDoc === showEditDocPrev && !!valueDebounce) {
      if (!isChosen) {
        setShow(true)
        getActiveLoads(valueDebounce)
      }
    }
  }, [valueDebounce])

  useEffect(() => {
    if (!value) {
      setShow(false)
    }
  }, [value])

  return (
    <div className={cls.wr_inputsearch2} ref={ref}>
      {isChosen && (
        <div className={cls.chosen_content}>
          <span>{formatReturnValue(value, 38)}</span>
          <button
            className="button_no_style"
            onClick={() => {
              setIdTrip(null)
              setIsChosen(false)
              setTimeout(() => {
                inputref.current.focus()
              }, 100)
              setIdLoad(null)
              if (isMixed === true) {
                setValue('Mixed')
              } else {
                setValue('')
              }
            }}
          >
            <IconX18 />
          </button>
        </div>
      )}
      <input
        autoComplete="off"
        className={classnames(cls.input, {
          [cls.chosen]: isChosen,
        })}
        id={id}
        onChange={(e) => setValue(e.target.value)}
        onFocus={(e) => {
          if (isChosen) {
            e.preventDefault()
            e.target.blur()
            return false
          }
          if (value === 'Mixed') {
            // setValue('')
          } else if (value) {
            setShow(true)
            getActiveLoads(value)
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            const len = loads.length
            if (len === 0) {
              if (index === -1) {
                setIndex(0)
              }
            } else {
              let newIndex
              if (index === loads.length - 1) {
                newIndex = 0
              } else {
                newIndex = index + 1
              }
              if (loads[newIndex]) {
                setIndex(newIndex)
              }
            }
            e.preventDefault()
          } else if (e.key === 'ArrowUp') {
            const len = loads.length
            if (len === 0) {
              if (index === 0) {
                setIndex(-1)
              }
            } else {
              let newIndex
              if (index === 0) {
                newIndex = loads.length - 1
              } else {
                newIndex = index - 1
              }
              if (loads[newIndex]) {
                setIndex(newIndex)
              }
            }

            e.preventDefault()
          } else if (e.key === 'Tab') {
            setShow(false)
            if (!idLoad && !isChosen) {
              setValue('')
            }
          } else if (e.key === 'Enter') {
            if (index === -1) {
              return
            }
            if (_.isEmpty(loads)) {
              handleChoseNewLoadNumber()
            } else {
              handleChoseLoadNumber(loads[index])
            }
          }
        }}
        ref={inputref}
        tabIndex={isChosen ? '-1' : '0'}
        type="text"
        value={value}
      />
      {renderOptions()}
    </div>
  )
}

export const InputFile2 = ({
  fileValue,
  setFileValue,
  fileNameFromServer,
  setFileNameFromServer,
  id,
  context,
  maxLengthFileName = 24,
}) => {
  const [focus, setFocus] = useState(false)
  const inputfileref = useRef()
  const fileValuePrev = usePrevious(fileValue)
  const [dragging, setDragging] = useState(false)

  useEffect(() => {
    if (!fileValue && !!fileValuePrev) {
      inputfileref.current.value = null
    }

    // if (!fileValuePrev && !!fileValue) {
    //   setFileNameFromServer('')
    // }
  }, [fileValue])

  const renderFileName = () => {
    const str = _.get(fileValue, 'name')
      ? _.get(fileValue, 'name')
      : fileNameFromServer
    return formatReturnValue(str, maxLengthFileName)
  }
  return (
    <div
      className={classnames(cls.wr_inputfile, {
        [cls.hasfile]: !!fileValue || fileNameFromServer,
        [cls.dragging]: dragging,
        [cls.focus]: focus,
      })}
    >
      <input
        accept="application/pdf"
        autoComplete="off"
        className={cls.inputfile}
        id={id}
        onBlur={() => {
          setFocus(false)
        }}
        onChange={(e) => {
          if (_.get(e, 'target.files[0]')) {
            if (e.target.files[0].type !== 'application/pdf') {
              context.message.error('Only accept Pdf files')
              e.preventDefault()
              return false
            }
            setFileValue(e.target.files[0])
          }
        }}
        onDragEnter={() => {
          setDragging(true)
        }}
        onDragLeave={() => {
          setDragging(false)
        }}
        onDrop={() => {
          setDragging(false)
        }}
        onFocus={() => {
          setFocus(true)
        }}
        ref={inputfileref}
        type="file"
      />
      <div>
        {!!fileValue || fileNameFromServer ? (
          <React.Fragment>
            <span className={cls.filename}>{renderFileName()}</span>
            <IconResetFile
              className={cls.resetfile}
              onClick={() => {
                setFileValue(null)
                if (setFileNameFromServer) {
                  setFileNameFromServer('')
                }
              }}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span style={{ cursor: 'pointer', diplay: 'inline-block' }}>
              Drop a PDF file to attach, or{' '}
            </span>
            <span className={cls.blue}>browse</span>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

// begin input search

export const InputSearch = ({
  error,
  children,
  disabled,
  list,
  setIdData,
  idData,
  getListFn,
  value,
  setValue,
  loadingData,
  showPopup,
  noDataText,
  idField,
  nameField,
}) => {
  const [index, setIndex] = useState(-1)
  const [valueDebounce] = useDebounce(value, 300)
  const [show, setShow] = useState(false)

  const ref = useOnclickOutside(() => {
    setShow(false)
  })

  useEffect(() => {
    if (showPopup) {
      getListFn(valueDebounce)
    }
  }, [valueDebounce])

  useEffect(() => {
    setIndex(-1)
  }, [list])
  useEffect(() => {
    setIndex(-1)
  }, [show])

  const renderDriverList = () => {
    if (!show) {
      return null
    }

    if (loadingData) {
      return (
        <div className={cls.wr_results}>
          <SpinnerIcon />
        </div>
      )
    }

    if (error && get(error, 'response.status') !== 404) {
      return (
        <div className={cls.wr_results}>
          <div className="p-4 ">Error: {getErrorMessage(error)}</div>
        </div>
      )
    }

    if (_.isEmpty(list)) {
      return (
        <div className={cls.wr_results}>
          <div className="no_data">
            <Caption>{noDataText}</Caption>
          </div>
        </div>
      )
    }
    return (
      <div className={cls.wr_results} style={{ overflow: 'auto' }}>
        {list.map((item, o) => (
          <React.Fragment key={o}>
            {children({
              item,
              o,
              index,
              setIdData,
              setShow,
              setValue,
              idField,
              nameField,
            })}
          </React.Fragment>
        ))}
      </div>
    )
  }

  return (
    <div className={cls.wr_inputsearch2} ref={ref}>
      <input
        autoComplete="off"
        className={cls.input}
        disabled={disabled}
        onBlur={() => {
          if (!idData) {
            setValue('')
          }
        }}
        onChange={(e) => {
          setIdData(null)
          setValue(e.target.value)
        }}
        onFocus={() => {
          getListFn(value)
          setShow(true)
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            const len = list.length
            if (len === 0) {
              return
            }

            let newIndex
            if (index === list.length - 1) {
              newIndex = 0
            } else {
              newIndex = index + 1
            }
            if (list[newIndex]) {
              setIndex(newIndex)
            }
            e.preventDefault()
          } else if (e.key === 'ArrowUp') {
            const len = list.length
            if (len === 0) {
              return
            }
            let newIndex
            if (index === 0) {
              newIndex = list.length - 1
            } else {
              newIndex = index - 1
            }
            if (list[newIndex]) {
              setIndex(newIndex)
            }
            e.preventDefault()
          } else if (e.key === 'Tab') {
            setShow(false)
          } else if (e.key === 'Enter') {
            // handleSelect(list[index])()
            if (_.get(list, `[${index}][${idField}]`)) {
              setIdData(list[index][idField])
              setValue(list[index][nameField])
              setShow(false)
              setIndex(-1)
            }
          }
        }}
        type="text"
        value={value}
      />
      {renderDriverList()}

      <div
        className={cls.wr_arrow}
        onClick={() => {
          if (disabled) {
            return
          }
          setShow(!show)
          if (!show) {
            getListFn(valueDebounce)
          }
        }}
      >
        <ArrowSelect />
      </div>
    </div>
  )
}

InputSearch.propTypes = {
  list: PropTypes.any.isRequired,
  setIdData: PropTypes.any.isRequired,
  idData: PropTypes.any.isRequired,
  getListFn: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  setValue: PropTypes.any.isRequired,
  loadingData: PropTypes.any.isRequired,
  showPopup: PropTypes.any.isRequired,
  noDataText: PropTypes.any.isRequired,
  idField: PropTypes.any.isRequired,
  nameField: PropTypes.any.isRequired,
}

// end input search

// begin item search

export const ItemSearch = ({
  o,
  index,
  setIdData,
  item,
  setShow,
  setValue,
  idField,
  nameField,
}) => {
  const itemRef = useRef()
  useEffect(() => {
    if (o === index) {
      itemRef.current.parentNode.scroll({
        top: itemRef.current.offsetTop - 100,
      })
    }
  }, [o, index])

  return (
    <div
      className={classnames(cls.result, {
        [cls.selected]: o === index,
      })}
      id={`item-${o}`}
      onClick={() => {
        setIdData(item[idField])
        setValue(item[nameField])
        setShow(false)
      }}
      ref={itemRef}
      tabIndex="0"
    >
      <Caption block className={cls.driver_name}>
        {item[nameField]}
      </Caption>
    </div>
  )
}

ItemSearch.propTypes = {
  o: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
  setIdData: PropTypes.any.isRequired,
  item: PropTypes.any.isRequired,
  setShow: PropTypes.any.isRequired,
  setValue: PropTypes.any.isRequired,
  idField: PropTypes.any.isRequired,
  nameField: PropTypes.any.isRequired,
}
// end item search
;((_data) => {})([React])
