import { MyCheckbox } from '@appComponent/MyCheckbox'
import { Button } from '@appComponent/button'
import { InfoIcon } from '@appComponent/icon'
import {
  Input2,
  InputFile2,
  InputSearchDriverWhenEditLoad,
  Textarea2,
} from '@appComponent/input'
import usePrevious from '@appComponent/usePrevious'
import apiRoute from '@constants/apiRoute'
import { DRAFT } from '@constants/load_status'
import {
  customAxios,
  equalStr,
  getEdittedListTripForEditLoad,
  getErrorMessage,
  getNewStopData,
  getStr,
  splitDate,
  splitTime,
  toastError,
} from '@helper'
import { useGroups } from '@hooks'
import { Drawer } from 'antd'
import classnames from 'classnames'
import produce from 'immer'
import { toCamelCase } from 'js-convert-case'
import _, { identity } from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import nextId from 'react-id-generator'
import useSWR from 'swr'
import {
  checkEmptyErrorsDispatchForm,
  checkErrorsDispatch,
  initValueErrors,
} from './DrawerAddLoad'
import OneStopEdit from './OneStopEdit'
import cls from './loadsnew.scss'
import { SpinnerIcon } from '@@/App/@components/spinnericon'
import { COMPLETE, INCOMPLETE } from '@@/constants/load_status'

if (typeof window !== 'undefined') {
  window.toCamelCase = toCamelCase
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#fff' : '#fff',
  border: isDraggingOver
    ? '2px groove rgba(80, 86, 94, 0.4)'
    : '2px groove rgba(0,0,0,0)',
  transition: 'all 0.2s linear',
  // background: isDraggingOver ? 'lightblue' : '#fff',
  padding: '10px',
  marginLeft: '12px',
  marginRight: '12px',
  borderRadius: '4px',
  marginTop: '-12px',
})

const checkAtleastRequiredField = ({
  loadnumber,
  idDriver,
  loadsheetFile,
  fileNameLoadsheetFromServer,
  note,
  truck,
  trailer,
  listTrip,
}) => {
  if (
    !!loadnumber ||
    !!idDriver ||
    !!loadsheetFile ||
    !!fileNameLoadsheetFromServer ||
    !!note ||
    !!truck ||
    !!trailer
  ) {
    return true
  }
  for (const item of listTrip) {
    if (
      !!item.order ||
      !!item.tripName ||
      !!item.tripType ||
      !!item.location.address ||
      !!item.appointmentNumber ||
      !!item.ld ||
      !!item.lt ||
      !!item.ed ||
      !!item.et ||
      item.note !== ''
    ) {
      return true
    }
  }
  return false
}

const fetcher = async (url, token) => {
  const data = await customAxios(token).get(url)
  if (data.status === 200) {
    return data.data.data
  }
  return { error: true }
}

const DrawerEditLoad = identity(function DrawerEditLoad({
  idForEdit,
  setIdForEdit,
  token,
  context,
  set_time_request,
  setListRecentlyEdittedIdLoad,
}) {
  const { listIdCheckedGroups } = useGroups()
  let url = `${
    apiRoute.loads.DETAIL
  }/${idForEdit}?groups=${listIdCheckedGroups.join(',')}`
  const {
    data,
    error,
    isValidating: loading,
  } = useSWR(idForEdit ? url : null, fetcher, {
    revalidateOnFocus: false,
  })

  const idForEditPrev = usePrevious(idForEdit)

  const [value, setValue] = useState('')
  const [savingOrDispatching, setSavingOrDispatching] = useState(false)
  const [isHover, setHover] = useState(false)
  const [loadnumber, set_loadnumber] = useState('')
  const [drivers, set_drivers] = useState([])
  const [idDriver, set_idDriver] = useState(null)
  const [, set_loadsheet] = useState('')
  const [note, set_note] = useState('')
  const [truck, set_truck] = useState('')
  const [trailer, set_trailer] = useState('')
  const [listTrip, setListTrip] = useState([getNewStopData(1)])
  const [loadsheetFile, setLoadsheetFile] = useState(undefined)
  const [fileNameLoadsheetFromServer, setFileNameLoadsheetFromServer] =
    useState('')
  const [loadsheetOld, setLoadsheetOld] = useState('')
  const locationRef = useRef([])
  const [loadingDriver, setLoadingDriver] = useState(false)

  const [errors, setErrors] = useState(initValueErrors)

  const isAllStopSmartETA = useMemo(() => {
    return listTrip.every((o) => o.tracking === true)
  }, [listTrip])

  const onClose = () => {
    setIdForEdit(null)
  }

  const handleClickToggleCollapse = (i) => {
    const nextState = produce(listTrip, (draft) => {
      draft[i].collapse = !draft[i].collapse
    })
    setListTrip(nextState)
  }

  const handleClickAppt = (i) => {
    const nextState = produce(listTrip, (draft) => {
      if (draft[i].isAppointment === 1) {
        draft[i].isAppointment = 0
      } else if (draft[i].isAppointment === 0) {
        draft[i].isAppointment = 1
      }
    })
    setListTrip(nextState)
  }

  const handleDeleteTripByUniqueId = (uniqueid) => {
    const i = listTrip.findIndex((o) => o.uniqueid === uniqueid)
    const nextState = produce(listTrip, (draft) => {
      // let i = draft.findIndex(o => o.uniqueid === uniqueid)
      if (i !== -1) {
        draft[i].delete = true
      }
    })

    const nextStateErrors = produce(errors, (draft) => {
      const trips = [...draft.trips]
      trips.splice(i, 1)
      draft.trips = trips
    })

    setListTrip(nextState)
    setErrors(nextStateErrors)
  }

  const onDragEnd = (result) => {
    const { destination } = result
    if (!destination) {
      return
    }
    //TODO
    // reOrderTrip(source, destination)
    const listTripReordered = reorder(
      listTrip,
      result.source.index,
      result.destination.index
    )

    const errorTripsReordered = reorder(
      errors.trips,
      result.source.index,
      result.destination.index
    )

    setErrors((prev) => ({ ...prev, trips: errorTripsReordered }))

    setListTrip(listTripReordered)
  }

  const updateListTrip = (uniqueid, field, value) => {
    const nextState = produce(listTrip, (draft) => {
      draft.filter((o) => {
        return o.uniqueid === uniqueid
      })[0][field] = value
    })
    setListTrip(nextState)
  }

  const handleAddStop = () => {
    const nextState = produce(listTrip, (draft) => {
      const tripNumber = listTrip.length + 1
      draft.push(getNewStopData(tripNumber))
    })
    setListTrip(nextState)

    const nextStateErrors = produce(errors, (draft) => {
      draft.trips.push({
        tripName: '',
        location: '',
        ld: '',
        lt: '',
        ed: '',
        et: '',
      })
    })
    setErrors(nextStateErrors)

    setTimeout(() => {
      let el = document.getElementById(`inputstoptype-${nextState.length - 1}`)
      if (el) {
        el.focus()
      }

      let antDrawerBody = Array.from(
        document.querySelectorAll('.wr_drawer_add_edit .ant-drawer-body')
      )[0]
      if (antDrawerBody) {
        antDrawerBody.scrollTop = antDrawerBody.scrollHeight
      }
    }, 100)
  }

  const handleClickSave = async () => {
    let atLeast1FieldIsFilled = checkAtleastRequiredField({
      idDriver,
      listTrip,
      loadnumber,
      loadsheetFile,
      note,
      trailer,
      truck,
    })

    if (!atLeast1FieldIsFilled) {
      toastError('You need to have at least 1 field filled out')
      return
    }

    try {
      setSavingOrDispatching(true)
      const listTripEditted = getEdittedListTripForEditLoad(listTrip, true)

      const formData = new FormData()
      const getData = (a) => {
        return a ? String(a) : ''
      }
      formData.append('loadSheet', loadsheetFile)
      formData.append(
        'loadSheetOld',
        fileNameLoadsheetFromServer === '' ? '' : loadsheetOld
      )
      formData.append('idDriver', getData(idDriver))
      formData.append('note', getData(note ? note.trim() : ''))
      formData.append('loadNumber', getData(loadnumber))
      formData.append('truck', getData(truck))
      formData.append('trailer', getData(trailer))

      formData.append('trips', JSON.stringify(listTripEditted))

      const resp = await customAxios(token).put(
        `${
          apiRoute.loads.UPDATE_SAVE
        }/${idForEdit}?groups=${listIdCheckedGroups.join(',')}`,
        formData
      )
      if (!resp.data.success) {
        context.message.error('Editting Load Failed!')
        return
      }
      setListRecentlyEdittedIdLoad([idForEdit])
      // setCurrentId(null)
      context.message.success('Edited Successfully')
      set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
      setIdForEdit(null)
    } catch (err) {
      context.message.error(getErrorMessage(err))
    } finally {
      setTimeout(() => {
        setSavingOrDispatching(false)
      }, 3888)
    }
  }

  const handleClickDispatch = async () => {
    const newErrorsDispatchForm = produce(errors, (draft) => {
      checkErrorsDispatch({
        draft,
        idDriver,
        listTrip,
        loadnumber,
      })
    })

    const isEmptyErrors = checkEmptyErrorsDispatchForm(newErrorsDispatchForm)

    if (!isEmptyErrors) {
      setErrors(newErrorsDispatchForm)
      return
    }
    try {
      setSavingOrDispatching(true)
      const listTripEditted = getEdittedListTripForEditLoad(listTrip, false)
      const formData = new FormData()
      const getData = (a) => {
        return a ? String(a) : ''
      }
      formData.append('loadSheet', loadsheetFile)
      formData.append(
        'loadSheetOld',
        fileNameLoadsheetFromServer === '' ? '' : loadsheetOld
      )
      formData.append('idDriver', getData(idDriver))
      formData.append('note', getData(note ? note.trim() : ''))
      formData.append('loadNumber', getData(loadnumber))
      formData.append('truck', getData(truck))
      formData.append('trailer', getData(trailer))

      formData.append('trips', JSON.stringify(listTripEditted))

      const resp = await customAxios(token).put(
        `${
          apiRoute.loads.UPDATE_DISPATCH
        }/${idForEdit}?groups=${listIdCheckedGroups.join(',')}`,
        formData
      )
      if (!resp.data.success) {
        context.message.error('Editting Load Failed!')
        return
      }
      setListRecentlyEdittedIdLoad([idForEdit])
      // setCurrentId(null)
      set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
      context.message.success('Edited Successfully')
      setIdForEdit(null)
    } catch (err) {
      context.message.error(getErrorMessage(err))
    } finally {
      setTimeout(() => {
        setSavingOrDispatching(false)
      }, 3888)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      let el = document.querySelectorAll(
        '.wr_drawer_add_edit .ant-drawer-body input'
      )[0]
      if (el) {
        el.focus()
      }
    }, 200)
  }, [])

  useEffect(() => {
    if (idDriver === null || _.isEmpty(drivers)) return
    let index = drivers.findIndex((o) => o.id_driver === idDriver)
    if (index === -1) {
      // set_idDriver(null)
      // setValue('')
    }
  }, [drivers, idDriver])

  useEffect(() => {
    if (loading === false && error === undefined && !!data) {
      set_loadnumber(getStr(data, 'load_number'))
      set_idDriver(getStr(data, 'drivers.id_driver'))
      set_loadsheet(getStr(data, 'load_sheet'))
      setLoadsheetOld(getStr(data, 'load_sheet'))
      set_note(data.note || '')
      set_truck(getStr(data, 'truck'))
      set_trailer(getStr(data, 'trailer'))
      //TODO
      setFileNameLoadsheetFromServer(getStr(data, 'originalName'))

      setValue(getStr(data, 'drivers.full_name'))

      const nextState = produce(data.trips, (draft) => {
        draft.map((o) => {
          o.uniqueid = nextId()
          o.collapse = false
          o.tripName = o.trip_name
          delete o.trip_name

          o.tripType = o.trip_type
          delete o.trip_type

          o.realtimeArrival = o.realtime_arrival
          delete o.realtime_arrival

          o.realtimeDeparture = o.realtime_departure
          delete o.realtime_departure

          o.appointmentNumber = o.appointment_number
          delete o.appointment_number

          o.earliestTime = o.earliest_time
          delete o.earliest_time

          o.latestTime = o.latest_time
          delete o.latest_time

          o.isAppointment = o.is_appointment
          delete o.is_appointment

          o.tripNumber = Number(o.trip_number)
          delete o.trip_number

          if (o.latestTime === '') {
            o.ld = null
            o.lt = null
          } else {
            o.ld = new Date(splitDate(o.latestTime))
            o.lt = splitTime(o.latestTime)
          }

          if (o.earliestTime === '') {
            o.ed = null
            o.et = null
          } else {
            o.ed = new Date(splitDate(o.earliestTime))
            o.et = splitTime(o.earliestTime)
          }

          if (!getStr(o, 'location.address')) {
            o.location = {
              city: '',
              address: '',
              latitude: '',
              longitude: '',
              placeId: '',
              state: '',
              name: '',
              zipcode: '',
              displayValue: '',
            }
          } else {
            o.location.placeId = getStr(o, 'location.placeId')
          }
          return o
        })
      })

      setListTrip(nextState)

      const errorTrips = data.trips.map(() => ({
        tripName: '',
        location: '',
        ld: '',
        lt: '',
        ed: '',
        et: '',
      }))

      setErrors({
        loadnumber: '',
        idDriver: '',
        trips: errorTrips,
      })
    }
  }, [data, loading, error])

  useEffect(() => {
    console.log('aaa errors', errors)
  }, [errors])
  console.log(note)

  return (
    <Drawer
      bodyStyle={{ paddingBottom: 80 }}
      className="wr_drawer_add wr_drawer_add_edit"
      destroyOnClose
      footer={
        <div
          className={`wr_footer_add_load ${
            savingOrDispatching ? 'disabled1' : ''
          }`}
        >
          <Button
            onClick={savingOrDispatching ? null : onClose}
            stateClass={savingOrDispatching ? 'Disabled' : 'Secondary'}
            width={74}
          >
            Cancel
          </Button>

          <div className="wr_save_dispatch">
            {equalStr(getStr(data, 'load_status'), DRAFT) && (
              <Button
                className="mr8"
                onClick={savingOrDispatching ? null : handleClickSave}
                stateClass={savingOrDispatching ? 'Disabled' : 'Outline'}
                width={61}
              >
                Save
              </Button>
            )}
            <Button
              onClick={savingOrDispatching ? null : handleClickDispatch}
              stateClass={savingOrDispatching ? 'Disabled' : 'Primary'}
              width={87}
            >
              Dispatch
            </Button>
          </div>
        </div>
      }
      maskClosable={false}
      onClose={onClose}
      title="Edit Load"
      visible={!!idForEdit}
      width={597}
    >
      {loading && (
        <div className="absolute bg-white bg-opacity-80 z-10 inset-0 flex justify-center items-center">
          <SpinnerIcon />
        </div>
      )}

      {error && (
        <div className="absolute bg-white bg-opacity-80 z-10 inset-0 flex justify-center items-center">
          <div className="flex flex-col justify-center items-center">
            <div className="mb-4 ">Error: {getErrorMessage(error)}</div>
            <Button onClick={onClose} stateClass={'Secondary'} width={74}>
              Close
            </Button>
          </div>
        </div>
      )}
      <div className="add_load_above_part">
        <div className="row1 u_d_flex">
          <div className="row1--col1">
            <div className="label_load1">
              Load#
              <div
                onMouseEnter={() => {
                  setHover(true)
                }}
                onMouseLeave={() => {
                  setHover(false)
                }}
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  zIndex: 100,
                }}
              >
                <InfoIcon></InfoIcon>
                <div
                  className={classnames('tooltip1 long1', {
                    opacity0: !isHover,
                  })}
                >
                  TMS Reference Number
                </div>
              </div>
            </div>
            <Input2
              error={!!errors.loadnumber}
              onChange={(e) => {
                if (e.target.value.length <= 32) {
                  setErrors((prev) => ({ ...prev, loadnumber: '' }))
                  set_loadnumber(e.target.value)
                }
              }}
              value={loadnumber}
            />
            {errors.loadnumber && (
              <div className="text-Red mt-1 text-[12px] leading-[150%] ">
                {errors.loadnumber}
              </div>
            )}
          </div>
          <div className="row1--col2">
            <div className="label_load1">Driver</div>
            <InputSearchDriverWhenEditLoad
              drivers={drivers}
              error={!!errors.idDriver}
              idDriver={idDriver}
              idForEdit={idForEdit}
              idForEditPrev={idForEditPrev}
              loadingDriver={loadingDriver}
              resetError={() => {
                setErrors((prev) => ({ ...prev, idDriver: '' }))
              }}
              setLoadingDriver={setLoadingDriver}
              setValue={setValue}
              set_drivers={set_drivers}
              set_idDriver={set_idDriver}
              token={token}
              value={value}
            ></InputSearchDriverWhenEditLoad>
            {errors.idDriver && (
              <div className="text-Red mt-1 text-[12px] leading-[150%] ">
                {errors.idDriver}
              </div>
            )}
          </div>
        </div>
        <div className="row1">
          <div className="label_load1">Upload Load Sheet (Optional)</div>
          <InputFile2
            context={context}
            fileNameFromServer={fileNameLoadsheetFromServer}
            fileValue={loadsheetFile}
            maxLengthFileName={60}
            setFileNameFromServer={setFileNameLoadsheetFromServer}
            setFileValue={setLoadsheetFile}
          ></InputFile2>
        </div>
        <div className="row1">
          <div className="label_load1">Note (Optional)</div>
          <Textarea2
            onChange={(e) => set_note(e.target.value || '')}
            value={note}
          ></Textarea2>
        </div>
        <div className="row1 row1--truck_trailer u_d_flex">
          <div>
            <div className="label_load1">Truck# (Optional)</div>
            <Input2 onChange={(e) => set_truck(e.target.value)} value={truck} />
          </div>
          <div>
            <div className="label_load1">Trailer# (Optional)</div>
            <Input2
              onChange={(e) => set_trailer(e.target.value)}
              value={trailer}
            />
          </div>
        </div>
        {/*  */}
        <div className=" flex justify-between items-center mt-6 ">
          <div className="text-[16px] font-bold  ">Stops</div>
          <div className="flex items-center gap-x-[8px] ">
            <MyCheckbox
              checked={isAllStopSmartETA}
              handleClickCheckbox={() => {
                const newTrackingValue = isAllStopSmartETA ? false : true
                setListTrip((prev) => {
                  return prev.map((trip) => {
                    console.log('aaa trip ', trip)
                    const isComplete =
                      equalStr(getStr(trip, 'trip_status'), COMPLETE) ||
                      equalStr(getStr(trip, 'trip_status'), INCOMPLETE)
                    return isComplete
                      ? trip
                      : { ...trip, tracking: newTrackingValue }
                  })
                })
              }}
              id="editloadform_trackingall"
            />
            <label
              className="text-Black text-[14px] "
              htmlFor="editloadform_trackingall"
              onClick={null}
            >
              Track with Smart ETA for all stops
            </label>
          </div>
        </div>
        {/*  */}
      </div>
      <DragDropContext className="DragDropContext" onDragEnd={onDragEnd}>
        <Droppable className="Droppable" droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {listTrip
                .filter((o) => o.delete !== true)
                .map((item, i) => (
                  <OneStopEdit
                    context={context}
                    errors={errors.trips[i]}
                    handleClickAppt={handleClickAppt}
                    handleClickToggleCollapse={handleClickToggleCollapse}
                    handleDeleteTripByUniqueId={handleDeleteTripByUniqueId}
                    i={i}
                    item={item}
                    key={item.uniqueid}
                    listTrip
                    locationRef={locationRef}
                    setErrors={(trip) => {
                      setErrors((prev) => {
                        return {
                          ...prev,
                          trips: prev.trips.map((o, index) => {
                            if (index === i) {
                              o = { ...trip }
                              return o
                            } else {
                              return o
                            }
                          }),
                        }
                      })
                    }}
                    token={token}
                    updateListTrip={updateListTrip}
                  />
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className={cls.wr_addstopbtn}>
        <Button
          havePrefixIcon={true}
          onClick={handleAddStop}
          stateClass="Link"
          tabIndex="0"
        >
          Add Stop
        </Button>
      </div>
    </Drawer>
  )
})

export default DrawerEditLoad
;((_data) => {})([React])
