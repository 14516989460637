import React from 'react'

const IconCompany = ({ fill }) => (
  <svg
    fill="none"
    height="16.5"
    viewBox="0 0 19 18"
    width="16.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M10.0932 0.833127C10.343 0.96168 10.5 1.21906 10.5 1.5V4.48904L15.4359 8.0147C15.633 8.15549 15.75 8.38279 15.75 8.625V15.75H16.875C17.2892 15.75 17.625 16.0858 17.625 16.5C17.625 16.9142 17.2892 17.25 16.875 17.25H1.875C1.46079 17.25 1.125 16.9142 1.125 16.5C1.125 16.0858 1.46079 15.75 1.875 15.75H3.75V5.25C3.75 5.00779 3.86697 4.78049 4.06407 4.6397L9.31407 0.889703C9.54268 0.726409 9.84338 0.704574 10.0932 0.833127ZM5.25 15.75H9V4.88374C8.99992 4.87749 8.99992 4.87125 9 4.865V2.95739L5.25 5.63597V15.75ZM10.5 6.33239V15.75H14.25V9.01096L10.5 6.33239Z"
      fill={fill || '#50565E'}
      fillRule="evenodd"
    />
  </svg>
)
export default IconCompany
;((_data) => {})([React])
