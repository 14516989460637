import { Button } from '@appComponent/button'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import withAuth from '@auth'
import { UikFormInputGroup, UikInput } from '@components'
import apiRoute from '@constants/apiRoute'
import {
  checkMessageAuth,
  customAxios,
  formatToNumber,
  getErrorMessage,
  removeCookieUtil,
} from '@helper'
import { withContext } from '@hoc'
import isEmpty from 'lodash/isEmpty'
import PhoneInput from 'react-phone-input-2'
import ShowEye from '../../../../@components/iconComponent'
import cls from './change-password.scss'
import { regex } from '../../../../../utils'
import ReactModal from '../../../components/MyModal/ReactModal'
import isEqual from 'lodash/isEqual'
import OtpInput from 'react-otp-input'
import { ModalConfirmArchiveOrininalDocs } from '../../Documents/ModalConfirmArchiveOrininalDocs'

function formatReturnedData(data) {
  if (!data && data !== 0) {
    return ''
  }
  return data
}

const initialContact = {
  email: '',
  phoneNumber: '',
  country: 'us',
  countryCode: '1',
}
const initialState = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
  oldPasswordType: 'password',
  newPasswordType: 'password',
  confirmPasswordType: 'password',
  error: '',
  errorNew: '',
  errorConfirm: '',
  disabledButton: true,
}

const ChangePassword = ({ context }) => {
  const [cookies, , removeCookie] = useCookies(['token', 'accessToken'])
  const { token } = cookies
  const [store, setStore] = useState(initialState)
  const [contact, setContact] = useState(initialContact)
  const [errorPhone, setErrorPhone] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [countryDefault, setCountryDefault] = useState('us')
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [errorEmail, setErrorEmail] = useState('')
  const [oldContact, setOldContact] = useState(initialContact)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [code, setCode] = useState('')
  const [showModalVerifyCode, setShowModalVerifyCode] = useState(false)
  const [spinner, setSpinner] = useState(false)

  useEffect(() => {
    handleGetContact()
  }, [])
  useEffect(() => {
    handleValidate()
  }, [store.oldPassword, store.newPassword, store.confirmPassword])
  useEffect(() => {
    const buttonDropDown = document.querySelector(
      '.containerClass .flag-dropdown'
    )

    if (isEmpty(buttonDropDown)) {
      return
    }

    buttonDropDown.addEventListener('click', async () => {
      // ////////////////////////////
      let countries = document.querySelectorAll(
        '.containerClass .country-list .country'
      )
      var interval = setInterval(() => {
        if (isEmpty(countries)) {
          countries = document.querySelectorAll(
            '.containerClass .country-list .country'
          )
        } else {
          clearInterval(interval)
          countries.forEach((o) => {
            const oldText = o.innerHTML
            const reg = />\+\d{1,100}/
            const match = oldText.match(reg)
            if (match !== null) {
              const match2 = match[0]

              const match3 = match2.replace('+', '(+')
              const match4 = `${match3})`
              const newText = oldText.replace(reg, match4)
              o.innerHTML = newText
            }
          })
        }
      }, 30)
      // ////////////////////////////
    })

    return () => {}
  }, [])
  const handleValidate = () => {
    if (store.oldPassword && store.newPassword && store.confirmPassword) {
      setStore({ ...store, disabledButton: false })
    } else {
      setStore({ ...store, disabledButton: true })
    }
  }

  const handleChange = (event) => {
    event.persist()
    const { value } = event.target
    switch (event.target.name) {
      case 'old_password': {
        setStore({ ...store, oldPassword: value, error: '' })
        break
      }
      case 'new_password': {
        setStore({ ...store, newPassword: value, errorNew: '' })
        break
      }
      case 'confirm_password': {
        setStore({ ...store, confirmPassword: value, errorConfirm: '' })
        break
      }
      case 'phoneNumber': {
        setErrorPhone('')
        const regExPhoneNumber = /[0-9]{10}/g
        const regExRandom10 = /[^0-9\(\)\s\-]{10}/g
        let phoneValue = ''
        if (regExRandom10.test(value)) {
          phoneValue = ''
        } else if (regExPhoneNumber.test(value)) {
          phoneValue = `(${value.slice(0, 3)}) ${value.slice(
            3,
            6
          )}-${value.slice(6, 10)}`
        } else {
          phoneValue = value
        }
        setContact({ ...contact, phoneNumber: phoneValue })
        break
      }
      default:
        break
    }
  }
  const phoneFormation = (inputValue) => {
    let result = ''
    const input = inputValue.replace(/\D/g, '').substring(0)
    const zip = input.substring(0, 3)
    const middle = input.substring(3, 6)
    const last = input.substring(6)
    if (input.length > 6) {
      result = `(${zip}) ${middle}-${last}`
    } else if (input.length > 3) {
      result = `(${zip}) ${middle}`
    } else if (input.length > 0) {
      result = `(${zip}`
    }
    return result
  }
  const handleBlur = (event) => {
    event.persist()
    const { value } = event.target
    switch (event.target.name) {
      case 'old_password': {
        setStore({ ...store, oldPassword: value.trim() })
        break
      }
      case 'new_password': {
        setStore({ ...store, newPassword: value.trim() })
        break
      }
      case 'confirm_password': {
        setStore({ ...store, confirmPassword: value.trim() })
        break
      }
      case 'phoneNumber': {
        const regExPhoneNumber = /[0-9]{10}/g
        const regExRandom10 = /[^0-9\(\)\s\-]{10}/g
        let phoneValue = ''
        if (regExRandom10.test(value)) {
          phoneValue = ''
        } else if (regExPhoneNumber.test(value)) {
          phoneValue = `(${value.slice(0, 3)}) ${value.slice(
            3,
            6
          )}-${value.slice(6, 10)}`
        } else {
          phoneValue = phoneFormation(value)
        }
        console.log('aaa phoneValue ', phoneValue)
        break
      }
      default:
        break
    }
  }

  const handleToggle = (type) => () => {
    switch (type) {
      case 'old': {
        if (store.oldPasswordType === 'password') {
          setStore({ ...store, oldPasswordType: 'text' })
        } else if (store.oldPasswordType === 'text') {
          setStore({ ...store, oldPasswordType: 'password' })
        }
        break
      }
      case 'new': {
        if (store.newPasswordType === 'password') {
          setStore({ ...store, newPasswordType: 'text' })
        } else if (store.newPasswordType === 'text') {
          setStore({ ...store, newPasswordType: 'password' })
        }
        break
      }
      case 'confirm': {
        if (store.confirmPasswordType === 'password') {
          setStore({ ...store, confirmPasswordType: 'text' })
        } else if (store.confirmPasswordType === 'text') {
          setStore({ ...store, confirmPasswordType: 'password' })
        }
        break
      }
      default:
        break
    }
  }
  const handleGetContact = async () => {
    try {
      const { data: dataResponse } = await customAxios(token).get(
        `${apiRoute.Contact}`
      )
      if (dataResponse.success) {
        const newContactValue = {
          email: formatReturnedData(dataResponse.data.email),
          phoneNumber: dataResponse.data.phoneNumber,
          country:
            dataResponse.data.isoCode &&
            dataResponse.data.isoCode.toLowerCase(),
          countryCode: dataResponse.data.countryCode,
        }
        if (dataResponse.data.isoCode) {
          setCountryDefault(dataResponse.data.isoCode.toLowerCase() || 'us')
        }
        setContact(newContactValue)
        setOldContact(newContactValue)
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    }
  }
  const checkValidate = () => {
    let isValid = true

    const checkPhoneNumber = contact.phoneNumber.charAt(0)
    const formattedNumber = formatToNumber(contact.phoneNumber)

    if (contact.phoneNumber && formattedNumber.length < 10) {
      setErrorPhone('Please enter a valid phone number')
      isValid = false
    }
    if (checkPhoneNumber === '0') {
      setErrorPhone('Phone number does not exist')
      isValid = false
    }

    return isValid
  }
  const handleValidatePass = () => {
    let isValid = true
    const newStore = { ...store }
    const passwordTest = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/
    if (!passwordTest.test(store.newPassword) || store.newPassword.length < 8) {
      newStore.errorNew =
        'Please choose a stronger password: Minimum 8 digits, 1 number, 1 uppercase.'
      isValid = false
    } else if (store.confirmPassword !== store.newPassword) {
      newStore.errorConfirm = "Password confirmation doesn't match Password"
      isValid = false
    }
    setStore(newStore)
    return isValid
  }
  const handleGetCodeVerification = async (props) => {
    if (oldContact.email === contact.email) {
      try {
        const isAllowed = checkValidate()
        if (!isAllowed) {
          return
        }
        const bodyRequest = {
          email: contact.email,
          phoneNumber: contact.phoneNumber,
          countryCode: contact.countryCode,
          isoCode: contact.country.toUpperCase(),
        }
        setLoading(true)
        const { data: dataResponse } = await customAxios(token).put(
          `${apiRoute.Contact}`,
          bodyRequest
        )

        if (dataResponse.success) {
          context.message.success('Changes saved')
          setOldContact(contact)
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      try {
        const isAllowed = checkValidate()
        if (!isAllowed) {
          return
        }
        const bodyRequest = {
          toEmail: contact.email,
        }
        setLoading(true)
        const { data: dataResponse } = await customAxios(token).post(
          `${apiRoute.GetCodeVerification}`,
          bodyRequest
        )
        if (dataResponse.success) {
          setShowModalVerifyCode(true)
          if (props === 'Resend') {
            context.message.success('VerifiCation code is resent')
          }
        }
      } catch (err) {
        console.log(err)
        setErrorEmail('Email Address already exists.')
      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 3888)
      }
    }
  }

  const handleSaveChangeEmail = async () => {
    try {
      const isAllowed = checkValidate()
      if (!isAllowed) {
        return
      }
      setSpinner(true)
      const bodyRequest = {
        email: contact.email,
        phoneNumber: contact.phoneNumber,
        countryCode: contact.countryCode,
        isoCode: contact.country.toUpperCase(),
        code: code,
      }
      setLoading(true)
      const { data: dataResponse } = await customAxios(token).post(
        `${apiRoute.ConfirmCodeChangeEmail}`,
        bodyRequest
      )

      if (dataResponse.success) {
        setShowModalVerifyCode(false)
        setSpinner(false)
        removeCookieUtil('token')
        removeCookieUtil('accessToken')
        localStorage.clear()
        setShowModalConfirm(true)
      }
    } catch (err) {
      if (err) {
        context.message.error('Incorrect verification code. Try again.')
      }
    } finally {
      setTimeout(() => {
        setSpinner(false)
        setLoading(false)
      }, 1000)
    }
  }

  const handleCancelPopupVerifyCode = () => {
    setShowModalVerifyCode(false)
    setCode('')
    handleGetContact()
  }

  const handleSave = async () => {
    const isValid = handleValidatePass()
    if (isValid) {
      if (isLoading) {
        return
      }
      const bodyRequest = {
        previousPassword: store.oldPassword,
        password: store.newPassword,
        confirmPassword: store.confirmPassword,
        accessToken: cookies.accessToken,
      }
      try {
        setLoading(true)
        const { data: dataResponse } = await customAxios(token).post(
          `${apiRoute.changePassword}`,
          bodyRequest
        )
        if (dataResponse) {
          context.message.success('Changes saved')
          setStore({
            ...store,
            newPassword: '',
            oldPassword: '',
            confirmPassword: '',
            error: '',
          })
          setLoading(false)
        }
      } catch (err) {
        setLoading(false)
        const errorMessage = err.response.data.message
        if (checkMessageAuth(errorMessage)) {
          context.message.error('Failed to save. Try again.')
          removeCookie('token', { path: '/' })
        } else {
          setStore({ ...store, error: errorMessage })
        }
      }
    }
  }
  const handleChangeEmail = (e) => {
    setLoading(false)
    const { value } = e.target
    setContact({
      ...contact,
      email: value,
    })
    if (regex.emailRegex.test(value)) {
      setErrorEmail('')
    } else {
      setErrorEmail('Please enter a valid email address')
    }
  }

  const handleLogout = () => {
    removeCookie('token', { path: '/' })
    removeCookie('accessToken', { path: '/' })
    localStorage.clear()
    sessionStorage.clear()
    window.location = `${process.env.RAZZLE_APP_AUTH_DOMAIN_NAME}/logout?client_id=${process.env.RAZZLE_APP_CLIENT_ID}&logout_uri=${process.env.RAZZLE_APP_DOMAIN_NAME}`
  }

  useEffect(() => {
    const isCompareContact = isEqual(oldContact, contact)
    setIsDisabledBtn(isCompareContact)
  }, [oldContact, contact])

  return (
    <div>
      <div className={cls.container_contact}>
        <div className={cls.input_wrapper}>
          <div className={cls.contact_info}>Contact Info</div>
          <UikFormInputGroup className={cls.form_input_wrapper}>
            <label className={cls.label}>Email Address</label>
            <UikInput
              className={classnames('text-Black', cls.commonInput_email, {
                [cls.errorDetection]: errorEmail,
              })}
              id="email_address"
              name="email_address"
              type="text"
              value={contact.email}
              onChange={handleChangeEmail}
            />
            {errorEmail !== '' && (
              <div className="text-Red text-xs">{errorEmail}</div>
            )}
          </UikFormInputGroup>
          <UikFormInputGroup className={cls.form_input_wrapper}>
            <label className={cls.label}>Phone Number (Optional)</label>

            <PhoneInput
              buttonClass="buttonClass"
              // priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
              containerClass="containerClass"
              country={countryDefault || 'us'}
              countryCodeEditable={false}
              disableCountryGuess
              dropdownClass="dropdownClass"
              inputClass={classnames(
                'inputClass text-[14px] leading-[150%] rounded-[4px] h-[38px] focus:border-borderInput',
                {
                  [cls.errorDetection]: errorPhone !== '',
                }
              )}
              masks={{
                us: '...-...-....',
                mx: '..-....-....',
                ca: '...-...-....',
              }}
              onChange={(value, data) => {
                const newContact = {
                  ...contact,
                  phoneNumber: value.slice(data.dialCode.length),
                  country: data.countryCode,
                  countryCode: data.dialCode,
                }
                setContact(newContact)
                setLoading(false)
                setIsDisabledBtn(false)
                setErrorPhone('')
              }}
              onlyCountries={['us', 'mx', 'ca']}
              value={contact.countryCode + contact.phoneNumber}
            />
            {errorPhone && <p className={cls.errorMsg}>{errorPhone}</p>}
          </UikFormInputGroup>
          <Button
            onClick={() => handleGetCodeVerification('')}
            stateClass={
              isLoading || errorEmail || isDisabledBtn ? 'Disabled' : 'Primary'
            }
            width={61}
          >
            Save
          </Button>
        </div>
      </div>

      <div className={cls.container}>
        <div className={cls.input_wrapper}>
          <div className={cls.contact_info}>Password</div>
          <UikFormInputGroup className={cls.form_input_wrapper}>
            <label className={cls.label}>Current Password</label>
            <UikInput
              className={classnames(cls.commonInput, {
                [cls.errorDetection]: store.error !== '',
              })}
              id="old_password"
              name="old_password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={store.oldPasswordType}
              value={store.oldPassword.replace(/[\s]/gm, '')}
            />
            <ShowEye
              className={cls.toggle_password}
              onClick={handleToggle('old')}
            />
          </UikFormInputGroup>
          {store.error && <p className={cls.errorMsg}>{store.error}</p>}
          <UikFormInputGroup className={cls.form_input_wrapper}>
            <label className={cls.label}>New Password</label>
            <UikInput
              className={classnames(cls.commonInput, {
                [cls.errorDetection]: store.errorNew !== '',
              })}
              id="new_password"
              name="new_password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={store.newPasswordType}
              value={store.newPassword.replace(/[\s]/gm, '')}
            />
            <ShowEye
              className={cls.toggle_password}
              onClick={handleToggle('new')}
            />
          </UikFormInputGroup>
          {store.errorNew && <p className={cls.errorMsg}>{store.errorNew}</p>}
          <UikFormInputGroup className={cls.form_input_wrapper}>
            <label className={cls.label}>Confirm New Password</label>
            <UikInput
              className={classnames(cls.commonInput, {
                [cls.errorDetection]: store.errorConfirm !== '',
              })}
              id="confirm_password"
              name="confirm_password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={store.confirmPasswordType}
              value={store.confirmPassword.replace(/[\s]/gm, '')}
            />
            <ShowEye
              className={cls.toggle_password}
              onClick={handleToggle('confirm')}
            />
          </UikFormInputGroup>
          {store.errorConfirm && (
            <p className={cls.errorMsg}>{store.errorConfirm}</p>
          )}
          <Button
            onClick={store.disabledButton ? null : handleSave}
            stateClass={store.disabledButton ? 'Disabled' : 'Primary'}
            width={61}
          >
            Save
          </Button>
        </div>
      </div>

      <ReactModal
        open={showModalConfirm}
        headerText="Email Address Change"
        Content="You will be logged out. Please log in again."
        ButtonContent={
          <div className="flex justify-end">
            <Button stateClass={'Primary'} width={74} onClick={handleLogout}>
              Ok
            </Button>
          </div>
        }
      />

      <ReactModal
        open={showModalVerifyCode}
        headerText={
          <div>
            <div className="text-lg text-Black mb-12">Account Verification</div>
            <div className="text-sm text-Black">
              To continue, enter the 6-digit code we sent to {contact.email}
            </div>
          </div>
        }
        Content={
          <div className={cls.input_code}>
            <OtpInput
              inputStyle={cls.item_input}
              isInputNum
              numInputs={6}
              onChange={(value) => {
                setCode(value)
              }}
              separator={<span />}
              value={code}
            />
          </div>
        }
        subContent={
          <div>
            <span>Didn’t receive the code? </span>
            <span
              onClick={() => handleGetCodeVerification('Resend')}
              className="cursor-pointer text-Blue"
            >
              Resend it
            </span>
          </div>
        }
        ButtonContent={
          <div className="flex justify-between">
            <Button
              className="bg-LighterGray hover:text-black"
              onClick={handleCancelPopupVerifyCode}
              width={74}
            >
              Cancel
            </Button>

            <Button
              onClick={handleSaveChangeEmail}
              stateClass={code.length === 6 ? 'Primary' : 'Disabled'}
              width={74}
            >
              Confirm
            </Button>
          </div>
        }
      />
      <ModalConfirmArchiveOrininalDocs open={spinner} loading={spinner} />
    </div>
  )
}

export default withAuth(withContext(ChangePassword))
;((_data) => {})([React])
