import usePrevious from '@appComponent/usePrevious'
import apiRoute from '@constants/apiRoute'
import classnames from 'classnames'
import clsx from 'clsx'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'

import {
  customAxios,
  getCity,
  getShortNameState,
  getState,
  getStr,
  getZipcode,
  removeVietnameseTones,
} from '@helper'
import useOnclickOutside from 'react-cool-onclickoutside'
import cls from './place_auto_complete.scss'

export const PlacesAutoComplete = ({
  error,
  allowManual = false,
  updateFunctionOnChange,
  updateFunctionOnSelect,
  valueInputLocation,
  tabIndex,
  onlyUs = false,
  noCityStateZipcode = false,
  context,
  disabled,
}) => {
  const country = onlyUs ? ['us', 'ca'] : ['us', 'vn', 'ca']
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    cache: 86400,
    requestOptions: {
      componentRestrictions: { country },
    },
    debounce: 200,
  })
  const dataPrev = usePrevious(data)
  const [index, setIndex] = useState(-1)
  const [show, setShow] = useState(false)
  const [locationValueBackup, setLocationValueBackup] = useState({})
  const [initValueComplete, setInitValueComplete] = useState(false)

  const [cookies] = useCookies(['token'])
  const { token } = cookies

  const clearLocation = () => {
    if (allowManual) {
      return null
    }
    if (_.isEmpty(locationValueBackup)) {
      return
    }
    if (!locationValueBackup.placeId) {
      const loc = {
        city: '',
        address: '',
        latitude: '',
        longitude: '',
        placeId: '',
        state: '',
        shortNameState: '',
        name: '',
        zipcode: '',
        displayValue: '',
      }
      setLocationValueBackup(loc)
      updateFunctionOnSelect(loc)
      setValue('')
    }
  }

  const ref = useOnclickOutside((e) => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions()
    if (
      !getStr(e, 'target.className').includes('react-datepicker') &&
      !!value
    ) {
      clearLocation(e)
    }
  })

  const countGoogle = () => {
    customAxios(token).post(`${apiRoute.google.COUNT}`)
  }

  const handleInput = (e) => {
    // Update the keyword of the input element
    e.persist()
    const eventValue = e.target.value
    const locationValue = {
      city: '',
      address: '',
      latitude: '',
      longitude: '',
      placeId: '',
      state: '',
      shortNameState: '',
      name: '',
      zipcode: '',
      displayValue: eventValue,
    }

    setLocationValueBackup(locationValue)

    if (!eventValue) {
      updateFunctionOnChange(locationValue, true)
    } else {
      updateFunctionOnChange(locationValue, false)
    }

    setValue(eventValue)
    // customAxios(token).post(`${apiRoute.google.COUNT}`)
  }

  const handleSelect = (data) => () => {
    const { description } = data
    // setValue(description, false)
    const loc = {
      city: '',
      address: '',
      latitude: '',
      longitude: '',
      placeId: '',
      state: '',
      shortNameState: '',
      name: '',
      zipcode: '',
      displayValue: description,
    }
    setLocationValueBackup(loc)
    updateFunctionOnSelect(loc)
    clearSuggestions()
    getDetails({
      placeId: data.place_id,
    })
      .then((place) => {
        const address = getStr(place, 'formatted_address')
        let addressWithoutCity = address
        const index = address.indexOf(',')
        if (index !== -1) {
          addressWithoutCity = address.substr(0, index)
        }

        let addressAndName = ''
        if (noCityStateZipcode) {
          addressAndName = address.includes(place.name)
            ? addressWithoutCity
            : `${place.name}, ${addressWithoutCity}`
        } else {
          addressAndName = address.includes(place.name)
            ? address
            : `${place.name}, ${address}`
        }

        const locationValue = {
          city: getCity(place.address_components),
          address: place.formatted_address,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          placeId: place.place_id,
          state: getState(place.address_components),
          shortNameState: getShortNameState(place.address_components),
          name: place.name,
          zipcode: getZipcode(place.address_components),
          displayValue: addressAndName,
        }

        setValue(addressAndName, false)
        setLocationValueBackup(locationValue)
        updateFunctionOnSelect(locationValue)
        countGoogle(value)
      })
      .catch((error) => {
        const loc = {
          city: '',
          address: '',
          latitude: '',
          longitude: '',
          placeId: '',
          state: '',
          shortNameState: '',
          name: '',
          zipcode: '',
          displayValue: '',
        }
        context.message.error('Location not found')
        setValue('', false)
        setLocationValueBackup(loc)
        updateFunctionOnSelect(loc)
        console.log('Error: ', error)
      })

    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
  }

  const renderSuggestions = () => {
    const cache = JSON.parse(sessionStorage.getItem('upa'))
    if (cache && cache[value]) {
      // not call count google, unless the cache is expired
      const cacheExpiredTimestampt = cache[value].maxAge
      const currentTimestampt = +new Date()
      if (cacheExpiredTimestampt < currentTimestampt) {
        if (data !== dataPrev) {
          countGoogle(value)
        }
      }
    } else if (data !== dataPrev) {
      countGoogle(value)
    }
    return data.map((suggestion, i) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      const renderMatch = () => {
        const index = removeVietnameseTones(main_text).indexOf(
          removeVietnameseTones(value)
        )
        if (index !== -1) {
          const strMatch = main_text.substr(index, index + value.length)
          const strRemain = main_text.substr(index + value.length)
          return (
            <React.Fragment>
              <strong className={cls.strong}>{strMatch}</strong>
              <span className={cls.normal}>{strRemain}</span>
            </React.Fragment>
          )
        }
        return <span className={cls.normal}>{main_text}</span>
      }

      return (
        <div
          className={classnames(cls.result, {
            [cls.selected]: index === i,
          })}
          key={place_id}
          onClick={() => {
            handleSelect(suggestion)()
          }}
          style={{ maxWidth: '100%' }}
        >
          {renderMatch()} <small className={cls.small}>{secondary_text}</small>
        </div>
      )
    })
  }

  const showSuggestion = () => {
    setShow(true)
    setValue('')
    setTimeout(() => {
      setValue(value)
    }, 10)
  }

  useEffect(() => {
    if (!!valueInputLocation && initValueComplete === false) {
      setValue(valueInputLocation)
      setInitValueComplete(true)
    }
  }, [valueInputLocation])

  return (
    <div className={cls.wr_input_place_auto_complete} ref={ref}>
      <input
        className={clsx(cls.input_place_auto_complete, error ? cls.error : '')}
        disabled={(() => {
          if (disabled) {
            return true
          }

          return !ready
        })()}
        onBlur={() => {
          // if (!!data[index]) {
          //   handleSelect(data[index])()
          // }
          setIndex(-1)
        }}
        onChange={handleInput}
        onClick={() => {
          showSuggestion()
        }}
        onFocus={() => {
          showSuggestion()
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            const len = data.length
            if (len === 0) {
              return
            }
            const newIndex = index + 1
            if (data[newIndex]) {
              setIndex(newIndex)
            }
            e.preventDefault()
          } else if (e.key === 'ArrowUp') {
            const len = data.length
            if (len === 0) {
              return
            }
            if (index === 0) {
              setIndex(-1)
            } else {
              const newIndex = index - 1
              if (data[newIndex]) {
                setIndex(newIndex)
              }
            }
            e.preventDefault()
          } else if (e.key === 'Enter') {
            handleSelect(data[index])()
          } else if (e.key === 'Tab') {
            clearLocation(e)
            setShow(false)
          }
        }}
        tabIndex={tabIndex || '0'}
        type="text"
        value={valueInputLocation}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {show && status === 'OK' && (
        <div className={cls.wr_results} style={{ width: '100%' }}>
          {renderSuggestions()}
        </div>
      )}
    </div>
  )
}
;((_data) => {})([React])
