import React from 'react'
import SpinnerGif from './../../../driverDocAssets/loading2.gif'
import cls from './spinnericon.scss'

export const SpinnerIcon = () => {
  return (
    <div className={cls.wr_spinner}>
      <img alt="spinner" src={SpinnerGif} />
    </div>
  )
}
;((_data) => {})([React])
