import * as React from 'react'
import classnames from 'classnames'
import UikOutsideClickHandler from '../UikOutsideClickHandler'

import cls from './menuDrop.scss'
import DefaultDisplayComponent from './DefaultDisplayComponent'

// @flow
type UikDropdownProps = {
  className?: String,
  childClassName?: String,
  children: React.Node,
  DisplayComponent?: React.ElementType,
  position?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight',
  listProps?: Object,
  displayComponentProps?: Object,
}

export default class UikDropdown extends React.PureComponent<UikDropdownProps> {
  static defaultProps = {
    className: null,
    position: 'bottomLeft',
    DisplayComponent: DefaultDisplayComponent,
    listProps: {},
    displayComponentProps: {},
  }

  state = {
    focused: false,
  }

  handleToggleFocus = () => {
    const { focused, displayComponentProps } = this.state
    if (
      displayComponentProps &&
      typeof displayComponentProps.onClick === 'function'
    ) {
      displayComponentProps.onClick()
    }
    this.setState({ focused: !focused })
  }

  render() {
    const {
      children,
      className,
      childClassName,
      dropdownId,
      position,
      DisplayComponent,
      displayComponentProps: { onClick, ...otherDisplayComponentProps },
      listProps: { listClassName, ...otherListProps },
      ...rest
    } = this.props

    const { focused } = this.state

    return (
      <UikOutsideClickHandler
        className={className}
        onClick={this.handleToggleFocus}
        onOutsideClick={focused ? this.handleToggleFocus : null}
        onOutsideScroll={false}
        style={{ position: 'relative', display: 'inline-block' }}
        {...rest}
      >
        <DisplayComponent {...otherDisplayComponentProps} />
        {focused && (
          <div
            className={classnames(childClassName, cls.list, listClassName, {
              [cls[position]]: position,
            })}
            id={dropdownId}
            {...otherListProps}
          >
            {children}
          </div>
        )}
      </UikOutsideClickHandler>
    )
  }
}
;((_data) => {})([React])
