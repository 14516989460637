import _ from 'lodash'
import { useEffect, useState } from 'react'

const useRecentlyEdited = () => {
  const [listRecentlyEdittedIdDoc, setListRecentlyEdittedIdDoc] = useState([])
  useEffect(() => {
    if (!_.isEmpty(listRecentlyEdittedIdDoc)) {
      setTimeout(() => {
        setListRecentlyEdittedIdDoc([])
      }, 4000)
    }
  }, [listRecentlyEdittedIdDoc])
  return [listRecentlyEdittedIdDoc, setListRecentlyEdittedIdDoc]
}

export default useRecentlyEdited
