import { MyInputPhone } from '@appComponent/MyInputPhone'
import { Button } from '@appComponent/button'
import { Small } from '@appComponent/typography'
import { companyGroupsAtom } from '@atoms/atoms'
import { UikFormInputGroup, UikInput } from '@components'
import apiRoute from '@constants/apiRoute'
import { customAxios, specialCharacter } from '@helper'
import { withContext } from '@hoc'
import { getActiveGroups, getActiveVendors } from '@service'
import { checkValidEmail } from '@utils'
import { Drawer } from 'antd'
import classnames from 'classnames'
import { useAtom } from 'jotai'
import { size, toLower } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { currentStepOfFlow, runTour } from '../../../../../../atoms/atoms'
import { isLoggedIn } from '../../../../../../helper'
import cls from './add-driver.scss'
import { InputSearch, ItemSearch } from '@@/App/@components/input'

// @flow

function AddDriver({ refreshFn, showAdd, setShowAdd, onCancel, context }) {
  const initialErrors = {
    firstName: '',
    lastName: '',
    errorVendor: '',
    phone: '',
    email: '',
  }

  const [cookies] = useCookies(['token'])
  const { token } = cookies
  const [error, setError] = useState(initialErrors)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [isDispatching, setDispatch] = useState(false)
  // vendor list
  const [vendorChecked, setVendorChecked] = useState(null)
  const [vendor, setVendor] = useState('')
  const [choseIdVendor, setChoseIdVendor] = useState('')
  const [errorVendor, setErrorVendor] = useState('')
  const [close, setClose] = useState(false)
  const [companyGroups, setCompanyGroups] = useAtom(companyGroupsAtom)
  const [activeGroups, setActiveGroups] = useState([])
  const [activeVendors, setActiveVendors] = useState([])
  const [idGroup, setIdGroup] = useState(null)
  const [textsearchgroup, setTextsearchgroup] = useState('')
  const [loadinggroup, setLoadinggroup] = useState(false)
  const [errorGetActiveGroups, setErrorGetActiveGroups] = useState(null)
  const [errorGetActiveVendors, setErrorGetActiveVendors] = useState(null)
  const [loadingvendor, setLoadingvendor] = useState(false)
  const [run] = useAtom(runTour)
  const [, setStep] = useAtom(currentStepOfFlow)
  const [countryCode, setCountryCode] = useState('1')
  const [country, setCountry] = useState('us')
  const [loadingAdd, setLoadingAdd] = useState(false)
  const nameRef = useRef(null)

  useEffect(() => {
    if (showAdd === true) {
      if (run) {
        setVendorChecked('employee')
      }
      setTimeout(() => {
        const el = document.querySelectorAll('input#firstName')[0]
        if (el) {
          el.focus()
        }
      }, 200)

      getActiveGroups(token, setCompanyGroups)
    } else {
      resetFields()
    }
  }, [showAdd])

  useEffect(() => {
    const inputElement = document.getElementById('phoneNumber')
    if (inputElement) {
      inputElement.addEventListener('keydown', enforceFormat)
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  const setEmailLower = useCallback((value) => {
    setEmail(toLower(value))
  }, [])

  const handleKeyDown = () => {
    //
  }
  const handleChange = (e) => {
    e.persist()
    const eventValue = e.target.value
    switch (e.target.name) {
      case 'firstName': {
        if (error.firstName !== '') {
          setFirstName(eventValue.replace(specialCharacter(), ''))
          setError({ ...error, firstName: '' })
        } else {
          setFirstName(eventValue.replace(specialCharacter(), ''))
        }
        break
      }
      case 'lastName': {
        if (error.lastName !== '') {
          setLastName(eventValue.replace(specialCharacter(), ''))
          setError({ ...error, lastName: '' })
        } else {
          setLastName(eventValue.replace(specialCharacter(), ''))
        }
        break
      }
      case 'phone': {
        const regExPhoneNumber = /[0-9]{10}/g
        const regExRandom10 = /[^0-9\(\)\s\-]{1,}/g
        // prevent user from copying a group of string doesnt match the pattern of phonenumber only input
        if (regExRandom10.test(eventValue)) {
          setPhoneNumber('')
          break
        }

        // format 10 digits to the expected show format if user copy phonenumber ONLY NUMBER from other place
        if (regExPhoneNumber.test(eventValue)) {
          const valuePhoneNumber = `${eventValue.slice(
            0,
            3
          )}-${eventValue.slice(3, 6)}-${eventValue.slice(6, 10)}`
          setPhoneNumber(valuePhoneNumber)
          break
        }
        // normal case
        if (error.phone !== '') {
          setPhoneNumber(eventValue)
          setError({ ...error, phone: '' })
        } else {
          setPhoneNumber(eventValue)
        }
        break
      }
      case 'email': {
        if (error.email !== '') {
          setEmailLower(eventValue)
          setError({ ...error, email: '' })
        } else {
          setEmailLower(eventValue)
        }
        break
      }
      default:
        break
    }
  }
  const handleBlur = (e) => {
    switch (e.target.name) {
      case 'firstName': {
        const trimFirstName = firstName.trim()
        setFirstName(trimFirstName)
        break
      }
      case 'lastName': {
        const trimLastName = lastName.trim()
        setLastName(trimLastName)
        break
      }
      case 'email': {
        const trimEmail = email.trim()
        setEmailLower(trimEmail)
        break
      }
      default:
        break
    }
  }
  useEffect(() => {
    setError({ ...error, phone: '' })
  }, [phoneNumber])
  const handleValidate = () => {
    let isValid = true
    const newError = { ...error }
    const emailTestRegex = checkValidEmail(email)
    if (firstName === '') {
      newError.firstName = "First name can't be blank"
      isValid = false
    }
    if (lastName === '') {
      newError.lastName = "Last Name can't be blank"
      isValid = false
    }
    if (phoneNumber === '') {
      newError.phone = "Phone number can't be blank"
      isValid = false
    }
    if (phoneNumber.length > 1 && phoneNumber.length < 10) {
      newError.phone = 'Please enter a valid phone number'
      isValid = false
    } else if (phoneNumber.charAt(0) === '0') {
      newError.phone = 'Phone number does not exist'
      isValid = false
    }
    // if (email === '') {
    //   newError.email = "Email address can't be blank"
    //   isValid = false
    // }
    if (vendorChecked === 'vendor' && (errorVendor || choseIdVendor === '')) {
      newError.errorVendor = "Vendor can't be blank"

      setErrorVendor('error')
      isValid = false
    }
    if (!emailTestRegex && email !== '') {
      newError.email = 'Please enter a valid email address'
      isValid = false
    }
    setError(newError)
    return isValid
  }
  const handleAdd = async () => {
    const isValid = handleValidate()

    if (isValid && !isDispatching) {
      const bodyRequest = {
        firstName,
        lastName,
        idVendor: vendorChecked === 'vendor' ? `${choseIdVendor}` : '',
        phoneNumber: phoneNumber.replace(/[\(\)\s\-]/gm, ''),
        isoCode: country.toUpperCase(),
        countryCode,
        email,
        idGroup: idGroup ? Number(idGroup) : '',
      }

      try {
        setLoadingAdd(true)

        setDispatch(true)
        const { data: dataResponse } = await customAxios(token).post(
          `${apiRoute.drivers.ADD_DRIVER}`,
          bodyRequest
        )
        if (dataResponse.success) {
          setDispatch(false)
          refreshFn()
          if (!run) {
            context.message.success('Added Successfully')
          }
          setCountry('us')
          setCountryCode('1')
          setShowAdd(false)
          setStep((pre) => pre + 1)
        }
      } catch (err) {
        setDispatch(false)
        const errorMessage = err.response.data.messages
        context.message.error(errorMessage)
        setError({
          ...error,
          email: 'Please enter a valid email address',
        })
      } finally {
        setTimeout(() => {
          setLoadingAdd(false)
        }, 2000)
      }
    }
  }

  const handleCancel = () => {
    setClose(true)
    onCancel()
    setCountry('us')
    setCountryCode('1')
  }
  const resetFields = () => {
    setIdGroup(null)
    setTextsearchgroup('')
    setFirstName('')
    setLastName('')
    setPhoneNumber('')
    setEmailLower('')
    setVendor('')
    setVendorChecked(null)
    setError(initialErrors)
  }
  const isNumericInput = (event) => {
    const key = event.keyCode
    return (
      (key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    )
  }

  const isModifierKey = (event) => {
    const key = event.keyCode
    return (
      event.shiftKey === true ||
      key === 35 ||
      key === 36 || // Allow Shift, Home, End
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 46 || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      // Allow Ctrl/Command + A,C,V,X,Z
      ((event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    )
  }

  const enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!isNumericInput(event) && !isModifierKey(event)) {
      event.preventDefault()
    }
  }

  const disableVerification = useMemo(() => {
    if (
      firstName === '' ||
      lastName === '' ||
      phoneNumber === '' ||
      (vendorChecked === 'vendor' && choseIdVendor === '') ||
      vendorChecked === null ||
      (vendor === '' && vendorChecked === 'vendor')
    ) {
      return true
    }
    return false
  }, [firstName, lastName, phoneNumber, vendorChecked, choseIdVendor, vendor])
  const handleCheckRadio = (e) => {
    const { name } = e.target
    setVendorChecked(name)
  }

  const renderSelectGroup = () => {
    if (size(companyGroups) <= 0) {
      return null
    }
    return (
      <div className={classnames(cls.form_group, 'driver_group_select ')}>
        <label htmlFor="inputupload_document_file">
          <Small>Group (Optional)</Small>
        </label>
        <InputSearch
          error={errorGetActiveGroups}
          getListFn={getActiveGroups.bind(
            null,
            token,
            setActiveGroups,
            setLoadinggroup,
            setErrorGetActiveGroups
          )}
          idData={idGroup || ''}
          idField="id_group"
          list={activeGroups}
          loadingData={loadinggroup}
          nameField="name"
          noDataText="No group"
          setIdData={setIdGroup}
          setValue={setTextsearchgroup}
          showPopup={showAdd}
          value={textsearchgroup}
        >
          {({
            item,
            o,
            index,
            setIdData,
            setShow,
            setValue,
            idField,
            nameField,
          }) => (
            <ItemSearch
              idField={idField}
              index={index}
              item={item}
              nameField={nameField}
              o={o}
              setIdData={setIdData}
              setShow={setShow}
              setValue={setValue}
            />
          )}
        </InputSearch>
      </div>
    )
  }

  const renderSelectVendor = () => (
    <div className={classnames(cls.form_group, 'driver_group_select')}>
      <label htmlFor="inputupload_document_file">
        <Small>Vendor Name</Small>
      </label>
      <InputSearch
        error={errorGetActiveVendors}
        getListFn={getActiveVendors.bind(
          null,
          token,
          setActiveVendors,
          setLoadingvendor,
          setErrorGetActiveVendors
        )}
        idData={choseIdVendor}
        idField="idVendor"
        list={activeVendors}
        loadingData={loadingvendor}
        nameField="name"
        noDataText="No vendor"
        setIdData={setChoseIdVendor}
        setValue={setVendor}
        showPopup={showAdd}
        value={vendor}
      >
        {({
          item,
          o,
          index,
          setIdData,
          setShow,
          setValue,
          idField,
          nameField,
        }) => (
          <ItemSearch
            idField={idField}
            index={index}
            item={item}
            nameField={nameField}
            o={o}
            setIdData={setIdData}
            setShow={setShow}
            setValue={setValue}
          />
        )}
      </InputSearch>
    </div>
  )

  return (
    <Drawer
      className={classnames(
        'wr_drawer_add2 ',
        isLoggedIn() && 'demo__projects3'
      )}
      destroyOnClose
      footer={
        <div className="wr_footer_add_load ">
          <Button
            id="cancelDriver"
            onClick={handleCancel}
            stateClass={run ? 'Disabled' : 'Secondary'}
            width={74}
          >
            Cancel
          </Button>

          <Button
            id="addDriver"
            onClick={handleAdd}
            stateClass={
              disableVerification || loadingAdd ? 'Disabled' : 'Primary'
            }
            width={58}
          >
            Add
          </Button>
        </div>
      }
      maskClosable={false}
      onClose={handleCancel}
      title="Add Driver"
      visible={showAdd}
      width={368}
    >
      <div
        className={classnames([cls.content], { [cls.close]: close === true })}
      >
        <form className={cls.form_add_driver}>
          <UikFormInputGroup style={{ margin: '0 0 8px 0' }}>
            <label className={cls.label} htmlFor="input">
              First Name
            </label>
            <UikInput
              autoComplete="off"
              className={classnames(cls.input_form, {
                [cls.errorDetection]: error.firstName !== '',
              })}
              id="firstName"
              maxLength="20"
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              ref={nameRef}
              type="text"
              value={firstName}
            />
            {error.firstName && (
              <p className={cls.errorMsg}>{error.firstName}</p>
            )}
          </UikFormInputGroup>
          <UikFormInputGroup style={{ margin: '0 0 8px 0' }}>
            <label className={cls.label} htmlFor="input">
              Last Name
            </label>
            <UikInput
              autoComplete="off"
              className={classnames(cls.input_form, {
                [cls.errorDetection]: error.lastName !== '',
              })}
              id="lastName"
              maxLength="20"
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={lastName}
            />
            {error.lastName && <p className={cls.errorMsg}>{error.lastName}</p>}
          </UikFormInputGroup>
          <UikFormInputGroup className={cls.radioContainer}>
            <span className={cls.spanRadioEmployee}>
              <input
                checked={vendorChecked === 'employee'}
                className={cls.employee}
                id="radioEmployee"
                name="employee"
                onChange={handleCheckRadio}
                type="radio"
              />
              <label className={cls.employeeLabel} htmlFor="radioEmployee">
                Employee/Owner Operator
              </label>
            </span>

            <span className={cls.spanRadioVendor}>
              <input
                checked={vendorChecked === 'vendor'}
                className={cls.vendor}
                disabled={run}
                id="radioVendor"
                name="vendor"
                onChange={handleCheckRadio}
                type="radio"
              />
              <label
                className={classnames(cls.vendorLabel, run && 'text-Gray')}
                htmlFor="radioVendor"
              >
                Vendor
              </label>
            </span>
          </UikFormInputGroup>
          {vendorChecked === 'vendor' && (
            <React.Fragment>{renderSelectVendor()}</React.Fragment>
          )}
          <UikFormInputGroup style={{ margin: '0 0 8px 0' }}>
            <label className={cls.label} htmlFor="inputphone_adddriver">
              Mobile Phone Number
            </label>
            {/* <UikInput
              autoComplete="off"
              className={classnames(cls.input_form, {
                [cls.errorDetection]: error.phone !== '',
              })}
              id="phoneNumber"
              maxLength="14"
              name="phone"
              onChange={handleChange}
              type="tel"
              value={phoneNumber}
            /> */}

            <MyInputPhone
              className={classnames('w-[320px]', {
                [cls.errorDetection]: error.phone !== '',
              })}
              containerClass="inputphone_adddriver"
              countryDefault={country || 'us'}
              id="inputphone_adddriver"
              onChange={(value, data) => {
                setCountry(data.countryCode)
                setCountryCode(data.dialCode)
                setPhoneNumber(value.slice(data.dialCode.length))
              }}
              value={`${countryCode}${phoneNumber}`}
            />
            {error.phone && <p className={cls.errorMsg}>{error.phone}</p>}
          </UikFormInputGroup>

          <UikFormInputGroup style={{ margin: '0 0 8px 0' }}>
            <label className={cls.label} htmlFor="input">
              E-mail Address (Optional)
            </label>
            <UikInput
              autoComplete="off"
              className={classnames(cls.input_form, {
                [cls.errorDetection]: error.email !== '',
              })}
              id="email"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={email}
            />
            {error.email && <p className={cls.errorMsg}>{error.email}</p>}
          </UikFormInputGroup>
          {renderSelectGroup()}
        </form>
      </div>
    </Drawer>
  )
}

export default withContext(AddDriver)
;((_data) => {})([React])
