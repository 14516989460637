import MyPagination from '@appComponent/MyPagination'
import MyTooltip from '@appComponent/MyTooltip'
import { StatusButton } from '@appComponent/StatusButton'
import { Button } from '@appComponent/button'
import {
  ActiveIconSquare,
  ArrowSort,
  DeactivateIconSquare,
  DeleteLoadIcon,
  DeleteSearch,
  DotsComponent,
  IconEdit2,
  IconSearchLoad,
  ResendIconSquare,
} from '@appComponent/icon'
import Modal from '@appComponent/modalPortal'
import { SpinnerIcon } from '@appComponent/spinnericon'
import { Caption, Small } from '@appComponent/typography'
import usePrevious from '@appComponent/usePrevious'
import withAuth from '@auth'
import {
  UikDropdown,
  UikDropdownItem,
  UikInput,
  UikTopBar,
  UikTopBarSection,
  UikTopBarTitle,
} from '@components'
import { SORT_ORDER } from '@constants'
import apiRoute from '@constants/apiRoute'
import {
  checkMessageAuth,
  customAxios,
  formatPhoneNumber,
  formatRawData,
  getErrorMessage,
  getStr,
  renderListLink,
  renderSingleLink,
} from '@helper'
import { withContext } from '@hoc'
import { useRecentlyEdited, useScreenWidth } from '@hooks'
import classnames from 'classnames'
import clsx from 'clsx'
import dayjs from 'dayjs'
import produce from 'immer'
import { useAtom } from 'jotai'
import _, { isEmpty } from 'lodash'
import queryString from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useIdleTimer } from 'react-idle-timer'
import useSWR from 'swr'
import { useDebounce } from 'use-debounce'
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'

import {
  currentStepOfFlow,
  openAddDriver,
  runTour,
} from '../../../../atoms/atoms'
import {
  IDLE_CHECK_INTERVAL,
  IDLE_TIME,
  PAGINATION_PAGE_SIZE_DEFAULT,
} from '../../../../config'
import { ButtonPopoverPlus } from '../../../@components/PopoverPlus'
import { PeoplePlusIcon, TwoPeopleIcon } from '../../../@components/icon'
import AddDriver from './components/addDriver'
import BulkAddDriver from './components/bulkAddDriver'
import EditDriver from './components/editDriver'
import cls from './drivers.scss'
import IconUnlock from '../../../svg/IconUnlock'
import { DriverStatusEnum } from '../../../../constants'

const sortKeywords = {
  status: 'status',
  fullName: 'fullName',
  phoneNumber: 'phoneNumber',
  carrierName: 'carrierName',
  stopsCount: 'stopsCount',
  onTime: 'onTime',
  totalDocuments: 'totalDocuments',
  documentsPercent: 'documentsPercent',
}

const DEFAULT_SORT = 'fullName'

const fetcher = async (url, token) => {
  if (!url) return
  const data = await customAxios(token).get(url)
  if (data.status === 200) {
    return data.data.data
  }
  return { error: true }
}

const Drivers = ({ context }) => {
  // token
  const { screenWidth } = useScreenWidth()
  const user = JSON.parse(localStorage.getItem('user'))
  const documentFlag = _.get(user, 'document')
  const smartetaFlag = _.get(user, 'smarteta')
  const [listRecentlyEdittedIdDoc, setListRecentlyEdittedIdDoc] =
    useRecentlyEdited()
  const [cookies, removeCookie] = useCookies(['token'])
  const { token } = cookies
  const company = localStorage.getItem('company_name')

  // show list driver
  const [listDriver, setListDriver] = useState([])
  const [showDeleteDriver, setShowDeleteDriver] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [chosenDeletedDriverId, setChosenDeletedDriverId] = useState('')
  // driver chosen resend
  // info for edit
  const [infoDriver, setInfoDriver] = useState({})

  // loading
  const [resendLoading, setResendLoading] = useState(false)

  // modal
  const [showAdd, setShowAdd] = useState(false)
  const [showBulkAdd, setShowBulkAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)

  const [type, setType] = useQueryParam(
    'type',
    withDefault(StringParam, 'all') // all || employee || vendor
  )
  const [openAdd] = useAtom(openAddDriver)
  const typePrev = usePrevious(type)

  const [countDriversData, setCountDriversData] = useState({
    activeDrivers: 0,
    activeEmployeeDrivers: 0,
    activeVendorDrivers: 0,
    countDrivers: 0,
    totalEmployeeDrivers: 0,
    totalVendorDrivers: 0,
  })
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1))

  const [pageSize, setPageSize] = useQueryParam(
    'size',
    withDefault(NumberParam, PAGINATION_PAGE_SIZE_DEFAULT)
  )

  const pageSizePrev = usePrevious(pageSize)
  const [textload, setTextload] = useQueryParam(
    'search',
    withDefault(StringParam, undefined)
  )

  const [textloadDebounce] = useDebounce(textload, 300)
  const textloadDebouncePrev = usePrevious(textloadDebounce)

  const [lastSortInfo, setLastSortInfo] = useQueryParam(
    'sort',
    withDefault(StringParam, DEFAULT_SORT)
  )
  const lastSortInfoPrev = usePrevious(lastSortInfo)
  const [total, setTotal] = useState(0)

  const [time_request, set_time_request] = useState(
    dayjs().format('YYYY-MM-DDTHH:mm:ss')
  )
  useEffect(() => {
    if (openAdd === 'bulk') {
      setShowBulkAdd(true)
    } else if (openAdd === 'single') {
      setShowAdd(true)
    }
  }, [openAdd])
  const [triggerFetch, setTriggerFetch] = useState(0)

  const [responseDataDriver, setResponseDataDriver] = useState({})
  // sort value
  const [sortList, setSortList] = useState({
    status: '',
    fullName: '',
    phoneNumber: '',
    carrierName: '',
    stopsCount: '',
    onTime: '',
    totalDocuments: '',
    documentsPercent: '',
  })
  const [, setStep] = useAtom(currentStepOfFlow)
  const [urlfetch, setUrlfetch] = useState('')
  const [run] = useAtom(runTour)
  const { data, error } = useSWR(urlfetch, fetcher, {
    revalidateOnFocus: false,
  })

  const loadingDrivers = useMemo(() => !data && !error, [data, error])

  const handleChangePageSize = (value) => {
    setPageSize(value)
  }

  /* react idle timer */
  const { reset } = useIdleTimer({
    timeout: IDLE_TIME,
    onIdle: handleOnIdle,
    debounce: IDLE_CHECK_INTERVAL,
  })
  const handleOnIdle = () => {
    reset()
    set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
  }
  /* react idle timer end */

  const handleDeleteSearch = () => {
    setTextload(undefined)
  }

  // delete driver
  const handleCloseDeleteDriver = () => {
    setShowDeleteDriver(false)
  }
  const handleShowDeleteDriver = (idDriver) => () => {
    setShowDeleteDriver(true)
    setChosenDeletedDriverId(idDriver)
  }

  const handleUnlockDriver = (idDriver) => async () => {
    if (!idDriver) return

    try {
      const { data: responseData } = await customAxios(token).put(
        `${apiRoute.drivers.UNLOCK_DRIVER}/${idDriver}`
      )
      if (responseData && responseData.success) {
        refreshListDriver('Unlocked successfully')
      }
    } catch (error) {
      console.log('errorUnlockDriver', error)
    }
  }

  const handleClick = () => {
    setStep((pre) => pre + 1)
  }
  const handleShowAddDriver = () => {
    handleClick()
    setShowAdd(true)
  }

  const handleShowBulkAddDriver = () => {
    handleClick()
    setShowBulkAdd(true)
  }

  const handleCancel = () => {
    setTimeout(() => {
      setShowAdd(false)
    }, 0)
  }

  const handleClickUpdate = (driver) => () => {
    setShowEdit(true)
    setInfoDriver(driver)
  }

  const handleGetByIdDriver = (driver) => async () => {
    try {
      const resp = await customAxios(token).get(
        `${apiRoute.drivers.GET_DRIVER_BY_ID}/${driver.idDriver}`
      )
      setResponseDataDriver(resp)
    } catch (error) {
      console.log('error GetByIdDriver', error)
    }
  }

  const handleCloseModal = () => {
    setTimeout(() => {
      setShowEdit(false)
    }, 500)
  }

  const refreshListDriver = (messageCallback) => {
    set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
    context.message.success(messageCallback)
  }
  const handleChangeInputSearch = (e) => {
    setTextload(e.target.value || undefined)
  }
  // resend invitation
  const handleResendInvitation = (idDriver) => async () => {
    try {
      setResendLoading(true)
      const { data: dataResponse } = await customAxios(token).post(
        `${apiRoute.drivers.RESEND}/${idDriver}`
      )
      if (dataResponse.success) {
        setResendLoading(false)
        context.message.success('Resent Successfully')
      }
    } catch (err) {
      setResendLoading(false)
      context.message.error(getErrorMessage(err))
    }
  }

  // sorting
  const handleSorting = (sortValue) => {
    const lastSortCriteria = lastSortInfo.replace('-', '')
    let lastSortInfoClone = lastSortInfo
    if (sortValue !== lastSortCriteria) {
      lastSortInfoClone =
        sortList[sortValue] === 'ASC' ? `-${sortValue}` : sortValue
    } else if (lastSortInfoClone[0] === '-') {
      lastSortInfoClone = lastSortInfoClone.replace('-', '')
    } else {
      lastSortInfoClone = `-${lastSortInfoClone}`
    }
    setLastSortInfo(lastSortInfoClone)
    const target = sortList[sortValue] === 'ASC' ? 'DESC' : 'ASC'
    setSortList({ ...sortList, [sortValue]: target })
  }

  // remove driver
  const handleRemoveDriver = async () => {
    try {
      setIsDeleting(true)
      const { data: responseData } = await customAxios(token).delete(
        `${apiRoute.drivers.DELETE_DRIVER}/${chosenDeletedDriverId}`
      )
      if (responseData.success) {
        setShowDeleteDriver(false)
        refreshListDriver('Removed Successfully')
        setTimeout(() => {
          if (listDriver.length === 1) {
            window.location.reload()
          }
        }, 1000)
      }
    } catch (err) {
      setShowDeleteDriver(false)
      context.message.error(getErrorMessage(err))
    } finally {
      setIsDeleting(false)
    }
  }

  // change status driver
  const handleChangeStatusDriver = (driver) => async () => {
    let popupMessage = ''
    if (driver.status === DriverStatusEnum.ACTIVE) {
      popupMessage = 'Deactivated'
    } else if (driver.status === DriverStatusEnum.INACTIVE) {
      popupMessage = 'Activated'
    }

    const bodyRequest = {
      firstName: responseDataDriver.data.data[0].first_name,
      lastName: responseDataDriver.data.data[0].last_name,
      phoneNumber: driver.phoneNumber,
      isoCode: responseDataDriver.data.data[0].iso_code,
      countryCode: driver.countryCode,
      email: driver.email,
      status:
        driver.status === DriverStatusEnum.PENDING ||
        driver.status === DriverStatusEnum.ACTIVE
          ? DriverStatusEnum.INACTIVE
          : DriverStatusEnum.ACTIVE,
      idVendor: _.get(driver, 'vendors.idVendor')
        ? String(_.get(driver, 'vendors.idVendor'))
        : '',
    }
    try {
      const { data: responseData } = await customAxios(token).put(
        `${apiRoute.drivers.EDIT_DRIVER}/${driver.idDriver}`,
        bodyRequest
      )
      if (responseData.success) {
        refreshListDriver(`${popupMessage} Successfully`)
      }
    } catch (err) {
      const errorMessage = err.response.data.messages
      if (checkMessageAuth(errorMessage)) {
        context.message.error('Please Login Again!')
        removeCookie('token', { path: '/' })
      } else {
        context.message.error(`${popupMessage} Fail`)
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const key = lastSortInfo.replace('-', '')
      const value = lastSortInfo[0] === '-' ? 'DESC' : 'ASC'
      const nextState = produce(sortList, (draftState) => {
        _.set(draftState, `${key}`, value)
        return draftState
      })
      setSortList(nextState)
    }, 100)
  }, [])
  useEffect(() => {
    const params = {}

    params.sort = lastSortInfo
    params.limit = pageSize

    if (textloadDebounce) {
      params.search = textloadDebounce
    }
    params.type = type
    params.page = page

    if (
      (lastSortInfo !== lastSortInfoPrev && lastSortInfoPrev !== undefined) ||
      (pageSizePrev !== pageSize && pageSizePrev !== undefined) ||
      textloadDebounce !== textloadDebouncePrev ||
      (type !== typePrev && typePrev !== undefined)
    ) {
      params.page = 1
      setPage(undefined)
    }

    params.time_request = time_request
    const url = `${apiRoute.drivers.BASE_DRIVER}?${queryString.stringify(
      params
    )}`
    setUrlfetch(url)

    if (
      lastSortInfo === DEFAULT_SORT &&
      page === 1 &&
      pageSize === PAGINATION_PAGE_SIZE_DEFAULT &&
      textloadDebounce === undefined
    ) {
      // when click on icon loads page on the left
      // setCurrentId(null)
      // set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
    }
  }, [page, textloadDebounce, pageSize, lastSortInfo, time_request, type])

  useEffect(() => {
    function handleData(data_) {
      setListDriver(_.get(data_, 'drivers') || [])

      if (type === 'all') {
        setTotal(data_.countDrivers)
      } else if (type === 'employee') {
        setTotal(data_.totalEmployeeDrivers)
      } else if (type === 'vendor') {
        setTotal(data_.totalVendorDrivers)
      }
      const { ...y } = data
      setCountDriversData(y)
      // setListDocument(_.get(data_, 'documents') || [])
    }
    if (error === undefined && !!data) {
      handleData(data)
    }
  }, [data, error])

  const renderTitle = () => (
    <UikTopBarTitle className="page_title loads_page_title">
      Drivers
    </UikTopBarTitle>
  )

  const renderLinkState = () => (
    <div className="loads_wr_filters">
      <div className="bg-LightestGray flex h-12 items-center rounded pl-6">
        <div
          className="loads_filter_item"
          onClick={() => {
            setType(undefined)
          }}
        >
          <span
            className={classnames('loads_filter_name fs18 lh27', {
              loads_filter_name_active: type === 'all' || type === undefined,
            })}
          >
            All
          </span>
          <MyTooltip
            classNameNormal="loads_filter_count"
            classNameTooltip="w-[136px]"
            contentNormal={
              <Caption gray>
                {`${countDriversData.activeDrivers}/${countDriversData.countDrivers}`}
              </Caption>
            }
            contentTooltip="Active & Pending/Total"
            tooltipId="all_driver_active"
          />
        </div>
        <div
          className="loads_filter_item"
          onClick={() => {
            setType('employee')
          }}
        >
          <span
            className={classnames('loads_filter_name fs18 lh27', {
              loads_filter_name_active:
                type === 'employee' || type === undefined,
            })}
          >
            Employee Drivers
          </span>
          <MyTooltip
            classNameNormal="loads_filter_count"
            classNameTooltip=" w-[136px]"
            contentNormal={
              <Caption gray>
                {`${countDriversData.activeEmployeeDrivers}/${countDriversData.totalEmployeeDrivers}`}
              </Caption>
            }
            contentTooltip="Active & Pending/Total"
            tooltipId="employee_driver_active"
          />
        </div>
        <div
          className="loads_filter_item"
          onClick={() => {
            setType('vendor')
          }}
        >
          <span
            className={classnames('loads_filter_name fs18 lh27', {
              loads_filter_name_active: type === 'vendor' || type === undefined,
            })}
          >
            Vendor Drivers
          </span>
          <MyTooltip
            classNameNormal="loads_filter_count"
            classNameTooltip="w-[136px]"
            contentNormal={
              <Caption gray>
                {`${countDriversData.activeVendorDrivers}/${countDriversData.totalVendorDrivers}`}
              </Caption>
            }
            contentTooltip="Active & Pending/Total"
            tooltipId="vendor_driver_active"
          />
        </div>
      </div>
    </div>
  )

  const renderDateSearchButton = () => (
    <div className={cls.drivers_interactive}>
      <div className={cls.search_wrapper}>
        <UikInput
          className={cls.search_drivers}
          iconPosition="right"
          id="search-drivers"
          onChange={handleChangeInputSearch}
          value={textload || ''}
        />
        {textload ? (
          <DeleteSearch onClick={handleDeleteSearch} />
        ) : (
          <span className={cls.iconSearch}>
            <IconSearchLoad />
          </span>
        )}
      </div>

      {/* <Button
        className={classnames('ml8', isLoggedIn() && 'demo__projects2')}
        havePrefixIcon
        onClick={handleShowAddDriver}
        stateClass="Primary"
        width={126}
      >
        Add Driver
      </Button> */}
      {run ? (
        <div
          className={clsx(
            'demo__projects2 w-[38px] h-[38px] transform translate-y-[-5px]'
          )}
          style={{
            borderRadius: '3.6px',
          }}
        >
          {/* line white horizontal */}
          <div className="relative w-[38px] h-[38px] rounded-full bg-DarkerBlue cursor-pointer hover:shadow-md hover:bg-DarkBlue">
            <div
              className=" items-center justify-center absolute inset-0 "
              style={{
                display: 'flex',
              }}
            >
              <div className="bg-white h-[3px] w-[23px] rounded-[4px] " />
            </div>

            {/* line white vertical */}
            <div
              className=" items-center justify-center absolute inset-0 "
              style={{
                display: 'flex',
              }}
            >
              <div className="bg-white h-[3px] w-[23px] rounded-[4px] transform rotate-90 " />
            </div>
          </div>
        </div>
      ) : (
        <ButtonPopoverPlus
          listItems={[
            {
              title: 'Add individual driver',
              icon: <PeoplePlusIcon />,

              onClick: handleShowAddDriver,
            },
            {
              title: 'Bulk add drivers',
              icon: <TwoPeopleIcon />,
              onClick: handleShowBulkAddDriver,
            },
          ]}
        />
      )}
    </div>
  )

  return (
    <React.Fragment>
      {/*  */}
      {screenWidth > 1500 ? (
        <div
          className={classnames(
            cls.headContainer,
            'wr_page_title wr_page_title_loads items-center'
          )}
        >
          {renderTitle()}
          {renderLinkState()}
          {renderDateSearchButton()}
        </div>
      ) : (
        <React.Fragment>
          <div
            className={classnames(
              cls.headContainer,
              'wr_page_title wr_page_title_loads'
            )}
          >
            {renderTitle()}
            {renderDateSearchButton()}
          </div>
          <div className="mb24">{renderLinkState()}</div>
        </React.Fragment>
      )}
      {/*  */}

      {/* // here table tailwind */}
      {!run && (
        <div className="w-full min-w-[1400px] ">
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr className=" bg-LightestGray">
                {/* status */}
                <th
                  className={clsx(
                    'w-[100px] 2xl:w-[126px] pl-[16px] fullhd:pl-[26px] pr-0 cursor-pointer pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%]  ',
                    ` ${
                      lastSortInfo.replace('-', '') === 'status'
                        ? 'text-Primary'
                        : 'text-DarkGray'
                    }  `
                  )}
                  onClick={() => {
                    // handleSorting(sortKeywords.name)
                    handleSorting('status', 'string')
                  }}
                >
                  <span className=" ">status</span>
                  <span className="w-1" />
                  <ArrowSort
                    className={clsx(
                      ' duration-300 transform ',
                      {
                        'rotate-180': sortList.status === SORT_ORDER.ASC,
                        'rotate-0': sortList.status === SORT_ORDER.DESC,
                      },
                      `${
                        lastSortInfo.replace('-', '') === 'status'
                          ? 'fill-Primary'
                          : 'fill-DarkGray'
                      }`
                    )}
                  />
                </th>

                {/* name */}
                <th
                  className={clsx(
                    'w-[250px] 2xl:w-[250px] fullhd:w-[270px] 3xl:w-[270px]  whitespace-nowrap pl-[16px] fullhd:pl-0 pr-0 cursor-pointer pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%]  ',
                    ` ${
                      lastSortInfo.replace('-', '') === 'fullName'
                        ? 'text-Primary'
                        : 'text-DarkGray'
                    }  `
                  )}
                  onClick={() => handleSorting('fullName', 'string')}
                >
                  <span className=" ">name</span>
                  <span className="w-1" />
                  <ArrowSort
                    className={clsx(
                      ' duration-300 transform ',
                      {
                        'rotate-180': sortList.fullName === SORT_ORDER.ASC,
                        'rotate-0': sortList.fullName === SORT_ORDER.DESC,
                      },
                      `${
                        lastSortInfo.replace('-', '') === 'fullName'
                          ? 'fill-Primary'
                          : 'fill-DarkGray'
                      }`
                    )}
                  />
                </th>

                {/* carrier */}
                <th
                  className={clsx(
                    'w-[190px] 2xl:w-[190px] fullhd:w-[236px] 3xl:w-[236px]   whitespace-nowrap pl-[16px] fullhd:pl-[26px] pr-0 cursor-pointer pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%]  ',
                    ` ${
                      lastSortInfo.replace('-', '') === 'carrierName'
                        ? 'text-Primary'
                        : 'text-DarkGray'
                    }  `
                  )}
                  onClick={() => {
                    handleSorting(sortKeywords.carrierName, 'string')
                  }}
                >
                  <span className=" ">carrier</span>
                  <span className="w-1" />
                  <ArrowSort
                    className={clsx(
                      ' duration-300 transform ',
                      {
                        'rotate-180': sortList.carrierName === SORT_ORDER.ASC,
                        'rotate-0': sortList.carrierName === SORT_ORDER.DESC,
                      },
                      `${
                        lastSortInfo.replace('-', '') === 'carrierName'
                          ? 'fill-Primary'
                          : 'fill-DarkGray'
                      }`
                    )}
                  />
                </th>

                {/* stops */}
                {smartetaFlag === 'true' && (
                  <th
                    className={clsx(
                      'w-[115px] whitespace-nowrap pl-[16px] fullhd:pl-[26px] pr-0 cursor-pointer pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%]  ',
                      ` ${
                        lastSortInfo.replace('-', '') === 'stopsCount'
                          ? 'text-Primary'
                          : 'text-DarkGray'
                      }  `
                    )}
                    onClick={() => {
                      handleSorting(sortKeywords.stopsCount, 'number')
                    }}
                  >
                    <span className=" ">stops</span>
                    <span className="w-1" />
                    <ArrowSort
                      className={clsx(
                        ' duration-300 transform ',
                        {
                          'rotate-180': sortList.stopsCount === SORT_ORDER.ASC,
                          'rotate-0': sortList.stopsCount === SORT_ORDER.DESC,
                        },
                        `${
                          lastSortInfo.replace('-', '') === 'stopsCount'
                            ? 'fill-Primary'
                            : 'fill-DarkGray'
                        }`
                      )}
                    />
                  </th>
                )}

                {/* on-time */}
                {smartetaFlag === 'true' && (
                  <th
                    className={clsx(
                      'w-[115px] whitespace-nowrap pl-[16px] fullhd:pl-[26px] pr-0 cursor-pointer pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%]  ',
                      ` ${
                        lastSortInfo.replace('-', '') === 'onTime'
                          ? 'text-Primary'
                          : 'text-DarkGray'
                      }  `
                    )}
                    onClick={() => {
                      handleSorting(sortKeywords.onTime, 'number')
                    }}
                  >
                    <span className=" ">on-time</span>
                    <span className="w-1" />
                    <ArrowSort
                      className={clsx(
                        ' duration-300 transform ',
                        {
                          'rotate-180': sortList.onTime === SORT_ORDER.ASC,
                          'rotate-0': sortList.onTime === SORT_ORDER.DESC,
                        },
                        `${
                          lastSortInfo.replace('-', '') === 'onTime'
                            ? 'fill-Primary'
                            : 'fill-DarkGray'
                        }`
                      )}
                    />
                  </th>
                )}

                {/* documents */}
                {documentFlag === 'true' && (
                  <th
                    className={clsx(
                      'w-[125px] whitespace-nowrap pl-[16px] fullhd:pl-[26px] pr-0 cursor-pointer pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%]  ',
                      ` ${
                        lastSortInfo.replace('-', '') ===
                        sortKeywords.totalDocuments
                          ? 'text-Primary'
                          : 'text-DarkGray'
                      }  `
                    )}
                    onClick={() => {
                      handleSorting(sortKeywords.totalDocuments, 'number')
                    }}
                  >
                    <span className=" ">documents</span>
                    <span className="w-1" />
                    <ArrowSort
                      className={clsx(
                        ' duration-300 transform ',
                        {
                          'rotate-180':
                            sortList.totalDocuments === SORT_ORDER.ASC,
                          'rotate-0':
                            sortList.totalDocuments === SORT_ORDER.DESC,
                        },
                        `${
                          lastSortInfo.replace('-', '') ===
                          sortKeywords.totalDocuments
                            ? 'fill-Primary'
                            : 'fill-DarkGray'
                        }`
                      )}
                    />
                  </th>
                )}

                {/* document % */}
                {documentFlag === 'true' && (
                  <th
                    className={clsx(
                      'w-[125px] whitespace-nowrap pl-[16px] fullhd:pl-[26px] pr-0 cursor-pointer pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%]  ',
                      ` ${
                        lastSortInfo.replace('-', '') ===
                        sortKeywords.documentsPercent
                          ? 'text-Primary'
                          : 'text-DarkGray'
                      }  `
                    )}
                    onClick={() => {
                      handleSorting(sortKeywords.documentsPercent, 'number')
                    }}
                  >
                    <span className=" ">document %</span>
                    <span className="w-1" />
                    <ArrowSort
                      className={clsx(
                        ' duration-300 transform ',
                        {
                          'rotate-180':
                            sortList.documentsPercent === SORT_ORDER.ASC,
                          'rotate-0':
                            sortList.documentsPercent === SORT_ORDER.DESC,
                        },
                        `${
                          lastSortInfo.replace('-', '') ===
                          sortKeywords.documentsPercent
                            ? 'fill-Primary'
                            : 'fill-DarkGray'
                        }`
                      )}
                    />
                  </th>
                )}
                {/* group */}
                <th
                  className={clsx(
                    'whitespace-nowrap pl-[16px] fullhd:pl-[26px] pr-0 pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%] text-DarkGray'
                  )}
                  onClick={() => {
                    // handleSorting(sortKeywords.stops)
                  }}
                >
                  group
                </th>

                <th className="w-[68px] whitespace-nowrap px-0 cursor-pointer pt-[13px] pb-[13px]" />
              </tr>
            </thead>
            {/* // here render tbody tailwind */}
            <tbody className="">
              {loadingDrivers && _.isEmpty(listDriver) ? (
                <React.Fragment>
                  <tr className="h-1 bg-Gray1">
                    <td colSpan="9" />
                  </tr>
                  <tr className="bg-white ">
                    <td className="h-[60px] " colSpan="9">
                      <SpinnerIcon />
                    </td>
                  </tr>
                </React.Fragment>
              ) : _.isEmpty(listDriver) ? (
                <React.Fragment>
                  <tr className="h-1 bg-Gray1">
                    <td colSpan="9" />
                  </tr>
                  <tr className="bg-white ">
                    <td className="h-[60px] " colSpan="9">
                      <div className="flex items-center justify-center ">
                        <Caption>No driver</Caption>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {(isEmpty(listDriver) ? [] : listDriver).map((driver) => (
                    <React.Fragment key={JSON.stringify(driver)}>
                      {/*  */}
                      <tr className="h-1 bg-Gray1">
                        <td colSpan="9" />
                      </tr>
                      <tr className="bg-white hover:bg-LightestGray ">
                        <td className="text-Black  pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]     leading-[150%]">
                          <StatusButton
                            status={(function () {
                              if (driver.status === DriverStatusEnum.INACTIVE)
                                return 'Inactive'

                              if (
                                driver.status === DriverStatusEnum.ACTIVE &&
                                !driver.banStatus
                              ) {
                                return 'Active'
                              } else if (driver.banStatus) {
                                return 'Locked'
                              }

                              return 'Pending'
                            })()}
                          />
                        </td>
                        <td className="text-Black  pl-[16px] fullhd:pl-0 pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]">
                          <Caption block className="w100">
                            {getStr(driver, 'fullName')}
                          </Caption>
                          <Small block className="w100">
                            {driver.phoneNumber
                              ? formatPhoneNumber(
                                  driver.countryCode,
                                  driver.phoneNumber
                                )
                              : ''}
                            {getStr(driver, 'email') && ' - '}
                            {getStr(driver, 'email')}
                          </Small>
                        </td>
                        <td className="text-Black  pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]">
                          {_.get(driver, 'vendors.idVendor') ? (
                            <React.Fragment>
                              {renderSingleLink(
                                '/page/vendors?search=',
                                getStr(driver, 'vendors.name')
                              )}
                            </React.Fragment>
                          ) : (
                            <span>{company}</span>
                          )}
                        </td>

                        {smartetaFlag === 'true' && (
                          <td className="text-Black  pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]">
                            {`${formatRawData(driver.stopsCount)}`}
                          </td>
                        )}

                        {smartetaFlag === 'true' && (
                          <td className="text-Black  pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]">
                            {`${formatRawData(driver.onTime)}%`}
                          </td>
                        )}

                        {documentFlag === 'true' && (
                          <td className="text-Black  pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]">
                            {`${getStr(driver, 'totalDocuments')}`}
                          </td>
                        )}

                        {documentFlag === 'true' && (
                          <td className="text-Black  pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]">
                            {`${getStr(driver, 'documentsPercent')}%`}
                          </td>
                        )}
                        <td className="text-Black  pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]">
                          {renderListLink(
                            _.get(driver, 'groups') || [],
                            '/page/groups?search=',
                            'name',
                            'idGroup'
                          )}
                        </td>

                        <td className="text-Black max-w-[68px] px-0 pt-[10px] pb-[10px] text-right">
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              padding: '8px 22px',
                              // minHeight: '68px',
                            }}
                            onClick={handleGetByIdDriver(driver)}
                          >
                            <UikDropdown
                              DisplayComponent={DotsComponent}
                              className="dotEdit"
                              position="bottomRight"
                            >
                              {driver.status && (
                                <UikDropdownItem
                                  onClick={handleClickUpdate(driver)}
                                >
                                  <div className="flex gap-x-4 items-center">
                                    <span className={cls.mr_icon_edit}>
                                      <IconEdit2 height={14} width={14} />
                                    </span>
                                    <span className=" ">Edit</span>
                                  </div>
                                </UikDropdownItem>
                              )}
                              {driver.status === DriverStatusEnum.PENDING &&
                                !driver.banStatus && (
                                  <UikDropdownItem
                                    className={cls.resend}
                                    onClick={handleResendInvitation(
                                      driver.idDriver
                                    )}
                                  >
                                    <div className="flex gap-x-4 items-center">
                                      <span className={cls.mr_icon_edit}>
                                        <ResendIconSquare />
                                      </span>
                                      <span className="">Resend</span>
                                    </div>
                                  </UikDropdownItem>
                                )}
                              {((!!driver.banStatus &&
                                driver.status === DriverStatusEnum.PENDING) ||
                                (!!driver.banStatus &&
                                  driver.status ===
                                    DriverStatusEnum.ACTIVE)) && (
                                <UikDropdownItem
                                  className={cls.resend}
                                  onClick={handleUnlockDriver(driver.idDriver)}
                                >
                                  <div className="flex gap-x-4 items-center">
                                    <span className={cls.mr_icon_edit}>
                                      <IconUnlock />
                                    </span>
                                    <span className="">Unlock</span>
                                  </div>
                                </UikDropdownItem>
                              )}

                              {((driver.status === DriverStatusEnum.INACTIVE &&
                                !driver.banStatus) ||
                                (driver.status === DriverStatusEnum.INACTIVE &&
                                  !!driver.banStatus)) && (
                                <UikDropdownItem
                                  className={cls.activate}
                                  onClick={handleChangeStatusDriver(driver)}
                                >
                                  <div className="flex gap-x-4 items-center">
                                    <span className={cls.mr_icon_edit}>
                                      <ActiveIconSquare />
                                    </span>
                                    <span className=" ">Activate</span>
                                  </div>
                                </UikDropdownItem>
                              )}
                              {driver.status === DriverStatusEnum.INACTIVE && (
                                <UikDropdownItem
                                  className={cls.delete}
                                  onClick={handleShowDeleteDriver(
                                    driver.idDriver
                                  )}
                                >
                                  <div className="flex gap-x-4 items-center">
                                    <span className={cls.mr_icon_edit}>
                                      <DeleteLoadIcon />
                                    </span>
                                    <span>Delete</span>
                                  </div>
                                </UikDropdownItem>
                              )}
                              {((driver.status === DriverStatusEnum.ACTIVE &&
                                !driver.banStatus) ||
                                (driver.status === DriverStatusEnum.ACTIVE &&
                                  !!driver.banStatus) ||
                                (driver.status === DriverStatusEnum.PENDING &&
                                  !!driver.banStatus)) && (
                                <UikDropdownItem
                                  className={cls.deactivate}
                                  onClick={handleChangeStatusDriver(driver)}
                                >
                                  <div className="flex gap-x-4 items-center">
                                    <span className={cls.mr_icon_edit}>
                                      <DeactivateIconSquare
                                        height={14}
                                        width={14}
                                      />
                                    </span>
                                    <span>Deactivate</span>
                                  </div>
                                </UikDropdownItem>
                              )}
                              {driver.status === DriverStatusEnum.PENDING &&
                                !driver.banStatus && (
                                  <UikDropdownItem
                                    className={cls.delete}
                                    onClick={handleShowDeleteDriver(
                                      driver.idDriver
                                    )}
                                  >
                                    <div className="flex gap-x-4 items-center">
                                      <span className={cls.mr_icon_edit}>
                                        <DeleteLoadIcon />
                                      </span>
                                      <span>Delete</span>
                                    </div>
                                  </UikDropdownItem>
                                )}
                            </UikDropdown>
                          </div>
                        </td>
                      </tr>
                      {/*  */}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
            </tbody>
          </table>
        </div>
      )}

      <div className=" h-[24px] " />

      {/* table tailwind end */}

      <MyPagination
        className={classnames(cls.pagination)}
        handleChangePageSize={handleChangePageSize}
        loading={loadingDrivers}
        page={page || 1}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        setTriggerFetch={setTriggerFetch}
        total={total}
        triggerFetch={triggerFetch}
      />

      <AddDriver
        emailDriver={infoDriver.email}
        first_name={infoDriver.first_name}
        idDriver={infoDriver.idDriver}
        last_name={infoDriver.last_name}
        onCancel={handleCancel}
        phone_number={infoDriver.phone_number}
        refreshFn={() => {
          set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
        }}
        setShowAdd={setShowAdd}
        showAdd={showAdd}
        statusDriver={infoDriver.status}
      />
      {showEdit && (
        <Modal selector="#modal">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <EditDriver
              country_code={infoDriver.countryCode}
              emailDriver={infoDriver.email}
              first_name={responseDataDriver.data.data[0].last_name}
              handleResendInvitation={handleResendInvitation}
              idDriver={infoDriver.idDriver}
              id_vendor={_.get(infoDriver, 'vendors.idVendor') || ''}
              iso_code={responseDataDriver.data.data[0].iso_code}
              last_name={responseDataDriver.data.data[0].last_name}
              listRecentlyEdittedIdDoc={listRecentlyEdittedIdDoc}
              onClose={handleCloseModal}
              phone_number={infoDriver.phoneNumber}
              refreshFn={() => {
                set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
              }}
              resendLoading={resendLoading}
              setListRecentlyEdittedIdDoc={setListRecentlyEdittedIdDoc}
              setShowEdit={setShowEdit}
              // showEdit={showEdit}
              showEdit={showEdit}
              statusDriver={infoDriver.status}
              vendor_name={_.get(infoDriver, 'vendors.name') || ''}
            />
          </div>
        </Modal>
      )}

      {/* {(showBulkAdd || true) && ( */}
      {showBulkAdd && (
        <BulkAddDriver
          onClose={() => {
            setShowBulkAdd(false)
          }}
          refreshFn={() => {
            set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
          }}
          showBulkAdd={showBulkAdd}
        />
      )}

      {showDeleteDriver && (
        <Modal selector="#modal">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Delete Driver
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>
              <div className={cls.main_wrapper}>
                Are you sure you want to delete this driver?
              </div>
              <div className={cls.button_wrapper}>
                <Button
                  onClick={handleCloseDeleteDriver}
                  stateClass="Secondary"
                  width={74}
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleRemoveDriver}
                  stateClass={isDeleting ? 'Disabled' : 'Primary'}
                  width={74}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default withAuth(withContext(Drivers))
;((_data) => {})([React])
