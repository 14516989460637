import { useEffect, useState } from 'react'

const initLocation = {
  long: '0',
  lat: '0',
}
const useUserLocation = () => {
  const [location, setLocation] = useState(initLocation)
  useEffect(() => {
    getLocation()
  }, [])
  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition)
    }
  }
  function showPosition(position) {
    setLocation({
      long: position.coords.longitude,
      lat: position.coords.latitude,
    })
  }
  return location
}

export default useUserLocation
