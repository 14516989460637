import throttle from 'lodash/throttle'
import { useEffect, useRef, useState } from 'react'

export default function useScrollDirection() {
  const scrollPos = useRef(0)
  const [direction, setDirection] = useState({
    scrollDown: false,
    scrollUp: false,
  })

  useEffect(() => {
    const handleScroll = throttle(() => {
      setDirection({
        scrollDown: window.pageYOffset > scrollPos.current,
        scrollUp: window.pageYOffset < scrollPos.current,
      })
      scrollPos.current = window.pageYOffset
    }, 100)

    document.addEventListener('scroll', handleScroll)
    return () => document.removeEventListener('scroll', handleScroll)
  }, [])

  return direction
}
