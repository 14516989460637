/* eslint-disable no-useless-escape */

// https://driver-doc-develop.s3.ap-southeast-1.amazonaws.com/29/Load/default-101221/Proof%20of%20Delivery-101221092742956-The%20Little%20Schemer%204th%20Ed.pdf?AWSAccessKeyId=AKIAQGA3LL7HQPIS2UPY&Expires=1634631648&Signature=MMo4PmTlDpaANAiWwuoMBmUuYlo%3D

import {
  instancePdfAtom,
  isDefaultSplitModeAtom,
  tokenDataAtom,
} from '@atoms/atoms'
import { useAtom } from 'jotai'
import _, { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { ToastContainer } from 'react-toastify'
import {
  Black,
  CONTAINER_ID_INSIDE_PDF_VIEWER,
  DarkBlue,
  DarkerBlue,
  DisabledColor,
  PSPDFKIT_LICENSE_KEY,
  Red,
  Transparent,
  White,
  notificationOptions,
} from '../../../config'
import cls from './PdfViewerComponent.scss'
import {
  IconArchive36,
  IconButtonThumbnailActive,
  IconButtonThumbnailNormal,
  IconCollapsescreen,
  IconCropActive,
  IconCropNormal,
  IconDelete36,
  IconDeletePage,
  IconDownload36,
  IconEdit36,
  IconFitPageExpand,
  IconFitPageNormal,
  IconFullscreen,
  IconMovePage,
  IconPrintPage,
  IconReject36,
  IconRotateLeftNormal,
  IconRotateRightNormal,
  IconSend36,
  IconSplitPageActive,
  IconSplitPageNormal,
  IconUnarchive36,
  IconZoomInNormal,
  IconZoomOutNormal,
} from './PdfViewerSvg'
import { createElementFromHTML, setupObserver } from '@@/helper'

let intervalAddDeleteButtons
let intervalChangeLabel

const createButtonWithTooltip = (
  tooltipContent,
  iconStr,
  movex = 0,
  movey = 0
) => {
  const createElementFromString = (str) => {
    if (typeof window === 'undefined') {
      return
    }
    let doc = new window.DOMParser().parseFromString(str, 'text/html')
    let element = doc.body.firstChild
    return element
  }

  return createElementFromString(`
    <div style="height: 44px; width: 52px; position: relative; cursor: pointer">
      <button
      data-movex="${movex}"
      data-movey="${movey}"
      data-tooltiplabel="${tooltipContent}"
      class="tooltip2"
      ><button>
      ${iconStr}
    </div>
  `)
}

function initTooltip(instance, id, container) {
  const tooltips = Array.from(
    instance.contentDocument.querySelectorAll('.tooltip2')
  )
  if (_.isEmpty(tooltips)) {
    return
  }

  tooltips.forEach((tooltip) => {
    tooltip.addEventListener('mouseover', handleMouseOver)
  })

  function handleMouseOver() {
    let tooltips = window.document.querySelectorAll('.tooltipbottom')
    tooltips.forEach((o) => o.remove())
    const tooltipbox = createTooltipBox(this)
    handleMouseLeave.tooltipbox = tooltipbox
    handleMouseLeave.element = this
    this.addEventListener('mouseleave', handleMouseLeave)

    if (typeof window !== undefined) {
      const mobileAndTabletCheck = function () {
        let check = false
        ;(function (a) {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
              a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
              a.substr(0, 4)
            )
          ) {
            check = true
          }
        })(navigator.userAgent || navigator.vendor || window.opera)
        return check
      }

      if (mobileAndTabletCheck()) {
        handleMouseClick.tooltipbox = tooltipbox
        handleMouseClick.element = this
        this.addEventListener('click', handleMouseClick)
      }
    }

    return
  }

  const handleMouseLeave = {
    handleEvent() {
      this.tooltipbox.remove()
      this.element.removeEventListener('mouseleave', handleMouseLeave)
    },
  }
  const handleMouseClick = {
    handleEvent() {
      this.tooltipbox.remove()
      this.element.removeEventListener('mouseleave', handleMouseClick)
    },
  }

  function createTooltipBox(el) {
    let tooltip = document.createElement('div')

    tooltip.innerText = el.getAttribute('data-tooltiplabel')

    tooltip.classList.add('tooltipbottom')
    let movex = Number(el.dataset.movex || 0)
    if (movex < 0) {
      tooltip.classList.add('moveleft')
    } else if (movex > 0) {
      tooltip.classList.add('moveright')
    }

    tooltip.style.top = 60
    tooltip.style.left = el.parentElement.offsetLeft - 25 + movex
    container.appendChild(tooltip)
    return tooltip
  }
}

export const getEl = (instance, selector) =>
  Array.from(instance.contentDocument.querySelectorAll(selector))[0]

const hideSideBar = (instance) => {
  instance.setViewState((viewState) => viewState.set('sidebarMode', null))
}

const showThumbnailSideBar = (instance, PSPDFKit) => {
  instance.setViewState((viewState) =>
    viewState.set('sidebarMode', PSPDFKit.SidebarMode.THUMBNAILS)
  )
}

const toggleDisplay = (el) => {
  if (el.style.display === 'none') {
    el.style.display = 'block'
  } else {
    el.style.display = 'none'
  }
}

const show = (el) => {
  if (el) {
    el.style.display = 'block'
  }
}
export const hide = (el) => {
  if (el) {
    el.style.display = 'none'
  }
}

export const remove = (el) => {
  if (el) {
    el.remove()
  }
}

const css = (instance, selector, property, value) => {
  const el = Array.from(instance.contentDocument.querySelectorAll(selector))[0]
  if (el) {
    el.style[property] = value
  }
}

export default function PdfViewerComponent({
  setIsInEditorMode,
  setIsInCropMode,
  id,
  listUrlForMerge,
  setDocumentFileFn,
  setDocumentFileMergerFn,
  document,
  actionFns: {
    downloadFn,
    archiveFn,
    unarchiveFn,
    rejectFn,
    editFn,
    sendFn,
    deleteFn,
  },
  rightSideButtonsType, // 'edit' or 'action'
  hideSplitIcon,
  isSplitMode,
  setIsSplitMode,
  merging = false,
  setMerging,
  setIsProcessingDocument: setIsProcessingDocumentFn,
  isProcessingDocument,
}) {
  const isProcessingDocumentRef = useRef(isProcessingDocument)
  const isClickRemoveRef = useRef(false)

  const setIsProcessingDocument = useCallback(
    (value) => {
      isProcessingDocumentRef.current = value
      setIsProcessingDocumentFn(value)
    },
    [setIsProcessingDocumentFn]
  )

  const [tokenData] = useAtom(tokenDataAtom)
  const [isDefaultSplitMode, setIsDefaultSplitMode] = useAtom(
    isDefaultSplitModeAtom
  )
  const containerRef = useRef(null)
  const containerRef2 = useRef(null)

  const [instancePdf, setInstancePdf] = useAtom(instancePdfAtom)

  useEffect(() => {
    const container = containerRef.current
    const container2 = containerRef2.current
    let PSPDFKit
    let instance

    const initPDF = async () => {
      PSPDFKit = await import('pspdfkit')
      PSPDFKit.unload(container) // Ensure that there's only one PSPDFKit instance.

      instance = await PSPDFKit.load({
        printMode: PSPDFKit.PrintMode.EXPORT_PDF,
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/`,
        initialViewState: new PSPDFKit.ViewState({
          sidebarMode: PSPDFKit.SidebarMode.THUMBNAILS,
          // interactionMode: PSPDFKit.InteractionMode.DOCUMENT_EDITOR,
        }),
        styleSheets: [`${process.env.RAZZLE_APP_DOMAIN_NAME}/my-pspdfkit.css`],
        licenseKey: PSPDFKIT_LICENSE_KEY,
        // headless: true,
        // renderPageCallback: async function(ctx, pageIndex, pageSize) {

        // },
      })

      await instance.whenReady
      console.log('setInstancePdf(instance)')
      setInstancePdf(instance)

      // instance.setViewState(viewState =>
      //   viewState.set(
      //     "interactionMode",
      //     PSPDFKit.InteractionMode.DOCUMENT_EDITOR
      //   )
      // );

      // setTimeout(async () => {
      //   const pdfResponse = await fetch(listUrlForMerge)
      //   const blob = await pdfResponse.arrayBuffer()
      //   debugger
      //   instance.applyOperations([
      //     {
      //       type: 'importDocument',
      //       afterPageIndex: 1, // Import document after page 10.
      //       treatImportedDocumentAsOnePage: false, // All the imported document pages will be treated separately for other operations.
      //       document: blob, // Document to import.
      //     },
      //   ])
      // }, 2000)

      const handleHover = (
        myClass,
        fillColorRectNormal = Transparent,
        fillColorPathNormal = Black
      ) => {
        const el = Array.from(
          instance.contentDocument.getElementsByClassName(myClass)
        )[0]
        if (el) {
          el.addEventListener('mouseenter', (e) => {
            let svg = e.target.querySelector('svg')
            if (!svg) {
              return
            }
            let rect = svg.querySelector('rect')

            if (!rect) {
              return
            }
            rect.setAttribute('fill', DarkBlue)

            svg.querySelectorAll('path').forEach((path) => {
              path.setAttribute('fill', White)
            })
          })
          el.addEventListener('mouseleave', (e) => {
            let svg = e.target.querySelector('svg')
            if (svg) {
              if (!svg) {
                return
              }

              let rect = svg.querySelector('rect')

              if (!rect) {
                return
              }
              rect.setAttribute('fill', fillColorRectNormal)
              svg.querySelectorAll('path').forEach((path) => {
                path.setAttribute('fill', fillColorPathNormal)
              })
            }
          })
        }
      }

      if (_.isFunction(setDocumentFileFn)) {
        console.log('aaa setDocumentFileFn1 ')
        setDocumentFileFn({
          instance,
          isChange: false,
        })
      }

      if (typeof window !== 'undefined') {
        window.instance = instance
        window.PSPDFKit = PSPDFKit
        window.container = container
        window.container2 = container2
      }

      window.document.activeElement.blur()

      // event listener begin

      ////
      // instance.addEventListener('document.saveStateChange', event => {
      //   console.log(`aaa saveStateChange: ${event.hasUnsavedChanges}`)
      // })

      if (rightSideButtonsType === 'edit') {
        const setupDeleteIcons = () => {
          const modifyThumbnail = ({ thumbnail }) => {
            const pageID = Number(thumbnail.dataset.pageIndex)

            const appendSVGDelete = () => {
              const bntDeleteOld = thumbnail.querySelector('svg')
              if (bntDeleteOld) {
                bntDeleteOld.remove()
              }

              let doc = new DOMParser().parseFromString(
                IconDeletePage,
                // `<span class="btndelete" style="border: 1px solid green;">${pageID}</span>`,
                'text/html'
              )

              let btnDeletePage = doc.body.firstChild
              btnDeletePage.addEventListener('click', () => {
                isClickRemoveRef.current = true
                if (isProcessingDocumentRef.current) {
                  return
                }
                setIsProcessingDocument(true)
                instance.applyOperations([
                  {
                    type: 'removePages',
                    pageIndexes: [pageID],
                  },
                ])
              })
              thumbnail.appendChild(btnDeletePage)
            }
            appendSVGDelete()
          }

          const modifyThumbnails = ({ thumbnails }) => {
            thumbnails.forEach((thumbnail) => {
              modifyThumbnail({ thumbnail })
            })
          }

          const containerOfThumbnails = Array.from(
            instance.contentDocument.querySelectorAll(
              '.PSPDFKit-8gjpn8p7xvprbe81bs4qegz9b5 > div > div'
            )
          )[0]

          const getThumbnails = () => {
            const thumbnails = Array.from(
              containerOfThumbnails.querySelectorAll(
                '.PSPDFKit-Sidebar-Thumbnails-Page'
              )
            )

            return { thumbnails }
          }

          const { thumbnails } = getThumbnails()

          modifyThumbnails({ thumbnails })

          setupObserver({
            targetNode: containerOfThumbnails,
            fn: ({ addedNodes }) => {
              const thumbnail = addedNodes.querySelector(
                '.PSPDFKit-Sidebar-Thumbnails-Page'
              )
              modifyThumbnail({ thumbnail })
            },
            fnWhenClickRemove: () => {
              if (isClickRemoveRef.current === true) {
                const { thumbnails } = getThumbnails()
                modifyThumbnails({ thumbnails })
              }
            },
          })
        }
        setupDeleteIcons()
      }

      instance.addEventListener('document.change', () => {
        if (_.isFunction(setDocumentFileFn)) {
          console.log(
            'aaa document changed, total page:',
            instance.totalPageCount
          )
          console.log('aaa setDocumentFileFn2 ')
          setDocumentFileFn({
            instance,
            isChange: true,
            fn: () => {
              setIsProcessingDocument(false)
            },
          })
        }

        if (_.isFunction(setDocumentFileMergerFn)) {
          console.log(
            'aaa merger fn document changed, total page:',
            instance.totalPageCount
          )
          setDocumentFileMergerFn({
            instance,
            fn: () => {
              setIsProcessingDocument(false)
            },
          })
        }
      })

      //// thumbnail button
      instance.addEventListener('viewState.change', (viewState) => {
        const data = viewState.toJS()

        if (data.interactionMode === 'DOCUMENT_EDITOR') {
          if (_.isFunction(setIsInEditorMode)) {
            setIsInEditorMode(true)

            const changeLabel = () => {
              let btnSaveEditMode = getEl(
                instance,
                'button.PSPDFKit-au2y6ump4kfh59dxhzebebxuk.PSPDFKit-2pntgtgpg8fsv3qg8w5fqacy4h.PSPDFKit-8gpjtx5az7b1eem7nk5gvydakg'
              )
              if (btnSaveEditMode) {
                if (btnSaveEditMode.innerText !== 'Done') {
                  btnSaveEditMode.innerText = 'Done'
                }
              }
            }

            intervalChangeLabel = setInterval(() => {
              changeLabel()
            }, 200)
          }
        } else {
          clearInterval(intervalChangeLabel)
          if (_.isFunction(setIsInEditorMode)) {
            setIsInEditorMode(false)
          }
        }

        if (data.sidebarMode === PSPDFKit.SidebarMode.THUMBNAILS) {
          hide(getEl(instance, '.btnThumbnaiNormal'))
          show(getEl(instance, '.btnThumbnaiActive'))
        } else if (data.sidebarMode === null) {
          hide(getEl(instance, '.btnThumbnaiActive'))
          show(getEl(instance, '.btnThumbnaiNormal'))
        }

        //// crop button
        if (
          rightSideButtonsType === 'edit' &&
          data.interactionMode === 'DOCUMENT_CROP'
        ) {
          if (_.isFunction(setIsInCropMode)) {
            setIsInCropMode(true)
          }
          hide(getEl(instance, '.btnCropInActive'))
          show(getEl(instance, '.btnCropActive'))
        } else if (
          rightSideButtonsType === 'edit' &&
          data.interactionMode === null
        ) {
          if (_.isFunction(setIsInCropMode)) {
            setIsInCropMode(false)
          }
          hide(getEl(instance, '.btnCropActive'))
          show(getEl(instance, '.btnCropInActive'))
        }

        //// zoom mode button
        if (data.zoom === 'FIT_TO_VIEWPORT') {
          hide(getEl(instance, '.btnFitPage'))
          show(getEl(instance, '.btnFitWidth'))
        } else {
          hide(getEl(instance, '.btnFitWidth'))
          show(getEl(instance, '.btnFitPage'))
        }

        // zoom in and zoom out
        let btnZoomIn = getEl(instance, '.btnZoomIn')
        let btnZoomOut = getEl(instance, '.btnZoomOut')
        if (data.zoom === instance.minimumZoomLevel) {
          btnZoomOut.classList.add('disabled_pdf_button')
        } else {
          btnZoomOut.classList.remove('disabled_pdf_button')
        }

        if (data.zoom === instance.maximumZoomLevel) {
          btnZoomIn.classList.add('disabled_pdf_button')
        } else {
          btnZoomIn.classList.remove('disabled_pdf_button')
        }
      })

      window.document.addEventListener('fullscreenchange', () => {
        // document.fullscreenElement will point to the element that
        // is in fullscreen mode if there is one. If there isn't one,
        // the value of the property is null.
        if (window.document.fullscreenElement) {
          hide(getEl(instance, '.btnExpand'))
          show(getEl(instance, '.btnCollapse'))
        } else {
          hide(getEl(instance, '.btnCollapse'))
          show(getEl(instance, '.btnExpand'))
        }
      })

      instance.addEventListener(
        'cropArea.changeStart',
        ({ area, pageIndex }) => {
          console.log(area, pageIndex)
        }
      )

      instance.addEventListener(
        'cropArea.changeStop',
        ({ area, pageIndex }) => {
          console.log(area, pageIndex)
        }
      )

      // event listener enddd

      // current

      // ///////////////////////////// set toolbar begin

      const getCustomItems = () => {
        let target
        let leftSideItems = [
          // TODO refactor
          {
            type: 'custom',
            node: createButtonWithTooltip(
              'Thumbnails',
              IconButtonThumbnailActive,
              35
            ),
            id: 'itemSidebarThumbnail',
            className: 'btnThumbnaiActive',
            fillColorRectNormal: DarkerBlue,
            fillColorPathNormal: White,
            onPress: async () => {
              hideSideBar(instance)
            },
          },

          {
            node: createButtonWithTooltip(
              'Thumbnails',
              IconButtonThumbnailNormal,
              35
            ),
            type: 'custom',
            id: 'itemSidebarNull',
            className: 'btnThumbnaiNormal',
            fillColorRectNormal: Transparent,
            onPress: async () => {
              showThumbnailSideBar(instance, PSPDFKit)
            },
          },
          {
            type: 'custom',
            className: 'btnZoomOut',
            node: createButtonWithTooltip('Zoom out', IconZoomOutNormal),
            onPress: async () => {
              instance.setViewState((viewState) => viewState.zoomOut())
            },
          },
          {
            type: 'custom',
            className: 'btnZoomIn',
            node: createButtonWithTooltip('Zoom in', IconZoomInNormal),
            onPress: async () => {
              instance.setViewState((viewState) => viewState.zoomIn())
            },
          },

          {
            type: 'custom',
            node: createButtonWithTooltip('Fit Page', IconFitPageNormal),
            className: 'btnFitPage',
            onPress: async () => {
              instance.setViewState((viewState) =>
                viewState.set('zoom', PSPDFKit.ZoomMode.FIT_TO_VIEWPORT)
              )
            },
          },

          {
            type: 'custom',
            node: createButtonWithTooltip('Fit Width', IconFitPageExpand),
            className: 'btnFitWidth',
            onPress: async () => {
              instance.setViewState((viewState) =>
                viewState.set('zoom', PSPDFKit.ZoomMode.FIT_TO_WIDTH)
              )
            },
          },

          {
            type: 'custom',
            node: createButtonWithTooltip('Full screen', IconFullscreen),
            id: 'itemExpand',
            className: 'btnExpand',
            onPress: async () => {
              function requestFullscreen(element) {
                if (element.requestFullscreen) {
                  element.requestFullscreen()
                } else if (element.mozRequestFullScreen) {
                  element.mozRequestFullScreen()
                } else if (element.webkitRequestFullscreen) {
                  element.webkitRequestFullscreen()
                } else if (element.msRequestFullscreen) {
                  element.msRequestFullscreen()
                }
              }

              if (window.document.fullscreenElement) {
                await window.document.exitFullscreen()
              } else {
                requestFullscreen(container2)
              }
            },
          },

          {
            type: 'custom',
            node: createButtonWithTooltip(
              'Exit Full screen',
              IconCollapsescreen
            ),
            id: 'itemCollapse',
            className: 'btnCollapse',
            onPress: async () => {
              function requestFullscreen(element) {
                if (element.requestFullscreen) {
                  element.requestFullscreen()
                } else if (element.mozRequestFullScreen) {
                  element.mozRequestFullScreen()
                } else if (element.webkitRequestFullscreen) {
                  element.webkitRequestFullscreen()
                } else if (element.msRequestFullscreen) {
                  element.msRequestFullscreen()
                }
              }

              if (window.document.fullscreenElement) {
                await window.document.exitFullscreen()
              } else {
                requestFullscreen(container2)
              }
            },
          },

          {
            type: 'spacer',
            id: 'itemSpacer1',
          },
        ]

        if (rightSideButtonsType === 'none') {
          target = leftSideItems
        }

        if (rightSideButtonsType === 'action') {
          /////////////////////////////////
          // let doc = new DOMParser().parseFromString(IconDeletePage, 'text/html')
          // let btnDeletePage = doc.body.firstChild
          // btnDeletePage.addEventListener('click', () => {
          //   alert(111)
          // })

          /////////////////////////////////

          const itemsActions = [
            {
              type: 'custom',
              className: 'btnPrint',
              node: createButtonWithTooltip('Print', IconPrintPage),
              onPress: () => {
                instance.print()
              },
            },
            {
              type: 'custom',
              node: createButtonWithTooltip('Download', IconDownload36),
              className: 'btnDownload',
              onPress: () => {
                downloadFn()
              },
            },
          ]
          if (sendFn) {
            itemsActions.push({
              type: 'custom',
              node: createButtonWithTooltip('Send', IconSend36),
              className: 'btnSend',
              onPress: async () => {
                if (window.document.fullscreenElement) {
                  await window.document.exitFullscreen()
                }
                sendFn()
              },
            })
          }

          if (archiveFn) {
            itemsActions.push({
              type: 'custom',
              node: createButtonWithTooltip('Archive', IconArchive36),
              className: 'btnArchive',
              onPress: async () => {
                archiveFn()
              },
            })
          }

          if (unarchiveFn) {
            itemsActions.push({
              type: 'custom',
              node: createButtonWithTooltip('Unarchive', IconUnarchive36),
              className: 'btnUnarchive',
              onPress: async () => {
                unarchiveFn()
              },
            })
          }

          itemsActions.push({
            type: 'custom',
            node: createButtonWithTooltip(
              'Edit',
              IconEdit36,
              !rejectFn && tokenData.role === 'Staff' ? -35 : 0
            ),
            className: 'btnEdit',
            onPress: async () => {
              if (window.document.fullscreenElement) {
                await window.document.exitFullscreen()
              }
              editFn()
            },
          })

          if (rejectFn) {
            itemsActions.push({
              type: 'custom',
              node: createButtonWithTooltip(
                'Reject',
                IconReject36,
                tokenData.role === 'Staff' ? -35 : 0
              ),
              className: 'btnReject',
              onPress: () => {
                rejectFn()
              },
            })
          }

          if (tokenData.role !== 'Staff') {
            itemsActions.push({
              type: 'custom',
              node: createButtonWithTooltip('Delete', IconDelete36, -35),
              className: 'btnDelete',
              fillColorPathNormal: Red,
              onPress: () => {
                deleteFn()
              },
            })
          }

          target = leftSideItems.concat(itemsActions)
        }

        if (rightSideButtonsType === 'edit') {
          const itemsEdit = [
            {
              type: 'custom',
              node: createButtonWithTooltip('Split Pages', IconSplitPageNormal),
              className: 'btnSplitNormal',
              onPress: async () => {
                hide(getEl(instance, '.btnSplitNormal'))
                show(getEl(instance, '.btnSplitActive'))
                if (_.isFunction(setIsSplitMode)) {
                  if (window.document.fullscreenElement) {
                    await window.document.exitFullscreen()
                  }

                  setIsSplitMode(true)
                }
              },
            },

            {
              type: 'custom',
              node: createButtonWithTooltip('Split Pages', IconSplitPageActive),
              className: 'btnSplitActive',
              fillColorRectNormal: DarkerBlue,
              fillColorPathNormal: White,
              onPress: () => {
                hide(getEl(instance, '.btnSplitActive'))
                show(getEl(instance, '.btnSplitNormal'))
                if (_.isFunction(setIsSplitMode)) {
                  setIsSplitMode(false)
                }
              },
            },
            {
              type: 'custom',
              node: createButtonWithTooltip('Arrange Pages', IconMovePage),
              className: `btnMoveNormal ${
                isSplitMode === true ? 'pointernone' : ''
              }`,
              onPress: () => {
                instance.setViewState((viewState) =>
                  viewState.set(
                    'interactionMode',
                    _.get(
                      PSPDFKit,
                      'ViewState.InteractionMode.DOCUMENT_EDITOR'
                    ) || _.get(PSPDFKit, 'InteractionMode.DOCUMENT_EDITOR')
                  )
                )
              },
            },
            {
              type: 'custom',
              node: createButtonWithTooltip('Crop', IconCropNormal),

              className: `btnCropInActive ${
                isSplitMode === true ? 'pointernone' : ''
              }`,
              onPress: () => {
                instance.setViewState((viewState) =>
                  viewState.set(
                    'interactionMode',
                    _.get(
                      PSPDFKit,
                      'ViewState.InteractionMode.DOCUMENT_CROP'
                    ) || _.get(PSPDFKit, 'InteractionMode.DOCUMENT_CROP')
                  )
                )
              },
            },

            {
              type: 'custom',
              node: createButtonWithTooltip('Crop', IconCropActive),
              className: `btnCropActive ${
                isSplitMode === true ? 'pointernone' : ''
              }`,
              fillColorRectNormal: DarkerBlue,
              fillColorPathNormal: White,
              onPress: () => {
                instance.setViewState((viewState) =>
                  viewState.set('interactionMode', null)
                )
              },
            },
            {
              type: 'custom',
              node: createButtonWithTooltip(
                'Rotate Left',
                IconRotateLeftNormal
              ),
              className: `btnRotateLeft ${
                isSplitMode === true ? 'pointernone' : ''
              }`,
              onPress: () => {
                let currentpage = Number(instance.viewState.currentPageIndex)
                instance.applyOperations([
                  {
                    type: 'rotatePages',
                    pageIndexes: [currentpage],
                    rotateBy: -90,
                  },
                ])
              },
            },

            {
              type: 'custom',
              node: createButtonWithTooltip(
                'Rotate Right',
                IconRotateRightNormal,
                -35
              ),

              className: `btnRotateRight ${
                isSplitMode === true ? 'pointernone' : ''
              }`,
              onPress: () => {
                let currentpage = Number(instance.viewState.currentPageIndex)
                instance.applyOperations([
                  {
                    type: 'rotatePages',
                    pageIndexes: [currentpage],
                    rotateBy: 90,
                  },
                ])
              },
            },
          ]

          target = leftSideItems.concat(itemsEdit)
        }
        return target
      }
      const customItems = getCustomItems()
      instance.setToolbarItems(customItems)

      /// import pdf
      if (rightSideButtonsType === 'edit') {
        // PSPDFKit-Tool-Node PSPDFKit-Toolbar-Node PSPDFKit-gcq16j5wcc9m8hqacd31kbbwq
        let doc = new DOMParser().parseFromString(
          `
          <div
            style="height: 44px; width: 52px; position: relative;"
            class="${`wr_btn_import PSPDFKit-Tool-Node PSPDFKit-Toolbar-Node PSPDFKit-gcq16j5wcc9m8hqacd31kbbwq ${
              isSplitMode === true ? 'pointernone' : ''
            }`}"
          >
            <button
              data-tooltiplabel="Add Page"
              class="tooltip2"
            ><button>
            <svg class="btnfilefake" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="36" height="36" rx="8" fill="${Transparent}" />
              <path d="M13.875 21C13.875 20.4477 14.3227 20 14.875 20H21.875C22.4273 20 22.875 20.4477 22.875 21C22.875 21.5523 22.4273 22 21.875 22H14.875C14.3227 22 13.875 21.5523 13.875 21Z" fill="#50565E"/>
              <path d="M14.875 23C14.3227 23 13.875 23.4477 13.875 24C13.875 24.5523 14.3227 25 14.875 25H18.375C18.9273 25 19.375 24.5523 19.375 24C19.375 23.4477 18.9273 23 18.375 23H14.875Z" fill="#50565E"/>
              <path d="M14.875 14.5C14.875 13.9477 15.3227 13.5 15.875 13.5H17.375V12C17.375 11.4477 17.8227 11 18.375 11C18.9273 11 19.375 11.4477 19.375 12V13.5H20.875C21.4273 13.5 21.875 13.9477 21.875 14.5C21.875 15.0523 21.4273 15.5 20.875 15.5H19.375V17C19.375 17.5523 18.9273 18 18.375 18C17.8227 18 17.375 17.5523 17.375 17V15.5H15.875C15.3227 15.5 14.875 15.0523 14.875 14.5Z" fill="#50565E"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M9.375 9C9.375 7.89543 10.2704 7 11.375 7H25.375C26.4796 7 27.375 7.89544 27.375 9V27C27.375 28.1046 26.4796 29 25.375 29H11.375C10.2704 29 9.375 28.1046 9.375 27V9ZM25.375 9H11.375V27H25.375V9Z" fill="#50565E"/>
            </svg>

            <input
              accept="application/pdf"
              class="btnImport"
              type="file"
            />
          </div>
        `,
          'text/html'
        )
        let html = doc.body.firstChild
        let btnTooltipAddPage = html.querySelector('.tooltip2')
        let btnImport = html.querySelector('.btnImport')
        let btnfilefake = html.querySelector('.btnfilefake')

        btnfilefake.addEventListener('click', () => {
          btnImport.click()
        })
        btnTooltipAddPage.addEventListener('click', () => {
          btnImport.click()
        })

        setTimeout(() => {
          handleHover('wr_btn_import')
        }, 10)

        const merge = (urls) => {
          let listPromise1 = []
          urls.forEach((o) => {
            listPromise1.push(fetch(o))
          })

          Promise.all(listPromise1).then(function (values) {
            let listPromise2 = []
            values.forEach((o) => {
              let p = o.blob()
              listPromise2.push(p)
            })

            //////////////////////////////////
            Promise.all(listPromise2)
              .then(async function (blobs) {
                console.log(blobs)

                // const mergedPdf = await PDFDocument.create()
                // const pdfsToMerge = await Promise.all(
                //   blobs.map(blob => blob.arrayBuffer())
                // )

                // for (const pdfBytes of pdfsToMerge) {
                //   const pdf = await PDFDocument.load(pdfBytes)
                //   const copiedPages = await mergedPdf.copyPages(
                //     pdf,
                //     pdf.getPageIndices()
                //   )
                //   copiedPages.forEach(page => {
                //     mergedPdf.addPage(page)
                //   })
                // }

                // const buf = await mergedPdf.save() // Uint8Array

                // const merger = new PDFMerger()
                // for (const file of blobs) {
                //   await merger.add(file)
                // }
                // const mergedPdf = await merger.saveAsBlob()

                // for (let index = 0; index < blobs.length; index++) {
                //   const blob = blobs[index]

                //   if (index !== blobs.length - 1) {
                //     await instance.applyOperations([
                //       {
                //         type: 'importDocument',
                //         afterPageIndex: Number(instance.totalPageCount) - 1,
                //         treatImportedDocumentAsOnePage: false,
                //         document: blob,
                //       },
                //     ])
                //   } else {
                //     const arrayBuffer = await instance.exportPDFWithOperations([
                //       {
                //         type: 'importDocument',
                //         afterPageIndex: Number(instance.totalPageCount) - 1,
                //         treatImportedDocumentAsOnePage: false,
                //         document: blob,
                //       },
                //     ])
                //     const blob = new Blob([arrayBuffer], {
                //       type: 'application/pdf',
                //     })

                //     // if (_.isFunction(setDocumentFileMergerFn)) {
                //     //   console.log('aaaaaa setDocumentFileMergerFn')
                //     //   setDocumentFileMergerFn(instance)
                //     // }
                //   }
                // }

                for (const blob of blobs) {
                  await instance.applyOperations([
                    {
                      type: 'importDocument',
                      afterPageIndex: Number(instance.totalPageCount) - 1,
                      treatImportedDocumentAsOnePage: false,
                      document: blob,
                    },
                  ])
                }

                if (_.isFunction(setMerging)) {
                  setMerging(false)
                }
              })
              .catch((err) => {
                let message =
                  _.get(err, 'response.data.messages', _.get(err, 'message')) ||
                  ''
                console.log(message)
              })
          })
        }

        if (_.isEmpty(listUrlForMerge)) {
          if (_.isFunction(setMerging)) {
            setMerging(false)
          }
        } else {
          merge(listUrlForMerge)
        }

        btnImport.addEventListener('change', async (e) => {
          console.log('aaa input type file start ')
          setIsProcessingDocument(true)
          isClickRemoveRef.current = false
          // const pdfResponse = await fetch(url1Bro)
          // const blob2 = await pdfResponse.blob()
          // instance.applyOperations([
          //   {
          //     type: 'importDocument',
          //     afterPageIndex: Number(instance.totalPageCount) - 1, // Import document after page 10.
          //     treatImportedDocumentAsOnePage: false, // All the imported document pages will be treated separately for other operations.
          //     document: blob2, // Document to import.
          //   },
          // ])

          if (_.get(e, 'target.files[0]')) {
            if (e.target.files[0].type !== 'application/pdf') {
              alert('Only accept Pdf files')
              e.preventDefault()
              return false
            }
            let blob = e.target.files[0]
            // debugger
            let currentpage = Number(instance.viewState.currentPageIndex)

            instance.applyOperations([
              {
                type: 'importDocument',
                afterPageIndex: Number(currentpage), // Import document after page 10.
                treatImportedDocumentAsOnePage: false, // All the imported document pages will be treated separately for other operations.
                document: blob, // Document to import.
              },
            ])
          }
        })
        let toolbarEl = getEl(instance, '.PSPDFKit-Toolbar')
        let btnMoveNormal = getEl(instance, '.btnMoveNormal')
        toolbarEl.insertBefore(html, btnMoveNormal)
      }

      hide(getEl(instance, '.btnThumbnaiNormal'))
      hide(getEl(instance, '.btnFitWidth'))
      hide(getEl(instance, '.btnCropActive'))
      hide(getEl(instance, '.btnCollapse'))

      if (hideSplitIcon === true) {
        hide(getEl(instance, '.btnSplitNormal'))
        hide(getEl(instance, '.btnSplitActive'))
      } else {
        if (isDefaultSplitMode === true) {
          hide(getEl(instance, '.btnSplitNormal'))
        } else {
          hide(getEl(instance, '.btnSplitActive'))
        }
      }

      if (
        isDefaultSplitMode === true &&
        hideSplitIcon === false &&
        _.isFunction(setIsSplitMode)
      ) {
        setIsSplitMode(true)
      }

      customItems.forEach(
        ({ className, fillColorRectNormal, fillColorPathNormal }) => {
          handleHover(className, fillColorRectNormal, fillColorPathNormal)
        }
      )

      // ////////////////////////////// set toolbar enddd

      // hide button exit full screen
      const btnExitFullscreen = Array.from(
        instance.contentDocument.getElementsByClassName('buttonExitFullscreen')
      )[0]
      if (btnExitFullscreen) {
        toggleDisplay(btnExitFullscreen)
      }

      // styling css begin
      // css(instance, '.test100', 'backgroundColor', 'red')
      // css(instance, '.test100', 'display', 'none')
      css(instance, '.btnImport', 'display', 'none')

      // styling css enddd
      instance.setViewState((v) => v.set('readOnly', false))
      setTimeout(() => {
        initTooltip(instance, id, container2)
      }, 10)
    }

    initPDF()

    return () => {
      clearInterval(intervalAddDeleteButtons)
      if (PSPDFKit) {
        PSPDFKit.unload(container)
      }
      setInstancePdf(null)
      setIsDefaultSplitMode(false)
    }
  }, [document])

  useEffect(() => {
    console.log('aaa isSplitMode', isSplitMode)
    if (!instancePdf) {
      return
    }
    let listClass = [
      'btnMoveNormal',
      'btnCropInActive',
      'btnCropActive',
      'btnRotateLeft',
      'btnRotateRight',
      'wr_btn_import',
    ]

    listClass.forEach((myClass) => {
      const el = Array.from(
        instancePdf.contentDocument.getElementsByClassName(myClass)
      )[0]
      if (!el) {
        return
      }
      let svg = el.querySelector('svg')
      if (!svg) {
        return
      }

      if (isSplitMode === true) {
        el.classList.add('pointernone')
        svg.querySelectorAll('path').forEach((path) => {
          path.setAttribute('fill', DisabledColor)
        })
      }

      if (isSplitMode === false) {
        el.classList.remove('pointernone')
        svg.querySelectorAll('path').forEach((path) => {
          path.setAttribute('fill', Black)
        })
      }
    })
    return () => {}
  }, [isSplitMode, instancePdf])

  const totalPageCount = useMemo(() => {
    if (!instancePdf) {
      return 0
    }

    return instancePdf.totalPageCount
  }, [instancePdf])

  useEffect(() => {
    console.log('aaa totalPageCount', totalPageCount)
  }, [totalPageCount])

  useEffect(() => {
    if (!instancePdf) {
      return
    }
    console.log(
      'aaa instancePdf.totalPageCount',
      _.get(instancePdf, 'totalPageCount')
    )

    if (_.get(instancePdf, 'totalPageCount') === 1) {
      remove(getEl(instancePdf, '.btn_delete_page'))

      setTimeout(() => {
        hide(getEl(instancePdf, '.btnMoveNormal'))
      }, 100)
    } else {
      show(getEl(instancePdf, '.btnMoveNormal'))
    }
  }, [_.get(instancePdf, 'totalPageCount')])

  useEffect(() => {
    console.log(_.get(instancePdf, 'totalPageCount'))
  }, [_.get(instancePdf, 'totalPageCount')])

  useEffect(() => {
    if (!instancePdf) {
      return
    }

    const sidebars =
      instancePdf.contentDocument.querySelectorAll('.PSPDFKit-Sidebar') || []
    const sidebar = sidebars[0]
    if (isEmpty(sidebar)) {
      return
    }
    if (isProcessingDocument) {
      let html = createElementFromHTML(
        `<div class="sidebar-overlay" style='user-select: none; display: flex; justify-content: center; align-items: center ;background-color: rgba(0,0,0,0.7); position: absolute; top: 0; left: 0; bottom: 0; right: 0; z-index: 1; color: #fff;' >
           Processing...
        </div>`
      )
      sidebar.append(html)
    } else {
      const lastChildSidebar = sidebar.lastElementChild
      if (lastChildSidebar.classList.contains('sidebar-overlay')) {
        sidebar.removeChild(sidebar.lastElementChild)
      }
    }
  }, [instancePdf, isProcessingDocument])

  return (
    <React.Fragment>
      <div
        className={`wr_pdf ${cls.pdfkit_container2}`}
        id={id}
        ref={containerRef2}
      >
        {merging && (
          <div className={cls.pdf_processing}>Merging documents...</div>
        )}
        <div className={cls.pdfkit_container} ref={containerRef} />
        <div aria-modal="true" id="modalInsidePdfViewer" />
        <div id="notificationInsidePdfViewer" />
        <ToastContainer
          {...notificationOptions}
          containerId={CONTAINER_ID_INSIDE_PDF_VIEWER}
        />
      </div>
    </React.Fragment>
  )
}
;((_data) => {})([React])
