/* eslint-disable */

import React from 'react'
import classnames from 'classnames'
import cls from './typography.scss'

export const Small = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={classnames(cls.small, className)}
    style={style}
  >
    {children}
  </span>
)

export const BodyBold = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={cls.bodybold}
  >
    {children}
  </span>
)

export const Caption = ({
  children,
  style,
  className,
  onClick,
  block,
  gray,
}) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={classnames(cls.caption, className, { [cls.gray]: gray })}
  >
    {children}
  </span>
)
export const CaptionBold = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={classnames(cls.CaptionBold, className)}
  >
    {children}
  </span>
)
export const Body = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={classnames(cls.body, className)}
  >
    {children}
  </span>
)
export const H1 = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={cls.H1}
  >
    {children}
  </span>
)
export const H1_Bold = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={cls.H1_Bold}
  >
    {children}
  </span>
)
export const H2 = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={cls.H2}
  >
    {children}
  </span>
)
export const H2_Bold = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={cls.H2_Bold}
  >
    {children}
  </span>
)
export const H3 = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={cls.H3}
  >
    {children}
  </span>
)
export const H3_Bold = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={cls.H3_Bold}
  >
    {children}
  </span>
)
export const H4 = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={cls.H4}
  >
    {children}
  </span>
)
export const H4_Bold = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={cls.H4_Bold}
  >
    {children}
  </span>
)
export const H5 = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={cls.H5}
  >
    {children}
  </span>
)
export const H5_Bold = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={cls.H5_Bold}
  >
    {children}
  </span>
)
export const H6 = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={classnames(cls.H6, className)}
  >
    {children}
  </span>
)
export const H6_Bold = ({ children, style, className, onClick, block }) => (
  <span
    style={{ display: block === true ? 'block' : 'inline' }}
    onClick={onClick}
    className={cls.H6_Bold}
  >
    {children}
  </span>
)
