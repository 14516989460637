import { useAtom } from 'jotai'
import React from 'react'
import { runTour } from '../../atoms/atoms'
import { UikButton } from '../../components'
import ImageWelcome from '../svg/ImageWelcome'
import cls from './common.scss'

export const ModalWelcome = () => {
  const [, setRun] = useAtom(runTour)
  const handleStart = () => {
    setRun(true)
  }
  return (
    <div className=" bg-BackGround w-full h-full z-[10000] opacity-100 fixed top-0 left-0">
      <div className="fixed rounded bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[628px] h-[350px] p-6">
        <div className="text-lg">Welcome!</div>
        <div className="flex justify-center items-center mt-[22px] mb-[24px]">
          <ImageWelcome />
        </div>
        <div className="text-sm text-center">
          It’s official, welcome to the driverDOC family! Let’s get started by
          adding your drivers!
        </div>
        <div className="float-right mt-6">
          <UikButton className={cls.button__start} onClick={handleStart}>
            Get Started!
          </UikButton>
        </div>
      </div>
    </div>
  )
}
;((_data) => {})([React])
