import classnames from 'classnames'
import { useAtom } from 'jotai'
import React from 'react'

import { runTour } from '../../atoms/atoms'
import { UikButton } from '../../components'
import cls from './common.scss'
import { H6 } from './typography'

export const ModalFinalCongratulations = () => {
  const [, setRun] = useAtom(runTour)
  const handleEnd = () => {
    window.location.reload()
    setTimeout(() => {
      setRun(false)
    }, 2000)
  }
  return (
    <div className=" fixed bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[628px] h-[613px] p-6">
      <H6>Congratulations!</H6>
      <div className="text-sm text-center mt-[22px] mb-[25px]">
        Your setup is complete and you’re officially ready to collaborate with
        your drivers. We couldn’t be happier to have you here! Below is a quick
        video with additional guidance to use driverDOC.
      </div>
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        height="327"
        src="https://www.youtube.com/embed/VJ07DiBT2MA"
        title="YouTube video player"
        width="580"
      />
      <div className="mt-6 text-sm text-center">
        <div className="inline">
          If you need guidance through your experience, we recommend checking
          out our{' '}
        </div>
        <a
          className="inline text-Blue"
          href="https://www.driverdoc.io/faq"
          rel="noreferrer"
          target="_blank"
        >
          FAQ&nbsp;
        </a>
        <div className="inline">or you can </div>
        <a
          className="inline text-Blue"
          href="https://www.driverdoc.io/customer-service"
          rel="noreferrer"
          target="_blank"
        >
          contact
        </a>
        <div className="inline">&nbsp; our support team.</div>
      </div>
      <div className="float-right mt-6">
        <UikButton
          className={classnames(cls.button__done, 'p-0')}
          onClick={handleEnd}
        >
          Done
        </UikButton>
      </div>
    </div>
  )
}
;((_data) => {})([React])
