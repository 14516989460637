import React from 'react'

const IconButtonAdd = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 19C0 8.50659 8.50659 0 19 0C29.4934 0 38 8.50659 38 19C38 29.4934 29.4934 38 19 38C8.50659 38 0 29.4934 0 19Z"
      fill="#023F62"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4293 10.5557C20.0122 10.5564 20.4842 11.0296 20.4835 11.6126L20.4752 17.9446H26.7847C27.3677 17.9446 27.8403 18.4171 27.8403 19.0001C27.8403 19.5831 27.3677 20.0557 26.7847 20.0557H20.4724L20.4641 26.3904C20.4633 26.9733 19.9901 27.4453 19.4071 27.4446C18.8242 27.4438 18.3522 26.9706 18.353 26.3876L18.3613 20.0557H12.007C11.424 20.0557 10.9514 19.5831 10.9514 19.0001C10.9514 18.4171 11.424 17.9446 12.007 17.9446H18.364L18.3723 11.6098C18.3731 11.0269 18.8463 10.5549 19.4293 10.5557Z"
      fill="white"
    />
  </svg>
)
export default IconButtonAdd
;((_data) => {})([React])
