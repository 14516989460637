import { customAxios } from '@helper'
import _, { get } from 'lodash'
import apiRoute from '../constants/apiRoute'
import { equalStr } from '@@/helper'

export const updateMyGroups = async (
  token,
  setGroups,
  setLoadinggroups,
  checkedGroups,
  setCheckedGroups
) => {
  const handleData = (data) => {
    if (setGroups && setCheckedGroups) {
      setGroups(data)
      /////////
      let listIdCheckedGroups = checkedGroups.map((group) => group.idGroup)
      setCheckedGroups(
        data.filter((o) => {
          return listIdCheckedGroups.includes(o.idGroup)
        })
      )
      /////////
    }
  }
  try {
    setLoadinggroups(true)
    const resp = await customAxios(token).get(
      `${apiRoute.groups.LIST_MY_GROUP}`
    )

    handleData(
      (get(resp, 'data.data.groups') || []).map((o) => {
        o.idGroup = o.id_group
        return o
      })
    )
  } catch (err) {
    //
  } finally {
    setLoadinggroups(false)
  }
}

export const getActiveGroups = async (
  token,
  setActiveGroups,
  setLoadingActiveGroups,
  setErr,
  search = ''
) => {
  const handleData = (data) => {
    setActiveGroups(data)
  }
  try {
    if (setErr) setErr(null)
    handleData([])
    if (setLoadingActiveGroups) {
      setLoadingActiveGroups(true)
    }
    const resp = await customAxios(token).get(
      `${apiRoute.groups.GET_ACTIVE_GROUPS}?search=${search}`
    )
    handleData(
      (_.get(resp, 'data.data.groups') || []).map((o) => {
        o.idGroup = o.id_group
        return o
      })
    )
  } catch (err) {
    if (setErr) setErr(err)
    handleData([])
  } finally {
    if (setLoadingActiveGroups) {
      setLoadingActiveGroups(false)
    }
  }
}

export const getActiveVendors = async (
  token,
  setActiveVendors,
  setLoadingActiveVendors,
  setErr,
  search = ''
) => {
  try {
    if (setErr) setErr(null)
    setActiveVendors([])
    if (setLoadingActiveVendors) {
      setLoadingActiveVendors(true)
    }
    const resp = await customAxios(token).get(
      `${apiRoute.vendors.ACTIVE_VENDORS}text=${search}`
    )
    setActiveVendors(_.get(resp, 'data.data') || [])
  } catch (err) {
    if (setErr) setErr(err)
    setActiveVendors([])
  } finally {
    if (setLoadingActiveVendors) {
      setLoadingActiveVendors(false)
    }
  }
}

export const getActiveLoads = async (
  { token, listIdCheckedGroups, setLoadingFn, setListFn, setErrFn },
  text
) => {
  if (!token || !setLoadingFn || !setListFn) {
    return
  }
  try {
    if (setErrFn) {
      setErrFn(null)
    }

    setListFn([])
    setLoadingFn(true)
    const resp = await customAxios(token).get(
      `${
        apiRoute.documents.GET_LOADS
      }?search=${text}&groups=${listIdCheckedGroups.join(',')}`
    )

    let data = []

    resp.data.data.forEach(({ id_load, load_number, id_trip, trip_name }) => {
      data.push({ id_load, load_number, id_trip, trip_name })
    })

    setListFn(data)
  } catch (err) {
    if (setErrFn) {
      setErrFn(err)
    }
  } finally {
    setLoadingFn(false)
  }
}

export const getDocumentTypes = async (token, setListFn) => {
  if (!token || !setListFn) {
    return
  }
  try {
    const resp = await customAxios(token).get(apiRoute.documents.DOCUMENT_TYPES)
    if (resp.status !== 200) return
    setListFn(resp.data || [])
  } catch (err) {
    //
  }
}

export const fetchCanCompleteStatus = async ({
  idLoad,
  customAxios,
  listIdCheckedGroups,
  status,
}) => {
  if (!idLoad) {
    return { canComplete: false }
  }

  if (equalStr(status, 'Reject')) {
    return { canComplete: false }
  }

  try {
    const resp = await customAxios().get(
      `${
        apiRoute.loads.CAN_COMPLETE
      }?idLoads=${idLoad}&groups=${listIdCheckedGroups.join(',')}`
    )

    if (_.get(resp, 'data.data[0].canComplete') === true) {
      return { canComplete: true }
    }

    return { canComplete: false }
  } catch (err) {
    return { canComplete: false }
  }
}
