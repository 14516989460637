import React from 'react'

const IconAutomations = ({ fill }) => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 19 18"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M9.37489 1.51562C5.24239 1.51562 1.88989 4.86812 1.88989 9.00062C1.88989 13.1331 5.24239 16.4856 9.37489 16.4856C13.5074 16.4856 16.8599 13.1331 16.8599 9.00062C16.8599 4.86812 13.5074 1.51562 9.37489 1.51562ZM9.37489 14.9856C6.07489 14.9856 3.38989 12.3006 3.38989 9.00062C3.38989 5.70062 6.07489 3.01562 9.37489 3.01562C12.6749 3.01562 15.3599 5.70062 15.3599 9.00062C15.3599 12.3006 12.6749 14.9856 9.37489 14.9856ZM6.56239 10.1256L9.93739 3.75063V7.87563H12.1874L8.91739 14.2506V10.1256H6.56239Z"
      fill={fill || '#50565E'}
      fillRule="evenodd"
    />
  </svg>
)
export default IconAutomations
;((_data) => {})([React])
