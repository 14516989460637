import React from 'react'
const IconVendor = ({ fill }) => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M10.6667 3.99967C10.6667 2.52691 11.8607 1.33301 13.3334 1.33301H18.6667C20.1395 1.33301 21.3334 2.52691 21.3334 3.99967V9.33301C21.3334 10.8058 20.1395 11.9997 18.6667 11.9997H17.3376L17.3348 14.6663H28.0001C28.7365 14.6663 29.3334 15.2633 29.3334 15.9997C29.3334 16.7361 28.7365 17.333 28.0001 17.333H25.3376L25.3348 19.9997H26.6667C28.1395 19.9997 29.3334 21.1936 29.3334 22.6663V27.9997C29.3334 29.4724 28.1395 30.6663 26.6667 30.6663H21.3334C19.8607 30.6663 18.6667 29.4724 18.6667 27.9997V22.6663C18.6667 21.1936 19.8607 19.9997 21.3334 19.9997H22.6681L22.6709 17.333H16.1224C16.0817 17.3367 16.0404 17.3386 15.9987 17.3385C15.9579 17.3385 15.9176 17.3366 15.8778 17.333H9.33757L9.3348 19.9997H10.6667C12.1395 19.9997 13.3334 21.1936 13.3334 22.6663V27.9997C13.3334 29.4724 12.1395 30.6663 10.6667 30.6663H5.33341C3.86066 30.6663 2.66675 29.4724 2.66675 27.9997V22.6663C2.66675 21.1936 3.86066 19.9997 5.33341 19.9997H6.66814L6.6709 17.333H4.00008C3.2637 17.333 2.66675 16.7361 2.66675 15.9997C2.66675 15.2633 3.2637 14.6663 4.00008 14.6663H14.6681L14.6709 11.9997H13.3334C11.8607 11.9997 10.6667 10.8058 10.6667 9.33301V3.99967ZM16.0051 9.33301C16.0057 9.33301 16.0064 9.33301 16.007 9.33301L18.6667 9.33301V3.99967H13.3334V9.33301H16.0051ZM7.87781 22.6663H5.33341V27.9997H10.6667V22.6663H8.12239C8.08166 22.67 8.0404 22.6719 7.9987 22.6719C7.95795 22.6718 7.91762 22.67 7.87781 22.6663ZM23.8778 22.6663H21.3334V27.9997H26.6667V22.6663H24.1224C24.0817 22.67 24.0404 22.6719 23.9987 22.6719C23.9579 22.6718 23.9176 22.67 23.8778 22.6663Z"
      fill={fill ? fill : '#50565E'}
      fillRule="evenodd"
    />
  </svg>
)
export default IconVendor
;((_data) => {})([React])
