import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Redirect } from 'react-router-dom'

export default function withAuth(ProtectedComponent) {
  return function Component(props) {
    const [cookies] = useCookies(['token'])
    const [loading, setLoading] = useState(true)
    const [redirect, setRedirect] = useState(false)
    useEffect(() => {
      const { token, accessToken } = cookies
      if (!_.isEmpty(token)) {
        setLoading(false)
      } else if (!_.isEmpty(accessToken)) {
        setLoading(false)
      } else {
        setLoading(false)
        setRedirect(true)
      }
    }, [])

    if (loading) {
      return null
    }
    if (redirect) {
      return <Redirect to="/" />
    }
    return (
      <React.Fragment>
        <ProtectedComponent {...props} />
      </React.Fragment>
    )
  }
}
;((_data) => {})([React])
