import { ArchivedBadge } from '@appComponent/ArchivedBadge'
import { Copier } from '@appComponent/Copier'
import { RejectedBadge } from '@appComponent/RejectedBadge'
import classnames from 'classnames'
import dayjs from 'dayjs'
import { get, identity, isEmpty } from 'lodash'
import moment from 'moment-timezone'
import React, { useMemo } from 'react'

import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'

// import Modal from 'react-modal'
import PdfViewerComponent from '@appComponent/pdf/PdfViewerComponent'
import {
  databaseDateFormat,
  databaseTimeFormat,
  displayDateFormat,
  displayTimeFormat,
  getStr,
  renderDocumentTitle,
} from '@helper'
import cls from './documents.scss'

import { libraries, mapContainerStyle } from '../../../../config'
import mapStyles from '../../../../config/mapStyles'
import MarkerIconPng from '../../../../driverDocAssets/marker_document_page.png'
import IconClose from '../../../svg/IconClose'
import { PreservesLineBreak } from '@@/App/@components/PreservesLineBreak'

if (typeof window !== 'undefined') {
  window.moment = moment
  window.displayDateFormat = displayDateFormat
  window.displayTimeFormat = displayTimeFormat
  window.databaseDateFormat = databaseDateFormat
  window.databaseTimeFormat = databaseTimeFormat
  window.googleMapsApiKey = process.env.RAZZLE_GOOGLE_MAP_API_KEY
}

const DocumentCard = identity(
  ({ o, handleCloseDocumentCard, actionFns, listIdDocumentForEdit }) => {
    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: process.env.RAZZLE_GOOGLE_MAP_API_KEY,
      libraries,
    })

    const mapRef = React.useRef()
    const onMapLoad = React.useCallback(
      (map) => {
        mapRef.current = map
      },
      [mapRef]
    )

    const lat = useMemo(() => Number(getStr(o, 'location.latitude')), [o])
    const lng = useMemo(() => Number(getStr(o, 'location.longitude')), [o])

    const address = useMemo(() => getStr(o, 'location.address'), [o])

    const hideSplitIcon = useMemo(
      () => o.status !== 'Success' && o.status !== 'Archive',
      [o]
    )

    const renderMap = () => {
      if (loadError) return 'Error'
      if (!isLoaded) return ''
      return (
        <div>
          <GoogleMap
            center={{
              lat: Number(getStr(o, 'location.latitude')),
              lng: Number(getStr(o, 'location.longitude')),
            }}
            id="map"
            mapContainerStyle={mapContainerStyle}
            onLoad={onMapLoad}
            options={{
              styles: mapStyles,
              disableDefaultUI: true,
              mapTypeControl: false,
              draggable: false,
              scaleControl: false,
              scrollwheel: false,
              navigationControl: false,
              streetViewControl: false,
            }}
            zoom={15}
          >
            <Marker
              icon={{
                url: MarkerIconPng,
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(7, -2),
                scaledSize: new window.google.maps.Size(14, 14),
              }}
              position={{
                lat: Number(getStr(o, 'location.latitude')),
                lng: Number(getStr(o, 'location.longitude')),
              }}
            />
          </GoogleMap>
        </div>
      )
    }

    const renderMain = () => {
      const renderDateArchiveOrRejectOrUpload = () => {
        if (o.rejectedBy) {
          return (
            <React.Fragment>
              <div className="item">
                <div className="s">Rejected By</div>
                <div className="c">{getStr(o, 'rejectedBy', '', 30)}</div>
              </div>
              <div className="item">
                <div className="s">Rejected Date/Time</div>
                <div className="c">
                  {o.rejectedAt
                    ? dayjs(o.rejectedAt).format(
                        `${displayDateFormat} - ${displayTimeFormat}`
                      )
                    : ''}
                </div>
              </div>
            </React.Fragment>
          )
        }
        if (o.archivedBy) {
          return (
            <React.Fragment>
              <div className="item">
                <div className="s">Archived By</div>
                <div className="c">{getStr(o, 'archivedBy', '', 30)}</div>
              </div>
              <div className="item">
                <div className="s">Archived Date/Time</div>
                <div className="c">
                  {o.archivedAt
                    ? dayjs(o.archivedAt).format(
                        `${displayDateFormat} - ${displayTimeFormat}`
                      )
                    : ''}
                </div>
              </div>
            </React.Fragment>
          )
        }
        // return (
        //   <React.Fragment>
        //     <div className="item">
        //       <div className="s">Updated By</div>
        //       <div className="c">{getStr(o, 'updatedBy', '', 30)}</div>
        //     </div>
        //     <div className="item">
        //       <div className="s">Updated Date/Time</div>
        //       <div className="c">
        //         {o.updatedAt
        //           ? dayjs(o.updatedAt).format(
        //               `${displayDateFormat} - ${displayTimeFormat}`
        //             )
        //           : ''}
        //       </div>
        //     </div>
        //   </React.Fragment>
        // )
      }
      return (
        <React.Fragment>
          <div className={classnames('loadcard_mid_12', [cls.loadcard_mid_12])}>
            <div
              className={classnames('loadcard_left_small', [cls.loadcard_left])}
            >
              <div className="myflex">
                <div className="item">
                  <div className="s">Uploaded By</div>
                  <div className="c">{getStr(o, 'uploadedBy', '', 30)}</div>
                </div>
                <div className="item">
                  <div className="s">Uploaded Date/Time</div>
                  <div className="c">
                    {/* {o.uploadTime
                      ? dayjs(o.uploadTime).format(
                          `${displayDateFormat} - ${displayTimeFormat}`
                        )
                      : ''} */}
                    {getStr(o, 'uploadTime')}
                  </div>
                </div>

                {renderDateArchiveOrRejectOrUpload()}

                <div className="item">
                  <div className="s">Location</div>
                  {!get(o, 'location.address') ? (
                    <div className="c">No location available</div>
                  ) : (
                    <div className="c">
                      {getStr(o, 'location.address', '', 30)}
                    </div>
                  )}
                </div>
                {get(o, 'location.address') && (
                  <div
                    className="item wr_map_small"
                    onClick={() => {
                      const url = `${process.env.RAZZLE_PORTAL_DOMAIN_NAME}/maps?lat=${lat}&lng=${lng}&address=${address}`
                      window.open(
                        url,
                        '_blank',
                        'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=8000,height=8000'
                      )
                    }}
                  >
                    <div className="map_inner" />
                    {renderMap()}
                  </div>
                )}
                <div className="item" style={{ flex: '1 0 100%' }}>
                  <div className="s">Note</div>
                  <PreservesLineBreak text={getStr(o, 'note', '')} />
                </div>
              </div>
            </div>
            <div className={classnames('loadcard_right', [cls.loadcard_right])}>
              {!isEmpty(o.url) && isEmpty(listIdDocumentForEdit) && (
                //
                <PdfViewerComponent
                  actionFns={actionFns}
                  className="pdf5"
                  document={o.url}
                  hideSplitIcon={hideSplitIcon}
                  id="pspdfkit_wr"
                  rightSideButtonsType="action"
                />
              )}
            </div>
          </div>
        </React.Fragment>
      )
    }
    // if (tab === 'loadinfo' || tab === 'documents') {
    //   return (

    //   )
    // } else {
    //   return (

    //   )
    // }

    if (!o) return null

    return (
      <div className={classnames('loadcard loadcard_document', [cls.loadcard])}>
        <div className={classnames('loadcard_top', [cls.loadcard_top])}>
          <div
            className={classnames('loadcard_title document flex gap-x-3', [
              cls.loadcard_title,
            ])}
          >
            <Copier className="inline-block">{renderDocumentTitle(o)}</Copier>
            {o.status === 'Archive' && (
              <div className="wr_tag_archived">
                <ArchivedBadge />
              </div>
            )}
            {o.status === 'Reject' && (
              <div className="wr_tag_archived">
                <RejectedBadge />
              </div>
            )}
          </div>

          <div
            className={classnames('close_load_card', [cls.close_load_card])}
            onClick={handleCloseDocumentCard}
          >
            <IconClose />
          </div>
        </div>

        {renderMain()}
      </div>
    )
  }
)

export default DocumentCard
;((_data) => {})([React])
