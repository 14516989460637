import apiRoute from '@constants/apiRoute'
import { customAxios, getCity, getState, getStr, getZipcode } from '@helper'
import classnames from 'classnames'
import _ from 'lodash'
import React, { useRef } from 'react'
import { useCookies } from 'react-cookie'
import cls from './common.scss'

const LocationInput = ({ item, type, hidelabel, tabIndex, updateFunction }) => {
  const myref = useRef()
  const addressRef = useRef(null)
  const [cookies] = useCookies(['token'])
  const { token } = cookies
  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside)
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside)
  //   }
  // }, [])

  const trackCount = async () => {
    await customAxios(token).post(`${apiRoute.google.COUNT}`)
  }

  const handleChangeLocation = (e) => {
    e.persist()
    const eventValue = e.target.value
    const locationValue = {
      city: '',
      address: '',
      latitude: '',
      longitude: '',
      placeId: '',
      state: '',
      name: '',
      zipcode: '',
      displayValue: eventValue,
    }
    updateFunction(locationValue)
    trackCount()
  }

  const handleFocusLocation = () => {
    // get location on input search
    const handleAutocompleteGoogleMap = () => {
      const { google } = window
      const autocomplete = new google.maps.places.Autocomplete(
        addressRef.current,
        {
          componentRestrictions: { country: 'us' },
        }
      )
      const handlePlaceChange = () => {
        const place = autocomplete.getPlace()
        let addressAndName = getStr(place, 'formatted_address').includes(
          getStr(place, 'name')
        )
          ? getStr(place, 'formatted_address')
          : `${getStr(place, 'name')}, ${getStr(place, 'formatted_address')}`

        if (!place.formatted_address) {
          addressAndName = place.name
        }

        const latFn = _.get(place, 'geometry.location.lat')
        const lngFn = _.get(place, 'geometry.location.lng')
        const locationValue = {
          city: getCity(getStr(place, 'address_components')),
          address: getStr(place, 'formatted_address'),
          latitude: _.isFunction(latFn) ? latFn() : '',
          longitude: _.isFunction(lngFn) ? lngFn() : '',
          placeId: getStr(place, 'place_id'),
          state: getState(getStr(place, 'address_components')),
          name: getStr(place, 'name'),
          zipcode: getZipcode(getStr(place, 'address_components')),
          displayValue: addressAndName,
        }
        // setNewListTrip(locationValue, parseInt(nodeRef.dataset.index))
        updateFunction(locationValue)
        trackCount()
      }
      autocomplete.addListener('place_changed', handlePlaceChange)
    }
    handleAutocompleteGoogleMap()
  }

  // const handleClickOutside = event => {
  //   if (
  //     !event.target.id.includes(`location-${type}-trip`) &&
  //     item.location.address &&
  //     refLocation &&
  //     refLocation.current
  //   ) {
  //     for (let x in Array.from({ length: refLocation.current.length })) {
  //       if (refLocation.current[x]) {
  //         refLocation.current[x].blur()
  //       }
  //     }
  //   }
  // }

  const addressAndName = getStr(item, 'location.address').includes(
    getStr(item, 'location.name', '')
  )
    ? getStr(item, 'location.address')
    : `${getStr(item, 'location.name')}, ${getStr(item, 'location.address')}`
  const value = getStr(item, 'location.displayValue') || addressAndName

  return (
    <div ref={myref} style={{ position: 'relative' }}>
      {hidelabel === true ? null : (
        <label
          className={cls.label}
          htmlFor={`location-${type}-trip-name-${item.uniqueid}`}
        >
          Location
        </label>
      )}
      <input
        autoComplete="new-password"
        className={classnames(cls.location_search)}
        onChange={handleChangeLocation}
        onFocus={handleFocusLocation}
        ref={addressRef}
        tabIndex={tabIndex || '0'}
        type="text"
        value={value}
      />
    </div>
  )
}

export default LocationInput
;((_data) => {})([React])
