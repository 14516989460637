import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import React from 'react'
import ReactTimeAgo from 'react-time-ago'

TimeAgo.addDefaultLocale(en)

const LastSeen = ({ date, label }) => {
  return (
    <React.Fragment>
      {label} <ReactTimeAgo date={date} locale="en-US" />
    </React.Fragment>
  )
}

export default LastSeen
;((_data) => {})([React])
