import React, { useEffect } from 'react'

import { PickDate, TimePickerComponent } from './index'

import cls from '../../loadsnew.scss'

const DateModification = ({ value, idDate }) => {
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  })
  const handleKeyDown = (e) => {
    if (
      e.keyCode === 9 &&
      e.target.id === `close_button_${idDate}` &&
      !e.shiftKey
    ) {
      e.preventDefault()
      document.getElementById(idDate).focus()
    }
    if (e.keyCode === 9 && e.shiftKey && e.target.id === idDate) {
      e.preventDefault()
      document.getElementById(`close_button_${idDate}`).focus()
    }
  }

  return (
    <div className={cls.modify_date_wrapper}>
      <div>
        <PickDate label="Date" value={value.date} />
      </div>
      <div>
        <div className={cls.dflex}>
          <TimePickerComponent
            label="Time"
            timeValue={value.time}
            // ref={timeRef}
          />
        </div>
      </div>
    </div>
  )
}

export default DateModification
;((_data) => {})([React])
