import React from 'react'

const IconLeft = ({ fill }) => (
  <svg
    fill="none"
    height="19"
    viewBox="0 0 18 19"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12.1553 4.46967C12.4482 4.76256 12.4482 5.23744 12.1553 5.53033L8.18566 9.5L12.1553 13.4697C12.4482 13.7626 12.4482 14.2374 12.1553 14.5303C11.8624 14.8232 11.3876 14.8232 11.0947 14.5303L6.59467 10.0303C6.30178 9.73744 6.30178 9.26256 6.59467 8.96967L11.0947 4.46967C11.3876 4.17678 11.8624 4.17678 12.1553 4.46967Z"
      fill={fill || '#0075FF'}
      fillRule="evenodd"
    />
  </svg>
)
export default IconLeft
;((_data) => {})([React])
