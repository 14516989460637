import clsx from 'clsx'
import React, { useMemo } from 'react'

export const PreservesLineBreak = ({ text, className = 'text-[14px]' }) => {
  const newText = useMemo(() => {
    return text.replaceAll('\r\n', '\r').replaceAll('\r', '\r\n')
  }, [text])
  return (
    <pre
      className={clsx(`leading-[1.5] font-lato whitespace-pre-wrap`, className)}
    >
      {newText}
    </pre>
  )
}
;((_data) => {})([React])
