import React from 'react'
const IconTime = () => (
  <svg
    fill="none"
    height="15"
    viewBox="0 0 14 15"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M0.583984 7.49967C0.583984 3.95585 3.45682 1.08301 7.00065 1.08301C10.5445 1.08301 13.4173 3.95585 13.4173 7.49967C13.4173 11.0435 10.5445 13.9163 7.00065 13.9163C3.45682 13.9163 0.583984 11.0435 0.583984 7.49967ZM7.00065 2.24967C4.10115 2.24967 1.75065 4.60018 1.75065 7.49967C1.75065 10.3992 4.10115 12.7497 7.00065 12.7497C9.90015 12.7497 12.2507 10.3992 12.2507 7.49967C12.2507 4.60018 9.90015 2.24967 7.00065 2.24967ZM7.00313 3.41634C7.3253 3.41637 7.58644 3.67757 7.58641 3.99973L7.58608 7.26064L9.88836 9.56292C10.1162 9.79073 10.1162 10.1601 9.88836 10.3879C9.66055 10.6157 9.29121 10.6157 9.0634 10.3879L6.59024 7.91472C6.48083 7.80531 6.41937 7.65691 6.41939 7.50218L6.41974 3.99962C6.41977 3.67745 6.68096 3.41631 7.00313 3.41634Z"
      fill="#50565E"
      fillRule="evenodd"
    />
  </svg>
)
export default IconTime
;((_data) => {})([React])
