import { customAxios, getErrorMessage } from '@helper'
import classnames from 'classnames'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'

import { Button } from '@appComponent/button'
import { Input2 } from '@appComponent/input'
import { Small } from '@appComponent/typography'
import apiRoute from '@constants/apiRoute'
import { useGroups } from '@hooks'
import { updateMyGroups } from '@service'
import { Drawer } from 'antd'
import InputMember from './InputMember'
import cls from './groups.scss'
import { identity, trim } from 'lodash'

const DrawerAddGroup = identity(
  ({ showAdd, setShowAdd, token, context, set_time_request }) => {
    const { setGroups, setLoadinggroups, checkedGroups, setCheckedGroups } =
      useGroups()
    const [name, setName] = useState('')

    const [isDisable, setDisable] = useState(true)
    const [savingOrDispatching, setSavingOrDispatching] = useState(false)

    const [listMemberSelected, setListMemberSelected] = useState([])

    const listIdUserSelected = useMemo(
      () =>
        listMemberSelected
          .filter((o) => o.idUser && !o.idDriver)
          .map((o) => o.idUser),
      [listMemberSelected]
    )

    const listIdDriverSelected = useMemo(
      () =>
        listMemberSelected
          .filter((o) => !o.idUser && o.idDriver)
          .map((o) => o.idDriver),
      [listMemberSelected]
    )

    const onClose = () => {
      setShowAdd(false)
    }

    const handleClickAdd = async () => {
      try {
        setSavingOrDispatching(true)

        const body = {
          name: trim(name),
          listIdUser: listIdUserSelected,
          listIdDriver: listIdDriverSelected,
        }

        const resp = await customAxios(token).post(
          `${apiRoute.groups.ADD}`,
          body
        )
        if (!resp.data.success) {
          context.message.error('Adding Group Failed!')
          return
        }

        set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
        context.message.success('Added Successfully')
        updateMyGroups(
          token,
          setGroups,
          setLoadinggroups,
          checkedGroups,
          setCheckedGroups
        )
        setShowAdd(false)
      } catch (err) {
        context.message.error(getErrorMessage(err))
      } finally {
        setTimeout(() => {
          setSavingOrDispatching(false)
        }, 3888)
      }
    }

    useEffect(() => {
      if (showAdd === true) {
        setTimeout(() => {
          const el = document.querySelectorAll(
            // TODO
            'input#input_group_firstname'
          )[0]
          if (el) {
            el.focus()
          }
        }, 200)
      } else {
        resetFields()
      }
    }, [showAdd])

    useEffect(() => {
      if (!trim(name)) {
        setDisable(true)
      } else {
        setDisable(false)
      }
    }, [name])

    const resetFields = () => {
      setListMemberSelected([])
      setName('')
    }

    return (
      <Drawer
        className={classnames(
          cls.wr_drawer_upload_doc,
          'wr_drawer_upload_doc wr_drawer_add_group'
        )}
        destroyOnClose
        footer={
          <div
            className={`wr_footer_add_load ${
              savingOrDispatching ? 'disabled1' : ''
            }`}
          >
            <Button
              onClick={savingOrDispatching ? null : onClose}
              stateClass={savingOrDispatching ? 'Disabled' : 'Secondary'}
              width={74}
            >
              Cancel
            </Button>
            <div className="wr_save_dispatch">
              <Button
                onClick={
                  isDisable || savingOrDispatching ? null : handleClickAdd
                }
                stateClass={
                  isDisable || savingOrDispatching ? 'Disabled' : 'Primary'
                }
                width={58}
              >
                Add
              </Button>
            </div>
          </div>
        }
        maskClosable={false}
        onClose={onClose}
        title="Add Group"
        visible={showAdd}
        width={448}
      >
        <div className={cls.form_group}>
          <label htmlFor="input_group_firstname">
            <Small>Group Name</Small>
          </label>
          <Input2
            id="input_group_firstname"
            onChange={(e) => {
              setName(e.target.value)
            }}
            onBlur={(e) => {
              setName(trim(e.target.value))
            }}
            value={name}
          />
        </div>
        <div className={cls.form_group}>
          <label htmlFor="input_group_lastname">
            <Small>Add Member</Small>
          </label>
          <InputMember
            {...{
              token,
              listMemberSelected,
              setListMemberSelected,
            }}
          />
        </div>
      </Drawer>
    )
  }
)

export default DrawerAddGroup
;((_data) => {})([React])
