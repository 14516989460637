import React from 'react'
const IconClose = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6.79289 6.29289C7.18342 5.90237 7.81658 5.90237 8.20711 6.29289L12.5 10.5858L16.7929 6.29289C17.1834 5.90237 17.8166 5.90237 18.2071 6.29289C18.5976 6.68342 18.5976 7.31658 18.2071 7.70711L13.9142 12L18.2071 16.2929C18.5976 16.6834 18.5976 17.3166 18.2071 17.7071C17.8166 18.0976 17.1834 18.0976 16.7929 17.7071L12.5 13.4142L8.20711 17.7071C7.81658 18.0976 7.18342 18.0976 6.79289 17.7071C6.40237 17.3166 6.40237 16.6834 6.79289 16.2929L11.0858 12L6.79289 7.70711C6.40237 7.31658 6.40237 6.68342 6.79289 6.29289Z"
      fill="#50565E"
      fillRule="evenodd"
    />
  </svg>
)
export default IconClose
;((_data) => {})([React])
