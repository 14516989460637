import classnames from 'classnames'
import PropTypes from 'prop-types'
import * as React from 'react'

import UikAvatar from '../UikAvatar'
import UikDropdown from '../UikDropdown'
import UikDropdownItem from '../UikDropdownItem'
import UikTag from '../UikTag'
import UikWidget from '../UikWidget'
import UikonWrap from '../UikonWrap'

import DisplayComponentAction from './DisplayComponentAction'

import cls from './knowledge-topic.scss'

const UikKnowledgeTopic = ({
  children,
  Component,
  className,
  icon: { className: iconClassName, ...icon },
  title,
  description,
  users,
  isAdmin,
  ...rest
}) => (
  <Component className={classnames(cls.wrapper, className)} padding {...rest}>
    <UikonWrap {...icon} className={classnames(cls.icon, iconClassName)} />
    <div className={cls.content}>
      <h2 className={cls.title}>
        <a className={cls.link}>{title}</a>
        {isAdmin && (
          <UikTag className={cls.tag} color="green">
            Admin
          </UikTag>
        )}
      </h2>
      <p>{description}</p>
    </div>
    {users && (
      <div className={cls.users}>
        {users.map((user) => (
          <UikAvatar key={user.imgUrl} {...user} />
        ))}
      </div>
    )}
    {isAdmin && (
      <UikDropdown
        DisplayComponent={DisplayComponentAction}
        className={cls.action}
        position="bottomRight"
      >
        <UikDropdownItem>Settings</UikDropdownItem>
        <UikDropdownItem>Your Profile</UikDropdownItem>
        <UikDropdownItem>Help & Support center</UikDropdownItem>
        <UikDropdownItem>Logout</UikDropdownItem>
      </UikDropdown>
    )}
  </Component>
)

UikKnowledgeTopic.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  Component: PropTypes.any, // eslint-disable-line
  title: PropTypes.string.isRequired,
  description: PropTypes.string,

  /* data */
  isAdmin: PropTypes.bool,
  icon: PropTypes.instanceOf(Object).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      imgUrl: PropTypes.string.isRequired,
      action: PropTypes.oneOf(['like', 'comment']).isRequired,
    })
  ),
}

UikKnowledgeTopic.defaultProps = {
  className: null,
  description: null,
  users: null,
  isAdmin: false,
  children: null,
  Component: UikWidget,
}

export default UikKnowledgeTopic
;((_data) => {})([React])
