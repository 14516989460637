import {
  UikButton,
  UikFormInputGroup,
  UikHeadline,
  UikInput,
} from '@components'
import Axios from 'axios'
import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { useCookies } from 'react-cookie'

import { userNameAtom } from '@atoms/atoms'
import apiRoute from '@constants/apiRoute'
import { handleLoginSuccess } from '@helper'
import { withContext } from '@hoc'
import { usePublicIp } from '@hooks'
import logo from '../../../driverDocAssets/DriverDocMainLogo.png'
import ShowEye from './components/showEye'
import cls from './reset-password.scss'
// const publicIp = require('public-ip')

function ResetPassword({ history, context }) {
  const [, setUserName] = useAtom(userNameAtom)
  const initialErrors = {
    password: '',
    confirmPassword: '',
  }
  const [cookies, setCookie, removeCookie] = useCookies([
    'token',
    'user_name',
    'company_name',
    'rs',
  ])
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(initialErrors)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [typePassword, setTypePassword] = useState('password')
  const [typeConfirmPassword, setTypeConfirmPassword] = useState('password')

  const [errorSubmit, setErrorSubmit] = useState('')
  const [ipPublic] = usePublicIp()

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'password':
        setPassword(e.target.value)
        break

      case 'confirmPassword':
        setConfirmPassword(e.target.value)
        break
      default:
        break
    }
    if (password.length !== '' || confirmPassword.length !== '') {
      setError('')
    }
  }

  const handleTogglePass = () => {
    if (typePassword === 'password') {
      setTypePassword('text')
    } else if (typePassword === 'text') {
      setTypePassword('password')
    }
  }

  const handleToggleConfirmPassword = () => {
    if (typeConfirmPassword === 'password') {
      setTypeConfirmPassword('text')
    } else if (typeConfirmPassword === 'text') {
      setTypeConfirmPassword('password')
    }
  }

  const handleSubmit = async () => {
    const _checkPass = document.getElementById('password')
    const _checkConfirm = document.getElementById('confirmPassword')

    if (password === '' || password === undefined) {
      const errPassword = {
        password: "Password can't be blank",
        confirmPassword: '',
      }
      _checkPass.focus()
      setError(errPassword)
    } else if (confirmPassword !== password) {
      const errConfirmPass = {
        password: '',
        confirmPassword: "Passwords don't match",
      }
      _checkConfirm.focus()
      setError(errConfirmPass)
    } else {
      setLoading(true)
      const apiResetPassword = `${apiRoute.resetPassword}/${cookies.rs}`
      const bodyRequest = {
        passWord: password,
        confirmPassword,
        browserVersion: navigator.userAgent,
        ipPublic,
      }
      try {
        const { data: dataResponse } = await Axios.post(
          apiResetPassword,
          bodyRequest
        )
        if (dataResponse.success) {
          context.message.success('Changed Successfully')
          handleLoginSuccess(setCookie, dataResponse, history, setUserName)
          removeCookie('rs', { path: '/' })
        } else {
          //
        }
      } catch (err) {
        setErrorSubmit(err.response.data.messages)
      } finally {
        setLoading(false)
      }
    }
  }
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit()
    }
  }
  return (
    <div className={cls.reset_password}>
      <div className={cls.content_reset}>
        <div className={cls.img_left}>
          <img alt="" className={cls.img_logo} src={logo} />
        </div>
        <div className={cls.form_right}>
          <UikHeadline className={cls.headline}>Reset Password</UikHeadline>
          <UikFormInputGroup className={cls.gr_password}>
            <label className={cls.labelPassword}>Password</label>
            <UikInput
              className={cls.ip_password}
              errorMessage={error.password}
              id="password"
              maxLength="20"
              name="password"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder="Password"
              type={typePassword}
              value={password}
            />
            <span className={cls.toggle_password} onClick={handleTogglePass}>
              <ShowEye />
            </span>
          </UikFormInputGroup>
          <UikFormInputGroup className={cls.gr_confirm}>
            <label className={cls.labelConfirm}>Confirm Password</label>
            <UikInput
              className={cls.ip_confirm_password}
              errorMessage={error.confirmPassword}
              id="confirmPassword"
              maxLength="20"
              name="confirmPassword"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder="Confirm Password"
              type={typeConfirmPassword}
              value={confirmPassword}
            />
            <span
              className={cls.toggle_confirm_password}
              onClick={handleToggleConfirmPassword}
            >
              <ShowEye />
            </span>
          </UikFormInputGroup>
          <UikButton
            className={cls.btn_submit}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            <span className={cls.value}>Submit</span>
          </UikButton>
          {errorSubmit && <p style={{ color: 'red' }}>{errorSubmit}</p>}
        </div>
      </div>
    </div>
  )
}

export default withContext(ResetPassword)
;((_data) => {})([React])
