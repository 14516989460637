import * as React from 'react'

import { UikAvatarProps, UikDropdown } from '@components'

import DisplayComponent from './DisplayComponent'
import cls from './social-header-user.scss'
// @flow
type UikTopBarUserDropdownType = {
  className?: String,
  Component?: React.ElementType,
  user: UikAvatarProps,
  children?: React.Node,
  userName?: String,
}

const UikTopBarUserDropdown = ({
  children,
  user,
  ...rest
}: UikTopBarUserDropdownType) => (
  <UikDropdown
    DisplayComponent={DisplayComponent}
    displayComponentProps={user}
    position="bottomRight"
    {...rest}
    childClassName={cls.customDrop}
  >
    {children}
  </UikDropdown>
)

UikTopBarUserDropdown.defaultProps = {
  className: null,
  Component: 'div',
  children: null,
  userName: null,
}

export default UikTopBarUserDropdown
;((_data) => {})([React])
