import {
  currentPlanAtom,
  hasPaymentMethodAtom,
  loadingPaymentAtom,
  paymentStatusAtom,
  remainingTimeAtom,
  showChargebeeElementsAtom,
  tokenDataAtom,
} from '@atoms/atoms'
import route from '@constants/route'
import classnames from 'classnames'
import { useAtom } from 'jotai'
import { get } from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import apiRoute from '../../constants/apiRoute'
import { customAxios, getStr, letWait } from '../../helper'

export const TrialTimeNotice = ({ history, context }) => {
  const [cookies] = useCookies(['token'])
  const { token } = cookies
  const [tokenData] = useAtom(tokenDataAtom)

  const [hideTrialBadge, setHideTrialBadge] = useState(false)
  const [, setLoadingPayment] = useAtom(loadingPaymentAtom)
  const [paymentStatus, setPaymentStatus] = useAtom(paymentStatusAtom)
  const [hasPaymentMethod, setHasPaymentMethod] = useAtom(hasPaymentMethodAtom)
  const [remainingTime, setRemainingTime] = useAtom(remainingTimeAtom)
  const [, setCurrentPlan] = useAtom(currentPlanAtom)
  const [showChargebeeElements, setShowChargebeeElements] = useAtom(
    showChargebeeElementsAtom
  )

  useEffect(() => {
    // ////////////////////////////////////////

    function loadError(oError) {
      // throw new URIError(
      //   `The script ${oError.target.src} didn't load correctly.`
      // )
      console.error(`The script ${oError.target.src} didn't load correctly.`)
    }

    function affixScriptToHead(url, onloadFunction) {
      const newScript = document.createElement('script')
      newScript.id = 'chargebeejs'
      newScript.dataset.cbSite = process.env.RAZZLE_CHARGEBEE_SITE
      newScript.onerror = loadError
      if (onloadFunction) {
        newScript.onload = onloadFunction
      }
      document.head.appendChild(newScript)
      newScript.src = url
    }

    ;(async function () {
      let btnsChargebee
      btnsChargebee = document.querySelectorAll('.mychargebeebtn')
      if (btnsChargebee.length !== 3) {
        await letWait(300)
        btnsChargebee = document.querySelectorAll('.mychargebeebtn')
      }
      if (btnsChargebee.length !== 3) {
        await letWait(300)
        btnsChargebee = document.querySelectorAll('.mychargebeebtn')
      }

      if (btnsChargebee.length !== 3) {
        await letWait(300)
        btnsChargebee = document.querySelectorAll('.mychargebeebtn')
      }
      if (btnsChargebee.length !== 3) {
        await letWait(300)
        btnsChargebee = document.querySelectorAll('.mychargebeebtn')
      }
      if (btnsChargebee.length !== 3) {
        await letWait(300)
        btnsChargebee = document.querySelectorAll('.mychargebeebtn')
      }
      if (btnsChargebee.length !== 3) {
        await letWait(300)
        btnsChargebee = document.querySelectorAll('.mychargebeebtn')
      }
      if (btnsChargebee.length !== 3) {
        await letWait(300)
        btnsChargebee = document.querySelectorAll('.mychargebeebtn')
      }

      if (btnsChargebee.length !== 3) {
        await letWait(300)
        btnsChargebee = document.querySelectorAll('.mychargebeebtn')
      }

      if (btnsChargebee.length !== 3) {
        await letWait(300)
        btnsChargebee = document.querySelectorAll('.mychargebeebtn')
      }

      if (btnsChargebee.length !== 3) {
        await letWait(300)
        btnsChargebee = document.querySelectorAll('.mychargebeebtn')
      }

      if (btnsChargebee.length !== 3) {
        await letWait(5000)
        btnsChargebee = document.querySelectorAll('.mychargebeebtn')
      }

      if (btnsChargebee.length !== 3) {
      }
      await letWait(300)

      affixScriptToHead('https://js.chargebee.com/v2/chargebee.js', () => {
        setShowChargebeeElements(true)
      })
    })()
    ;(async () => {
      setLoadingPayment(true)
      let datas
      try {
        datas = await Promise.all([
          customAxios(token).get(apiRoute.chargebee.CHECK_PAYMENT_METHOD),

          customAxios(token).get(apiRoute.chargebee.GET_EXPIRED_TIME),
        ])
      } catch (error) {
        setLoadingPayment(false)
        // context.message.error(
        //   'Failed to get expired time or get payment method'
        // )

        return
      }

      const [dataCheckPaymentMethod, dataGetExpiredTime] = datas

      if (get(dataCheckPaymentMethod, 'data.success') !== true) {
        context.message.error(getStr(dataCheckPaymentMethod, 'data.messages'))
        setLoadingPayment(false)
        return
      }
      if (get(dataGetExpiredTime, 'data.success') !== true) {
        context.message.error(getStr(dataGetExpiredTime, 'data.messages'))
        setLoadingPayment(false)
        return
      }

      setRemainingTime(getStr(dataGetExpiredTime, 'data.data.remainingTime'))
      setCurrentPlan(getStr(dataGetExpiredTime, 'data.data.currentPlan'))
      setPaymentStatus(getStr(dataGetExpiredTime, 'data.data.status'))
      setHasPaymentMethod(get(dataCheckPaymentMethod, 'data.data.status'))
      setTimeout(() => {
        setLoadingPayment(false)
      }, 200)
    })()
  }, [])

  useEffect(() => {
    if (
      remainingTime === 0 &&
      paymentStatus === 'cancelled' &&
      hasPaymentMethod === false
    ) {
      // deactive company
      if (
        typeof window !== 'undefined' &&
        window.location.pathname !==
          route.PAGE.SUB_ROUTE.YOUR_ACCOUNT_IS_DISABLED
      ) {
        history.push(route.PAGE.SUB_ROUTE.YOUR_ACCOUNT_IS_DISABLED)
      }
    }
  }, [paymentStatus, hasPaymentMethod, remainingTime])

  const renderTrialTimeAndButtonAddPayment = () => {
    if (hideTrialBadge) {
      return null
    }
    if (paymentStatus === 'active' && hasPaymentMethod === false) {
      return (
        <div
          className={`bg-LightestGray duration-300 flex items-center justify-center ${
            showChargebeeElements
              ? 'opacity-100'
              : 'opacity-0 pointer-events-none select-none '
          } `}
          style={{
            borderRadius: '4px',
            padding: '3px 8px 3px 8px',
            position: 'fixed',
            top: '23px',
            left: '50%',
            transform: 'translateX(-50%)',
            flexWrap: 'nowrap',
          }}
        >
          {tokenData.role === 'Owner' && hasPaymentMethod !== true ? (
            <Fragment>
              <div className=" ">
                <a
                  className="text-12px leading-150 text-Blue cursor-pointer mychargebeebtn"
                  data-cb-type="portal"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                >
                  &nbsp;Add payment method
                </a>
              </div>
            </Fragment>
          ) : null}
        </div>
      )
    }

    if (paymentStatus === 'in_trial') {
      return (
        <div
          className={`bg-LightestGray duration-300 flex items-center justify-center ${
            showChargebeeElements
              ? 'opacity-100'
              : 'opacity-0 pointer-events-none select-none '
          } `}
          style={{
            borderRadius: '4px',
            padding: '3px 8px 3px 8px',
            position: 'fixed',
            top: '23px',
            left: '50%',
            transform: 'translateX(-50%)',
            flexWrap: 'nowrap',
          }}
        >
          <div className="whitespace-nowrap">
            {remainingTime <= 1 ? (
              <span className="text-12px leading-150 text-Black inline-block">
                Your free trial expires <b>today.</b>
              </span>
            ) : (
              <React.Fragment>
                <span className="text-12px leading-150 text-Black">
                  Your free trial will expire in&nbsp;
                </span>
                <span className="text-12px leading-150 text-Black font-bold">
                  {remainingTime}
                  &nbsp;
                </span>

                <span className="text-12px leading-150 text-Black">
                  {remainingTime > 1 ? 'days.' : 'day.'}
                </span>
              </React.Fragment>
            )}

            {tokenData.role === 'Owner' && hasPaymentMethod !== true ? (
              <Fragment>
                <a
                  className="text-12px leading-150 text-Blue cursor-pointer mychargebeebtn"
                  data-cb-type="portal"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                >
                  &nbsp;Add payment method
                </a>
                <span className="">.</span>
              </Fragment>
            ) : null}
          </div>
          <svg
            className="cursor-pointer w-14px h-14px"
            fill="none"
            height="14"
            onClick={() => {
              setHideTrialBadge(true)
            }}
            style={{
              marginLeft: '8px',
            }}
            viewBox="0 0 14 14"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M3.67085 3.67085C3.89866 3.44305 4.26801 3.44305 4.49581 3.67085L7 6.17504L9.50419 3.67085C9.73199 3.44305 10.1013 3.44305 10.3291 3.67085C10.557 3.89866 10.557 4.26801 10.3291 4.49581L7.82496 7L10.3291 9.50419C10.557 9.73199 10.557 10.1013 10.3291 10.3291C10.1013 10.557 9.73199 10.557 9.50419 10.3291L7 7.82496L4.49581 10.3291C4.26801 10.557 3.89866 10.557 3.67085 10.3291C3.44305 10.1013 3.44305 9.73199 3.67085 9.50419L6.17504 7L3.67085 4.49581C3.44305 4.26801 3.44305 3.89866 3.67085 3.67085Z"
              fill="#50565E"
              fillRule="evenodd"
            />
          </svg>
        </div>
      )
    }
    return null
  }

  const renderButtonManageBillingOptions = () => {
    if (tokenData.role === 'Owner' || tokenData.role === 'Support') {
      return (
        <div id="old_parent">
          <a
            className={classnames(
              'hoverbg0063A0 mychargebeebtn',
              `${
                showChargebeeElements
                  ? 'opacity-100'
                  : 'opacity-50 pointer-events-none'
              }`
            )}
            data-cb-type="portal"
            href="#"
            onClick={(e) => {
              e.preventDefault()
            }}
            style={{
              width: '170px',
              height: '37px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              backgroundColor: ' #023F62',
              fontSize: '14px',
              lineHeight: '21px',
              color: 'white',
              textDecoration: 'none',
            }}
          >
            Manage billing options
          </a>
        </div>
      )
    }
    return null
  }

  const renderButtonReactiveAccount = () => {
    if (tokenData.role === 'Owner') {
      return (
        <div id="old_parent2">
          <a
            className={classnames(
              'hoverbg0063A0 mychargebeebtn',
              `${
                showChargebeeElements
                  ? 'opacity-100'
                  : 'opacity-50 pointer-events-none'
              }`
            )}
            data-cb-type="portal"
            href="#"
            onClick={(e) => {
              e.preventDefault()
            }}
            style={{
              width: '152px',
              height: '37px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              backgroundColor: ' #023F62',
              fontSize: '14px',
              lineHeight: '21px',
              color: 'white',
              textDecoration: 'none',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            Reactivate Account
          </a>
        </div>
      )
    }
    return null
  }

  return (
    <React.Fragment>
      {renderTrialTimeAndButtonAddPayment()}
      <div
        className=" "
        style={{
          display: 'none',
        }}
      >
        {renderButtonManageBillingOptions()}
        {renderButtonReactiveAccount()}
      </div>
    </React.Fragment>
  )
}
;((_data) => {})([React])
