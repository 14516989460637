import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import { ArrowDropdown } from '@appComponent/icon'
import LoadingSkeleton from '@appComponent/loadingSkeleton'

import cls from './common.scss'

const CustomerInput = ({
  label,
  customerValue,
  listCustomer,
  loadingCustomer,
  onHandleChangeCustomer,
  onHandleClickCustomer,
  onHandleClickIcon,
  error,
  onHandleErrorCustomer,
  isReadOnly = false,
}) => {
  const customerRef = useRef(null)
  const customerElementRef = useRef([])
  const listCustomerSelectionRef = useRef(null)
  const [isChosenCustomer, setChosenCustomer] = useState(false)
  const [showListCustomer, setShowListCustomer] = useState(false)
  const [customerCursor, setCustomerCursor] = useState(0)
  const [isClickedIcon, setIsClickedIcon] = useState(false)

  const [arrowDirection, setArrowDirection] = useState(null)

  useEffect(() => {
    if (isClickedIcon) {
      onHandleClickIcon()
      setShowListCustomer(true)
    }
    if (error) {
      onHandleErrorCustomer('')
    }
  }, [isClickedIcon])
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside)
    // window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
      // window.removeEventListener('keydown', handleKeyDown)
    }
  })

  useEffect(() => {
    if (
      listCustomerSelectionRef &&
      listCustomerSelectionRef.current &&
      customerElementRef &&
      customerElementRef.current &&
      showListCustomer &&
      arrowDirection === 'down' &&
      customerCursor % 7 === 0
    ) {
      listCustomerSelectionRef.current.scrollTo(
        0,
        customerElementRef.current[customerCursor].offsetTop
      )
    } else if (
      listCustomerSelectionRef &&
      listCustomerSelectionRef.current &&
      customerElementRef &&
      customerElementRef.current &&
      showListCustomer &&
      arrowDirection === 'up' &&
      (customerCursor + 1) % 7 === 0
    ) {
      listCustomerSelectionRef.current.scrollTo(
        0,
        customerElementRef.current[customerCursor].offsetTop - 266
      )
    }
  })

  const handleClickOutside = (event) => {
    if (customerRef.current && listCustomer.length === 0 && showListCustomer) {
      setShowListCustomer(false)
      if (isChosenCustomer) {
        onHandleErrorCustomer('')
      } else {
        onHandleErrorCustomer('error')
      }
    }
    if (
      customerRef.current &&
      listCustomerSelectionRef.current &&
      !customerRef.current.contains(event.target) &&
      !listCustomerSelectionRef.current.contains(event.target) &&
      showListCustomer
    ) {
      setShowListCustomer(false)
      if (isChosenCustomer) {
        onHandleErrorCustomer('')
      } else {
        onHandleErrorCustomer('error')
      }
    }
  }

  const handleChange = (e) => {
    if (e.target.value === '') {
      onHandleErrorCustomer('')
    }
    if (isChosenCustomer) {
      setChosenCustomer(false)
    }
    setShowListCustomer(true)
    onHandleChangeCustomer(e)
  }
  const handleFocusCustomer = (e) => {
    if (e.target.value !== '' && !isReadOnly) {
      setShowListCustomer(true)
    }
  }

  const handleKeyDownCustomerName = (e) => {
    let selectionLength = listCustomer.length
    function choosingCustomer() {
      let customer = listCustomer[customerCursor]
      setChosenCustomer(true)
      onHandleErrorCustomer('')
      setShowListCustomer(false)
      onHandleClickCustomer(customer.customer_name, customer.id_customer)
    }
    if (e.keyCode === 40 && customerCursor < selectionLength - 1) {
      e.preventDefault()
      if (arrowDirection !== 'down') {
        setArrowDirection('down')
      }
      setCustomerCursor(customerCursor + 1)
    }
    if (e.keyCode === 38 && customerCursor > 0) {
      e.preventDefault()
      if (arrowDirection !== 'up') {
        setArrowDirection('up')
      }
      setCustomerCursor(customerCursor - 1)
    }
    if (e.keyCode === 9) {
      setShowListCustomer(false)
      if (!isChosenCustomer && customerValue !== '' && !isReadOnly) {
        onHandleErrorCustomer('error')
      }
      if (customerValue && selectionLength > 0) {
        choosingCustomer()
      }
    }
    if (e.keyCode === 13 && selectionLength > 0) {
      choosingCustomer()
    }
  }
  const handleClickCustomer = (customer) => () => {
    setChosenCustomer(true)
    setShowListCustomer(false)
    setIsClickedIcon(false)
    onHandleErrorCustomer('')
    onHandleClickCustomer(customer.customer_name, customer.id_customer)
  }

  const handleMouseOver = (index) => () => {
    setCustomerCursor(index)
  }

  const handleClickIconDropdown = () => {
    setIsClickedIcon(!isClickedIcon)
  }
  return (
    <div className={cls.customer_name}>
      <label className={cls.label}>{label}</label>
      <input
        autoComplete="off"
        className={classnames({
          [cls.error_border]: error,
        })}
        id="customer_name"
        name="customer_name"
        onChange={handleChange}
        onFocus={handleFocusCustomer}
        onKeyDown={handleKeyDownCustomerName}
        readOnly={isReadOnly}
        ref={customerRef}
        type="text"
        value={customerValue}
      />
      <span className={cls.dropdown_icon} onClick={handleClickIconDropdown}>
        <ArrowDropdown />
      </span>
      {showListCustomer && loadingCustomer && (
        <div className={cls.load_wrapper}>
          <LoadingSkeleton />
        </div>
      )}
      {showListCustomer && !loadingCustomer && listCustomer.length === 0 && (
        <div className={cls.no_data_found}>No Data</div>
      )}
      {showListCustomer && !loadingCustomer && listCustomer.length > 0 && (
        <ul className={cls.customer_list} ref={listCustomerSelectionRef}>
          {listCustomer.map((item, index) => {
            return (
              <li
                className={classnames(cls.customer_sc, {
                  [cls.activeItem]: customerCursor === index,
                })}
                key={index}
                onClick={handleClickCustomer(item)}
                onMouseOver={handleMouseOver(index)}
                ref={(el) => (customerElementRef.current[index] = el)}
              >
                {item.customer_name}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default CustomerInput
;((_data) => {})([React])
