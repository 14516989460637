let baseUrl = process.env.RAZZLE_API_URL
if (typeof window !== 'undefined') {
  baseUrl = localStorage.getItem('baseurl') || baseUrl
}

const apiVersion = 'api/v2'

const apiPrefix = `${baseUrl}/${apiVersion}`

const apiRoute = {
  base: baseUrl,
  login: `${apiPrefix}/users/loginAdmin`,
  forgetPassword: `${apiPrefix}/users`,
  changePassword: `${apiPrefix}/users/changePassword`,
  createNewPassCognito: `${apiPrefix}/users`,
  resetPassword: `${apiPrefix}/users/loginFirsttime/resetPassword`,
  verifyAuthToken: `${apiPrefix}/users/verify-auth-enrollment`,
  GetTokenForCreatingNewPassword: `${apiPrefix}/users/resetPassword`,
  CreateNewPasswordForNewUser: `${apiPrefix}/users/changePassword/resetPassword`,
  Reset: `${apiPrefix}/users`,
  Contact: `${apiPrefix}/users/contactInfo`,
  GetCodeVerification: `${apiPrefix}/users/get-code-verification`,
  ConfirmCodeChangeEmail: `${apiPrefix}/users/confirm-change-email`,
  signUp: {
    CREATE_ACCOUNT: `${apiPrefix}/users/signup`,
    CONFIRM: `${apiPrefix}/users/confirmation`,
    RESEND_CODE: `${apiPrefix}/users/resend-code`,
  },
  smart_eta: {
    LIST: `${apiPrefix}/dash_board`,
    STOP_DETAIL: `${apiPrefix}/dash_board/detail_trip`,
  },
  customers: {
    BASE_CUSTOMERS: `${apiPrefix}/customers`,
    ADD: `${apiPrefix}/customers`,
    SEARCH: `${apiPrefix}/customers/search?`,
    DETAIL: `${apiPrefix}/customers/detailCustomer`,
    ACTIVE_CUSTOMERS: `${apiPrefix}/customers/activeCustomer?`,
  },
  drivers: {
    BASE_DRIVER: `${apiPrefix}/drivers/searchDrivers`,
    ADD_DRIVER: `${apiPrefix}/drivers`,
    ADD_BULK_DRIVER: `${apiPrefix}/drivers/bulk`,
    EDIT_DRIVER: `${apiPrefix}/drivers`,
    DELETE_DRIVER: `${apiPrefix}/drivers`,
    RESEND: `${apiPrefix}/drivers`,
    LIST: `${apiPrefix}/drivers`,
    SEARCH: `${apiPrefix}/drivers/search?`,
    ACTIVE_DRIVERS: `${apiPrefix}/drivers/activeDriver?`,
    GET_DRIVER_BY_ID: `${apiPrefix}/drivers`,
    UNLOCK_DRIVER: `${apiPrefix}/drivers/unlock`,
  },
  vendors: {
    BASE_VENDORS: `${apiPrefix}/vendors`,
    LIST: `${apiPrefix}/vendors`,
    SEARCH: `${apiPrefix}/vendors`,
    ADD: `${apiPrefix}/vendors`,
    DETAIL: `${apiPrefix}/vendors/edit`,
    UPDATE: `${apiPrefix}/vendors/update`,
    CHANGE_STATUS: `${apiPrefix}/vendors/changeVendorStatus`,
    ACTIVE_VENDORS: `${apiPrefix}/vendors/activeVendor?`,
  },
  trucks: {
    BASE_TRUCK: `${apiPrefix}/trucks`,
    LIST: `${apiPrefix}/trucks`,
    SEARCH: `${apiPrefix}/trucks/search?`,
    ACTIVE_TRUCKS: `${apiPrefix}/trucks/listTruckActive?`,
  },
  loads: {
    BASE_LOAD: `${apiPrefix}/loads`,
    DETAIL: `${apiPrefix}/loads/detailLoad`,
    LOAD_DETAIL: `${apiPrefix}/loads/getDetailLoadInfo`,
    SEARCH: `${apiPrefix}/loads/searchLoad`,
    TRIPSBYLOADID: `${apiPrefix}/trips/tripsByLoadId`,
    DOCUMENTSBYLOADID: `${apiPrefix}/documents/web/documentsByLoadId`,
    SAVE: `${apiPrefix}/loads/createLoad/save`,
    DISPATCH: `${apiPrefix}/loads/createLoad/dispatch`,
    UPDATE_SAVE: `${apiPrefix}/loads/updateLoad/save`,
    UPDATE_DISPATCH: `${apiPrefix}/loads/updateLoad/dispatch`,
    DELETE: `${apiPrefix}/loads/deleteLoad`,
    UPDATE_DATE: `${apiPrefix}/loads/updateManual`,
    CAN_COMPLETE: `${apiPrefix}/loads/web/canLoadsComplete`,
    COMPLETE: `${apiPrefix}/loads/complete`,
  },
  documents: {
    SEARCH: `${apiPrefix}/documents/web/searchDocuments`,
    DOCUMENT_TYPES: `${apiPrefix}/document_types/web`,
    GET_LOADS: `${apiPrefix}/documents/web/getLoadsWhenUpload`,
    UPLOAD_DOCUMENT: `${apiPrefix}/documents/uploadInWeb`,
    REJECT_DOCUMENTS: `${apiPrefix}/documents/web/rejectDocuments`,
    DELETE_DOCUMENTS: `${apiPrefix}/documents/web/deleteDocuments`,
    ARCHIVE_DOCUMENTS: `${apiPrefix}/documents/web/archiveDocuments`,
    UNARCHIVE_DOCUMENTS: `${apiPrefix}/documents/web/unArchiveDocuments`,
    DOCUMENT_BY_ID: `${apiPrefix}/documents/web`,
    EDIT_DOCUMENT: `${apiPrefix}/documents/web/editDocument`,
    EDIT_DOCUMENTS: `${apiPrefix}/documents/web/editDocuments`,
    GET_URLS_BY_DOCUMENT_IDS: `${apiPrefix}/documents/web/downloadDocuments`,
    SEARCH_LOAD_BY_ID_DRIVER_WHEN_REQUEST_DOC: `${apiPrefix}/documents/web/searchLoadsWhenRequest`,
    REQUEST_DOCUMENT: `${apiPrefix}/documents/web/requestDocument`,
    RESEND_REQUEST_DOCUMENT: `${apiPrefix}/documents/web/resendRequestDocuments`,
    CANCEL_REQUEST_DOCUMENT: `${apiPrefix}/documents/web/cancelRequestDocuments`,
    SEND_DOCUMENT: `${apiPrefix}/documents/web/sendDocuments`,
    MERGE_DOCUMENT: `${apiPrefix}/documents/web/mergeDocuments`,
    GET_PRESIGNED_URL: `${apiPrefix}/documents/web/presignedUrls`,
    SPLIT_DOCUMENT: `${apiPrefix}/documents/web/splitDocument`,
    DELETE_DOCUMENT_FILE: `${apiPrefix}/documents/web/removeFiles`,
  },
  company: {
    COMPANY: `${apiPrefix}/dash_board/profile`,
  },
  users: {
    GET_LOGGED_IN_USER_INFO: `${apiPrefix}/users/status`,
    LIST: `${apiPrefix}/users`,
    GET_USER_BY_ID: `${apiPrefix}/users`,
    ADD: `${apiPrefix}/users/addUser`,
    EDIT: `${apiPrefix}/users/editUser`,
    DELETE: `${apiPrefix}/users/deleteUser`,
    ACTIVATE: `${apiPrefix}/users/activateUser`,
    DEACTIVATE: `${apiPrefix}/users/deactivateUser`,
    RESEND: `${apiPrefix}/users/resend-code`,
  },
  google: {
    COUNT: `${apiPrefix}/countGoogleCall`,
  },
  storage: {
    STORAGE: `${apiPrefix}/dash_board/storage`,
  },
  automations: `${apiPrefix}/users/companyEmailAlias`,
  groups: {
    LIST: `${apiPrefix}/groups/searchMyGroups`,
    DELETE: `${apiPrefix}/groups/deleteGroup`,
    ACTIVATE: `${apiPrefix}/groups/activateGroup`,
    DEACTIVATE: `${apiPrefix}/groups/deactivateGroup`,
    ADD: `${apiPrefix}/groups/addGroup`,
    EDIT: `${apiPrefix}/groups/editGroup`, // TODO
    LIST_MY_GROUP: `${apiPrefix}/groups/listMyGroups`,
    SEARCH_GROUP_MEMBERS: `${apiPrefix}/groups/searchMembers`,
    GET_GROUP_BY_ID: `${apiPrefix}/groups/detail`, // TODO
    GET_ACTIVE_GROUPS: `${apiPrefix}/groups/activeGroups`, // TODO
  },
  roles: {
    GET_ROLES: `${apiPrefix}/roles`,
  },
  chargebee: {
    CHECK_PAYMENT_METHOD: `${apiPrefix}/chargebee/checkPaymentMethod`,
    GET_EXPIRED_TIME: `${apiPrefix}/chargebee/expireTime`,
  },
}

export default apiRoute
