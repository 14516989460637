import { MyCheckbox } from '@appComponent/MyCheckbox'
import { Button } from '@appComponent/button'
import { InfoIcon } from '@appComponent/icon'
import {
  Input2,
  InputFile2,
  InputSearchDriverWhenAddLoad,
  Textarea2,
} from '@appComponent/input'
import usePrevious from '@appComponent/usePrevious'
import apiRoute from '@constants/apiRoute'
import {
  customAxios,
  getEdittedListTrip,
  getErrorMessage,
  getNewStopData,
  getStr,
  toastError,
} from '@helper'
import { useGroups } from '@hooks'
import { Drawer } from 'antd'
import classnames from 'classnames'
import produce from 'immer'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import OneStopAdd from './OneStopAdd'
import cls from './loadsnew.scss'
import { identity, trim } from 'lodash'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const checkErrorsDispatch = ({
  draft,
  loadnumber,
  idDriver,
  listTrip,
}) => {
  if (!trim(loadnumber)) {
    draft.loadnumber = 'Can not contains spaces only'
  }

  if (!loadnumber) {
    draft.loadnumber = 'Required field'
  }

  if (!idDriver) {
    draft.idDriver = 'Required field'
  }

  const trips = [...draft.trips]
  draft.trips = trips.map((tripError, index) => {
    const trip = listTrip[index]
    if (!trip.tripName) {
      tripError.tripName = 'Required field'
    }

    if (trip.tracking === true) {
      if (!getStr(trip, 'location.address')) {
        tripError.location = 'Required field'
      }

      if (!trip.ld) {
        tripError.ld = 'Required field'
      }
      if (!trip.lt) {
        tripError.lt = 'Required field'
      }
      if (Number(trip.isAppointment) === 0) {
        if (!trip.ed) {
          tripError.ed = 'Required field'
        }
        if (!trip.et) {
          tripError.et = 'Required field'
        }
      }
    } else {
      if (!!trip.ld && !trip.lt) {
        tripError.lt = 'You have date but not have time'
      }
      if (!!trip.lt && !trip.ld) {
        tripError.ld = 'You have time but not have date'
      }

      if (!!trip.ed && !trip.et) {
        tripError.et = 'You have date but not have time'
      }
      if (!!trip.et && !trip.ed) {
        tripError.ed = 'You have time but not have date'
      }
    }

    return tripError
  })
}

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#fff' : '#fff',
  border: isDraggingOver
    ? '2px groove rgba(80, 86, 94, 0.4)'
    : '2px groove rgba(0,0,0,0)',
  transition: 'all 0.2s linear',
  // background: isDraggingOver ? 'lightblue' : '#fff',
  padding: '10px',
  marginLeft: '12px',
  marginRight: '12px',
  borderRadius: '4px',
  marginTop: '-12px',
})

export const checkEmptyErrorsDispatchForm = (errors) => {
  // shadow
  const { trips } = errors
  const isEmptyTripsErrors = trips.every((trip) => {
    return (
      !trip.ed &&
      !trip.et &&
      !trip.ld &&
      !trip.lt &&
      !trip.location &&
      !trip.tripName
    )
  })
  return !errors.idDriver && !errors.loadnumber && isEmptyTripsErrors
}

const checkAtleastRequiredField = ({
  loadnumber,
  idDriver,
  loadsheetFile,
  note,
  truck,
  trailer,
  listTrip,
}) => {
  if (
    !!loadnumber ||
    !!idDriver ||
    !!loadsheetFile ||
    !!note ||
    !!truck ||
    !!trailer
  ) {
    return true
  }
  for (const item of listTrip) {
    if (
      !!item.order ||
      !!item.tripName ||
      !!item.tripType ||
      !!item.location.address ||
      !!item.appointmentNumber ||
      !!item.ld ||
      !!item.lt ||
      !!item.ed ||
      !!item.et ||
      item.note !== ''
    ) {
      return true
    }
  }
  return false
}

export const initValueErrors = {
  loadnumber: '',
  idDriver: '',
  trips: [
    {
      tripName: '',
      location: '',
      ld: '',
      lt: '',
      ed: '',
      et: '',
    },
  ],
}

const DrawerAddLoad = identity(
  ({ showAdd, setShowAdd, token, context, set_time_request }) => {
    const { listIdCheckedGroups } = useGroups()
    const showAddPrev = usePrevious(showAdd)
    const [value, setValue] = useState('')
    const containerref = useRef()
    const [savingOrDispatching, setSavingOrDispatching] = useState(false)
    const [isHover, setHover] = useState(false)
    const [loadnumber, set_loadnumber] = useState('')
    const [drivers, set_drivers] = useState([])
    const [idDriver, set_idDriver] = useState(null)
    const [note, set_note] = useState('')
    const [truck, set_truck] = useState('')
    const [trailer, set_trailer] = useState('')
    const [listTrip, setListTrip] = useState([getNewStopData(1)]) //
    const [loadsheetFile, setLoadsheetFile] = useState(undefined)
    const [loadingDriver, setLoadingDriver] = useState(false)

    const [errors, setErrors] = useState(initValueErrors)

    const isAllStopSmartETA = useMemo(() => {
      return listTrip.every((o) => o.tracking === true)
    }, [listTrip])

    const locationRef = useRef([])

    const onClose = () => {
      setShowAdd(false)
      resetFields()
    }

    const handleClickToggleCollapse = (i) => {
      const nextState = produce(listTrip, (draft) => {
        draft[i].collapse = !draft[i].collapse
      })
      setListTrip(nextState)
    }

    const handleClickAppt = (i) => {
      const nextState = produce(listTrip, (draft) => {
        if (draft[i].isAppointment === 1) {
          draft[i].isAppointment = 0
        } else if (draft[i].isAppointment === 0) {
          draft[i].isAppointment = 1
        }
      })
      setListTrip(nextState)
    }

    const handleDeleteTrip = (i) => {
      const nextState = produce(listTrip, (draft) => {
        draft.splice(i, 1)
      })

      const nextStateErrors = produce(errors, (draft) => {
        const trips = [...draft.trips]
        trips.splice(i, 1)
        draft.trips = trips
      })

      setListTrip(nextState)
      setErrors(nextStateErrors)
    }

    const onDragEnd = (result) => {
      const { destination } = result
      if (!destination) {
        return
      }
      // TODO
      // reOrderTrip(source, destination)
      const listTripReordered = reorder(
        listTrip,
        result.source.index,
        result.destination.index
      )

      const errorTripsReordered = reorder(
        errors.trips,
        result.source.index,
        result.destination.index
      )

      setErrors((prev) => ({ ...prev, trips: errorTripsReordered }))

      setListTrip(listTripReordered)
    }

    const updateListTrip = (uniqueid, field, value) => {
      const nextState = produce(listTrip, (draft) => {
        draft.filter((o) => o.uniqueid === uniqueid)[0][field] = value
      })
      setListTrip(nextState)
    }

    const handleAddStop = () => {
      const nextState = produce(listTrip, (draft) => {
        const tripNumber = listTrip.length + 1
        draft.push(getNewStopData(tripNumber))
      })
      setListTrip(nextState)

      const nextStateErrors = produce(errors, (draft) => {
        draft.trips.push({
          tripName: '',
          location: '',
          ld: '',
          lt: '',
          ed: '',
          et: '',
        })
      })
      setErrors(nextStateErrors)

      setTimeout(() => {
        const el = document.getElementById(
          `inputstoptype-${nextState.length - 1}`
        )
        if (el) {
          el.focus()
        }
        // reset scroll to top
        const antDrawerBody = Array.from(
          document.querySelectorAll('.wr_drawer_add .ant-drawer-body')
        )[0]
        if (antDrawerBody) {
          antDrawerBody.scrollTop = antDrawerBody.scrollHeight
        }
      }, 100)
    }

    const resetFields = () => {
      setValue('')
      set_truck('')
      set_trailer('')
      set_loadnumber('')
      set_idDriver(null)
      set_drivers([])
      set_note('')
      setListTrip([getNewStopData(1)])
      setLoadsheetFile(undefined)
      setErrors(initValueErrors)

      // reset scroll to top
      const antDrawerBody = Array.from(
        document.querySelectorAll('.wr_drawer_add .ant-drawer-body')
      )[0]
      if (antDrawerBody) {
        antDrawerBody.scrollTop = 0
      }
    }

    const handleClickSave = async () => {
      let atLeast1FieldIsFilled = checkAtleastRequiredField({
        idDriver,
        listTrip,
        loadnumber,
        loadsheetFile,
        note,
        trailer,
        truck,
      })

      if (!atLeast1FieldIsFilled) {
        toastError('You need to have at least 1 field filled out')
        return
      }

      try {
        const getData = (a) => {
          return a ? String(a) : ''
        }
        setSavingOrDispatching(true)
        const formData = new FormData()

        formData.append('loadSheet', loadsheetFile)
        formData.append('idDriver', getData(idDriver))
        formData.append('note', getData(note ? note.trim() : ''))
        formData.append('loadNumber', getData(loadnumber))
        formData.append('truck', getData(truck))
        formData.append('trailer', getData(trailer))

        const listTripEditted = getEdittedListTrip(listTrip)
        formData.append('trips', JSON.stringify(listTripEditted))

        const resp = await customAxios(token).post(
          `${apiRoute.loads.SAVE}?groups=${listIdCheckedGroups.join(',')}`,
          formData
        )
        if (!resp.data.success) {
          context.message.error('Adding Load Failed!')
          return
        }

        set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
        context.message.success('Added Successfully')
        onClose()
      } catch (err) {
        console.log(err.response)
        context.message.error(getErrorMessage(err))
      } finally {
        setTimeout(() => {
          setSavingOrDispatching(false)
        }, 3888)
      }
    }

    // const getActiveDrivers = useCallback(
    //   async text => {
    //     try {
    //       setLoadingDriver(true)
    //       const resp = await customAxios(token).get(
    //         `${
    //           apiRoute.drivers.ACTIVE_DRIVERS
    //         }text=${text}&groups=${listIdCheckedGroups.join(',')}`
    //       )
    //       set_drivers(resp.data.data)
    //     } catch (err) {
    //       //
    //     } finally {
    //       setLoadingDriver(false)
    //     }
    //   },
    //   [listIdCheckedGroups]
    // )

    const handleClickDispatch = async () => {
      const newErrorsDispatchForm = produce(errors, (draft) => {
        checkErrorsDispatch({
          draft,
          idDriver,
          listTrip,
          loadnumber,
        })
      })

      const isEmptyErrors = checkEmptyErrorsDispatchForm(newErrorsDispatchForm)

      if (!isEmptyErrors) {
        setErrors(newErrorsDispatchForm)
        return
      }

      try {
        setSavingOrDispatching(true)
        const listTripEditted = getEdittedListTrip(listTrip)
        const getData = (a) => {
          return a ? String(a) : ''
        }
        const formData = new FormData()
        formData.append('loadSheet', loadsheetFile)
        formData.append('idDriver', getData(idDriver))
        formData.append('note', getData(note.trim()))
        formData.append('loadNumber', getData(loadnumber))
        formData.append('truck', getData(truck))
        formData.append('trailer', getData(trailer))
        formData.append('trips', JSON.stringify(listTripEditted))

        const resp = await customAxios(token).post(
          `${apiRoute.loads.DISPATCH}?groups=${listIdCheckedGroups.join(',')}`,
          formData
        )
        if (!resp.data.success) {
          context.message.error('Adding Load Failed!')
          return
        }
        context.message.success('Added Successfully')
        onClose()
        set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
      } catch (err) {
        context.message.error(getErrorMessage(err))
      } finally {
        setTimeout(() => {
          setSavingOrDispatching(false)
        }, 3888)
      }
    }

    useEffect(() => {
      if (showAdd === true) {
        setTimeout(() => {
          const el = document.querySelectorAll(
            '.wr_drawer_add_add .ant-drawer-body input'
          )[0]
          if (el) {
            el.focus()
          }
        }, 200)
      }
    }, [showAdd])

    return (
      <Drawer
        bodyStyle={{ paddingBottom: 80 }}
        className="wr_drawer_add wr_drawer_add_add"
        destroyOnClose
        footer={
          <div
            className={`wr_footer_add_load ${
              savingOrDispatching ? 'disabled1' : ''
            }`}
          >
            <Button
              onClick={savingOrDispatching ? null : onClose}
              stateClass={savingOrDispatching ? 'Disabled' : 'Secondary'}
              width={74}
            >
              Cancel
            </Button>
            <div className="wr_save_dispatch">
              <Button
                className="mr8"
                onClick={savingOrDispatching ? null : handleClickSave}
                stateClass={savingOrDispatching ? 'Disabled' : 'Outline'}
                width={61}
              >
                Save
              </Button>
              <Button
                onClick={savingOrDispatching ? null : handleClickDispatch}
                stateClass={savingOrDispatching ? 'Disabled' : 'Primary'}
                width={87}
              >
                Dispatch
              </Button>
            </div>
          </div>
        }
        maskClosable={false}
        onClose={onClose}
        title="Add Load"
        visible={showAdd}
        width={597}
      >
        <div
          className="add_load_above_part"
          ref={containerref}
          style={{ position: 'relative' }}
        >
          <div className="row1 u_d_flex">
            <div className="row1--col1">
              <div className="label_load1">
                Load#
                <div
                  onMouseEnter={() => {
                    setHover(true)
                  }}
                  onMouseLeave={() => {
                    setHover(false)
                  }}
                  style={{
                    position: 'relative',
                    display: 'inline-block',
                    zIndex: 100,
                  }}
                >
                  <InfoIcon />
                  <div
                    className={classnames('tooltip1 long1', {
                      opacity0: !isHover,
                    })}
                  >
                    TMS Reference Number
                  </div>
                </div>
              </div>
              <Input2
                error={!!errors.loadnumber}
                id="input_load_number_modaladd"
                onChange={(e) => {
                  if (e.target.value.length <= 32) {
                    setErrors((prev) => ({ ...prev, loadnumber: '' }))
                    set_loadnumber(e.target.value)
                  }
                }}
                onBlur={(e) => {
                  set_loadnumber(trim(e.target.value))
                }}
                value={loadnumber}
              />
              {errors.loadnumber && (
                <div className="text-Red mt-1 text-[12px] leading-[150%] ">
                  {errors.loadnumber}
                </div>
              )}
            </div>
            <div className="row1--col2">
              <div className="label_load1">Driver</div>
              <InputSearchDriverWhenAddLoad
                drivers={drivers}
                error={!!errors.idDriver}
                idDriver={idDriver}
                loadingDriver={loadingDriver}
                resetError={() => {
                  setErrors((prev) => ({ ...prev, idDriver: '' }))
                }}
                setLoadingDriver={setLoadingDriver}
                setValue={setValue}
                set_drivers={set_drivers}
                set_idDriver={set_idDriver}
                showAdd={showAdd}
                showAddPrev={showAddPrev}
                token={token}
                value={value}
              />
              {errors.idDriver && (
                <div className="text-Red mt-1 text-[12px] leading-[150%] ">
                  {errors.idDriver}
                </div>
              )}
            </div>
          </div>
          <div className="row1">
            <div className="label_load1">Upload Load Sheet (Optional)</div>
            <InputFile2
              context={context}
              fileValue={loadsheetFile}
              maxLengthFileName={60}
              setFileValue={setLoadsheetFile}
            />
          </div>
          <div className="row1">
            <div className="label_load1">Note (Optional)</div>
            <Textarea2
              onChange={(e) => set_note(e.target.value)}
              value={note}
            />
          </div>
          <div className="row1 row1--truck_trailer u_d_flex">
            <div>
              <div className="label_load1">Truck# (Optional)</div>
              <Input2
                onChange={(e) => set_truck(e.target.value)}
                value={truck}
              />
            </div>
            <div>
              <div className="label_load1">Trailer# (Optional)</div>
              <Input2
                onChange={(e) => set_trailer(e.target.value)}
                value={trailer}
              />
            </div>
          </div>
          {/*  */}
          <div className=" flex justify-between items-center mt-6 ">
            <div className="text-[16px] font-bold  ">Stops</div>
            <div className="flex items-center gap-x-[8px] ">
              <MyCheckbox
                checked={isAllStopSmartETA}
                handleClickCheckbox={() => {
                  if (isAllStopSmartETA) {
                    setListTrip((prev) =>
                      prev.map((trip) => ({ ...trip, tracking: false }))
                    )
                  } else {
                    setListTrip((prev) =>
                      prev.map((trip) => ({ ...trip, tracking: true }))
                    )
                  }
                }}
                id="addloadform_trackingall"
              />
              <label
                className="text-Black text-[14px] "
                htmlFor="addloadform_trackingall"
                onClick={null}
              >
                Track with Smart ETA for all stops
              </label>
            </div>
          </div>
          {/*  */}
        </div>
        <DragDropContext className="DragDropContext" onDragEnd={onDragEnd}>
          <Droppable className="Droppable" droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {listTrip.map((item, i) => (
                  <OneStopAdd
                    context={context}
                    errors={errors.trips[i]}
                    handleClickAppt={handleClickAppt}
                    handleClickToggleCollapse={handleClickToggleCollapse}
                    handleDeleteTrip={handleDeleteTrip}
                    i={i}
                    item={item}
                    key={item.uniqueid}
                    listTrip
                    locationRef={locationRef}
                    setErrors={(trip) => {
                      setErrors((prev) => {
                        return {
                          ...prev,
                          trips: prev.trips.map((o, index) => {
                            if (index === i) {
                              o = { ...trip }
                              return o
                            } else {
                              return o
                            }
                          }),
                        }
                      })
                    }}
                    token={token}
                    updateListTrip={updateListTrip}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={cls.wr_addstopbtn}>
          <Button
            havePrefixIcon
            onClick={handleAddStop}
            stateClass="Link"
            tabIndex="0"
          >
            Add Stop
          </Button>
        </div>
      </Drawer>
    )
  }
)

export default DrawerAddLoad
;((_data) => {})([React])
