import React from 'react'

const IconLoad = ({ fill }) => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M10.6667 2.66699C11.4031 2.66699 12.0001 3.26395 12.0001 4.00033V9.33366H20.0001V4.00033C20.0001 3.26395 20.597 2.66699 21.3334 2.66699C22.0698 2.66699 22.6667 3.26395 22.6667 4.00033V9.33366H28.0001C28.7365 9.33366 29.3334 9.93061 29.3334 10.667C29.3334 11.4034 28.7365 12.0003 28.0001 12.0003H22.6667V20.0003H28.0001C28.7365 20.0003 29.3334 20.5973 29.3334 21.3337C29.3334 22.07 28.7365 22.667 28.0001 22.667H22.6667V28.0003C22.6667 28.7367 22.0698 29.3337 21.3334 29.3337C20.597 29.3337 20.0001 28.7367 20.0001 28.0003V22.667H12.0001V28.0003C12.0001 28.7367 11.4031 29.3337 10.6667 29.3337C9.93037 29.3337 9.33342 28.7367 9.33342 28.0003V22.667H4.00008C3.2637 22.667 2.66675 22.07 2.66675 21.3337C2.66675 20.5973 3.2637 20.0003 4.00008 20.0003H9.33342V12.0003H4.00008C3.2637 12.0003 2.66675 11.4034 2.66675 10.667C2.66675 9.93061 3.2637 9.33366 4.00008 9.33366H9.33342V4.00033C9.33342 3.26395 9.93037 2.66699 10.6667 2.66699ZM12.0001 12.0003V20.0003H20.0001V12.0003H12.0001Z"
      fill={fill || '#50565E'}
      fillRule="evenodd"
    />
  </svg>
)
export default IconLoad
;((_data) => {})([React])
