import React from 'react'
// import Modal from '@appComponent/modalPortal'
import { Button } from '@appComponent/button'
import { H6 } from '@appComponent/typography'
import { removeCookieUtil } from '@helper'
import { Modal } from 'antd'
import classnames from 'classnames'
import cls from './session_expired.scss'
// @flow

export const SessionExpired = () => {
  const handleOk = () => {
    //
  }
  const handleCancel = () => {
    //
  }
  return (
    <Modal
      centered
      className={cls.modal_session_expired}
      closable={false}
      footer={null}
      onCancel={handleCancel}
      onOk={handleOk}
      title={null}
      visible
      width={398}
    >
      <div className={cls.wr_modal}>
        <H6 block className={cls.title}>
          Session Expired
        </H6>
        <div className={cls.loginagain}>
          You will be logged out. Please log in again.
        </div>
        <div className={cls.wr_ok}>
          <Button
            onClick={() => {
              removeCookieUtil('token')
              removeCookieUtil('accessToken')
              localStorage.clear()
              window.location = '/'
              // history.push('/')
            }}
            stateClass="Primary"
            width={53}
          >
            OK
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export const AccountLocked = ({ history }) => {
  const handleOk = () => {
    //
  }
  const handleCancel = () => {
    //
  }
  return (
    <Modal
      centered
      className={cls.modal_session_expired}
      closable={false}
      footer={null}
      onCancel={handleCancel}
      onOk={handleOk}
      title={null}
      visible
      width={398}
    >
      <div className={cls.wr_modal}>
        <H6 block className={cls.title}>
          Account Locked
        </H6>
        <div className={classnames(cls.loginagain, cls.f14)}>
          Your account is locked. Please contact your company administrator.
        </div>
        <div className={cls.wr_ok}>
          <Button
            onClick={() => {
              history.push('/')
            }}
            stateClass="Primary"
            width={53}
          >
            OK
          </Button>
        </div>
      </div>
    </Modal>
  )
}
;((_data) => {})([React])
