import { useEffect } from 'react'

export default function useLowerTabIndex(tripClassName) {
  useEffect(() => {
    //set tabindex lower to tab over div
    let inspectTrip = document.querySelectorAll(tripClassName)
    for (let x of inspectTrip) {
      x.setAttribute('tabindex', '-1')
    }
  })
}
