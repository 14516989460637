import React from 'react'

const ShowEye = () => (
  <svg height={20} width={20}>
    <path
      d="M10 .75C5.833.75 2.275 3.342.833 7c1.442 3.658 5 6.25 9.167 6.25s7.725-2.592 9.167-6.25C17.725 3.342 14.167.75 10 .75zm0 10.417A4.168 4.168 0 015.833 7C5.833 4.7 7.7 2.833 10 2.833S14.167 4.7 14.167 7 12.3 11.167 10 11.167zM10 4.5A2.497 2.497 0 007.5 7c0 1.383 1.117 2.5 2.5 2.5s2.5-1.117 2.5-2.5-1.117-2.5-2.5-2.5z"
      fill="#B2B8C0"
      fillRule="evenodd"
    />
  </svg>
)

export default ShowEye
;((_data) => {})([React])
