import React from 'react'

import { UikTopBarTitle } from '@components'
import ChangePassword from './ChangePassword'
import cls from './account.scss'

const Account = () => {
  const userName = localStorage.getItem('user_name') || ''

  return (
    <div>
      <UikTopBarTitle
        className={cls.account_header}
      >{`${userName}'s Account`}</UikTopBarTitle>
      <ChangePassword />
    </div>
  )
}

export default Account
;((_data) => {})([React])
