import { Button } from '@appComponent/button'
import { Switch } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { UikInput } from '../../../../../components'
import apiRoute from '../../../../../constants/apiRoute'
import { customAxios } from '../../../../../helper'
import { withContext } from '../../../../../hoc'
import cls from './Automations.scss'
import { toLower, trim } from 'lodash'
import { REGX_EMAIL_HEAD } from '../../Loadsnew/components/common/constants'

function Automations({ context }) {
  const [cookies] = useCookies(['token'])
  const [check, setCheck] = useState(false)
  const [email, setEmail] = useState('')
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)

  const { token } = cookies

  const setEmailLower = useCallback((value) => {
    setEmail(toLower(value))
  }, [])

  const handleValidate = () => {
    let isValid = true
    let newError = ''
    const emailTestRegex = REGX_EMAIL_HEAD.test(
      String(`${email}`).trim().toLowerCase()
    )
    if (!emailTestRegex && email !== '') {
      newError =
        'Only letters (a-z), numbers (0-9), periods (.), underscore (_), hyphen (-), and plus sign (+) are allowed.'
      isValid = false
    }
    if (check && email === '') {
      newError = 'Email alias is required'
      isValid = false
    }
    setErr(newError)
    return isValid
  }
  const getInfoAutomations = async () => {
    try {
      const dataResponse = await customAxios(token).get(
        `${apiRoute.users.GET_LOGGED_IN_USER_INFO}`
      )
      if (dataResponse.status === 200) {
        setCheck(dataResponse.data.automation)
        setEmailLower(dataResponse.data.alias)
      }
    } catch (error) {}
  }
  useEffect(() => {
    getInfoAutomations()
  }, [])

  const handleSave = async () => {
    const isValid = handleValidate()
    const bodyRequest = {
      alias: email ? email.trim() : '',
      automation: check,
    }
    if (isValid && !loading) {
      try {
        setLoading(true)
        const dataResponse = await customAxios(token).put(
          `${apiRoute.automations}`,
          bodyRequest
        )
        if (dataResponse.status === 200) {
          context.message.success('Saved successfully')
        }
      } catch (e) {
        setLoading(false)
        if (e.response.data.errorCode === 'ALIAS_EXISTS') {
          setErr(e.response.data.messages)
        }
        if (e.response.data.errorCode === 'VALIDATION_FAILED') {
          setErr(e.response.data.messages[0].error)
        }
      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 3888)
      }
    }
  }

  return (
    <div className={cls.container_automations}>
      <div className={cls.title}>Automations</div>
      <div className={cls.automations_form}>
        <div className="flex items-center mb-6">
          <Switch
            checked={check}
            className={cls.switch}
            disabled={loading}
            onChange={(checked) => {
              setCheck(checked)
            }}
            size="small"
          />
          <div className="ml-2">Import documents via Email</div>
        </div>
        <div className="text-sm mb-6">Email alias to receive documents</div>
        <div className="flex items-center">
          <UikInput
            className={classNames('w-[149.87px] h-[37px]', {
              [cls.errorDetection]: err !== '',
            })}
            id="email_address"
            name="email_address"
            onBlur={(event) => {
              event.persist()
              const { value } = event.target
              setEmailLower(trim(value))
              setErr('')
            }}
            onChange={(event) => {
              event.persist()
              const { value } = event.target
              setEmailLower(trim(value))
              setErr('')
            }}
            type="text"
            value={email}
          />
          <div className="text-sm ml-[7.63px]">@driverdoc.net</div>
        </div>
        {err && <p className={cls.errorMsg}>{err}</p>}
        <div className="mt-6">
          <Button
            onClick={handleSave}
            stateClass={loading ? 'Disabled' : 'Primary'}
            width={61}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
export default withContext(Automations)
;((_data) => {})([React])
