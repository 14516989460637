/* eslint-disable no-restricted-syntax */

export const getTokenFromLocation = ({ location }) => {
  const split = location.toString().split('?')

  if (split.length < 2) {
    return null
  }
  const pairs = split[1].split('&')
  const objectToken = { AccessToken: '', IdToken: '', RefreshToken: '' }

  for (const pair of pairs) {
    const [key, value] = pair.split('=')
    objectToken[key] = value
  }
  return objectToken
}
