/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-var */
/* eslint-disable comma-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import classNames from 'classnames'
import { isEmpty, values } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import PhoneInput from 'react-phone-input-2'
import { equalStr } from '../../helper'
import { CountryCodeEnum } from '../../constants'

export const MyInputPhone = ({
  disabled,
  id = '',
  className,
  countryDefault,
  containerClass,
  onChange,
  value,
  error,
}) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      const flagElement = document.querySelector(
        `.${containerClass} .selected-flag > .flag`
      )
      if (isEmpty(flagElement)) {
        return
      }

      const makeUS = () => {
        flagElement.classList.remove(CountryCodeEnum.CA)
        flagElement.classList.remove(CountryCodeEnum.MX)
        flagElement.classList.add(CountryCodeEnum.US)
      }
      const makeCA = () => {
        flagElement.classList.remove(CountryCodeEnum.US)
        flagElement.classList.remove(CountryCodeEnum.MX)
        flagElement.classList.add(CountryCodeEnum.CA)
      }
      const makeMX = () => {
        flagElement.classList.remove(CountryCodeEnum.US)
        flagElement.classList.remove(CountryCodeEnum.CA)
        flagElement.classList.add(CountryCodeEnum.MX)
      }
      if (countryDefault === CountryCodeEnum.CA) {
        makeCA()
      } else if (countryDefault === CountryCodeEnum.US) {
        makeUS()
      } else {
        makeMX()
      }
    }, 1)

    return () => clearTimeout(timeout)
  }, [priority, countryDefault])

  const priority = useMemo(() => {
    // smaller win
    if (equalStr(countryDefault, CountryCodeEnum.CA)) {
      return { ca: 0, us: 1, mx: 1 }
    } else if (equalStr(countryDefault, CountryCodeEnum.US)) {
      return { us: 0, ca: 1, mx: 1 }
    }
    return { mx: 0, us: 1, ca: 1 }
  }, [countryDefault])

  useEffect(() => {
    const buttonDropDown = document.querySelector(
      `.${containerClass} .flag-dropdown`
    )
    if (isEmpty(buttonDropDown)) {
      return
    }
    buttonDropDown.addEventListener('click', async (a, b) => {
      var countries = document.querySelectorAll(
        `.${containerClass} .country-list .country`
      )

      var interval = setInterval(() => {
        if (isEmpty(countries)) {
          countries = document.querySelectorAll(
            `.${containerClass} .country-list .country`
          )
        } else {
          clearInterval(interval)
          countries.forEach((o, i) => {
            o.classList.remove('highlight')
            const highlight = () => {
              const countryCode = o.dataset.countryCode
              if (equalStr(countryCode, countryDefault)) {
                o.classList.add('highlight')
              }
            }
            highlight()

            const replaceText = () => {
              const oldText = o.innerHTML
              const reg = />\+\d{1,100}/
              const match = oldText.match(reg)
              if (match !== null) {
                const match2 = match[0]
                const match3 = match2.replace('+', '(+')
                const match4 = `${match3})`
                const newText = oldText.replace(reg, match4)
                o.innerHTML = newText
              }
            }
            replaceText()
          })
        }
      }, 30)
    })

    return () => {}
  }, [countryDefault])

  if (isEmpty(countryDefault) || isEmpty(priority)) {
    return null
  }
  return (
    <div className="relative ">
      <PhoneInput
        buttonClass="buttonClass"
        containerClass={`${containerClass} ${
          disabled ? 'inputphone_disabled' : ''
        }`}
        // priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
        country="us"
        countryCodeEditable={false}
        disableCountryGuess
        disabled={!!disabled}
        dropdownClass="dropdownClass"
        id={id}
        inputClass={classNames(
          'inputClass text-[14px] leading-[150%] rounded-[4px] h-[38px] focus:border-borderInput',
          className,
          error ? 'border-Red' : ''
        )}
        masks={{ us: '...-...-....', mx: '..-....-....', ca: '...-...-....' }}
        onChange={onChange}
        onlyCountries={[
          CountryCodeEnum.US,
          CountryCodeEnum.MX,
          CountryCodeEnum.CA,
        ]}
        priority={priority}
        value={value}
        // disableInitialCountryGuess={true}
      />
      {error && <div className="text-Red absolute left-0 text-xs">{error}</div>}
    </div>
  )
}
// value={`${countryCode}${phoneNumber}`}
// (value, data, event, formattedValue) => {
//   setCountry(data.countryCode)
//   setCountryCode(data.dialCode)
//   setPhone(value.slice(data.dialCode.length))
// }

MyInputPhone.propTypes = {
  containerClass: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  countryDefault: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
}
;((_data) => {})([React])
