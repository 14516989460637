import { UikButton, UikFormInputGroup, UikInput } from '@components'
import apiRoute from '@constants/apiRoute'
import { handleRedirect } from '@helper'
import { withContext } from '@hoc'
import { default as Axios, default as axios } from 'axios'
import classnames from 'classnames'
import _, { get, isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
// import logo from '../../../driverDocAssets/DriverDocMainLogo.png'
import { Button } from '@appComponent/button'
import { BackArrow, ShowEye } from '@appComponent/icon'
import { Checkbox } from 'antd'
import logo from '../../driverDocAssets/DriverDocMainLogo.png'
// import ShowEye from './../components/showEye'

import Footer from '../@components/Footer'
import SplashScreen from '../@components/splashscreen'
import { Caption, H6 } from '../@components/typography'
import cls from './getTokenForCreatingPassword.scss'

const publicIp = require('public-ip')

function GetTokenForCreatingNewPassword({ context, history, ...props }) {
  const initialErrors = {
    password: '',
    confirmPassword: '',
  }
  const [agreed, setAgreed] = useState(false)
  const [loadingPage, setLoadingPage] = useState(true)
  const { token_for_creating_new_password } = props.match.params
  const [cookies, , removeCookie] = useCookies(['token'])
  const { token } = cookies
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(initialErrors)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [typePassword, setTypePassword] = useState('password')
  const [typeConfirmPassword, setTypeConfirmPassword] = useState('password')

  const [errorSubmit] = useState('')
  const [, setIpPublic] = useState()
  const [JWTToken, setJWTToken] = useState('abc')
  const [userName, setUserName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (token) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (!user) {
        return
      }
      handleRedirect(user, history)
    }
  }, [token])

  useEffect(() => {
    if (token) {
      return
    }
    const getJWTToken = async (token_for_creating_new_password) => {
      if (!isNil(token_for_creating_new_password)) {
        try {
          setLoadingPage(true)
          const ipPublc = await publicIp.v4()
          setIpPublic(ipPublc)
          const bodyRequest = {
            browserVersion: navigator.userAgent,
            ipPublic: ipPublc,
            isNewUser: true,
          }
          const { data: dataResponse } = await axios.post(
            `${apiRoute.GetTokenForCreatingNewPassword}/${token_for_creating_new_password}`,
            bodyRequest
          )

          if (_.get(dataResponse, 'data[0].jwtAdminToken')) {
            setJWTToken(dataResponse.data[0].jwtAdminToken)
            setUserName(`${dataResponse.data[0].first_name}`)
          }
          setLoadingPage(false)
        } catch (err) {
          if (_.get(err, 'response.status') === 400) {
            setJWTToken('')
            removeCookie('token', { path: '/' })
            setErrorMessage('Your invitation has expired.')
            setLoadingPage(false)
          } else {
            window.location = '/error-page'
          }
          // setCookie('token', `${token_for_creating_new_password}`, {
          //   path: '/',
          // })
          // props.history.push('/forget-Password')
        }
      }
    }
    getJWTToken(token_for_creating_new_password)
    setLoadingPage(false)
  }, [token_for_creating_new_password])

  const handleChange = (e) => {
    const errPassword = {
      password: '',
      confirmPassword: '',
    }
    switch (e.target.name) {
      case 'password':
        setError(errPassword)
        setPassword(e.target.value)
        break

      case 'confirmPassword':
        setError(errPassword)
        setConfirmPassword(e.target.value)
        break
      default:
        break
    }
  }
  const handleBlur = (e) => {
    const errPassword = {
      password: '',
      confirmPassword: '',
    }
    switch (e.target.name) {
      case 'password':
        setError(errPassword)
        setPassword(e.target.value.trim())
        break

      case 'confirmPassword':
        setError(errPassword)
        setConfirmPassword(e.target.value.trim())
        break
      default:
        break
    }
  }

  const handleTogglePass = () => {
    if (typePassword === 'password') {
      setTypePassword('text')
    } else if (typePassword === 'text') {
      setTypePassword('password')
    }
  }

  const handleToggleConfirmPassword = () => {
    if (typeConfirmPassword === 'password') {
      setTypeConfirmPassword('text')
    } else if (typeConfirmPassword === 'text') {
      setTypeConfirmPassword('password')
    }
  }
  const handleDisabled = () => {
    if (
      password === '' ||
      confirmPassword === '' ||
      error.password ||
      error.confirmPassword ||
      !agreed
    ) {
      return true
    }
    return false
  }

  const handleSubmit = async () => {
    // if (!JWTToken) {
    //   return
    // }
    const _checkPass = document.getElementById('password')
    const _checkConfirm = document.getElementById('confirmPassword')
    const passwordTest = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/
    if (password === '' || password === undefined) {
      const errPassword = {
        password: "Password can't be blank",
        confirmPassword: '',
      }
      _checkPass.focus()
      setError(errPassword)
    } else if (!passwordTest.test(password) || password.length < 8) {
      const errPassword = {
        password:
          'Please choose a stronger password: Minimum 8 digits, 1 number, 1 uppercase.',
        confirmPassword: '',
      }
      _checkPass.focus()
      setError(errPassword)
    } else if (confirmPassword !== password) {
      const errConfirmPass = {
        password: '',
        confirmPassword: "Password confirmation doesn't match Password",
      }
      _checkConfirm.focus()
      setError(errConfirmPass)
    } else {
      setLoading(true)
      const bodyRequest = {
        password: password.replace(/[\s]/gm, ''),
      }
      try {
        const httpClient = Axios.create()

        httpClient.defaults.headers.common.Authorization = `Bearer ${token_for_creating_new_password}`

        const { data: dataResponse } = await httpClient.post(
          `${apiRoute.createNewPassCognito}`,
          bodyRequest
        )
        if (dataResponse.success) {
          context.message.success('Create New Password Successfully')
          setTimeout(() => {
            window.location = '/all-set'
          }, 2000)
        }
      } catch (err) {
        if (get(err, 'response.status') === 403) {
          setJWTToken('')
          removeCookie('token', { path: '/' })
          setErrorMessage('Your invitation has expired.')
          setLoadingPage(false)
        } else {
          window.location = '/error-page'
        }

        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
  }
  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !handleDisabled()) {
      handleSubmit()
    }
  }

  const renderContent = () => {
    if (!JWTToken) {
      return (
        <React.Fragment>
          <Caption block className={cls.mb24}>
            Please contact your company administrator to resend a new
            invitation.
          </Caption>
          <div className={cls.wr_btn_back}>
            <Button
              onClick={() => {
                window.location = '/'
              }}
              stateClass="Primary"
              width={140}
            >
              <BackArrow className={cls.mr13} /> Back to log in
            </Button>
          </div>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <UikFormInputGroup className={cls.gr_password}>
          <label className={cls.labelPassword}>New Password</label>
          <UikInput
            autoComplete="off"
            className={cls.ip_password}
            disabled={!JWTToken}
            errorMessage={error.password}
            id="password"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Password"
            type={typePassword}
            value={password.replace(/[\s]/gm, '')}
          />
          <span className={cls.toggle_password} onClick={handleTogglePass}>
            <ShowEye />
          </span>
        </UikFormInputGroup>
        <UikFormInputGroup className={cls.gr_confirm}>
          <label className={cls.labelConfirm}>Confirm Password</label>
          <UikInput
            className={cls.ip_confirm_password}
            disabled={!JWTToken}
            errorMessage={error.confirmPassword}
            id="confirmPassword"
            name="confirmPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Confirm Password"
            type={typeConfirmPassword}
            value={confirmPassword}
          />
          <span
            className={cls.toggle_confirm_password}
            onClick={handleToggleConfirmPassword}
          >
            <ShowEye />
          </span>
        </UikFormInputGroup>
        <div className={cls.wr_agree}>
          <Checkbox
            checked={agreed}
            className={cls.input_check}
            onChange={(e) => setAgreed(e.target.checked)}
          />
          <Caption block className={cls.agree}>
            I agree to the{' '}
            <a
              href="https://driverdoc.io/terms"
              rel="noopener noreferrer"
              tabIndex="-1"
              target="_blank"
            >
              Terms of Use
            </a>{' '}
            and acknowledge I have read the{' '}
            <a
              href="https://driverdoc.io/privacy"
              rel="noopener noreferrer"
              tabIndex="-1"
              target="_blank"
            >
              Privacy Policy
            </a>
            .{' '}
          </Caption>{' '}
        </div>
        <div className={cls.form_button}>
          {' '}
          <UikButton
            className={
              handleDisabled() ? cls.btn_submit_disabled : cls.btn_submit
            }
            disabled={handleDisabled()}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            <span className={cls.value}>Submit</span>
          </UikButton>
        </div>
      </React.Fragment>
    )
  }

  if (loadingPage) {
    return <SplashScreen />
  }
  if (!JWTToken) {
    // return <div className={ cls.expired }>Create Password Token is Expired.</div>
  }

  return (
    <div className={cls.Reset}>
      <div className={classnames(cls.div1, cls.img_left)}>
        <img alt="" className={cls.img_logo} src={logo} />
      </div>
      <div className={classnames(cls.div2, cls.reset_password)}>
        <div className={cls.content_reset}>
          <div className={cls.form_right}>
            <React.Fragment>
              <H6 block className={cls.head1}>
                Welcome {userName}
              </H6>

              <div className={cls.head2}>
                {JWTToken ? (
                  <Caption block className={cls.caption}>
                    Please set your password for your new account.
                  </Caption>
                ) : (
                  <Caption block className={cls.expired}>
                    {errorMessage}
                  </Caption>
                )}
              </div>
            </React.Fragment>
            {renderContent()}

            {errorSubmit && <p style={{ color: 'red' }}>{errorSubmit}</p>}
          </div>
        </div>
      </div>
      <div className={classnames(cls.div3, cls.footer_reset)}>
        <Footer />
      </div>
    </div>
  )
}

export default withContext(GetTokenForCreatingNewPassword)
;((_data) => {})([React])
