import {
  hasPaymentMethodAtom,
  loadingPaymentAtom,
  paymentStatusAtom,
  remainingTimeAtom,
  tokenDataAtom,
} from '@atoms/atoms'
import withAuth from '@auth'
import apiRoute from '@constants/apiRoute'
import { customAxios, equalStr, handleRedirect, letWait } from '@helper'
import { withContext } from '@hoc'
import { useAtom } from 'jotai'
import React, { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import imgAlone from '../../../../driverDocAssets/alone.png'

const YourAccountIsDisabled = ({ history }) => {
  const [cookies] = useCookies(['token'])
  const { token } = cookies
  const [loading, setLoading] = useState(true)
  const [tokenData] = useAtom(tokenDataAtom)

  const [paymentStatus] = useAtom(paymentStatusAtom)
  const [hasPaymentMethod] = useAtom(hasPaymentMethodAtom)
  const [remainingTime] = useAtom(remainingTimeAtom)
  const [loadingPayment] = useAtom(loadingPaymentAtom)

  useEffect(() => {
    ;(async function () {
      try {
        const { data } = await customAxios(token).get(
          apiRoute.users.GET_LOGGED_IN_USER_INFO
        )
        if (!equalStr(data.companyStatus, 'Inactive')) {
          handleRedirect(tokenData, history)
        }
      } catch (error) {
        // alert(getErrorMessage(error))
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const isDisabled = useMemo(() => {
    if (loadingPayment) {
      return true
    }

    if (
      hasPaymentMethod === false &&
      loadingPayment === false &&
      paymentStatus === '' &&
      remainingTime === ''
    ) {
      return true
    }
    return (
      remainingTime === 0 &&
      paymentStatus === 'cancelled' &&
      hasPaymentMethod === false
    )
  }, [loadingPayment, remainingTime, paymentStatus, hasPaymentMethod])

  useEffect(() => {
    if (isDisabled !== true) {
      handleRedirect(tokenData, history)
    }
  }, [isDisabled])

  useEffect(() => {
    ;(async function () {
      function move(oldParent, newParent) {
        while (oldParent.childNodes.length > 0) {
          newParent.appendChild(oldParent.childNodes[0])
        }
      }

      let el
      let el2
      el = document.getElementById('old_parent2')
      el2 = document.getElementById('wr_btn_reactivate_account')
      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent2')
        el2 = document.getElementById('wr_btn_reactivate_account')
      }

      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent2')
        el2 = document.getElementById('wr_btn_reactivate_account')
      }

      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent2')
        el2 = document.getElementById('wr_btn_reactivate_account')
      }

      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent2')
        el2 = document.getElementById('wr_btn_reactivate_account')
      }

      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent2')
        el2 = document.getElementById('wr_btn_reactivate_account')
      }

      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent2')
        el2 = document.getElementById('wr_btn_reactivate_account')
      }
      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent2')
        el2 = document.getElementById('wr_btn_reactivate_account')
      }
      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent2')
        el2 = document.getElementById('wr_btn_reactivate_account')
      }
      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent2')
        el2 = document.getElementById('wr_btn_reactivate_account')
      }
      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent2')
        el2 = document.getElementById('wr_btn_reactivate_account')
      }

      if (!el || !el2) {
        await letWait(5000)
        el = document.getElementById('old_parent2')
        el2 = document.getElementById('wr_btn_reactivate_account')
      }
      if (!el || !el2) {
        return
      }
      move(el, el2)
    })()
  }, [])

  if (loadingPayment) {
    return null
  }

  if (loading) {
    return 'Loading'
  }

  return (
    <div
      className=" text-center w-full mx-auto"
      style={{
        padding: '15px',
        maxWidth: '600px',
      }}
    >
      <img
        alt="spinner"
        src={imgAlone}
        style={{
          width: '402px',
          height: 'auto',
          maxWidth: '95%',
          display: 'block',
          margin: '20px auto 38px auto',
        }}
      />
      <div className="text-Black text-14px leading-150">
        {tokenData.role === 'Owner'
          ? 'Your account is disabled.'
          : 'Your account is disabled. Please contact your company administrator.'}
      </div>
      <div className="h-24px" />

      {tokenData.role === 'Owner' && <div id="wr_btn_reactivate_account" />}
    </div>
  )
}

export default withAuth(withContext(YourAccountIsDisabled))
;((_data) => {})([React])
