import { withContext } from '@hoc'
import axios from 'axios'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import OtpInput from 'react-otp-input'
import { UikButton, UikHeadline } from '../../../components'
import apiRoute from '../../../constants/apiRoute'
import logo from '../../../driverDocAssets/DriverDocMainLogo.png'
import { H6 } from '../../@components/typography'
import IconLeft from '../../svg/IconLeftBack'
import cls from './verification.scss'
import { useVerifyAuthToken } from '@@/hooks/apiHooks/useVerifyAuthToken'
import { get } from 'lodash'
import { includesAll } from '@@/helper'
import route from '@@/constants/route'

function Verification({ context, history }) {
  const [code, setCode] = useState('')
  const [isLoading, setLoading] = useState(false)
  const { search } = window.location
  const params = new URLSearchParams(search)
  const token = params.get('token')
  const {
    data,
    error: errorVerifyToken,
    isLoading: isVerifyingToken,
  } = useVerifyAuthToken(token)

  const email = useMemo(() => {
    return get(data, 'data.enrollment') || ''
  }, [data])

  const isInvalidToken = useMemo(() => {
    return includesAll({ str: errorVerifyToken, words: ['Invalid', 'token'] })
  }, [errorVerifyToken])

  const isTokenExpired = useMemo(() => {
    if (!errorVerifyToken) {
      return false
    }
    return includesAll({
      str: errorVerifyToken.error[0],
      words: ['expired', 'token'],
    })
  }, [errorVerifyToken])

  const instance = axios.create({
    headers: { authorization: `Bearer ${token}` },
  })
  const [error, setError] = useState('')

  const checkRequired = useMemo(() => {
    if (code.length < 6) {
      return true
    }
    return false
  }, [code])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!checkRequired) {
      const bodyRequest = {
        code,
      }

      try {
        setError('')
        setLoading(true)
        const { data: dataResponse } = await instance.post(
          `${apiRoute.signUp.CONFIRM}`,
          bodyRequest
        )
        if (dataResponse) {
          localStorage.removeItem('tokenVerifyCode')
          localStorage.removeItem('emailVerify')
          history.push('/all-set')
        }
      } catch (e) {
        setError('Incorrect verification code. Try again.')
      } finally {
        setTimeout(() => {
          setLoading(false)
        }, [200])
      }
    }
  }
  const handleResend = async () => {
    try {
      const { data: dataResponse } = await instance.post(
        `${apiRoute.signUp.RESEND_CODE}`
      )
      if (dataResponse) {
        context.message.success('Verification code is resent.')
      }
    } catch (e) {
      context.message.error('Failed to resend verification code. Try again.')
      if (e.response.status === 403) {
        setTimeout(() => {
          window.location = '/error-page'
        }, 2000)
      }
    }
  }

  if (isVerifyingToken) {
    return null
  }

  if (isInvalidToken || isTokenExpired) {
    window.location = route.ERROR_PAGE
    return null
  }

  return (
    <div className={cls.verification_page}>
      <div className={classNames(cls.img_left, cls.c, cls.c1)}>
        <img alt="logo" className={cls.img_logo} src={logo} />
      </div>
      <div className={cls.back} onClick={() => (window.location.href = '/')}>
        <div className={cls.icon}>
          <IconLeft />
        </div>
        <div>Back</div>
      </div>

      <div className={cls.form_verification}>
        <div className={cls.form}>
          <UikHeadline className={cls.headline}>
            <H6>Account Verification</H6>
          </UikHeadline>
          <div className={cls.desc}>
            To continue, enter the 6-digit code we sent to
            <div>{email}</div>
          </div>
          <div>
            <div className={cls.input_code}>
              <OtpInput
                inputStyle={cls.item_input}
                isInputNum
                numInputs={6}
                onChange={(value) => {
                  setCode(value)
                }}
                separator={<span />}
                value={code}
              />
            </div>
            {error && (
              <div className={cls.errorMessage}>
                Incorrect verification code. Try again.
              </div>
            )}
          </div>
          <UikButton
            className={checkRequired ? cls.btn_reset_disabled : cls.btn_reset}
            disabled={checkRequired || isLoading}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            <span className={cls.value}>Continue</span>
          </UikButton>
          <div className={cls.text_footer}>
            <div className={cls.text}>Didn’t receive the code?</div>
            <div className={cls.resend} onClick={handleResend}>
              &nbsp; Resend it
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default withContext(Verification)
;((_data) => {})([React])
