'use client'

import React from 'react'
import ReactModalLib from 'react-modal'
import clsx from 'clsx'

export default function ReactModal({
  open,
  headerText,
  Content,
  subContent,
  children,
  onClose,
  closeWhenClickOutside = false,
  ButtonContent,
}) {
  return (
    <ReactModalLib
      ariaHideApp={false}
      shouldCloseOnOverlayClick={closeWhenClickOutside}
      isOpen={open}
      onRequestClose={() => {
        onClose()
      }}
      contentLabel="Example Modal"
      className="min-h-[100px] z-[1002] bg-white p-0 rounded-[8px]
         absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
          w-full max-w-[350px] lg:max-w-[440px] max-h-screen overflow-y-visible
          focus-visible:outline-none pt-[21px] px-[30px] pb-[30px]
      "
      portalClassName=""
      overlayClassName="fixed inset-0 bg-black bg-opacity-40 z-[1002] "
      bodyOpenClassName=""
      htmlOpenClassName=""
    >
      <React.Fragment>
        {headerText && (
          <h2
            className={clsx(
              ' text-[#50565e] text-[18px] leading-[27px] font-medium mb-[22px] '
            )}
          >
            {headerText}
          </h2>
        )}
        <div className="mb-6 whitespace-pre-line text-center text-[14px] leading-[21px]">
          {!!Content ? Content : children}
        </div>
        <div className="mb-[30px] whitespace-pre-line text-center text-[14px] leading-[21px]">
          {subContent}
        </div>

        {ButtonContent}
      </React.Fragment>
    </ReactModalLib>
  )
}
;((_data) => {})([React])
