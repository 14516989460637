import React from 'react'
import ReactTooltip from 'react-tooltip'

const MyTooltip = ({
  contentNormal,
  contentTooltip,
  classNameNormal,
  classNameTooltip,
  tooltipId,
}) => (
  <React.Fragment>
    <span
      className={classNameNormal}
      data-delay-hide="1"
      data-delay-show="1"
      data-for={tooltipId}
      data-tip
    >
      {contentNormal}
    </span>
    <ReactTooltip
      className={classNameTooltip}
      effect="solid"
      id={tooltipId}
      type="dark"
    >
      <span>{contentTooltip}</span>
    </ReactTooltip>
  </React.Fragment>
)
export default MyTooltip
;((_data) => {})([React])
