import clsx from 'clsx'
import React from 'react'

export const ThNoSort = ({
  className,
  title,
  classNamePadding = 'pl-[16px] fullhd:pl-[26px]',
}) => {
  return (
    <th
      className={clsx(
        'text-DarkGray  pr-0 pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%]',
        classNamePadding,
        className // w-[100px] 2xl:w-[126px]
      )}
    >
      {title}
    </th>
  )
}
;((_data) => {})([React])
