import React from 'react'

const IconGroup = ({ fill }) => (
  <svg
    fill={fill}
    height="28"
    viewBox="0 0 28 28"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M13.0572 0.390524C13.5779 -0.130175 14.4221 -0.130175 14.9428 0.390524L19.6095 5.05719C20.1302 5.57789 20.1302 6.42211 19.6095 6.94281L14.9428 11.6095C14.4221 12.1302 13.5779 12.1302 13.0572 11.6095L8.39052 6.94281C7.86983 6.42211 7.86983 5.57789 8.39052 5.05719L13.0572 0.390524ZM11.219 6L14 8.78105L16.781 6L14 3.21895L11.219 6ZM5.05719 8.39052C5.57789 7.86983 6.42211 7.86983 6.94281 8.39052L11.6095 13.0572C12.1302 13.5779 12.1302 14.4221 11.6095 14.9428L6.94281 19.6095C6.42211 20.1302 5.57789 20.1302 5.05719 19.6095L0.390524 14.9428C-0.130175 14.4221 -0.130175 13.5779 0.390524 13.0572L5.05719 8.39052ZM21.0572 8.39052C21.5779 7.86983 22.4221 7.86983 22.9428 8.39052L27.6095 13.0572C28.1302 13.5779 28.1302 14.4221 27.6095 14.9428L22.9428 19.6095C22.4221 20.1302 21.5779 20.1302 21.0572 19.6095L16.3905 14.9428C15.8698 14.4221 15.8698 13.5779 16.3905 13.0572L21.0572 8.39052ZM3.21895 14L6 16.781L8.78105 14L6 11.219L3.21895 14ZM19.219 14L22 16.781L24.781 14L22 11.219L19.219 14ZM11.219 22L14 24.781L16.781 22L14 19.219L11.219 22ZM13.0572 16.3905C13.5779 15.8698 14.4221 15.8698 14.9428 16.3905L19.6095 21.0572C20.1302 21.5779 20.1302 22.4221 19.6095 22.9428L14.9428 27.6095C14.4221 28.1302 13.5779 28.1302 13.0572 27.6095L8.39052 22.9428C7.86983 22.4221 7.86983 21.5779 8.39052 21.0572L13.0572 16.3905Z"
      fill={fill || '#50565E'}
      fillRule="evenodd"
    />
  </svg>
)
export default IconGroup
;((_data) => {})([React])
