import { StatusButton } from '@appComponent/StatusButton'
import { Input2WithReset } from '@appComponent/input'
import Modal from '@appComponent/modalPortal'
import { SpinnerIcon } from '@appComponent/spinnericon'
import { Tooltip } from 'antd'
import dayjs from 'dayjs'
import produce from 'immer'
import { useAtom } from 'jotai'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'

import MyPagination from '@appComponent/MyPagination'
import { Button } from '@appComponent/button'
import { Caption, Small } from '@appComponent/typography'
import usePrevious from '@appComponent/usePrevious'
import {
  UikDropdown,
  UikDropdownItem,
  UikTopBar,
  UikTopBarSection,
  UikTopBarTitle,
} from '@components'
import { SORT_ORDER } from '@constants'
import apiRoute from '@constants/apiRoute'
import {
  customAxios,
  equalStr,
  getErrorMessage,
  getStr,
  renderListLink,
} from '@helper'
import { withContext } from '@hoc'
import { useRecentlyEdited } from '@hooks'
import classnames from 'classnames'
import queryString from 'query-string'
import { useCookies } from 'react-cookie'
import useSWR from 'swr'
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'

import {
  ArrowDown,
  DeleteLoadIcon,
  DeleteSearch2,
  DotsComponent,
  EditLoadIcon,
  IconActivate14,
  IconDeactivate14,
  IconInfo14,
  IconResend14,
  IconSearchLoad,
} from '@appComponent/icon'

import MyTooltip from '@appComponent/MyTooltip'
import { tokenDataAtom } from '@atoms/atoms'
import _ from 'lodash'
import { currentStepOfFlow, runTour } from '../../../../../atoms/atoms'
import { PAGINATION_PAGE_SIZE_DEFAULT } from '../../../../../config'
import { formatPhoneNumber, isLoggedIn } from '../../../../../helper'
import DrawerAddUser from './DrawerAddUser'
import DrawerEditUser from './DrawerEditUser'
import cls from './users.scss'
import IconButtonAdd from '../../../../svg/IconButtonAdd'

const DEFAULT_SORT = 'name'

const fetcher = async (url, token) => {
  if (!url) return
  const data = await customAxios(token).get(url)
  if (data.status === 200) {
    return data.data.data
  }
  return { error: true }
}

const Users = ({ context }) => {
  const closeDropdownRef = useRef(0)
  const [tokenData] = useAtom(tokenDataAtom)
  const [listRecentlyEdittedIdDoc, setListRecentlyEdittedIdDoc] =
    useRecentlyEdited()
  const [totalActive, setTotalActive] = useState(0)
  const [listUser, setListUser] = useState([])
  const [showAdd, setShowAdd] = useState(false)
  const [idEditUser, setIdEditUser] = useState('')
  const [idDeleteUser, setIdDeleteUser] = useState('')
  const [idDeactivateUser, setIdDeactivateUser] = useState('')
  const [idActivateUser, setIdActivateUser] = useState('')
  const [idResendUser, setIdResendUser] = useState('')
  const [triggerFetch, setTriggerFetch] = useState(0)
  const [resending, setResending] = useState(false)

  const [textload, setTextload] = useQueryParam(
    'search',
    withDefault(StringParam, undefined)
  )
  const [textloadDebounce] = useDebounce(textload, 300)
  const textloadDebouncePrev = usePrevious(textloadDebounce)

  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1))
  const [total, setTotal] = useState(0)

  const [lastSortInfo, setLastSortInfo] = useQueryParam(
    'sort',
    withDefault(StringParam, DEFAULT_SORT)
  )
  const [run] = useAtom(runTour)
  const [, setStep] = useAtom(currentStepOfFlow)

  const [sortList, setSortList] = useState({
    name: '',
    status: '',
  })
  const lastSortInfoPrev = usePrevious(lastSortInfo)
  const [pageSize, setPageSize] = useQueryParam(
    'limit',
    withDefault(NumberParam, PAGINATION_PAGE_SIZE_DEFAULT)
  )

  const pageSizePrev = usePrevious(pageSize)

  const [time_request, set_time_request] = useState(
    dayjs().format('YYYY-MM-DDTHH:mm:ss')
  )

  const [cookies] = useCookies(['token'])
  const { token } = cookies
  const [urlfetch, setUrlfetch] = useState('')

  const { data, error } = useSWR(urlfetch, fetcher, {
    revalidateOnFocus: false,
  })

  const handleChangePageSize = (value) => {
    setPageSize(value)
  }

  const loadingUsers = useMemo(() => !data && !error, [data, error])

  const handleDeleteLoad = async () => {
    try {
      const { data: dataResponse } = await customAxios(token).put(
        `${apiRoute.users.DELETE}/${idDeleteUser}`
      )
      if (dataResponse.success) {
        setIdDeleteUser(null)
        set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
        context.message.success('Deleted Successfully')
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    }
  }

  const handleDeactivateLoad = async () => {
    try {
      const { data: dataResponse } = await customAxios(token).put(
        `${apiRoute.users.DEACTIVATE}/${idDeactivateUser}`
      )
      if (dataResponse.success) {
        setIdDeactivateUser(null)
        set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
        context.message.success('Deactivated Successfully')
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    }
  }

  const handleActivateLoad = async () => {
    try {
      const { data: dataResponse } = await customAxios(token).put(
        `${apiRoute.users.ACTIVATE}/${idActivateUser}`
      )
      if (dataResponse.success) {
        setIdActivateUser(null)
        set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
        context.message.success('Activated Successfully')
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    }
  }
  const handleResendLoad = async () => {
    try {
      setResending(true)
      const { data: dataResponse } = await customAxios(token).post(
        `${apiRoute.users.RESEND}/${idResendUser}`
      )
      if (dataResponse.success) {
        setIdResendUser(null)
        set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
        context.message.success('Resent Successfully')
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    } finally {
      setResending(false)
    }
  }

  const handleDeleteSearch = () => {
    setTextload(undefined)
  }

  const handleChangeInputSearch = (e) => {
    setTextload(e.target.value || undefined)
  }

  const handleSorting = (sortValue) => {
    const lastSortCriteria = lastSortInfo.replace('-', '')
    let lastSortInfoClone = lastSortInfo
    if (sortValue !== lastSortCriteria) {
      lastSortInfoClone =
        sortList[sortValue] === 'ASC' ? `-${sortValue}` : sortValue
    } else if (lastSortInfoClone[0] === '-') {
      lastSortInfoClone = lastSortInfoClone.replace('-', '')
    } else {
      lastSortInfoClone = `-${lastSortInfoClone}`
    }
    setLastSortInfo(lastSortInfoClone)
    const target = sortList[sortValue] === 'ASC' ? 'DESC' : 'ASC'
    setSortList({ ...sortList, [sortValue]: target })
  }

  const renderUserActions = (user) => {
    // No access to edit Owner account under the Users screen
    if (user.role.roleName === 'Owner' && tokenData.role === 'Manager') {
      return null
    }

    const handleEditUser = (id) => {
      setIdEditUser(id)
    }

    const handleDeactiveUser = (id) => {
      setIdDeactivateUser(id)
    }

    const handleActiveUser = (id) => {
      setIdActivateUser(id)
    }

    const handleResend = (id) => {
      setIdResendUser(id)
    }

    const handleShowDeleteLoad = (id) => {
      setIdDeleteUser(id)
    }

    const userLocalStorage = JSON.parse(localStorage.getItem('user'))

    return (
      <UikDropdown
        DisplayComponent={DotsComponent}
        className={cls.dropdown_edit_load}
        position="bottomRight"
      >
        {(equalStr(user.status, 'Active') ||
          equalStr(user.status, 'Inactive')) && (
          <UikDropdownItem onClick={() => handleEditUser(user.id_user)}>
            <span className={cls.wr_edit_load_icon}>
              <EditLoadIcon height={14} width={14} />
            </span>
            <span className={cls.label}>Edit</span>
          </UikDropdownItem>
        )}

        {equalStr(user.status, 'Active') &&
          String(getStr(userLocalStorage, 'id_user')) !==
            String(user.id_user) && (
            <UikDropdownItem onClick={() => handleDeactiveUser(user.id_user)}>
              <span className={cls.wr_edit_load_icon}>
                <IconDeactivate14 />
              </span>
              <span className={classnames(cls.red, cls.label)}>Deactivate</span>
            </UikDropdownItem>
          )}

        {equalStr(user.status, 'Inactive') && (
          <UikDropdownItem onClick={() => handleActiveUser(user.id_user)}>
            <span className={cls.wr_edit_load_icon}>
              <IconActivate14 />
            </span>
            <span className={cls.label}>Activate</span>
          </UikDropdownItem>
        )}

        {equalStr(user.status, 'Pending') && (
          <UikDropdownItem onClick={() => handleResend(user.id_user)}>
            <span className={cls.wr_edit_load_icon}>
              <IconResend14 />
            </span>
            <span className={cls.label}>Resend</span>
          </UikDropdownItem>
        )}

        {(equalStr(user.status, 'Inactive') ||
          equalStr(user.status, 'Pending')) && (
          <UikDropdownItem onClick={() => handleShowDeleteLoad(user.id_user)}>
            <span className={cls.wr_edit_load_icon}>
              <DeleteLoadIcon />
            </span>
            <span className={classnames(cls.red, cls.label)}>Delete</span>
          </UikDropdownItem>
        )}
      </UikDropdown>
    )
  }

  useEffect(() => {
    setTimeout(() => {
      const key = lastSortInfo.replace('-', '')
      const value = lastSortInfo[0] === '-' ? 'DESC' : 'ASC'
      const nextState = produce(sortList, (draftState) => {
        _.set(draftState, `${key}`, value)
        return draftState
      })
      setSortList(nextState)
    }, 100)
  }, [])

  useEffect(() => {
    const params = {}

    params.sort = lastSortInfo
    params.limit = pageSize

    params.page = page
    params.search = textloadDebounce

    if (
      (lastSortInfo !== lastSortInfoPrev && lastSortInfoPrev !== undefined) ||
      (pageSize !== pageSizePrev && pageSizePrev !== undefined) ||
      textloadDebounce !== textloadDebouncePrev
    ) {
      params.page = 1
      setPage(undefined)
    }

    params.time_request = time_request
    const url = `${apiRoute.users.LIST}?${queryString.stringify(params)}`
    setUrlfetch(url)

    if (
      // TODO
      lastSortInfo === 'name' &&
      page === 1 &&
      pageSize === PAGINATION_PAGE_SIZE_DEFAULT
    ) {
      // when click on icon loads page on the left
      // setCurrentId(null)
      // set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
    }
  }, [page, pageSize, lastSortInfo, time_request, textloadDebounce])

  useEffect(() => {
    function handleData(data_) {
      setListUser(_.get(data_, 'users') || [])
      setTotal(_.get(data_, 'total') || 0)
      setTotalActive(_.get(data_, 'totalActive') || 0)
    }
    if (error === undefined && !!data) {
      handleData(data)
    } else {
      // handleData(sampleUsers)
    }
  }, [data, error])

  const renderListUser = () => {
    if (loadingUsers && _.isEmpty(listUser)) {
      return <SpinnerIcon />
    }

    if (run) {
      return <div />
    }
    return (
      <table style={{ width: 'inherit' }}>
        <thead>
          <tr className={cls.head_row}>
            <th className={cls.c1}>
              <div
                className={cls.sort_wrapper}
                onClick={() => handleSorting('status', 'string')}
              >
                <span
                  className={
                    lastSortInfo.replace('-', '') === 'status'
                      ? 'text-Primary'
                      : 'text-DarkGray'
                  }
                >
                  STATUS
                </span>
                <ArrowDown
                  active={
                    lastSortInfo.replace('-', '') === 'status' ? 'active' : ''
                  }
                  className={classnames({
                    [cls.rotate_asc]: sortList.status === SORT_ORDER.ASC,
                    [cls.rotate_desc]: sortList.status === SORT_ORDER.DESC,
                  })}
                />
              </div>
            </th>
            <th className={cls.c2}>
              <div
                className={cls.sort_wrapper}
                onClick={() => handleSorting('name', 'string')}
              >
                <span
                  className={
                    lastSortInfo.replace('-', '') === 'name'
                      ? 'text-Primary'
                      : 'text-DarkGray'
                  }
                >
                  NAME
                </span>
                <ArrowDown
                  active={
                    lastSortInfo.replace('-', '') === 'name' ? 'active' : ''
                  }
                  className={classnames({
                    [cls.rotate_asc]: sortList.name === SORT_ORDER.ASC,
                    [cls.rotate_desc]: sortList.name === SORT_ORDER.DESC,
                  })}
                />
              </div>
            </th>

            <th className={cls.c4}>
              <div
                className={cls.sort_wrapper}
                onClick={() => handleSorting('role', 'string')}
              >
                <span
                  className={
                    lastSortInfo.replace('-', '') === 'role'
                      ? 'text-Primary'
                      : 'text-DarkGray'
                  }
                >
                  ROLE
                </span>
                <ArrowDown
                  active={
                    lastSortInfo.replace('-', '') === 'role' ? 'active' : ''
                  }
                  className={classnames({
                    [cls.rotate_asc]: sortList.role === SORT_ORDER.ASC,
                    [cls.rotate_desc]: sortList.role === SORT_ORDER.DESC,
                  })}
                />
              </div>
            </th>

            <th className={cls.c5}>
              <div className={cls.sort_wrapper}>
                <span>GROUPS</span>
              </div>
            </th>

            <th className={cls.c3} />
          </tr>
        </thead>

        <tbody>
          {_.isEmpty(listUser) ? (
            <React.Fragment>
              <tr className="h-1 bg-Gray1">
                <td colSpan="9" />
              </tr>
              <tr className="bg-white ">
                <td className="h-[60px] " colSpan="9">
                  <div className="flex items-center justify-center ">
                    <Caption>No user</Caption>
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ) : (
            listUser.map((user) => (
              <React.Fragment key={user.id_user}>
                <tr className={cls.body_row_spacer}>
                  <td className={cls.c1} />
                  <td className={cls.c2} />
                  <td className={cls.c4} />
                  <td className={cls.c5} />
                  <td className={cls.c3} />
                </tr>
                <tr
                  className={classnames(cls.body_row, {
                    [cls.go]: listRecentlyEdittedIdDoc.includes(user.id_user),
                  })}
                  key={user.id_user}
                >
                  <td className={cls.c1}>
                    <StatusButton status={user.status} />
                  </td>
                  <td className={cls.c2}>
                    <Caption
                      block
                    >{`${user.first_name} ${user.last_name}`}</Caption>

                    <Small>
                      {formatPhoneNumber(user.country_code, user.phone_number)}
                      {user.ext ? ` Ext. ${user.ext}` : ''}
                      {(!!user.phone_number || user.ext) && ' - '}
                      {user.email ? `${user.email}` : ''}
                    </Small>
                  </td>

                  <td className={cls.c4}>
                    <Caption>{getStr(user, 'role.roleName')}</Caption>
                    <Tooltip
                      mouseEnterDelay={0}
                      mouseLeaveDelay={0}
                      overlayClassName="mw280"
                      placement="right"
                      title={getStr(user, 'role.roleNote')}
                    >
                      <div
                        className="tooltip5"
                        style={{
                          marginLeft: '8px',
                          display: 'inline-block',
                          transform: 'translateY(2px)',
                        }}
                      >
                        <IconInfo14 />
                      </div>
                    </Tooltip>
                  </td>

                  <td className={cls.c5}>
                    {renderListLink(
                      _.get(user, 'groups') || [],
                      '/page/groups?search=',
                      'name',
                      'idGroup'
                    )}
                  </td>
                  <td className={cls.c3}>
                    <div className="px-[22px]">{renderUserActions(user)}</div>
                  </td>
                </tr>
              </React.Fragment>
            ))
          )}
        </tbody>
      </table>
    )
  }

  return (
    <div className={cls.container_user_profile}>
      <DrawerAddUser
        context={context}
        setShowAdd={setShowAdd}
        set_time_request={set_time_request}
        showAdd={showAdd}
        token={token}
      />
      {!!idEditUser && (
        <DrawerEditUser
          context={context}
          idEditUser={idEditUser}
          setIdEditUser={setIdEditUser}
          setListRecentlyEdittedIdDoc={setListRecentlyEdittedIdDoc}
          setShowAdd={setShowAdd}
          set_time_request={set_time_request}
          showAdd={showAdd}
          token={token}
        />
      )}

      {/* Modal Delete */}
      {!!idDeleteUser && (
        <Modal selector="#modal">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Delete User
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>
              <div className={cls.main_wrapper} ref={closeDropdownRef}>
                Are you sure you want to delete this user?
              </div>
              <div className={cls.button_wrapper}>
                <Button
                  onClick={() => setIdDeleteUser(null)}
                  stateClass="Secondary"
                  width={74}
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleDeleteLoad}
                  stateClass="Primary"
                  width={74}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {/* Modal Delete end */}

      {/* Modal Deactivate */}
      {!!idDeactivateUser && (
        <Modal selector="#modal">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Deactivate User
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>
              <div className={cls.main_wrapper} ref={closeDropdownRef}>
                Are you sure you want to deactivate this user?
              </div>
              <div className={cls.button_wrapper}>
                <Button
                  onClick={() => setIdDeactivateUser(null)}
                  stateClass="Secondary"
                  width={74}
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleDeactivateLoad}
                  stateClass="Primary"
                  width={95}
                >
                  Deactivate
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {/* Modal Deactivate end */}
      {/* Modal Activate */}
      {!!idActivateUser && (
        <Modal selector="#modal">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Activate User
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>
              <div className={cls.main_wrapper} ref={closeDropdownRef}>
                Are you sure you want to activate this user?
              </div>
              <div className={cls.button_wrapper}>
                <Button
                  onClick={() => setIdActivateUser(null)}
                  stateClass="Secondary"
                  width={81}
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleActivateLoad}
                  stateClass="Primary"
                  width={90}
                >
                  Activate
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {/* Modal Activate end */}

      {/* Modal Resend */}
      {!!idResendUser && (
        <Modal selector="#modal">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Resend Welcome Email
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>
              <div className={cls.main_wrapper} ref={closeDropdownRef}>
                Are you sure you want to Resend welcome email?
              </div>
              <div className={cls.button_wrapper}>
                <Button
                  onClick={() => setIdResendUser(null)}
                  stateClass="Secondary"
                  width={74}
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleResendLoad}
                  stateClass={resending ? 'Disabled' : 'Primary'}
                  width={75}
                >
                  Resend
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {/* Modal Resend end */}
      {/* title and Add button */}
      <div className={classnames(cls.wr_title, !run && 'min-w-[1400px]')}>
        <div className={cls.userName}>
          Users
          <MyTooltip
            classNameNormal="ml8"
            classNameTooltip=""
            contentNormal={
              <Caption gray> {`${totalActive}/${total}`} </Caption>
            }
            contentTooltip="Active & Pending/Total"
            tooltipId="driver_active"
          />
        </div>
        <div className={cls.search_wrapper}>
          <Input2WithReset
            className="w-[220px]"
            iconright={
              textload ? (
                <DeleteSearch2 onClick={handleDeleteSearch} />
              ) : (
                <span>
                  <IconSearchLoad />
                </span>
              )
            }
            onChange={handleChangeInputSearch}
            value={textload === undefined ? '' : textload}
          />
        </div>

        <div
          className={isLoggedIn() && 'demo__projects5'}
          onClick={() => {
            setShowAdd(true)
            setStep((pre) => pre + 1)
          }}
        >
          <IconButtonAdd width={38} height={38} />
        </div>
      </div>
      {/* title and Add button end */}
      {/* table users */}
      <div className={classnames(cls.wr_table_users, !run && 'min-w-[1400px]')}>
        {renderListUser()}
      </div>
      {/* table users end */}
      <MyPagination
        className={classnames(cls.pagination, !run && 'min-w-[1400px]')}
        handleChangePageSize={handleChangePageSize}
        loading={loadingUsers}
        page={page || 1}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        setTriggerFetch={setTriggerFetch}
        total={total}
        triggerFetch={triggerFetch}
      />
    </div>
  )
}

export default withContext(Users)
;((_data) => {})([React])
