module.exports = {
  important: true,
  content: ['./src/universal/App/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      animation: {
        editted: 'editted 4s ease-in-out infinite',
      },
      boxShadow: {
        Popover: '0 1px 2px 0 rgb(0 0 0 / 8%)',
      },
      colors: {
        Black: ' #50565E',
        DarkBlue: 'rgba(0, 99, 160, 1)',
        DarkerBlue: 'rgba(2, 63, 98, 1)', // #023f62
        DarkGray: 'rgba(136, 142, 150, 1)', // #888e96ff
        Gray1: 'rgb(251, 251, 253)',
        Green: 'rgba(0, 161, 63, 1)', // #00a13fff
        LighterGray: 'rgba(227, 231, 237, 1)', //#e3e7edff
        LightGray: 'rgba(203, 209, 217, 1)', // #CBD1D9
        LightestGray: 'rgba(227, 231, 237, 0.2)', //#e3e7ed33
        Primary: 'rgba(51, 190, 173, 1)',
        Purple: 'rgba(93, 78, 219, 1)',
        Red: 'rgba(255, 33, 44, 1)', // #ff212c
        LightBlue: 'rgba(36, 201, 253, 1)',
        BackGround: 'rgba(0, 0, 0, 0.5)',
        Blue: '#0075FF',
        borderInput: '#33bead',
        Gray: '#B2B8C0',
        Orange: '#FF9200',
      },
      fontFamily: {
        lato: ['Lato'],
      },
      zIndex: {
        1000: '1000',
      },
      keyframes: {
        editted: {
          '0%': { backgroundColor: 'rgba(255,255,255,1)' },
          '10%': { backgroundColor: 'rgba(51, 190, 173, 0.6)' },
          '100%': { backgroundColor: 'rgba(255,255,255,1)' },
        },
      },
      backgroundImage: {
        'custom-gradient':
          'linear-gradient(90deg, rgba(255, 255, 255, 0.9) 100%) ',
      },
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
      fullhd: '1800px',
      '3xl': '2300px',
    },
  },
  plugins: [],
}
