import { currentStepOfFlow } from '@atoms/atoms'
import clsx from 'clsx'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'

import useOnclickOutside from 'react-cool-onclickoutside'
import { runTour } from '../../atoms/atoms'
import IconButtonAdd from '../svg/IconButtonAdd'

export const ButtonPopoverPlus = ({ listItems }) => {
  const [stepIndex] = useAtom(currentStepOfFlow)
  const [open, setOpen] = useState(false)
  const [run] = useAtom(runTour)

  useEffect(() => {
    if (run && stepIndex === 1) {
      setOpen(true)
    }
  }, [stepIndex, run])
  const ref = useOnclickOutside(() => {
    setOpen(false)
  })
  const handleShowOptionAddDriver = () => {
    setOpen(!open)
  }
  return (
    <div
      className={clsx(
        ' relative w-[38px] h-[38px] demo__projects2 transform',
        stepIndex === 1 ? ' bg-[#7d7d7e] ' : '  '
      )}
      ref={ref}
      style={{
        borderRadius: '3.6px',
      }}
    >
      <div className="relative">
        {/* line white horizontal */}
        <div
          className="w-[38px] h-[38px] rounded-full bg-DarkerBlue cursor-pointer hover:shadow-md hover:bg-DarkBlue"
          onClick={handleShowOptionAddDriver}
        >
          <IconButtonAdd width={38} height={38} />
        </div>

        {/* popover */}
        <div
          className={clsx(
            'z-[10000] demo__projects2 whitespace-nowrap rounded-[4px] bg-white shadow-Popover border-[#d8dce6] mt-1 absolute top-[48px] right-0 cursor-pointer',
            open
              ? ' opacity-100 duration-150 '
              : ' opacity-0 duration-75 pointer-events-none'
          )}
          onClick={() => setOpen(!open)}
        >
          {listItems.map(({ icon, title, onClick }, index) => (
            <React.Fragment key={title}>
              <div
                className="ignore-onclickoutside gap-x-[16px] items-center py-2 w-full px-[16px] hover:bg-LighterGray"
                onClick={onClick}
                style={{ display: 'flex' }}
              >
                {icon}

                <div className="text-Black text-[14px] leading-[150%] ">
                  {title}
                </div>
              </div>
              {index !== listItems.length - 1 && (
                <div className="h-[1px] w-auto mx-4 bg-LighterGray " />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}
;((_data) => {})([React])
