export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
}
export const clientId = process.env.RAZZLE_APP_CLIENT_ID
export const provider = process.env.RAZZLE_APP_AUTH_DOMAIN_NAME
export const CAN_NOT_COMPLETE = 'CAN_NOT_COMPLETE'

export const DriverStatusEnum = {
  PENDING: 'Pending', //0
  ACTIVE: 'Active', //1
  INACTIVE: 'Inactive', //2
  INDEPENDENT: 'Independent', //3
  DELETED: 'Deleted', //4
}

export const TripTypeEnum = {
  PICKUP: 'Pickup', //0
  DELIVERY: 'Delivery', //1
}

export const CountryCodeEnum = {
  US: 'us',
  CA: 'ca',
  MX: 'mx',
}
