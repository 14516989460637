/* eslint-disable no-unused-expressions */
import { MyInputPhone } from '@appComponent/MyInputPhone'
import { Button } from '@appComponent/button'
import { companyGroupsAtom, currentStepOfFlow, runTour } from '@atoms/atoms'
import { UikFormInputGroup } from '@components'
import { getErrorMessage, toastError, toastSuccess } from '@helper'
import { withContext } from '@hoc'
import { getActiveGroups, getActiveVendors } from '@service'
import { Drawer } from 'antd'
import classnames from 'classnames'
import clsx from 'clsx'
import produce from 'immer'
import { useAtom } from 'jotai'
import { isEmpty, isNumber, size, toLower } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useCookies } from 'react-cookie'
import nextId from 'react-id-generator'
import apiRoute from '../../../../../../constants/apiRoute'
import { customAxios } from '../../../../../../helper'
import cls from './bulk-add-driver.scss'
import { Input2, InputSearch, ItemSearch } from '@@/App/@components/input'
import { regex } from '../../../../../../utils'

// @flow

const initDriver = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  countryCode: '1',
  isoCode: 'us',
}

const initError = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
}

const initErrors = [initError, initError, initError]

const initDrivers = [
  { ...initDriver, id: nextId() },
  { ...initDriver, id: nextId() },
  { ...initDriver, id: nextId() },
]

function BulkAddDriver({ onClose, refreshFn }) {
  const [run] = useAtom(runTour)
  const [, setStep] = useAtom(currentStepOfFlow)
  const [cookies, ,] = useCookies(['token'])
  const { token } = cookies

  const [drivers, setDrivers] = useState(initDrivers)
  const [errors, setErrors] = useState(initErrors)

  const [companyGroups] = useAtom(companyGroupsAtom)
  const [activeGroups, setActiveGroups] = useState([])
  const [idGroup, setIdGroup] = useState(null)
  const [textsearchgroup, setTextsearchgroup] = useState('')
  const [loadinggroup, setLoadinggroup] = useState(false)
  const [errorGetActiveGroups, setErrorGetActiveGroups] = useState(null)
  const [errorGetActiveVendors, setErrorGetActiveVendors] = useState(null)

  const [vendorChecked, setVendorChecked] = useState(null)
  const [activeVendors, setActiveVendors] = useState([])
  const [loadingvendor, setLoadingvendor] = useState(false)
  const [choseIdVendor, setChoseIdVendor] = useState('')
  const [vendor, setVendor] = useState('')
  const [savingForm, setSavingForm] = useState(false)

  const isFormValid = React.useMemo(() => {
    if (isEmpty(vendorChecked)) {
      return false
    }

    if (vendorChecked === 'vendor' && !isNumber(choseIdVendor)) {
      return false
    }

    return drivers.some(
      ({ firstName, lastName, phoneNumber }) =>
        firstName && lastName && phoneNumber.length === 10
    )
  }, [drivers, vendorChecked, choseIdVendor])

  const handleCheckRadio = (e) => {
    const { name } = e.target
    setVendorChecked(name)
  }

  const renderSelectVendor = () => (
    <div className="mb-4">
      <label
        className="mb-[10px] text-[12px] text-DarkGray block"
        htmlFor="inputupload_document_file"
      >
        Vendor Name
      </label>
      <InputSearch
        error={errorGetActiveVendors}
        getListFn={getActiveVendors.bind(
          null,
          token,
          setActiveVendors,
          setLoadingvendor,
          setErrorGetActiveVendors
        )}
        idData={choseIdVendor}
        idField="idVendor"
        list={activeVendors}
        loadingData={loadingvendor}
        nameField="name"
        noDataText="No vendor"
        setIdData={setChoseIdVendor}
        setValue={setVendor}
        showPopup
        value={vendor}
      >
        {({
          item,
          o,
          index,
          setIdData,
          setShow,
          setValue,
          idField,
          nameField,
        }) => (
          <ItemSearch
            idField={idField}
            index={index}
            item={item}
            nameField={nameField}
            o={o}
            setIdData={setIdData}
            setShow={setShow}
            setValue={setValue}
          />
        )}
      </InputSearch>
    </div>
  )

  const checkErrorsEmpty = useCallback((errors) => {
    return errors.every((error) => {
      return (
        !error.firstName &&
        !error.lastName &&
        !error.phoneNumber &&
        !error.email
      )
    })
  }, [])

  const submitFormBulkAddDrivers = async () => {
    const newDriver = drivers.filter(
      (item) =>
        item.firstName && item.lastName && item.phoneNumber.length === 10
    )
    const bodyRequest = {
      driversData: newDriver,
      idGroup,
      idVendor: vendorChecked === 'vendor' ? `${choseIdVendor}` : '',
    }

    const isEmptyDriverRow = (driver) => {
      return (
        !driver.email &&
        !driver.firstName &&
        !driver.lastName &&
        !driver.phoneNumber
      )
    }

    const newStateErrors = produce(errors, (draft) => {
      drivers.forEach((driver, index) => {
        if (isEmptyDriverRow(driver)) {
          draft[index] = initError
        } else {
          draft[index].firstName = !driver.firstName
            ? 'First name is required'
            : ''
          draft[index].lastName = !driver.lastName
            ? 'Last name is required'
            : ''
          draft[index].phoneNumber = (function () {
            if (!driver.phoneNumber) {
              return 'Phone number is required'
            }
            if (driver.phoneNumber.length !== 10) {
              return 'Phone number must be 10 digits'
            }

            if (driver.phoneNumber.charAt(0) === '0') {
              return 'Phone number does not exist'
            }
            return ''
          })()
          draft[index].email = (function () {
            if (!driver.email) {
              return ''
            }
            return regex.emailRegex.test(driver.email)
              ? ''
              : 'Please enter a valid email address'
          })()
        }
      })
    })

    setErrors(newStateErrors)
    if (!checkErrorsEmpty(newStateErrors)) {
      return
    }

    try {
      setSavingForm(true)
      const { data: dataResponse } = await customAxios(token).post(
        `${apiRoute.drivers.ADD_BULK_DRIVER}`,
        bodyRequest
      )
      if (dataResponse) {
        const { failedDrivers, successDrivers } = dataResponse
        if (!run) {
          const nameFailed = failedDrivers.map(
            (item) => ` ${item.firstName} ${item.lastName}`
          )
          !isEmpty(successDrivers) && toastSuccess('Added successfully')
          !isEmpty(failedDrivers) &&
            toastError(`Unable to add ${nameFailed}. Try again.`, 10000)
          onClose()
          refreshFn()
        } else if (isEmpty(successDrivers)) {
          const nameFailed = failedDrivers.map(
            (item) => ` ${item.firstName} ${item.lastName}`
          )
          !isEmpty(failedDrivers) &&
            toastError(`Unable to add ${nameFailed}. Try again.`, 10000)
        } else {
          !isEmpty(successDrivers) && setStep((pre) => pre + 1)
          onClose()
          refreshFn()
        }
      }
    } catch (error) {
      toastError(getErrorMessage(error))
    } finally {
      setTimeout(() => {
        setSavingForm(false)
      }, 4000)
    }
  }

  const renderSelectGroup = () => {
    if (size(companyGroups) <= 0) {
      return null
    }
    return (
      <div className={classnames(cls.form_group, 'driver_group_select ')}>
        <label
          className="mb-[10px] text-[12px] text-DarkGray block"
          htmlFor="inputupload_document_file"
        >
          Group (Optional)
        </label>
        <InputSearch
          error={errorGetActiveGroups}
          getListFn={getActiveGroups.bind(
            null,
            token,
            setActiveGroups,
            setLoadinggroup,
            setErrorGetActiveGroups
          )}
          idData={idGroup}
          idField="id_group"
          list={activeGroups}
          loadingData={loadinggroup}
          nameField="name"
          noDataText="No group"
          setIdData={setIdGroup}
          setValue={setTextsearchgroup}
          showPopup
          value={textsearchgroup}
        >
          {({
            item,
            o,
            index,
            setIdData,
            setShow,
            setValue,
            idField,
            nameField,
          }) => (
            <ItemSearch
              idField={idField}
              index={index}
              item={item}
              nameField={nameField}
              o={o}
              setIdData={setIdData}
              setShow={setShow}
              setValue={setValue}
            />
          )}
        </InputSearch>
      </div>
    )
  }

  const addNewDriver = useCallback(() => {
    setDrivers((prev) => [...prev, { ...initDriver, id: nextId() }])
  }, [drivers, setDrivers])

  const updateDrivers = useCallback(
    ({ id, propertyName, value }) => {
      const findIndex = drivers.findIndex((o) => o.id === id)
      if (findIndex === -1) return

      const nextStateDrivers = produce(drivers, (draft) => {
        if (typeof propertyName === 'string') {
          draft[findIndex][propertyName] = value
        } else {
          propertyName.forEach((item, index) => {
            draft[findIndex][item] = value[index]
          })
        }
      })
      setDrivers(nextStateDrivers)

      const nextStateErrors = produce(errors, (draft) => {
        if (typeof propertyName === 'string') {
          draft[findIndex][propertyName] = ''
        } else {
          propertyName.forEach((item) => {
            draft[findIndex][item] = ''
          })
        }
      })
      setErrors(nextStateErrors)
    },
    [drivers, setDrivers, errors, setErrors]
  )

  const removeDriver = useCallback(
    ({ id }) => {
      const nextStateDrivers = produce(drivers, (draft) => {
        const findIndex = draft.findIndex((o) => o.id === id)
        draft.splice(findIndex, 1)
      })
      setDrivers(nextStateDrivers)
    },
    [drivers, setDrivers]
  )

  const addNewError = useCallback(() => {
    setErrors((prev) => [...prev, initError])
  }, [errors, setErrors])

  const removeError = useCallback(
    ({ index }) => {
      const nextStateErrors = produce(errors, (draft) => {
        draft.splice(index, 1)
      })
      setErrors(nextStateErrors)
    },
    [errors, setErrors]
  )

  React.useEffect(() => {
    if (run) {
      setVendorChecked('employee')
    }
  }, [run])

  // React.useEffect(() => {
  //   console.log('aaa errors ', errors)
  // }, [errors])

  // React.useEffect(() => {
  //   console.log('aaa drivers ', drivers)
  // }, [drivers])

  return (
    <Drawer
      className={classnames('wr_drawer_bulk_add_driver', 'demo__projects3')}
      destroyOnClose
      footer={
        <div className="wr_footer_add_load ">
          <Button
            id="cancelDriver"
            onClick={onClose}
            stateClass={run ? 'Disabled' : 'Secondary'}
            width={74}
          >
            Cancel
          </Button>

          <Button
            id="addDriver"
            onClick={submitFormBulkAddDrivers}
            stateClass={!isFormValid || savingForm ? 'Disabled' : 'Primary'}
            width={58}
          >
            Add
          </Button>
        </div>
      }
      maskClosable={false}
      onClose={() => {
        onClose()
      }}
      title="Bulk Add Drivers"
      visible
      width={834}
    >
      {/* drawer body */}
      <div className=" ">
        {/* Drivers' Information */}
        <div className=" ">
          <div className="text-[14px] text-Black font-bold mb-4 ">
            Drivers’ Information
          </div>

          <table className="leading-[150%] mb-2 ">
            <thead>
              <tr className="text-DarkGray text-[12px] font-normal ">
                <th className="font-normal pb-[2px] w-[166px] ">First Name</th>
                <th className="font-normal pb-[2px] w-[166px] ">Last Name</th>
                <th className="font-normal pb-[2px] w-[226px] ">
                  Mobile Phone Number
                </th>
                <th className="font-normal pb-[2px] ">
                  Email Address (Optional)
                </th>
                <th className="font-normal pb-[2px] w-[48px] " />
              </tr>
            </thead>
            <tbody>
              {drivers.map(
                (
                  { firstName, email, lastName, phoneNumber, id, countryCode },
                  index
                ) => (
                  <tr className="" key={id}>
                    <td className="pr-2  py-2">
                      <Input2
                        error={errors[index].firstName}
                        // className="w-[150px]"
                        onChange={(e) => {
                          updateDrivers({
                            id,
                            propertyName: 'firstName',
                            value: e.target.value,
                          })
                        }}
                        value={firstName}
                      />
                    </td>
                    <td className="pr-2 py-2">
                      <Input2
                        error={errors[index].lastName}
                        // className="w-[150px]"
                        onChange={(e) => {
                          updateDrivers({
                            id,
                            propertyName: 'lastName',
                            value: e.target.value,
                          })
                        }}
                        value={lastName}
                      />
                    </td>
                    <td className="pr-2 py-2">
                      <MyInputPhone
                        className={classnames('w-full', {
                          // [cls.errorDetection]: error.phone !== '',
                        })}
                        containerClass={`inputphone_bulkdriver_${id}`}
                        countryDefault="us"
                        error={errors[index].phoneNumber}
                        id={`inputphone_bulkdriver_${id}`}
                        onChange={(value, data) => {
                          updateDrivers({
                            id,
                            propertyName: [
                              'isoCode',
                              'countryCode',
                              'phoneNumber',
                            ],
                            value: [
                              data.countryCode.toUpperCase(),
                              data.dialCode,
                              value.slice(data.dialCode.length),
                            ],
                          })
                        }}
                        value={`${countryCode}${phoneNumber}`}
                      />
                    </td>
                    <td className="py-2">
                      <Input2
                        className="w-[227px]"
                        error={errors[index].email}
                        onChange={(e) => {
                          updateDrivers({
                            id,
                            propertyName: 'email',
                            value: toLower(e.target.value),
                          })
                        }}
                        value={email}
                      />
                    </td>
                    <td className="w-[3%]">
                      <svg
                        className={clsx(
                          'mx-auto',
                          index >= 3 ? '  ' : ' hidden '
                        )}
                        fill="none"
                        height="18"
                        onClick={() => {
                          removeDriver({ id })
                          removeError({ index })
                        }}
                        viewBox="0 0 18 18"
                        width="18"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          clipRule="evenodd"
                          d="M9.28125 3.375C6.17625 3.375 3.65625 5.895 3.65625 9C3.65625 12.105 6.17625 14.625 9.28125 14.625C12.3862 14.625 14.9062 12.105 14.9062 9C14.9062 5.895 12.3862 3.375 9.28125 3.375ZM4.78125 9C4.78125 11.4806 6.80063 13.5 9.28125 13.5C11.7619 13.5 13.7812 11.4806 13.7812 9C13.7812 6.51938 11.7619 4.5 9.28125 4.5C6.80063 4.5 4.78125 6.51938 4.78125 9ZM6.46875 8.4375V9.5625H12.0938V8.4375H6.46875Z"
                          fill="#50565E"
                          fillRule="evenodd"
                        />
                      </svg>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>

          <Button
            onClick={() => {
              addNewDriver()
              addNewError()
            }}
            stateClass="Link"
            tabIndex="0"
          >
            Add
          </Button>
        </div>
        {/* Drivers' Information end */}

        {/* line */}
        <div className="h-[2px] bg-LightestGray my-4" />
        {/* line end */}

        {/* Additional Information */}
        <div className=" ">
          <div className="text-[14px] text-Black font-bold mb-4 ">
            Additional Information
          </div>

          <div className="w-[320px] ">
            <UikFormInputGroup className={cls.radioContainer}>
              <span className={cls.spanRadioEmployee}>
                <input
                  checked={vendorChecked === 'employee'}
                  className={cls.employee}
                  id="radioEmployee"
                  name="employee"
                  onChange={handleCheckRadio}
                  type="radio"
                />
                <label className={cls.employeeLabel} htmlFor="radioEmployee">
                  Employee/Owner Operator
                </label>
              </span>

              <span className={cls.spanRadioVendor}>
                <input
                  checked={vendorChecked === 'vendor'}
                  className={cls.vendor}
                  disabled={run}
                  id="radioVendor"
                  name="vendor"
                  onChange={handleCheckRadio}
                  type="radio"
                />
                <label
                  className={classnames(cls.vendorLabel, run && 'text-Gray')}
                  htmlFor="radioVendor"
                >
                  Vendor
                </label>
              </span>
            </UikFormInputGroup>

            {vendorChecked === 'vendor' && (
              <React.Fragment>{renderSelectVendor()}</React.Fragment>
            )}

            {renderSelectGroup()}
          </div>
        </div>

        {/* Additional Information end */}
      </div>
      {/* drawer body end */}
    </Drawer>
  )
}

export default withContext(BulkAddDriver)
;((_data) => {})([React])
