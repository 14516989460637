import classnames from 'classnames'
import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import InputMask from 'react-input-mask'

import { isFunction } from 'lodash'
import 'react-datepicker/dist/react-datepicker.css'
import { Portal } from 'react-overlays'
import cls from './common.scss'

function PickDate({
  resetErrorDate,
  resetErrorDateAndTime,
  error,
  className,
  value,
  onChange,
  reset,
  tabIndex,
  disabled,
  popperContainerSelector,
}) {
  const dateRef = useRef()
  function handleChangeRaw(e) {
    if (e.target.value === '__/__/__') {
      reset()
    }
  }

  useEffect(() => {
    if (!value) {
      if (isFunction(resetErrorDateAndTime)) {
        resetErrorDateAndTime()
      }
    } else {
      if (isFunction(resetErrorDate)) {
        resetErrorDate()
      }
    }
  }, [value])

  return (
    <div className={classnames(cls.date_picker, className)}>
      <DatePicker
        autoComplete="off"
        className={className}
        customInput={
          <InputMask className={clsx(error ? cls.error : '')} mask="99/99/99" />
        }
        dateFormat="MM/dd/yy"
        disabled={disabled}
        onChange={(e) => {
          onChange(e)
          setTimeout(() => {
            dateRef.current.input.focus()
          }, 1)
        }}
        onChangeRaw={handleChangeRaw}
        onKeyDown={(e) => {
          if (isFunction(resetErrorDate)) {
            resetErrorDate()
          }
          if (e.key === 'Tab') {
            dateRef.current.setOpen(false)
          }
        }}
        popperContainer={({ children }) => {
          const el = Array.from(
            document.querySelectorAll(popperContainerSelector)
          )[0]
          return <Portal container={el}>{children}</Portal>
        }}
        ref={dateRef}
        selected={value}
        tabIndex={tabIndex || '0'}
      />
    </div>
  )
}

export default PickDate
;((_data) => {})([React])
