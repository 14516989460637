import React from 'react'

const IconCustomerServiceCenter = () => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6.66667 2H25.3333C26.8 2 28 3.2 28 4.66667V23.3333C28 24.8 26.8 26 25.3333 26H20L16 30L12 26H6.66667C5.18667 26 4 24.8 4 23.3333V4.66667C4 3.2 5.18667 2 6.66667 2ZM18.8933 23.3333H25.3333V4.66667H6.66667V23.3333H13.1067L13.88 24.1067L16 26.2267L18.1067 24.12L18.8933 23.3333ZM14.6667 22V19.3333H17.3333V22H14.6667ZM18.6667 11.3333C18.6667 9.86667 17.4667 8.66667 16 8.66667C14.5333 8.66667 13.3333 9.86667 13.3333 11.3333H10.6667C10.6667 8.38667 13.0533 6 16 6C18.9467 6 21.3333 8.38667 21.3333 11.3333C21.3333 13.0439 20.28 13.9644 19.2544 14.8607C18.2814 15.711 17.3333 16.5395 17.3333 18H14.6667C14.6667 15.5716 15.9228 14.6088 17.0273 13.7622C17.8936 13.0981 18.6667 12.5056 18.6667 11.3333Z"
      fill="#50565E"
      fillRule="evenodd"
    />
  </svg>
)
export default IconCustomerServiceCenter
;((_data) => {})([React])
