import { MyCheckbox } from '@appComponent/MyCheckbox'
import { PlacesAutoComplete } from '@appComponent/PlacesAutoComplete'
import { StatusButton } from '@appComponent/StatusButton'
import {
  ArrowSelect,
  DeleteSearch2,
  IconDeleteAddLoad,
  IconHambergerAddLoad,
  IconMinusAddLoad,
  IconRightAddLoad,
  IconTargetAddLoad,
} from '@appComponent/icon'
import { Input2, Textarea2 } from '@appComponent/input'
import { COMPLETE } from '@constants/load_status'
import { equalStr, getStr } from '@helper'
import { Select } from 'antd'
import classnames from 'classnames'
import clsx from 'clsx'
import _, { identity } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { PickDate, TimePickerComponent } from './components/common'
import { INCOMPLETE } from '@@/constants/load_status'
import { TripTypeEnum } from '../../../../constants'

const { Option } = Select
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging
    ? 'rgba(51, 190, 173, 0.5)'
    : 'rgba(227, 231, 237, 0.2)',
  border: isDragging ? '1px solid rgba(0,0,0,0)' : '1px solid #e3e7ed',
  borderRadius: '4px',
  marginBottom: '24px',
  padding: '16px',
  overflow: 'hidden',
  ...draggableStyle,
})

const OneStopEdit = identity(
  ({
    setErrors,
    errors,
    item,
    i,
    updateListTrip,
    handleDeleteTripByUniqueId,
    handleClickAppt,
    handleClickToggleCollapse,
    context,
  }) => {
    const isDone = useMemo(() => {
      return (
        equalStr(getStr(item, 'trip_status'), COMPLETE) ||
        equalStr(getStr(item, 'trip_status'), INCOMPLETE)
      )
    }, [item])

    let addressAndName = item.location.address.includes(item.location.name)
      ? item.location.address
      : `${item.location.name}, ${item.location.address}`
    let valueInputLocation =
      getStr(item, 'location.displayValue') || addressAndName

    useEffect(() => {
      if (item.tracking === false) {
        setErrors({
          ...errors,
          location: '',
          lt: '',
          ld: '',
          et: '',
          ed: '',
        })
      }
    }, [item.tracking])

    return (
      <Draggable className="Draggable" draggableId={item.uniqueid} index={i}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={clsx(
              'wr_one_stop',
              (() => {
                return ''
                // if (item.collapse) {
                //   return 'h-[138px]'
                // }
                // if (item.isAppointment === 0) {
                //   return 'h-[615px]'
                // }
                // return 'h-[534px]'
              })()
            )}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <div className="wr_one_stop--wr_titleeditmodal">
              <div
                className="toggle_stop"
                onClick={() => handleClickToggleCollapse(i)}
              >
                <IconRightAddLoad
                  className={classnames({
                    rotate90: item.collapse === false,
                  })}
                />
              </div>
              <span className="title_stop">Stop #{i + 1}</span>
              <div className="wr_status_stop_edit_modal">
                <StatusButton
                  classNameWidth={clsx(
                    ' w-[80px] ',
                    _.isEmpty(item.trip_status)
                      ? ' opacity-0 pointer-events-none'
                      : '  '
                  )}
                  status={getStr(item, 'trip_status')}
                />
              </div>
              <div className="dragicon_stop flex-1-0-100">
                <IconHambergerAddLoad />
              </div>
              {i !== 0 && (
                <button
                  className={classnames(
                    'wr_icon_delete_stop',
                    'button_no_style'
                  )}
                  onClick={() => handleDeleteTripByUniqueId(item.uniqueid)}
                >
                  <IconDeleteAddLoad />
                </button>
              )}
            </div>
            <div
              className={clsx(
                'row1 stop_type_row u_d_flex',
                item.collapse ? ' mb-0 ' : ' mb-4 '
              )}
            >
              <div>
                <div className="label_load1">Stop Type (Optional)</div>

                <Select
                  disabled={isDone}
                  allowClear
                  clearIcon={<DeleteSearch2 />}
                  id={`inputstoptype-${i}`}
                  onChange={(value) =>
                    updateListTrip(item.uniqueid, 'tripType', value)
                  }
                  suffixIcon={<ArrowSelect />}
                  value={item.tripType ? String(item.tripType) : undefined}
                >
                  <Option value={TripTypeEnum.PICKUP}>Pickup</Option>
                  <Option value={TripTypeEnum.DELIVERY}>Delivery</Option>
                </Select>
              </div>
              <div>
                <div className="label_load1">Order# (Optional)</div>
                <Input2
                  disabled={isDone}
                  id={`inputorder-${i}`}
                  onChange={(e) =>
                    updateListTrip(item.uniqueid, 'order', e.target.value)
                  }
                  tabIndex="0"
                  value={item.order}
                />
              </div>
              <div>
                <div className="label_load1">Appt# (Optional)</div>
                <Input2
                  disabled={isDone}
                  onChange={(e) =>
                    updateListTrip(
                      item.uniqueid,
                      'appointmentNumber',
                      e.target.value
                    )
                  }
                  value={item.appointmentNumber}
                />
              </div>
            </div>
            {/* hidden when in collapse mode */}
            <div className={`${item.collapse ? 'hidden' : ''}`}>
              <div className="row1">
                <div>
                  <div className="label_load1">Stop Name</div>
                  <Input2
                    disabled={isDone}
                    error={!!errors.tripName}
                    onChange={(e) => {
                      setErrors({ ...errors, tripName: '' })
                      updateListTrip(item.uniqueid, 'tripName', e.target.value)
                    }}
                    tabIndex={item.collapse ? '-1' : '0'}
                    value={getStr(item, 'tripName')}
                  />
                  {errors.tripName && (
                    <div className="text-Red mt-1 text-[12px] leading-[150%] ">
                      {errors.tripName}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center gap-x-[8px] mb-4 ">
                <MyCheckbox
                  checked={item.tracking}
                  disabled={isDone}
                  handleClickCheckbox={(e) => {
                    updateListTrip(item.uniqueid, 'tracking', e.target.checked)
                  }}
                  id={`editloadform_trackingstop_${item.uniqueid}`}
                />
                <label
                  className="text-Black text-[14px] leading-[150%] "
                  htmlFor={`editloadform_trackingstop_${item.uniqueid}`}
                  onClick={null}
                >
                  Track with Smart ETA
                </label>
              </div>

              <div className="row1">
                <div>
                  <div className="label_load1">
                    Street Address {!item.tracking ? '(Optional)' : ''}
                  </div>

                  <PlacesAutoComplete
                    disabled={isDone}
                    context={context}
                    error={!!errors.location}
                    onlyUs={false}
                    tabIndex={item.collapse ? '-1' : '0'}
                    updateFunctionOnChange={(value) => {
                      setErrors({ ...errors, location: '' })
                      updateListTrip(item.uniqueid, 'location', value)
                    }}
                    updateFunctionOnSelect={updateListTrip.bind(
                      null,
                      item.uniqueid,
                      'location'
                    )}
                    valueInputLocation={valueInputLocation}
                  />
                  {errors.location && (
                    <div className="text-Red mt-1 text-[12px] leading-[150%] ">
                      {errors.location}
                    </div>
                  )}
                </div>
              </div>

              <div className="row1 date_row">
                <div className="appt_window">
                  {item.isAppointment === 0 && (
                    <div
                      className="appt_window_row mb-4"
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div className="w-[160px] mr-2 ">
                        <div className="label_load1 whitespace-nowrap">
                          {`Earliest Date ${
                            !item.tracking ? '(Optional)' : ''
                          }`}
                        </div>
                        <PickDate
                          disabled={isDone}
                          className="date_picker_load"
                          error={!!errors.ed}
                          onChange={(date) => {
                            updateListTrip(item.uniqueid, 'ed', date)
                          }}
                          popperContainerSelector=".wr_drawer_add_edit .ant-drawer-content-wrapper"
                          reset={updateListTrip.bind(
                            null,
                            item.uniqueid,
                            'ed',
                            ''
                          )}
                          resetErrorDate={() => {
                            setErrors({ ...errors, ed: '' })
                          }}
                          resetErrorDateAndTime={() => {
                            setErrors({ ...errors, et: '', ed: '' })
                          }}
                          tabIndex={item.collapse ? '-1' : '0'}
                          value={item.ed}
                        />
                        {errors.ed && (
                          <div className="text-Red mt-1 text-[12px] leading-[150%] ">
                            {errors.ed}
                          </div>
                        )}
                      </div>
                      <div className="w-[100px] ">
                        <div className="label_load1 whitespace-nowrap">
                          {`Earliest Time ${
                            !item.tracking ? '(Optional)' : ''
                          }`}
                        </div>
                        <TimePickerComponent
                          disabled={isDone}
                          className="time_picker_load"
                          error={!!errors.et}
                          onHandleChangeTime={(time) => {
                            updateListTrip(item.uniqueid, 'et', time)
                          }}
                          resetErrorDateAndTime={() => {
                            setErrors({ ...errors, et: '', ed: '' })
                          }}
                          resetErrorTime={() => {
                            setErrors({ ...errors, et: '' })
                          }}
                          tabIndex={item.collapse ? '-1' : '0'}
                          timeValue={item.et}
                          type="earliest_time"
                        />
                        {errors.et && (
                          <div className="text-Red mt-1 text-[12px] leading-[150%] ">
                            {errors.et}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className="appt_window_row mb-4"
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div className="w-[160px] mr-2 ">
                      <div className="label_load1 whitespace-nowrap">
                        {`Latest Date ${!item.tracking ? '(Optional)' : ''}`}
                      </div>
                      <PickDate
                        disabled={isDone}
                        className="date_picker_load"
                        error={!!errors.ld}
                        onChange={(date) =>
                          updateListTrip(item.uniqueid, 'ld', date)
                        }
                        popperContainerSelector=".wr_drawer_add_edit .ant-drawer-content-wrapper"
                        reset={updateListTrip.bind(
                          null,
                          item.uniqueid,
                          'ld',
                          ''
                        )}
                        resetErrorDate={() => {
                          setErrors({ ...errors, ld: '' })
                        }}
                        resetErrorDateAndTime={() => {
                          setErrors({ ...errors, lt: '', ld: '' })
                        }}
                        tabIndex={item.collapse ? '-1' : '0'}
                        value={item.ld}
                      />
                      {errors.ld && (
                        <div className="text-Red mt-1 text-[12px] leading-[150%] ">
                          {errors.ld}
                        </div>
                      )}
                    </div>
                    <div className="w-[100px] ">
                      <div className="label_load1 whitespace-nowrap">
                        {`Latest Time ${!item.tracking ? '(Optional)' : ''}`}
                      </div>
                      <TimePickerComponent
                        disabled={isDone}
                        className="time_picker_load"
                        error={!!errors.lt}
                        onHandleChangeTime={(time) => {
                          updateListTrip(item.uniqueid, 'lt', time)
                        }}
                        resetErrorDateAndTime={() => {
                          if (item.tracking === false && !!errors.ld) {
                            setErrors({ ...errors, ld: '', lt: '' })
                          }
                        }}
                        resetErrorTime={() => {
                          setErrors({ ...errors, lt: '' })
                        }}
                        tabIndex={item.collapse ? '-1' : '0'}
                        timeValue={item.lt}
                        type="latest_time"
                      />
                      {errors.lt && (
                        <div className="text-Red mt-1 text-[12px] leading-[150%] ">
                          {errors.lt}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!isDone && (
                  <button
                    className="button_no_style"
                    onClick={() => handleClickAppt(i)}
                    tabIndex={item.collapse ? '-1' : '0'}
                  >
                    {item.isAppointment === 1 ? (
                      <IconTargetAddLoad />
                    ) : (
                      <IconMinusAddLoad />
                    )}
                  </button>
                )}
              </div>
              <div className="row1" style={{ marginBottom: '0px' }}>
                <div className="label_load1">Note (Optional)</div>
                <Textarea2
                  disabled={isDone}
                  height={72}
                  onChange={(e) =>
                    updateListTrip(item.uniqueid, 'note', e.target.value)
                  }
                  tabIndex={item.collapse ? '-1' : '0'}
                  value={item.note ? item.note : ''}
                />
              </div>
            </div>
            {/* hidden when in collapse mode end */}
          </div>
        )}
      </Draggable>
    )
  }
)

export default OneStopEdit
;((_data) => {})([React])
