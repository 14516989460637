// Libraries
import classnames from 'classnames'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import withAuth from '@auth'
import { UikContainerHorizontal, UikContainerVertical } from '@components'
import route from '@constants/route'

import { useAtom } from 'jotai'
import { runTour } from '../../atoms/atoms'
import Header from './components/header'
import cls from './page.module.scss'
import * as pages from './pages'

// class Page extends React.PureComponent<{
//   location: Object,
// }> {
//   static getDerivedStateFromProps(props, state) {
//     return {
//       ...state,
//       pathname: props.location.pathname,
//       activeContent:
//         props.location.pathname === state.pathname
//           ? state.activeContent
//           : 'Dashboard',
//     }
//   }

//   state = {
//     activeContent: 'dashboard',
//   }

//   setActiveContent = (content, callback) => {
//     this.setState(
//       {
//         activeContent: content,
//       },
//       callback
//     )
//   }

//   handleClickTab = tab => () => {
//     this.setActiveContent(tab, () => {
//       this.props.history.push(`/page/${tab.toLowerCase()}`)
//     })
//   }

//   render() {
//     const {
//       location: { pathname },
//     } = this.props

//     const page = pathname.split('/')[2]

//     return (
//       <UikContainerVertical style={{ height: 'auto' }}>
//         <Header
//           history={this.props.history}
//           page={page}
//           pathname={getStr(this.props, 'location.pathname')}
//         />
//         <UikContainerHorizontal
//           className={classnames(cls.contentContainer, 'min-w-[1448px] ', {
//             contentContainer: true,
//           })}
//         >
//           <div>
//             <Switch>
//               <Route
//                 component={pages.Dashboard}
//                 path={route.PAGE.SUB_ROUTE.DASHBOARD}
//               />
//               <Route
//                 component={pages.Loadsnew}
//                 path={route.PAGE.SUB_ROUTE.LOADSNEW}
//               />

//               <Route
//                 component={pages.Drivers}
//                 path={route.PAGE.SUB_ROUTE.DRIVERS}
//               />
//               <Route
//                 component={pages.Account}
//                 path={route.PAGE.SUB_ROUTE.ACCOUNT}
//               />
//               <Route
//                 component={pages.Vendors}
//                 path={route.PAGE.SUB_ROUTE.VENDORS}
//               />
//               <Route
//                 component={pages.Documents}
//                 path={route.PAGE.SUB_ROUTE.DOCUMENTS}
//               />
//               <Route
//                 component={pages.Preferences}
//                 path={route.PAGE.SUB_ROUTE.COMPANY}
//               />
//               <Route
//                 component={pages.Preferences}
//                 path={route.PAGE.SUB_ROUTE.STORAGE}
//               />
//               <Route
//                 component={pages.Tracking}
//                 path={route.PAGE.SUB_ROUTE.TRACKING}
//               />
//               <Route component={pages.User} path={route.PAGE.SUB_ROUTE.USERS} />

//               <Route
//                 component={pages.YourAccountIsDisabled}
//                 exact
//                 path={route.PAGE.SUB_ROUTE.YOUR_ACCOUNT_IS_DISABLED}
//               />

//               <Route
//                 component={pages.Groups}
//                 path={route.PAGE.SUB_ROUTE.GROUPS}
//               />
//               <Redirect to={route.PAGE.SUB_ROUTE.DASHBOARD} />
//             </Switch>
//           </div>
//         </UikContainerHorizontal>
//       </UikContainerVertical>
//     )
//   }
// }

// export default withAuth(Page)
const Page = ({ location: { pathname }, history }) => {
  const page = pathname.split('/')[2]
  const [run] = useAtom(runTour)
  return (
    <UikContainerVertical style={{ height: 'auto' }}>
      <Header history={history} page={page} pathname={pathname} />
      <UikContainerHorizontal
        className={classnames(cls.contentContainer, !run && 'min-w-[1448px] ', {
          contentContainer: true,
        })}
      >
        <div>
          <Switch>
            <Route
              component={pages.Dashboard}
              path={route.PAGE.SUB_ROUTE.DASHBOARD}
            />
            <Route
              component={pages.Loadsnew}
              path={route.PAGE.SUB_ROUTE.LOADSNEW}
            />

            <Route
              component={pages.Drivers}
              path={route.PAGE.SUB_ROUTE.DRIVERS}
            />
            <Route
              component={pages.Account}
              path={route.PAGE.SUB_ROUTE.ACCOUNT}
            />
            <Route
              component={pages.Vendors}
              path={route.PAGE.SUB_ROUTE.VENDORS}
            />
            <Route
              component={pages.Documents}
              path={route.PAGE.SUB_ROUTE.DOCUMENTS}
            />
            <Route
              component={pages.Preferences}
              path={route.PAGE.SUB_ROUTE.COMPANY}
            />
            <Route
              component={pages.Preferences}
              path={route.PAGE.SUB_ROUTE.STORAGE}
            />
            <Route
              component={pages.Preferences}
              path={route.PAGE.SUB_ROUTE.AUTOMATIONS}
            />
            <Route
              component={pages.Tracking}
              path={route.PAGE.SUB_ROUTE.TRACKING}
            />
            <Route component={pages.User} path={route.PAGE.SUB_ROUTE.USERS} />

            <Route
              component={pages.YourAccountIsDisabled}
              exact
              path={route.PAGE.SUB_ROUTE.YOUR_ACCOUNT_IS_DISABLED}
            />

            <Route
              component={pages.Groups}
              path={route.PAGE.SUB_ROUTE.GROUPS}
            />
            <Redirect to={route.PAGE.SUB_ROUTE.DASHBOARD} />
          </Switch>
        </div>
      </UikContainerHorizontal>
    </UikContainerVertical>
  )
}
export default withAuth(Page)
;((_data) => {})([React])
