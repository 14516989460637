import React from 'react'

const IconStorage = ({ fill }) => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 19 18"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6875 14.2507C14.2053 14.2507 14.625 13.831 14.625 13.3132C14.625 12.7955 14.2053 12.3757 13.6875 12.3757C13.1697 12.3757 12.75 12.7955 12.75 13.3132C12.75 13.831 13.1697 14.2507 13.6875 14.2507Z"
      fill={fill || '#50565E'}
    />
    <path
      clipRule="evenodd"
      d="M3.76439 1.125C3.40956 1.125 3.10329 1.37366 3.03039 1.72091L1.14304 10.7115C1.13123 10.7644 1.125 10.8193 1.125 10.8757V15.7507C1.125 16.1649 1.46079 16.5007 1.875 16.5007H16.875C17.2892 16.5007 17.625 16.1649 17.625 15.7507V10.8757C17.625 10.82 17.6189 10.7656 17.6074 10.7133L15.742 1.72264C15.6698 1.37456 15.3632 1.125 15.0077 1.125H3.76439ZM15.9535 10.1257L14.3973 2.625H4.37329L2.7987 10.1257H15.9535ZM2.625 11.6257V15.0007H16.125V11.6257H2.625Z"
      fill={fill || '#50565E'}
      fillRule="evenodd"
    />
  </svg>
)
export default IconStorage
;((_data) => {})([React])
