import React from 'react'

const ShowEye = (props) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.125 12C5.4225 8.7075 8.625 6.375 12.375 6.375C16.125 6.375 19.3275 8.7075 20.625 12C19.3275 15.2925 16.125 17.625 12.375 17.625C8.625 17.625 5.4225 15.2925 4.125 12ZM18.99 12C17.7525 9.4725 15.2175 7.875 12.375 7.875C9.5325 7.875 6.9975 9.4725 5.76 12C6.9975 14.5275 9.525 16.125 12.375 16.125C15.225 16.125 17.7525 14.5275 18.99 12ZM12.375 10.125C13.41 10.125 14.25 10.965 14.25 12C14.25 13.035 13.41 13.875 12.375 13.875C11.34 13.875 10.5 13.035 10.5 12C10.5 10.965 11.34 10.125 12.375 10.125ZM9 12C9 10.14 10.515 8.625 12.375 8.625C14.235 8.625 15.75 10.14 15.75 12C15.75 13.86 14.235 15.375 12.375 15.375C10.515 15.375 9 13.86 9 12Z"
      fill="#50565E"
      fillRule="evenodd"
    />
  </svg>
)

export default ShowEye
;((_data) => {})([React])
