import { Button } from '@appComponent/button'
import { Input2 } from '@appComponent/input'
import { Small } from '@appComponent/typography'
import apiRoute from '@constants/apiRoute'
import { customAxios, getErrorMessage, getStr } from '@helper'
import { useGroups } from '@hooks'
import { updateMyGroups } from '@service'
import { Drawer } from 'antd'
import classnames from 'classnames'
import { identity, orderBy, trim } from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'
import InputMember from './InputMember'
import cls from './groups.scss'

const DrawerEditGroup = identity(
  ({
    idEditGroup,
    setIdEditGroup,
    token,
    context,
    set_time_request,
    setListRecentlyEdittedIdDoc,
  }) => {
    const { setGroups, setLoadinggroups, checkedGroups, setCheckedGroups } =
      useGroups()

    const [name, setName] = useState('')

    const [isDisable, setDisable] = useState(true)
    const [savingOrDispatching, setSavingOrDispatching] = useState(false)
    const onClose = () => {
      setIdEditGroup(null)
    }

    const [listMemberSelected, setListMemberSelected] = useState([])

    const listIdUserSelected = useMemo(
      () =>
        listMemberSelected
          .filter((o) => o.idUser && !o.idDriver)
          .map((o) => o.idUser),
      [listMemberSelected]
    )

    const listIdDriverSelected = useMemo(
      () =>
        listMemberSelected
          .filter((o) => !o.idUser && o.idDriver)
          .map((o) => o.idDriver),
      [listMemberSelected]
    )

    const handleClickSave = async () => {
      try {
        setSavingOrDispatching(true)

        const body = {
          name: trim(name),
          listIdUser: listIdUserSelected,
          listIdDriver: listIdDriverSelected,
        }
        const resp = await customAxios(token).put(
          `${apiRoute.groups.EDIT}/${idEditGroup}`,
          body
        )
        if (!resp.data.success) {
          context.message.error('Updating Group Failed!')
          return
        }

        set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
        context.message.success('Edited Successfully')

        setListRecentlyEdittedIdDoc([idEditGroup])
        setIdEditGroup(null)

        updateMyGroups(
          token,
          setGroups,
          setLoadinggroups,
          checkedGroups,
          setCheckedGroups
        )
      } catch (err) {
        context.message.error(getErrorMessage(err))
      } finally {
        setTimeout(() => {
          setSavingOrDispatching(false)
        }, 3888)
      }
    }

    useEffect(() => {
      async function getGroupById(id) {
        const handleData = (data) => {
          const members = []
          if (data.users !== null) {
            data.users.forEach((o) => {
              members.push({
                idUser: o.idUser,
                idDriver: null,
                fullName: o.fullName,
                countUnfinishedLoads: null,
              })
            })
          }

          if (data.drivers !== null) {
            data.drivers.forEach((o) => {
              members.push({
                idDriver: o.idDriver,
                idUser: null,
                fullName: o.fullName,
                countUnfinishedLoads: o.countUnfinishedLoads,
              })
            })
          }
          setName(getStr(data, 'name')) // TODO
          setListMemberSelected(orderBy(members, ['fullName'])) // TODO
        }

        try {
          // setListDocuments([])
          const url = `${apiRoute.groups.GET_GROUP_BY_ID}/${id}`
          const { data: dataResponse } = await customAxios(token).get(url)
          if (!dataResponse.success) {
            return
          }
          handleData(dataResponse.data)
        } catch (err) {
          //
        }
      }
      if (idEditGroup) {
        getGroupById(idEditGroup)
        setTimeout(() => {
          const el = document.querySelectorAll('input#input_group_firstname')[0]
          if (el) {
            el.focus()
          }
        }, 200)
      } else {
        //
      }
    }, [idEditGroup])

    useEffect(() => {
      if (trim(name)) {
        setDisable(false)
      } else {
        setDisable(true)
      }
    }, [name])

    return (
      <Drawer
        className={classnames(cls.wr_drawer_upload_doc, 'wr_drawer_edit_group')}
        destroyOnClose
        footer={
          <div
            className={`wr_footer_add_load ${
              savingOrDispatching ? 'disabled1' : ''
            }`}
          >
            <Button
              onClick={savingOrDispatching ? null : onClose}
              stateClass={savingOrDispatching ? 'Disabled' : 'Secondary'}
              width={74}
            >
              Cancel
            </Button>
            <div className="wr_save_dispatch">
              <Button
                onClick={
                  isDisable || savingOrDispatching ? null : handleClickSave
                }
                stateClass={
                  isDisable || savingOrDispatching ? 'Disabled' : 'Primary'
                }
                width={61}
              >
                Save
              </Button>
            </div>
          </div>
        }
        maskClosable={false}
        onClose={onClose}
        title="Edit Group"
        visible
        width={448}
      >
        <div className={cls.form_group}>
          <label htmlFor="input_group_firstname">
            <Small>Group Name</Small>
          </label>
          <Input2
            id="input_group_firstname"
            onChange={(e) => {
              setName(e.target.value)
            }}
            onBlur={(e) => {
              setName(trim(e.target.value))
            }}
            value={name}
          />
        </div>

        <div className={cls.form_group}>
          <label htmlFor="input_group_lastname">
            <Small>Add Member</Small>
          </label>
          <InputMember
            {...{
              context,
              token,
              listMemberSelected,
              setListMemberSelected,
              groupName: name,
            }}
          />
        </div>
      </Drawer>
    )
  }
)

export default DrawerEditGroup
;((_data) => {})([React])
