import classNames from 'classnames'
import React from 'react'
import { UikHeadline } from '../../../components'
import logo from '../../../driverDocAssets/DriverDocMainLogo.png'
import { withContext } from '../../../hoc'
import { H6 } from '../../@components/typography'
import cls from './error-page.scss'

function ErrorPage() {
  return (
    <div className={cls.error_page}>
      <div className={classNames(cls.img_left, cls.c, cls.c1)}>
        <img alt="logo" className={cls.img_logo} src={logo} />
      </div>
      <div className={cls.form_error}>
        <div className={cls.form}>
          <UikHeadline className={cls.headline}>
            <H6>Uh oh! Something's wrong!</H6>
          </UikHeadline>
          <div className={cls.desc}>
            <div className={cls.item}>
              We are unable to create your account. Please
            </div>{' '}
            <a
              className={cls.item}
              href="https://www.driverdoc.io/customer-service"
            >
              click here
            </a>{' '}
            <div className={cls.item}>&nbsp;or email us at</div>{' '}
            <a className={cls.item} href="mailto:help@driverdoc.io">
              &nbsp; help@driverdoc.io
            </a>{' '}
            <div className={cls.item}>&nbsp;for assistance.</div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default withContext(ErrorPage)
;((_data) => {})([React])
