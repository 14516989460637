import { Button } from '@appComponent/button'

import {
  DateIconNew,
  DeleteLoadIcon,
  DeleteSearch2,
  DotsComponent,
  EditLoadIcon,
  FilterIcon,
  IconArchive14,
  IconArchive32,
  IconUnarchive32,
  IconDelete32,
  IconDownload32,
  IconEdit32,
  IconMerge32,
  IconReject14,
  IconReject32,
  IconResend14New,
  IconResend32,
  IconSend14,
  IconSend32,
} from '@appComponent/icon'
import { tokenDataAtom } from '@atoms/atoms'
import useOnclickOutside from 'react-cool-onclickoutside'

import { Input2Advanced, Input2WithReset } from '@appComponent/input'

// import SelectDate from '@duik/select-date'
import Modal from '@appComponent/modalPortal'
import MyPagination from '@appComponent/MyPagination'
import { Caption } from '@appComponent/typography'
import usePrevious from '@appComponent/usePrevious'

import withAuth from '@auth'
import {
  UikDropdown,
  UikDropdownItem,
  UikTopBar,
  UikTopBarSection,
  UikTopBarTitle,
} from '@components'

import IconPrint from '@driverDOCassets/IconPrint.png'
import {
  checkExpired,
  checkNumber,
  customAxios,
  databaseDateFormat,
  displayDateFormat,
  download_files,
  formatTimeSearchLoad,
  getErrorMessage,
  getStr,
  sortListStatusDocument,
} from '@helper'
import { withContext } from '@hoc'
import {
  useGroups,
  useRecentlyEdited,
  useScreenWidth,
  useUserLocation,
} from '@hooks'
import { DatePicker, Popover } from 'antd'
import classnames from 'classnames'
import produce from 'immer'
import { useAtom } from 'jotai'
import _, { get, identity } from 'lodash'
import moment from 'moment-timezone'
import queryString from 'query-string'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useIdleTimer } from 'react-idle-timer'
import useSWR from 'swr'
import { useDebounce } from 'use-debounce'
import {
  BooleanParam,
  DateParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import {
  IDLE_CHECK_INTERVAL,
  IDLE_TIME,
  PAGINATION_PAGE_SIZE_DEFAULT,
  PSPDFKIT_LICENSE_KEY,
} from '../../../../config'
import DocumentCard from './DocumentCard'
import cls from './documents.scss'
import DrawerEditDocument from './DrawerEditDocument'
import DrawerMergeDocument from './DrawerMergeDocument'
import DrawerRequestDocument from './DrawerRequestDocument'
import DrawerSendDocument from './DrawerSendDocument'
import DrawerUploadDocument from './DrawerUploadDocument'
import { LoadDocuments } from './LoadDocuments'
import { OpenRequests } from './OpenRequests'
import { OtherDocuments } from './OtherDocuments'
import { IconDownload14, IconUnarchive14 } from '@@/App/@components/icon'
import apiRoute from '../../../../constants/apiRoute'
import IconButtonAdd from '../../../svg/IconButtonAdd'

export const sortKeywords = {
  loadNumber: 'loadNumber',
  documentType: 'documentType',
  documentNumber: 'documentNumber',
  uploadTime: 'uploadTime',
  uploadedBy: 'uploadedBy',
  requestedAt: 'requestedAt',
  requestorName: 'requestorName',
  requesteeName: 'requesteeName',
}

const { RangePicker } = DatePicker

const isShowArchiveSingleDoc = (doc) =>
  doc.status !== 'Archive' && doc.status !== 'Reject'

const isShowUnArchiveSingleDoc = (doc) =>
  doc.status === 'Archive' && doc.status !== 'Reject'

const isShowRejectSingleDoc = (doc) =>
  doc.status !== 'Archive' &&
  doc.status !== 'Reject' &&
  !doc.idUser &&
  doc.idDriver

const isShowSendSingleDoc = (doc) => doc.status !== 'Reject'

const getListFilter = (_sortListStatusDocument, documentCounts) => {
  const target = _sortListStatusDocument.map((item) => {
    if (getStr(item, 'content').toUpperCase() === 'ALL') {
      const sum = _.reduce(
        documentCounts,
        (a, { total }) => Number(a) + Number(total),
        0
      )
      return { ...item, countTotal: sum }
    }
    const findItem = _.find(
      documentCounts,
      (o) =>
        getStr(o, 'load_status').toUpperCase() ===
        getStr(item, 'content').toUpperCase()
    )
    if (findItem) {
      return { ...item, countTotal: findItem.total }
    }
    return { ...item, countTotal: 0 }
  })
  return target
}

// @flow
const DEFAULT_SORT = 'uploadTime'

const fetcher = async (url) => {
  if (!url) return
  console.log('aaa url ', url)
  const data = await customAxios().get(url)
  if (data.status === 200) {
    return data.data.data
  }
  return { error: true }
}

const getDateForRangePickerFromQueryParams = (dateQueryParam) => {
  if (!dateQueryParam) return null
  return moment(
    moment(dateQueryParam, databaseDateFormat).format(displayDateFormat),
    displayDateFormat
  )
}

const DocumentPage = identity(({ context, history, ...rest }) => {
  const [tokenData] = useAtom(tokenDataAtom)

  const documentFlag = _.get(
    JSON.parse(localStorage.getItem('user')),
    'document'
  )
  if (documentFlag !== 'true') {
    history.push('/')
  }
  const [listRecentlyEdittedIdDoc, setListRecentlyEdittedIdDoc] =
    useRecentlyEdited()
  const { screenWidth } = useScreenWidth()

  const [listDocumentSelected, setListDocumentSelected] = useState([])
  const [urlfetch, setUrlfetch] = useState('')
  const [documentCounts, setDocumentCounts] = useState([])
  const [idForEdit, setIdForEdit] = useState(null)
  const location = useUserLocation()
  const [listDocument, setListDocument] = useState([])
  const listDocumentPrev = usePrevious(listDocument)
  const closeDropdownRef = useRef(0)

  const [cookies] = useCookies(['token'])
  const { token } = cookies

  const [currentId, setCurrentId] = useState(null)
  const currentIdPrev = usePrevious(currentId)

  const [triggerFetch, setTriggerFetch] = useState(0)

  const [listDocForSendDoc, setListDocForSendDoc] = useState([])
  const [listDocForMerge, setListDocForMerge] = useState([])

  const [showDeleteDoc, setShowDeleteDoc] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [idDeleteSingleDoc, setIdDeleteSingleDoc] = useState('')

  const [showRejectDoc, setShowRejectDoc] = useState(false)
  const [idRejectSingleDoc, setIdRejectSingleDoc] = useState('')
  const [rejecting, setRejecting] = useState(false)

  const [showArchiveDoc, setShowArchiveDoc] = useState(false)
  const [idArchiveSingleDoc, setIdArchiveSingleDoc] = useState('')

  const [showUnArchiveDoc, setShowUnArchiveDoc] = useState(false)
  const [idUnArchiveSingleDoc, setIdUnArchiveSingleDoc] = useState('')

  // const [showArchiveDoc, setShowArchiveDoc] = useState(false)

  const [listIdDocumentForEdit, setListIdDocumentForEdit] = useState([])

  const [showRequestDoc, setShowRequestDoc] = useState(false)
  const [showUploadDoc, setShowUploadDoc] = useState(false)

  const [includeArchiveDoc, SetIncludeArchiveDoc] = useQueryParam(
    'archive',
    withDefault(BooleanParam, false)
  )
  const [includeRejectDoc, setIncludeRejectDoc] = useQueryParam(
    'reject',
    withDefault(BooleanParam, false)
  )
  const [listIdDocumentForCancel, setListIdDocumentForCancel] = useState([])
  const [visible, setVisible] = useState(false)
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1))
  const [tabDocument, setTabDocument] = useQueryParam(
    'tab',
    withDefault(NumberParam, 1)
  )
  const tabDocumentPrev = usePrevious(tabDocument)

  const [rangeStartStr, setRangeStartStr] = useQueryParam(
    'from',
    withDefault(DateParam, undefined)
  )

  const rangeStartStrPrev = usePrevious(rangeStartStr)

  const [rangeEndStr, setRangeEndStr] = useQueryParam(
    'to',
    withDefault(DateParam, undefined)
  )
  const rangeEndStrPrev = usePrevious(rangeEndStr)

  const [pageSize, setPageSize] = useQueryParam(
    'size',
    withDefault(NumberParam, PAGINATION_PAGE_SIZE_DEFAULT)
  )

  const pageSizePrev = usePrevious(pageSize)

  const [textload, setTextload] = useQueryParam(
    'search',
    withDefault(StringParam, undefined)
  )
  const [textloadAdvance, setTextloadAdvance] = useQueryParam(
    'searchAdvanced',
    withDefault(StringParam, undefined)
  )

  const [textloadDebounce] = useDebounce(textload, 280)
  const textloadDebouncePrev = usePrevious(textloadDebounce)

  const [lastSortInfo, setLastSortInfo] = useQueryParam(
    'sort',
    withDefault(StringParam, DEFAULT_SORT)
  )
  const lastSortInfoPrev = usePrevious(lastSortInfo)

  const [time_request, set_time_request] = useState(
    moment().format('YYYY-MM-DDTHH:mm:ss')
  )

  const [documentDetail, setDocumentDetail] = useState({})
  // sort value
  const [sortList, setSortList] = useState({
    [sortKeywords.loadNumber]: '',
    [sortKeywords.documentType]: '',
    [sortKeywords.documentNumber]: '',
    [sortKeywords.uploadTime]: '',
    [sortKeywords.uploadedBy]: '',
    [sortKeywords.requestedAt]: '',
    [sortKeywords.requestorName]: '',
    [sortKeywords.requesteeName]: '',
  })

  const { listIdCheckedGroups } = useGroups()

  //
  const { data, error, mutate } = useSWR(urlfetch, fetcher, {
    revalidateOnFocus: false,
  })

  /* react idle timer */
  const handleOnIdle = () => {
    reset()
    set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
  }
  const { reset } = useIdleTimer({
    timeout: IDLE_TIME,
    onIdle: handleOnIdle,
    debounce: IDLE_CHECK_INTERVAL,
  })
  /* react idle timer end */
  const handleDeleteTextInputSearch = () => {
    setTextload(undefined)
  }

  const handleChangeInputSearch = (e) => {
    // e.persist()
    // setValueSearch(e.target.value)
    if (e.target.value === '') {
      setTextload(undefined)
    } else {
      setTextload(e.target.value)
    }
  }
  const handleChangeInputSearchAdvance = (e) => {
    if (e.target.value === '') {
      setTextloadAdvance(undefined)
    } else {
      setTextloadAdvance(e.target.value)
    }
  }
  const onchangeFilterDoc = (e) => {
    const { name } = e.target

    if (name === 'archive') {
      SetIncludeArchiveDoc(e.target.checked)
    }
    if (name === 'reject') {
      setIncludeRejectDoc(e.target.checked)
    }
  }

  const handleRejectDoc = async (arrayIdDocument, isRequest, nextCurrentId) => {
    const plural = _.size(arrayIdDocument) >= 2 ? 's' : ''

    const notify = function (data, defaultt = '') {
      if (_.get(data, 'reject.success') === undefined) {
        context.message.error(defaultt)
      }

      if (_.get(data, 'reject.success') === true) {
        context.message.success(`Document${plural} rejected successfully`)
      }

      if (_.get(data, 'request.success') === true) {
        setTimeout(() => {
          context.message.success(`Document request${plural} sent successfully`)
        }, 2000)
      } else if (
        _.get(data, 'request.success') === false &&
        _.get(data, 'request.errorCode') === 'DRIVERS_NO_LONGER_IN_GROUP'
      ) {
        setTimeout(() => {
          const Msg = () => {
            const listMessages = _.get(data, 'request.messages') || []
            return (
              <span style={{ textAlign: 'left' }}>
                {listMessages.map((item, index) => (
                  <React.Fragment key={index}>
                    {item}
                    {index !== listMessages.length - 1 && <br />}
                  </React.Fragment>
                ))}
              </span>
            )
          }
          context.message.error(<Msg />)
        }, 2000)
      }
    }
    try {
      setRejecting(true)
      const resp = await customAxios(token).put(
        `${
          apiRoute.documents.REJECT_DOCUMENTS
        }?groups=${listIdCheckedGroups.join(',')}`,
        {
          listIdDocument: arrayIdDocument,
          isRequest,
        }
      )

      if (resp.data.success === true) {
        context.message.success(`Document${plural} rejected successfully`)
      } else {
        notify(resp.data)
      }

      // ////////
    } catch (err) {
      notify(_.get(err, 'response.data'), getErrorMessage(err))
    } finally {
      setRejecting(false)
      setShowRejectDoc(false)
      setIdRejectSingleDoc(null)
      // ////////////////////
      if (nextCurrentId === 'end') {
        // go to next page
        // if (nextPage === 'end') {
        //   setCurrentId(null)
        //   set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
        // } else {
        //   setPage(nextPage)
        // }

        setCurrentId(null)
        set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
      } else {
        setCurrentId(nextCurrentId)
        set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
      }

      // ////////////////////
      // remove docs from listDocumentSelected
      const nextStateDoc = produce(listDocumentSelected, (draft) => {
        arrayIdDocument.forEach((idNeedToBeRemoved) => {
          const findIndex = draft
            .map((o) => o.idDocument)
            .findIndex((o) => o === idNeedToBeRemoved)
          if (findIndex !== -1) {
            draft.splice(findIndex, 1)
          }
          return draft
        })
      })
      setListDocumentSelected(nextStateDoc)
    }
  }

  const handleDeleteDoc = async (arrayIdDocument, nextCurrentId) => {
    try {
      setIsDeleting(true)
      const { data: dataResponse } = await customAxios(token).put(
        `${
          apiRoute.documents.DELETE_DOCUMENTS
        }?groups=${listIdCheckedGroups.join(',')}`,
        {
          listIdDocument: arrayIdDocument,
        }
      )
      if (dataResponse.success) {
        setShowDeleteDoc(false)
        setIdDeleteSingleDoc(null)
        // ////////////////////
        if (nextCurrentId === 'end') {
          // go to next page
          // if (nextPage === 'end') {
          //   setCurrentId(null)
          //   set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
          // } else {
          //   setPage(nextPage)
          // }

          setCurrentId(null)
          set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
        } else {
          setCurrentId(nextCurrentId)
          set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
        }

        // ////////////////////
        context.message.success('Deleted Successfully')

        const nextStateDoc = produce(listDocumentSelected, (draft) => {
          arrayIdDocument.forEach((idNeedToBeRemoved) => {
            const findIndex = draft
              .map((o) => o.idDocument)
              .findIndex((o) => o === idNeedToBeRemoved)
            if (findIndex !== -1) {
              draft.splice(findIndex, 1)
            }
            return draft
          })
        })
        setListDocumentSelected(nextStateDoc)
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    } finally {
      setIsDeleting(false)
    }
  }

  const handleArchiveDoc = async (arrayIdDocument, nextCurrentId) => {
    try {
      const { data: dataResponse } = await customAxios(token).put(
        `${
          apiRoute.documents.ARCHIVE_DOCUMENTS
        }?groups=${listIdCheckedGroups.join(',')}`,
        {
          listIdDocument: arrayIdDocument,
        }
      )
      if (dataResponse.success) {
        setShowArchiveDoc(false)
        setIdArchiveSingleDoc(null)
        if (nextCurrentId === 'end') {
          // go to next page
          // if (nextPage === 'end') {
          //   setCurrentId(null)
          //   set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
          // } else {
          //   setPage(nextPage)
          // }

          setCurrentId(null)
          set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
        } else {
          setCurrentId(nextCurrentId)
          set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
        }

        context.message.success(
          `Document${arrayIdDocument.length >= 2 ? 's' : ''} archived`
        )

        // remove docs from listDocumentSelected
        const nextStateDoc = produce(listDocumentSelected, (draft) => {
          arrayIdDocument.forEach((idNeedToBeRemoved) => {
            const findIndex = draft
              .map((o) => o.idDocument)
              .findIndex((o) => o === idNeedToBeRemoved)
            if (findIndex !== -1) {
              draft.splice(findIndex, 1)
            }
            return draft
          })
        })
        setListDocumentSelected(nextStateDoc)
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    }
  }

  const handleUnArchiveDoc = async (arrayIdDocument) => {
    try {
      const { data: dataResponse } = await customAxios(token).put(
        `${
          apiRoute.documents.UNARCHIVE_DOCUMENTS
        }?groups=${listIdCheckedGroups.join(',')}`,
        {
          listIdDocument: arrayIdDocument,
        }
      )
      if (dataResponse.success) {
        setShowUnArchiveDoc(false)
        setIdUnArchiveSingleDoc(null)
        setListRecentlyEdittedIdDoc(arrayIdDocument)
        mutate()
        context.message.success(
          `Document${arrayIdDocument.length >= 2 ? 's' : ''} unarchived`
        )
        getDocumentById(currentId)
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    }
  }

  const handleShowRejectDoc = (idDocument, urlDocument) => {
    setShowRejectDoc(true)
    if (idDocument && urlDocument) {
      setIdRejectSingleDoc(idDocument)
    }
  }

  const handleShowDeleteDoc = (idDocument, urlDocument) => {
    setShowDeleteDoc(true)
    if (idDocument && urlDocument) {
      setIdDeleteSingleDoc(idDocument)
    }
  }

  const handleShowArchiveDoc = (idDocument, urlDocument) => {
    setShowArchiveDoc(true)
    if (idDocument && urlDocument) {
      setIdArchiveSingleDoc(idDocument)
    }
  }

  const handleShowUnArchiveDoc = (idDocument, urlDocument) => {
    setShowUnArchiveDoc(true)
    if (idDocument && urlDocument) {
      setIdUnArchiveSingleDoc(idDocument)
    }
  }

  const handleDownload = (uri) => {
    if (checkExpired(uri)) {
      context.message.error(
        'Can not download this document. Please refresh the page and try again.'
      )
      return
    }
    const link = document.createElement('a')
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', '')
    link.href = uri
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  // setShowArchiveDoc(true)
  // if (idDocument) {
  //   setIdArchiveSingleDoc(idDocument)
  // }

  const handleCloseRejectDoc = () => {
    setShowRejectDoc(false)
    setIdRejectSingleDoc(null)
  }

  const handleCloseDeleteDoc = () => {
    setShowDeleteDoc(false)
    setIdDeleteSingleDoc(null)
  }

  const handleCloseArchiveDoc = () => {
    setShowArchiveDoc(false)
    setIdArchiveSingleDoc(null)
  }

  const handleCloseUnArchiveDoc = () => {
    setShowUnArchiveDoc(false)
    setIdUnArchiveSingleDoc(null)
  }

  const handleClickRow = (_data) => {
    if (!currentId) {
      setCurrentId(_data.idDocument)
    } else if (currentId !== _data.idDocument) {
      setCurrentId(_data.idDocument)
    }
  }

  useEffect(() => {
    if (currentId) {
      getDocumentById(currentId)
    }
  }, [currentId, listIdDocumentForEdit])

  const getDocumentById = async (currentId) => {
    try {
      const urlGet = `${
        apiRoute.documents.DOCUMENT_BY_ID
      }/${currentId}?groups=${listIdCheckedGroups.join(',')}`
      const { data: dataResponse } = await customAxios(token).get(urlGet)
      const currDocList = listDocument.find(
        (o) => String(o.idDocument) === String(currentId)
      )
      const currentDocumentDetail = {
        ...dataResponse.data,
        uploadedBy: currDocList.uploadedBy,
        uploadTime: currDocList.uploadTime,
      }
      setDocumentDetail(currentDocumentDetail)
    } catch (error) {
      console.log('error', error)
      setDocumentDetail({})
    }
  }

  const checkDocument = (doc) => {
    const { idDocument } = doc
    const findIndexIdDoc = listIdDocumentSelected.findIndex(
      (o) => o === idDocument
    )

    // update listIdDocumentSelected and listDocumentSelected
    if (findIndexIdDoc === -1) {
      // current not checked -> go check all docs that have the same load Number
      const nextStateDoc = produce(listDocumentSelected, (draft) => {
        draft.push(doc)
      })
      setListDocumentSelected(nextStateDoc)
    } else {
      // uncheck current doc
      const nextStateDoc = produce(listDocumentSelected, (draft) => {
        draft.splice(findIndexIdDoc, 1)
      })
      setListDocumentSelected(nextStateDoc)
    }
  }

  const checkOrUnCheckAll = () => {
    if (isAllItemOnScreenAreCheck) {
      // all are checked -> clear all

      const nextStateDoc = produce(listDocumentSelected, (draft) => {
        listDocument.forEach((docOnScreen) => {
          const findIndex = draft
            .map((o) => o.idDocument)
            .findIndex((o) => o === docOnScreen.idDocument)
          if (findIndex !== -1) {
            draft.splice(findIndex, 1)
          }
          return draft
        })
      })
      setListDocumentSelected(nextStateDoc)
    } else {
      // not all are check -> check remainning items

      const nextStateDoc = produce(listDocumentSelected, (draft) => {
        listDocument.forEach((docOnScreen) => {
          if (
            !draft.map((o) => o.idDocument).includes(docOnScreen.idDocument)
          ) {
            draft.push(docOnScreen)
          }
        })
      })

      setListDocumentSelected(nextStateDoc)
    }
  }

  const handleChangePageSize = (value) => {
    setPageSize(value)
  }

  const handleSorting = (sortValue) => {
    const lastSortCriteria = lastSortInfo.replace('-', '')
    let lastSortInfoClone = lastSortInfo
    if (sortValue !== lastSortCriteria) {
      lastSortInfoClone =
        sortList[sortValue] === 'ASC' ? `-${sortValue}` : sortValue
    } else if (lastSortInfoClone[0] === '-') {
      lastSortInfoClone = lastSortInfoClone.replace('-', '')
    } else {
      lastSortInfoClone = `-${lastSortInfoClone}`
    }
    setLastSortInfo(lastSortInfoClone)
    const target = sortList[sortValue] === 'ASC' ? 'DESC' : 'ASC'
    setSortList({ ...sortList, [sortValue]: target })
  }

  const handleResend = async (listIdDocument) => {
    try {
      await customAxios(token).put(
        `${
          apiRoute.documents.RESEND_REQUEST_DOCUMENT
        }?groups=${listIdCheckedGroups.join(',')}`,
        {
          listIdDocument,
        }
      )
      set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
      context.message.success(
        `Document request${
          listIdDocument.length >= 2 ? 's' : ''
        } sent successfully`
      )

      // remove requests from listDocumentSelected
      const nextStateDoc = produce(listDocumentSelected, (draft) => {
        listIdDocument.forEach((idNeedToBeRemoved) => {
          const findIndex = draft
            .map((o) => o.idDocument)
            .findIndex((o) => o === idNeedToBeRemoved)
          if (findIndex !== -1) {
            draft.splice(findIndex, 1)
          }
          return draft
        })
      })
      setListDocumentSelected(nextStateDoc)
    } catch (err) {
      context.message.error(getErrorMessage(err))
    }
  }

  const handleCancel = async (listIdDocument) => {
    try {
      await customAxios(token).put(
        `${
          apiRoute.documents.CANCEL_REQUEST_DOCUMENT
        }?groups=${listIdCheckedGroups.join(',')}`,
        {
          listIdDocument,
        }
      )
      set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
      context.message.success('Canceled Successfully')
      setListIdDocumentForCancel([])
      // remove requests from listDocumentSelected
      const nextStateDoc = produce(listDocumentSelected, (draft) => {
        listIdDocument.forEach((idNeedToBeRemoved) => {
          const findIndex = draft
            .map((o) => o.idDocument)
            .findIndex((o) => o === idNeedToBeRemoved)
          if (findIndex !== -1) {
            draft.splice(findIndex, 1)
          }
          return draft
        })
      })
      setListDocumentSelected(nextStateDoc)
    } catch (err) {
      context.message.error(getErrorMessage(err))
    } finally {
      setListIdDocumentForCancel([])
    }
  }

  const handlePrint = async (url) => {
    const PSPDFKit = await import('pspdfkit')
    PSPDFKit.load({
      headless: true,
      document: url,
      baseUrl: `${window.location.protocol}//${window.location.host}/`,
      licenseKey: PSPDFKIT_LICENSE_KEY,
    })
      .then((instance) => {
        instance.print(PSPDFKit.PrintMode.DOM)
      })
      .catch((_err) => {
        context.message.error('Failed to print')
      })
  }

  const renderDocumentActions = (data) => {
    if (tabDocument === 3) {
      return (
        <UikDropdown
          DisplayComponent={DotsComponent}
          className={cls.dropdown_edit_load}
          position="bottomRight"
        >
          <UikDropdownItem
            onClick={() => {
              handleResend([data.idDocument])
            }}
          >
            <span className={cls.wr_edit_load_icon}>
              <IconResend14New />
            </span>
            <span>Resend</span>
          </UikDropdownItem>

          <UikDropdownItem
            onClick={() => {
              setListIdDocumentForCancel([data.idDocument])
            }}
            style={{ color: 'red' }}
          >
            <span className={cls.wr_delete_load_icon}>
              <DeleteLoadIcon />
            </span>
            <span className={cls.wr_delete_load_label}>Cancel</span>
          </UikDropdownItem>
        </UikDropdown>
      )
    }

    return (
      <UikDropdown
        DisplayComponent={DotsComponent}
        className={cls.dropdown_edit_load}
        position="bottomRight"
      >
        <UikDropdownItem
          onClick={() => {
            handlePrint(data.url)
          }}
        >
          <span className={cls.wr_edit_load_icon}>
            <img alt="" height="14" src={IconPrint} width="14" />
          </span>
          <span>Print</span>
        </UikDropdownItem>
        <a
          download
          href={data.url}
          onClick={(e) => {
            if (checkExpired(data.url)) {
              e.preventDefault()
              context.message.error(
                'Can not download this document. Please refresh the page and try again.'
              )
            }
          }}
          style={{ color: '#505652' }}
        >
          <UikDropdownItem onClick={null}>
            <span className={cls.wr_edit_load_icon}>
              <IconDownload14 />
            </span>
            <span>Download</span>
          </UikDropdownItem>
        </a>

        {isShowUnArchiveSingleDoc(data) && (
          <UikDropdownItem
            onClick={() => handleShowUnArchiveDoc(data.idDocument, data.url)}
          >
            <span className={cls.wr_edit_load_icon}>
              <IconUnarchive14 />
            </span>
            <span>Unarchive</span>
          </UikDropdownItem>
        )}

        <div>
          {isShowSendSingleDoc(data) && (
            <UikDropdownItem onClick={() => setListDocForSendDoc([data])}>
              <span className={cls.wr_edit_load_icon}>
                <IconSend14 />
              </span>
              <span>Send</span>
            </UikDropdownItem>
          )}
        </div>

        {isShowArchiveSingleDoc(data) && (
          <UikDropdownItem
            onClick={() => handleShowArchiveDoc(data.idDocument, data.url)}
          >
            <span className={cls.wr_edit_load_icon}>
              <IconArchive14 />
            </span>
            <span>Archive</span>
          </UikDropdownItem>
        )}

        <UikDropdownItem
          onClick={() => setListIdDocumentForEdit([data.idDocument])}
        >
          <span className={cls.wr_edit_load_icon}>
            <EditLoadIcon height={14} width={14} />
          </span>
          <span>Edit</span>
        </UikDropdownItem>
        {isShowRejectSingleDoc(data) && (
          <UikDropdownItem
            onClick={() => handleShowRejectDoc(data.idDocument, data.url)}
          >
            <span className={cls.wr_edit_load_icon}>
              <IconReject14 />
            </span>
            <span>Reject</span>
          </UikDropdownItem>
        )}
        {tokenData.role !== 'Staff' && (
          <UikDropdownItem
            onClick={() => handleShowDeleteDoc(data.idDocument, data.url)}
            style={{ color: 'red' }}
          >
            <span className={cls.wr_delete_load_icon}>
              <DeleteLoadIcon />
            </span>
            <span className={cls.wr_delete_load_label}>Delete</span>
          </UikDropdownItem>
        )}
      </UikDropdown>
    )
  }

  useEffect(() => {
    console.log('aaa test ')
    setTimeout(() => {
      const key = lastSortInfo.replace('-', '')
      const value = lastSortInfo[0] === '-' ? 'DESC' : 'ASC'
      const nextState = produce(sortList, (draftState) => {
        _.set(draftState, `${key}`, value)
        return draftState
      })
      setSortList(nextState)
    }, 100)
  }, [])

  useEffect(() => {
    function handleData(data_) {
      setListDocument(get(data_, 'documents') || [])
      function getTotal(arr, findLoadDocument) {
        const target = arr.find((o) => o.loadDocument === findLoadDocument)
        if (target) {
          return target.total
        }
        return 0
      }

      const dataCount = []
      const nonLoadDocCount = data_.documentType.filter(
        (o) => o.loadDocument === false
      )
      dataCount.push(
        _.isEmpty(nonLoadDocCount)
          ? { loadDocument: false, total: 0 }
          : nonLoadDocCount[0]
      )
      let total = 0
      data_.documentType
        .filter((o) => o.loadDocument === true)
        .forEach((o) => {
          total += Number(o.total)
        })
      dataCount.push({
        loadDocument: true,
        total,
      })

      const requestDocObj = data_.documentType.filter(
        (o) => o.requestDocument === true
      )
      let countRequestDoc = 0
      if (!_.isEmpty(requestDocObj)) {
        countRequestDoc = requestDocObj[0].total
      }

      setDocumentCounts([
        {
          load_status: 'Load Documents',
          total: getTotal(dataCount, true),
        },
        {
          load_status: 'Other Documents',
          total: getTotal(dataCount, false),
        },
        {
          load_status: 'Open Requests',
          total: countRequestDoc,
        },
      ])

      // TODO, when next page select first item
      if (currentId !== null) {
        const isCurrentIdAvailable = !!data.documents.find(
          (o) => o.idDocument === currentId
        )
        if (!isCurrentIdAvailable) {
          setCurrentId(_.get(data_, 'documents[0].idDocument', null))
        }
      }
    }
    if (error === undefined && !!data) {
      handleData(data)
    }
  }, [data, error])

  useEffect(() => {
    const params = {}

    params.sort = lastSortInfo
    params.limit = pageSize
    params.groups = listIdCheckedGroups.join(',')

    if (tabDocument === 1) {
      params.loadDocument = true
    } else if (tabDocument === 2) {
      params.loadDocument = false
    } else if (tabDocument === 3) {
      params.requestDocument = true
    }

    if (textloadDebounce) {
      params.search = textloadDebounce
    }
    if (includeArchiveDoc) {
      params.archived = includeArchiveDoc
    }
    if (includeRejectDoc) {
      params.rejected = includeRejectDoc
    }
    const time = `${formatTimeSearchLoad(rangeStartStr)}-${formatTimeSearchLoad(
      rangeEndStr
    )}`

    const timePrev = `${formatTimeSearchLoad(
      rangeStartStrPrev
    )}-${formatTimeSearchLoad(rangeEndStrPrev)}`

    if (rangeStartStr && rangeEndStr) {
      params.uploadTime = time
    }

    params.page = page
    if (
      textloadDebounce !== textloadDebouncePrev ||
      (time !== timePrev && timePrev !== '-') ||
      (tabDocument !== tabDocumentPrev && tabDocumentPrev !== undefined) ||
      (pageSize !== pageSizePrev && pageSizePrev !== undefined)
    ) {
      params.page = 1
      setPage(undefined)
      setCurrentId(null)
    }

    if (lastSortInfo !== lastSortInfoPrev && lastSortInfoPrev !== undefined) {
      params.page = 1
      setPage(undefined)
    }

    params.time_request = time_request
    const url = `${apiRoute.documents.SEARCH}?${queryString.stringify(params)}`
    console.log('aaa url ', url)
    setUrlfetch(url)
  }, [
    page,
    tabDocument,
    pageSize,
    textloadDebounce,
    lastSortInfo,
    listIdCheckedGroups,
    time_request,
  ])

  const handleSearch = ({
    isSetTextload,
    textloadAdvance,
    includeArchiveDoc,
    includeRejectDoc,
    rangeStartStr,
    rangeEndStr,
  }) => {
    const params = {}

    params.sort = lastSortInfo
    params.limit = pageSize
    params.groups = listIdCheckedGroups.join(',')

    if (tabDocument === 1) {
      params.loadDocument = true
    } else if (tabDocument === 2) {
      params.loadDocument = false
    } else if (tabDocument === 3) {
      params.requestDocument = true
    }

    if (textloadAdvance) {
      params.search = textloadAdvance
    }
    if (includeArchiveDoc) {
      params.archived = includeArchiveDoc
    }
    if (includeRejectDoc) {
      params.rejected = includeRejectDoc
    }

    const time = `${formatTimeSearchLoad(rangeStartStr)}-${formatTimeSearchLoad(
      rangeEndStr
    )}`

    const timePrev = `${formatTimeSearchLoad(
      rangeStartStrPrev
    )}-${formatTimeSearchLoad(rangeEndStrPrev)}`

    if (rangeStartStr && rangeEndStr) {
      params.uploadTime = time
    }

    params.page = page
    if (
      textloadDebounce !== textloadDebouncePrev ||
      (time !== timePrev && timePrev !== '-') ||
      (tabDocument !== tabDocumentPrev && tabDocumentPrev !== undefined) ||
      (pageSize !== pageSizePrev && pageSizePrev !== undefined)
    ) {
      params.page = 1
      setPage(undefined)
      setCurrentId(null)
    }

    if (lastSortInfo !== lastSortInfoPrev && lastSortInfoPrev !== undefined) {
      params.page = 1
      setPage(undefined)
    }

    params.time_request = time_request
    const url = `${apiRoute.documents.SEARCH}?${queryString.stringify(params)}`
    console.log('aaa url2 ', url)
    setUrlfetch(url)
    if (isSetTextload) {
      setTextload(textloadAdvance)
    }
    setVisible(false)
  }

  useEffect(() => {
    if (currentId) {
      setListDocumentSelected([])
    }

    if (checkNumber(currentIdPrev) === true && currentId === null) {
      // when clode detail view, recall list api
      // set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
    }
  }, [currentId])

  const nextIdDocument = useMemo(() => {
    if (!currentId || _.size(listDocument) <= 1) {
      return null
    }
    const indexCurrentId = listDocument.findIndex(
      (o) => o.idDocument === currentId
    )

    if (indexCurrentId === _.size(listDocument) - 1) {
      return 'end'
    }
    return listDocument[indexCurrentId + 1].idDocument
  }, [currentId, listDocument])

  const total = useMemo(() => {
    const findTotalLoadsOfCurrentStatusFilter = _.find(documentCounts, (o) => {
      let target = ''
      if (tabDocument === 2) {
        target = 'Other Documents'
      } else if (tabDocument === 3) {
        target = 'Open Requests'
      } else {
        target = 'Load Documents'
      }

      return o.load_status === target
    })

    if (findTotalLoadsOfCurrentStatusFilter) {
      return findTotalLoadsOfCurrentStatusFilter.total
    }
    return 0
  }, [tabDocument, documentCounts])

  const totalPage = useMemo(() => {
    if (total % pageSize === 0) {
      return total / pageSize
    }
    return Math.ceil(total / pageSize)
  }, [total, pageSize])

  const nextPage = useMemo(() => {
    if (page === totalPage) {
      return 'end'
    }
    return page + 1
  }, [page, totalPage])

  useEffect(() => {
    setCurrentId(null)
    setListDocumentSelected([])
  }, [tabDocument])

  useEffect(() => {
    if (rest.location.search === '') {
      setCurrentId(null)
    }
  }, [rest.location.key])

  const listUrlDocumentSelected = useMemo(
    () => listDocumentSelected.map((o) => o.url),
    [listDocumentSelected]
  )

  const listIdDocumentSelected = useMemo(
    () => listDocumentSelected.map((o) => o.idDocument),
    [listDocumentSelected]
  )

  const showArchiveMultiple = useMemo(
    () => listDocumentSelected.every(isShowArchiveSingleDoc),
    [listDocumentSelected]
  )

  const showUnArchiveMultiple = useMemo(
    () => listDocumentSelected.every(isShowUnArchiveSingleDoc),
    [listDocumentSelected]
  )

  const showSendMultiple = useMemo(
    () => listDocumentSelected.every((o) => o.status !== 'Reject'),
    [listDocumentSelected]
  )

  const showRejectMultiple = useMemo(
    () =>
      listDocumentSelected.every(
        (o) =>
          o.status !== 'Reject' &&
          o.status !== 'Archive' &&
          !o.idUser &&
          !!o.idDriver
      ),
    [listDocumentSelected]
  )

  const showMergeMultiple = useMemo(() => {
    if (_.size(listDocumentSelected) <= 1) {
      return false
    }
    return listDocumentSelected.every(
      (o) => o.status === 'Success' || o.status === 'Archive'
    )
  }, [listDocumentSelected])

  let isAllItemOnScreenAreCheck = useMemo(() => {
    function checkAllItemsAreChecked(listIdSelected, listIdOnScreen) {
      if (_.isEmpty(listIdOnScreen) || _.isEmpty(listIdSelected)) {
        return false
      }
      const failed = listIdOnScreen.some(
        (idOnScreen) => !listIdSelected.includes(idOnScreen)
      )
      return !failed
    }
    const allIdDocsOnScreen = listDocument.map((o) => o.idDocument)
    return checkAllItemsAreChecked(listIdDocumentSelected, allIdDocsOnScreen)
  }, [listDocument, listIdDocumentSelected])

  const loadingDocs = useMemo(() => !data && !error, [data, error])

  const renderTitle = () => (
    <UikTopBarTitle className="page_title loads_page_title">
      Documents
    </UikTopBarTitle>
  )

  const renderLinkState = () => (
    <div className="loads_wr_filters">
      <div className="bg-LightestGray flex h-12 items-center rounded pl-6">
        {getListFilter(sortListStatusDocument, documentCounts).map((item) => (
          <div
            className={classnames('loads_filter_item', {})}
            key={item.id}
            onClick={() => {
              if (item.content === 'Load Documents') {
                if (tabDocument !== 1) {
                  setListDocument([])
                  setTabDocument(undefined)
                  if (tabDocument === 3) {
                    setLastSortInfo(DEFAULT_SORT)
                  }
                } else {
                  set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
                }
              } else if (item.content === 'Other Documents') {
                if (tabDocument !== 2) {
                  setListDocument([])
                  setTabDocument(2)
                  if (tabDocument === 3) {
                    setLastSortInfo(DEFAULT_SORT)
                  }
                } else {
                  set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
                }
              } else if (item.content === 'Open Requests') {
                if (tabDocument !== 3) {
                  setLastSortInfo('requestedAt')
                  setListDocument([])
                  setTabDocument(3)
                } else {
                  set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
                }
              }
            }}
          >
            <span
              className={classnames('loads_filter_name fs18 lh27', {
                loads_filter_name_active:
                  ((tabDocument === 1 || tabDocument === undefined) &&
                    item.content === 'Load Documents') ||
                  (tabDocument === 2 && item.content === 'Other Documents') ||
                  (tabDocument === 3 && item.content === 'Open Requests'),
              })}
            >
              {item.content}
            </span>
            <span className="loads_filter_count fs14 lh21">
              {item.countTotal}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
  const disableVerification = useMemo(() => {
    if (rangeStartStr || rangeEndStr || textloadAdvance) {
      return false
    }
    return true
  }, [rangeStartStr, rangeEndStr, textloadAdvance])
  useEffect(() => {
    if (visible) {
      setTextloadAdvance(textload)
    }
  }, [visible])

  const contentSearch = () => (
    <div className="w-[320px]">
      <div>
        <label
          className="text-xs mb-[10px] block text-[#888E96]"
          htmlFor="search"
        >
          Keywords
        </label>
        <Input2WithReset
          iconright={
            textloadAdvance && (
              <DeleteSearch2 onClick={() => setTextloadAdvance(undefined)} />
            )
          }
          id="search"
          onChange={handleChangeInputSearchAdvance}
          value={textloadAdvance === undefined ? '' : textloadAdvance}
        />
      </div>
      <div className="my-4">
        <label
          className="text-xs mb-[10px] block text-[#888E96]"
          htmlFor="date-range"
        >
          Date Range
        </label>
        <RangePicker
          format={displayDateFormat}
          onChange={(dates, dateStrings) => {
            if (dateStrings[0] === '' && dateStrings[1] === '') {
              // click clear date
              setRangeStartStr(undefined)
              setRangeEndStr(undefined)
            } else {
              setRangeStartStr(new Date(dateStrings[0]))
              setRangeEndStr(new Date(dateStrings[1]))
            }
          }}
          placeholder={['', '']}
          ranges={{
            Today: [moment(), moment()],
          }}
          suffixIcon={<DateIconNew />}
          value={[
            getDateForRangePickerFromQueryParams(rangeStartStr),
            getDateForRangePickerFromQueryParams(rangeEndStr),
          ]}
        />
      </div>

      <div className="mb-4">
        <div className="flex items-center text-Black">
          <input
            checked={includeArchiveDoc}
            className="mr-[10px]"
            name="archive"
            onChange={onchangeFilterDoc}
            type="checkbox"
          />
          Include Archived Documents
        </div>
      </div>
      <div className="mb-6">
        <div className="flex items-center text-Black">
          <input
            checked={includeRejectDoc}
            className="mr-[10px]"
            name="reject"
            onChange={onchangeFilterDoc}
            type="checkbox"
          />
          Include Rejected Documents
        </div>
      </div>
      <div className="wr_footer_add_load ">
        <Button
          onClick={() => setVisible(false)}
          stateClass="Secondary"
          width={74}
        >
          Cancel
        </Button>

        <div className="flex gap-x-2">
          <Button
            onClick={() => {
              setTextloadAdvance(undefined)
              setTextload(undefined)
              SetIncludeArchiveDoc(undefined)
              setIncludeRejectDoc(undefined)
              setRangeStartStr(undefined)
              setRangeEndStr(undefined)
              handleSearch({
                isSetTextload: false,
                textloadAdvance: undefined,
                textload: undefined,
                includeArchiveDoc: undefined,
                includeRejectDoc: undefined,
                rangeStartStr: undefined,
                rangeEndStr: undefined,
              })
            }}
            stateClass="Link"
            tabIndex="0"
          >
            Reset
          </Button>

          <Button
            id="addVendor"
            onClick={() => {
              handleSearch({
                isSetTextload: true,
                textloadAdvance,
                textload,
                includeArchiveDoc,
                includeRejectDoc,
                rangeStartStr,
                rangeEndStr,
              })
            }}
            // onKeyDown={ handleKeyDownSave }
            stateClass={disableVerification ? 'Disabled' : 'Primary'}
            width={74}
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  )

  const renderDateSearchButton = () => (
    <div
      // ref={ ref2 }
      className={cls.loads_interactive}
    >
      <div className={cls.search_wrapper}>
        <Input2Advanced
          className="w-[320px]"
          disabled={visible}
          iconDelete={
            textload && <DeleteSearch2 onClick={handleDeleteTextInputSearch} />
          }
          iconright={
            <Popover
              content={contentSearch}
              onVisibleChange={() => setVisible(!visible)}
              placement="bottomRight"
              title="Advanced Search"
              trigger="click"
              visible={visible}
            >
              <span>
                <FilterIcon onClick={() => setVisible(!visible)} />
              </span>
            </Popover>
          }
          id="search"
          onChange={handleChangeInputSearch}
          placeholder="Search"
          value={textload === undefined ? '' : textload}
        />
      </div>
      {/* </Popover> */}

      <ButtonPopoverDocument
        setShowRequestDoc={setShowRequestDoc}
        setShowUploadDoc={setShowUploadDoc}
      />
    </div>
  )
  const renderListDocuments = () => {
    /* begin render list docs */
    // here
    if (tabDocument === 1) {
      // Table Load Document
      return (
        <LoadDocuments
          checkDocument={checkDocument}
          checkOrUnCheckAll={checkOrUnCheckAll}
          currentId={currentId}
          handleClickRow={handleClickRow}
          handleSorting={handleSorting}
          isAllItemOnScreenAreCheck={isAllItemOnScreenAreCheck}
          lastSortInfo={lastSortInfo}
          listDocument={listDocument}
          listIdDocumentSelected={listIdDocumentSelected}
          listRecentlyEdittedIdDoc={listRecentlyEdittedIdDoc}
          loading={
            loadingDocs && currentId === null && _.isEmpty(listDocumentPrev)
          }
          renderDocumentActions={renderDocumentActions}
          setIdForEdit={setIdForEdit}
          sortKeywords={sortKeywords}
          sortList={sortList}
          tokenData={tokenData}
        />
      )
    }
    if (tabDocument === 2) {
      // Table Non Load Document
      return (
        <OtherDocuments
          checkDocument={checkDocument}
          checkOrUnCheckAll={checkOrUnCheckAll}
          currentId={currentId}
          handleClickRow={handleClickRow}
          handleSorting={handleSorting}
          isAllItemOnScreenAreCheck={isAllItemOnScreenAreCheck}
          lastSortInfo={lastSortInfo}
          listDocument={listDocument}
          listIdDocumentSelected={listIdDocumentSelected}
          listRecentlyEdittedIdDoc={listRecentlyEdittedIdDoc}
          loading={
            loadingDocs && currentId === null && _.isEmpty(listDocumentPrev)
          }
          renderDocumentActions={renderDocumentActions}
          setIdForEdit={setIdForEdit}
          sortKeywords={sortKeywords}
          sortList={sortList}
          tokenData={tokenData}
        />
      )
    }
    // Table Open Requests
    // begin render open requests
    return (
      <OpenRequests
        checkDocument={checkDocument}
        checkOrUnCheckAll={checkOrUnCheckAll}
        currentId={currentId}
        handleClickRow={handleClickRow}
        handleSorting={handleSorting}
        isAllItemOnScreenAreCheck={isAllItemOnScreenAreCheck}
        lastSortInfo={lastSortInfo}
        listDocument={listDocument}
        listIdDocumentSelected={listIdDocumentSelected}
        loading={
          loadingDocs && currentId === null && _.isEmpty(listDocumentPrev)
        }
        renderDocumentActions={renderDocumentActions}
        setIdForEdit={setIdForEdit}
        sortKeywords={sortKeywords}
        sortList={sortList}
        tokenData={tokenData}
      />
    )
    // begin render open requests

    /* end render list docs */
  }

  const renderActionsForMultiple = () => {
    // /////////////////////////
    if (tabDocument === 3) {
      return (
        <React.Fragment>
          <IconWithTooltipTop tooltipcontent="Resend">
            <IconResend32
              onClick={handleResend.bind(null, listIdDocumentSelected)}
            />
          </IconWithTooltipTop>
          <IconWithTooltipTop tooltipcontent="Cancel">
            <IconDelete32
              onClick={() => setListIdDocumentForCancel(listIdDocumentSelected)}
            />
          </IconWithTooltipTop>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        {_.size(listDocumentSelected) === 1 && (
          <IconWithTooltipTop tooltipcontent="Print">
            <div className={cls.wr_print_32}>
              <img
                alt=""
                height="22"
                onClick={() => {
                  handlePrint(getStr(listDocumentSelected, '[0].url'))
                }}
                src={IconPrint}
                width="22"
              />
            </div>
          </IconWithTooltipTop>
        )}

        <IconWithTooltipTop tooltipcontent="Download">
          <IconDownload32
            onClick={() => {
              const atLeastOneUrlExpired = listUrlDocumentSelected.some((url) =>
                checkExpired(url)
              )
              if (atLeastOneUrlExpired) {
                context.message.error(
                  'One or many documents has expired. Please refresh the page and try again.'
                )
              } else {
                download_files(listUrlDocumentSelected)
              }
            }}
          />
        </IconWithTooltipTop>

        {showSendMultiple && (
          <IconWithTooltipTop tooltipcontent="Send">
            <IconSend32
              onClick={() => setListDocForSendDoc(listDocumentSelected)}
            />
          </IconWithTooltipTop>
        )}

        {showArchiveMultiple && (
          <IconWithTooltipTop tooltipcontent="Archive">
            <IconArchive32 onClick={() => handleShowArchiveDoc()} />
          </IconWithTooltipTop>
        )}

        {showUnArchiveMultiple && (
          <IconWithTooltipTop tooltipcontent="Unarchive">
            <IconUnarchive32 onClick={() => handleShowUnArchiveDoc()} />
          </IconWithTooltipTop>
        )}

        <IconWithTooltipTop tooltipcontent="Edit">
          <IconEdit32
            onClick={() => setListIdDocumentForEdit(listIdDocumentSelected)}
          />
        </IconWithTooltipTop>
        {showRejectMultiple && (
          <IconWithTooltipTop tooltipcontent="Reject">
            <IconReject32 onClick={() => handleShowRejectDoc()} />
          </IconWithTooltipTop>
        )}

        {showMergeMultiple && (
          <IconWithTooltipTop tooltipcontent="Merge">
            <IconMerge32
              onClick={() => {
                // current
                setListDocForMerge(listDocumentSelected)
              }}
            />
          </IconWithTooltipTop>
        )}

        {tokenData.role !== 'Staff' && (
          <IconWithTooltipTop tooltipcontent="Delete">
            <IconDelete32 onClick={() => handleShowDeleteDoc()} />
          </IconWithTooltipTop>
        )}
      </React.Fragment>
    )
    // /////////////////////////
  }

  return (
    <React.Fragment>
      <DrawerUploadDocument
        context={context}
        location={location}
        setCurrentId={setCurrentId}
        setShowUploadDoc={setShowUploadDoc}
        set_time_request={set_time_request}
        showUploadDoc={showUploadDoc}
        token={token}
      />

      {_.size(listIdDocumentForEdit) >= 1 && (
        <DrawerEditDocument
          context={context}
          listDocumentSelected={listDocumentSelected}
          listIdDocumentForEdit={listIdDocumentForEdit}
          listIdDocumentSelected={listIdDocumentSelected}
          listRecentlyEdittedIdDoc={listRecentlyEdittedIdDoc}
          location={location}
          setCurrentId={setCurrentId}
          setListDocumentSelected={setListDocumentSelected}
          setListIdDocumentForEdit={setListIdDocumentForEdit}
          setListRecentlyEdittedIdDoc={setListRecentlyEdittedIdDoc}
          set_time_request={set_time_request}
          tabDocument={tabDocument}
          token={token}
        />
      )}

      {_.size(listDocForMerge) >= 2 && (
        <DrawerMergeDocument
          context={context}
          listDocForMerge={listDocForMerge}
          listDocumentSelected={listDocumentSelected}
          listIdDocumentForEdit={listIdDocumentForEdit}
          listIdDocumentSelected={listIdDocumentSelected}
          listRecentlyEdittedIdDoc={listRecentlyEdittedIdDoc}
          location={location}
          setCurrentId={setCurrentId}
          setListDocForMerge={setListDocForMerge}
          setListDocumentSelected={setListDocumentSelected}
          setListIdDocumentForEdit={setListIdDocumentForEdit}
          setListRecentlyEdittedIdDoc={setListRecentlyEdittedIdDoc}
          set_time_request={set_time_request}
          tabDocument={tabDocument}
          token={token}
        />
      )}

      <DrawerRequestDocument
        context={context}
        listDocumentSelected={listDocumentSelected}
        location={location}
        setListDocumentSelected={setListDocumentSelected}
        setShowRequestDoc={setShowRequestDoc}
        set_time_request={set_time_request}
        showRequestDoc={showRequestDoc}
        tabDocument={tabDocument}
        token={token}
      />

      <DrawerSendDocument
        context={context}
        listDocForSendDoc={listDocForSendDoc}
        setListDocForSendDoc={setListDocForSendDoc}
        token={token}
      />

      {screenWidth > 1600 ? (
        <div
          className={classnames(
            cls.headContainer,
            'wr_page_title wr_page_title_loads items-center'
          )}
        >
          {renderTitle()}
          {renderLinkState()}
          {renderDateSearchButton()}
        </div>
      ) : (
        <React.Fragment>
          <div
            className={classnames(
              cls.headContainer,
              'wr_page_title wr_page_title_loads'
            )}
          >
            {renderTitle()}
            {renderDateSearchButton()}
          </div>
          <div className="mb24">{renderLinkState()}</div>
        </React.Fragment>
      )}
      {/* main */}
      <div
        className={classnames('wr_main_load', [cls.wr_main_load], {
          [cls.detail]: !!currentId,
          detail: !!currentId,
        })}
      >
        <div className={cls.wr_table_pagi}>
          {renderListDocuments()}
          <div className="h-[24px] " />

          <MyPagination
            className={classnames(cls.pagination, {
              [cls.detail]: !!currentId,
            })}
            handleChangePageSize={handleChangePageSize}
            loading={loadingDocs}
            page={page || 1}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            setTriggerFetch={setTriggerFetch}
            simple={!!currentId}
            total={total}
            triggerFetch={triggerFetch}
          />
        </div>

        {!!currentId && documentDetail && (
          <div
            className={classnames('wr_loadcard', cls.wr_loadcard, {
              wr_loadcard_show: !!currentId,
              [cls.wr_loadcard_show]: !!currentId,
            })}
          >
            {/* TODO */}
            <DocumentCard
              listIdDocumentForEdit={listIdDocumentForEdit}
              actionFns={{
                downloadFn: handleDownload.bind(null, documentDetail.url),

                archiveFn: isShowArchiveSingleDoc(documentDetail)
                  ? handleShowArchiveDoc.bind(
                      null,
                      documentDetail.idDocument,
                      documentDetail.url
                    )
                  : null,

                unarchiveFn: isShowUnArchiveSingleDoc(documentDetail)
                  ? handleShowUnArchiveDoc.bind(
                      null,
                      documentDetail.idDocument,
                      documentDetail.url
                    )
                  : null,

                rejectFn: isShowRejectSingleDoc(documentDetail)
                  ? handleShowRejectDoc.bind(
                      null,
                      documentDetail.idDocument,
                      documentDetail.url
                    )
                  : null,

                sendFn: isShowSendSingleDoc(documentDetail)
                  ? setListDocForSendDoc.bind(null, [documentDetail])
                  : null,

                editFn: setListIdDocumentForEdit.bind(null, [
                  documentDetail.idDocument,
                ]),

                deleteFn: handleShowDeleteDoc.bind(
                  null,
                  documentDetail.idDocument,
                  documentDetail.url
                ),
              }}
              context={context}
              handleCloseDocumentCard={() => {
                setCurrentId(null)
              }}
              idForEdit={idForEdit}
              location={location}
              o={documentDetail}
              // o={documentDetail}
              setIdForEdit={setIdForEdit}
            />
          </div>
        )}
      </div>
      {/* main end */}

      {showRejectDoc && (
        <Modal selector="#modalInsidePdfViewer">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Reject Document
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>

              <Caption block className="mb23">
                {!!idRejectSingleDoc || _.size(listIdDocumentSelected) === 1
                  ? 'Do you want to request a replacement for this document?'
                  : `Do you want to request replacements for these ${listIdDocumentSelected.length} documents?`}
              </Caption>

              <div className={cls.button_wrapper_reject}>
                <Button
                  onClick={handleCloseRejectDoc}
                  stateClass="Secondary"
                  width={74}
                >
                  Cancel
                </Button>

                <Button
                  onClick={
                    idRejectSingleDoc
                      ? handleRejectDoc.bind(
                          null,
                          [idRejectSingleDoc],
                          false,
                          nextIdDocument
                        )
                      : handleRejectDoc.bind(
                          null,
                          listIdDocumentSelected,
                          false,
                          nextIdDocument
                        )
                  }
                  stateClass={rejecting ? 'Disabled' : 'Outline'}
                  width={51}
                >
                  No
                </Button>

                <Button
                  onClick={
                    idRejectSingleDoc
                      ? handleRejectDoc.bind(
                          null,
                          [idRejectSingleDoc],
                          true,
                          nextIdDocument
                        )
                      : handleRejectDoc.bind(
                          null,
                          listIdDocumentSelected,
                          true,
                          nextIdDocument
                        )
                  }
                  stateClass={rejecting ? 'Disabled' : 'Primary'}
                  width={54}
                >
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showDeleteDoc && (
        <Modal selector="#modalInsidePdfViewer">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Delete Document
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>
              <div className={cls.main_wrapper} ref={closeDropdownRef}>
                {!!idDeleteSingleDoc || _.size(listIdDocumentSelected) === 1
                  ? 'Are you sure you want to delete this document?'
                  : `Are you sure you want to delete these ${listIdDocumentSelected.length} documents?`}
              </div>
              <div className={cls.button_wrapper}>
                <Button
                  onClick={handleCloseDeleteDoc}
                  stateClass="Secondary"
                  width={74}
                >
                  Cancel
                </Button>

                <Button
                  onClick={
                    idDeleteSingleDoc
                      ? handleDeleteDoc.bind(
                          null,
                          [idDeleteSingleDoc],
                          nextIdDocument
                        )
                      : handleDeleteDoc.bind(
                          null,
                          listIdDocumentSelected,
                          nextIdDocument
                        )
                  }
                  stateClass={isDeleting ? 'Disabled' : 'Primary'}
                  width={74}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showArchiveDoc && (
        <Modal selector="#modalInsidePdfViewer">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Archive Document
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>
              <div className={cls.main_wrapper} ref={closeDropdownRef}>
                {!!idArchiveSingleDoc || _.size(listIdDocumentSelected) === 1
                  ? 'Are you sure you want to archive this document?'
                  : `Are you sure you want to archive these ${listIdDocumentSelected.length} documents?`}
              </div>
              <div className={cls.button_wrapper}>
                <Button
                  onClick={handleCloseArchiveDoc}
                  stateClass="Secondary"
                  width={74}
                >
                  Cancel
                </Button>

                <Button
                  onClick={
                    idArchiveSingleDoc
                      ? handleArchiveDoc.bind(
                          null,
                          [idArchiveSingleDoc],
                          nextIdDocument,
                          nextPage
                        )
                      : handleArchiveDoc.bind(
                          null,
                          listIdDocumentSelected,
                          nextIdDocument,
                          nextPage
                        )
                  }
                  stateClass="Primary"
                  width={74}
                >
                  Archive
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showUnArchiveDoc && (
        <Modal selector="#modalInsidePdfViewer">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Unarchive Document
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>
              <div className={cls.main_wrapper} ref={closeDropdownRef}>
                {!!idUnArchiveSingleDoc || _.size(listIdDocumentSelected) === 1
                  ? 'Are you sure you want to unarchive this document?'
                  : `Are you sure you want to unarchive these ${listIdDocumentSelected.length} documents?`}
              </div>
              <div className={cls.button_wrapper}>
                <Button
                  onClick={handleCloseUnArchiveDoc}
                  stateClass="Secondary"
                  width={74}
                >
                  Cancel
                </Button>

                <Button
                  onClick={
                    idUnArchiveSingleDoc
                      ? handleUnArchiveDoc.bind(
                          null,
                          [idUnArchiveSingleDoc],
                          nextIdDocument,
                          nextPage
                        )
                      : handleUnArchiveDoc.bind(
                          null,
                          listIdDocumentSelected,
                          nextIdDocument,
                          nextPage
                        )
                  }
                  stateClass="Primary"
                >
                  Unarchive
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {_.size(listIdDocumentForCancel) >= 1 && (
        <Modal selector="#modal">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Cancel Request Document
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>
              <div className={cls.main_wrapper} ref={closeDropdownRef}>
                {_.size(listIdDocumentForCancel) === 1
                  ? 'Are you sure you want to cancel this request?'
                  : `Are you sure you want to cancel these ${listIdDocumentForCancel.length} requests?`}
              </div>
              <div className={cls.button_wrapper}>
                <Button
                  onClick={() => setListIdDocumentForCancel([])}
                  stateClass="Secondary"
                  width={74}
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleCancel.bind(null, listIdDocumentForCancel)}
                  stateClass="Primary"
                  width={74}
                >
                  Ok
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {/* Actions Multiple documents */}
      {!_.isEmpty(listIdDocumentSelected) && (
        <div className={cls.wr_multiple_actions}>
          <div>
            <Caption>
              {`${listIdDocumentSelected.length} document${
                listIdDocumentSelected.length > 1 ? 's' : ''
              } selected. `}
            </Caption>
            <Caption
              className={cls.blue}
              onClick={() => {
                setListDocumentSelected([])
              }}
            >
              Clear selection
            </Caption>
          </div>
          <div className={cls.wr_action_icons}>
            {renderActionsForMultiple()}
          </div>
        </div>
      )}
      {/* Actions Multiple documents end */}
    </React.Fragment>
  )
})
export default withAuth(withContext(DocumentPage))

const IconWithTooltipTop = ({ children, tooltipcontent }) => {
  const [isHover, setHover] = useState(false)

  return (
    <div
      className={cls.wr_icon_with_tooltip}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <div className={classnames(cls.tooltiptop, { opacity0: !isHover })}>
        {tooltipcontent}
      </div>
      {children}
    </div>
  )
}

const ButtonPopoverDocument = ({ setShowRequestDoc, setShowUploadDoc }) => {
  const [open, setOpen] = useState(false)
  const listItems = [
    {
      title: 'Request Document',
      icon: (
        <svg
          fill="none"
          height="15"
          viewBox="0 0 14 15"
          width="14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.75 2.24967C1.75 1.60534 2.27233 1.08301 2.91667 1.08301H11.0833C11.7277 1.08301 12.25 1.60535 12.25 2.24967V8.37467C12.25 8.69684 11.9888 8.95801 11.6667 8.95801C11.3445 8.95801 11.0833 8.69684 11.0833 8.37467V2.24967H2.91667V12.7497H6.125C6.44717 12.7497 6.70833 13.0108 6.70833 13.333C6.70833 13.6552 6.44717 13.9163 6.125 13.9163H2.91667C2.27234 13.9163 1.75 13.394 1.75 12.7497V2.24967Z"
            fill="#50565E"
          />
          <path
            d="M4.375 3.99967C4.375 3.67751 4.63617 3.41634 4.95833 3.41634H9.04167C9.36383 3.41634 9.625 3.67751 9.625 3.99967C9.625 4.32184 9.36383 4.58301 9.04167 4.58301H4.95833C4.63617 4.58301 4.375 4.32184 4.375 3.99967Z"
            fill="#50565E"
          />
          <path
            d="M4.95833 5.74967C4.63617 5.74967 4.375 6.01084 4.375 6.33301C4.375 6.65517 4.63617 6.91634 4.95833 6.91634H9.04167C9.36383 6.91634 9.625 6.65517 9.625 6.33301C9.625 6.01084 9.36383 5.74967 9.04167 5.74967H4.95833Z"
            fill="#50565E"
          />
          <path
            d="M4.375 8.66634C4.375 8.34418 4.63617 8.08301 4.95833 8.08301H6.70833C7.0305 8.08301 7.29167 8.34418 7.29167 8.66634C7.29167 8.98851 7.0305 9.24967 6.70833 9.24967H4.95833C4.63617 9.24967 4.375 8.98851 4.375 8.66634Z"
            fill="#50565E"
          />
          <path
            clipRule="evenodd"
            d="M9.33333 9.24967C8.20576 9.24967 7.29167 10.1638 7.29167 11.2913C7.29167 12.4189 8.20576 13.333 9.33333 13.333C9.6847 13.333 10.0157 13.244 10.3045 13.0875L10.9625 13.7455C11.1903 13.9733 11.5597 13.9733 11.7875 13.7455C12.0153 13.5177 12.0153 13.1483 11.7875 12.9205L11.1295 12.2626C11.2859 11.9737 11.375 11.6427 11.375 11.2913C11.375 10.1638 10.4609 9.24967 9.33333 9.24967ZM10.2083 11.2913C10.2083 11.5331 10.111 11.7511 9.95206 11.91C9.79311 12.069 9.57513 12.1663 9.33333 12.1663C8.85009 12.1663 8.45833 11.7746 8.45833 11.2913C8.45833 10.8081 8.85009 10.4163 9.33333 10.4163C9.81658 10.4163 10.2083 10.8081 10.2083 11.2913Z"
            fill="#50565E"
            fillRule="evenodd"
          />
        </svg>
      ),

      onClick: () => setShowRequestDoc(true),
    },
    {
      title: 'Upload Document',
      icon: (
        <svg
          fill="none"
          height="15"
          viewBox="0 0 14 15"
          width="14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            d="M1.0546 4.58326C1.98935 2.79004 3.93296 1.75882 5.94192 1.99021C7.79784 2.20397 9.3545 3.45444 9.97716 5.18809L10.2232 5.18809C11.5821 5.1872 12.9401 5.90537 13.3894 7.32351C13.8202 8.68322 13.32 10.1653 12.1532 10.9857C11.8897 11.171 11.5258 11.1076 11.3405 10.8441C11.1552 10.5806 11.2186 10.2167 11.4821 10.0314C12.2326 9.50369 12.5543 8.55045 12.2773 7.67588C12.0187 6.85989 11.2089 6.35399 10.2241 6.35475H9.54673C9.28041 6.35475 9.0479 6.17437 8.98171 5.9164C8.59922 4.42579 7.33724 3.3253 5.80843 3.14921C4.27959 2.97313 2.8005 3.75789 2.08914 5.12254C1.37779 6.4872 1.58148 8.14916 2.60134 9.30164C2.81484 9.5429 2.79233 9.91156 2.55107 10.1251C2.3098 10.3386 1.94114 10.3161 1.72764 10.0748C0.387506 8.56038 0.119842 6.37649 1.0546 4.58326ZM6.58745 6.29157C6.81526 6.06377 7.1846 6.06377 7.41241 6.29157L9.26857 8.14771C9.49638 8.37551 9.49638 8.74486 9.26858 8.97267C9.04077 9.20048 8.67143 9.20048 8.44362 8.97267L7.58366 8.11272L7.58568 12.4578C7.58583 12.7799 7.32479 13.0412 7.00262 13.0414C6.68046 13.0415 6.41917 12.7805 6.41902 12.4583L6.41699 8.11194L5.55624 8.97267C5.32843 9.20048 4.95908 9.20048 4.73128 8.97267C4.50348 8.74486 4.50348 8.37551 4.73129 8.14771L6.58745 6.29157Z"
            fill="#50565E"
            fillRule="evenodd"
          />
        </svg>
      ),
      onClick: () => setShowUploadDoc(true),
    },
  ]

  const ref = useOnclickOutside(() => {
    setOpen(false)
  })

  return (
    <div className="" ref={ref}>
      <div
        className="relative w-[38px] h-[38px] rounded-full bg-DarkerBlue cursor-pointer hover:shadow-md hover:bg-DarkBlue ml-[4px] "
        onClick={() => {
          // if (open === false) {
          //   setOpen(true)
          // }
          setOpen(!open)
        }}
      >
        <IconButtonAdd width={38} height={38} />
        {/* line white horizontal */}
        {/* <div
          className=" items-center justify-center absolute inset-0 "
          style={{
            display: 'flex',
          }}
        >
          <div className="bg-white h-[3px] w-[21.33px] rounded-[4px] " />
        </div> */}

        {/* line white vertical */}
        {/* <div
          className=" items-center justify-center absolute inset-0 "
          style={{
            display: 'flex',
          }}
        >
          <div className="bg-white h-[3px] w-[21.33px] rounded-[4px] transform rotate-90" />
        </div> */}

        {/* popover */}
        <div
          className={classnames(
            ' w-[184px] rounded-[4px] bg-white shadow-Popover border-[#d8dce6] mt-1 absolute top-[48px] right-0 ',
            open
              ? ' opacity-100 duration-150 '
              : ' opacity-0 duration-75 pointer-events-none'
          )}
        >
          {listItems.map(({ icon, title, onClick }, index) => (
            <React.Fragment key={title}>
              <div
                className="ignore-onclickoutside gap-x-[16px] items-center py-2 w-full px-[16px] hover:bg-LighterGray"
                onClick={onClick}
                style={{ display: 'flex' }}
              >
                {icon}

                <div className="text-Black text-[14px] leading-[150%] ">
                  {title}
                </div>
              </div>
              {index !== listItems.length - 1 && (
                <div className="h-[1px] w-auto mx-4 bg-LighterGray " />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}
;((_data) => {})([React])
