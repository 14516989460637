import classNames from 'classnames'
import React from 'react'
import cls from './tag.scss'

// Primary, Active, Disabled, Hover
export const Tag = ({
  children,
  stateClass,
  onClick,
  width,
  noHover,
  isRed,
}) => {
  const [hover, setHover] = React.useState(false)

  const computeClass = () => {
    if (isRed) {
      return cls.red
    }
    if (stateClass === 'Disabled') {
      return cls.Disabled
    }
    if (hover === true) {
      return cls.Hover
    }
    return cls[stateClass]
  }

  return (
    <button
      className={classNames(cls.tag, computeClass(), {
        [cls.nohover]: noHover === true,
      })}
      onClick={onClick}
      onMouseEnter={() => {
        if (noHover !== true) {
          setHover(true)
        }
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      style={{ width }}
    >
      {children}
    </button>
  )
}
;((_data) => {})([React])
