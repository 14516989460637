import React, { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { ToastContainer } from 'react-toastify'

import { useAtom } from 'jotai'

import route from '@constants/route'
import DataContext from '@context'
import '@duik/it/dist/styles.css'
import { useGroups } from '@hooks'
import '@progress/kendo-theme-material/dist/all.css'
import 'abortcontroller-polyfill'
import 'antd/dist/antd.css'
import classnames from 'classnames'
import { hotjar } from 'react-hotjar'
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
// import './app.css'
import './globals_compiled.css'
import './react-phone-input-2.css'

import {
  dataFromToken,
  handleRedirect,
  toastError,
  toastSuccess,
} from '@helper'
import { withDimensionOnChangeRdx } from '@hoc'
import { get } from 'lodash'

import { tokenDataAtom } from '@atoms/atoms'
import classNames from 'classnames'
import DriverDocLogo from '../driverDocAssets/DriverDocLogo.png'
import Footer from './@components/Footer'
import SplashScreen from './@components/splashscreen'
import cls from './App.scss'
import routeMap from './routeMap.js'
import IconDocument from './svg/IconDocument'
import IconDriver from './svg/IconDriver'
import IconLoad from './svg/IconLoad'
import IconTracking from './svg/IconTracking'
import IconVendor from './svg/IconVendor'

import { currentStepOfFlow, noDriver, runTour } from '../atoms/atoms'
import {
  CONTAINER_ID_GLOBAL,
  generatePendo,
  notificationOptions,
} from '../config'
import apiRoute from '../constants/apiRoute'
import { customAxios, isLoggedIn } from '../helper'
import { withContext } from '../hoc'
import { JoyrideWrapper } from '../lib/JoyrideWrapper'
import { ModalWelcome } from './@components/ModalWelcome'
import Map from './Page/pages/Map'
import IconCustomerServiceCenter from './svg/IconCustomerServiceCenter'
import IconGroup from './svg/IconGroup'
import IconPreferences from './svg/IconReferences'
import IconUser from './svg/IconUser'

require('es6-promise/auto')

// @flow

const IconPage = ({
  render,
  isActive,
  targeturl,
  tooltipcontent,
  className,
  enableHover = true,
  run,
}) => {
  const [isHover, setHover] = useState(false)
  return (
    <li
      className={classnames(
        `icon_page icon_page_load ${!run && 'mb-2'}`,
        className
      )}
      onMouseEnter={() => {
        if (enableHover) {
          setHover(true)
        }
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <div className={classNames('tooltip1', { opacity0: !isHover })}>
        {tooltipcontent}
      </div>

      {targeturl.indexOf('http') === 0 ? (
        <a
          className="link_sidebar"
          href={run ? '' : targeturl}
          rel="noreferrer"
          target="_blank"
        >
          <div
            className={classNames('square_icon', {
              icon_active: isActive,
              icon_hover: isHover,
            })}
          >
            {render(isActive ? '#fff' : undefined)}
          </div>
        </a>
      ) : (
        <Link className="link_sidebar" to={targeturl}>
          <div
            className={classNames('square_icon', {
              icon_active: isActive,
              icon_hover: isHover,
            })}
          >
            {render(isActive ? '#fff' : undefined)}
          </div>
        </Link>
      )}
    </li>
  )
}

const ComponentHandleGroups = () => {
  const { groups } = useGroups()

  useEffect(() => {
    function closeIt() {
      // check all groups
      // setCheckedGroups(groups)
    }
    // window.onbeforeunload = closeIt
    window.addEventListener('beforeunload', closeIt)
    return () => {
      window.removeEventListener('beforeunload', closeIt)
    }
  }, [groups])
  return null
}

const App = withRouter(
  withContext(
    withDimensionOnChangeRdx(
      ({ location, location: { pathname }, history }) => {
        const [cookies] = useCookies([
          'token',
          'user_name',
          'company_name',
          'accessToken',
        ])
        const [tokenData, setTokenData] = useAtom(tokenDataAtom)
        const [didMount, setDidMount] = useState(null)
        const [isOpenModal, setIsOpenModal] = useState(false)
        const [run] = useAtom(runTour)
        const [stepIndex] = useAtom(currentStepOfFlow)

        const [Driver, setDriver] = useAtom(noDriver)
        const checkRoleAndRedirect = (pathname, tokenData) => {
          if (!tokenData) {
            return null
          }
          if (
            (get(tokenData, 'smarteta') === 'false' &&
              pathname === '/page/loads') ||
            (get(tokenData, 'document') === 'false' &&
              pathname === '/page/documents')
          ) {
            handleRedirect(tokenData, history)
          }

          // check role and redirect
          if (get(tokenData, 'role') === 'Manager') {
            const listFobbiddenPage = ['/page/company-profile']
            if (listFobbiddenPage.includes(pathname)) {
              handleRedirect(tokenData, history)
            }
          }
        }
        checkRoleAndRedirect(pathname, tokenData)
        const handleGetNoDriver = async () => {
          try {
            const response = await customAxios(cookies.token).get(
              `${apiRoute.users.GET_LOGGED_IN_USER_INFO}`
            )
            if (
              response.status === 200 &&
              response.data.driverCount === 0 &&
              response.data.role === 'Owner'
            ) {
              history.push('/page/drivers')
              setDriver(true)
            }
          } catch (err) {}
        }

        useEffect(() => {
          if (cookies.token) {
            handleGetNoDriver()
          }
        }, [cookies, location.pathname])

        useEffect(() => {
          if (run) {
            document.body.addEventListener(
              'wheel',
              (event) => {
                if (event.ctrlKey === true) {
                  event.preventDefault()
                  return false
                }
              },
              { passive: false }
            )

            document.body.addEventListener(
              'keydown',
              (event) => {
                if (
                  event.ctrlKey === true &&
                  (String(event.which) === '107' ||
                    String(event.which) === '109' ||
                    String(event.which) === '187' ||
                    String(event.which) === '189' ||
                    String(event.which) === '27')
                ) {
                  event.preventDefault()
                  return false
                }
              },
              { passive: false }
            )
            document.body.addEventListener(
              'keydown',
              (event) => {
                if (event.keyCode === 27) {
                  event.preventDefault()
                  return false
                }
              },
              { passive: false }
            )
            return () => {}
          }
        }, [run])

        useEffect(() => {
          if (!cookies.token) {
            return
          }

          // todo
          const data = dataFromToken(cookies.token)

          const target = data

          generatePendo(process.env.RAZZLE_PENDO_API_KEY)

          window.pendo.initialize({
            visitor: {
              id: target.idUser,
              user_name: target.user_name,
            },
          })

          setTokenData(target)
          window.tokenData = target
        }, [cookies.token])

        const fixUnderneathModal = () => {
          const rootPage = document.getElementById('root')

          setIsOpenModal(true)
          rootPage.style.position = 'fixed'
        }

        const allowScrollRoot = () => {
          const rootPage = document.getElementById('root')
          setIsOpenModal(false)
          rootPage.style.position = 'unset'
        }

        const key = useMemo(() => {
          if (pathname) {
            return pathname.slice('/')[0]
          }
          return ''
        }, [pathname])

        const page = useMemo(() => {
          if (pathname) {
            return pathname.split('/')[2]
          }
          return ''
        }, [pathname])
        useEffect(() => {
          let timeout = null
          timeout = setTimeout(() => {
            setDidMount(true)
          }, 0)
          hotjar.initialize(
            `${process.env.RAZZLE_HOTJAR_ID}`,
            `${process.env.RAZZLE_HOTJAR_SNIPPET_VERSION}`
          )

          setTimeout(() => {
            const witget = document.getElementById('jsd-widget')
            if (witget) {
              witget.style.zIndex = 1000
            }
          }, 1200)

          return () => {
            clearTimeout(timeout)
          }
        }, [])

        if (!didMount) {
          return <SplashScreen />
        }
        return (
          <DataContext.Provider
            value={{
              message: {
                success: toastSuccess,
                error: toastError,
              },
              modal: {
                isOpen: isOpenModal,
                fixUnderneath: fixUnderneathModal,
                allowScroll: allowScrollRoot,
              },
            }}
          >
            {Driver && !run && isLoggedIn() && <ModalWelcome />}
            <JoyrideWrapper
              data={{
                run,
                stepIndex,
              }}
              path={location.pathname}
            />
            <ComponentHandleGroups />
            <aside
              className={classNames('left_navigation', {
                hidden:
                  pathname === '/maps' ||
                  pathname === route.LOGIN ||
                  pathname === route.SESSION_EXPIRED ||
                  pathname === route.RESET_PASSWORD ||
                  pathname === route.FORGET_PASSWORD ||
                  pathname.includes(route.RESET) ||
                  pathname.includes(
                    route.GET_TOKEN_FOR_CREATING_NEW_PASSWORD
                  ) ||
                  pathname.includes(route.CREATE_ACCOUNT) ||
                  pathname === route.VERIFICATION ||
                  pathname === route.ALL_SET ||
                  pathname === route.ERROR_PAGE,
              })}
            >
              <div className="wr_logo">
                <span>
                  <img
                    alt="logo"
                    className={cls.logo}
                    height="60"
                    src={DriverDocLogo}
                    width="60"
                  />
                </span>
              </div>
              <ul
                className={`wr_icon_page ${
                  pathname === route.PAGE.SUB_ROUTE.YOUR_ACCOUNT_IS_DISABLED
                    ? 'hidden'
                    : ''
                }`}
              >
                {get(tokenData, 'smarteta') === 'true' ? (
                  <div>
                    <IconPage
                      isActive={page === 'loads'}
                      page={page}
                      render={(fill) => <IconLoad fill={fill || undefined} />}
                      run={run}
                      targeturl="/page/loads"
                      tooltipcontent="Loads"
                    />
                    <IconPage
                      isActive={page === 'tracking'}
                      page={page}
                      render={(fill) => (
                        <IconTracking fill={fill || undefined} />
                      )}
                      run={run}
                      targeturl="/page/tracking"
                      tooltipcontent="Smart ETA"
                    />
                  </div>
                ) : (
                  ''
                )}

                {get(tokenData, 'document') === 'true' ? (
                  <IconPage
                    isActive={page === 'documents'}
                    page={page}
                    render={(fill) => <IconDocument fill={fill || undefined} />}
                    run={run}
                    targeturl="/page/documents"
                    tooltipcontent="Documents"
                  />
                ) : (
                  ''
                )}
                <div className="h-[1px] my-[22px] w-11 bg-LightGray text-LightestGray">
                  |
                </div>
                {get(tokenData, 'role') !== 'Staff' && (
                  <div className={isLoggedIn() && 'demo__projects'}>
                    <IconPage
                      enableHover={!run}
                      isActive={page === 'drivers'}
                      page={page}
                      render={(fill) => <IconDriver fill={fill || undefined} />}
                      run={run}
                      targeturl="/page/drivers"
                      tooltipcontent="Drivers"
                    />
                  </div>
                )}
                {get(tokenData, 'role') !== 'Staff' && (
                  <div className={isLoggedIn() && 'demo__projects4'}>
                    <IconPage
                      enableHover={!run}
                      isActive={page === 'users'}
                      page={page}
                      render={(fill) => <IconUser fill={fill || undefined} />}
                      run={run}
                      targeturl="/page/users"
                      tooltipcontent="Users"
                    />
                  </div>
                )}
                {get(tokenData, 'role') !== 'Staff' && (
                  <IconPage
                    isActive={page === 'groups'}
                    page={page}
                    render={(fill) => <IconGroup fill={fill || undefined} />}
                    run={run}
                    targeturl="/page/groups"
                    tooltipcontent="Groups"
                  />
                )}
                {get(tokenData, 'role') !== 'Staff' && (
                  <IconPage
                    isActive={page === 'vendors'}
                    page={page}
                    render={(fill) => <IconVendor fill={fill || undefined} />}
                    run={run}
                    targeturl="/page/vendors"
                    tooltipcontent="Vendors"
                  />
                )}
                {get(tokenData, 'role') !== 'Staff' && (
                  <IconPage
                    isActive={
                      page === 'company-profile' ||
                      page === 'storage' ||
                      page === 'automations'
                    }
                    page={page}
                    render={(fill) => (
                      <IconPreferences fill={fill || undefined} />
                    )}
                    run={run}
                    targeturl={
                      get(tokenData, 'role') === 'Owner' ||
                      get(tokenData, 'role') === 'Support'
                        ? '/page/company-profile'
                        : '/page/storage'
                    }
                    tooltipcontent="More"
                  />
                )}
                <IconPage
                  className={cls.iconCustomerServiceCenter}
                  isActive={false}
                  page={page}
                  render={(fill) => (
                    <IconCustomerServiceCenter fill={fill || undefined} />
                  )}
                  run={run}
                  targeturl="https://driverdoc.io/customer-service"
                  tooltipcontent="Customer Service Center"
                />
              </ul>
            </aside>
            <div
              className={classnames(cls.app, 'wr_content', {
                'no-margin-left':
                  pathname === route.LOGIN || pathname === route.CREATE_ACCOUNT,
              })}
            >
              <div className="wr_content_above">
                <Switch key={key} location={location}>
                  <Route
                    component={routeMap.Page}
                    path={route.PAGE.BASE_ROOT}
                  />
                  <Route
                    component={routeMap.ResetPassword}
                    exact
                    path={route.RESET_PASSWORD}
                  />
                  <Route
                    component={routeMap.ResetPass}
                    exact
                    path={route.RESET}
                  />
                  <Route
                    component={routeMap.ForgetPassword}
                    exact
                    path={route.FORGET_PASSWORD}
                  />
                  <Route
                    component={routeMap.ResetPass}
                    exact
                    path={`${route.RESET}/:id`}
                  />
                  <Route
                    component={routeMap.GetTokenForCreatingNewPassword}
                    exact
                    path={`${route.GET_TOKEN_FOR_CREATING_NEW_PASSWORD}/:token_for_creating_new_password`}
                  />
                  <Route component={routeMap.Login} exact path={route.LOGIN} />
                  <Route
                    component={routeMap.SessionExpired}
                    exact
                    path={route.SESSION_EXPIRED}
                  />
                  <Route
                    component={routeMap.CreateAccount}
                    exact
                    path={route.CREATE_ACCOUNT}
                  />
                  <Route
                    component={routeMap.Verification}
                    exact
                    path={route.VERIFICATION}
                  />
                  <Route
                    component={routeMap.AllSet}
                    exact
                    path={route.ALL_SET}
                  />
                  <Route
                    component={routeMap.ErrorPage}
                    exact
                    path={route.ERROR_PAGE}
                  />
                  <Route
                    component={routeMap.AccountLocked}
                    exact
                    path={route.ACCOUNT_LOCKED}
                  />

                  <Route component={Map} exact path={route.MAP} />

                  <Redirect to={route.PAGE.SUB_ROUTE.DASHBOARD} />
                </Switch>
              </div>

              <ToastContainer
                {...notificationOptions}
                containerId={CONTAINER_ID_GLOBAL}
              />

              <Footer
                className={classNames({
                  hidden:
                    pathname === '/maps' ||
                    pathname === route.LOGIN ||
                    pathname === route.RESET_PASSWORD ||
                    pathname === route.FORGET_PASSWORD ||
                    pathname.includes(route.RESET) ||
                    pathname.includes(
                      route.GET_TOKEN_FOR_CREATING_NEW_PASSWORD
                    ) ||
                    pathname.includes(route.CREATE_ACCOUNT),
                })}
              />
            </div>
          </DataContext.Provider>
        )
      }
    )
  )
)

export default App
;((_data) => {})([React])
