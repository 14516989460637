import { StatusButton } from '@appComponent/StatusButton'
import Modal from '@appComponent/modalPortal'
import { SpinnerIcon } from '@appComponent/spinnericon'
import { useGroups, useRecentlyEdited } from '@hooks'
import dayjs from 'dayjs'
import produce from 'immer'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { Button } from '@appComponent/button'
import {
  UikDropdown,
  UikDropdownItem,
  UikTopBar,
  UikTopBarSection,
  UikTopBarTitle,
} from '@components'
import apiRoute from '@constants/apiRoute'
import { withContext } from '@hoc'
import classnames from 'classnames'

import MyPagination from '@appComponent/MyPagination'
import { Caption, H4 } from '@appComponent/typography'
import usePrevious from '@appComponent/usePrevious'
import { SORT_ORDER } from '@constants'
import { customAxios, equalStr, getErrorMessage } from '@helper'
import queryString from 'query-string'
import { useCookies } from 'react-cookie'
import useSWR from 'swr'
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'

import { Input2WithReset } from '@appComponent/input'

import {
  ArrowDown,
  DeleteLoadIcon,
  DeleteSearch2,
  DotsComponent,
  EditLoadIcon,
  IconActivate14,
  IconDeactivate14,
  IconSearchLoad,
} from '@appComponent/icon'

import MyTooltip from '@appComponent/MyTooltip'
import { updateMyGroups } from '@service'
import _ from 'lodash'
import { PAGINATION_PAGE_SIZE_DEFAULT } from '../../../../../config'
import DrawerAddGroup from './DrawerAddGroup'
import DrawerEditGroup from './DrawerEditGroup'
import cls from './groups.scss'
import IconButtonAdd from '../../../../svg/IconButtonAdd'

const DEFAULT_SORT = 'name'

const fetcher = async (url, token) => {
  if (!url) return
  const data = await customAxios(token).get(url)
  if (data.status === 200) {
    return data.data.data
  }
  return { error: true }
}

const Groups = ({ context }) => {
  const closeDropdownRef = useRef(0)
  const { setGroups, setLoadinggroups, checkedGroups, setCheckedGroups } =
    useGroups()
  const [listRecentlyEdittedIdDoc, setListRecentlyEdittedIdDoc] =
    useRecentlyEdited()
  const [listGroup, setListGroup] = useState([])
  const [showAdd, setShowAdd] = useState(false)
  const [idEditGroup, setIdEditGroup] = useState('')
  const [idDeleteGroup, setIdDeleteGroup] = useState('')
  const [idDeactivateGroup, setIdDeactivateGroup] = useState('')
  const [idActivateGroup, setIdActivateGroup] = useState('')
  const [triggerFetch, setTriggerFetch] = useState(0)

  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1))
  const [total, setTotal] = useState(0)
  const [totalActive, setTotalActive] = useState(0)

  const [textload, setTextload] = useQueryParam(
    'search',
    withDefault(StringParam, undefined)
  )

  const [textloadDebounce] = useDebounce(textload, 300)
  const textloadDebouncePrev = usePrevious(textloadDebounce)

  const [lastSortInfo, setLastSortInfo] = useQueryParam(
    'sort',
    withDefault(StringParam, DEFAULT_SORT)
  )

  const [sortList, setSortList] = useState({
    name: '',
    status: '',
    member: '',
  })
  const lastSortInfoPrev = usePrevious(lastSortInfo)
  const [pageSize, setPageSize] = useQueryParam(
    'limit',
    withDefault(NumberParam, PAGINATION_PAGE_SIZE_DEFAULT)
  )

  const pageSizePrev = usePrevious(pageSize)

  const [time_request, set_time_request] = useState(
    dayjs().format('YYYY-MM-DDTHH:mm:ss')
  )

  const [cookies] = useCookies(['token'])
  const { token } = cookies
  const [urlfetch, setUrlfetch] = useState('')

  const { data, error } = useSWR(urlfetch, fetcher, {
    revalidateOnFocus: false,
  })

  const handleChangePageSize = (value) => {
    setPageSize(value)
  }

  const loadingGroups = useMemo(() => !data && !error, [data, error])

  const handleDeleteGroup = async () => {
    try {
      const { data: dataResponse } = await customAxios(token).put(
        `${apiRoute.groups.DELETE}/${idDeleteGroup}`
      )
      if (dataResponse.success) {
        updateMyGroups(
          token,
          setGroups,
          setLoadinggroups,
          checkedGroups,
          setCheckedGroups
        )
        setIdDeleteGroup(null)
        set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
        context.message.success('Deleted Successfully')
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    }
  }

  const handleDeleteSearch = () => {
    setTextload(undefined)
  }

  const handleChangeInputSearch = (e) => {
    setTextload(e.target.value || undefined)
  }

  const handleDeactivateGroup = async () => {
    try {
      const { data: dataResponse } = await customAxios(token).put(
        `${apiRoute.groups.DEACTIVATE}/${idDeactivateGroup}`
      )
      if (dataResponse.success) {
        updateMyGroups(
          token,
          setGroups,
          setLoadinggroups,
          checkedGroups,
          setCheckedGroups
        )
        setIdDeactivateGroup(null)
        set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
        context.message.success('Deactivated Successfully')
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    }
  }

  const handleActivateGroup = async () => {
    try {
      const { data: dataResponse } = await customAxios(token).put(
        `${apiRoute.groups.ACTIVATE}/${idActivateGroup}`
      )
      if (dataResponse.success) {
        setIdActivateGroup(null)
        set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
        context.message.success('Activated Successfully')
        updateMyGroups(
          token,
          setGroups,
          setLoadinggroups,
          checkedGroups,
          setCheckedGroups
        )
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    }
  }

  const handleSorting = (sortValue) => {
    const lastSortCriteria = lastSortInfo.replace('-', '')
    let lastSortInfoClone = lastSortInfo
    if (sortValue !== lastSortCriteria) {
      lastSortInfoClone =
        sortList[sortValue] === 'ASC' ? `-${sortValue}` : sortValue
    } else if (lastSortInfoClone[0] === '-') {
      lastSortInfoClone = lastSortInfoClone.replace('-', '')
    } else {
      lastSortInfoClone = `-${lastSortInfoClone}`
    }
    setLastSortInfo(lastSortInfoClone)
    const target = sortList[sortValue] === 'ASC' ? 'DESC' : 'ASC'
    setSortList({ ...sortList, [sortValue]: target })
  }

  const renderGroupActions = (group) => {
    const handleEditGroup = (id) => {
      setIdEditGroup(id)
    }

    const handleDeactiveGroup = (id) => {
      setIdDeactivateGroup(id)
    }

    const handleActiveGroup = (id) => {
      setIdActivateGroup(id)
    }

    const handleShowDeleteGroup = (id) => {
      setIdDeleteGroup(id)
    }

    return (
      <UikDropdown
        DisplayComponent={DotsComponent}
        className={cls.dropdown_edit_load}
        position="bottomRight"
      >
        <UikDropdownItem onClick={() => handleEditGroup(group.idGroup)}>
          <span className={cls.wr_edit_load_icon}>
            <EditLoadIcon height={14} width={14} />
          </span>
          <span className={cls.label}>Edit</span>
        </UikDropdownItem>

        {equalStr(group.status, 'Active') && (
          <UikDropdownItem onClick={() => handleDeactiveGroup(group.idGroup)}>
            <span className={cls.wr_edit_load_icon}>
              <IconDeactivate14 />
            </span>
            <span className={classnames(cls.red, cls.label)}>Deactivate</span>
          </UikDropdownItem>
        )}

        {equalStr(group.status, 'Inactive') && (
          <UikDropdownItem onClick={() => handleActiveGroup(group.idGroup)}>
            <span className={cls.wr_edit_load_icon}>
              <IconActivate14 />
            </span>
            <span className={cls.label}>Activate</span>
          </UikDropdownItem>
        )}

        {!equalStr(group.status, 'Active') && (
          <UikDropdownItem onClick={() => handleShowDeleteGroup(group.idGroup)}>
            <span className={cls.wr_edit_load_icon}>
              <DeleteLoadIcon />
            </span>
            <span className={classnames(cls.red, cls.label)}>Delete</span>
          </UikDropdownItem>
        )}
      </UikDropdown>
    )
  }

  useEffect(() => {
    setTimeout(() => {
      const key = lastSortInfo.replace('-', '')
      const value = lastSortInfo[0] === '-' ? 'DESC' : 'ASC'
      const nextState = produce(sortList, (draftState) => {
        _.set(draftState, `${key}`, value)
        return draftState
      })
      setSortList(nextState)
    }, 100)
  }, [])

  useEffect(() => {
    const params = {}

    params.sort = lastSortInfo
    params.limit = pageSize
    params.search = textloadDebounce
    params.page = page

    if (
      (lastSortInfo !== lastSortInfoPrev && lastSortInfoPrev !== undefined) ||
      (pageSize !== pageSizePrev && pageSizePrev !== undefined) ||
      textloadDebounce !== textloadDebouncePrev
    ) {
      params.page = 1
      setPage(undefined)
    }

    params.time_request = time_request
    // const url = `${apiRoute.groups.LIST}?${queryString.stringify(params)}`
    // ?sort=name&page=1&limit=10&search=group
    const url = `${apiRoute.groups.LIST}?${queryString.stringify(params)}`
    setUrlfetch(url)

    if (
      // TODO
      lastSortInfo === 'name' &&
      page === 1 &&
      pageSize === PAGINATION_PAGE_SIZE_DEFAULT
    ) {
      // when click on icon loads page on the left
      // setCurrentId(null)
      // set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
    }
  }, [page, pageSize, lastSortInfo, textloadDebounce, time_request])

  useEffect(() => {
    function handleData(data_) {
      setListGroup(_.get(data_, 'groups') || [])
      setTotal(_.get(data_, 'total') || 0)
      setTotalActive(_.get(data_, 'totalActive') || 0)
    }
    if (error === undefined && !!data) {
      handleData(data)
    } else {
      //
    }
  }, [data, error])

  const renderListGroup = () => {
    if (loadingGroups && _.isEmpty(listGroup)) {
      return <SpinnerIcon />
    }

    // if (_.isEmpty(listGroup)) {
    //   return (
    //     <div className="no_data">
    //       <Caption>No group</Caption>
    //     </div>
    //   )
    // }
    return (
      <table style={{ width: 'inherit' }}>
        <thead>
          <tr className={cls.head_row}>
            <th className={cls.c1}>
              <div
                className={cls.sort_wrapper}
                onClick={() => handleSorting('status', 'string')}
              >
                <span
                  className={
                    lastSortInfo.replace('-', '') === 'status'
                      ? 'text-Primary'
                      : 'text-DarkGray'
                  }
                >
                  STATUS
                </span>
                <ArrowDown
                  active={
                    lastSortInfo.replace('-', '') === 'status' ? 'active' : ''
                  }
                  className={classnames({
                    [cls.rotate_asc]: sortList.status === SORT_ORDER.ASC,
                    [cls.rotate_desc]: sortList.status === SORT_ORDER.DESC,
                  })}
                />
              </div>
            </th>
            <th className={cls.c2}>
              <div
                className={cls.sort_wrapper}
                onClick={() => handleSorting('name', 'string')}
              >
                <span
                  className={
                    lastSortInfo.replace('-', '') === 'name'
                      ? 'text-Primary'
                      : 'text-DarkGray'
                  }
                >
                  GROUP NAME
                </span>
                <ArrowDown
                  active={
                    lastSortInfo.replace('-', '') === 'name' ? 'active' : ''
                  }
                  className={classnames({
                    [cls.rotate_asc]: sortList.name === SORT_ORDER.ASC,
                    [cls.rotate_desc]: sortList.name === SORT_ORDER.DESC,
                  })}
                />
              </div>
            </th>
            <th className={cls.c3}>
              <div
                className={cls.sort_wrapper}
                onClick={() => handleSorting('member', 'string')}
              >
                <span
                  className={
                    lastSortInfo.replace('-', '') === 'member'
                      ? 'text-Primary'
                      : 'text-DarkGray'
                  }
                >
                  # OF MEMBERS
                </span>
                <ArrowDown
                  active={
                    lastSortInfo.replace('-', '') === 'member' ? 'active' : ''
                  }
                  className={classnames({
                    [cls.rotate_asc]: sortList.member === SORT_ORDER.ASC,
                    [cls.rotate_desc]: sortList.member === SORT_ORDER.DESC,
                  })}
                />
              </div>
            </th>
            <th className={cls.c4} />
          </tr>
        </thead>

        <tbody>
          {_.isEmpty(listGroup) ? (
            <tr className="bg-white ">
              <td className="h-[60px] " colSpan="9">
                <div className="flex items-center justify-center ">
                  <Caption>No group</Caption>
                </div>
              </td>
            </tr>
          ) : (
            listGroup.map((group, index) => (
              <React.Fragment key={group.idGroup}>
                <tr className={cls.body_row_spacer}>
                  <td className={cls.c1} />
                  <td className={cls.c2} />
                  <td className={cls.c3} />
                </tr>
                <tr
                  className={classnames(cls.body_row, {
                    [cls.go]: listRecentlyEdittedIdDoc.includes(group.idGroup),
                  })}
                  key={index}
                >
                  <td className={cls.c1}>
                    <StatusButton status={group.status} />
                  </td>
                  <td className={cls.c2}>
                    <Caption>{group.name}</Caption>
                  </td>
                  <td className={cls.c3}>
                    <Caption block>{group.countMembers}</Caption>
                  </td>
                  <td className={cls.c4}>
                    <div className="px-[22px]">{renderGroupActions(group)}</div>
                  </td>
                </tr>
              </React.Fragment>
            ))
          )}
        </tbody>
      </table>
    )
  }

  return (
    <div className={cls.container_group_profile}>
      <DrawerAddGroup
        context={context}
        setShowAdd={setShowAdd}
        set_time_request={set_time_request}
        showAdd={showAdd}
        token={token}
      />
      {!!idEditGroup && (
        <DrawerEditGroup
          context={context}
          idEditGroup={idEditGroup}
          setIdEditGroup={setIdEditGroup}
          setListRecentlyEdittedIdDoc={setListRecentlyEdittedIdDoc}
          setShowAdd={setShowAdd}
          set_time_request={set_time_request}
          showAdd={showAdd}
          token={token}
        />
      )}

      {/* Modal Delete */}
      {!!idDeleteGroup && (
        <Modal selector="#modal">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Delete Group
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>
              <div className={cls.main_wrapper} ref={closeDropdownRef}>
                Are you sure you want to delete this group?
              </div>
              <div className={cls.button_wrapper}>
                <Button
                  onClick={() => setIdDeleteGroup(null)}
                  stateClass="Secondary"
                  width={74}
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleDeleteGroup}
                  stateClass="Primary"
                  width={74}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {/* Modal Delete end */}

      {/* Modal Deactivate */}
      {!!idDeactivateGroup && (
        <Modal selector="#modal">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Deactivate Group
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>
              <div className={cls.main_wrapper} ref={closeDropdownRef}>
                Are you sure you want to deactivate this group?
              </div>
              <div className={cls.button_wrapper}>
                <Button
                  onClick={() => setIdDeactivateGroup(null)}
                  stateClass="Secondary"
                  width={74}
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleDeactivateGroup}
                  stateClass="Primary"
                  width={95}
                >
                  Deactivate
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {/* Modal Deactivate end */}
      {/* Modal Activate */}
      {!!idActivateGroup && (
        <Modal selector="#modal">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Activate Group
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>
              <div className={cls.main_wrapper} ref={closeDropdownRef}>
                Are you sure you want to activate this group?
              </div>
              <div className={cls.button_wrapper}>
                <Button
                  onClick={() => setIdActivateGroup(null)}
                  stateClass="Secondary"
                  width={81}
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleActivateGroup}
                  stateClass="Primary"
                  width={90}
                >
                  Activate
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {/* Modal Activate end */}

      {/* title and Add button */}
      <div className={cls.wr_title}>
        <div className={cls.groupName}>
          <MyTooltip
            classNameNormal=""
            classNameTooltip=""
            contentNormal={<H4>Groups</H4>}
            contentTooltip={
              <div>
                Groups allow you to segment your users
                <br /> and drivers into isolated workspaces
                <br /> such as markets, terminals, etc…
              </div>
            }
            tooltipId="groups"
          />
          <MyTooltip
            classNameNormal="ml8"
            classNameTooltip=""
            contentNormal={
              <Caption gray> {`${totalActive}/${total}`} </Caption>
            }
            contentTooltip="Active/Total"
            tooltipId="group_active"
          />
        </div>
        <div className={cls.search_wrapper}>
          <Input2WithReset
            className="w-[220px]"
            iconright={
              textload ? (
                <DeleteSearch2 onClick={handleDeleteSearch} />
              ) : (
                <span>
                  <IconSearchLoad />
                </span>
              )
            }
            onChange={handleChangeInputSearch}
            value={textload === undefined ? '' : textload}
          />
        </div>
        <div
          onClick={() => {
            setShowAdd(true)
          }}
        >
          <IconButtonAdd width={38} height={38} />
        </div>
      </div>
      {/* title and Add button end */}
      {/* table groups */}
      <div className={cls.wr_table_groups}>{renderListGroup()}</div>
      {/* table groups end */}
      <MyPagination
        className={classnames(cls.pagination)}
        handleChangePageSize={handleChangePageSize}
        loading={loadingGroups}
        page={page || 1}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        setTriggerFetch={setTriggerFetch}
        total={total}
        triggerFetch={triggerFetch}
      />
    </div>
  )
}

export default withContext(Groups)
;((_data) => {})([React])
