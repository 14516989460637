import React, { useMemo } from 'react'
import { Button } from '@@/App/@components/button'
import ReactModal from '../../components/MyModal/ReactModal'
import { SpinnerIcon } from '@@/App/@components/spinnericon'

export const ModalConfirmArchiveOrininalDocs = ({
  open,
  onClose,
  callback,
  isArchiveOriginalDocsRef,
  loading,
}) => {
  const headerText = useMemo(() => {
    return loading ? (
      <div className="text-center w-full">Processing... Please wait!</div>
    ) : (
      'Original Document'
    )
  }, [loading])

  return (
    <ReactModal
      headerText={headerText}
      Content={
        loading ? '' : 'Do you want to archive the original document(s)?'
      }
      open={open}
      textCancelButton="Keep"
      onClose={onClose}
      ButtonContent={
        loading ? (
          <div className=" flex justify-center items-center p-[10px] ">
            <SpinnerIcon />
          </div>
        ) : (
          <div className="flex justify-end items-center gap-2">
            <Button
              onClick={() => {
                isArchiveOriginalDocsRef.current = false
                callback()
              }}
              stateClass="Outline"
              loading={loading}
            >
              Keep
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                isArchiveOriginalDocsRef.current = true
                callback()
              }}
              stateClass="Primary"
              width={74}
            >
              Archive
            </Button>
          </div>
        )
      }
    />
  )
}
;((_data) => {})([React])
