import React from 'react'

export default (props) => (
  <svg
    fill="currentColor"
    height="2px"
    version="1.1"
    viewBox="0 0 20 2"
    width="20px"
    {...props}
  >
    <g id="Icon/20px/menu-[Extra]" transform="translate(0.000000, -9.000000)">
      <polygon id="Path" points="0 11 20 11 20 9 0 9" />
    </g>
  </svg>
)
;((_data) => {})([React])
