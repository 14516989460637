import React from 'react'
import DriverDocLogo from '../../driverDocAssets/DriverDocLogo.png'
import cls from './common.scss'
const SplashScreen = () => {
  const splashScreen = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <React.Fragment>
      <div
        className={cls.splash_screen}
        id="splash_screen"
        style={splashScreen}
      >
        <img alt="logo" className={cls.image} src={DriverDocLogo} width="150" />
      </div>
    </React.Fragment>
  )
}

export default SplashScreen
;((_data) => {})([React])
