/* eslint-disable no-unused-expressions */
import { Small } from '@appComponent/typography'
import querystring from 'query-string'
import apiRoute from '@constants/apiRoute'
import route from '@constants/route'
import axios from 'axios'
import { differenceInDays } from 'date-fns'
import dayjs from 'dayjs'
import cookies from 'js-cookie'
import cookie from 'cookie'
import jwtDecode from 'jwt-decode'
import _, { get, isEmpty, toLower, trim } from 'lodash'
import moment from 'moment'
import qs from 'qs'
import React from 'react'
import { Cookies } from 'react-cookie'
import nextId from 'react-id-generator'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IconNotiFailed, IconNotiSuccess } from '../App/@components/icon'
import { CONTAINER_ID_GLOBAL, CONTAINER_ID_INSIDE_PDF_VIEWER } from '../config'
import { TripTypeEnum } from '../constants'

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)

  document.cookie = `${cname}=${cvalue};path=/`
}
if (typeof window !== 'undefined') {
  window.dayjs = dayjs
}

export const promiser = async (promise) => {
  try {
    const data = await promise
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const promiserAll = async (promises) => {
  try {
    const datas = await Promise.all(promises)
    return [datas, null]
  } catch (error) {
    return [null, error]
  }
}

export const displayDateFormat = 'MM/DD/YY'
export const displayTimeFormat = 'HH:mm'
export const databaseDateFormat = 'YYYY-MM-DD'
export const databaseTimeFormat = 'HH:mm:ss'

export function parseCookies(req) {
  return cookie.parse(
    req
      ? req.headers.cookie || ''
      : typeof window !== 'undefined'
      ? window.document.cookie
      : ''
  )
}

export const customAxios = (_token) => {
  const controllerInstance = new AbortController()

  const token2 = parseCookies(null)['token']
  // console.log('aaa _token ', _token )
  // console.log('aaa token2 ', token2)

  if (!token2) {
    throw new Error('token does not exist')
  }
  const instance = axios.create({
    baseURL: apiRoute.base,
    timeout: 30000,
    signal: controllerInstance.signal,
  })

  instance.defaults.headers.common.Authorization = `Bearer ${token2}`

  instance.interceptors.request.use(
    (response) => {
      if (
        window.location.pathname ===
          route.PAGE.SUB_ROUTE.YOUR_ACCOUNT_IS_DISABLED &&
        response.url !== apiRoute.users.GET_LOGGED_IN_USER_INFO
      ) {
        throw new axios.Cancel('Operation canceled by the user.')
      } else {
        return response
      }
    },
    (error) => Promise.reject(error)
  )

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const cookies = new Cookies()

      if (_.get(error, 'response.data.errorCode') === 'USER_ACCOUNT_LOCKED') {
        // window.location = '/account-locked'
        if (
          getStr(error, 'config.url') !== apiRoute.users.GET_LOGGED_IN_USER_INFO
        ) {
          window.location = route.PAGE.SUB_ROUTE.YOUR_ACCOUNT_IS_DISABLED
        }

        return Promise.reject(error)
      }

      if (_.get(error, 'response.data.errorCode') === 'INVALID_WEB_TOKEN') {
        cookies.remove('token')
        cookies.remove('accessToken')
        localStorage.clear()
        sessionStorage.clear()
        window.location = '/'
      } else if (_.get(error, 'response.status') === 401) {
        // axios({
        //   method: 'post',
        //   url: `${process.env.RAZZLE_APP_AUTH_DOMAIN_NAME}/oauth2/token`,
        //   headers: new Headers({ 'content-type': 'application/x-www-form-urlencoded' }),
        //   body: {

        //     refresh_token: cookies.get('refreshToken'),
        //     grant_type: 'refresh_token',
        //     redirect_uri: process.env.RAZZLE_APP_DOMAIN_NAME,
        //     client_id: process.env.RAZZLE_APP_CLIENT_ID,

        //   },
        // }).then((response) => {
        // // saveToken()
        //   cookies.set('token', response.id_token)
        //   cookies.set('accessToken', response.access_token)
        //   error.response.config.headers.Authorization = `Bearer ${response.data.access_token}`
        //   return axios(error.response.config)
        // }).catch((err) => {
        // // destroyToken()
        //   window.location = '/'
        //   return Promise.reject(err)
        // })
        //   .finally()
        cookies.remove('token')
        cookies.remove('accessToken')
        localStorage.clear()
        sessionStorage.clear()
        if (_.get(error, 'response.data.errorCode') === 'CHANGE_PERMISSION') {
          window.location = '/session-expired'
        } else {
          window.location = '/'
        }
      } else if (_.get(error, 'response.status') === 403) {
        controllerInstance.abort()
        cookies.remove('token')
        cookies.remove('accessToken')
        const data = {
          refresh_token: cookies.get('refreshToken'),
          grant_type: 'refresh_token',
          redirect_uri: process.env.RAZZLE_APP_DOMAIN_NAME,
          client_id: process.env.RAZZLE_APP_CLIENT_ID,
        }
        const controller = new AbortController()
        axios({
          method: 'post',
          url: `${process.env.RAZZLE_APP_AUTH_DOMAIN_NAME}/oauth2/token`,
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(data),
          signal: controller.signal,
        })
          .then((response) => {
            // saveToken()
            controller.abort()
            setCookie('token', response.data.id_token)
            setCookie('accessToken', response.data.access_token)
            instance.defaults.headers.common.Authorization =
              response.data.id_token
            window.location.reload()
            const { config } = response
            return instance(config)
          })
          .catch((err) => {
            if (
              err.response.status === 400 &&
              err.response.data.error === 'invalid_grant'
            ) {
              localStorage.clear()
              sessionStorage.clear()
              window.location = '/'
            } else {
              controller.abort()
            }
            return Promise.reject(err)
          })
      }
      return Promise.reject(error)
    }
  )
  return instance
}
export const getLoginURL = () =>
  `${process.env.RAZZLE_APP_AUTH_DOMAIN_NAME}/login?client_id=${process.env.RAZZLE_APP_CLIENT_ID}&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=${process.env.RAZZLE_APP_DOMAIN_NAME}`
export const formatNumber = (input) => {
  if (!input && input !== 0) {
    return ''
  }
  const newFormatNumber = `${input.slice(0, 2)} (${input.slice(
    2,
    5
  )}) ${input.slice(5, 8)}-${input.slice(8)}`
  return newFormatNumber
}
export const formatNumberWithoutPrefix = (input) => {
  if (!input && input !== 0) {
    return ''
  }
  const newFormatNumber = `${input.slice(2, 5)}-${input.slice(
    5,
    8
  )}-${input.slice(8)}`
  return newFormatNumber
}

export const formatToNumber = (input) => {
  if (!input && input !== 0) {
    return ''
  }
  return `${input.replace(/\D/gm, '')}`
}

export const formatRawData = (input) => {
  if (!input && input !== 0) {
    return ''
  }
  return input
}
export const formatDate = (input) =>
  moment(new Date(input)).format(databaseDateFormat)

export const formatWesternDate = (input) =>
  moment(input, databaseDateFormat).format(displayDateFormat)
export const formatTime = (input) => moment(new Date(input)).format('HH:mm')

export const formatEarliestTime = (date, earliest) => {
  if (!date && !earliest) {
    return ''
  }
  if (!date) {
    const currentDay = new Date()
    return `${formatDate(currentDay)} ${earliest}:00`
  }
  if (!earliest) {
    return `${formatDate(date)} 00:00:00`
  }
  return `${formatDate(date)} ${earliest}:00`
}
export const formatLatestTime = (date, latest) => {
  if (!date && !latest) {
    return ''
  }
  if (!date) {
    const currentDay = new Date()
    return `${formatDate(currentDay)} ${latest}:00`
  }
  if (!latest) {
    return `${formatDate(date)} 00:00:00`
  }
  return `${formatDate(date)} ${latest}:00`
}

export const formattedTripsAdd = (input) => {
  const result = input.map((item, index) => ({
    location: item.location,
    idDriver: item.driver.id_driver,
    idTruck: item.truck.id_truck,
    isAppointment:
      item.type === 'Window' ? 0 : item.type === 'Appointment' ? 1 : '',
    tripNumber: `${index + 1}`,
    earliestTime:
      item.type === 'Appointment'
        ? formatLatestTime(item.latest_date, item.latest_time)
        : formatEarliestTime(item.earliest_date, item.earliest_time),
    latestTime: formatLatestTime(item.latest_date, item.latest_time),
    appointmentNumber: item.appointment,
    note: item.note,
    trailers: item.trailer,
    tripType:
      item.trip_type === 'Pickup'
        ? '0'
        : item.trip_type === 'Delivery'
        ? '1'
        : '',
    // idTrip: item.id_trip,
  }))

  return result
}

export const formattedTripsEdit = (input) => {
  const result = input.map((item, index) => {
    if (item.delete) {
      return {
        location: item.location,
        idDriver: item.driver.id_driver,
        idTruck: item.truck.id_truck,
        isAppointment:
          item.type === 'Window' ? 0 : item.type === 'Appointment' ? 1 : '',
        tripNumber: `${index + 1}`,
        earliestTime:
          item.type === 'Appointment'
            ? formatLatestTime(item.latest_date, item.latest_time)
            : formatEarliestTime(item.earliest_date, item.earliest_time),
        latestTime: formatLatestTime(item.latest_date, item.latest_time),
        appointmentNumber: item.appointment,
        note: item.note,
        trailers: item.trailer,
        tripType:
          item.trip_type === 'Pickup'
            ? '0'
            : item.trip_type === 'Delivery'
            ? '1'
            : '',
        idTrip: item.id_trip,
        is_partial: item.is_partial,
        delete: true,
      }
    }
    return {
      location: item.location,
      idDriver: item.driver.id_driver,
      idTruck: item.truck.id_truck,
      isAppointment:
        item.type === 'Window' ? 0 : item.type === 'Appointment' ? 1 : '',
      tripNumber: `${index + 1}`,
      earliestTime:
        item.type === 'Appointment'
          ? formatLatestTime(item.latest_date, item.latest_time)
          : formatEarliestTime(item.earliest_date, item.earliest_time),
      latestTime: formatLatestTime(item.latest_date, item.latest_time),
      appointmentNumber: item.appointment,
      note: item.note,
      trailers: item.trailer,
      tripType:
        item.trip_type === 'Pickup'
          ? '0'
          : item.trip_type === 'Delivery'
          ? '1'
          : '',
      idTrip: item.id_trip,
      is_partial: item.is_partial,
    }
  })
  return result
}

export const specialCharacter = () => {
  const speRegex =
    /[^a-zA-Z0-9\s\(\)àáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ]/g
  return speRegex
}

export const splitDate = (input) => {
  if (!input || input === 'Invalid date') {
    return ''
  }
  return formatWesternDate(input.split(' ')[0])
}
export const splitTime = (input) => {
  if (!input || input === 'Invalid date' || typeof input !== 'string') {
    return ''
  }
  const newInput = input.split(' ')[1]
  return newInput ? newInput.slice(0, 5) : ''
}
export const compareTime = (schedule_arrival_eta, latest_time) => {
  if (!schedule_arrival_eta || !latest_time) {
    return
  }
  const scheduleArrivalEta = moment(schedule_arrival_eta, 'YYYY-MM-DD hh:mm:ss')
  const latestTime = moment(latest_time, 'YYYY-MM-DD hh:mm:ss')
  if (scheduleArrivalEta.diff(latestTime) > 0) {
    return true
  }
  return false
}

export const checkAppointmentTime = (startTime, endTime) => {
  const start_time = splitTime(startTime).slice(0, 5)
  const end_time = splitTime(endTime).slice(0, 5)
  const start_date = splitDate(startTime)
  const end_date = splitDate(endTime)
  if (start_time === end_time && start_date === end_date) {
    return 'appointment'
  }
  return 'window'
}

export const formatTimeAppointment = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return ''
  }
  const start = splitTime(startTime).slice(0, 5)
  const end = splitTime(endTime).slice(0, 5)

  if (start === end) {
    return start
  }
  return `${start} - ${end}`
}
export const formatTimeSearchLoad = (input) => {
  let result = ''
  if (!input) {
    return ''
  }
  result = `${input.getFullYear()}/${input.getMonth() + 1}/${input.getDate()}`
  return result
}

export const formatDocumentType = (input) => {
  if (!input) {
    return ''
  }
  if (input === 'Trip Document') {
    return 'Others'
  }
  if (input === 'Bill of Lading') {
    return 'BOL'
  }
  if (input === 'Proof of Delivery') {
    return 'POD'
  }
}

export const lowerString = (input) => {
  if (!input) {
    return input
  }
  return input.toLowerCase()
}

export const checkInputToSort = (sortValue, sortType) => {
  if (sortType === 'number') {
    return Number(sortValue)
  }
  if (sortType === 'string') {
    return lowerString(sortValue)
  }
}

export const generateErrorList = (errorLength) => {
  const result = Array.from({ length: errorLength }, (v, k) => ({
    trip_index: k + 1,
    trip_type: '',
    truck: {
      id_truck: '',
      number: '',
    },
    driver: {
      full_name: '',
      id_driver: '',
      phone_number: '',
    },
    location: {
      city: '',
      address: '',
      latitude: '',
      longitude: '',
      placeId: '',
      state: '',
      name: '',
      zipcode: '',
      displayValue: '',
    },
    appointment: '',
    type: '',
    earliest_date: '',
    latest_date: '',
    earliest_time: '',
    latest_time: '',
    note: '',
    idTrip: '',
  }))
  return result
}

export function formatReturnValue(str, max, noDots = false) {
  // if (!input && input !== 0) {
  //   return ''
  // }
  // if (input.length > truncate) {
  //   for (let x = truncate; x > 0; x--) {
  //     if (input[x] === ' ') {
  //       return input.slice(0, x).concat('...')
  //     }
  //   }
  //   return input.slice(0, truncate).concat('...')
  // }
  // return input
  if (str === null || str === undefined || str === '') {
    return ''
  }
  if (str.length > max) {
    const dots = noDots ? '' : '...'
    return str.substr(0, max) + dots
  }
  return str
}

function formatReturnedDate(input) {
  if (!input) {
    return ''
  }
  return new Date(splitDate(input))
}

export const formatTrip = (input) =>
  input.map((item, index) => ({
    trip_index: index + 1,
    trip_type:
      getStr(item, 'trip_type') === '0'
        ? 'Pickup'
        : getStr(item, 'trip_type') === '1'
        ? 'Delivery'
        : '',
    trip_number: item.trip_number,
    truck: {
      id_truck: getStr(item, 'trucks.id_truck'),
      number: getStr(item, 'trucks.number'),
      isChosen: true,
    },
    driver: {
      full_name: getStr(item, 'drivers.full_name'),
      id_driver: getStr(item, 'drivers.id_driver'),
      phone_number: '',
      isChosen: true,
    },
    location: {
      city: getStr(item, 'location.city'),
      address: getStr(item, 'location.address'),
      latitude: getStr(item, 'location.latitude'),
      longitude: getStr(item, 'location.longitude'),
      placeId: getStr(item, 'location.place_id'),
      state: getStr(item, 'location.state'),
      name: getStr(item, 'location.name'),
      zipcode: getStr(item, 'location.zipcode'),
      displayValue: getStr(item, 'location.display_values'),
    },
    type:
      item.is_appointment === 0
        ? 'Window'
        : item.is_appointment === 1
        ? 'Appointment'
        : '',
    earliest_date:
      item.is_appointment === 1 ? '' : formatReturnedDate(item.earliest_time),
    latest_date: formatReturnedDate(item.latest_time),
    earliest_time:
      item.is_appointment === 1 ? '' : splitTime(item.earliest_time),
    // splitTime(item.earliest_time),
    latest_time: splitTime(item.latest_time),
    appointment: item.appointment_number,
    trailer: item.trailers,
    note: item.note,
    id_trip: getStr(item, 'id_trip'),
    trip_status: item.trip_status,
    is_partial: item.is_partial,
  }))

export const formatLeadingZero = (input) => {
  if (Number(input) < 10) {
    return `0${input}`
  }
  return input.toString()
}

export const checkMessageAuth = (message) => {
  if (
    message === 'Invalid Web Token!' ||
    message === 'Expired Web Token' ||
    message === 'Verify Web Token Fail!'
  ) {
    return true
  }
  return false
}

export const getLatestElementValue = (listTrip, trip_type) => {
  for (let ele = listTrip.length - 1; listTrip.length >= 0; ele--) {
    if (listTrip[ele].trip_type === trip_type) {
      return listTrip[ele]
    }
  }
}

export const sortListStatus = [
  {
    id: 1,
    content: 'Draft', // TODO
    countTotal: 0,
  },
  {
    id: 2,
    content: 'Pending',
    countTotal: 0,
  },
  {
    id: 3,
    content: 'Active',
    countTotal: 0,
  },
  {
    id: 4,
    content: 'Incomplete',
    countTotal: 0,
  },
  {
    id: 5,
    content: 'Complete',
    countTotal: 0,
  },
  {
    id: 6,
    content: 'All',
    countTotal: 0,
  },
]

export const sortListStatusDocument = [
  {
    id: 1,
    content: 'Load Documents', // TODO
    countTotal: 0,
  },
  {
    id: 2,
    content: 'Other Documents',
    countTotal: 0,
  },
  {
    id: 3,
    content: 'Open Requests',
    countTotal: 0,
  },
]

export const getNewStopData = (tripNumber) => ({
  uniqueid: nextId(),
  collapse: false,
  order: '',
  tripName: '',
  tripType: '',
  tracking: true,
  location: {
    city: '',
    address: '',
    latitude: '',
    longitude: '',
    placeId: '',
    state: '',
    name: '',
    zipcode: '',
    displayValue: '',
  },
  appointmentNumber: '',
  ld: null,
  lt: null,
  ed: null,
  et: null,
  earliestTime: null,
  isAppointment: 1,
  latestTime: null,
  note: '',
  tripNumber,
})

export const sample = [
  {
    id_load: '',
    id_carrier: '',
    load_number: '',
    status: '',
    created_at: '',
    updated_at: '',
    created_by: '',
    updated_by: '',
    purchase_order: '',
    reference: '',
    billOfLading: '',
    countDriver: '',
    countTruck: '',
    customers: {
      id_customer: '',
      customer_name: '',
    },
    trips: [
      {
        id_load: '',
        id_carrier: '',
        id_location: '',
        id_trip: '',
        trip_number: '',
        power_detention: '',
        id_driver: '',
        earliest_time: '',
        latest_time: '',
        is_appointment: '',
        ondock_time: '',
        realtime_arrival: '',
        realtime_departure: '',
        arrival_status: '',
        departure_status: '',
        document_type: '',
        created_at: '',
        updated_at: '',
        document_status: '',
        active_trip: '',
        power_detention_time: '',
        is_done: '',
        check_schedule_eta: '',
        eta_late_time: '',
        tracking_time: '',
        send_mail_status: '',
        estimate_time: '',
        arrival_timezone: '',
        id_truck: '',
        trip_type: '',
        arrival_eta: '',
        schedule_arrival_eta: '',
        trailers: '',
        appointment_number: '',
        note: '',
        is_partial: '',
        drivers: {
          id_driver: '',
          full_name: '',
        },
        trucks: {
          id_truck: '',
          number: '',
        },
        location: {
          name: '',
          address: '',
          city: '',
          state: '',
          latitude: '',
          longitude: '',
          zipcode: '',
          displayValue: '',
        },
        trip_status: '',
        url: '',
      },
    ],
  },
]

export const checkNumber = (number) => !isNaN(Number(String(number)))

export const convertSectoDay = (secondData, short = false) => {
  if (secondData === '') return ''
  if (!checkNumber(secondData)) {
    return ''
  }

  secondData = Math.floor(Math.abs(secondData))
  const day = Math.floor(secondData / (24 * 3600))

  secondData %= 24 * 3600
  const hour = Math.floor(secondData / 3600)

  secondData %= 3600
  const minutes = Math.floor(secondData / 60)

  secondData %= 60
  const seconds = Math.floor(secondData)

  let max
  if (day === 0) {
    max = 'hour'
    if (hour === 0) {
      if (minutes === 0) {
        max = 'second'
      } else {
        max = 'minute'
      }
    } else {
      max = 'hour'
    }
  } else {
    max = 'day'
  }

  const dayStr = `${day}${short ? 'd' : ' days'}`

  let longHour = ' hrs'
  if (Number(hour) === 1) {
    longHour = ' hr'
  }
  const hourStr = `${hour}${short ? 'h' : longHour}`

  let longMin = ' mins'
  if (Number(minutes) === 1) {
    longMin = ' min'
  }
  const minuteStr = `${minutes}${short ? 'm' : longMin}`

  let longSecond = ' seconds'
  if (Number(seconds) === 1) {
    longSecond = ' second'
  }
  const secondStr = `${seconds}${short ? 's' : longSecond}`
  switch (max) {
    case 'second':
      return secondStr
    case 'minute':
      return `${minuteStr}`
    case 'hour':
      return `${hourStr} ${minuteStr}`
    default:
      return `${dayStr} ${hourStr}`
  }
}

export const formatDetentionTimeLong = (detentionTime) => {
  detentionTime = Math.abs(detentionTime)
  const hours = detentionTime / 60
  const rHours = Math.floor(hours)
  const minutes = (hours - rHours) * 60
  const rMinutes = Math.round(minutes)

  if (rHours === 0 && rMinutes === 1) return `${rMinutes} min`
  if (rHours === 0 && rMinutes > 1) return `${rMinutes} mins`

  if (rHours === 1 && rMinutes === 0) return `${rHours} hour`
  if (rHours === 1 && rMinutes === 1) {
    return `${rHours} hour and ${rMinutes} min`
  }
  if (rHours === 1 && rMinutes > 1) return `${rHours} hour and ${rMinutes} mins`

  if (rHours > 1 && rMinutes === 0) return `${rHours} hours`
  if (rHours > 1 && rMinutes === 1) return `${rHours} hours and ${rMinutes} min`
  if (rHours > 1 && rMinutes > 1) return `${rHours} hours and ${rMinutes} mins`

  return ''
}

export const formatDetentionTimeShort = (detentionTime) => {
  detentionTime = Math.abs(detentionTime)
  const hours = detentionTime / 60
  const rHours = Math.floor(hours)
  const minutes = (hours - rHours) * 60
  const rMinutes = Math.round(minutes)

  if (rHours === 0 && rMinutes === 1) return `${rMinutes}m`
  if (rHours === 0 && rMinutes > 1) return `${rMinutes}m`

  if (rHours === 1 && rMinutes === 0) return `${rHours}h`
  if (rHours === 1 && rMinutes === 1) return `${rHours}h ${rMinutes}m`
  if (rHours === 1 && rMinutes > 1) return `${rHours}h ${rMinutes}m`

  if (rHours > 1 && rMinutes === 0) return `${rHours}h`
  if (rHours > 1 && rMinutes === 1) return `${rHours}h ${rMinutes}m`
  if (rHours > 1 && rMinutes > 1) return `${rHours}h ${rMinutes}m`

  return ''
}

export const getStr = (
  obj,
  path,
  defaultValue = '',
  maxlength = 10000000000,
  noDots = false
) => {
  if (_.get(obj, path) === 0) {
    return 0
  }
  if (_.get(obj, path) === null) {
    return defaultValue || ''
  }
  if (typeof _.get(obj, path) === 'object') {
    console.error('pppp getStr')
    return ''
  }

  return formatReturnValue(
    _.get(obj, path) || defaultValue || '',
    maxlength,
    noDots
  )
}

if (typeof window !== 'undefined') {
  window.getStr = getStr
}

// export const formatDateTime = date => {
//   if (!date) return ''
//   return moment(date).format('HH:mm MM/DD/YY')
// }

export const ucfirst = (str) => {
  const firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

export const equalStr = (str1, str2) => {
  if (isEmpty(str1) || isEmpty(str2)) {
    return false
  }
  return str1.trim().toLowerCase() === str2.trim().toLowerCase()
}

export const getDateTime = (str) => {
  if (!str) return ''
  return `${splitDate(str)} - ${splitTime(str)}`
}

export const getTripNumber = (str) => {
  const index = str.indexOf('-')
  return str.substring(index + 1)
}

export const getTripTypeText = (a) => {
  if (!a) {
    return ''
  }
  if (a === TripTypeEnum.PICKUP) {
    return 'Pickup'
  }
  if (a === TripTypeEnum.DELIVERY) {
    return 'Delivery'
  }
}

export const getEdittedListTrip = (listTrip) => {
  const target = _.map(listTrip, (o, i) => {
    const obj = {
      order: getStr(o, 'order'),
      tripName: getStr(o, 'tripName'),
      tripType: getStr(o, 'tripType'),
      tracking: get(o, 'tracking') === true,
      location: {
        address: getStr(o, 'location.address'),
        city: getStr(o, 'location.city'),
        latitude: getStr(o, 'location.latitude'),
        longitude: getStr(o, 'location.longitude'),
        name: getStr(o, 'location.name'),
        placeId: getStr(o, 'location.placeId'),
        state: getStr(o, 'location.state'),
        zipcode: getStr(o, 'location.zipcode'),
      },
      appointmentNumber: getStr(o, 'appointmentNumber'),
      isAppointment: getStr(o, 'isAppointment'),
      note: o.note || '',
      tripNumber: i + 1,
    }

    let latest_date_time = ''
    if (!!o.ld || !!o.lt) {
      latest_date_time = `${
        o.ld
          ? dayjs(o.ld).format(databaseDateFormat)
          : dayjs().format(databaseDateFormat)
      } ${o.lt ? o.lt.concat(':00') : '00:00:00'}`
    }

    let earliest_date_time = ''
    if (!!o.ed || !!o.et) {
      earliest_date_time = `${
        o.ed
          ? dayjs(o.ed).format(databaseDateFormat)
          : dayjs().format(databaseDateFormat)
      } ${o.et ? o.et.concat(':00') : '00:00:00'}`
    }

    if (o.isAppointment === 1) {
      obj.latestTime = latest_date_time
      obj.earliestTime = latest_date_time
    } else {
      obj.latestTime = latest_date_time
      obj.earliestTime = earliest_date_time
    }

    if (o.tracking !== true) {
      if (!obj.latestTime) {
        delete obj.latestTime
      }
      if (!obj.earliestTime) {
        delete obj.earliestTime
      }
      if (!getStr(obj, 'location.address')) {
        delete obj.location
      }
    }
    return obj
  })

  return target
}

export const getEdittedListTripForEditLoad = (listTrip, is_partial = false) => {
  const listTripNotContainAddThenDelete = listTrip.filter((trip) => {
    const novalid = trip.delete === true && trip.id_trip === undefined
    return !novalid
  })

  const target = _.map(listTripNotContainAddThenDelete, (o, i) => {
    const obj = {
      is_partial,
      idTrip: o.id_trip,
      delete: o.delete,
      order: getStr(o, 'order'),
      tripName: getStr(o, 'tripName'),
      tripType: getStr(o, 'tripType'),
      tracking: get(o, 'tracking') === true,
      realtimeArrival: o.realtimeArrival,
      realtimeDeparture: o.realtimeDeparture,
      location: {
        address: getStr(o, 'location.address'),
        city: getStr(o, 'location.city'),
        latitude: getStr(o, 'location.latitude'),
        longitude: getStr(o, 'location.longitude'),
        name: getStr(o, 'location.name'),
        placeId:
          getStr(o, 'location.place_id') || getStr(o, 'location.placeId'),
        state: getStr(o, 'location.state'),
        zipcode: getStr(o, 'location.zipcode'),
      },
      appointmentNumber: getStr(o, 'appointmentNumber'),
      isAppointment: getStr(o, 'isAppointment'),
      note: o.note ? o.note.trim() : '',
      tripNumber: i + 1,
    }

    let latest_date_time = ''
    if (!!o.ld || !!o.lt) {
      latest_date_time = `${
        o.ld
          ? dayjs(o.ld).format(databaseDateFormat)
          : dayjs().format(databaseDateFormat)
      } ${o.lt ? o.lt.concat(':00') : '00:00:00'}`
    }

    let earliest_date_time = ''
    if (!!o.ed || !!o.et) {
      earliest_date_time = `${
        o.ed
          ? dayjs(o.ed).format(databaseDateFormat)
          : dayjs().format(databaseDateFormat)
      } ${o.et ? o.et.concat(':00') : '00:00:00'}`
    }

    if (o.isAppointment === 1) {
      obj.latestTime = latest_date_time
      obj.earliestTime = latest_date_time
    } else {
      obj.latestTime = latest_date_time
      obj.earliestTime = earliest_date_time
    }
    if (o.tracking !== true) {
      if (!obj.latestTime) {
        delete obj.latestTime
      }
      if (!obj.earliestTime) {
        delete obj.earliestTime
      }
      if (!getStr(obj, 'location.address')) {
        delete obj.location
      }
    }
    return obj
  })

  return target
}

export const getErrorMessage = (err) =>
  _.get(err, 'response.data.messages', _.get(err, 'message')) || ''

export const getIdStringFromUrl = (url) => {
  if (!url) {
    return ''
  }
  url = url.replaceAll(' ', '')
  url = url.replaceAll('.', '')
  url = url.replaceAll('/', '')
  url = url.replaceAll('-', '')
  url = url.replaceAll('_', '')
  url = url.replaceAll('=', '')
  url = url.replaceAll('%', '')
  url = url.replaceAll('?', '')
  url = url.replaceAll('!', '')
  url = url.replaceAll(':', '')
  url = url.replaceAll('&', '')
  return url
}

export const download_files = (urlList) => {
  function download_next(i) {
    if (i >= urlList.length) {
      return
    }
    const a = document.createElement('a')
    a.href = urlList[i]
    a.target = '_parent'
    // Use a.download if available, it prevents plugins from opening.
    if ('download' in a) {
      a.download = urlList[i]
    }
    // Add a to the doc for click to work.
    ;(document.body || document.documentElement).appendChild(a)
    if (a.click) {
      a.click() // The click method is supported by most browsers.
    }
    // Delete the temporary link.
    a.parentNode.removeChild(a)
    // Download the next file with a small timeout. The timeout is necessary
    // for IE, which will otherwise only download the first file.
    setTimeout(() => {
      download_next(i + 1)
    }, 500)
  }
  // Initiate the first download.
  download_next(0)
}

export const start_and_end = (str, max = 43, end = 10) => {
  if (str === null || str === undefined || str === '') {
    return ''
  }
  if (str.length > max) {
    return `${str.substr(0, max - end - 3)}...${str.substr(
      str.length - end,
      str.length
    )}`
  }
  return str
}

export const start_and_end2 = (str, max = 43) => {
  if (str === null || str === undefined || str === '') {
    return ''
  }
  if (str.length > max) {
    return `${str.substr(0, max)}...`
  }
  return str
}

export const checkExpired = (url) => {
  const currentTimeStampt = Math.round(+new Date() / 1000)
  const index = url.indexOf('Expires=')
  const urlExpiredTimeStamp = url.substr(index + 8, 10)
  // check if before expired time 10s
  return currentTimeStampt >= urlExpiredTimeStamp - 10
}

export const removeVietnameseTones = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, '') // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ')
  str = str.trim()
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  )
  return str.toLowerCase()
}

export const getState = (addressArray) => {
  let state = ''
  if (!addressArray) {
    return state
  }
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        addressArray[i].types[0] === 'administrative_area_level_1'
      ) {
        state = addressArray[i].long_name
        return state
      }
    }
  }
  return state
}
export const getShortNameState = (addressArray) => {
  let state = ''
  if (!addressArray) {
    return state
  }
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        addressArray[i].types[0] === 'administrative_area_level_1'
      ) {
        state = addressArray[i].short_name
        return state
      }
    }
  }
  return state
}

export const getCity = (addressArray) => {
  let state = ''
  if (!addressArray) {
    return state
  }
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && addressArray[i].types[0] === 'locality') {
        state = addressArray[i].long_name
        return state
      }
    }
  }
  return state
}

export const getZipcode = (addressArray) => {
  let state = ''
  if (!addressArray) {
    return state
  }
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        addressArray[i].types[0] === 'postal_code'
      ) {
        state = addressArray[i].long_name
        return state
      }
    }
  }
  return state
}

export const phoneFormation = (inputValue) => {
  let result = ''
  const input = inputValue.replace(/\D/g, '').substring(0)
  const zip = input.substring(0, 3)
  const middle = input.substring(3, 6)
  const last = input.substring(6)
  if (input.length > 6) {
    result = `(${zip}) ${middle}-${last}`
  } else if (input.length > 3) {
    result = `(${zip}) ${middle}`
  } else if (input.length > 0) {
    result = `(${zip}`
  }
  return result
}

export const formatPhoneNumber = (phoneCode, phoneNumber) => {
  let returnedValue = ''
  if (phoneCode === '1' && !_.isEmpty(phoneNumber)) {
    returnedValue = `+${phoneCode} ${phoneNumber.slice(
      0,
      3
    )}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
    return returnedValue
  }
  if (phoneCode === '52' && !_.isEmpty(phoneNumber)) {
    returnedValue = `+${phoneCode} ${phoneNumber.slice(
      0,
      2
    )}-${phoneNumber.slice(2, 6)}-${phoneNumber.slice(6, 10)}`
    return returnedValue
  }
  return returnedValue
}
export const handleRedirect = (tokenData, history) => {
  console.log('aaa handleRedirect')
  if (tokenData.smarteta === 'true') {
    history.push('/page/loads')
  } else if (tokenData.document === 'true') {
    history.push('/page/documents')
  } else if (tokenData.document === 'false' && tokenData.smarteta === 'false') {
    if (tokenData.role === 'Staff') {
      history.push('/page/account/change-password')
    } else {
      history.push('/page/drivers')
    }
  }
  return null
}
export const handleLoginSuccess = async (dataResponse, history) => {
  console.log('aaa handleLoginSuccess ')
  const fullName = `${dataResponse.first_name} ${dataResponse.last_name}`
  localStorage.setItem('user_name', fullName)
  localStorage.setItem('company_name', dataResponse.company_name)
  localStorage.setItem('user', JSON.stringify(dataResponse))
  localStorage.setItem('loginsuccess', '1')
  handleRedirect(dataResponse, history)
}

export const handleLoginSuccessPortal = (
  setCookie,
  data,
  history,
  setUserName
) => {
  console.log('aaa handleLoginSuccessPortal ')
  setCookie('token', data.jwtAdminToken || '', {
    path: '/',
  })
  console.log(data)
  const fullName = `${data.first_name} ${data.last_name}`
  localStorage.setItem('user_name', fullName)
  setUserName(fullName)
  localStorage.setItem('company_name', data.company_name)
  const json = data
  if (json.smarteta === 'true') {
    json.smarteta = true
  }
  if (json.smarteta === 'false') {
    json.smarteta = false
  }
  if (json.document === 'true') {
    json.document = true
  }
  if (json.document === 'false') {
    json.document = false
  }

  delete json.jwtAdminToken
  localStorage.setItem('user', JSON.stringify(json))

  handleRedirect(json, history)
}

export const renderTimeMeta = (uploadTime) => {
  if (!uploadTime) {
    return ''
  }
  const dateOnlyUploadTime = dayjs(uploadTime).format('YYYY-MM-DD')
  const dateOnlyCurrentTime = dayjs().format('YYYY-MM-DD')
  const diff = differenceInDays(
    new Date(dateOnlyCurrentTime),
    new Date(dateOnlyUploadTime)
  )
  if (diff === 0) {
    return null
  }
  if (diff === 1) {
    return <Small className="ml8 mt2">Yesterday</Small>
  }
  return <Small className="ml8 mt2 Red">{diff} days ago</Small>
}

export const getSafeLink = (link) => link.replace('#', '%23222#')

export const renderSingleLink = (baselink, name, maxLength = 75) => {
  if (_.isEmpty(name)) {
    return null
  }
  return (
    <Link to={getSafeLink(`${baselink}${name}`)}>
      <span className="link">{`${formatReturnValue(name, maxLength)}`}</span>
    </Link>
  )
}

export const renderListLink = (arr, baselink, nameField, idField) => {
  if (_.isEmpty(arr)) {
    return null
  }
  return (
    <React.Fragment>
      {arr.map((item, index) => {
        let link = `${baselink}${item[nameField]}`
        link = getSafeLink(link)
        return (
          <React.Fragment key={`${item[idField]}_${index}`}>
            <Link to={link}>
              <span className="link">{item[nameField]}</span>
            </Link>
            {index !== arr.length - 1 && <span className="w9">{', '}</span>}
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

export const getAddressCityStateZipString = (address, city, state, zip) => {
  let arr = [address, city, state, zip]
  arr = _.compact(arr)
  return arr.join(', ')
}

export const renderDocumentTitle = (doc) => {
  function renderLoadNumber() {
    if (!doc.loadNumber) {
      return ''
    }
    return `Load# ${formatReturnValue(doc.loadNumber, 50)} - `
  }

  function renderStopName() {
    const stopName = getStr(doc, 'tripName')
    if (!stopName) {
      return ''
    }

    return `${formatReturnValue(stopName, 50)} - `
  }

  function renderDocumentTypeAndNumber() {
    return `${getStr(doc, 'documentType.name')}${
      doc.documentNumber ? ` #${formatReturnValue(doc.documentNumber, 50)}` : ''
    }`
  }

  return (
    <React.Fragment>
      {renderLoadNumber()}
      {renderStopName()}
      {renderDocumentTypeAndNumber()}
    </React.Fragment>
  )
}

export const generateDocumentObjectKey = (
  isLoadDocument,
  loadNumber,
  idCompany,
  documentTypeName,
  fileOriginalName,
  documentNumber
) => {
  const getTimeNow = function getTimeNow(dateFormat) {
    return moment.utc().format(dateFormat)
  }

  const documentCreatedDate = getTimeNow('MMDDYYHHmmssSSS')
  const loadDocumentCreatedDate = getTimeNow('MMDDYY')
  const loadNumberReq = loadNumber ? encodeURIComponent(loadNumber) : 'default'
  const documentNumberReq = documentNumber
    ? `-${encodeURIComponent(documentNumber)}`
    : ''
  const fileKey = isLoadDocument
    ? `${idCompany}/Load/${loadNumberReq}-${loadDocumentCreatedDate}/${documentTypeName}${documentNumberReq}-${documentCreatedDate}-${fileOriginalName}`
    : `${idCompany}/Non-Load/${documentTypeName}-${documentCreatedDate}-${fileOriginalName}`

  return fileKey
}

export const dataFromToken = (token) => {
  if (typeof window !== undefined && token) {
    const tokenData = jwtDecode(token)
    return {
      idRole: tokenData.id_role,
      hierarchy: tokenData.hierarchy,
      role: tokenData.role,
      user_name: `${tokenData.first_name} ${tokenData.last_name}`,
      company_name: tokenData.company_name,
      id_company: tokenData.id_company,
      idUser: tokenData.id_user,
      smarteta: tokenData.smarteta,
      document: tokenData.document,
    }
  }
  return null
}

// export const letWait = async miliseconds => {
//   const promise = new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve('success')
//     }, miliseconds)
//   })
// }

export const letWait = (time) => new Promise((res) => setTimeout(res, time))

export const removeCookieUtil = (key) => {
  cookies.remove(key)
}
export function getCookie(cname) {
  const name = `${cname}=`
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
export const isLoggedIn = () => {
  const token = getCookie('token')
  if (token) {
    return true
  }
  return false
}

export const getTimeOffset = () => {
  let strOffset = String(new Date().getTimezoneOffset() / 60)
  if (strOffset === '0') {
    return strOffset
  }
  if (strOffset.includes('-')) {
    strOffset = strOffset.replace('-', '+')
  } else {
    strOffset = `-${strOffset}`
  }
  return strOffset
}

const customToast = ({ time = 3000, type, msg }) => {
  const isSuccess = type === 'success'
  setTimeout(() => {
    const config = {
      icon: isSuccess
        ? () => <IconNotiSuccess className="no-hover-effect" />
        : () => <IconNotiFailed className="no-hover-effect" />,
      className: `toast-wr ${isSuccess ? 'toast-success' : 'toast-error'}`,
      bodyClassName: 'toast-body',
      containerId: document.getElementById('pspdfkit_wr')
        ? CONTAINER_ID_INSIDE_PDF_VIEWER
        : CONTAINER_ID_GLOBAL,
      autoClose: time,
      pauseOnHover: true,
    }
    isSuccess ? toast.success(msg, config) : toast.error(msg, config)
  }, 100)
}

export const toastSuccess = (msg) => {
  customToast({ msg, type: 'success' })
}

export const toastError = (msg = 'Something went wrong', time) => {
  customToast({ time, msg, type: 'error' })
}
;((_data) => {})([React])

export const toQueryString = ({ url, params }) => {
  return `${url}?${querystring.stringify(params, {
    skipEmptyString: true,
  })}`
}

export const validatePhone = ({ phone }) => {
  let error = ''

  const checkPhoneNumber = phone.charAt(0)
  const formattedNumber = formatToNumber(phone)

  if (phone && formattedNumber.length < 10) {
    error = 'Please enter a valid phone number'
  } else if (checkPhoneNumber === '0') {
    error = 'Phone number does not exist'
  }

  return { error, isValid: !error }
}

export const isEmptyTrimmed = (str) => {
  return !trim(str)
}

export const includesAll = ({ str, words }) => {
  return words.map(toLower).every((word) => toLower(str).includes(word))
}

export const createElementFromHTML = (htmlString) => {
  var div = document.createElement('div')
  div.innerHTML = htmlString.trim()

  // Change this to div.childNodes to support multiple top-level nodes.
  return div.firstChild
}

export const setupObserver = ({ targetNode, fn, fnWhenClickRemove }) => {
  const config = { attributes: true, childList: true, subtree: false }

  const callback = (mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        if (!isEmpty(mutation.addedNodes)) {
          fn({ addedNodes: mutation.addedNodes[0] })
        }
      } else if (mutation.type === 'attributes') {
        fnWhenClickRemove()
      }
    }
  }

  const observer = new MutationObserver(callback)

  observer.observe(targetNode, config)
}
