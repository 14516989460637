import React from 'react'

export const ArchivedBadge = () => {
  return (
    <React.Fragment>
      <button
        className="border border-DarkGray text-[12px] text-DarkGray leading-[150%] w-[64px] h-[24px]
        rounded-[4px] flex items-center justify-center cursor-auto min-w-max px-[8px]"
        style={{
          background:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #50565E',
        }}
      >
        Archived
      </button>
    </React.Fragment>
  )
}
;((_data) => {})([React])
