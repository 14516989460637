import React from 'react'

const IconDriver = ({ fill }) => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M1.33398 16.0007C1.33398 7.90047 7.90047 1.33398 16.0007 1.33398C24.1008 1.33398 30.6673 7.90047 30.6673 16.0007C30.6673 24.1008 24.1008 30.6673 16.0007 30.6673C7.90047 30.6673 1.33398 24.1008 1.33398 16.0007ZM4.07389 14.6673H9.46734C10.085 11.6243 12.7754 9.33398 16.0007 9.33398C19.2259 9.33398 21.9163 11.6243 22.534 14.6673H27.9274C27.2642 8.66738 22.1774 4.00065 16.0007 4.00065C9.82391 4.00065 4.73715 8.66738 4.07389 14.6673ZM27.9274 17.334H22.534C22.0037 19.9462 19.9462 22.0037 17.334 22.534V27.9274C22.8961 27.3125 27.3125 22.8961 27.9274 17.334ZM14.6673 27.9274V22.534C12.0551 22.0037 9.99758 19.9462 9.46734 17.334H4.07389C4.68875 22.8961 9.10516 27.3125 14.6673 27.9274ZM16.0007 12.0007C13.7915 12.0007 12.0007 13.7915 12.0007 16.0007C12.0007 18.2098 13.7915 20.0007 16.0007 20.0007C18.2098 20.0007 20.0007 18.2098 20.0007 16.0007C20.0007 13.7915 18.2098 12.0007 16.0007 12.0007Z"
      fill={fill || '#50565E'}
      fillRule="evenodd"
    />
  </svg>
)
export default IconDriver
;((_data) => {})([React])
