import { ArrowSort } from '@appComponent/icon'
import { SORT_ORDER } from '@constants'
import clsx from 'clsx'
import React from 'react'

export const ThWithSort = ({
  className,
  title,
  sortKeyword,
  lastSortInfo,
  onClick,
  sortList,
}) => {
  return (
    <th
      className={clsx(
        'pr-0 cursor-pointer pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%]',
        `${
          lastSortInfo.replace('-', '') === sortKeyword
            ? 'text-Primary'
            : 'text-DarkGray'
        }  `,
        className // w-[100px] 2xl:w-[126px]
      )}
      onClick={onClick}
    >
      <span className=" ">{title}</span>
      <span className="w-1" />
      <ArrowSort
        className={clsx(
          ' duration-300 transform ',
          {
            'rotate-180': sortList[sortKeyword] === SORT_ORDER.ASC,
            'rotate-0': sortList[sortKeyword] === SORT_ORDER.DESC,
          },
          `${
            lastSortInfo.replace('-', '') === sortKeyword
              ? 'fill-Primary'
              : 'fill-DarkGray'
          }`
        )}
      />
    </th>
  )
}
;((_data) => {})([React])
