import { useAtom } from 'jotai'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { currentStepOfFlow } from '../../atoms/atoms'
import { UikButton } from '../../components'
import cls from './common.scss'
import { H6 } from './typography'

export const ModalCongratulations = withRouter(({ history }) => {
  const [, setStep] = useAtom(currentStepOfFlow)
  const handleStart = () => {
    history.push('/page/users')
    setStep((pre) => pre + 1)
  }
  return (
    <div className=" fixed bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[628px] h-[260px] p-6">
      <H6>Congratulations!</H6>
      <div className="text-sm text-center mt-[22px] mb-[25px]">
        Your first driver is added! They will be receiving a text message from
        driverDOC to download the app. Setup is simple and takes drivers less
        than 5 minutes to complete!
      </div>
      <div className="text-sm text-center mb-6">
        Now, let's add a user from your office team so they can experience the
        benefits of driverDOC firsthand!
      </div>
      <div className="flex items-center justify-between">
        <div
          className="text-sm text-Blue hover:underline cursor-pointer"
          onClick={() => setStep((pre) => pre + 5)}
        >
          I’ll do this later
        </div>
        <UikButton className={cls.button__user} onClick={handleStart}>
          Add User
        </UikButton>
      </div>
    </div>
  )
})
;((_data) => {})([React])
