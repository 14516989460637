import {
  IconRequestDocument,
  IconUploadDocument,
  Plus,
  Upload,
} from '@appComponent/icon'
import classNames from 'classnames'
import React from 'react'
import cls from './button.scss'

const Black = '#50565e'
const DarkerBlue = '#023f62'
const Blue = 'rgba(0, 117, 255, 1)'
const Gray = '#b2b8c0'

export const Button = ({
  loading,
  children,
  stateClass,
  havePrefixIcon,
  onClick,
  iconType, // plus
  width,
  id,
  onKeyDown,
  className,
  style = {},
}) => {
  const [hover, setHover] = React.useState(false)
  const [focus, setFocus] = React.useState(false)

  const computeClass = () => {
    if (loading) {
      return cls.Disabled
    }
    if (stateClass === 'Disabled') {
      return cls.Disabled
    }
    if (hover === true || focus === true) {
      return cls.Hover
    }
    return cls[stateClass]
  }

  const renderPrefixIcon = () => {
    if (!havePrefixIcon) return null
    let fill = '#fff'
    const a1 = computeClass()
    switch (a1) {
      case cls.Primary:
        fill = '#fff'
        break
      case cls.Secondary:
        fill = Black
        break
      case cls.Link:
        fill = Blue
        break
      case cls.Outline:
        fill = DarkerBlue
        break
      case cls.Hover:
        fill = '#fff'
        break
      case cls.Disabled:
        fill = Gray
        break
      default:
        break
    }
    if (iconType === 'upload') {
      return (
        <span className={cls.wr_plus}>
          <Upload className="no-hover-effect" fill={fill} />
        </span>
      )
    }

    if (iconType === 'request_document') {
      return (
        <span className={cls.wr_plus}>
          <IconRequestDocument className="no-hover-effect" fill={fill} />
        </span>
      )
    }

    if (iconType === 'upload_document') {
      return (
        <span className={cls.wr_plus}>
          <IconUploadDocument className="no-hover-effect" fill={fill} />
        </span>
      )
    }

    return (
      <span className={cls.wr_plus}>
        <Plus className="no-hover-effect" fill={fill} />
      </span>
    )
  }

  return (
    <button
      className={classNames(cls.button, computeClass(), className)}
      id={id}
      onBlur={() => setFocus(false)}
      onClick={stateClass === 'Disabled' ? null : onClick}
      onFocus={() => setFocus(true)}
      // style={{ width }}
      onKeyDown={onKeyDown}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      style={{ ...style, ...{ width } }}
      tabIndex={stateClass === 'Disabled' ? '-1' : '0'}
    >
      {renderPrefixIcon()}
      {children}
    </button>
  )
}
;((_data) => {})([React])
