import { libraries, mapContainerStyle } from '@config'
import mapStyles from '@config/mapStyles'
import MarkerIconPng from '@driverDOCassets/marker_document_page.png'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from '@react-google-maps/api'
import React from 'react'
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import cls from './map.scss'

const Map = () => {
  const [lat] = useQueryParam('lat', withDefault(NumberParam, 0))
  const [lng] = useQueryParam('lng', withDefault(NumberParam, 0))
  const [address] = useQueryParam('address', withDefault(StringParam, ''))

  const [selectedTrip, setSelectedTrip] = React.useState(null)

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.RAZZLE_GOOGLE_MAP_API_KEY,
    libraries,
  })

  const mapRef = React.useRef()
  const onMapLoad = React.useCallback(
    (map) => {
      mapRef.current = map
    },
    [mapRef]
  )

  const renderMap = () => {
    if (loadError) return 'Error'
    if (!isLoaded) return ''
    return (
      <div className={cls.wr_map_page}>
        <GoogleMap
          center={{
            lat: Number(lat),
            lng: Number(lng),
          }}
          id="map"
          mapContainerStyle={mapContainerStyle}
          onLoad={onMapLoad}
          options={{
            styles: mapStyles,
            disableDefaultUI: false,
            mapTypeControl: false,
            draggable: true,
            scaleControl: true,
            scrollwheel: true,
            navigationControl: false,
            streetViewControl: false,
            zoom: 13,
          }}
        >
          <Marker
            icon={{
              url: MarkerIconPng,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, -3),
              scaledSize: new window.google.maps.Size(30, 30),
            }}
            onMouseOut={() => {
              setSelectedTrip(null)
            }}
            onMouseOver={() => {
              // setSelectedTrip(true)
            }}
            position={{
              lat: Number(lat),
              lng: Number(lng),
            }}
          />

          {selectedTrip && (
            <InfoWindow
              onCloseClick={() => {
                // setSelectedTrip(null)
              }}
              position={{
                lat: Number(lat),
                lng: Number(lng),
              }}
            >
              <div>
                <p> {address} </p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    )
  }
  return <div>{renderMap()}</div>
}

export default Map
;((_data) => {})([React])
