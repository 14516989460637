import { MyCheckbox } from '@appComponent/MyCheckbox'
import clsInput from '@appComponent/input/input.scss'
import { SpinnerIcon } from '@appComponent/spinnericon'
import { Caption } from '@appComponent/typography'
import { companyGroupsAtom, tokenDataAtom, userNameAtom } from '@atoms/atoms'
import {
  UikDropdownItem,
  UikTopBar,
  UikTopBarSection,
  UikTopBarTitle,
} from '@components'
import apiRoute from '@constants/apiRoute'
import { customAxios } from '@helper'
import { withContext } from '@hoc'
import { useClickOutside, useGroups, useScrollDirection } from '@hooks'
import { updateMyGroups } from '@service'
import classnames from 'classnames'
import produce from 'immer'
import { useAtom } from 'jotai'
import { get, isEmpty, size } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { TrialTimeNotice } from '../../@components/TrialTimeNotice'
import {
  ArrowSelect,
  IconGroup,
  LogoutIcon,
  MyAccountIcon,
} from '../../@components/icon'
import UikTopBarUserDropwdown from './UikTopBarUserDropdown'
import cls from './header.scss'

const DashboardHeader = ({ history, context }) => {
  const [tokenData] = useAtom(tokenDataAtom)

  const {
    groups,
    setGroups,
    checkedGroups,
    setCheckedGroups,
    listIdCheckedGroups,
    groupsCheckedString,
    loadinggroups,
    setLoadinggroups,
  } = useGroups()

  const [companyGroups, setCompanyGroups] = useAtom(companyGroupsAtom)
  const [userName, setUserName] = useAtom(userNameAtom)
  const hideSelectionRef = useRef(null)
  const [cookies, , removeCookie] = useCookies([
    'token',
    'accessToken',
    'user_name',
    'company_name',
  ])
  const [pos, setPos] = useState(0)
  const [showDropdown, setShowDropdown] = useState(false)
  const scrollDirection = useScrollDirection()
  const [emailUser, setEmailUser] = useState('')

  const ref = useRef(null)
  useClickOutside(ref, () => {
    setShowDropdown(false)
  })
  useEffect(() => {
    setUserName(localStorage.getItem('user_name'))
  }, [cookies.token])
  useEffect(() => {
    const getAllGroups = async () => {
      const handleData = (data) => {
        setGroups(data)
        if (localStorage.getItem('loginsuccess') === '1') {
          setCheckedGroups(data)
          localStorage.removeItem('loginsuccess')
        }
      }
      try {
        const resp = await customAxios(cookies.token).get(
          `${apiRoute.groups.LIST_MY_GROUP}`
        )

        handleData(
          (get(resp, 'data.data.groups') || []).map((o) => {
            o.idGroup = o.id_group
            return o
          })
        )
      } catch (err) {
        //
      }
    }

    const getActiveGroups = async () => {
      try {
        const resp = await customAxios(cookies.token).get(
          `${apiRoute.groups.GET_ACTIVE_GROUPS}?search=`
        )
        setCompanyGroups(
          (get(resp, 'data.data.groups') || []).map((o) => {
            o.idGroup = o.id_group
            return o
          })
        )
      } catch (err) {
        //
      }
    }
    getAllGroups()
    getActiveGroups()

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)
    let lastKnownScrollPosition = 0
    let ticking = false
    const handleScroll = () => {
      const doSomething = (lastKnownScrollPosition) => {
        setPos(lastKnownScrollPosition)
      }
      lastKnownScrollPosition = window.scrollY

      if (!ticking) {
        window.requestAnimationFrame(() => {
          doSomething(lastKnownScrollPosition)
          ticking = false
        })
        ticking = true
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleOnline = () => {
    context.message.success('Network Connected')
  }
  const handleOffline = () => {
    context.message.error('Network Disconnected')
  }

  const handleNavigateAccount = () => {
    hideSelectionRef.current.click()
    history.push('/page/account/change-password')
  }
  const handleLogout = () => {
    hideSelectionRef.current.click()
    removeCookie('token', { path: '/' })
    removeCookie('accessToken', { path: '/' })
    localStorage.clear()
    sessionStorage.clear()
    window.location = `${process.env.RAZZLE_APP_AUTH_DOMAIN_NAME}/logout?client_id=${process.env.RAZZLE_APP_CLIENT_ID}&logout_uri=${process.env.RAZZLE_APP_DOMAIN_NAME}`
  }

  const hide = {
    transform: 'translateY(-100%)',
  }
  const show = {
    transform: 'translateY(0)',
  }

  const handleGetContact = async () => {
    try {
      const { data: dataResponse } = await customAxios(cookies.token).get(
        `${apiRoute.Contact}`
      )
      if (dataResponse.success) {
        setEmailUser(dataResponse.data.email)
      }
    } catch (err) {}
  }

  const headerStyle = useMemo(() => {
    let target
    if (pos > 50 && !context.modal.isOpen && scrollDirection.scrollDown) {
      target = hide
    } else {
      target = show
    }
    return target
  }, [pos, scrollDirection, context.modal.isOpen])

  const renderListGroup = () => {
    // ///////////
    if (!showDropdown) {
      return null
    }

    if (loadinggroups) {
      return (
        <div
          className={cls.group_selection}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <SpinnerIcon />
        </div>
      )
    }

    if (isEmpty(groups)) {
      return (
        <div className={cls.no_group}>
          <Caption>No Group</Caption>
        </div>
      )
    }

    const handleClickCheckbox = (o) => {
      if (listIdCheckedGroups.includes(o.idGroup)) {
        const nextState = produce(checkedGroups, (draft) => {
          const index = draft.findIndex((item) => item.idGroup === o.idGroup)

          if (index !== -1) {
            draft.splice(index, 1)
          }
          return draft
        })
        setCheckedGroups(nextState)
      } else {
        const nextState = produce(checkedGroups, (draft) => {
          draft.push(o)
          return draft
        })
        setCheckedGroups(nextState)
      }
    }

    return (
      <div
        className={cls.group_selection}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {groups.map((o, index) => (
          <React.Fragment key={o.idGroup}>
            {index !== 0 && (
              <div
                className={cls.line}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
            )}
            <div
              className={cls.row_group}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <MyCheckbox
                checked={listIdCheckedGroups.includes(o.idGroup)}
                handleClickCheckbox={handleClickCheckbox.bind(null, o)}
              />

              <span className={cls.group_name}>{o.name}</span>
            </div>
          </React.Fragment>
        ))}
      </div>
    )

    // ///////////
  }

  return (
    <div
      className={cls.headerWrapper}
      id="driverDOC_header"
      style={headerStyle}
    >
      <TrialTimeNotice context={context} history={history} />
      <UikTopBar className={cls.top_bar} id="driverdoc__header">
        <UikTopBarSection className={cls.companyname}>
          {/* <UikTopBarMenuDivider className={cls.divider} /> */}
          <UikTopBarTitle className={cls.company_name}>
            {localStorage.getItem('company_name') || tokenData.company_name}
          </UikTopBarTitle>
        </UikTopBarSection>

        {size(companyGroups) >= 1 && !isEmpty(groups) ? (
          <div className={cls.wr_group_filter} ref={ref}>
            <div className={cls.icongroup}>
              <IconGroup />
            </div>
            <div
              className={classnames(cls.inputgroup, clsInput.input)}
              onClick={async () => {
                setShowDropdown(true)
                try {
                  updateMyGroups(
                    cookies.token,
                    setGroups,
                    setLoadinggroups,
                    checkedGroups,
                    setCheckedGroups
                  )
                } catch (err) {
                  //
                }
              }}
              tabIndex="0"
            >
              <Caption>{groupsCheckedString}</Caption>
              <div className={cls.arrowgroup}>
                <ArrowSelect />
              </div>
              {renderListGroup()}
            </div>
          </div>
        ) : (
          <div className={cls.wr_group_filter} ref={ref} />
        )}
        <UikTopBarSection className={cls.username} onClick={handleGetContact}>
          <span ref={hideSelectionRef} />
          <UikTopBarUserDropwdown>
            <div className={cls.itemDrop}>
              {' '}
              <div className={cls.name}>{userName}</div>
              <div className={cls.email}>{emailUser}</div>
            </div>
            <UikDropdownItem>
              <div className={cls.itemDropdown} onClick={handleNavigateAccount}>
                <span className={cls.mr_16}>
                  <MyAccountIcon />
                </span>
                <span>My Account</span>
              </div>
            </UikDropdownItem>
            <UikDropdownItem onClick={handleLogout}>
              <span className={cls.mr_16}>
                <LogoutIcon />
              </span>
              <span className={cls.itemDropdown}>Log Out</span>
            </UikDropdownItem>
          </UikTopBarUserDropwdown>
        </UikTopBarSection>
      </UikTopBar>
    </div>
  )
}

export default withContext(DashboardHeader)
;((_data) => {})([React])
