import { tokenDataAtom } from '@atoms/atoms'
import withAuth from '@auth'
import classnames from 'classnames'
import { useAtom } from 'jotai'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import route from '../../../../constants/route'
import IconAutomations from '../../../svg/IconAutomations'
import IconCompany from '../../../svg/IconCompany'
import IconStorage from '../../../svg/IconStorage'
import Automations from './Automations'
import CompanyProfile from './CompanyProfile/index'
import cls from './Preferences.scss'
import Storage from './Storage/index'

const Preferences = (props) => {
  const tabPreferences = [
    {
      iconActive: <IconCompany fill="#fff" />,
      icon: <IconCompany />,
      page: 'company-profile',
      content: 'Company Profile',
    },
    {
      icon: <IconStorage />,
      iconActive: <IconStorage fill="#fff" />,
      page: 'storage',
      content: 'Storage',
    },
    {
      icon: <IconAutomations />,
      iconActive: <IconAutomations fill="#fff" />,
      page: 'automations',
      content: 'Automations',
    },
  ]
  const [tokenData] = useAtom(tokenDataAtom)
  const { pathname } = props.location

  const pageName = pathname.split('/')[2]
  const handleClickTab = (page) => () => {
    props.history.push(`/page/${page}`)
  }
  return (
    <React.Fragment>
      <div className={cls.account_wrapper}>
        <div className={cls.tab_account_container}>
          <ul className={cls.tab_list}>
            {tabPreferences.map((item, index) => {
              if (
                tokenData.role === 'Manager' &&
                (item.content === 'Company Profile' ||
                  item.content === 'Automations')
              ) {
                return null
              }

              return (
                <li
                  className={classnames(cls.tab_account, {
                    [cls.active]: item.page === pageName,
                  })}
                  key={index}
                  onClick={handleClickTab(item.page)}
                >
                  <span
                    className={classnames(cls.item_tab, {
                      [cls.active]: item.page === pageName,
                    })}
                  >
                    <div className={classnames(cls.item_icon)}>
                      {item.page === pageName ? item.iconActive : item.icon}
                    </div>
                    <span className={classnames(cls.title_tab)}>
                      {item.content}
                    </span>
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
        <div className={cls.route_content}>
          <Switch>
            <Route
              component={CompanyProfile}
              exact
              path={route.PAGE.SUB_ROUTE.COMPANY}
            />
            <Route
              component={Storage}
              exact
              path={route.PAGE.SUB_ROUTE.STORAGE}
            />
            <Route
              component={Automations}
              exact
              path={route.PAGE.SUB_ROUTE.AUTOMATIONS}
            />
          </Switch>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withAuth(Preferences)
;((_data) => {})([React])
