// import Tutorials from './Tutorials'
// import Knowledge from './Knowledge'
// import Socialio from './Socialio'
// import Documentation from './Documentation'
// import Analytics from './Analytics'
// import AppDirectory from './AppDirectory'
// import Buildings from './Buildings'
import GetTokenForCreatingNewPassword from './GetTokenForCreatingNewPassword'
import Login from './Login'
import Page from './Page'
import ForgetPassword from './Reset_Forget/ForgetPassword'
import ResetPass from './Reset_Forget/Reset-Password'
import ResetPassword from './Reset_Forget/ResetPassword'
import { AccountLocked, SessionExpired } from './SessionExpired'
import AllSet from './SignUp/All-set'
import CreateAccount from './SignUp/CreateAccount'
import ErrorPage from './SignUp/Error-page'
import Verification from './SignUp/Verification'

const AsyncRouteMap = {
  Login,
  SessionExpired,
  AccountLocked,
  Page,
  ResetPassword,
  ForgetPassword,
  ResetPass,
  GetTokenForCreatingNewPassword,
  CreateAccount,
  Verification,
  AllSet,
  ErrorPage,
}

export default AsyncRouteMap
