import { Caption, H4 } from '@appComponent/typography'
import apiRoute from '@constants/apiRoute'
import { withContext } from '@hoc'
import { Progress } from 'antd'
import classnames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'

import { customAxios } from '@helper'

import _ from 'lodash'
import cls from './storage.scss'

const TOTAL = 1048576

const Storage = () => {
  const [cookies] = useCookies(['token'])
  const { token } = cookies
  const [mb, setMb] = useState(0)
  useEffect(() => {
    const getStorageInfo = async () => {
      try {
        const { data: dataResponse } = await customAxios(token).get(
          `${apiRoute.storage.STORAGE}`
        )
        if (dataResponse.success) {
          setMb(_.get(dataResponse, 'data.storage') || 0)
        }
      } catch (err) {}
    }
    getStorageInfo()
  }, [])

  const gb = useMemo(() => (mb / 1024).toFixed(2), [mb])

  const percent = useMemo(() => ((mb * 100) / TOTAL).toFixed(2), [mb])

  return (
    <div className={cls.container_company_profile}>
      <div className={cls.companyName}>Storage</div>
      <div className={cls.Company_form}>
        <div
          className={classnames(cls.wr_storage, 'wr_storage', {
            wr_storage_90_percent: percent >= 90,
            wr_storage_100_percent: percent > 100,
          })}
        >
          <div className={cls.storage_meta}>
            <H4>1 TB Storage</H4>
            <Caption>
              {gb} GB ({((mb * 100) / TOTAL).toFixed(2)}
              %) storage used
            </Caption>
          </div>
          <Progress
            percent={percent}
            showInfo={false}
            status={percent >= 90 ? 'exception' : ''}
          />
        </div>
      </div>
    </div>
  )
}

export default withContext(Storage)
;((_data) => {})([React])
