import { toast, Zoom } from 'react-toastify'
import mapStyles from './mapStyles'

export const IDLE_TIME = 1800000
export const IDLE_CHECK_INTERVAL = 10000
export const UPDATE_DASHBOARD_INTERVAL_TIME = 300000
export const PAGINATION_PAGE_SIZE_DEFAULT = 10
export const TIME_NO_LOCATION_LIMIT = 300
export const WAIT_TIME_LIMIT = 3600

export const libraries = ['places']
export const mapContainerStyle = {
  height: '100%',
  width: '100%',
  maxHeight: '100%',
}
export const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  gestureHandling: 'greedy',
}

export const americaViewPort = {
  center: {
    lat: 39.5,
    // lng: -98.7272,
    lng: -102.7272,
  },
  zoom: 5.2,
}

export const hanoiViewPort = {
  center: {
    lat: 21.028603086756,
    lng: 105.7808224999,
  },
  zoom: 18,
}

export const defaultPDFUrl =
  'https://s23.q4cdn.com/202968100/files/doc_downloads/test.pdf'

export const PDF_VIEWER_CONFIG = {
  defaultViewMode: 'FIT_PAGE',
  showAnnotationTools: false,
  dockPageControls: false,
  showDownloadPDF: false,
  showPrintPDF: false,
}

export const CONTAINER_ID_INSIDE_PDF_VIEWER = 'CONTAINER_ID_INSIDE_PDF_VIEWER'
export const CONTAINER_ID_GLOBAL = 'CONTAINER_ID_GLOBAL'
export const NOTIFICATION_TIME = '3000'

export const notificationOptions = {
  enableMultiContainer: true,
  position: toast.POSITION.TOP_CENTER,
  autoClose: NOTIFICATION_TIME,
  hideProgressBar: true,
  newestOnTop: false,
  rtl: false,
  pauseOnFocusLoss: true,
  transition: Zoom,
}

export const PSPDFKIT_LICENSE_KEY = process.env.RAZZLE_PSPDFKIT_LICENSE_KEY

export const DarkBlue = 'rgba(0, 99, 160, 1)'
export const DarkerBlue = '#023f62'
export const White = '#fff'
export const Black = '#50565e'
export const DisabledColor = '#B2B8C0'
export const Transparent = 'rgba(0,0,0,0)'
export const Red = '#ff212c'

export const generatePendo = (pendoKey) => {
  ;(function (apiKey) {
    ;(function (p, e, n, d, o) {
      var v, w, x, y, z
      o = p[d] = p[d] || {}
      o._q = o._q || []

      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push'](
                [m].concat([].slice.call(arguments, 0))
              )
            }
        })(v[w])

      y = e.createElement(n)
      y.async = !0
      y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js'

      z = e.getElementsByTagName(n)[0]
      z.parentNode.insertBefore(y, z)
    })(window, document, 'script', 'pendo')
  })(pendoKey)
}
