export const route = {
  LOGIN: '/',
  SESSION_EXPIRED: '/session-expired',
  ACCOUNT_LOCKED: '/account-locked',
  RESET_PASSWORD: '/reset-password',
  FORGET_PASSWORD: '/forget-Password',
  RESET: '/setPassword',
  CREATE_ACCOUNT: '/start',
  VERIFICATION: '/verification',
  ALL_SET: '/all-set',
  ERROR_PAGE: '/error-page',
  // GET_TOKEN_FOR_CREATING_NEW_PASSWORD: '/get_token_for_creating_new_password',
  GET_TOKEN_FOR_CREATING_NEW_PASSWORD: '/createNewPassword',
  PAGE: {
    BASE_ROOT: '/page',
    SUB_ROUTE: {
      DASHBOARD: '/page/dashboard',
      CUSTOMERS: '/page/customers',
      LOADSNEW: '/page/loads',
      DRIVERS: '/page/drivers',
      TRUCKS: '/page/trucks',
      ACCOUNT: '/page/account',
      VENDORS: '/page/vendors',
      DOCUMENTS: '/page/documents',
      COMPANY: '/page/company-profile',
      AUTOMATIONS: '/page/automations',
      STORAGE: '/page/storage',
      TRACKING: '/page/tracking',
      USERS: '/page/users',
      GROUPS: '/page/groups',
      YOUR_ACCOUNT_IS_DISABLED: '/page/your-account-is-disabled',
    },
  },
  MAP: '/maps',
}

export default route
