import React from 'react'
const IconTracking = ({ fill }) => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0001 1.33301C22.2593 1.33301 27.3334 6.40709 27.3334 12.6663C27.3334 16.6471 25.4964 20.6725 22.4251 24.6004C21.3351 25.9945 20.1497 27.2943 18.9352 28.4783C18.8352 28.5758 18.7368 28.6708 18.6402 28.7631L18.0829 29.2858L17.5742 29.7443L17.1199 30.1367L16.8424 30.3666C16.3555 30.7634 15.6576 30.7666 15.1672 30.3742L14.877 30.134L14.4248 29.7433L13.917 29.2856L13.36 28.7631L13.0649 28.4783C11.8505 27.2943 10.665 25.9945 9.57502 24.6004C6.50381 20.6725 4.66675 16.6471 4.66675 12.6663C4.66675 6.40709 9.74084 1.33301 16.0001 1.33301ZM16.0001 3.99967C11.2136 3.99967 7.33341 7.87985 7.33341 12.6663C7.33341 15.9412 8.93713 19.4553 11.6758 22.9579C12.6869 24.2511 13.7929 25.4638 14.9264 26.5688L15.4634 27.0825C15.6403 27.2485 15.8174 27.4147 16.0001 27.5743L16.5367 27.0825L17.0738 26.5688C18.2072 25.4638 19.3133 24.2511 20.3244 22.9579C23.063 19.4553 24.6667 15.9412 24.6667 12.6663C24.6667 7.87985 20.7866 3.99967 16.0001 3.99967ZM16.0001 7.33301C18.9456 7.33301 21.3334 9.72083 21.3334 12.6663C21.3334 15.6119 18.9456 17.9997 16.0001 17.9997C13.0546 17.9997 10.6667 15.6119 10.6667 12.6663C10.6667 9.72083 13.0546 7.33301 16.0001 7.33301ZM16.0001 9.99967C14.5273 9.99967 13.3334 11.1936 13.3334 12.6663C13.3334 14.1391 14.5273 15.333 16.0001 15.333C17.4728 15.333 18.6667 14.1391 18.6667 12.6663C18.6667 11.1936 17.4728 9.99967 16.0001 9.99967Z"
      fill={fill ? fill : '#50565E'}
    />
  </svg>
)
export default IconTracking
;((_data) => {})([React])
