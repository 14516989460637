import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import {
  ArrowSelect,
  DeleteSearch2,
  IconInfo14,
  IconMinusCircle32,
  IconPlusCircle32,
  IconX18,
} from '@appComponent/icon'
import { Input2, Textarea2 } from '@appComponent/input'
import clsInput from '@appComponent/input/input.scss'
import PdfViewerComponent from '@appComponent/pdf/PdfViewerComponent'
import { Small } from '@appComponent/typography'
import usePrevious from '@appComponent/usePrevious'
import { instancePdfAtom, tokenDataAtom } from '@atoms/atoms'
import apiRoute from '@constants/apiRoute'
import {
  customAxios,
  formatReturnValue,
  generateDocumentObjectKey,
  getErrorMessage,
  getStr,
  getTimeOffset,
} from '@helper'
import { useGroups } from '@hooks'
import { Drawer, Select, Tooltip } from 'antd'
import classnames from 'classnames'
import produce from 'immer'
import { useAtom } from 'jotai'
import _, { identity, isEmpty, isFunction, size } from 'lodash'
import moment from 'moment-timezone'
import useOnclickOutside from 'react-cool-onclickoutside'
import nextId from 'react-id-generator'
import { useDebounce } from 'use-debounce'
import { fetchCanCompleteStatus, getDocumentTypes } from '../../../../service'
import cls from './documents.scss'
import { Button } from '@@/App/@components/button'
import { SpinnerIcon } from '@@/App/@components/spinnericon'
import { ModalConfirmArchiveOrininalDocs } from './ModalConfirmArchiveOrininalDocs'
import ReactModal from '../../components/MyModal/ReactModal'
import { CAN_NOT_COMPLETE } from '@@/constants'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  InputSearchLoadNumberWhenUploadDoc,
  ItemLoadNumber,
} from '../../../@components/input'

const { Option } = Select

const isLoadDocumentSplit = (idDocumentTypeSelected, documentTypeList) => {
  if (!idDocumentTypeSelected) {
    return true
  }
  const find = documentTypeList.find(
    (o) => o.idDocumentType === idDocumentTypeSelected
  )
  if (find) {
    return find.loadDocument
  }
  return false
}

const isShowGroupFieldSplit = (
  idLoad,
  idDocumentTypeSelected,
  groups,
  documentTypeList
) => {
  if (_.isEmpty(documentTypeList)) {
    return false
  }
  const listIdNonLoadType = documentTypeList
    .filter((o) => o.loadDocument === false)
    .map((o) => o.idDocumentType)
  if (idLoad && !listIdNonLoadType.includes(idDocumentTypeSelected)) {
    return false
  }
  if (size(groups) <= 1) {
    return false
  }
  return true
}

const renderSelectGroup = (
  idLoad,
  idDocumentTypeSelected,
  groups,
  documentTypeList,
  idGroup,
  updateListSplit
) => {
  if (
    !isShowGroupFieldSplit(
      idLoad,
      idDocumentTypeSelected,
      groups,
      documentTypeList
    )
  ) {
    return null
  }
  return (
    <div className={cls.form_group}>
      <label htmlFor="inputupload_document_file">
        <Small>Group</Small>
      </label>
      <Select
        clearIcon={<DeleteSearch2 />}
        id="inputupload_document_type"
        onChange={(value) => {
          updateListSplit(value)
        }}
        style={{ width: '100%' }}
        suffixIcon={<ArrowSelect />}
        value={idGroup ? Number(idGroup) : undefined}
      >
        {groups.map((group) => (
          <Option key={group.idGroup} value={group.idGroup}>
            {group.name}
          </Option>
        ))}
      </Select>
    </div>
  )
}

const checkInputPagesInvalid = (str, maxPage) => {
  if (str.match(/^\s*-\s*/)) {
    return true
  }
  if (str.match(/^\s*,\s*/)) {
    return true
  }
  if (str.match(/^\s*,\s*\d+/)) {
    return true
  }
  if (str.match(/,\s*-/)) {
    return true
  }

  if (str.match(/,\s*,/)) {
    return true
  }

  if (str.match(/-\s*-/)) {
    return true
  }

  if (str.match(/-\s*,/)) {
    return true
  }
  if (str.match(/\d+\s*-\s*$/)) {
    return true
  }
  if (str.match(/^\s*-\s*\d+/)) {
    return true
  }
  if (str.match(/\s*\d+\s*-\s*\d+\s*-\s*\d+/)) {
    return true
  }
  if (str.match(/\s*\d+\s*-\s*\d+\s+\d+\s*/)) {
    return true
  }
  if (str.match(/\s*\d+\s+\d+\s*-\s*\d+\s*/)) {
    return true
  }

  let arr = str.split(',')
  arr = arr.map((o) => o.trim()).filter((o) => o !== '')

  if (arr.some((o) => o.match(/^\d+\s+\d+$/))) {
    return true
  }

  if (
    arr.some((o2) => {
      if (o2.includes('-')) {
        const index = o2.indexOf('-')
        const begin = o2.substr(0, index)
        const end = o2.substr(index + 1, 10000)

        if (Number(end) < Number(begin)) {
          return true
        }
      }
      return false
    })
  ) {
    return true
  }

  const matches = [...str.matchAll(/\d+/g)]
  if (matches.map((o) => o[0]).some((o) => o > maxPage)) {
    return true
  }

  return false

  // true mean invalid
}

const defaultSplitObject = {
  idDocumentTypeSelected: 0,
  documentNumber: '',
  idLoad: null,
  idTrip: null,
  loadNumber: '',
  documentFile: null,
  isConfirm: null,
  isChosen: false, // is chosen load number
  value: '', // value load number field
  note: '',
  fetchingLoad: false,
  pages: '',
  loads: [],
  canComplete: undefined,
  confirmComplete: undefined,
  idGroup: undefined,
}

const DrawerEditDocument = identity(
  ({
    listIdDocumentForEdit,
    setListIdDocumentForEdit,
    listDocumentSelected,
    setListDocumentSelected,
    setListRecentlyEdittedIdDoc,
    listIdDocumentSelected,
    context,
    set_time_request,
    token,
    location,

    tabDocument,
  }) => {
    const [tokenData] = useAtom(tokenDataAtom)
    const [instancePdf] = useAtom(instancePdfAtom)

    const [isInEditorMode, setIsInEditorMode] = useState(false)
    const [isInCropMode, setIsInCropMode] = useState(false)
    const [url, setUrl] = useState('')
    const { listIdCheckedGroups, groups } = useGroups()
    const [isChosen, setIsChosen] = useState(true)

    const [isMixed, setIsMixed] = useState(false)
    const isMixedPrev = usePrevious(isMixed)

    const [isProcessingDocument, setIsProcessingDocument] = useState(false)
    const [documentFile, setDocumentFile] = useState(undefined)
    const [isDocumentFileChanged, setIsDocumentFileChanged] = useState(false)
    const [status, setStatus] = useState('')
    const [idLoad, setIdLoad] = useState(null)
    const [idTrip, setIdTrip] = useState(null)
    const [documentTypeList, setDocumentTypeList] = useState([])
    const [idDocumentTypeSelected, setIdDocumentTypeSelected] =
      useState(undefined)
    const [documentNumber, setDocumentNumber] = useState('')
    const [originalFilename, setOriginalFilename] = useState('')
    const [loadNumber, setLoadNumber] = useState(undefined)

    const [value, setValue] = useState('')

    const [savingOrDispatching, setSavingOrDispatching] = useState(false)

    const [confirmComplete, setConfirmComplete] = useState(undefined)

    const [idGroup, setIdGroup] = useState(undefined)

    // / split state
    const [isSplitMode, setIsSplitMode] = useState(false)
    const [dataSplitArr, setDataSplitArr] = useState([
      { ...defaultSplitObject, uniqueid: nextId() },
      { ...defaultSplitObject, uniqueid: nextId() },
    ])

    const [dataSplitArrUpdateCount, setDataSplitArrUpdateCount] = useState(0)
    const [arrConfirmCompleteModal, setArrConfirmCompleteModal] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [
      isOpenModalConfirmArchiveOriginalDocs,
      setIsOpenModalConfirmArchiveOriginalDocs,
    ] = useState(false)
    const isArchiveOriginalDocsRef = useRef(null)

    const arrErrorInputPageSplit = useMemo(() => {
      if (!instancePdf) {
        return [true]
      }

      return dataSplitArr.map((o) =>
        checkInputPagesInvalid(o.pages, instancePdf.totalPageCount)
      )
    }, [dataSplitArr, _.get(instancePdf, 'totalPageCount')])

    useEffect(() => {
      setDataSplitArrUpdateCount(dataSplitArrUpdateCount + 1)
    }, [dataSplitArr])

    useEffect(() => {
      if (isSplitMode && dataSplitArrUpdateCount === 1) {
        // fill the infor of the original doc
        // aaa

        updateListSplit(dataSplitArr[0].uniqueid, {
          idDocumentTypeSelected,
          documentNumber,
          value,
          idLoad,
          idTrip,
          idGroup,
          isChosen,
          loadNumber,
          pages:
            instancePdf.totalPageCount === 1
              ? '1'
              : `1-${instancePdf.totalPageCount}`,
        })
      }
    }, [isSplitMode, dataSplitArrUpdateCount])

    const arrPageSplit = useMemo(() => {
      if (arrErrorInputPageSplit.includes(true)) {
        return [[]]
      }

      const arr = dataSplitArr.map((o) =>
        o.pages
          .split(',')
          .map((o) => o.replaceAll(' ', ''))
          .filter((o) => o !== '')
      )

      const target = []
      arr.forEach((o1) => {
        if (_.isEmpty(o1)) {
          target.push(o1)
        } else {
          const target2 = []
          o1.forEach((o2) => {
            if (o2.includes('-')) {
              const index = o2.indexOf('-')
              const begin = o2.substr(0, index)
              const end = o2.substr(index + 1, 10000)

              for (let i = Number(begin); i <= Number(end); i++) {
                target2.push(i)
              }
            } else {
              target2.push(o2)
            }
          })

          target.push(target2)
        }
      })

      // let target1 = arr.map(o => {
      //   return o.map(item => {
      //     if (_.isEmpty(item)) {
      //       return []
      //     }
      //     let result = []
      //     if (item.includes('-')) {
      //       let index = item.indexOf('-')
      //       let begin = item.substr(0, index)
      //       let end = item.substr(index + 1, 10000)

      //       for (let i = begin; i <= end; i++) {
      //         result.push(i)
      //       }
      //     } else {
      //       result.push(o)
      //     }
      //     return result
      //   })
      // })
      return target
    }, [dataSplitArr, arrErrorInputPageSplit])

    const updateListSplit = (uniqueid, field, value) => {
      // console.log('aaa 11', { uniqueid, field, value })

      if (typeof field === 'string') {
        const nextState = produce(dataSplitArr, (draft) => {
          const find = draft.findIndex((o) => o.uniqueid === uniqueid)

          if (find === -1) {
            return
          }

          draft[find][field] = value
          return draft
        })

        console.log('aaa 11', { nextState })
        setDataSplitArr(nextState)
      } else {
        const nextState = produce(dataSplitArr, (draft) => {
          const find = draft.findIndex((o) => o.uniqueid === uniqueid)

          if (find === -1) {
            return
          }
          for (const property in field) {
            draft[find][property] = field[property]
          }
          return draft
        })

        console.log('aaa 11', { nextState })
        setDataSplitArr(nextState)
      }
    }

    const handleAddSplit = () => {
      const nextState = produce(dataSplitArr, (draft) => {
        draft.push({ ...defaultSplitObject, uniqueid: nextId() })
      })
      setDataSplitArr(nextState)
    }
    const handleAddSplitAtIndex = (index) => {
      const nextState = produce(dataSplitArr, (draft) => {
        draft.splice(index + 1, 0, {
          ...defaultSplitObject,
          uniqueid: nextId(),
        })
      })
      setDataSplitArr(nextState)

      setTimeout(() => {
        const el = document.getElementById(`inputsplittype-${index + 1}`)
        if (el) {
          el.focus()
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }, 100)
    }

    const handleDeleteSplitByIndex = (index) => {
      const nextState = produce(dataSplitArr, (draft) => {
        draft.splice(index, 1)
      })
      setDataSplitArr(nextState)
    }

    const showEditDoc = useMemo(
      () => _.size(listIdDocumentForEdit) >= 1,
      [listIdDocumentForEdit]
    )

    const showEditDocPrev = usePrevious(showEditDoc)

    // const isLoadDocument = useMemo(() => {
    //   if (
    //     _.isEmpty(documentTypeList) ||
    //     !idDocumentTypeSelected ||
    //     idDocumentTypeSelected === 'Mixed'
    //   ) {
    //     return true
    //   }
    //   let find = documentTypeList.find(o => {
    //     return o.idDocumentType === idDocumentTypeSelected
    //   })
    //   if (find) {
    //     return find.loadDocument
    //   } else {
    //     return false
    //   }
    // }, [idDocumentTypeSelected, documentTypeList])

    const isLoadDocument = useMemo(() => {
      if (tabDocument === 2 && idDocumentTypeSelected === 'Mixed') {
        return false
      }

      if (tabDocument === 1 && idDocumentTypeSelected === 'Mixed') {
        return true
      }

      const find = documentTypeList.find(
        (o) => o.idDocumentType === idDocumentTypeSelected
      )
      if (find) {
        return find.loadDocument
      }
      return false
    }, [idDocumentTypeSelected, documentTypeList, tabDocument])

    const onClose = () => {
      // set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
      setListIdDocumentForEdit([])
    }

    const resetFields = () => {
      console.log('aaa resetFields ')
      setDocumentFile(undefined)
      setIdDocumentTypeSelected(undefined)
      setIdGroup(undefined)
      setValue('')
      setIsChosen(false)
      setIdLoad(null)
      setIdTrip(null)
      setStatus('')
      setDocumentNumber('')
      // reset scroll to top
      const antDrawerBody = Array.from(
        document.querySelectorAll('.wr_drawer_upload_doc .ant-drawer-body')
      )[0]
      if (antDrawerBody) {
        antDrawerBody.scrollTop = 0
      }
    }

    const getActiveLoadsSplit = async (docsplit, text) => {
      try {
        updateListSplit(docsplit.uniqueid, 'fetchingLoad', true)
        const resp = await customAxios(token).get(
          `${
            apiRoute.documents.GET_LOADS
          }?search=${text}&groups=${listIdCheckedGroups.join(',')}`
        )
        if (
          docsplit.isChosen === true &&
          _.get(resp, 'data.data.length') === 1
        ) {
          updateListSplit(
            docsplit.uniqueid,
            'canComplete',
            _.get(resp, 'data.data[0].canComplete')
          )
        }
        const data = []

        resp.data.data.forEach(
          ({ id_load, load_number, id_trip, trip_name }) => {
            data.push({
              id_load,
              load_number,
              id_trip,
              trip_name,
            })
          }
        )

        updateListSplit(docsplit.uniqueid, 'loads', data)
      } catch (err) {
      } finally {
        updateListSplit(docsplit.uniqueid, 'fetchingLoad', false)
      }
    }

    const handleClickSaveEdit = async () => {
      setSavingOrDispatching(true)

      const { canComplete } = await fetchCanCompleteStatus({
        customAxios,
        idLoad,
        listIdCheckedGroups,
        status,
      })

      if (canComplete) {
        setConfirmComplete(true)
      } else {
        saveDoc(CAN_NOT_COMPLETE)
      }
    }

    const handleClickSaveSplitPhase1 = async () => {
      try {
        setSavingOrDispatching(true)

        const arrIdLoad = _.chain(dataSplitArr)
          .map((o) => o.idLoad)
          .compact()
          .map((o) => String(o))
          .uniq()
          .value()

        if (_.isEmpty(arrIdLoad)) {
          handleClickSaveSplitPhase2(dataSplitArr)
          return
        }

        const url = `${apiRoute.loads.CAN_COMPLETE}?idLoads=${arrIdLoad.join(
          ','
        )}&groups=${listIdCheckedGroups.join(',')}`

        const respCanComplete = await customAxios(token).get(url)

        const arrCanComplete = (
          _.get(respCanComplete, 'data.data') || []
        ).filter((o) => o.canComplete === true)
        // [{idLoad: 4472, loadNumber: 'Test002', canComplete: true}]

        if (_.isEmpty(arrCanComplete)) {
          handleClickSaveSplitPhase2(dataSplitArr)
          return
        }
        setArrConfirmCompleteModal(_.reverse(arrCanComplete))
        return
      } catch (err) {
        context.message.error('Unable to split document. Try again.')
        handleClickSaveSplitPhase2(dataSplitArr)
        setSavingOrDispatching(false)
      }
    }

    const handleClickSaveSplitPhase2 = async (dataSplitArr) => {
      try {
        const { totalPageCount } = instancePdf
        const arrPage = Array.from(Array(totalPageCount).keys())
        const arr777 = []
        arrPageSplit.forEach((o) => {
          //
          const arr = o.map((o2) => Number(o2) - 1)
          const arrRemovePage = arrPage.filter((o) => !arr.includes(o))

          arr777.push(arrRemovePage)
        })

        const arrBlob = []
        const arrObject = []
        for (const arrIndex of arr777) {
          let file
          if (_.isEmpty(arrIndex)) {
            file = await instancePdf.exportPDF()
          } else {
            file = await instancePdf.exportPDFWithOperations([
              {
                type: 'removePages',
                pageIndexes: arrIndex,
              },
            ])
          }

          const blob = new Blob([file], {
            type: 'application/pdf',
          })

          const objectUrl = URL.createObjectURL(
            new Blob([file], {
              type: 'application/pdf',
            })
          )

          arrBlob.push(blob)
          arrObject.push(objectUrl)
        }

        const objectKeyList = dataSplitArr.map((o, index) => {
          const isLoadDocument1 = isLoadDocumentSplit(
            o.idDocumentTypeSelected,
            documentTypeList
          )
          const documentTypeName =
            _.get(
              documentTypeList.find(
                (o2) =>
                  Number(o2.idDocumentType) === Number(o.idDocumentTypeSelected)
              ),
              'name'
            ) || ''

          return generateDocumentObjectKey(
            isLoadDocument1,
            o.idLoad ? o.loadNumber : o.value,
            tokenData.id_company,
            documentTypeName,
            `DriverDocsPDF${dayjs()
              .add(index, 'seconds')
              .format('YYYY.MM.DD_HH.mm.ss')}.pdf`,
            o.documentNumber
          )
        })

        const respPresignedUrl = await customAxios(token).post(
          `${
            apiRoute.documents.GET_PRESIGNED_URL
          }?groups=${listIdCheckedGroups.join(',')}`,
          {
            objectKeyList,
          }
        )

        if (!respPresignedUrl.data.success) {
          return
        }
        const arrPresignUrl = _.get(respPresignedUrl, 'data.data.presignedUrls')

        const arrPromiseUploadS3 = []

        for (let i = 0; i < arrPresignUrl.length; i++) {
          const { presignedUrl } = arrPresignUrl[i]
          const blob = arrBlob[i]

          const promise = axios.put(presignedUrl, blob, {
            headers: {
              'Content-Type': blob.type,
            },
          })

          arrPromiseUploadS3.push(promise)
          // if (i === 1) {
          //   let promiseFail = axios.put(`https://www.google.com/`, blob, {
          //     headers: {
          //       'Content-Type': blob.type,
          //     },
          //   })
          //   arrPromiseUploadS3.push(promiseFail)
          // }
        }

        try {
          await Promise.all(arrPromiseUploadS3)
        } catch (err) {
          // failed to upload all file
          context.message.error('Unable to split document. Try again.')

          await customAxios(token).put(
            `${
              apiRoute.documents.DELETE_DOCUMENT_FILE
            }?groups=${listIdCheckedGroups.join(',')}`,

            {
              objectKeyList: arrPresignUrl.map((o) => o.objectKey),
            }
          )

          return
        } finally {
        }

        const splitDocumentData = []

        for (let i = 0; i < dataSplitArr.length; i++) {
          const docsplit = dataSplitArr[i]

          const data = {
            // idLoad: '1',
            // documentNumber: '',
            // loadNumber: '',
            // idGroup: '1',
            idDocumentType: String(docsplit.idDocumentTypeSelected),
            location: {
              latitude: location.lat,
              longitude: location.long,
            },
            note: docsplit.note,
            objectKey: objectKeyList[i],
            timeOffset: Number(getTimeOffset()),
          }

          if (docsplit.isConfirm !== null) {
            data.isConfirm = docsplit.isConfirm
          }

          if (
            isLoadDocumentSplit(
              docsplit.idDocumentTypeSelected,
              documentTypeList
            )
          ) {
            data.documentNumber = docsplit.documentNumber
            if (docsplit.idLoad) {
              data.idLoad = String(docsplit.idLoad)
            } else {
              data.loadNumber = docsplit.value ? String(docsplit.value) : ''
              if (size(groups) === 0) {
                //
              } else if (size(groups) === 1) {
                data.idGroup = String(groups[0].idGroup)
              } else {
                data.idGroup = String(docsplit.idGroup)
              }
            }

            if (docsplit.idTrip) {
              data.idTrip = String(docsplit.idTrip)
            }
          } else if (size(groups) === 0) {
            //
          } else if (size(groups) === 1) {
            data.idGroup = String(groups[0].idGroup)
          } else {
            data.idGroup = String(docsplit.idGroup)
          }

          splitDocumentData.push(data)
        }

        await customAxios(token).post(
          `${
            apiRoute.documents.SPLIT_DOCUMENT
          }?groups=${listIdCheckedGroups.join(',')}&isArchiveOriginalDoc=${
            isArchiveOriginalDocsRef.current === true ? 'true' : 'false'
          }`,
          {
            idDocument: listIdDocumentForEdit[0],
            splitDocumentData,
          }
        )

        context.message.success('Document splitted successfully')
        setListDocumentSelected([])
        set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
        setListIdDocumentForEdit([])
        resetFields()
      } catch (err) {
        context.message.error(getErrorMessage(err))
      } finally {
        setTimeout(() => {
          setSavingOrDispatching(false)
        }, 3888)
      }
    }

    // saveDoc

    const saveDoc = async (confirm) => {
      const updateUrls = async (listIdDocument, removedId) => {
        // get new Url
        try {
          const resp2 = await customAxios(token).post(
            `${
              apiRoute.documents.GET_URLS_BY_DOCUMENT_IDS
            }?groups=${listIdCheckedGroups.join(',')}`,
            {
              listIdDocument,
            }
          )
          if (resp2.data.success !== true) {
            return
          }

          const nextState = produce(listDocumentSelected, (draft) => {
            if (removedId) {
              const index = draft.findIndex(
                (doc) => doc.idDocument === removedId
              )
              if (index !== -1) {
                draft.splice(index, 1)
              }
            }
            for (const doc of resp2.data.data) {
              const index = draft.findIndex(
                (o) => o.idDocument === doc.idDocument
              )
              if (index !== -1) {
                draft[index].url = doc.url
              }
            }
          })
          setListDocumentSelected(nextState)
        } catch (err) {
          console.log(err)
        }
      }

      try {
        setSavingOrDispatching(true)

        if (_.size(listIdDocumentForEdit) === 1) {
          // edit single Doc
          const formData = new FormData()
          formData.append('idDocumentType', idDocumentTypeSelected)
          if (isDocumentFileChanged) {
            formData.append('documentFile', documentFile, originalFilename)
          }
          if (isLoadDocument) {
            formData.append('documentNumber', documentNumber)

            if (idLoad) {
              formData.append('idLoad', idLoad)
            } else {
              formData.append('loadNumber', value)
              if (size(groups) === 0) {
                //
              } else if (size(groups) === 1) {
                formData.append('idGroup', groups[0].idGroup)
              } else {
                formData.append('idGroup', idGroup)
              }
            }

            if (idTrip) {
              formData.append('idTrip', idTrip)
            }
          } else if (size(groups) === 0) {
            //
          } else if (size(groups) === 1) {
            formData.append('idGroup', groups[0].idGroup)
          } else {
            formData.append('idGroup', idGroup)
          }

          if (confirm !== CAN_NOT_COMPLETE) {
            formData.append('isConfirm', confirm)
          }

          const idSingle = listIdDocumentForEdit[0]
          setIsLoading(true)
          const resp = await customAxios(token).put(
            `${
              apiRoute.documents.EDIT_DOCUMENT
            }/${idSingle}?groups=${listIdCheckedGroups.join(',')}`,
            formData
          )
          setIsLoading(false)

          if (!resp.data.success) {
            context.message.error('Updating Document Failed!')
            return
          }
          // clear selection if update from non-load to load, or from load to non-load
          if (
            (isLoadDocument && tabDocument === 2) ||
            (!isLoadDocument && tabDocument === 1)
          ) {
            const nextState = produce(listDocumentSelected, (draft) => {
              const index = draft.findIndex(
                (doc) => doc.idDocument === idSingle
              )
              if (index !== -1) {
                draft.splice(index, 1)
              }
            })
            setListDocumentSelected(nextState)
            updateUrls([idSingle], idSingle)
          } else {
            // when not clear, update urls
            updateUrls([idSingle])
          }
          setListRecentlyEdittedIdDoc([idSingle])
          set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
          context.message.success('Edited Successfully')
          setListIdDocumentForEdit([])
          resetFields()
          updateUrls([idSingle])
          // get new Url
        } else {
          // edit multiple docs
          const getObj = (property) => {
            const obj = { mixed: property === 'Mixed' }
            if (property !== 'Mixed') {
              obj.value = property
            }
            return obj
          }

          const body = {
            listIdDocument: listIdDocumentSelected,
            idDocumentType: getObj(idDocumentTypeSelected),
          }

          if (isLoadDocument) {
            body.documentNumber = getObj(documentNumber)
            if (idLoad) {
              body.idLoad = idLoad
            } else {
              body.loadNumber = getObj(value)

              // handle idGroup
              if (isMixed) {
                if (size(groups) === 0) {
                  //
                } else if (idGroup === 'Mixed') {
                } else {
                  body.idGroup = Number(idGroup)
                }
              } else if (size(groups) === 0) {
                //
              } else if (size(groups) === 1) {
                body.idGroup = groups[0].idGroup
              } else {
                body.idGroup = idGroup
              }
            }

            if (idTrip) {
              body.idTrip = idTrip
            }
          } else {
            // handle idGroup
            if (isMixed) {
              if (size(groups) === 0) {
                //
              } else if (idGroup === 'Mixed') {
              } else {
                body.idGroup = Number(idGroup)
              }
            } else if (size(groups) === 0) {
              //
            } else if (size(groups) === 1) {
              body.idGroup = groups[0].idGroup
            } else {
              body.idGroup = idGroup
            }
          }
          if (confirm !== -1) {
            body.isConfirm = confirm
          }
          const resp = await customAxios(token).put(
            `${
              apiRoute.documents.EDIT_DOCUMENTS
            }?groups=${listIdCheckedGroups.join(',')}`,
            body
          )
          if (!resp.data.success) {
            context.message.error('Updating Document Failed!')
            return
          }
          // clear selection if update from non-load to load, or from load to non-load
          if (
            idDocumentTypeSelected !== 'Mixed' &&
            ((isLoadDocument && tabDocument === 2) ||
              (!isLoadDocument && tabDocument === 1))
          ) {
            setListDocumentSelected([])
          } else {
            // when not clear, update urls
            updateUrls(listIdDocumentSelected)
          }
          setListRecentlyEdittedIdDoc(listIdDocumentSelected)
          set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
          context.message.success('Edited Successfully')
          setListIdDocumentForEdit([])
          resetFields()
          updateUrls(listIdDocumentSelected)
        }
      } catch (err) {
        context.message.error(getErrorMessage(err))
      } finally {
        setConfirmComplete(false)
        setTimeout(() => {
          setSavingOrDispatching(false)
          setIsLoading(false)
        }, 3888)
      }
    }
    // saveDoc end

    const isShowGroupField = useMemo(() => {
      if (_.isEmpty(documentTypeList)) {
        return false
      }

      const listIdNonLoadType = documentTypeList
        .filter((o) => o.loadDocument === false)
        .map((o) => o.idDocumentType)

      if (idLoad && !listIdNonLoadType.includes(idDocumentTypeSelected)) {
        return false
      }
      if (size(groups) === 0) {
        return false
      }

      if (size(groups) === 1 && !isMixed) {
        return false
      }

      return true
    }, [idLoad, idDocumentTypeSelected, groups, value, documentTypeList])

    const isDisable = useMemo(() => {
      if (isInEditorMode || isInCropMode) {
        return true
      }

      if (isSplitMode) {
        if (dataSplitArr.map((o) => o.idDocumentTypeSelected).some((o) => !o)) {
          return true
        }

        if (
          dataSplitArr.some(
            (o) =>
              isShowGroupFieldSplit(
                o.idLoad,
                o.idDocumentTypeSelected,
                groups,
                documentTypeList
              ) && !o.idGroup
          )
        ) {
          return true
        }

        if (
          arrErrorInputPageSplit.some((o) => o === true) ||
          arrPageSplit.some((o) => _.isEmpty(o))
        ) {
          return true
        }

        return false
      }
      if (!idDocumentTypeSelected) {
        return true
      }
      if (isShowGroupField && !idGroup) {
        return true
      }

      if (!isMixed && documentFile === undefined) {
        return true
      }

      return false
    }, [
      idDocumentTypeSelected,
      idGroup,
      isShowGroupField,
      documentFile,
      isMixed,
      isInEditorMode,
      isInCropMode,
      isSplitMode,
      dataSplitArr,
      groups,
      documentTypeList,
    ])

    const hideSplitIcon = useMemo(
      () => status !== 'Success' && status !== 'Archive',
      [status]
    )

    useEffect(() => {
      async function getDocumentById(id) {
        try {
          // setListDocuments([])
          const urlGet = `${
            apiRoute.documents.DOCUMENT_BY_ID
          }/${id}?groups=${listIdCheckedGroups.join(',')}`
          const { data: dataResponse } = await customAxios(token).get(urlGet)
          if (!dataResponse.success) return
          // TODO
          // setUrl(getStr(dataResponse, 'data.url'))

          const urlData = dataResponse.data.url
          setUrl(urlData)
          setIdLoad(dataResponse.data.idLoad)
          setStatus(dataResponse.data.status)
          const tripName = _.get(dataResponse, 'data.tripName')
          if (_.get(dataResponse, 'data.tripName')) {
            setValue(`${getStr(dataResponse, 'data.loadNumber')} - ${tripName}`)
          } else {
            setValue(getStr(dataResponse, 'data.loadNumber'))
          }
          setIdDocumentTypeSelected(dataResponse.data.idDocumentType)
          const docNumber = getStr(dataResponse, 'data.documentNumber')
          setDocumentNumber(docNumber)

          if (urlData) {
            const getOriginalNameFromUrl = (str) => {
              // original file name
              const regexp = /-\d{15}-/g
              const matches = [...str.matchAll(regexp)]
              const last = _.last(matches)
              const { index } = last
              const index2 = str.indexOf('?AWSAccessKeyId')
              const filename = str.substr(index + 17, index2 - index - 17)
              const target =
                filename.indexOf('.pdf') === -1 ? 'document.pdf' : filename
              return target
            }

            setOriginalFilename(getOriginalNameFromUrl(urlData))
          }

          setLoadNumber(dataResponse.data.loadNumber)
          setIdGroup(dataResponse.data.idGroup)
          setIsChosen(!!dataResponse.data.loadNumber)
          setIdTrip(dataResponse.data.idTrip)
        } catch (err) {
          console.log(err)
        }
      }

      if (_.size(listIdDocumentForEdit) === 1) {
        getDocumentById(listIdDocumentForEdit[0])
      }

      getDocumentTypes(token, setDocumentTypeList)
    }, [listIdDocumentForEdit])

    useEffect(() => {
      if (_.size(listIdDocumentForEdit) === 1) {
        setIsMixed(false)
      } else {
        setIsMixed(true)
        setIsChosen(false)
      }
    }, [listIdDocumentForEdit])

    useEffect(() => {
      if (isMixed === true && isMixedPrev !== true) {
        setIdDocumentTypeSelected('Mixed')
        setDocumentNumber('Mixed')
        setValue('Mixed')
        setIdGroup('Mixed')
      }
    }, [isMixed, isMixedPrev])

    useEffect(() => {
      // if ('undefined' != typeof window) {
      //   window.onbeforeunload = function() {
      //     return 'Dude, are you sure you want to leave? Think of the kittens!'
      //   }
      // }

      const timeout = setTimeout(() => {
        const el = document.querySelectorAll(
          '.wr_drawer_upload_doc .ant-drawer-body input'
        )[0]
        if (el) {
          el.focus()
        }
      }, 200)

      return () => clearTimeout(timeout)
    }, [])

    useEffect(() => {
      if (confirmComplete === true) {
        setTimeout(() => {
          const el = document.getElementById('edit_confirm_yes')
          if (el) {
            el.focus()
          }
        }, 200)
      }
    }, [confirmComplete])

    useEffect(() => {
      console.log('aaa isDisable', isDisable)
    }, [isDisable])

    useEffect(() => {
      console.log('aaa savingOrDispatching', savingOrDispatching)
    }, [savingOrDispatching])

    useEffect(() => {
      console.log('aaa documentFile ', documentFile)
    }, [documentFile])

    useEffect(() => {
      console.log('aaa isProcessingDocument', isProcessingDocument)
    }, [isProcessingDocument])

    const renderSelectGroup = () => {
      if (isLoadDocument) {
        if (isMixed === true && !isChosen && value) {
          return null
        }
      }

      if (!isShowGroupField) {
        return null
      }

      return (
        <div className={cls.form_group}>
          <label htmlFor="inputupload_document_file">
            <Small>Group</Small>
          </label>
          <Select
            clearIcon={<DeleteSearch2 />}
            id="inputupload_document_type"
            onChange={(value) => setIdGroup(value)}
            style={{ width: '100%' }}
            suffixIcon={<ArrowSelect />}
            value={
              idGroup === 'Mixed'
                ? 'Mixed'
                : idGroup
                ? Number(idGroup)
                : undefined
            }
          >
            {isMixed === true && (
              <Option key="group_id_mixed" value="Mixed">
                Mixed
              </Option>
            )}
            {groups.map((group) => (
              <Option key={group.idGroup} value={group.idGroup}>
                {group.name}
              </Option>
            ))}
          </Select>
        </div>
      )
    }

    const getWidthDrawer = () => {
      if (isMixed) {
        return 368
      }
      if (typeof window !== 'undefined') {
        return window.innerWidth - 32
      }
      return 368
    }

    const renderLeftEdit = () => (
      <React.Fragment>
        {confirmComplete && (
          <ReactModal
            headerText={'Load Complete'}
            Content={'Are you done with this Load?'}
            open={true}
            onClose={() => {}}
            ButtonContent={
              <div className="flex justify-end items-center gap-2">
                <Button
                  onClick={() => {
                    saveDoc(false)
                  }}
                  stateClass="Secondary"
                  width={74}
                >
                  No
                </Button>

                <Button
                  id="edit_confirm_yes"
                  onClick={() => {
                    saveDoc(true)
                  }}
                  stateClass="Primary"
                  width={74}
                >
                  Yes
                </Button>
              </div>
            }
          />
        )}

        <div className={cls.form_group}>
          <label htmlFor="inputedit_document_type">
            <Small>Document Type</Small>
          </label>
          <Select
            clearIcon={<DeleteSearch2 />}
            id="inputedit_document_type"
            onChange={(value) => setIdDocumentTypeSelected(value)}
            style={{ width: '100%' }}
            suffixIcon={<ArrowSelect />}
            value={
              idDocumentTypeSelected === 'Mixed'
                ? 'Mixed'
                : idDocumentTypeSelected
                ? Number(idDocumentTypeSelected)
                : undefined
            }
          >
            {isMixed === true && (
              <Option key="document_type_mixed" value="Mixed">
                Mixed
              </Option>
            )}
            {documentTypeList.map((doc_type) => (
              <Option
                key={doc_type.idDocumentType}
                value={doc_type.idDocumentType}
              >
                {doc_type.name}
              </Option>
            ))}
          </Select>
        </div>

        {isLoadDocument && (
          <div className={cls.form_group}>
            <label htmlFor="inputedit_doc_number">
              <Small>Document# (Optional)</Small>
            </label>
            <Input2
              id="inputedit_doc_number"
              onChange={(e) => {
                setDocumentNumber(e.target.value)
              }}
              value={documentNumber}
            />
          </div>
        )}

        {isLoadDocument && (
          // load or non-load document
          <div className={cls.form_group}>
            <label htmlFor="inputedit_load_number">
              <Small>Load# (Optional)</Small>
            </label>
            {/* <InputSearchLoadNumberWhenEditDoc
              error={errorFetchLoad}
              fetchingLoad={fetchingLoad}
              getActiveLoads={getActiveLoads}
              idLoad={idLoad}
              idTrip={idTrip}
              isChosen={isChosen}
              isMixed={isMixed}
              loads={loads}
              setIdLoad={setIdLoad}
              setIdTrip={setIdTrip}
              setIsChosen={setIsChosen}
              setValue={setValue}
              showEditDoc={showEditDoc}
              showEditDocPrev={showEditDocPrev}
              value={value}
              token={token}
              listIdCheckedGroups={listIdCheckedGroups}
            /> */}

            <InputSearchLoadNumberWhenUploadDoc
              idLoad={idLoad}
              idTrip={idTrip}
              isChosen={isChosen}
              setIdLoad={setIdLoad}
              setIdTrip={setIdTrip}
              setIsChosen={setIsChosen}
              setValue={setValue}
              showUploadDoc={showEditDoc}
              showUploadDocPrev={showEditDocPrev}
              value={value}
              token={token}
              listIdCheckedGroups={listIdCheckedGroups}
            />
          </div>
        )}

        {renderSelectGroup()}
      </React.Fragment>
    )

    const renderLeftSplit = () => {
      const renderArrModalConfirmComplete = () => (
        <div className={cls.wr_arr_modal}>
          {arrConfirmCompleteModal.map((modal, index) => {
            if (index >= 1) {
              return null
            }

            return (
              <ReactModal
                key={String(modal.idLoad)}
                headerText={'Load Complete'}
                Content={`Are you done with this Load# ${modal.loadNumber}?`}
                open={true}
                onClose={() => {}}
                ButtonContent={
                  <div className="flex justify-end items-center gap-2">
                    <Button
                      onClick={() => {
                        const dataSplitArrNew = produce(
                          dataSplitArr,
                          (draft) => {
                            draft.forEach((element) => {
                              if (
                                String(element.idLoad) === String(modal.idLoad)
                              ) {
                                element.isConfirm = false
                              }
                            })
                          }
                        )
                        setDataSplitArr(dataSplitArrNew)

                        const arrConfirmCompleteModalNew = _.filter(
                          arrConfirmCompleteModal,
                          (o) => o.idLoad !== modal.idLoad
                        )
                        setArrConfirmCompleteModal(arrConfirmCompleteModalNew)
                        if (_.isEmpty(arrConfirmCompleteModalNew)) {
                          handleClickSaveSplitPhase2(dataSplitArrNew)
                        }
                      }}
                      stateClass="Secondary"
                      width={74}
                    >
                      No
                    </Button>

                    <Button
                      id="edit_confirm_yes"
                      onClick={() => {
                        const dataSplitArrNew = produce(
                          dataSplitArr,
                          (draft) => {
                            let count = 0
                            draft.forEach((element) => {
                              if (
                                String(element.idLoad) === String(modal.idLoad)
                              ) {
                                if (count === 0) {
                                  element.isConfirm = true
                                  count += 1
                                }
                              }
                            })
                          }
                        )
                        setDataSplitArr(dataSplitArrNew)

                        const arrConfirmCompleteModalNew = _.filter(
                          arrConfirmCompleteModal,
                          (o) => o.idLoad !== modal.idLoad
                        )
                        setArrConfirmCompleteModal(arrConfirmCompleteModalNew)
                        if (_.isEmpty(arrConfirmCompleteModalNew)) {
                          handleClickSaveSplitPhase2(dataSplitArrNew)
                        }
                      }}
                      stateClass="Primary"
                      width={74}
                    >
                      Yes
                    </Button>
                  </div>
                }
              />
            )
          })}
        </div>
      )

      return (
        <div className={cls.wr_left_split}>
          {renderArrModalConfirmComplete()}
          {dataSplitArr.map((docsplit, index) => (
            <ItemSplit
              arrErrorInputPageSplit={arrErrorInputPageSplit}
              docsplit={docsplit}
              documentTypeList={documentTypeList}
              getActiveLoadsSplit={getActiveLoadsSplit}
              groups={groups}
              handleAddSplit={handleAddSplit}
              handleAddSplitAtIndex={handleAddSplitAtIndex}
              handleDeleteSplitByIndex={handleDeleteSplitByIndex}
              idGroup={idGroup}
              indexItem={index}
              isChosen={docsplit.isChosen}
              key={docsplit.uniqueid}
              length={dataSplitArr.length}
              listIdCheckedGroups={listIdCheckedGroups}
              setIdGroup={setIdGroup}
              token={token}
              updateListSplit={updateListSplit}
            />
          ))}
        </div>
      )
    }

    return (
      <Drawer
        bodyStyle={{ paddingBottom: 0 }}
        className={classnames(
          cls.wr_drawer_upload_doc,
          'wr_drawer_upload_doc',
          {
            widedrawer: getWidthDrawer() !== 368,
          }
        )}
        destroyOnClose
        footer={
          <div
            className={`wr_footer_add_load ${
              savingOrDispatching ? 'disabled1' : ''
            }`}
          >
            <Button
              onClick={savingOrDispatching ? null : onClose}
              stateClass={savingOrDispatching ? 'Disabled' : 'Secondary'}
              width={74}
            >
              Cancel
            </Button>
            <div className="wr_save_dispatch">
              {isProcessingDocument && (
                <div className="font-bold pr-4 flex justify-center items-center ">
                  Processing, please wait ...
                </div>
              )}

              <Button
                onClick={() => {
                  if (
                    isProcessingDocument ||
                    isDisable ||
                    savingOrDispatching
                  ) {
                    return false
                  }
                  if (isSplitMode) {
                    if (isArchiveOriginalDocsRef.current === null) {
                      setIsOpenModalConfirmArchiveOriginalDocs(true)
                    }
                  } else {
                    handleClickSaveEdit()
                  }
                }}
                stateClass={
                  isProcessingDocument || isDisable || savingOrDispatching
                    ? 'Disabled'
                    : 'Primary'
                }
                width={77}
              >
                Save
              </Button>
            </div>
          </div>
        }
        maskClosable={false}
        onClose={onClose}
        title="Edit Document"
        visible
        width={getWidthDrawer()}
      >
        <div className={cls.wr_body_drawer}>
          {isOpenModalConfirmArchiveOriginalDocs &&
            isEmpty(arrConfirmCompleteModal) && (
              <ModalConfirmArchiveOrininalDocs
                loading={savingOrDispatching}
                open={true}
                onClose={() => {}}
                isArchiveOriginalDocsRef={isArchiveOriginalDocsRef}
                callback={handleClickSaveSplitPhase1}
              />
            )}

          <div className={cls.wr_body_drawer_left}>
            {isSplitMode ? renderLeftSplit() : renderLeftEdit()}
          </div>
          {!isMixed && (
            <div className={cls.wr_body_drawer_right}>
              {url && status ? (
                <PdfViewerComponent
                  setIsProcessingDocument={setIsProcessingDocument}
                  isProcessingDocument={isProcessingDocument}
                  actionFns={{}}
                  document={url}
                  hideSplitIcon={hideSplitIcon}
                  id="pspdfkit_edit_wr"
                  isSplitMode={isSplitMode}
                  rightSideButtonsType="edit"
                  setDocumentFileFn={async ({ instance, isChange, fn }) => {
                    setDocumentFile(undefined)
                    const arrayBuffer = await instance.exportPDF()
                    const blob = new Blob([arrayBuffer], {
                      type: 'application/pdf',
                    })
                    console.log('drawer edit setDocumentFile(blob)', blob)
                    setDocumentFile(blob)
                    if (isChange) {
                      setIsDocumentFileChanged(true)
                    }
                    if (isFunction(fn)) {
                      fn()
                    }
                  }}
                  setIsInCropMode={setIsInCropMode}
                  setIsInEditorMode={setIsInEditorMode}
                  setIsSplitMode={setIsSplitMode}
                />
              ) : null}
            </div>
          )}
        </div>
        <ModalConfirmArchiveOrininalDocs loading={isLoading} open={isLoading} />
      </Drawer>
    )
  }
)

export default DrawerEditDocument

const ItemSplit = ({
  isChosen,
  arrErrorInputPageSplit,
  handleAddSplitAtIndex,
  handleDeleteSplitByIndex,
  docsplit,
  updateListSplit,
  documentTypeList,
  token,
  listIdCheckedGroups,
  groups,
  indexItem,
  length,
}) => {
  const [show, setShow] = useState(false)
  const [index, setIndex] = useState(-1)
  const [, setCanComplete] = useState(undefined)
  const inputref = useRef()

  const [loading, setLoading] = useState(false)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [loadsData, setLoadsData] = useState([])
  const [loadsError, setLoadsError] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [limit, setLimit] = useState(10)

  const value = useMemo(() => docsplit.value, [docsplit])

  const [valueDebounce] = useDebounce(value, 300)

  useEffect(() => {
    setIndex(-1)
  }, [loadsData])

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    // clearSuggestions()
    setShow(false)
    setHasMore(true)
    setLimit(10)
  })

  const getActiveLoads = async (text) => {
    try {
      setLoadsError(null)

      if (!isLoadMore) {
        setLoading(true)
      }
      const resp = await customAxios(token).get(
        `${
          apiRoute.documents.GET_LOADS
        }?search=${text}&groups=${listIdCheckedGroups.join(
          ','
        )}&page=1&limit=${limit}`
      )

      setLoading(false)

      if (isChosen === true && _.get(resp, 'data.data.length') === 1) {
        setCanComplete(_.get(resp, 'data.data[0].canComplete'))
      }
      const { data } = resp.data

      if (_.isEmpty(data)) {
        setLoadsData([])
      } else {
        const newData = _.unionBy([...loadsData, ...data], (obj) =>
          JSON.stringify(obj)
        )
        setLoadsData(newData, data)
      }
    } catch (err) {
      setLoading(false)
      setLoadsError(err)
    }
  }

  useEffect(() => {
    if (valueDebounce) {
      if (!isChosen) {
        setShow(true)

        getActiveLoads(valueDebounce)
      }
    }
  }, [valueDebounce, limit])

  useEffect(() => {
    if (!value) {
      setShow(false)
      setLimit(10)
      setLoadsData([])
      setHasMore(true)
      setIsLoadMore(false)
      setLoading(false)
    }
  }, [value])

  const handleChoseNewLoadNumber = () => {
    setIndex(-1)
    setShow(false)

    updateListSplit(docsplit.uniqueid, {
      idTrip: null,
      idLoad: null,
      isChosen: true,
    })
  }

  const handleChoseLoadNumber = (load) => {
    const targetIdLoad = load.id_load
    let targetIdTrip
    let targetValue
    let targetLoadNumber

    if (load.id_trip) {
      targetIdTrip = Number(load.id_trip)
    } else {
      targetIdTrip = null
    }
    if (load.trip_name) {
      targetValue = `${load.load_number} - ${load.trip_name}`
    } else {
      targetValue = load.load_number
    }

    targetLoadNumber = load.load_number

    updateListSplit(docsplit.uniqueid, {
      idTrip: targetIdTrip,
      idLoad: targetIdLoad,
      value: targetValue,
      loadNumber: targetLoadNumber,
      isChosen: true,
    })

    setShow(false)

    setIndex(-1)
  }

  const renderOptions = () => {
    if (value === 'Mixed' || !value || !show) {
      return null
    }

    // if (fetchingLoad) {
    //   return (
    //     <div className={classnames(cls.result)} onClick={() => {}} tabIndex="0">
    //       <SpinnerIcon />
    //     </div>
    //   )
    // }

    if (loadsError) {
      return (
        <div className={classnames(cls.result)} onClick={() => {}} tabIndex="0">
          <div className="p-4 ">Error: {getErrorMessage(loadsError)}</div>
        </div>
      )
    }

    if (_.isEmpty(loadsData)) {
      return (
        <div
          className={classnames(clsInput.result, {
            [clsInput.selected]: index === 0,
          })}
          onClick={() => {
            handleChoseNewLoadNumber()
          }}
          tabIndex="0"
          style={{
            flexWrap: 'wrap',
            gap: 4,
          }}
        >
          Load# <strong>{value}</strong> not found.{' '}
          <span className={clsInput.blue}>Add New Load</span>
        </div>
      )
    }
    return (
      <React.Fragment>
        {loadsData.map((load, o) => (
          <ItemLoadNumber
            handleChoseLoadNumber={handleChoseLoadNumber}
            index={index}
            key={o}
            load={load}
            o={o}
          />
        ))}
      </React.Fragment>
    )
  }

  const fetchMoreData = () => {
    setIsLoadMore(true)
    if (limit > loadsData.length) {
      setHasMore(false)
      setLimit(10)
    } else {
      setLimit(limit + 10)
      setHasMore(true)
    }
  }

  const renderLoadNumberField = () => (
    <div className={clsInput.wr_inputsearch2} ref={ref}>
      {isChosen && (
        <div className={clsInput.chosen_content}>
          <span>{formatReturnValue(value, 38)}</span>
          <button
            className="button_no_style"
            onClick={() => {
              setTimeout(() => {
                inputref.current.focus()
              }, 100)

              updateListSplit(docsplit.uniqueid, {
                idTrip: null,
                idLoad: null,
                value: '',
                isChosen: false,
              })
            }}
          >
            <IconX18 />
          </button>
        </div>
      )}
      <input
        autoComplete="off"
        className={classnames(clsInput.input, {
          [clsInput.chosen]: isChosen,
        })}
        onChange={(e) => {
          const { value: newValue } = e.target

          if (value !== newValue) {
            setLoading(true)
            setLoadsData([])
          }
          setLimit(10)
          setIsLoadMore(false)
          setHasMore(true)
          if (newValue === '') {
            updateListSplit(docsplit.uniqueid, {
              idLoad: undefined,
              idTrip: undefined,
              value: '',
            })
          } else {
            updateListSplit(docsplit.uniqueid, {
              value: newValue,
            })
          }
        }}
        onFocus={(e) => {
          if (isChosen) {
            e.preventDefault()
            e.target.blur()
            return false
          }
          if (value) {
            // getActiveLoads(value)
            setShow(true)
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            const len = loadsData.length
            if (len === 0) {
              if (index === -1) {
                setIndex(0)
              }
            } else {
              let newIndex
              if (index === loadsData.length - 1) {
                newIndex = 0
              } else {
                newIndex = index + 1
              }
              if (loadsData[newIndex]) {
                setIndex(newIndex)
              }
            }

            e.preventDefault()
          } else if (e.key === 'ArrowUp') {
            const len = loadsData.length
            if (len === 0) {
              if (index === 0) {
                setIndex(-1)
              }
            } else {
              let newIndex
              if (index === 0) {
                newIndex = loadsData.length - 1
              } else {
                newIndex = index - 1
              }
              if (loadsData[newIndex]) {
                setIndex(newIndex)
              }
            }

            e.preventDefault()
          } else if (e.key === 'Tab') {
            setShow(false)
            if (!docsplit.idLoad && !!value && !isChosen) {
              updateListSplit(docsplit.uniqueid, {
                value: '',
              })
            }
          } else if (e.key === 'Enter') {
            if (index === -1) {
              return
            }
            if (_.isEmpty(loadsData)) {
              handleChoseNewLoadNumber()
            } else {
              handleChoseLoadNumber(loadsData[index])
            }
          }
        }}
        ref={inputref}
        tabIndex={isChosen ? '-1' : '0'}
        type="text"
        value={value}
      />
      {show && (
        <React.Fragment>
          {loading ? (
            <div className={clsInput.wr_results}>
              <div
                className={classnames(cls.result)}
                onClick={() => {}}
                tabIndex="0"
              >
                <SpinnerIcon />
              </div>
            </div>
          ) : (
            <React.Fragment>
              {_.size(loadsData) < 10 ? (
                <div className={clsInput.wr_results}>{renderOptions()}</div>
              ) : (
                <div
                  className={clsInput.wr_results}
                  style={{
                    overflowY: 'hidden',
                  }}
                >
                  <InfiniteScroll
                    dataLength={loadsData.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={
                      hasMore && loadsData.length === limit && <SpinnerIcon />
                    }
                    height={275}
                  >
                    {loadsData.map((load, o) => (
                      <ItemLoadNumber
                        handleChoseLoadNumber={handleChoseLoadNumber}
                        index={index}
                        key={o}
                        load={load}
                        o={o}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  )

  return (
    <div className={cls.split_item}>
      <div className={cls.form_group}>
        <label htmlFor="inputedit_document_type">
          <Small>Document Type</Small>
        </label>
        <Select
          clearIcon={<DeleteSearch2 />}
          id={`inputsplittype-${indexItem}`}
          onChange={(value) => {
            updateListSplit(docsplit.uniqueid, 'idDocumentTypeSelected', value)
          }}
          style={{ width: '100%' }}
          suffixIcon={<ArrowSelect />}
          value={
            docsplit.idDocumentTypeSelected
              ? Number(docsplit.idDocumentTypeSelected)
              : undefined
          }
        >
          {documentTypeList.map((doc_type) => (
            <Option
              key={doc_type.idDocumentType}
              value={doc_type.idDocumentType}
            >
              {doc_type.name}
            </Option>
          ))}
        </Select>
      </div>
      {/* TODO */}
      {isLoadDocumentSplit(
        docsplit.idDocumentTypeSelected,
        documentTypeList
      ) && (
        <div className={cls.form_group}>
          <label htmlFor="inputedit_doc_number">
            <Small>Document# (Optional)</Small>
          </label>
          <Input2
            id="inputedit_doc_number"
            onChange={(e) => {
              updateListSplit(
                docsplit.uniqueid,
                'documentNumber',
                e.target.value
              )
            }}
            value={docsplit.documentNumber}
          />
        </div>
      )}

      {isLoadDocumentSplit(
        docsplit.idDocumentTypeSelected,
        documentTypeList
      ) && (
        // load or non-load document
        <div className={cls.form_group}>
          <label htmlFor="inputupload_load_number">
            <Small>Load# (Optional)</Small>
          </label>
          {/* current */}
          {renderLoadNumberField()}
        </div>
      )}

      {renderSelectGroup(
        docsplit.idLoad,
        docsplit.idDocumentTypeSelected,
        groups,
        documentTypeList,
        docsplit.idGroup,
        updateListSplit.bind(null, docsplit.uniqueid, 'idGroup')
      )}

      <div className={cls.form_group}>
        <label htmlFor="inputupload_document_note">
          <Small>Note (Optional)</Small>
        </label>
        <Textarea2
          height="102"
          id="inputupload_document_note"
          onChange={(e) => {
            updateListSplit(docsplit.uniqueid, 'note', e.target.value)
          }}
          value={docsplit.note}
        />
      </div>

      <div className={cls.form_group}>
        <label htmlFor="inputedit_doc_number">
          <Small>Page(s)</Small>

          <Tooltip
            overlayClassName="mw340"
            placement="right"
            title="Separate pages by commas and page range by hyphens. For example: 1,2, 4-6, 3-8."
          >
            <div
              className="tooltip5"
              style={{
                marginLeft: '4px',
                display: 'inline-block',
                transform: 'translateY(2px)',
              }}
            >
              <IconInfo14 />
            </div>
          </Tooltip>
        </label>

        <div className={cls.wr_page_input}>
          <Input2
            className="w160"
            error={arrErrorInputPageSplit[indexItem]}
            id="inputedit_doc_number"
            onChange={(e) => {
              updateListSplit(docsplit.uniqueid, 'pages', e.target.value)
            }}
            onKeyDown={(e) => {
              const { key } = e
              // /^\d+\s+\d+$/
              const valid = [
                'Backspace',
                'Delete',
                'Tab',
                'ArrowLeft',
                'ArrowRight',
                'Home',
                'End',
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '0',
                '-',
                ',',
                ' ',
              ]

              if (e.ctrlKey) {
                return true
              }

              if (!valid.includes(key)) {
                e.preventDefault()
                return false
              }
              return true
            }}
            value={docsplit.pages}
          />

          {indexItem >= 1 && (
            <button
              className="button_no_style"
              onClick={() => {
                handleAddSplitAtIndex(indexItem)
              }}
              tabIndex="0"
            >
              <IconPlusCircle32 />
            </button>
          )}

          {indexItem >= 2 && (
            <button
              className="button_no_style"
              onClick={() => {
                handleDeleteSplitByIndex(indexItem)
              }}
              tabIndex="0"
            >
              <IconMinusCircle32 />
            </button>
          )}
        </div>

        {arrErrorInputPageSplit[indexItem] === true && (
          <div className={cls.invalid_input}>Invalid input</div>
        )}
      </div>
      {indexItem !== length - 1 && <div className={cls.linesplit} />}
    </div>
  )
}

;((_data) => {})([React])
