/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import MyPagination from '@appComponent/MyPagination'
import MyTooltip from '@appComponent/MyTooltip'
import { StatusButton } from '@appComponent/StatusButton'
import { Button } from '@appComponent/button'
import {
  ActiveIconSquare,
  ArrowSort,
  DeactivateIconSquare,
  DeleteSearch2,
  DotsComponent,
  IconEdit2,
  IconSearchLoad,
} from '@appComponent/icon'
import { Input2WithReset } from '@appComponent/input'
import { SpinnerIcon } from '@appComponent/spinnericon'
import { Caption } from '@appComponent/typography'
import usePrevious from '@appComponent/usePrevious'
import withAuth from '@auth'
import { UikDropdown, UikDropdownItem, UikTopBarTitle } from '@components'
import { SORT_ORDER } from '@constants'
import apiRoute from '@constants/apiRoute'
import {
  customAxios,
  formatReturnValue,
  getAddressCityStateZipString,
  getErrorMessage,
  getStr,
} from '@helper'
import { withContext } from '@hoc'
import { useRecentlyEdited } from '@hooks'
import classnames from 'classnames'
import clsx from 'clsx'
import dayjs from 'dayjs'
import produce from 'immer'
import _, { isEmpty } from 'lodash'
import queryString from 'query-string'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useIdleTimer } from 'react-idle-timer'
import useSWR from 'swr'
import { useDebounce } from 'use-debounce'
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import {
  IDLE_CHECK_INTERVAL,
  IDLE_TIME,
  PAGINATION_PAGE_SIZE_DEFAULT,
} from '../../../../config'
import { formatPhoneNumber } from '../../../../helper'
import AddVendor from './components/addVendor'
import EditVendor from './components/editVendor'
import cls from './vendors.scss'
import IconButtonAdd from '../../../svg/IconButtonAdd'

// @flow

const fetcher = async (url, token) => {
  if (!url) return
  const data = await customAxios(token).get(url)
  if (data.status === 200) {
    return data.data.data
  }
  return { error: true }
}

const DEFAULT_SORT = 'name'

function Vendors({ context }) {
  const [cookies] = useCookies(['token'])
  const { token } = cookies
  const [listRecentlyEdittedIdDoc, setListRecentlyEdittedIdDoc] =
    useRecentlyEdited()

  const [urlfetch, setUrlfetch] = useState('')
  const { data, error } = useSWR(urlfetch, fetcher, {
    revalidateOnFocus: false,
  })

  const headerRef = useRef(null)

  const [listVendor, setListVendor] = useState([])

  const [showAdd, setShowAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)

  const [editedIdVendor, setEditedIdVendor] = useState('')

  const [textload, setTextload] = useQueryParam(
    'search',
    withDefault(StringParam, undefined)
  )

  const [textloadDebounce] = useDebounce(textload, 300)
  const textloadDebouncePrev = usePrevious(textloadDebounce)

  const [lastSortInfo, setLastSortInfo] = useQueryParam(
    'sort',
    withDefault(StringParam, DEFAULT_SORT)
  )
  const [sortList, setSortList] = useState({
    status: '',
    name: '',
  })

  const [time_request, set_time_request] = useState(
    dayjs().format('YYYY-MM-DDTHH:mm:ss')
  )

  const [triggerFetch, setTriggerFetch] = useState(0)
  const [total, setTotal] = useState(0)
  const [totalActive, setTotalActive] = useState(0)

  /* react idle timer */
  const handleOnIdle = () => {
    reset()
    set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
  }
  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: IDLE_TIME,
    onIdle: handleOnIdle,
    debounce: IDLE_CHECK_INTERVAL,
  })
  /* react idle timer end */
  /* pagination */
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1))
  const [pageSize, setPageSize] = useQueryParam(
    'size',
    withDefault(NumberParam, PAGINATION_PAGE_SIZE_DEFAULT)
  )

  const pageSizePrev = usePrevious(pageSize)

  const lastSortInfoPrev = usePrevious(lastSortInfo)

  const loadingVendors = useMemo(() => !data && !error, [data, error])

  useEffect(() => {
    setTimeout(() => {
      const key = lastSortInfo.replace('-', '')
      const value = lastSortInfo[0] === '-' ? 'DESC' : 'ASC'
      const nextState = produce(sortList, (draftState) => {
        _.set(draftState, `${key}`, value)
        return draftState
      })
      setSortList(nextState)
    }, 100)
  }, [])

  useEffect(() => {
    const params = {}

    params.sort = lastSortInfo
    params.limit = pageSize

    if (textloadDebounce) {
      params.search = textloadDebounce
    }

    params.page = page
    if (
      textloadDebounce !== textloadDebouncePrev ||
      (pageSizePrev !== pageSize && pageSizePrev !== undefined) ||
      (lastSortInfo !== lastSortInfoPrev && lastSortInfoPrev !== undefined)
    ) {
      params.page = 1
      setPage(undefined)
    }

    params.time_request = time_request
    const url = `${apiRoute.vendors.BASE_VENDORS}?${queryString.stringify(
      params
    )}`
    setUrlfetch(url)

    if (
      lastSortInfo === DEFAULT_SORT &&
      page === 1 &&
      pageSize === PAGINATION_PAGE_SIZE_DEFAULT &&
      textloadDebounce === undefined
    ) {
      // when click on icon loads page on the left
      set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
    }
  }, [page, textloadDebounce, pageSize, lastSortInfo, time_request])

  useEffect(() => {
    function handleData(data_) {
      setListVendor(_.get(data_, 'vendors') || [])
      setTotal(_.get(data_, 'countVendors') || 0)
      setTotalActive(_.get(data_, 'activeVendors') || 0)
      // setListDocument(_.get(data_, 'documents') || [])
    }
    if (error === undefined && !!data) {
      handleData(data)
    }
  }, [data, error])

  const handleDeleteSearch = () => {
    setTextload(undefined)
  }

  // fetching vendors

  const handleShowAdd = () => {
    setShowAdd(true)
  }
  const handleAddVendor = (content) => {
    handleRefreshData(content)
    setShowAdd(false)
  }
  const handleCancel = () => {
    context.modal.allowScroll()
    setTimeout(() => {
      setShowAdd(false)
    }, 500)
  }

  const handleClickUpdate = (item) => () => {
    setShowEdit(true)
    setEditedIdVendor(item.idVendor)
  }
  const handleEditVendor = (content) => {
    setShowEdit(false)

    handleRefreshData(content)
  }
  const handleCloseModal = () => {
    setTimeout(() => {
      setShowEdit(false)
    }, 500)
    context.modal.allowScroll()
  }

  const handleChangeInputSearch = (e) => {
    setTextload(e.target.value || undefined)
  }
  // pagination
  const handleChangePageSize = (value) => {
    setPageSize(value)
  }
  // sorting
  const handleSorting = (sortValue) => {
    const lastSortCriteria = lastSortInfo.replace('-', '')
    let lastSortInfoClone = lastSortInfo
    if (sortValue !== lastSortCriteria) {
      lastSortInfoClone =
        sortList[sortValue] === 'ASC' ? `-${sortValue}` : sortValue
    } else if (lastSortInfoClone[0] === '-') {
      lastSortInfoClone = lastSortInfoClone.replace('-', '')
    } else {
      lastSortInfoClone = `-${lastSortInfoClone}`
    }
    setLastSortInfo(lastSortInfoClone)
    const target = sortList[sortValue] === 'ASC' ? 'DESC' : 'ASC'
    setSortList({ ...sortList, [sortValue]: target })
  }

  // activate or deactivate
  const handleChangeStatusVendor = (vendor) => async () => {
    headerRef.current.click()
    let popupMessage = ''
    if (vendor.status === true) {
      popupMessage = 'Deactivated'
    } else if (vendor.status === false) {
      popupMessage = 'Activated'
    }
    try {
      const { data: responseData } = await customAxios(token).put(
        `${apiRoute.vendors.CHANGE_STATUS}/${vendor.idVendor}`
      )
      if (responseData.success) {
        handleRefreshData(`${popupMessage} Successfully`)
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    }
  }

  const handleRefreshData = (messageCallback) => {
    set_time_request(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
    context.message.success(messageCallback)
  }

  const renderListVendors = () => {
    if (loadingVendors && _.isEmpty(listVendor)) {
      return <SpinnerIcon />
    }

    if (_.isEmpty(listVendor)) {
      return (
        <div className="no_data">
          <Caption>No vendor</Caption>
        </div>
      )
    }

    return (
      <div className="wr_list">
        {listVendor.map((vendor, index) => {
          vendor.contacts = Array.isArray(vendor.contacts)
            ? vendor.contacts
            : []
          const address = getStr(vendor, 'address')
          const city = getStr(vendor, 'city')
          const state = getStr(vendor, 'state')
          const zipcode = getStr(vendor, 'zipcode')
          const addressWithCityStateZip = getAddressCityStateZipString(
            address,
            city,
            state,
            zipcode
          )

          return (
            <React.Fragment key={vendor.idVendor}>
              <div style={{ height: '4px', background: '#fbfbfd' }} />
              <div
                className={classnames(cls.vendor_row, {
                  [cls.go]: listRecentlyEdittedIdDoc.includes(vendor.idVendor),
                })}
              >
                <div className={cls.vendor_status}>
                  <div className={cls.middle_container}>
                    <span
                      className={classnames(
                        [cls.commonTag],
                        {
                          [cls.pending]: vendor.status === '0',
                        },
                        {
                          [cls.active]: vendor.status === true,
                        },
                        {
                          [cls.inactive]: vendor.status === false,
                        }
                      )}
                    >
                      {vendor.status === true ? 'Active' : 'Inactive'}
                    </span>
                  </div>
                </div>
                <div className={cls.vendor_name}>
                  <div style={{ margin: '8px 0' }}>
                    <span className={cls.common_style_content_1}>
                      {formatReturnValue(vendor.name, 40)}
                    </span>
                    <span className={cls.common_style_content_2}>
                      {addressWithCityStateZip}
                    </span>
                    <span className={cls.common_style_content_2}>
                      {vendor.mainPhoneNumber &&
                        formatPhoneNumber(
                          vendor.countryCode,
                          vendor.mainPhoneNumber
                        )}
                      {vendor.mainPhoneNumber && vendor.emailAddress
                        ? ' - '
                        : null}
                      {getStr(vendor, 'emailAddress')}
                    </span>
                  </div>
                </div>
                {/* <div className={cls.vendor_address}>
                    <span className={cls.common_style_content}>
                      {formatReturnValue(vendor.address, 75)}
                    </span>
                  </div> */}
                <div className={cls.contact_name}>
                  <span className={cls.common_style_content}>
                    {vendor.contacts.map((item, index) => (
                      <React.Fragment key={index}>
                        {item.contactName
                          ? formatReturnValue(item.contactName, 15)
                          : ''}
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                </div>
                <div className={cls.vendor_phoneNumber}>
                  <span className={cls.common_style_content}>
                    {vendor.contacts.map((item, index) => (
                      <React.Fragment key={index}>
                        {item.phoneNumber
                          ? formatPhoneNumber(
                              item.countryCode,
                              item.phoneNumber
                            )
                          : ''}
                        &nbsp;
                        {item.ext ? `Ext. ${item.ext}` : ''}
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                </div>
                <div className={cls.vendor_email}>
                  <span className={cls.common_style_content}>
                    {vendor.contacts.map((item, index) => (
                      <React.Fragment key={index}>
                        {getStr(item, 'email')}
                        &nbsp;
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                </div>
                <div className={cls.vendor_edit}>
                  <div className={cls.edit_container}>
                    <UikDropdown
                      DisplayComponent={DotsComponent}
                      className={cls.dotEdit}
                      position="bottomRight"
                    >
                      <UikDropdownItem onClick={handleClickUpdate(vendor)}>
                        <span className={cls.mr_icon_edit}>
                          <IconEdit2 height={14} width={14} />
                        </span>
                        Edit
                      </UikDropdownItem>
                      {vendor.status === false && (
                        <UikDropdownItem
                          className={cls.activate}
                          onClick={handleChangeStatusVendor(vendor)}
                        >
                          <span className={cls.mr_icon_edit}>
                            <ActiveIconSquare />
                          </span>
                          Activate
                        </UikDropdownItem>
                      )}
                      {vendor.status === true && (
                        <UikDropdownItem
                          className={cls.deactivate}
                          onClick={handleChangeStatusVendor(vendor)}
                        >
                          <span className={cls.mr_icon_edit}>
                            <DeactivateIconSquare height={14} width={14} />
                          </span>
                          Deactivate
                        </UikDropdownItem>
                      )}
                    </UikDropdown>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
        })}
      </div>
    )
  }
  return (
    <React.Fragment>
      <div className={classnames(cls.headContainer, 'wr_page_title mt-3')}>
        <UikTopBarTitle className="page_title">
          <span ref={headerRef}>Vendors</span>
          <MyTooltip
            classNameNormal="ml8"
            classNameTooltip=""
            contentNormal={
              <Caption gray> {`${totalActive}/${total}`} </Caption>
            }
            contentTooltip="Active/Total"
            tooltipId="group_active"
          />
        </UikTopBarTitle>
        <div className={cls.vendors_interactive}>
          {/**/}
          <div className={cls.search_wrapper}>
            <Input2WithReset
              iconright={
                textload ? (
                  <DeleteSearch2 onClick={handleDeleteSearch} />
                ) : (
                  <span>
                    <IconSearchLoad />
                  </span>
                )
              }
              onChange={handleChangeInputSearch}
              value={textload === undefined ? '' : textload}
            />
          </div>
          <div className="ml-2" onClick={handleShowAdd}>
            <IconButtonAdd width={38} height={38} />
          </div>
        </div>
      </div>

      {/* // here table tailwind */}
      <div className="w-full min-w-[1400px] ">
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr className=" bg-LightestGray">
              {/* status */}
              <th
                className={clsx(
                  'w-[100px] 2xl:w-[126px] pl-[16px] fullhd:pl-[26px] pr-0 cursor-pointer pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%]',
                  ` ${
                    lastSortInfo.replace('-', '') === 'status'
                      ? 'text-Primary'
                      : 'text-DarkGray'
                  }  `
                )}
                onClick={() => {
                  // handleSorting(sortKeywords.name)
                  handleSorting('status')
                }}
              >
                <span className=" ">status</span>
                <span className="w-1" />
                <ArrowSort
                  className={clsx(
                    ' duration-300 transform ',
                    {
                      'rotate-180': sortList.status === SORT_ORDER.ASC,
                      'rotate-0': sortList.status === SORT_ORDER.DESC,
                    },
                    `${
                      lastSortInfo.replace('-', '') === 'status'
                        ? 'fill-Primary'
                        : 'fill-DarkGray'
                    }`
                  )}
                />
              </th>

              {/* name */}
              <th
                className={clsx(
                  'w-[380px] 2xl:w-[380px] fullhd:w-[400px] 3xl:w-[520px]  whitespace-nowrap  pr-0 cursor-pointer pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%]',
                  ` ${
                    lastSortInfo.replace('-', '') === 'name'
                      ? 'text-Primary'
                      : 'text-DarkGray'
                  }  `
                )}
                onClick={() => handleSorting('name')}
              >
                <span className=" ">name</span>

                <ArrowSort
                  className={clsx(
                    ' duration-300 transform ',
                    {
                      'rotate-180': sortList.name === SORT_ORDER.ASC,
                      'rotate-0': sortList.name === SORT_ORDER.DESC,
                    },
                    `${
                      lastSortInfo.replace('-', '') === 'name'
                        ? 'fill-Primary'
                        : 'fill-DarkGray'
                    }`
                  )}
                />
              </th>

              {/* contact */}
              <th
                className={clsx(
                  'w-[248px] whitespace-nowrap pl-[16px] fullhd:pl-[26px] pr-0 pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%] text-DarkGray'
                )}
                onClick={() => {
                  // handleSorting(sortKeywords.stops)
                }}
              >
                contact
              </th>

              {/* phone number */}
              <th
                className={clsx(
                  'w-[252px] whitespace-nowrap pl-[16px] fullhd:pl-[26px] pr-0 pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%] text-DarkGray'
                )}
                onClick={() => {
                  // handleSorting(sortKeywords.stops)
                }}
              >
                phone number
              </th>

              {/* email address */}
              <th
                className={clsx(
                  'whitespace-nowrap pl-[16px] fullhd:pl-[26px] pr-0 pt-[13px] pb-[13px] text-left text-[12px] font-bold uppercase leading-[150%] text-DarkGray'
                )}
                onClick={() => {
                  // handleSorting(sortKeywords.stops)
                }}
              >
                email address
              </th>

              <th className="w-[68px] whitespace-nowrap px-0 cursor-pointer pt-[13px] pb-[13px]" />
            </tr>
          </thead>
          {/* // here render tbody tailwind */}
          <tbody className="">
            {loadingVendors && _.isEmpty(listVendor) ? (
              <React.Fragment>
                <tr className="h-1 bg-Gray1">
                  <td colSpan="6" />
                </tr>
                <tr className="bg-white ">
                  <td className="h-[60px] " colSpan="6">
                    <SpinnerIcon />
                  </td>
                </tr>
              </React.Fragment>
            ) : _.isEmpty(listVendor) ? (
              <React.Fragment>
                <tr className="h-1 bg-Gray1">
                  <td colSpan="6" />
                </tr>
                <tr className="bg-white ">
                  <td className="h-[60px] " colSpan="6">
                    <div className="flex items-center justify-center ">
                      <Caption>No vendor</Caption>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {(isEmpty(listVendor) ? [] : listVendor).map(
                  (vendor, index) => {
                    vendor.contacts = Array.isArray(vendor.contacts)
                      ? vendor.contacts
                      : []
                    const address = getStr(vendor, 'address')
                    const city = getStr(vendor, 'city')
                    const state = getStr(vendor, 'state')
                    const zipcode = getStr(vendor, 'zipcode')
                    const addressWithCityStateZip =
                      getAddressCityStateZipString(
                        address,
                        city,
                        state,
                        zipcode
                      )
                    // console.log('aaa31 ', vendor)
                    return (
                      <React.Fragment key={vendor.idVendor}>
                        {/*  */}
                        <tr className="h-1 bg-Gray1">
                          <td colSpan="6" />
                        </tr>
                        <tr className="bg-white hover:bg-LightestGray ">
                          {/* status */}
                          <td className="text-Black  pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]     leading-[150%]">
                            <StatusButton
                              status={(function () {
                                if (vendor.status === '0') {
                                  return 'pending'
                                }
                                if (vendor.status === true) {
                                  return 'active'
                                }
                                return 'inactive'
                              })()}
                            />
                          </td>

                          {/* name */}
                          <td className="text-Black pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]">
                            <div style={{ margin: '8px 0' }}>
                              <span className={cls.common_style_content_1}>
                                {formatReturnValue(vendor.name, 40)}
                              </span>
                              <span className={cls.common_style_content_2}>
                                {addressWithCityStateZip}
                              </span>
                              <span className={cls.common_style_content_2}>
                                {vendor.mainPhoneNumber &&
                                  formatPhoneNumber(
                                    vendor.countryCode,
                                    vendor.mainPhoneNumber
                                  )}
                                {vendor.mainPhoneNumber && vendor.emailAddress
                                  ? ' - '
                                  : null}
                                {getStr(vendor, 'emailAddress')}
                              </span>
                            </div>
                          </td>

                          {/* contact */}
                          <td className="text-Black  pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]">
                            <span className={cls.common_style_content}>
                              {vendor.contacts.map((item, index) => (
                                <React.Fragment key={index}>
                                  {item.contactName
                                    ? formatReturnValue(item.contactName, 15)
                                    : ''}
                                  <br />
                                </React.Fragment>
                              ))}
                            </span>
                          </td>

                          {/* phone number */}
                          <td className="text-Black  pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]">
                            <span className={cls.common_style_content}>
                              {vendor.contacts.map((item, index) => (
                                <React.Fragment key={index}>
                                  {item.phoneNumber
                                    ? formatPhoneNumber(
                                        item.countryCode,
                                        item.phoneNumber
                                      )
                                    : ''}
                                  &nbsp;
                                  {item.ext ? `Ext. ${item.ext}` : ''}
                                  <br />
                                </React.Fragment>
                              ))}
                            </span>
                          </td>
                          {/* email address */}
                          <td className="text-Black  pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]">
                            <span className={cls.common_style_content}>
                              {vendor.contacts.map((item, index) => (
                                <React.Fragment key={index}>
                                  {getStr(item, 'email')}
                                  &nbsp;
                                  <br />
                                </React.Fragment>
                              ))}
                            </span>
                          </td>

                          {/* dropdown */}
                          <td className="text-Black max-w-[68px] px-0 pt-[10px] pb-[10px] text-right">
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                padding: '8px 22px',
                                // minHeight: '68px',
                              }}
                            >
                              <UikDropdown
                                DisplayComponent={DotsComponent}
                                className="dotEdit"
                                position="bottomRight"
                              >
                                <UikDropdownItem
                                  onClick={handleClickUpdate(vendor)}
                                >
                                  <span className={cls.mr_icon_edit}>
                                    <IconEdit2 height={14} width={14} />
                                  </span>
                                  Edit
                                </UikDropdownItem>
                                {vendor.status === false && (
                                  <UikDropdownItem
                                    className={cls.activate}
                                    onClick={handleChangeStatusVendor(vendor)}
                                  >
                                    <span className={cls.mr_icon_edit}>
                                      <ActiveIconSquare />
                                    </span>
                                    Activate
                                  </UikDropdownItem>
                                )}
                                {vendor.status === true && (
                                  <UikDropdownItem
                                    className={cls.deactivate}
                                    onClick={handleChangeStatusVendor(vendor)}
                                  >
                                    <span className={cls.mr_icon_edit}>
                                      <DeactivateIconSquare
                                        height={14}
                                        width={14}
                                      />
                                    </span>
                                    Deactivate
                                  </UikDropdownItem>
                                )}
                              </UikDropdown>
                            </div>
                          </td>
                        </tr>
                        {/*  */}
                      </React.Fragment>
                    )
                  }
                )}
              </React.Fragment>
            )}
          </tbody>
        </table>
      </div>

      <div className=" h-[24px] " />

      {/* table tailwind end */}

      <MyPagination
        className={cls.pagination}
        handleChangePageSize={handleChangePageSize}
        loading={loadingVendors}
        page={page || 1}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        setTriggerFetch={setTriggerFetch}
        total={total}
        triggerFetch={triggerFetch}
      />
      <AddVendor
        onCancel={handleCancel}
        onClickAdd={handleAddVendor}
        showAdd={showAdd}
      />

      {showEdit && (
        <EditVendor
          idVendor={editedIdVendor}
          onClickSave={handleEditVendor}
          onClose={handleCloseModal}
          setListRecentlyEdittedIdDoc={setListRecentlyEdittedIdDoc}
          showEdit={showEdit}
        />
      )}
    </React.Fragment>
  )
}

export default withAuth(withContext(Vendors))
;((_data) => {})([React])
