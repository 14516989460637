import Checkbox from 'antd/lib/checkbox/Checkbox'
import axios from 'axios'
import classnames from 'classnames'
import _, { isEmpty, toLower } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import {
  UikButton,
  UikFormInputGroup,
  UikHeadline,
  UikInput,
} from '../../../components'
import { clientId, clientSecret, provider } from '../../../constants'
import apiRoute from '../../../constants/apiRoute'
import logo from '../../../driverDocAssets/DriverDocMainLogo.png'
import { withContext } from '../../../hoc'
import { checkValidEmail } from '../../../utils'
import Footer from '../../@components/Footer'
import { ShowEye } from '../../@components/icon'
import { Caption, H2, H3, H4, H6 } from '../../@components/typography'
import createAuthContext from '../../lib/createAuthContext'
import Step1 from '../../svg/step1'
import Step2 from '../../svg/step2'
import Step3 from '../../svg/step3'
import cls from './create-account.scss'

// @flow
const { AuthContext, Authenticated } = createAuthContext({
  clientId,
  clientSecret,
  provider,
  // tokenEndpoint: 'http://localhost:3020/token' // If token endpoint is not "provider + '/token'"
})
function ProtectedStuff() {
  return <Authenticated>This would be visible only if logged in.</Authenticated>
}
function CreateAccount({ context, history }) {
  const [account, setAccount] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    country: 'us',
    countryCode: '1',
    email: '',
    password: '',
    confirmPassword: '',
    agree: false,
  })
  const [error, setError] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    agree: '',
  })
  const [showProtected, setShowProtected] = useState(false)

  const [double, setDouble] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [typePassword, setTypePassword] = useState('password')
  const [typeConfirmPassword, setTypeConfirmPassword] = useState('password')
  const handleTogglePass = () => {
    if (typePassword === 'password') {
      setTypePassword('text')
    } else if (typePassword === 'text') {
      setTypePassword('password')
    }
  }
  useEffect(() => {
    const buttonDropDown = document.querySelector(
      '.containerClass .flag-dropdown'
    )
    if (isEmpty(buttonDropDown)) {
      return
    }

    buttonDropDown.addEventListener('click', async () => {
      // ////////////////////////////
      let countries = document.querySelectorAll(
        '.containerClass .country-list .country'
      )
      var interval = setInterval(() => {
        if (isEmpty(countries)) {
          countries = document.querySelectorAll(
            '.containerClass .country-list .country'
          )
        } else {
          clearInterval(interval)
          countries.forEach((o) => {
            const oldText = o.innerHTML
            const reg = />\+\d{1,100}/
            const match = oldText.match(reg)
            if (match !== null) {
              const match2 = match[0]

              const match3 = match2.replace('+', '(+')
              const match4 = `${match3})`
              const newText = oldText.replace(reg, match4)
              o.innerHTML = newText
            }
          })
        }
      }, 30)
      // ////////////////////////////
    })

    return () => {}
  }, [])
  const checkRequired = useMemo(() => {
    if (
      !_.isEmpty(account.companyName) &&
      account.agree &&
      !_.isEmpty(account.firstName) &&
      !_.isEmpty(account.lastName) &&
      !_.isEmpty(account.email) &&
      !_.isEmpty(account.password) &&
      !_.isEmpty(account.confirmPassword)
    ) {
      setDouble(false)
      return false
    }
    setDouble(true)
    return true
  }, [
    account.companyName,
    account.agree,
    account.firstName,
    account.lastName,
    account.email,
    account.password,
    account.confirmPassword,
  ])
  const handleToggleConfirmPassword = () => {
    if (typeConfirmPassword === 'password') {
      setTypeConfirmPassword('text')
    } else if (typeConfirmPassword === 'text') {
      setTypeConfirmPassword('password')
    }
  }

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'password':
        setAccount({ ...account, password: e.target.value })
        break
      case 'confirmPassword':
        setAccount({ ...account, confirmPassword: e.target.value })
        break
      default:
        break
    }
    setError({ ...error, password: '', confirmPassword: '' })
  }
  const handleValidate = () => {
    let isValid = true
    const newError = { ...error }
    const emailTestRegex = checkValidEmail(account.email)
    const passwordTest = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/
    if (account.phoneNumber.length > 1 && account.phoneNumber.length < 10) {
      newError.phoneNumber = 'Please enter a valid phone number'
      isValid = false
    } else if (account.phoneNumber.charAt(0) === '0') {
      newError.phoneNumber = 'Phone number does not exist'
      isValid = false
    }
    if (!passwordTest.test(account.password) || account.password.length < 8) {
      newError.password =
        'Please choose a stronger password: Minimum 8 digits, 1 number, 1 uppercase.'
      isValid = false
    } else if (account.confirmPassword !== account.password) {
      newError.confirmPassword = "Password confirmation doesn't match Password"
      isValid = false
    }
    if (!emailTestRegex && account.email !== '') {
      newError.email = 'Please enter a valid email address'
      isValid = false
    }
    setError(newError)
    return isValid
  }
  const handleSubmit = async () => {
    const isValid = handleValidate()
    if (isValid && !isLoading) {
      const bodyRequest = {
        name: account.companyName.trim(),
        email: account.email.trim(),
        password: account.password.replace(/[\s]/gm, ''),
        firstName: account.firstName.trim(),
        lastName: account.lastName.trim(),
        phoneNumber: account.phoneNumber.replace(/[\(\)\s\-]/gm, ''),
        isoCode: account.country.toUpperCase(),
        countryCode: account.countryCode,
      }
      try {
        setIsLoading(true)
        setDouble(true)
        const { data: dataResponse } = await axios.post(
          `${apiRoute.signUp.CREATE_ACCOUNT}`,
          bodyRequest
        )
        if (dataResponse) {
          history.push(`verification?token=${dataResponse.enrollmentToken}`)
        }
      } catch (err) {
        if (err.response.data) {
          context.message.error(err.response.data.messages)
        }
        // if (err.response.status === 404) {
        //   setErrorLogin('Email address or password is incorrect. Try again.')
        // } else {
        //   const errorMessage = err.response.data.messages
        //   setErrorLogin(errorMessage)
        // }
      } finally {
        setTimeout(() => {
          setDouble(false)
        }, 3888)
        setIsLoading(false)
      }
    }
  }
  const handleLogin = () => {
    setShowProtected(!showProtected)
  }

  return (
    <AuthContext history={history}>
      <div className={cls.create_account}>
        <div className={cls.imageBackground}>
          <div className={cls.step}>
            <div className={cls.step_title}>
              <H2>Success in 3 easy steps</H2>
            </div>
            <div className={cls.step_form}>
              <div className={cls.step_item}>
                <div className={cls.step_image}>
                  <Step1 />
                </div>
                <H4>Start your driverDOC free trial.</H4>
              </div>
              <div className={cls.step_item}>
                <div className={cls.step_image}>
                  <Step3 />
                </div>
                <H4>Invite drivers to download the mobile app.</H4>
              </div>
              <div className={cls.step_item}>
                <div className={cls.step_image}>
                  <Step2 />
                </div>
                <H4>Invite office team to access the portal.</H4>
              </div>
            </div>
          </div>
        </div>

        <div className={cls.formLoginContainer}>
          <div className={classnames(cls.img_left, cls.c, cls.c1)}>
            <img alt="logo" className={cls.img_logo} src={logo} />
          </div>
          <div>
            <H3>Try driverDOC FREE for 14 days</H3>
          </div>
          <div className={cls.title_desc}>
            {' '}
            <Caption>Full access. No credit card needed.</Caption>
          </div>
          <div className={classnames(cls.form_create, cls.c, cls.c2)}>
            <div className={cls.form}>
              <UikHeadline className={cls.headline}>
                <H6>Account Setup</H6>
              </UikHeadline>
              <UikFormInputGroup
                className={classnames(cls.common_input_wrapper)}
              >
                <label className={cls.label} htmlFor="company">
                  Company Name
                </label>
                <UikInput
                  autoComplete="off"
                  className={classnames(cls.input_style, {
                    [cls.errorDetection]: error.firstName !== '',
                  })}
                  id="company"
                  name="company"
                  onChange={(e) =>
                    setAccount({ ...account, companyName: e.target.value })
                  }
                  onKeyDown={() => {}}
                  type="text"
                  value={account.companyName}
                />
                {error.companyName && (
                  <p className={cls.errorMsg}>{error.companyName}</p>
                )}
              </UikFormInputGroup>

              <section className={cls.name}>
                <UikFormInputGroup
                  className={classnames(
                    cls.common_input_wrapper,
                    cls.firstName,
                    cls.input_name
                  )}
                >
                  <label className={cls.label} htmlFor="first_name">
                    First Name
                  </label>
                  <UikInput
                    autoComplete="off"
                    className={classnames(cls.input_style, {
                      [cls.errorDetection]: error.companyName !== '',
                    })}
                    id="first_name"
                    maxLength="14"
                    name="phoneNumber"
                    onChange={(e) =>
                      setAccount({ ...account, firstName: e.target.value })
                    }
                    tabIndex="0"
                    type="text"
                    value={account.firstName}
                  />
                  {error.firstName && (
                    <p className={cls.errorMsg}>{error.firstName}</p>
                  )}
                </UikFormInputGroup>
                <UikFormInputGroup
                  className={classnames(
                    cls.common_input_wrapper,
                    cls.lastName,
                    cls.input_name
                  )}
                >
                  <label className={cls.label} htmlFor="last_name">
                    Last Name
                  </label>
                  <UikInput
                    autoComplete="off"
                    className={classnames(cls.input_style, {
                      [cls.errorDetection]: error.lastName !== '',
                    })}
                    id="last_name"
                    maxLength="14"
                    name="last_name"
                    onChange={(e) =>
                      setAccount({ ...account, lastName: e.target.value })
                    }
                    tabIndex="0"
                    type="text"
                    value={account.lastName}
                  />
                  {error.lastName && (
                    <p className={cls.errorMsg}>{error.lastName}</p>
                  )}
                </UikFormInputGroup>
              </section>
              <UikFormInputGroup
                className={classnames(cls.common_input_wrapper)}
              >
                <label className={cls.label} htmlFor="phone">
                  Phone Number (Optional)
                </label>
                <PhoneInput
                  buttonClass="buttonClass"
                  // priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                  containerClass="containerClass"
                  country="us"
                  countryCodeEditable={false}
                  disableCountryGuess
                  dropdownClass="dropdownClass"
                  inputClass={classnames(
                    'inputClass text-[14px] leading-[150%] rounded-[4px] h-[38px] focus:border-borderInput w-[320px]'
                  )}
                  masks={{
                    us: '...-...-....',
                    mx: '..-....-....',
                    ca: '...-...-....',
                  }}
                  onChange={(value, data) => {
                    const newAcc = {
                      ...account,
                      country: data.countryCode,
                      phoneNumber: value.slice(data.dialCode.length),
                      countryCode: data.dialCode,
                    }
                    setAccount(newAcc)
                    setError({ ...error, phoneNumber: '' })
                  }}
                  onlyCountries={['us', 'mx', 'ca']}
                  value={account.countryCode + account.phoneNumber}
                />
                {error.phoneNumber && (
                  <p className={cls.errorMsg}>{error.phoneNumber}</p>
                )}
              </UikFormInputGroup>
              <UikFormInputGroup
                className={classnames(cls.common_input_wrapper)}
              >
                <label className={cls.label} htmlFor="email_address">
                  Email Address
                </label>
                <UikInput
                  autoComplete="off"
                  className={classnames(cls.input_style, {
                    [cls.errorDetection]: error.email !== '',
                  })}
                  id="email_address"
                  name="foo"
                  onChange={(e) => {
                    setError({ ...error, email: '' })
                    setAccount({ ...account, email: toLower(e.target.value) })
                  }}
                  type="text"
                  value={account.email}
                />
                {error.email && <p className={cls.errorMsg}>{error.email}</p>}
              </UikFormInputGroup>
              <UikFormInputGroup className={cls.gr_password}>
                <label className={cls.label} htmlFor="password">
                  Password
                </label>
                <UikInput
                  autoComplete="off"
                  className={classnames(cls.ip_password, {
                    [cls.errorDetection]: error.password !== '',
                  })}
                  id="password"
                  maxLength="20"
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"
                  type={typePassword}
                  value={account.password}
                />
                <span
                  className={cls.toggle_password}
                  onClick={handleTogglePass}
                >
                  <ShowEye />
                </span>
                {error.password && (
                  <p className={cls.errorMsg}>{error.password}</p>
                )}
              </UikFormInputGroup>
              <UikFormInputGroup className={cls.gr_confirm}>
                <label className={cls.label} htmlFor="confirmPassword">
                  Confirm Password
                </label>
                <UikInput
                  autoComplete="off"
                  className={classnames(cls.ip_confirm_password, {
                    [cls.errorDetection]: error.confirmPassword !== '',
                  })}
                  id="confirmPassword"
                  maxLength="20"
                  name="confirmPassword"
                  onChange={handleChange}
                  // onKeyDown={handleKeyDown}
                  placeholder="Confirm Password"
                  type={typeConfirmPassword}
                  value={account.confirmPassword}
                />
                <span
                  className={cls.toggle_confirm_password}
                  onClick={handleToggleConfirmPassword}
                >
                  <ShowEye />
                </span>
                {error.confirmPassword && (
                  <p className={cls.errorMsg}>{error.confirmPassword}</p>
                )}
              </UikFormInputGroup>
              <div className={cls.agree}>
                <Checkbox
                  checked={account.agree}
                  className={cls.input_check}
                  onChange={(e) =>
                    setAccount({ ...account, agree: e.target.checked })
                  }
                />
                <div className={cls.text_agree}>
                  By signing up, I agree to the{' '}
                  <a href="https://driverdoc.io/terms">Terms of Use</a> and
                  acknowledge I have read the{' '}
                  <a href="https://driverdoc.io/privacy">Privacy Policy</a>.{' '}
                </div>
              </div>
              <UikButton
                className={
                  checkRequired || double || isLoading
                    ? cls.btn_reset_disabled
                    : cls.btn_reset
                }
                disabled={checkRequired || double || isLoading}
                isLoading={isLoading}
                onClick={handleSubmit}
              >
                <span className={cls.value}>Continue</span>
              </UikButton>
              <div className={cls.footer}>
                <div>Already have an account?</div>
                <div className={cls.link_to_login} onClick={handleLogin}>
                  &nbsp; Log in
                </div>
                {showProtected && <ProtectedStuff />}
              </div>
            </div>
          </div>
          <div className={cls.footer_info}>
            <Footer />
          </div>
        </div>
      </div>
    </AuthContext>
  )
}
export default withContext(CreateAccount)
;((_data) => {})([React])
