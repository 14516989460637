import React from 'react'

const IconArrowRight = () => (
  <svg
    fill="none"
    height="12"
    viewBox="0 0 6 12"
    width="6"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6 0L0 6L6 12L6 0Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
)
export default IconArrowRight
;((_data) => {})([React])
