import { ArchivedBadge } from '@appComponent/ArchivedBadge'
import { MyCheckbox } from '@appComponent/MyCheckbox'
import { RejectedBadge } from '@appComponent/RejectedBadge'
import { ThNoSort } from '@appComponent/ThNoSort'
import { ThWithSort } from '@appComponent/ThWithSort'
import { SpinnerIcon } from '@appComponent/spinnericon'
import { Caption } from '@appComponent/typography'
import { getStr, renderTimeMeta } from '@helper'
import clsx from 'clsx'
import _, { isEmpty } from 'lodash'
import React from 'react'
import { sortKeywords } from './index'
import dayjs from 'dayjs'

export const OtherDocuments = ({
  currentId,
  checkDocument,
  loading,
  sortList,
  lastSortInfo,
  handleClickRow,
  handleSorting,
  listDocument,
  listRecentlyEdittedIdDoc,
  checkOrUnCheckAll,
  isAllItemOnScreenAreCheck,
  listIdDocumentSelected,
  renderDocumentActions,
}) => {
  return (
    <div
      className={clsx(
        '  ',
        !currentId ? ' w-full min-w-[1400px] ' : ' w-[267px] '
      )}
      style={
        !!currentId
          ? {
              maxHeight: 'calc(100vh - 230px)',
              overflowY: 'auto',
            }
          : {}
      }
    >
      <table
        className={clsx(
          ' w-full  border-collapse ',
          !currentId ? ' table-auto ' : ' table-fixed '
        )}
      >
        <thead>
          <tr className=" bg-LightestGray">
            {/* th checkbox all */}
            {!currentId && (
              <ThNoSort
                className="w-[38px]"
                classNamePadding="pl-[16px] fullhd:pl-[22px]"
                onChange={checkOrUnCheckAll}
                title={
                  <MyCheckbox
                    checked={isAllItemOnScreenAreCheck}
                    className="w-[13px] h-[13px]"
                    handleClickCheckbox={checkOrUnCheckAll}
                    id="tsrtsr"
                  />
                }
              ></ThNoSort>
            )}

            {/* th document type */}
            <ThWithSort
              className={`${
                !currentId
                  ? 'pl-[16px] fullhd:pl-[26px] w-[237px] '
                  : 'pl-[19px]  w-[250px] '
              }`}
              lastSortInfo={lastSortInfo}
              onClick={handleSorting.bind(
                null,
                sortKeywords.documentType,
                'string'
              )}
              sortKeyword={sortKeywords.documentType}
              sortList={sortList}
              title={'document type'}
            ></ThWithSort>

            {!currentId && (
              <React.Fragment>
                {/* th uploaded by */}
                <ThWithSort
                  className={`${
                    !currentId
                      ? 'pl-[16px] fullhd:pl-[26px]  w-[229px]'
                      : 'pl-[19px]  w-[158px] '
                  }`}
                  lastSortInfo={lastSortInfo}
                  onClick={handleSorting.bind(
                    null,
                    sortKeywords.uploadedBy,
                    'string'
                  )}
                  sortKeyword={sortKeywords.uploadedBy}
                  sortList={sortList}
                  title={'uploaded by'}
                ></ThWithSort>

                {/* th uploaded date/time */}
                <ThWithSort
                  className={`${
                    !currentId
                      ? 'pl-[16px] fullhd:pl-[26px]'
                      : 'pl-[19px]  w-[158px] '
                  }`}
                  lastSortInfo={lastSortInfo}
                  onClick={handleSorting.bind(
                    null,
                    sortKeywords.uploadTime,
                    'string'
                  )}
                  sortKeyword={sortKeywords.uploadTime}
                  sortList={sortList}
                  title={'uploaded date/time'}
                ></ThWithSort>

                {/* th label status (archived, rejected) */}
                <th className="w-[72px] pt-[13px] pb-[13px]" />

                {/* actions */}
                <th className="w-[68px] whitespace-nowrap px-0 cursor-pointer pt-[13px] pb-[13px]" />
              </React.Fragment>
            )}
          </tr>
        </thead>
        {/* // here render tbody tailwind */}
        <tbody className="">
          {loading && _.isEmpty(listDocument) ? (
            <React.Fragment>
              <tr className="h-1 bg-Gray1">
                <td colSpan="8" />
              </tr>
              <tr className="bg-white ">
                <td className="h-[60px] " colSpan="8">
                  <SpinnerIcon />
                </td>
              </tr>
            </React.Fragment>
          ) : _.isEmpty(listDocument) ? (
            <React.Fragment>
              <tr className="h-1 bg-Gray1">
                <td colSpan="6" />
              </tr>
              <tr className="bg-white ">
                <td className="h-[60px] " colSpan="6">
                  <div className="flex items-center justify-center ">
                    <Caption>No document</Caption>
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {(isEmpty(listDocument) ? [] : listDocument).map((data) => {
                return (
                  <React.Fragment key={data.idDocument}>
                    {/*  */}
                    <tr className="h-1 bg-Gray1">
                      <td colSpan="8" />
                    </tr>
                    <tr
                      className={clsx(
                        '  ',
                        (function () {
                          if (
                            listRecentlyEdittedIdDoc.includes(data.idDocument)
                          ) {
                            return 'animate-editted'
                          } else if (getStr(data, 'idDocument') === currentId) {
                            return 'bg-Primary text-white'
                          } else {
                            return ' bg-white hover:bg-LightestGray '
                          }
                        })()
                      )}
                    >
                      {/* td checkbox */}
                      {!currentId && (
                        <td className=" h-[60px] pl-[16px] fullhd:pl-[22px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]     leading-[150%]">
                          <MyCheckbox
                            checked={listIdDocumentSelected.includes(
                              data.idDocument
                            )}
                            className="w-[13px] h-[13px]"
                            handleClickCheckbox={() => {
                              checkDocument(data)
                            }}
                          />
                        </td>
                      )}

                      {/* td document type */}
                      <td
                        className={clsx(
                          'cursor-pointer breakword  h-[60px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%] ',
                          !currentId
                            ? ' pl-[16px] fullhd:pl-[26px] '
                            : ' pl-[19px] '
                        )}
                        onClick={() => handleClickRow(data)}
                      >
                        {getStr(data, 'documentType.name', '', 30)}
                      </td>

                      {!currentId && (
                        <React.Fragment>
                          {/* td uploaded by */}
                          <td
                            className="cursor-pointer breakword pl-[16px] fullhd:pl-[26px] h-[60px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%] "
                            onClick={() => handleClickRow(data)}
                          >
                            {getStr(data, 'uploadedBy', null, 46)}
                          </td>

                          {/* td uploaded date/time */}
                          <td
                            className="cursor-pointer breakword pl-[16px] fullhd:pl-[26px] h-[60px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%] "
                            onClick={() => handleClickRow(data)}
                          >
                            <Caption>
                              {/* {data.uploadTime
                                ? dayjs(data.uploadTime).format(
                                    `${displayDateFormat} - ${displayTimeFormat}`
                                  )
                                : ''} */}
                              {getStr(data, 'uploadTime')}
                            </Caption>
                            {renderTimeMeta(
                              dayjs(data.uploadTime.slice(0, 8)).format(
                                'MM-DD-YYYY'
                              )
                            )}
                          </td>

                          {/* td status (archived, rejected) */}
                          <td
                            className="cursor-pointer"
                            onClick={() => handleClickRow(data)}
                          >
                            {data.status === 'Archive' && <ArchivedBadge />}

                            {data.status === 'Reject' && <RejectedBadge />}
                          </td>

                          {/* td actions */}
                          <td className=" h-[60px] max-w-[68px] px-0 pt-[10px] pb-[10px] text-right">
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                padding: '8px 22px',
                                // minHeight: '68px',
                              }}
                            >
                              {renderDocumentActions(data)}
                            </div>
                          </td>
                        </React.Fragment>
                      )}
                    </tr>

                    {/*  */}
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          )}
        </tbody>
      </table>
    </div>
  )
}
;((_data) => {})([React])
