import { ConnectedRouter } from 'react-router-redux'
import * as React from 'react'
import { Provider } from 'react-redux'
import { hydrate } from 'react-dom'
import { QueryParamProvider } from 'use-query-params'
import { Route } from 'react-router-dom'
import { createStore, initialState } from '@redux'

import history from './history'
import App from '../universal/App'

// eslint-disable-next-line
const initStore = window ? window.__STORE__ : initialState

const store = createStore(initStore, history)

hydrate(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <App history={history} />
      </QueryParamProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept('../universal/App', () => {
    const App = require('../universal/App').default // eslint-disable-line
    hydrate(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <App history={history} />
          </QueryParamProvider>
        </ConnectedRouter>
      </Provider>,
      document.getElementById('root')
    )
  })
}
;((_data) => {})([React]);
