import classnames from 'classnames'
import React, { useEffect, useRef } from 'react'

import cls from './common.scss'

const StandardInput = ({
  id,
  label,
  trip_index,
  value,
  onChange,
  onBlur,
  isReadOnly = false,
}) => {
  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current && label === 'Purchase Order') {
      inputRef.current.focus()
    }
  }, [])

  const handleChange = (e) => {
    if (trip_index) {
      onChange(e, trip_index)
    } else {
      onChange(e)
    }
  }

  const handleBlur = (e) => {
    onBlur(e.target.name, e.target.value, trip_index)
  }

  return (
    <div
      className={classnames(cls.load_number_container, {
        [cls.reference]:
          id === 'add-load__reference' || id === 'edit-load__reference',
        [cls.bill_of_lading]: id === 'add-load__BOL' || id === 'edit-load__BOL',
        [cls.trailer]: label === 'Trailer (Optional)',
        [cls.appointment]: label === 'Appointment# (Optional)',
        [cls.note]: label === 'Note (Optional)',
      })}
    >
      <label className={cls.label}>{label}</label>
      {label === 'Note (Optional)' && (
        <textarea
          autoComplete="off"
          id={id}
          name={id}
          onBlur={handleBlur}
          onChange={handleChange}
          readOnly={isReadOnly}
          ref={inputRef}
          type="text"
          //maxLength="200"
          value={value}
        />
      )}
      {label !== 'Note (Optional)' && (
        <input
          autoComplete="off"
          id={id}
          maxLength="50"
          name={id}
          onBlur={handleBlur}
          onChange={handleChange}
          readOnly={isReadOnly}
          ref={inputRef}
          type="text"
          value={value}
        />
      )}
    </div>
  )
}

export default StandardInput
;((_data) => {})([React])
