import { MyCheckbox } from '@appComponent/MyCheckbox'
import { ThNoSort } from '@appComponent/ThNoSort'
import { ThWithSort } from '@appComponent/ThWithSort'
import { SpinnerIcon } from '@appComponent/spinnericon'
import { Caption } from '@appComponent/typography'
import {
  displayDateFormat,
  displayTimeFormat,
  formatReturnValue,
  getStr,
  renderTimeMeta,
} from '@helper'
import clsx from 'clsx'
import dayjs from 'dayjs'
import _, { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { sortKeywords } from './index'

export const OpenRequests = ({
  checkDocument,
  loading,
  sortList,
  lastSortInfo,
  handleSorting,
  listDocument,
  checkOrUnCheckAll,
  isAllItemOnScreenAreCheck,
  listIdDocumentSelected,
  renderDocumentActions,
}) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const smartetaFlag = _.get(user, 'smarteta')
  const isDocumentOnlyCompany = useMemo(() => {
    return smartetaFlag === 'false'
  }, [smartetaFlag])
  return (
    <div className=" w-full min-w-[1400px] ">
      <table className="w-full table-auto border-collapse">
        <thead>
          <tr className=" bg-LightestGray">
            {/* th checkbox all */}

            <ThNoSort
              className="w-[38px]"
              classNamePadding="pl-[16px] fullhd:pl-[22px]"
              onChange={checkOrUnCheckAll}
              title={
                <MyCheckbox
                  checked={isAllItemOnScreenAreCheck}
                  className="w-[13px] h-[13px]"
                  handleClickCheckbox={checkOrUnCheckAll}
                  id="tsrtsr"
                />
              }
            ></ThNoSort>

            {/* th load# */}
            <ThWithSort
              className="pl-[16px] fullhd:pl-[26px] w-[147px]"
              lastSortInfo={lastSortInfo}
              onClick={handleSorting.bind(
                null,
                sortKeywords.loadNumber,
                'string'
              )}
              sortKeyword={sortKeywords.loadNumber}
              sortList={sortList}
              title={'load#'}
            ></ThWithSort>

            {/* th stop name */}
            {!isDocumentOnlyCompany && (
              <ThNoSort className="w-[310px] " title={'stop name'}></ThNoSort>
            )}

            {/* th document# */}
            <ThWithSort
              className="pl-[16px] fullhd:pl-[26px] w-[229px]"
              lastSortInfo={lastSortInfo}
              onClick={handleSorting.bind(
                null,
                sortKeywords.documentType,
                'string'
              )}
              sortKeyword={sortKeywords.documentType}
              sortList={sortList}
              title={'document#'}
            ></ThWithSort>

            {/* th requestors */}
            <ThWithSort
              className="pl-[16px] fullhd:pl-[26px]  w-[185px]"
              lastSortInfo={lastSortInfo}
              onClick={handleSorting.bind(
                null,
                sortKeywords.requestorName,
                'string'
              )}
              sortKeyword={sortKeywords.requestorName}
              sortList={sortList}
              title={'requestors'}
            ></ThWithSort>

            {/* th requestees */}
            <ThWithSort
              className="pl-[16px] fullhd:pl-[26px] w-[188px] "
              lastSortInfo={lastSortInfo}
              onClick={handleSorting.bind(
                null,
                sortKeywords.requesteeName,
                'string'
              )}
              sortKeyword={sortKeywords.requesteeName}
              sortList={sortList}
              title={'requestees'}
            ></ThWithSort>

            {/* th request date/time */}
            <ThWithSort
              className="pl-[16px] fullhd:pl-[26px]"
              lastSortInfo={lastSortInfo}
              onClick={handleSorting.bind(
                null,
                sortKeywords.requestedAt,
                'string'
              )}
              sortKeyword={sortKeywords.requestedAt}
              sortList={sortList}
              title={'request date/time'}
            ></ThWithSort>

            {/* actions */}
            <th className="w-[68px] whitespace-nowrap px-0 cursor-pointer pt-[13px] pb-[13px]" />
          </tr>
        </thead>
        {/* // here render tbody tailwind */}
        <tbody className="">
          {loading && _.isEmpty(listDocument) ? (
            <React.Fragment>
              <tr className="h-1 bg-Gray1">
                <td colSpan="8" />
              </tr>
              <tr className="bg-white ">
                <td className="h-[60px] " colSpan="8">
                  <SpinnerIcon />
                </td>
              </tr>
            </React.Fragment>
          ) : _.isEmpty(listDocument) ? (
            <React.Fragment>
              <tr className="h-1 bg-Gray1">
                <td colSpan="8" />
              </tr>
              <tr className="bg-white ">
                <td className="h-[60px] " colSpan="8">
                  <div className="flex items-center justify-center ">
                    <Caption>No document</Caption>
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {(isEmpty(listDocument) ? [] : listDocument).map((data) => {
                return (
                  <React.Fragment key={data.idDocument}>
                    {/*  */}
                    <tr className="h-1 bg-Gray1">
                      <td colSpan="8" />
                    </tr>
                    <tr className="bg-white hover:bg-LightestGray">
                      {/* td checkbox */}
                      <td className=" h-[60px] pl-[16px] fullhd:pl-[22px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]     leading-[150%]">
                        <MyCheckbox
                          checked={listIdDocumentSelected.includes(
                            data.idDocument
                          )}
                          className="w-[13px] h-[13px]"
                          handleClickCheckbox={() => {
                            checkDocument(data)
                          }}
                        />
                      </td>

                      {/* load# */}
                      <td
                        className={clsx(
                          'breakword  h-[60px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%] pl-[16px] fullhd:pl-[26px] '
                        )}
                      >
                        {formatReturnValue(data.loadNumber, 24)}
                      </td>

                      {/* td stop name */}
                      {!isDocumentOnlyCompany && (
                        <td
                          className={
                            'breakword pl-[16px] fullhd:pl-[26px] h-[60px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]'
                          }
                        >
                          {getStr(data, 'documentTrip.tripName', '', 83)}
                        </td>
                      )}

                      {/* td document# */}
                      <td
                        className={
                          'breakword pl-[16px] fullhd:pl-[26px] h-[60px] pr-0 pt-[10px] pb-[10px] text-left   text-[14px]   leading-[150%] '
                        }
                      >
                        {`${getStr(data, 'documentTypeName')}${
                          data.documentNumber
                            ? ` #${getStr(data, 'documentNumber', '', 22)}`
                            : ''
                        }`}
                      </td>

                      {/* td requestors */}
                      <td className="breakword pl-[16px] fullhd:pl-[26px] h-[60px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%] ">
                        {getStr(data, 'requestorName', null, 48)}
                      </td>

                      {/* td requestees */}
                      <td className="breakword pl-[16px] fullhd:pl-[26px] h-[60px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%] ">
                        {getStr(data, 'requesteeName', null, 44)}
                      </td>

                      {/* td request date/time */}
                      <td className="breakword pl-[16px] fullhd:pl-[26px] h-[60px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%] ">
                        <Caption>
                          {data.requestedAt
                            ? dayjs(data.requestedAt).format(
                                `${displayDateFormat} - ${displayTimeFormat}`
                              )
                            : ''}
                        </Caption>
                        {renderTimeMeta(data.requestedAt)}
                      </td>

                      {/* td actions */}
                      <td className=" h-[60px] max-w-[68px] px-0 pt-[10px] pb-[10px] text-right">
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            padding: '8px 22px',
                            // minHeight: '68px',
                          }}
                        >
                          {renderDocumentActions(data)}
                        </div>
                      </td>
                    </tr>

                    {/*  */}
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          )}
        </tbody>
      </table>
    </div>
  )
}
;((_data) => {})([React])
