import React, { useEffect, useMemo, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'

import { Button } from '@appComponent/button'
import {
  IconLink,
  IconRemoveCircleOutline24,
  IconX14,
} from '@appComponent/icon'
import { Caption, Small } from '@appComponent/typography'
import apiRoute from '@constants/apiRoute'
import { customAxios, getErrorMessage, renderDocumentTitle } from '@helper'
import { useGroups } from '@hooks'
import { Checkbox, Drawer } from 'antd'
import classnames from 'classnames'
import produce from 'immer'
import _, { identity, isEmpty, toLower, trim } from 'lodash'
import { Textarea2 } from '@appComponent/input'
import cls from './documents.scss'
import { checkValidEmail } from '@@/utils'
import { Input2 } from '@@/App/@components/input'
import { REGX_EMAIL_HEAD } from '../Loadsnew/components/common/constants'

const DrawerSendDocument = identity(
  ({ context, token, setListDocForSendDoc, listDocForSendDoc }) => {
    const { listIdCheckedGroups } = useGroups()
    const [savingOrDispatching, setSavingOrDispatching] = useState(false)
    // const inputRef = useState('texte')
    const inputRef = useState('')
    const inputRef2 = useRef()

    const [html, setHtml] = useState('')
    const [chips, setChips] = useState([])
    const [errorEmailField, setErrorEmailField] = useState('')

    const [emailSubject, setEmailSubject] = useState('Document(s)')
    const [message, setMessage] = useState(
      'Please see the attached document(s).'
    )
    const [isSendCopy, setIsSendCopy] = useState(false)

    const resetFields = () => {
      setHtml('')
      setChips([])
      setEmailSubject('Document(s)')
      setMessage('Please see the attached document(s).')
      setIsSendCopy(false)
      setErrorEmailField('')
    }

    const someChipsInvalid = useMemo(() => {
      if (isEmpty(chips)) {
        return false
      }
      return chips.some((o) => !checkValidEmail(o))
    }, [chips])

    useEffect(() => {
      if (!someChipsInvalid) {
        setErrorEmailField('')
      }
    }, [someChipsInvalid])

    const isDisable = useMemo(() => {
      if (_.isEmpty(chips)) {
        return true
      }

      if (someChipsInvalid) {
        return true
      }

      if (!emailSubject) {
        return true
      }

      return false
    }, [emailSubject, chips, someChipsInvalid])

    const showSendDoc = useMemo(
      () => _.size(listDocForSendDoc) >= 1,
      [listDocForSendDoc]
    )

    const listIdForSendDoc = useMemo(
      () => _.map(listDocForSendDoc, (o) => o.idDocument),
      [listDocForSendDoc]
    )

    const totalSizeSendDoc = useMemo(() => {
      let total = 0
      listDocForSendDoc.forEach((doc) => {
        let size = _.get(doc, 'size') || 0
        total += Number(size)
      })
      return total
    }, [listDocForSendDoc])

    const removeChipData = (indexToRemove) => {
      setChips([...chips.filter((_, index) => index !== indexToRemove)])
    }

    const onClose = () => {
      setListDocForSendDoc([])
    }

    const handleClickSend = async () => {
      try {
        setSavingOrDispatching(true)

        if (totalSizeSendDoc > 9.95) {
          context.message.error(
            'Document sizes exceed limit. Please reduce number of documents and try again.'
          )
          return
        }

        const resp = await customAxios(token).post(
          `${
            apiRoute.documents.SEND_DOCUMENT
          }?groups=${listIdCheckedGroups.join(',')}`,
          {
            idDocumentList: listIdForSendDoc,
            recipientList: chips,
            subject: emailSubject,
            message,
            sendMeCopy: isSendCopy,
          }
        )
        if (!resp.data.success === true) {
          return
        }
        context.message.success('Send documents successfully')
        setListDocForSendDoc([])
      } catch (err) {
        context.message.error(getErrorMessage(err))
      } finally {
        setTimeout(() => {
          setSavingOrDispatching(false)
        }, 3888)
      }
    }

    useEffect(() => {
      if (showSendDoc === true) {
        setTimeout(() => {
          if (inputRef) {
            inputRef.current.focus()

            inputRef.current.addEventListener('paste', function (e) {
              // cancel paste
              e.preventDefault()

              // get text representation of clipboard
              var text = (e.originalEvent || e).clipboardData.getData(
                'text/plain'
              )

              // insert text manually
              document.execCommand('insertHTML', false, text)
            })
          }
        }, 200)
      } else {
        resetFields()
      }
    }, [showSendDoc])

    const addChip = () => {
      if (!html) {
        return
      }
      const INVALID_EMAIL_TEXT = 'Invalid email format.'
      const action = () => {
        setChips([...chips, html.trim().replaceAll('&nbsp;', '')])
        setHtml('')
      }

      const r = /[a-zA-Z0-9]/
      const numberOfAtSign = html.split('').filter((o) => o === '@').length

      if (numberOfAtSign === 0) {
        setErrorEmailField(INVALID_EMAIL_TEXT)
      } else if (!r.test(html[0])) {
        setErrorEmailField(
          'The first character must be an ascil letter (a-z) or number (0-9).'
        )
      } else if (numberOfAtSign >= 2) {
        setErrorEmailField(INVALID_EMAIL_TEXT)
      } else {
        const indexOfAtSign = html.indexOf('@')
        const head = html.substring(0, indexOfAtSign)
        const tail = html.substring(indexOfAtSign, 10000000000)
        if (!/^@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/.test(tail)) {
          setErrorEmailField(INVALID_EMAIL_TEXT)
        } else if (!REGX_EMAIL_HEAD.test(head)) {
          setErrorEmailField(
            'Only letters (a-z), numbers (0-9), periods (.), underscore (_), hyphen (-), and plus sign (+) are allowed.'
          )
        }
      }

      action()
    }

    return (
      <Drawer
        bodyStyle={{ paddingBottom: 80 }}
        className={classnames(cls.wr_drawer_upload_doc, 'wr_drawer_upload_doc')}
        destroyOnClose
        footer={
          <div
            className={`wr_footer_add_load ${
              savingOrDispatching ? 'disabled1' : ''
            }`}
          >
            <Button
              onClick={savingOrDispatching ? null : onClose}
              stateClass={savingOrDispatching ? 'Disabled' : 'Secondary'}
              width={74}
            >
              Cancel
            </Button>
            <div className="wr_save_dispatch">
              <Button
                onClick={
                  isDisable || savingOrDispatching ? null : handleClickSend
                }
                stateClass={
                  isDisable || savingOrDispatching ? 'Disabled' : 'Primary'
                }
                width={63}
              >
                Send
              </Button>
            </div>
          </div>
        }
        maskClosable={false}
        onClose={onClose}
        title="Send Document"
        visible={showSendDoc}
        width={552}
      >
        <div className={cls.form_group}>
          <label htmlFor="receipient_email_address  ">
            <Small>Receipient’s Email Address(es)</Small>
          </label>

          <div
            className={`${cls.chip_input} ${errorEmailField ? cls.error : ''}`}
            onClick={() => {
              inputRef.current.focus()
            }}
            ref={inputRef2}
          >
            <ul className={cls.chips}>
              {chips.map((chip, index) => (
                <Chip
                  chip={chip}
                  index={index}
                  key={chip}
                  removeChipData={removeChipData}
                />
              ))}
              <ContentEditable
                className={cls.editable}
                disabled={false}
                html={html} // innerHTML of the editable div
                innerRef={inputRef} // use true to disable editing
                onBlur={() => {
                  inputRef2.current.classList.remove('inputfocused')
                  addChip()
                }}
                onChange={(evt) => {
                  const value = evt.target.value || ''
                  const trimmedValue = toLower(
                    trim(value.replaceAll('&nbsp;', ''))
                  )
                  setHtml(trimmedValue)
                }}
                onFocus={() => {
                  inputRef2.current.classList.add('inputfocused')
                }} // handle innerHTML change
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
                    e.preventDefault()
                    addChip()
                  } else if (e.key === 'Backspace') {
                    if (html === '') {
                      e.preventDefault()
                      if (chips.length === 0) {
                        return
                      }
                      const newChips = Array.from(chips)
                      // let last = newChips[newChips.length - 1]
                      // setHtml(last)
                      newChips.length -= 1
                      setChips(newChips)
                    }
                  } else if (e.key === 'Tab') {
                    addChip()
                  } else {
                    if (someChipsInvalid) {
                      e.preventDefault()
                      return
                    }
                  }
                }}
              />
            </ul>
          </div>

          {errorEmailField && (
            <div className="text-Red mt-1 text-[12px] leading-[150%] ">
              {errorEmailField}
            </div>
          )}
        </div>
        <div className={cls.form_group}>
          <label htmlFor="email_subject">
            <Small>Email Subject</Small>
          </label>
          <Input2
            id="email_subject"
            onChange={(e) => {
              setEmailSubject(e.target.value)
            }}
            value={emailSubject}
          />
        </div>

        <div className={cls.form_group}>
          <label htmlFor="message">
            <Small>Message (Optional)</Small>
          </label>
          <Textarea2
            className="height172"
            id="message"
            onChange={(e) => setMessage(e.target.value)}
            rows={7}
            value={message}
          />
        </div>

        <div className={classnames(cls.form_group, cls.label_inline)}>
          <Checkbox
            onChange={(e) => {
              setIsSendCopy(e.target.checked)
            }}
            value={isSendCopy}
          >
            Send me a copy
          </Checkbox>
        </div>
        <Caption block className="mt24 mb16">
          Attached Documents
        </Caption>
        <div className={cls.wr_list_attach}>
          {listDocForSendDoc.map((o) => (
            <div className={cls.row_attach} key={o.idDocument}>
              <div className={cls.wr_link}>
                <IconLink />
              </div>
              <Caption>{renderDocumentTitle(o)}</Caption>
              <div className={cls.spacer} />
              {listDocForSendDoc.length >= 2 && (
                <div
                  className={cls.btn_remove_attach}
                  onClick={() => {
                    const nextState = produce(listDocForSendDoc, (draft) => {
                      const findIndex = draft.findIndex(
                        (attach) => attach.idDocument === o.idDocument
                      )
                      if (findIndex !== -1) {
                        draft.splice(findIndex, 1)
                      }
                      return draft
                    })
                    setListDocForSendDoc(nextState)
                  }}
                >
                  <IconRemoveCircleOutline24 />
                </div>
              )}
            </div>
          ))}
        </div>
      </Drawer>
    )
  }
)

export default DrawerSendDocument

const Chip = ({ chip, index, removeChipData }) => {
  const isValid = useMemo(() => checkValidEmail(chip), [chip])
  return (
    <li className={classnames(cls.chip, { [cls.invalid]: !isValid })}>
      <span className={cls.chip_title}>{chip}</span>
      <IconX14
        className={classnames(cls.chip_close_icon, 'no-hover-effect')}
        fill={isValid ? '#50565e' : '#fff'}
        onClick={() => removeChipData(index)}
      />
    </li>
  )
}
;((_data) => {})([React])
