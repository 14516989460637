import classNames from 'classnames'
import React, { useState } from 'react'
import { UikButton, UikHeadline } from '../../../components'
import { clientId, clientSecret, provider } from '../../../constants'
import logo from '../../../driverDocAssets/DriverDocMainLogo.png'
import { withContext } from '../../../hoc'
import { H2 } from '../../@components/typography'
import createAuthContext from '../../lib/createAuthContext'
import cls from './all-set.scss'

// @flow
const { AuthContext, Authenticated } = createAuthContext({
  clientId,
  clientSecret,
  provider,
  // tokenEndpoint: 'http://localhost:3020/token' // If token endpoint is not "provider + '/token'"
})
function ProtectedStuff() {
  return <Authenticated>This would be visible only if logged in.</Authenticated>
}
function AllSet({ history }) {
  const [showProtected, setShowProtected] = useState(false)

  const handleLogin = () => {
    setShowProtected(!showProtected)
  }
  return (
    <AuthContext history={history}>
      <div className={cls.all_set}>
        <div className={classNames(cls.img_left, cls.c, cls.c1)}>
          <img alt="logo" className={cls.img_logo} src={logo} />
        </div>
        <div className={cls.all_set_form}>
          <div className={cls.form}>
            <UikHeadline className={cls.headline}>
              <H2>You’re all set!</H2>
            </UikHeadline>
            <UikButton className={cls.btn_login} onClick={handleLogin}>
              <span className={cls.value}>Log in</span>
            </UikButton>
            {showProtected && <ProtectedStuff />}
          </div>
        </div>
      </div>
    </AuthContext>
  )
}

export default withContext(AllSet)
;((_data) => {})([React])
