import createCodeVerifier from './create-code-verifier'
import getEncodedVerifierKey from './getEncodedVerifierKey'
import hashed from './hashed'
import { base64URLEncode, sha256 } from './sha256-base64-url-encode'

export default function authorize({
  provider,
  clientId,
  scopes,
  storage = sessionStorage,
}) {
  console.log('aaa 12')
  const redirect_uri = window.location.toString()

  const encodedVerifier = base64URLEncode(createCodeVerifier())
  storage.setItem(
    getEncodedVerifierKey(clientId),
    JSON.stringify({
      encodedVerifier,
      redirect_uri,
    })
  )

  const query = {
    client_id: clientId,
    response_type: 'code',
    redirect_uri: process.env.RAZZLE_APP_DOMAIN_NAME,
    scope: 'aws.cognito.signin.user.admin+email+openid',
    code_challenge: base64URLEncode(sha256(encodedVerifier)),
    code_challenge_method: 'S256',
  }

  if (scopes && scopes.length > 0) {
    query.scope = scopes.join(' ')
  }

  const url = `${provider}/login?${hashed(query)}`
  console.log(url)
  window.location.replace(url)
}
