import React from 'react'
const IconDocument = ({ fill }) => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3333 18.6663C10.597 18.6663 10 19.2633 10 19.9997C10 20.7361 10.597 21.333 11.3333 21.333H20.6667C21.403 21.333 22 20.7361 22 19.9997C22 19.2633 21.403 18.6663 20.6667 18.6663H11.3333Z"
      fill={fill ? fill : '#50565E'}
    />
    <path
      d="M10 23.9997C10 23.2633 10.597 22.6663 11.3333 22.6663H16C16.7364 22.6663 17.3333 23.2633 17.3333 23.9997C17.3333 24.7361 16.7364 25.333 16 25.333H11.3333C10.597 25.333 10 24.7361 10 23.9997Z"
      fill={fill ? fill : '#50565E'}
    />
    <path
      d="M20.9428 9.60915C21.4635 9.08845 21.4635 8.24423 20.9428 7.72353C20.4221 7.20283 19.5779 7.20283 19.0572 7.72353L14.6667 12.1141L12.9428 10.3902C12.4221 9.8695 11.5779 9.8695 11.0572 10.3902C10.5365 10.9109 10.5365 11.7551 11.0572 12.2758L13.7239 14.9425C14.2446 15.4632 15.0888 15.4632 15.6095 14.9425L20.9428 9.60915Z"
      fill={fill ? fill : '#50565E'}
    />
    <path
      clipRule="evenodd"
      d="M6.66667 1.33301C5.19391 1.33301 4 2.52691 4 3.99967V27.9997C4 29.4725 5.19392 30.6663 6.66667 30.6663H25.3333C26.8061 30.6663 28 29.4725 28 27.9997V3.99967C28 2.52693 26.8061 1.33301 25.3333 1.33301H6.66667ZM6.66667 3.99967H25.3333V27.9997H6.66667V3.99967Z"
      fill={fill ? fill : '#50565E'}
      fillRule="evenodd"
    />
  </svg>
)
export default IconDocument
;((_data) => {})([React])
