import React from 'react'

const IconCheck = ({ color, height, width }) => (
  <svg
    width={width || '15'}
    height={height || '15'}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.46265 2.96289C3.62321 1.80235 5.22818 1.0835 6.99992 1.0835C8.77166 1.0835 10.3766 1.80235 11.5372 2.96289L11.1247 3.37537L11.5372 2.9629C12.6977 4.12346 13.4166 5.72842 13.4166 7.50016C13.4166 9.2719 12.6977 10.8769 11.5372 12.0374C10.3766 13.198 8.77166 13.9168 6.99992 13.9168C5.22818 13.9168 3.62321 13.198 2.46265 12.0374L2.87513 11.6249L2.46265 12.0374C1.3021 10.8769 0.583252 9.2719 0.583252 7.50016C0.583252 5.72842 1.3021 4.12346 2.46265 2.9629L2.46265 2.96289ZM6.99992 2.25016C5.55003 2.25016 4.23832 2.83715 3.2876 3.78785C2.3369 4.73857 1.74992 6.05027 1.74992 7.50016C1.74992 8.95006 2.33691 10.2618 3.28761 11.2125C4.23833 12.1632 5.55003 12.7502 6.99992 12.7502C8.44981 12.7502 9.76151 12.1632 10.7122 11.2125C11.6629 10.2618 12.2499 8.95006 12.2499 7.50016C12.2499 6.05027 11.6629 4.73857 10.7122 3.78785C9.76151 2.83715 8.44981 2.25016 6.99992 2.25016ZM10.3291 5.33768C10.5569 5.56549 10.5569 5.93484 10.3291 6.16264L6.82906 9.66264C6.60126 9.89045 6.23191 9.89045 6.00411 9.66264L4.25411 7.91264C4.0263 7.68484 4.0263 7.31549 4.25411 7.08768C4.48191 6.85988 4.85126 6.85988 5.07906 7.08768L6.41659 8.42521L9.50411 5.33768C9.73191 5.10988 10.1013 5.10988 10.3291 5.33768Z"
      fill={color || '#50565E'}
    />
  </svg>
)
export default IconCheck
;((_data) => {})([React])
