/* eslint-disable react/jsx-indent */
import { atom } from 'jotai'
import React from 'react'
import { ModalDoneUser } from '../App/@components/ModalCongratulationUser'
import { ModalCongratulations } from '../App/@components/ModalCongratulations'
import { ModalFinalCongratulations } from '../App/@components/ModalFinalCongratulations'

let initialUserName = ''
if (typeof window !== 'undefined') {
  initialUserName = window.localStorage.getItem('user_name')
}

export const companyGroupsAtom = atom([])
export const userNameAtom = atom(initialUserName)
export const userLocalStateAtom = atom('')

export const instancePdfAtom = atom(null)
export const isDefaultSplitModeAtom = atom(false)

export const tokenDataAtom = atom({})

// const [tokenData, setTokenData] = useAtom(tokenDataAtom)

export const remainingTimeAtom = atom('')
export const currentPlanAtom = atom('')
// const [currentPlan, setCurrentPlan] = useAtom(currentPlanAtom)

export const paymentStatusAtom = atom('')

export const hasPaymentMethodAtom = atom(false)
export const loadingPaymentAtom = atom(true)
// const [loadingPayment, setLoadingPayment] = useAtom(loadingPaymentAtom)

// const [paymentStatus, setPaymentStatus] = useAtom(paymentStatusAtom)
// const [hasPaymentMethod, setHasPaymentMethod] = useAtom(hasPaymentMethodAtom)
// const [remainingTime, setRemainingTime] = useAtom(remainingTimeAtom)

export const emailVerification = atom('')
export const showChargebeeElementsAtom = atom(false)
export const runTour = atom(false)
export const steps = atom([
  {
    content: <div className="text-xs">Add and manage your drivers</div>,
    placement: 'right-center',
    offset: -8,
    target: '.demo__projects',
    spotlightClicks: true,
    disableBeacon: true,
    spotlightPadding: 0,
    styles: {
      spotlight: {
        // Set your border-radius here!
        borderRadius: 0,
      },
    },
  },
  {
    content: (
      <div
        className="text-xs whitespace-normal "
        style={{
          width: '137px',
        }}
      >
        Click to add a single driver or multiple drivers
      </div>
    ),
    placement: 'left-center',
    target: '.demo__projects2',
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    styles: {
      spotlight: {
        // Set your border-radius here!
        borderRadius: '50%',
      },
    },
  },
  {
    content: <div />,
    placement: 'left-center',
    target: '.demo__projects3',
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 0,
  },
  {
    content: (
      <div>
        <ModalCongratulations />
      </div>
    ),
    placement: 'center',
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: 'body',
  },
  {
    content: <div className="text-xs">Add and manage your users</div>,
    placement: 'right-center',
    disableBeacon: true,
    offset: -8,
    target: '.demo__projects4',
    spotlightClicks: true,
    spotlightPadding: 0,
    styles: {
      spotlight: {
        // Set your border-radius here!
        borderRadius: 0,
      },
    },
  },
  {
    content: <div className="text-xs">Click to add a user</div>,
    placement: 'left-center',
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.demo__projects5',
    spotlightClicks: true,
    spotlightPadding: 0,
  },
  {
    content: <div />,
    placement: 'left-center',
    target: '.demo__projects6',
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 0,
  },
  {
    content: (
      <div>
        <ModalDoneUser />
      </div>
    ),
    placement: 'center',
    target: 'body',
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    content: (
      <div>
        <ModalFinalCongratulations />
      </div>
    ),
    placement: 'center',
    target: 'body',
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
])
export const currentStepOfFlow = atom(0)
export const noDriver = atom(false)
export const openAddDriver = atom('')
// const [showChargebeeElements, setShowChargebeeElements] = useAtom(
//   showChargebeeElementsAtom
// )
;((_data) => {})([React])
