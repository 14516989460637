import {
  ArrowSelect,
  IconNextPagination,
  IconPrevPagination,
} from '@appComponent/icon'
import { Pagination, Select } from 'antd'
import classnames from 'classnames'
import React from 'react'

const { Option } = Select

const MyPagination = ({
  page,
  setPage,
  total,
  pageSize,
  setPageSize,
  handleChangePageSize,
  triggerFetch,
  setTriggerFetch,
  loading,
  className,
  simple,
}) => {
  const onShowSizeChange = (current, pageSize) => {
    handleChangePageSize(pageSize)
  }
  return (
    <div className={classnames('wr_pagination', className)}>
      <Pagination
        current={page}
        disabled={loading}
        itemRender={(page, type, originalElement) => {
          if (type === 'page') {
            return originalElement
          }
          if (type === 'prev') {
            return <IconPrevPagination></IconPrevPagination>
          }
          if (type === 'next') {
            return <IconNextPagination></IconNextPagination>
          }
          return originalElement
        }}
        onChange={(p) => {
          setPage(p)
          setTriggerFetch(triggerFetch + 1)
        }}
        onShowSizeChange={onShowSizeChange}
        pageSize={pageSize}
        showSizeChanger={false}
        showTotal={(_total, range) =>
          `${range[0]}-${range[1]} of ${_total} items`
        }
        simple={simple}
        total={total}
      />
      {simple === true ? null : (
        <Select
          onChange={(value) => setPageSize(value)}
          suffixIcon={<ArrowSelect></ArrowSelect>}
          value={pageSize}
        >
          <Option value={10}>
            <span className="ant_option_size">10/ page</span>
          </Option>
          <Option value={20}>
            <span className="ant_option_size">20/ page</span>
          </Option>
          <Option value={50}>
            <span className="ant_option_size">50/ page</span>
          </Option>
          <Option value={100}>
            <span className="ant_option_size">100/ page</span>
          </Option>
        </Select>
      )}
    </div>
  )
}
export default MyPagination
;((_data) => {})([React])
