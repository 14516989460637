import { formatReturnValue } from '@helper'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import createPersistedState from 'use-persisted-state'

const useGroupsState = createPersistedState('groups')
const useCheckedGroupsState = createPersistedState('checkedgroups')

const useGroups = () => {
  const [groups, setGroups] = useGroupsState([])

  const [checkedGroups, setCheckedGroups] = useCheckedGroupsState([])

  const [loadinggroups, setLoadinggroups] = useState(false)

  const listIdCheckedGroups = useMemo(() => {
    if (_.isEmpty(checkedGroups)) {
      return []
    }
    return checkedGroups.map((o) => o.idGroup)
  }, [checkedGroups])

  const groupsCheckedString = useMemo(() => {
    let str = ''

    const checkedGroupsSorted = _.orderBy(checkedGroups, ['name'])
    checkedGroupsSorted.forEach((group) => {
      str += `${group.name}, `
    })
    if (!str) {
      //
    } else {
      const index = str.lastIndexOf(', ')
      if (index !== -1) {
        str = str.substr(0, index)
        str = formatReturnValue(str, 23)
      }
    }
    return str
  }, [checkedGroups])

  return {
    groups,
    setGroups,
    checkedGroups,
    setCheckedGroups,
    listIdCheckedGroups,
    groupsCheckedString,
    loadinggroups,
    setLoadinggroups,
  }
}

export default useGroups
