import { MyCheckbox } from '@appComponent/MyCheckbox'
import { Button } from '@appComponent/button'
import { ArrowSelect, DeleteSearch2 } from '@appComponent/icon'
import { Input2 } from '@appComponent/input'
import clsInput from '@appComponent/input/input.scss'
import { Caption, Small } from '@appComponent/typography'
import { companyGroupsAtom, tokenDataAtom, userNameAtom } from '@atoms/atoms'
import apiRoute from '@constants/apiRoute'
import {
  customAxios,
  equalStr,
  formatReturnValue,
  getErrorMessage,
  getStr,
} from '@helper'
import { useClickOutside, useGroups } from '@hooks'
import { getActiveGroups, updateMyGroups } from '@service'
import { Drawer, Select } from 'antd'
import classnames from 'classnames'
import produce from 'immer'
import { useAtom } from 'jotai'
import _, { identity, isEmpty, toLower, trim } from 'lodash'
import moment from 'moment-timezone'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { SpinnerIcon } from '../../../../@components/spinnericon'
import cls from './users.scss'
import { MyInputPhone } from '../../../../@components/MyInputPhone'

const { Option } = Select

const DrawerEditUser = identity(
  ({
    idEditUser,
    setIdEditUser,
    token,
    context,
    set_time_request,
    setListRecentlyEdittedIdDoc,
  }) => {
    const [tokenData] = useAtom(tokenDataAtom)
    const { setGroups, setLoadinggroups, checkedGroups, setCheckedGroups } =
      useGroups()
    const [, setUserName] = useAtom(userNameAtom)

    const [loadingUser, setLoadingUser] = useState(false)

    const [fn, setFn] = useState('')
    const [ln, setLn] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [ext, setExt] = useState('')

    const [savingOrDispatching, setSavingOrDispatching] = useState(false)
    const [phoneError, setPhoneError] = useState('')

    const [textsearchgroup, setTextsearchgroup] = useState('')
    const [textsearchgroupDebounce] = useDebounce(textsearchgroup, 300)
    const [companyGroups, setCompanyGroups] = useAtom(companyGroupsAtom)
    const [activeGroups, setActiveGroups] = useState([])
    const [checkedActiveGroups, setCheckedActiveGroups] = useState([])
    const [loadingActiveGroups, setLoadingActiveGroups] = useState(false)

    const [arrRole, setArrRole] = useState([])
    const [idRoleSelected, setIdRoleSelected] = useState(0)

    const [showDropdown, setShowDropdown] = useState(false)
    const [status, setStatus] = useState('')
    const [countryCode, setCountryCode] = useState('1')
    const [country, setCountry] = useState('us')
    const ref = useRef(null)

    useClickOutside(ref, () => {
      setShowDropdown(false)
      setTextsearchgroup(undefined)
    })

    const setEmailLowerCase = useCallback(
      (value) => {
        const valuee = toLower(value || '')
        setEmail(valuee)
      },
      [setEmail]
    )

    const onClose = () => {
      setIdEditUser(null)
    }

    const listIdCheckedActiveGroups = useMemo(
      () => checkedActiveGroups.map((o) => o.idGroup),
      [checkedActiveGroups]
    )

    const groupsCheckedString = useMemo(() => {
      let str = ''
      checkedActiveGroups.forEach((group) => {
        str += `${group.name}, `
      })
      if (!str) {
        str = str.replace
      } else {
        const index = str.lastIndexOf(', ')
        if (index !== -1) {
          str = str.substr(0, index)
          str = formatReturnValue(str, 36)
        }
      }
      return str
    }, [checkedActiveGroups])

    const handleClickSave = async () => {
      let valid = true

      if (phone.length > 1 && phone.length < 10) {
        setPhoneError('Please enter a valid phone number')
        valid = false
      } else if (phone.charAt(0) === '0') {
        setPhoneError('Phone number does not exist')
        valid = false
      }

      if (!valid) {
        return
      }
      try {
        setSavingOrDispatching(true)

        const body = {
          firstName: trim(fn),
          lastName: trim(ln),
          email: trim(email),
          phoneNumber: phone,
          isoCode: country.toUpperCase(),
          countryCode,
          ext,
          listIdGroup: listIdCheckedActiveGroups,
          idRole: idRoleSelected,
        }
        const resp = await customAxios(token).put(
          `${apiRoute.users.EDIT}/${idEditUser}`,
          body
        )
        if (!resp.data.success) {
          context.message.error('Updating User Failed!')
          return
        }

        set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
        context.message.success('Edited Successfully')

        // update user name
        const userLocalStorage = JSON.parse(localStorage.getItem('user'))
        const id_user = String(getStr(userLocalStorage, 'id_user'))
        if (String(idEditUser) === id_user) {
          updateMyGroups(
            token,
            setGroups,
            setLoadinggroups,
            checkedGroups,
            setCheckedGroups
          )
          const fullName = `${fn} ${ln}`
          setUserName(fullName)
          localStorage.setItem('user_name', fullName)
        }

        setListRecentlyEdittedIdDoc([idEditUser])
        setIdEditUser(null)
      } catch (err) {
        context.message.error(getErrorMessage(err))
      } finally {
        setTimeout(() => {
          setSavingOrDispatching(false)
        }, 3888)
      }
    }

    useEffect(() => {
      async function getUserById(id) {
        try {
          setLoadingUser(true)
          // setListDocuments([])
          const { data: dataResponse } = await customAxios(token).get(
            `${apiRoute.users.GET_USER_BY_ID}/${id}`
          )
          if (!dataResponse.success) return
          setPhone(getStr(dataResponse, 'data.phone_number'))
          setCheckedActiveGroups(_.get(dataResponse, 'data.groups'))
          setStatus(dataResponse.data.status)
          setFn(getStr(dataResponse, 'data.first_name'))
          setLn(getStr(dataResponse, 'data.last_name'))
          setEmailLowerCase(getStr(dataResponse, 'data.email'))
          const isoCode = dataResponse.data.iso_code
          if (isoCode) {
            setCountry(isoCode)
          }

          const countryCode = dataResponse.data.country_code

          if (countryCode) {
            setCountryCode(countryCode || '')
          }
          setExt(getStr(dataResponse, 'data.ext'))
          setIdRoleSelected(dataResponse.data.id_role)
        } catch (err) {
          console.log(err)
        } finally {
          setLoadingUser(false)
        }
      }

      const getRoles = async () => {
        try {
          const resp = await customAxios(token).get(
            `${apiRoute.roles.GET_ROLES}`
          )
          setArrRole(resp.data.data)
        } catch (err) {
          getErrorMessage(err)
        }
      }
      getRoles()

      if (idEditUser) {
        getUserById(idEditUser)
        setTimeout(() => {
          const el = document.querySelectorAll('input#input_user_firstname')[0]
          if (el) {
            el.focus()
          }
        }, 200)
      } else {
        //
      }
    }, [])

    const isDisable = useMemo(() => {
      if (
        !_.isEmpty(trim(fn)) &&
        !_.isEmpty(trim(ln)) &&
        !_.isEmpty(trim(email)) &&
        !!idRoleSelected
      ) {
        return false
      }
      return true
    }, [fn, ln, email, idRoleSelected])

    useEffect(() => {
      getActiveGroups(token, setCompanyGroups, null, null, '')
    }, [])

    useEffect(() => {
      if (textsearchgroupDebounce === undefined) {
        return
      }
      getActiveGroups(
        token,
        setActiveGroups,
        setLoadingActiveGroups,
        null,
        textsearchgroupDebounce
      )
    }, [textsearchgroupDebounce])

    const renderListGroup = () => {
      // ///////////
      if (!showDropdown) {
        return null
      }

      if (loadingActiveGroups) {
        return (
          <div className={cls.group_selection}>
            <SpinnerIcon />
          </div>
        )
      }

      if (isEmpty(activeGroups)) {
        return (
          <div className={cls.group_selection}>
            <div className="no_data">
              <Caption>No group</Caption>
            </div>
          </div>
        )
      }

      const handleClickCheckbox = (o) => {
        if (listIdCheckedActiveGroups.includes(o.idGroup)) {
          const nextState = produce(checkedActiveGroups, (draft) => {
            const index = draft.findIndex((item) => item.idGroup === o.idGroup)

            if (index !== -1) {
              draft.splice(index, 1)
            }
            return draft
          })
          setCheckedActiveGroups(nextState)
        } else {
          const nextState = produce(checkedActiveGroups, (draft) => {
            draft.push(o)
            return draft
          })
          setCheckedActiveGroups(nextState)
        }
      }

      return (
        <div
          className={cls.group_selection}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {activeGroups.map((o, index) => (
            <React.Fragment key={o.idGroup}>
              {index !== 0 && (
                <div
                  className={cls.line}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              )}
              <div
                className={cls.row_group}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <MyCheckbox
                  checked={listIdCheckedActiveGroups.includes(o.idGroup)}
                  handleClickCheckbox={handleClickCheckbox.bind(null, o)}
                />

                <span className={cls.group_name}>{o.name}</span>
              </div>
            </React.Fragment>
          ))}
        </div>
      )

      // ///////////
    }

    let value = ''
    if (showDropdown) {
      value = textsearchgroup === undefined ? '' : textsearchgroup
    } else {
      value = groupsCheckedString
    }

    if (loadingUser) {
      return null
    }

    return (
      <Drawer
        className={classnames(cls.wr_drawer_upload_doc, 'wr_drawer_upload_doc')}
        destroyOnClose
        footer={
          <div
            className={`wr_footer_add_load ${
              savingOrDispatching ? 'disabled1' : ''
            }`}
          >
            <Button
              onClick={savingOrDispatching ? null : onClose}
              stateClass={savingOrDispatching ? 'Disabled' : 'Secondary'}
              width={74}
            >
              Cancel
            </Button>
            <div className="wr_save_dispatch">
              <Button
                onClick={
                  isDisable || savingOrDispatching ? null : handleClickSave
                }
                stateClass={
                  isDisable || savingOrDispatching ? 'Disabled' : 'Primary'
                }
                width={61}
              >
                Save
              </Button>
            </div>
          </div>
        }
        maskClosable={false}
        onClose={onClose}
        title="Edit User"
        visible
        width={368}
      >
        <div className={cls.form_group}>
          <label htmlFor="input_user_firstname">
            <Small>First Name</Small>
          </label>
          <Input2
            id="input_user_firstname"
            onChange={(e) => {
              setFn(e.target.value)
            }}
            onBlur={(e) => {
              setFn(trim(e.target.value))
            }}
            value={fn}
          />
        </div>
        <div className={cls.form_group}>
          <label htmlFor="input_user_lastname">
            <Small>Last Name</Small>
          </label>
          <Input2
            id="input_user_lastname"
            onChange={(e) => {
              setLn(e.target.value)
            }}
            onBlur={(e) => {
              setLn(trim(e.target.value))
            }}
            value={ln}
          />
        </div>
        <div className={cls.form_group}>
          <label htmlFor="input_user_email">
            <Small>Email Address</Small>
          </label>
          <Input2
            disabled
            email
            id="input_user_email"
            onChange={(e) => {
              setEmailLowerCase(e.target.value)
            }}
            onBlur={(e) => {
              setEmailLowerCase(trim(e.target.value))
            }}
            value={email}
          />
        </div>

        <div className={cls.phone_and_ext}>
          <div className={cls.form_group}>
            <label htmlFor="inputphone_edituser">
              <Small>Phone Number (Optional)</Small>
            </label>
            <MyInputPhone
              className="w-full"
              containerClass="inputphone_adduser"
              countryDefault={country.toLowerCase()}
              id="inputphone_adduser"
              onChange={(value, data) => {
                setCountry(data.countryCode)
                setCountryCode(data.dialCode)
                setPhone(value.slice(data.dialCode.length))
              }}
              value={`${countryCode}${phone}`}
            />
            {!!phoneError && <div className={cls.error}>{phoneError}</div>}
          </div>

          <div className={cls.form_group}>
            <label htmlFor="input_user_ext">
              <Small>Ext. (Optional)</Small>
            </label>
            <Input2
              id="input_user_ext"
              onChange={(e) => {
                setExt(e.target.value)
              }}
              value={ext}
            />
          </div>
        </div>

        <div className={cls.form_group}>
          <label htmlFor="inputupload_document_type">
            <Small>Role</Small>
          </label>
          <Select
            clearIcon={<DeleteSearch2 />}
            id="input_role"
            onChange={(value) => setIdRoleSelected(value)}
            style={{ width: '100%' }}
            suffixIcon={<ArrowSelect />}
            value={idRoleSelected ? Number(idRoleSelected) : undefined}
          >
            {arrRole
              .filter((o) => o.name !== 'Support')
              .map((role) => (
                <Option
                  disabled={tokenData.hierarchy > role.hierarchy}
                  key={role.idRole}
                  value={role.idRole}
                >
                  <Caption block className="mt8">
                    {role.name}
                  </Caption>
                  <Small block>{role.note}</Small>
                </Option>
              ))}
          </Select>
        </div>

        {/*  */}
        {!equalStr(status, 'Inactive') && _.size(companyGroups) >= 1 && (
          <div
            className={classnames(cls.form_group, cls.wr_group_filter)}
            ref={ref}
          >
            <label htmlFor="input_user_ext">
              <Small>Group (Optional)</Small>
            </label>
            <div className={cls.inputgroup}>
              <input
                className={clsInput.input}
                onChange={(e) => {
                  setTextsearchgroup(e.target.value)
                }}
                onFocus={() => {
                  setShowDropdown(true)
                  getActiveGroups(
                    token,
                    setActiveGroups,
                    setLoadingActiveGroups,
                    null,
                    textsearchgroupDebounce || ''
                  )
                }}
                ref={ref}
                value={value}
              />
              <div
                className={cls.arrowgroup}
                onClick={() => {
                  setShowDropdown(!showDropdown)

                  if (!showDropdown) {
                    getActiveGroups(
                      token,
                      setActiveGroups,
                      setLoadingActiveGroups,
                      null,
                      textsearchgroupDebounce || ''
                    )
                  } else {
                    setTextsearchgroup('')
                  }
                }}
              >
                <ArrowSelect />
              </div>
              {renderListGroup()}
            </div>
          </div>
        )}
        {/*  */}
      </Drawer>
    )
  }
)

export default DrawerEditUser
;((_data) => {})([React])
