export const getState = (addressArray) => {
  let state = ''
  if (!addressArray) {
    return state
  }
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        addressArray[i].types[0] === 'administrative_area_level_1'
      ) {
        state = addressArray[i].long_name
        return state
      }
    }
  }
  return state
}
export const getShortNameState = (addressArray) => {
  let state = ''
  if (!addressArray) {
    return state
  }
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        addressArray[i].types[0] === 'administrative_area_level_1'
      ) {
        state = addressArray[i].short_name
        return state
      }
    }
  }
  return state
}

export const getCity = (addressArray) => {
  let state = ''
  if (!addressArray) {
    return state
  }
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && addressArray[i].types[0] === 'locality') {
        state = addressArray[i].long_name
        return state
      }
    }
  }
  return state
}

export const getZipcode = (addressArray) => {
  let state = ''
  if (!addressArray) {
    return state
  }
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        addressArray[i].types[0] === 'postal_code'
      ) {
        state = addressArray[i].long_name
        return state
      }
    }
  }
  return state
}
