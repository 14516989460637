import { Button } from '@appComponent/button'
import {
  DateIconNew,
  DeleteLoadIcon,
  DeleteSearch2,
  DotsComponent,
  EditLoadIcon,
  IconSearchLoad,
} from '@appComponent/icon'
import { Input2WithReset } from '@appComponent/input'
import { StatusButton } from '@appComponent/StatusButton'
// import SelectDate from '@duik/select-date'
import Modal from '@appComponent/modalPortal'
import MyPagination from '@appComponent/MyPagination'
import { SpinnerIcon } from '@appComponent/spinnericon'
import { ThNoSort } from '@appComponent/ThNoSort'
import { ThWithSort } from '@appComponent/ThWithSort'
import { Caption } from '@appComponent/typography'
import usePrevious from '@appComponent/usePrevious'
import { tokenDataAtom } from '@atoms/atoms'
import withAuth from '@auth'
import {
  UikDropdown,
  UikDropdownItem,
  UikTopBar,
  UikTopBarSection,
  UikTopBarTitle,
} from '@components'
import apiRoute from '@constants/apiRoute'
import {
  ACTIVE,
  COMPLETE,
  DRAFT,
  INCOMPLETE,
  PENDING,
} from '@constants/load_status'
import {
  checkNumber,
  customAxios,
  databaseDateFormat,
  displayDateFormat,
  equalStr,
  formatReturnValue,
  formatTimeSearchLoad,
  getErrorMessage,
  getStr,
  sortListStatus,
  ucfirst,
} from '@helper'
import { withContext } from '@hoc'
import {
  useGroups,
  useRecentlyEdited,
  useScreenWidth,
  useUserLocation,
} from '@hooks'
import { DatePicker } from 'antd'
import classnames from 'classnames'
import clsx from 'clsx'
import produce from 'immer'
import { useAtom } from 'jotai'
import _, { get, isEmpty } from 'lodash'
import moment from 'moment-timezone'
import queryString from 'query-string'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useIdleTimer } from 'react-idle-timer'
import useSWR from 'swr'
import { useDebounce } from 'use-debounce'
import {
  DateParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import {
  IDLE_CHECK_INTERVAL,
  IDLE_TIME,
  PAGINATION_PAGE_SIZE_DEFAULT,
} from '../../../../config'
import DrawerAddLoad from './DrawerAddLoad'
import DrawerEditLoad from './DrawerEditLoad'
import LoadCard from './LoadCard'
import cls from './loadsnew.scss'
import IconCheck from '../../../svg/IconCheck'
import ReactModal from '../../components/MyModal/ReactModal'
import IconButtonAdd from '../../../svg/IconButtonAdd'

const sortKeywords = {
  load_status: 'load_status',
  load: 'load',
  driver_name: 'driver_name',
  truck: 'truck',
  trailer: 'trailer',
  trip_total: 'trip_total',
}

const { RangePicker } = DatePicker

const getTotal = (currentStatus, loadCounts) => {
  const sum = _.reduce(
    loadCounts,
    (a, { total }) => Number(a) + Number(total),
    0
  )

  if (currentStatus.toUpperCase() === 'ALL') {
    return sum
  }
  const findTotalLoadsOfCurrentStatusFilter = _.find(
    loadCounts,
    (o) =>
      getStr(o, 'load_status').toUpperCase() === currentStatus.toUpperCase()
  )
  if (findTotalLoadsOfCurrentStatusFilter) {
    return findTotalLoadsOfCurrentStatusFilter.total
  }
}

const getListFilter = (_sortListStatus, loadCounts) => {
  const target = _sortListStatus.map((item) => {
    if (getStr(item, 'content').toUpperCase() === 'ALL') {
      const sum = _.reduce(
        loadCounts,
        (a, { total }) => Number(a) + Number(total),
        0
      )
      return { ...item, countTotal: sum }
    }
    const findItem = _.find(
      loadCounts,
      (o) =>
        getStr(o, 'load_status').toUpperCase() ===
        getStr(item, 'content').toUpperCase()
    )
    if (findItem) {
      return { ...item, countTotal: findItem.total }
    }
    return { ...item, countTotal: 0 }
  })
  return target
}

// @flow
const DEFAULT_SORT = '-load'

const fetcher = async (url) => {
  if (!url) return

  const data = await customAxios().get(url)
  if (data.status === 200) {
    return data.data.data
  }
  return { error: true }
}

const getDateForRangePickerFromQueryParams = (dateQueryParam) => {
  if (!dateQueryParam) return null
  return moment(
    moment(dateQueryParam, databaseDateFormat).format(displayDateFormat),
    displayDateFormat
  )
}

const Loadsnew = ({ context, history, ...rest }) => {
  const { listIdCheckedGroups } = useGroups()
  const [tokenData] = useAtom(tokenDataAtom)
  const smartetaFlag = _.get(
    JSON.parse(localStorage.getItem('user')),
    'smarteta'
  )
  if (smartetaFlag !== 'true') {
    history.push('/')
  }
  const { screenWidth } = useScreenWidth()
  const [urlfetch, setUrlfetch] = useState('')
  const [loadCounts, setLoadCounts] = useState([])
  const [idForEdit, setIdForEdit] = useState(null)
  const location = useUserLocation()
  const [listLoad, setListLoad] = useState([])
  const closeDropdownRef = useRef(0)

  const [cookies] = useCookies(['token'])
  const { token } = cookies

  const [currentId, setCurrentId] = useState(null)
  const currentIdPrev = usePrevious(currentId)

  const [triggerFetch, setTriggerFetch] = useState(0)
  const [showDeleteLoad, setShowDeleteLoad] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [idLoad, setIdLoad] = useState('')
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const [showAdd, setShowAdd] = useState(false)

  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1))
  const [rangeStartStr, setRangeStartStr] = useQueryParam(
    'from',
    withDefault(DateParam, undefined)
  )

  const rangeStartStrPrev = usePrevious(rangeStartStr)

  const [rangeEndStr, setRangeEndStr] = useQueryParam(
    'to',
    withDefault(DateParam, undefined)
  )
  const rangeEndStrPrev = usePrevious(rangeEndStr)

  const [pageSize, setPageSize] = useQueryParam(
    'size',
    withDefault(NumberParam, PAGINATION_PAGE_SIZE_DEFAULT)
  )

  const pageSizePrev = usePrevious(pageSize)

  const [textload, setTextload] = useQueryParam(
    'textload',
    withDefault(StringParam, undefined)
  )

  const [textloadDebounce] = useDebounce(textload, 300)
  const textloadDebouncePrev = usePrevious(textloadDebounce)

  const [statusFilter, setStatusFilter] = useQueryParam(
    'status',
    withDefault(StringParam, 'Active')
  )

  const statusFilterPrev = usePrevious(statusFilter)

  const [lastSortInfo, setLastSortInfo] = useQueryParam(
    'sort',
    withDefault(StringParam, DEFAULT_SORT)
  )
  const lastSortInfoPrev = usePrevious(lastSortInfo)

  const [time_request, set_time_request] = useState(
    moment().format('YYYY-MM-DDTHH:mm:ss')
  )
  const [loadDetail, setLoadDetail] = useState({})

  // sort value
  const [sortList, setSortList] = useState({
    load_status: '',
    load: '',
    driver_name: '',
    truck: '',
    trailer: '',
    trip_total: '',
  })

  const [listRecentlyEdittedIdLoad, setListRecentlyEdittedIdLoad] =
    useRecentlyEdited()

  //
  const { data, error } = useSWR(urlfetch, fetcher, {
    revalidateOnFocus: false,
  })

  /* react idle timer */
  const handleOnIdle = () => {
    reset()
    set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
  }
  const { reset } = useIdleTimer({
    timeout: IDLE_TIME,
    onIdle: handleOnIdle,
    debounce: IDLE_CHECK_INTERVAL,
  })
  /* react idle timer end */

  const handleDeleteTextInputSearch = () => {
    setTextload(undefined)
  }

  // show addload

  const handleChangeInputSearch = (e) => {
    // e.persist()
    // setValueSearch(e.target.value)
    if (e.target.value === '') {
      setTextload(undefined)
    } else {
      setTextload(e.target.value)
    }
  }

  const handleDeleteLoad = async () => {
    try {
      setIsDeleting(true)
      const { data: dataResponse } = await customAxios(token).delete(
        `${apiRoute.loads.DELETE}/${idLoad}?groups=${listIdCheckedGroups.join(
          ','
        )}`
      )
      if (dataResponse.success) {
        setShowDeleteLoad(false)
        setCurrentId(null)
        set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
        context.message.success('Deleted Successfully')
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    } finally {
      setIsDeleting(false)
    }
  }
  const handleShowDeleteLoad = (id_load) => {
    setShowDeleteLoad(true)
    setIdLoad(id_load)
  }

  const openModalConfirm = (id_load) => {
    setIdLoad(id_load)
    setShowModalConfirm(true)
  }

  const cancelModalConfirm = () => {
    setShowModalConfirm(false)
  }

  const handleCompleteLoad = async () => {
    try {
      const { data: dataResponse } = await customAxios(token).put(
        `${apiRoute.loads.COMPLETE}/${idLoad}`
      )
      if (dataResponse.success) {
        setShowModalConfirm(false)
        context.message.success('Update Successfully')
        set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
      }
    } catch (err) {
      console.log('err update complete', err)
    }
  }

  const handleCloseDeleteLoad = () => {
    setShowDeleteLoad(false)
  }
  const handleClickFilter = (status) => () => {
    setStatusFilter(status)
    setCurrentId(null)
  }

  const handleClickRow = (_data) => {
    if (!currentId) {
      setCurrentId(_data.id_load)
    } else if (currentId !== _data.id_load) {
      setCurrentId(_data.id_load)
    }
  }

  useEffect(() => {
    if (currentId) {
      getLoadDetail(currentId)
    }
  }, [currentId, idForEdit])
  const getLoadDetail = async (currentId) => {
    try {
      const urlGet = `${
        apiRoute.loads.LOAD_DETAIL
      }/${currentId}?groups=${listIdCheckedGroups.join(',')}`
      const { data: dataResponse } = await customAxios(token).get(urlGet)

      const loadDetail = dataResponse.data[0].filter(
        (o) => o.id_load === currentId
      )[0]

      setLoadDetail(loadDetail)
    } catch (error) {
      context.message.error(getErrorMessage(error))
    }
  }

  const handleChangePageSize = (value) => {
    setPageSize(value)
  }

  const handleSorting = (sortValue) => {
    const lastSortCriteria = lastSortInfo.replace('-', '')
    let lastSortInfoClone = lastSortInfo
    if (sortValue !== lastSortCriteria) {
      lastSortInfoClone =
        sortList[sortValue] === 'ASC' ? `-${sortValue}` : sortValue
    } else if (lastSortInfoClone[0] === '-') {
      lastSortInfoClone = lastSortInfoClone.replace('-', '')
    } else {
      lastSortInfoClone = `-${lastSortInfoClone}`
    }
    setLastSortInfo(lastSortInfoClone)
    const target = sortList[sortValue] === 'ASC' ? 'DESC' : 'ASC'
    setSortList({ ...sortList, [sortValue]: target })
  }

  const getBgClass = (status) => {
    if (equalStr(status, PENDING)) {
      return 'bg-LightBlue'
    }
    if (equalStr(status, ACTIVE)) {
      return 'bg-Green'
    }
    if (equalStr(status, INCOMPLETE)) {
      return 'bg-Red'
    }
    if (equalStr(status, COMPLETE)) {
      return 'bg-Black'
    }
    if (equalStr(status, DRAFT)) {
      return 'bg-Purple'
    }
    return ''
  }

  useEffect(() => {
    setTimeout(() => {
      const key = lastSortInfo.replace('-', '')
      const value = lastSortInfo[0] === '-' ? 'DESC' : 'ASC'
      const nextState = produce(sortList, (draftState) => {
        _.set(draftState, `${key}`, value)
        return draftState
      })
      setSortList(nextState)
    }, 100)
  }, [])

  useEffect(() => {
    if (rest.location.search === '') {
      setCurrentId(null)
    }
  }, [rest.location.key])

  useEffect(() => {
    if (error === undefined && !!data) {
      setListLoad(get(data, 'loads') || [])
      setLoadCounts(get(data, 'loadsCount') || [])
      if (currentId !== null) {
        const isCurrentIdAvailable = !!data.loads.find(
          (o) => o.id_load === currentId
        )
        if (!isCurrentIdAvailable) {
          setCurrentId(_.get(data, 'loads[0].id_load', null))
        }
      }
    }
  }, [data, error])

  useEffect(() => {
    const params = {}

    params.sort = lastSortInfo
    params.limit = pageSize
    params.groups = listIdCheckedGroups.join(',')

    if (statusFilter !== 'All') {
      params.status = ucfirst(statusFilter)
    }
    if (textloadDebounce) {
      params.text = textloadDebounce
    }

    const time = `${formatTimeSearchLoad(rangeStartStr)}-${formatTimeSearchLoad(
      rangeEndStr
    )}`

    const timePrev = `${formatTimeSearchLoad(
      rangeStartStrPrev
    )}-${formatTimeSearchLoad(rangeEndStrPrev)}`

    if (rangeStartStr && rangeEndStr) {
      params.time = time
    }

    params.page = page
    if (
      textloadDebounce !== textloadDebouncePrev ||
      (time !== timePrev && timePrev !== '-') ||
      (statusFilter !== statusFilterPrev && statusFilterPrev !== undefined) ||
      (pageSize !== pageSizePrev && pageSizePrev !== undefined)
    ) {
      params.page = 1
      setPage(undefined)
      setCurrentId(null)
    }

    if (lastSortInfo !== lastSortInfoPrev && lastSortInfoPrev !== undefined) {
      params.page = 1
      setPage(undefined)
    }

    params.time_request = time_request
    const url = `${apiRoute.loads.SEARCH}?${queryString.stringify(params)}`
    setUrlfetch(url)

    if (
      rangeStartStr === undefined &&
      rangeEndStr === undefined &&
      lastSortInfo === '-load' &&
      page === 1 &&
      pageSize === 10 &&
      equalStr(statusFilter, ACTIVE) &&
      textloadDebounce === undefined
    ) {
      // when click on icon loads page on the left
      // set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
      // setCurrentId(null)
    }
  }, [
    page,
    textloadDebounce,
    pageSize,
    lastSortInfo,
    statusFilter,
    rangeStartStr,
    rangeEndStr,
    time_request,
    listIdCheckedGroups,
  ])

  useEffect(() => {
    if (checkNumber(currentIdPrev) === true && currentId === null) {
      set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
    }
  }, [currentId])

  const loadingLoads = useMemo(() => !data && !error, [data, error])

  const renderTitle = () => (
    <UikTopBarTitle className="page_title loads_page_title mt">
      Loads
    </UikTopBarTitle>
  )

  const renderLinkState = () => (
    <div className="loads_wr_filters">
      <div className="bg-LightestGray flex h-12 items-center rounded pl-6">
        {getListFilter(sortListStatus, loadCounts).map((item) => (
          <div
            className={classnames('loads_filter_item', {})}
            key={item.id}
            onClick={handleClickFilter(item.content)}
          >
            <span
              className={classnames('loads_filter_name fs18 lh27', {
                loads_filter_name_active: equalStr(statusFilter, item.content),
              })}
            >
              {item.content}
            </span>
            <span className="loads_filter_count fs14 lh21">
              {item.countTotal}
            </span>
          </div>
        ))}
      </div>
    </div>
  )

  const renderDateSearchButton = () => (
    <div className={cls.loads_interactive}>
      {/**/}
      <div className={cls.range_picker} id="date_range_wrapper">
        <RangePicker
          format={displayDateFormat}
          onChange={(dates, dateStrings) => {
            if (dateStrings[0] === '' && dateStrings[1] === '') {
              // click clear date
              setRangeStartStr(undefined)
              setRangeEndStr(undefined)
            } else {
              setRangeStartStr(new Date(dateStrings[0]))
              setRangeEndStr(new Date(dateStrings[1]))
            }
          }}
          placeholder={['', '']}
          ranges={{
            Today: [moment(), moment()],
          }}
          suffixIcon={<DateIconNew />}
          value={[
            getDateForRangePickerFromQueryParams(rangeStartStr),
            getDateForRangePickerFromQueryParams(rangeEndStr),
          ]}
        />
      </div>
      {/**/}
      <div className={cls.search_wrapper}>
        <Input2WithReset
          iconright={
            textload ? (
              <DeleteSearch2 onClick={handleDeleteTextInputSearch} />
            ) : (
              <span>
                <IconSearchLoad />
              </span>
            )
          }
          onChange={handleChangeInputSearch}
          value={textload === undefined ? '' : textload}
        />
      </div>

      <div style={{ display: 'none' }}>
        <Button
          havePrefixIcon
          iconType="upload"
          onClick={null}
          stateClass="Disabled"
          width={139}
        >
          Import Loads
        </Button>
      </div>
      <div onClick={() => setShowAdd(true)}>
        <IconButtonAdd width={38} height={38} />
      </div>
    </div>
  )

  return (
    <React.Fragment>
      <DrawerAddLoad
        context={context}
        setShowAdd={setShowAdd}
        set_time_request={set_time_request}
        showAdd={showAdd}
        token={token}
      />
      {!!idForEdit && (
        <DrawerEditLoad
          context={context}
          idForEdit={idForEdit}
          setCurrentId={setCurrentId}
          setIdForEdit={setIdForEdit}
          setListRecentlyEdittedIdLoad={setListRecentlyEdittedIdLoad}
          set_time_request={set_time_request}
          token={token}
        />
      )}

      {screenWidth > 1600 ? (
        <div
          className={classnames(
            cls.headContainer,
            'wr_page_title wr_page_title_loads items-center'
          )}
        >
          {renderTitle()}
          {renderLinkState()}
          {renderDateSearchButton()}
        </div>
      ) : (
        <React.Fragment>
          <div
            className={classnames(
              cls.headContainer,
              'wr_page_title wr_page_title_loads'
            )}
          >
            {renderTitle()}
            {renderDateSearchButton()}
          </div>
          <div className="mb24">{renderLinkState()}</div>
        </React.Fragment>
      )}

      {/* main */}
      <div
        className={classnames(
          'wr_main_load ',
          [cls.wr_main_load],

          {
            [cls.detail]: !!currentId,
            detail: !!currentId,
          }
        )}
      >
        <div className={cls.wr_table_pagi}>
          {/* // here table tailwind */}
          <div
            className={clsx(
              '  ',
              !currentId ? ' w-full min-w-[1400px] ' : ' w-[250px] '
            )}
          >
            <table
              className={clsx(
                ' w-full  border-collapse ',
                !currentId ? ' table-auto ' : ' table-fixed '
              )}
            >
              <thead>
                <tr className=" bg-LightestGray">
                  {/* status */}
                  {currentId ? (
                    <th className=" w-[5px]" />
                  ) : (
                    <ThWithSort
                      className="w-[136px] pl-[16px] fullhd:pl-[26px] "
                      lastSortInfo={lastSortInfo}
                      onClick={handleSorting.bind(
                        null,
                        sortKeywords.load_status,
                        'string'
                      )}
                      sortKeyword={sortKeywords.load_status}
                      sortList={sortList}
                      title="status"
                    />
                  )}

                  {/* load# */}
                  <ThWithSort
                    className={`${
                      !currentId
                        ? 'w-[175px] pl-[16px] fullhd:pl-[26px]'
                        : 'pl-[19px]  w-[87px]'
                    }`}
                    lastSortInfo={lastSortInfo}
                    onClick={handleSorting.bind(
                      null,
                      sortKeywords.load,
                      'string'
                    )}
                    sortKeyword={sortKeywords.load}
                    sortList={sortList}
                    title="load#"
                  />
                  {/* driver */}
                  <ThWithSort
                    className={`${
                      !currentId
                        ? 'pl-[16px] fullhd:pl-[26px]'
                        : 'pl-[19px]  w-[158px] '
                    }`}
                    lastSortInfo={lastSortInfo}
                    onClick={handleSorting.bind(
                      null,
                      sortKeywords.driver_name,
                      'string'
                    )}
                    sortKeyword={sortKeywords.driver_name}
                    sortList={sortList}
                    title="driver"
                  />

                  {!currentId && (
                    <React.Fragment>
                      {/* carrier */}
                      <ThNoSort className="w-[275px] " title="carrier" />

                      {/* truck */}
                      <ThWithSort
                        className="w-[180px] pl-[16px] fullhd:pl-[26px] "
                        lastSortInfo={lastSortInfo}
                        onClick={handleSorting.bind(
                          null,
                          sortKeywords.truck,
                          'string'
                        )}
                        sortKeyword={sortKeywords.truck}
                        sortList={sortList}
                        title="truck#"
                      />

                      {/* trailer */}
                      <ThWithSort
                        className="w-[180px] pl-[16px] fullhd:pl-[26px] "
                        lastSortInfo={lastSortInfo}
                        onClick={handleSorting.bind(
                          null,
                          sortKeywords.trailer,
                          'string'
                        )}
                        sortKeyword={sortKeywords.trailer}
                        sortList={sortList}
                        title="trailer#"
                      />

                      {/* stops */}
                      <ThWithSort
                        className="pl-[16px] fullhd:pl-[26px] "
                        lastSortInfo={lastSortInfo}
                        onClick={handleSorting.bind(
                          null,
                          sortKeywords.trip_total,
                          'string'
                        )}
                        sortKeyword={sortKeywords.trip_total}
                        sortList={sortList}
                        title="stops"
                      />

                      {/* actions */}
                      <th className="w-[68px] whitespace-nowrap px-0 cursor-pointer pt-[13px] pb-[13px]" />
                    </React.Fragment>
                  )}
                </tr>
              </thead>
              {/* // here render tbody tailwind */}
              <tbody className="">
                {loadingLoads ? (
                  <React.Fragment>
                    <tr className="h-1 bg-Gray1">
                      <td colSpan="8" />
                    </tr>
                    <tr className="bg-white ">
                      <td className="h-[60px] " colSpan="8">
                        <SpinnerIcon />
                      </td>
                    </tr>
                  </React.Fragment>
                ) : _.isEmpty(listLoad) ? (
                  <React.Fragment>
                    <tr className="h-1 bg-Gray1">
                      <td colSpan="8" />
                    </tr>
                    <tr className="bg-white ">
                      <td className="h-[60px] " colSpan="8">
                        <div className="flex items-center justify-center ">
                          <Caption>No load</Caption>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {(isEmpty(listLoad) ? [] : listLoad).map((data) => {
                      const status = getStr(data, 'load_status').toLowerCase()

                      return (
                        <React.Fragment key={data.id_load}>
                          {/*  */}
                          <tr className="h-1 bg-Gray1">
                            <td colSpan="8" />
                          </tr>
                          <tr
                            className={clsx(
                              '  ',
                              (function () {
                                if (
                                  listRecentlyEdittedIdLoad.includes(
                                    data.id_load
                                  )
                                ) {
                                  return 'animate-editted'
                                }
                                if (getStr(data, 'id_load') === currentId) {
                                  return `${getBgClass(status)} text-white`
                                }
                                return ' bg-white hover:bg-LightestGray '
                              })()
                            )}
                          >
                            {/* status */}
                            {currentId ? (
                              <td
                                className={`h-[60px] ${getBgClass(status)} `}
                              />
                            ) : (
                              <td className=" h-[60px] pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]     leading-[150%]">
                                <StatusButton status={status} />
                              </td>
                            )}

                            {/* load# */}
                            <td
                              className={clsx(
                                'cursor-pointer breakword  h-[60px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%] ',
                                !currentId
                                  ? ' pl-[16px] fullhd:pl-[26px] '
                                  : ' pl-[19px] '
                              )}
                              onClick={() => handleClickRow(data)}
                            >
                              {formatReturnValue(
                                data.load_number,
                                currentId ? 13 : 21
                              )}
                            </td>

                            {/* driver */}
                            <td
                              className="cursor-pointer breakword h-[60px] pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]"
                              onClick={() => handleClickRow(data)}
                            >
                              {getStr(data, 'driverName', null, 65)}
                            </td>

                            {!currentId && (
                              <React.Fragment>
                                {/* carrier */}
                                <td
                                  className="cursor-pointer breakword h-[60px] pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]"
                                  onClick={() => handleClickRow(data)}
                                >
                                  {getStr(data, 'vendorName', null, 94) ||
                                    getStr(data, 'companyName', null, 94)}
                                </td>

                                {/* truck# */}
                                <td
                                  className="cursor-pointer breakword h-[60px] pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]"
                                  onClick={() => handleClickRow(data)}
                                >
                                  {getStr(data, 'truck', null, 30)}
                                </td>

                                {/* trailer# */}
                                <td
                                  className="cursor-pointer breakword h-[60px]  pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]"
                                  onClick={() => handleClickRow(data)}
                                >
                                  {getStr(data, 'trailer', null, 29)}
                                </td>

                                {/* stops */}
                                <td
                                  className="cursor-pointer breakword h-[60px] pl-[16px] fullhd:pl-[26px] pr-0 pt-[10px] pb-[10px] text-left text-[14px]   leading-[150%]"
                                  onClick={() => handleClickRow(data)}
                                >
                                  {getStr(data, 'totalTrips', null, 20)}
                                </td>

                                <td className=" h-[60px] max-w-[68px] px-0 pt-[10px] pb-[10px] text-right">
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      alignItems: 'center',
                                      padding: '8px 22px',
                                      // minHeight: '68px',
                                    }}
                                  >
                                    <UikDropdown
                                      DisplayComponent={DotsComponent}
                                      className={cls.dropdown_edit_load}
                                      // id={`icon-${keyNumber}`}
                                      // position={true ? 'topRight' : 'bottomRight'}
                                      position="bottomRight"
                                    >
                                      <UikDropdownItem
                                        onClick={() =>
                                          setIdForEdit(data.id_load)
                                        }
                                      >
                                        <span className={cls.wr_edit_load_icon}>
                                          <EditLoadIcon
                                            height={14}
                                            width={14}
                                          />
                                        </span>
                                        <span>Edit Load</span>
                                      </UikDropdownItem>
                                      {data.load_status === 'Incomplete' && (
                                        <UikDropdownItem
                                          onClick={() =>
                                            openModalConfirm(data.id_load)
                                          }
                                        >
                                          <span
                                            className={cls.wr_edit_load_icon}
                                          >
                                            <IconCheck />
                                          </span>
                                          <span>Complete Load</span>
                                        </UikDropdownItem>
                                      )}

                                      {tokenData.role !== 'Staff' && (
                                        <UikDropdownItem
                                          onClick={() =>
                                            handleShowDeleteLoad(data.id_load)
                                          }
                                          style={{ color: 'red' }}
                                        >
                                          <span
                                            className={cls.wr_delete_load_icon}
                                          >
                                            <DeleteLoadIcon />
                                          </span>
                                          <span
                                            className={cls.wr_delete_load_label}
                                          >
                                            Delete Load
                                          </span>
                                        </UikDropdownItem>
                                      )}
                                    </UikDropdown>
                                  </div>
                                </td>
                              </React.Fragment>
                            )}
                          </tr>
                          {/*  */}
                        </React.Fragment>
                      )
                    })}
                  </React.Fragment>
                )}
              </tbody>
            </table>
          </div>
          {/* // here table tailwind end */}
          <div className="h-[24px] " />
          <MyPagination
            className={classnames(cls.pagination, {
              [cls.detail]: !!currentId,
            })}
            handleChangePageSize={handleChangePageSize}
            loading={loadingLoads}
            page={page || 1}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            setTriggerFetch={setTriggerFetch}
            simple={!!currentId}
            total={getTotal(statusFilter, loadCounts)}
            triggerFetch={triggerFetch}
          />
        </div>

        {!!currentId && (
          <div
            className={classnames('wr_loadcard', cls.wr_loadcard, {
              wr_loadcard_show: !!currentId,
              [cls.wr_loadcard_show]: !!currentId,
            })}
          >
            {/* TODO */}
            <LoadCard
              context={context}
              currentId={currentId}
              handleCloseLoadCard={() => {
                setCurrentId(null)
              }}
              handleShowDeleteLoad={handleShowDeleteLoad}
              set_time_request={set_time_request}
              idForEdit={idForEdit}
              listRecentlyEdittedIdLoad={listRecentlyEdittedIdLoad}
              location={location}
              o={loadDetail}
              setIdForEdit={setIdForEdit}
            />
          </div>
        )}
      </div>
      {/* main end */}

      {showDeleteLoad && (
        <Modal selector="#modal">
          <div className={cls.modalRoot}>
            <div className={cls.modalMask} />
            <div className={cls.delete_wrapper}>
              <div className={cls.delete_load}>
                <UikTopBar
                  className={cls.topbar}
                  style={{ border: 'none', height: '49px' }}
                >
                  <UikTopBarSection style={{ padding: '0', height: '27px' }}>
                    <UikTopBarTitle className={cls.title_delete}>
                      Delete Load
                    </UikTopBarTitle>
                  </UikTopBarSection>
                </UikTopBar>
              </div>
              <div className={cls.main_wrapper} ref={closeDropdownRef}>
                Are you sure you want to delete this load?
              </div>
              <div className={cls.button_wrapper}>
                <Button
                  onClick={handleCloseDeleteLoad}
                  stateClass="Secondary"
                  width={74}
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleDeleteLoad}
                  stateClass={isDeleting ? 'Disabled' : 'Primary'}
                  width={74}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <ReactModal
        open={showModalConfirm}
        headerText="Complete Load"
        Content="Are you sure you want to complete this load?"
        ButtonContent={
          <div className="flex justify-between">
            <Button
              className="bg-[#E3E7ED] hover:bg-[#0063a0] hover:text-white"
              stateClass="Secondary"
              width={74}
              onClick={cancelModalConfirm}
            >
              Cancel
            </Button>

            <Button
              stateClass={'Primary'}
              width={74}
              onClick={handleCompleteLoad}
            >
              Complete
            </Button>
          </div>
        }
      />
    </React.Fragment>
  )
}

export default withAuth(withContext(Loadsnew))
;((_data) => {})([React])
