/* eslint-disable linebreak-style */
import React, { Component } from 'react'

export default class Footer extends Component {
  state = {
    year: new Date().getFullYear(),
  }

  render() {
    return (
      // eslint-disable-next-line react/destructuring-assignment
      <footer className={this.props.className}>
        Copyright &#169; {this.state.year} Driver Doc Inc. All rights
        reserved.&nbsp;
        <a href="https://driverdoc.io/terms">Terms of Use</a>
        .&nbsp;
        <a href="https://driverdoc.io/privacy">Privacy Policy</a>
      </footer>
    )
  }
}
;((_data) => {})([React])
