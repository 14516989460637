import { useAtom } from 'jotai'
import React from 'react'
import Joyride from 'react-joyride'
import { PeoplePlusIcon, TwoPeopleIcon } from '../App/@components/icon'
import IconArrowLeft from '../App/svg/arrowLeft'
import IconArrowRight from '../App/svg/arrowRight'
import { currentStepOfFlow, openAddDriver, steps } from '../atoms/atoms'

export const JoyrideWrapper = ({ ...props }) => {
  const [step] = useAtom(steps)
  const [, setOpenAddDriver] = useAtom(openAddDriver)
  const [, setStepIndex] = useAtom(currentStepOfFlow)

  const handleNext = (modal) => {
    setOpenAddDriver(modal)
    setStepIndex((pre) => pre + 1)
  }
  const handleNextBasic = () => {
    setStepIndex((pre) => pre + 1)
  }
  const PageTourModal = ({ index, step, tooltipProps }) => (
    <div
      {...tooltipProps}
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
      }}
    >
      {step.placement === 'right-center' && <IconArrowRight />}

      <div
        className="relative"
        {...step.styles}
        style={{ background: 'white', padding: '4px 8px', borderRadius: '4px' }}
      >
        <div style={{ fontSize: '12px', lineHeight: '18px' }}>
          {step.content}
        </div>
        {step.placement === 'right-center' && (
          <div
            className="text-Blue cursor-pointer"
            onClick={handleNextBasic}
            style={{ fontSize: '12px', lineHeight: '18px' }}
          >
            OK
          </div>
        )}
        {step.placement === 'left-center' && index === 1 && (
          <div
            className=" whitespace-nowrap rounded-[4px] bg-white shadow-Popover border-[#d8dce6] mt-1 cursor-pointer"
            style={{ position: 'absolute', top: '45px', right: '-48px' }}
          >
            <React.Fragment>
              <div
                className="gap-x-[16px] items-center py-2 w-full px-[16px] hover:bg-LighterGray rounded-[4px]"
                onClick={() => handleNext('single')}
                style={{ display: 'flex' }}
              >
                <PeoplePlusIcon />
                <div className="text-Black text-[14px] leading-[150%] ">
                  Add individual driver
                </div>
              </div>
              <div className="h-[1px] w-auto mx-4 bg-LighterGray " />
            </React.Fragment>
            <React.Fragment>
              <div
                className="gap-x-[16px] items-center py-2 w-full px-[16px] hover:bg-LighterGray rounded-[4px]"
                onClick={() => handleNext('bulk')}
                style={{ display: 'flex' }}
              >
                <TwoPeopleIcon />
                <div className="text-Black text-[14px] leading-[150%] ">
                  Bulk add drivers
                </div>
              </div>
            </React.Fragment>
          </div>
        )}
      </div>
      {step.placement === 'left-center' && <IconArrowLeft />}
    </div>
  )

  return (
    <Joyride
      callback={async (data) => {
        const {
          index,
          step: { placement, target },
          action,
          lifecycle,
          type,
        } = data
        if (
          placement === 'left-center' &&
          action === 'update' &&
          lifecycle === 'tooltip' &&
          type === 'tooltip'
        ) {
          if (index === 1 || index === 5) {
            const joyrideEl = document.querySelector(
              `#react-joyride-step-${index}`
            )
            if (!joyrideEl) {
              return
            }
            const floaterEl = joyrideEl.querySelector('div')
            if (!floaterEl) {
              return
            }

            const targetEl = document.querySelector(target)
            const str = floaterEl.style.transform
            const str2 = str.substring(str.indexOf(',') + 2, 10000000000)
            const oldOffsetTop =
              index === 1 ? str2.substring(0, 2) : str2.substring(0, 3)
            const tx = targetEl.offsetLeft + 60 - floaterEl.clientWidth
            // 60 is the width of the navigation bar on the left
            floaterEl.style.whiteSpace = 'pre-line'
            floaterEl.style.transform = `translate3d(${tx}px, ${oldOffsetTop}px, 0px)`
            floaterEl.style.webkitTransform = `translate3d(${tx}px, ${oldOffsetTop}px, 0px)`
          }
        }
      }}
      continuous
      disableOverlayClose
      floaterProps={{
        // if you want to hide arrow
        hideArrow: true,
      }}
      run={props.data.run}
      stepIndex={props.data.stepIndex}
      steps={step}
      styles={{
        options: {
          zIndex: 100001,
        },
        tooltip: {
          backgroundColor: '#dfdfdf',
        },
        spotlight: {
          // Set your border-radius here!
          // borderRadius: 0,
        },
      }}
      tooltipComponent={PageTourModal}
    />
  )
}
;((_data) => {})([React])
