import { PlacesAutoComplete } from '@appComponent/PlacesAutoComplete'
import { Button } from '@appComponent/button'
import { ArrowDown } from '@appComponent/icon'
import { Input2 } from '@appComponent/input'
import { Body, Small } from '@appComponent/typography'
import { currentPlanAtom } from '@atoms/atoms'

import { UikFormInputGroup, UikInput } from '@components'
import apiRoute from '@constants/apiRoute'
import {
  customAxios,
  formatToNumber,
  getErrorMessage,
  getStr,
  letWait,
} from '@helper'
import { withContext } from '@hoc'
import {
  AmericaAndCanadaStates,
  AmericaZipCodeRegx,
  CanadaZipCodeRegx,
  checkValidEmail,
} from '@utils'
import classnames from 'classnames'
import { useAtom } from 'jotai'
import _, { isEmpty, toLower } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { MyInputPhone } from '../../../../@components/MyInputPhone'

import cls from './companyProfile.scss'

function formatReturnedData(data) {
  if (!data && data !== 0) {
    return ''
  }
  return data
}

const CompanyProfile = ({ context }) => {
  const initialCompany = {
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    email: '',
    website: '',
    country: 'us',
    countryCode: '1',
  }
  const initialErrors = { ...initialCompany }
  const [cookies] = useCookies(['token'])
  const { token } = cookies
  const [currentPlan] = useAtom(currentPlanAtom)
  const [company, setCompany] = useState(initialCompany)
  const [error, setError] = useState(initialErrors)
  const [isShowState, setShowState] = useState(false)
  const [stateCursor, setStateCursor] = useState(null)
  const [arrowDirection, setArrowDirection] = useState(null)
  const [listState, setListState] = useState(AmericaAndCanadaStates)
  const companyName = localStorage.getItem('company_name') || ''
  const iconDropdownRef = useRef(null)
  const [errors, setErrors] = useState({})
  const holdCompanyValueRef = useRef(initialCompany)
  const stateRef = useRef([])
  const headerTitleRef = useRef(null)
  const companyNameRef = useRef(null)
  const listStateRef = useRef(null)
  const holdVendorValueRef = useRef(initialCompany)
  const [place, setPlace] = useState(null)
  const [isDispatching, setDispatch] = useState(false)
  const [loadingCompany, setLoadingCompany] = useState(false)
  useEffect(() => {
    setStateCursor(-1)
  }, [listState])

  useEffect(() => {
    if (headerTitleRef.current) {
      headerTitleRef.current.click()
    }
    handleGetCompany()
  }, [])
  useEffect(() => {
    const buttonDropDown = document.querySelector(
      '.containerClass .flag-dropdown'
    )
    if (isEmpty(buttonDropDown)) {
      return
    }

    buttonDropDown.addEventListener('click', async () => {
      // ////////////////////////////
      let countries = document.querySelectorAll(
        '.containerClass .country-list .country'
      )
      var interval = setInterval(() => {
        if (isEmpty(countries)) {
          countries = document.querySelectorAll(
            '.containerClass .country-list .country'
          )
        } else {
          clearInterval(interval)
          countries.forEach((o) => {
            const oldText = o.innerHTML
            const reg = />\+\d{1,100}/
            const match = oldText.match(reg)
            if (match !== null) {
              const match2 = match[0]

              const match3 = match2.replace('+', '(+')
              const match4 = `${match3})`
              const newText = oldText.replace(reg, match4)
              o.innerHTML = newText
            }
          })
        }
      }, 30)
      // ////////////////////////////
    })

    return () => {}
  }, [])
  useEffect(() => {
    ;(async function () {
      function move(oldParent, newParent) {
        while (oldParent.childNodes.length > 0) {
          newParent.appendChild(oldParent.childNodes[0])
        }
      }

      let el
      let el2
      el = document.getElementById('old_parent')
      el2 = document.getElementById('wr_billing')
      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent')
        el2 = document.getElementById('wr_billing')
      }

      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent')
        el2 = document.getElementById('wr_billing')
      }

      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent')
        el2 = document.getElementById('wr_billing')
      }

      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent')
        el2 = document.getElementById('wr_billing')
      }

      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent')
        el2 = document.getElementById('wr_billing')
      }

      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent')
        el2 = document.getElementById('wr_billing')
      }
      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent')
        el2 = document.getElementById('wr_billing')
      }
      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent')
        el2 = document.getElementById('wr_billing')
      }
      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent')
        el2 = document.getElementById('wr_billing')
      }
      if (!el || !el2) {
        await letWait(500)
        el = document.getElementById('old_parent')
        el2 = document.getElementById('wr_billing')
      }

      if (!el || !el2) {
        await letWait(5000)
        el = document.getElementById('old_parent')
        el2 = document.getElementById('wr_billing')
      }
      if (!el || !el2) {
        return
      }
      move(el, el2)
    })()

    return () => {
      ;(async function () {
        function move(oldParent, newParent) {
          while (oldParent.childNodes.length > 0) {
            newParent.appendChild(oldParent.childNodes[0])
          }
        }

        let el
        let el2
        el = document.getElementById('old_parent')
        el2 = document.getElementById('wr_billing')

        if (el2) {
          move(el2, el)
        }
      })()
    }
  }, [])

  useEffect(() => {
    if (
      listStateRef.current &&
      stateRef &&
      stateRef.current[stateCursor] &&
      isShowState &&
      stateCursor % 5 === 0 &&
      arrowDirection === 'down'
    ) {
      // console.log(stateRef.current[stateCursor].offsetTop)
      listStateRef.current.scrollTo(0, stateRef.current[stateCursor].offsetTop)
    } else if (
      listStateRef.current &&
      stateRef &&
      stateRef.current[stateCursor] &&
      isShowState &&
      (stateCursor + 1) % 5 === 0 &&
      arrowDirection === 'up'
    ) {
      listStateRef.current.scrollTo(
        0,
        stateRef.current[stateCursor].offsetTop - 152
      )
    }
    const locationAutocomplete =
      document.getElementsByClassName('pac-container')
    for (const address of locationAutocomplete) {
      address.style.width = '301px'
    }
    window.addEventListener('mousedown', handleClickOutside)
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
      window.removeEventListener('keydown', handleKeyDown)
    }
  })
  useEffect(() => {
    const updatedListState = AmericaAndCanadaStates.filter((state) => {
      const lowercaseState = getStr(state, 'short_name').toLowerCase()
      const lowercaseTextSearch = getStr(company, 'state').toLowerCase()
      return lowercaseState.includes(lowercaseTextSearch)
    })
    setListState(updatedListState)
  }, [company.state])
  const handleClickOutside = (e) => {
    if (
      (listStateRef.current && listStateRef.current.contains(e.target)) ||
      (iconDropdownRef.current && iconDropdownRef.current.contains(e.target))
    ) {
      return
    }
    if (isShowState) {
      setShowState(false)
    }
  }
  const handleGetCompany = async () => {
    try {
      setLoadingCompany(true)
      const { data: dataResponse } = await customAxios(token).get(
        `${apiRoute.company.COMPANY}`
      )
      if (dataResponse.success) {
        const newCompanyValue = {
          address: formatReturnedData(dataResponse.data.address),
          city: formatReturnedData(dataResponse.data.city),
          state: formatReturnedData(dataResponse.data.state),
          zipCode: formatReturnedData(dataResponse.data.zipcode),
          phoneNumber: dataResponse.data.phoneNumber,
          country: getStr(dataResponse, 'data.isoCode').toLowerCase() || 'us',
          countryCode: dataResponse.data.countryCode || '1',
          email: formatReturnedData(dataResponse.data.email),
          website: formatReturnedData(dataResponse.data.website),
        }
        holdCompanyValueRef.current = newCompanyValue
        setCompany({ ...company, ...newCompanyValue })
        setPlace(dataResponse.data.address)
        if (companyNameRef.current) {
          const companyNode = document.getElementById(
            companyNameRef.current.props.id
          )
          companyNode.focus()
        }
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    } finally {
      setLoadingCompany(false)
    }
  }
  const handleKeyDown = (e) => {
    const editButton = document.getElementById('editCompany')
    if (isShowState) {
      if (e.keyCode === 40 && stateCursor < AmericaAndCanadaStates.length - 1) {
        e.preventDefault()
        if (arrowDirection !== 'down') {
          setArrowDirection('down')
        }
        setStateCursor(stateCursor + 1)
      }
      if (e.keyCode === 38 && stateCursor > 0) {
        e.preventDefault()
        if (arrowDirection !== 'up') {
          setArrowDirection('up')
        }
        setStateCursor(stateCursor - 1)
      }
      if (e.keyCode === 9 || (e.keyCode === 13 && isShowState)) {
        setCompany({
          ...company,
          state: getStr(listState, `[${stateCursor}].short_name`),
        })
        setShowState(false)
      }
    }
    if (e.target.id === 'edit_cancelVendor' && e.keyCode === 13) {
      // handleCancel()
    }
    if (
      e.target.id === 'edit_cancelVendor' &&
      e.keyCode === 9 &&
      !e.shiftKey &&
      editButton.disabled
    ) {
      e.preventDefault()
      document.getElementById('edit-Company').focus()
    }
    if (
      e.target.id === 'edit-Company' &&
      e.keyCode === 9 &&
      e.shiftKey &&
      editButton.disabled
    ) {
      document.getElementById('edit_cancelCompany').focus()
    }
  }
  const handleMouseOverstate = (index) => () => {
    setStateCursor(index)
  }
  const handleClickState = (shortname_state) => () => {
    setErrors({ ...errors, state: '' })
    setCompany({ ...company, state: shortname_state })
    setShowState(false)
  }
  const handleToggleState = () => {
    setShowState(!isShowState)
  }
  const checkStateExist = (state) => {
    return _.findIndex(AmericaAndCanadaStates, ['short_name', state]) >= 0
  }

  useEffect(() => {
    setError({ ...error, zipCode: '' })
  }, [company.zipCode])

  const handleChange = (e) => {
    e.persist()
    const eventValue = e.target.value
    const nodeName = e.target.name
    let newCompany = {}
    switch (nodeName) {
      case 'phoneNumber': {
        if (error.phoneNumber !== '') {
          setError({ ...error, phoneNumber: '' })
        }
        break
      }
      case 'email':
        if (error.email !== '') {
          setError({ ...error, email: '' })
        }
        break

      default:
        break
    }
    if (nodeName === 'mainExt') {
      newCompany = {
        ...company,
        mainExt: eventValue.replace(/\D/g, ''),
      }
    } else if (nodeName === 'zipCode') {
      const zipCodeValue = eventValue
        // .trim()
        .toUpperCase()
        .replace(/\s/g, 'RANDOM1658444776')
        .replace(/\W/g, '')
        .replace(/RANDOM1658444776/g, ' ')

      newCompany = {
        ...company,
        zipCode: zipCodeValue,
      }
    } else if (nodeName === 'phoneNumber') {
      const regExPhoneNumber = /[0-9]{10}/g
      const regExRandom10 = /[^0-9\(\)\s\-]{10}/g
      let phoneValue = ''
      // prevent user from copying a group of string doesnt match the pattern of phonenumber only input
      if (regExRandom10.test(eventValue)) {
        phoneValue = ''
      } else if (regExPhoneNumber.test(eventValue)) {
        phoneValue = `(${eventValue.slice(0, 3)}) ${eventValue.slice(
          3,
          6
        )}-${eventValue.slice(6, 10)}`
      } else {
      }
      newCompany = { ...company, phoneNumber: phoneValue }
    } else if (nodeName === 'email') {
      newCompany = { ...company, email: toLower(eventValue) }
    } else {
      newCompany = { ...company, [nodeName]: eventValue }
    }
    holdVendorValueRef.current = newCompany
    setCompany(newCompany)
    if (nodeName === 'state' && !isShowState) {
      setShowState(true)
    }
  }
  const handleBlur = (e) => {
    if (place === null && company.address) {
      setCompany({ ...company, address: '' })
    }
    const trimValue = e.target.value.trim()
    if (e.target.name === 'state') {
      const newErrorState = !checkStateExist(trimValue) ? 'Invalid state' : ''
      setErrors({ ...errors, state: newErrorState })
    }
    updateCompany(e.target.name, trimValue)
  }
  const handleFocusState = () => {
    setErrors({ ...errors, state: '' })
    setShowState(true)
    setStateCursor(0)
  }
  const updateCompany = (nodeName, value) => {
    if (company[nodeName] !== value) {
      setCompany({ ...company, [nodeName]: value })
    }
  }

  const updateFunctionOnChange = (location, resetCityStateZip) => {
    setPlace(null)

    if (resetCityStateZip) {
      setCompany({
        ...company,
        address: location.displayValue,
        displayValue: location.displayValue,
        city: location.city,
        state: location.shortNameState,
        zipCode: location.zipcode,
      })
    } else {
      setCompany({
        ...company,
        address: location.displayValue,
        displayValue: location.displayValue,
      })
    }
  }

  const updateFunctionOnSelect = (location) => {
    const latestCompanyValue = holdCompanyValueRef.current
    const newCompany = {
      ...latestCompanyValue,
      address: location.displayValue,
      displayValue: location.displayValue,
      city: location.city,
      state: location.shortNameState,
      zipCode: location.zipcode,
    }
    setCompany(newCompany)
  }

  const checkValidate = () => {
    let isValid = true
    const newError = { ...error }

    const checkPhoneNumber = (company.phoneNumber || '').charAt(0)
    const formattedNumber = formatToNumber(company.phoneNumber)
    const formattedEmail = checkValidEmail(company.email)

    if (company.phoneNumber && formattedNumber.length < 10) {
      newError.phoneNumber = 'Please enter a valid phone number'
      isValid = false
    }
    if (checkPhoneNumber === '0') {
      newError.phoneNumber = 'Phone number does not exist'
      isValid = false
    }
    if (!formattedEmail && company.email) {
      newError.email = 'Please enter a valid email address'
      isValid = false
    }

    if (
      !!company.zipCode &&
      !AmericaZipCodeRegx.test(company.zipCode) &&
      !CanadaZipCodeRegx.test(company.zipCode)
    ) {
      newError.zipCode = 'Please enter a valid zip code'
      isValid = false
    }

    setError(newError)
    return isValid
  }
  const handleSave = async () => {
    const isAllowed = checkValidate()
    if (isAllowed && !isDispatching) {
      const bodyRequest = {
        address: company.displayValue,
        city: company.city,
        state: company.state,
        zipcode: company.zipCode,
        phoneNumber: company.phoneNumber || '',
        isoCode: company.country.toUpperCase(),
        countryCode: company.countryCode,
        email: company.email,
        website: company.website,
      }
      try {
        setDispatch(true)
        const { data: dataResponse } = await customAxios(token).put(
          `${apiRoute.company.COMPANY}`,
          bodyRequest
        )
        if (dataResponse.success) {
          setDispatch(false)
          context.message.success('Edited Successfully')
        }
      } catch (err) {
        setDispatch(false)
        context.message.error(getErrorMessage(err))
      }
    }
  }
  const valueInputLocation = company.displayValue || company.address
  // if (loadingCompany) {
  //   return null
  // }
  return (
    <div className={cls.container_company_profile}>
      <div className={cls.companyName}>{companyName}</div>
      <div className={cls.Company_form}>
        <div className={cls.Company_form_container}>
          <div className={cls.general_info}>
            <Body>General Info</Body>
            <div
              className={cls.location_container}
              style={{ marginTop: '16px' }}
            >
              <Small className={cls.label}>Address (Optional)</Small>
              <PlacesAutoComplete
                allowManual
                context={context}
                noCityStateZipcode
                updateFunctionOnChange={updateFunctionOnChange}
                updateFunctionOnSelect={updateFunctionOnSelect}
                valueInputLocation={valueInputLocation}
              />
            </div>
            <section className={cls.location__info}>
              <UikFormInputGroup
                className={classnames(
                  cls.common_input_wrapper,
                  cls.city_container
                )}
              >
                <Small className={cls.label} htmlFor="edit_company_city">
                  City
                </Small>
                <Input2
                  autoComplete="off"
                  className={cls.input_form_city}
                  id="edit_company_city"
                  maxLength="200"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  tabIndex="0"
                  type="text"
                  value={company.city}
                />
              </UikFormInputGroup>
              <UikFormInputGroup
                className={classnames(
                  cls.common_input_wrapper,
                  cls.state_container
                )}
              >
                <Small className={cls.label} htmlFor="edit_vendor_state">
                  State
                </Small>
                <Input2
                  autoComplete="off"
                  // className={classnames(cls.input_form_state, {
                  //   [cls.error_border]: !!errors.state,
                  // })}
                  id="edit_vendor_state"
                  maxLength="50"
                  name="state"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={handleFocusState}
                  tabIndex="0"
                  type="text"
                  value={company.state}
                />
                <span
                  className={cls.arrow_drd}
                  id="edit-vendor_iconDropdown"
                  onClick={handleToggleState}
                  ref={iconDropdownRef}
                >
                  <ArrowDown />
                </span>
                {isShowState && listState.length > 0 && (
                  <ul
                    className={cls.america_state_container}
                    id="edit-vendor_listState"
                    ref={listStateRef}
                  >
                    {listState.map((state, index) => (
                      <li
                        className={classnames(cls.state_name, {
                          [cls.active]: stateCursor === index,
                        })}
                        id={`edit-vendor_${state}`}
                        key={index}
                        onClick={handleClickState(state.short_name)}
                        onMouseOver={handleMouseOverstate(index)}
                        ref={(el) => (stateRef.current[index] = el)}
                      >
                        {state.short_name}
                      </li>
                    ))}
                  </ul>
                )}
              </UikFormInputGroup>
              <UikFormInputGroup
                className={classnames(
                  cls.common_input_wrapper,
                  cls.zipCode_container
                )}
              >
                <Small className={cls.label} htmlFor="edit_vendor_zipCode">
                  Zip Code
                </Small>
                <Input2
                  autoComplete="off"
                  className={cls.input_form_zipCode}
                  error={!!error.zipCode}
                  id="edit_vendor_zipCode"
                  maxLength="7"
                  name="zipCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    const validRegx = /[\w\s]/
                    if (e.ctrlKey) {
                      return true
                    }
                    if (!validRegx.test(e.key)) {
                      e.preventDefault()
                      return false
                    }
                    return true
                  }}
                  tabIndex="0"
                  type="text"
                  value={company.zipCode}
                />
                {error.zipCode && (
                  <p className={cls.errorMsg}>{error.zipCode}</p>
                )}
              </UikFormInputGroup>
            </section>
          </div>
          <div className={cls.contact_info}>
            <Body>Contact Info</Body>
            <UikFormInputGroup
              className={cls.form_input_wrapper}
              style={{ marginTop: '16px' }}
            >
              <Small className={cls.label}>Phone Number (Optional)</Small>
              {!loadingCompany && (
                <React.Fragment>
                  <MyInputPhone
                    containerClass="company_mainPhoneNumber"
                    countryDefault={company.country}
                    id="company_mainPhoneNumber"
                    onChange={(value, data) => {
                      const newCompany = {
                        ...company,
                        country: data.countryCode,
                        countryCode: data.dialCode,
                        phoneNumber: value.slice(data.dialCode.length),
                      }
                      setCompany(newCompany)
                    }}
                    value={`${company.countryCode}${company.phoneNumber}`}
                  />
                  {error.phoneNumber && (
                    <p className={cls.errorMsg}>{error.phoneNumber}</p>
                  )}
                </React.Fragment>
              )}
            </UikFormInputGroup>
            <UikFormInputGroup className={cls.form_input_wrapper}>
              <Small className={cls.label}>Email Address (Optional)</Small>
              <UikInput
                autoComplete="off"
                className={classnames(cls.input_form, {
                  [cls.errorDetection]: error.email !== '',
                })}
                id="email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={company.email}
              />
              {error.email && <p className={cls.errorMsg}>{error.email}</p>}
            </UikFormInputGroup>
            <UikFormInputGroup className={cls.form_input_wrapper}>
              <Small className={cls.label}>Website (Optional)</Small>
              <Input2
                autoComplete="off"
                className={cls.input_form}
                id="website"
                name="website"
                onBlur={handleBlur}
                onChange={handleChange}
                tabIndex="0"
                type="text"
                value={company.website}
              />
            </UikFormInputGroup>
          </div>
        </div>
        <Button onClick={handleSave} stateClass="Primary" width={61}>
          Save
        </Button>
      </div>
      {(function () {
        if (!currentPlan) {
          return null
        }
        return (
          <div className={classnames(cls.Company_form, ' mt24 ')}>
            <Body block className="mb16">
              Billings
            </Body>
            <div id="wr_billing" />
          </div>
        )
      })()}
    </div>
  )
}

export default withContext(CompanyProfile)
;((_data) => {})([React])
