import usePrevious from '@appComponent/usePrevious'
import apiRoute from '@constants/apiRoute'
import { customAxios, getErrorMessage, getStr } from '@helper'
import { useGroups } from '@hooks'
import { Drawer, Radio } from 'antd'
import classnames from 'classnames'
import _, { identity } from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'

import { Button } from '@appComponent/button'
import { ItemDriver } from '@appComponent/input'
import { Small } from '@appComponent/typography'
import { getDocumentTypes } from '@service'
import cls from './documents.scss'
import {
  InputSearch,
  InputSearchLoadNumberWhenUploadDoc,
} from '@@/App/@components/input'

const DrawerRequestDocument = identity(
  ({ context, set_time_request, setShowRequestDoc, showRequestDoc, token }) => {
    const { listIdCheckedGroups } = useGroups()
    const [textsearchdriver, setTextsearchdriver] = useState('')
    const [isChosen, setIsChosen] = useState(false)
    const [errorFetchDriver, setErrorFetchDriver] = useState(null)
    const showRequestDocPrev = usePrevious(showRequestDoc)
    const [textsearchloadnumber, setTextsearchloadnumber] = useState('')
    const [idLoad, setIdLoad] = useState(null)
    const [idTrip, setIdTrip] = useState(null)
    const [documentTypeList, setDocumentTypeList] = useState([])
    const [idDocumentType, setIdDocumentType] = useState(null)

    const [savingOrDispatching, setSavingOrDispatching] = useState(false)
    const onClose = () => {
      setShowRequestDoc(false)
    }

    const [drivers, set_drivers] = useState([])
    const [idDriver, set_idDriver] = useState(null)
    const [loadingDriver, setLoadingDriver] = useState(false)

    const selectedDriver = useMemo(() => {
      if (_.isEmpty(drivers) || !idDriver) {
        return {}
      }
      return drivers.find((o) => o.id_driver === idDriver)
    }, [drivers, idDriver])

    const handleClickSave = async () => {
      try {
        setSavingOrDispatching(true)
        let body = {
          idDriver,
          idDocumentType,
        }

        if (isShowLoadNumberField) {
          if (idLoad) {
            body.idLoad = Number(idLoad)
          } else {
            body.loadNumber = String(textsearchloadnumber)
          }
          if (idTrip) {
            body.idTrip = idTrip
          }
        }

        await customAxios(token).post(
          `${
            apiRoute.documents.REQUEST_DOCUMENT
          }?groups=${listIdCheckedGroups.join(',')}`,
          body
        )
        context.message.success('Document request sent successfully')
        set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
        // TODO
        setShowRequestDoc(false)
      } catch (err) {
        if (
          _.get(err, 'response.data.errorCode') === 'DRIVER_NO_LONGER_IN_GROUP'
        ) {
          const selectedDriverName = selectedDriver.full_name
          const selectedDriverGroupName = getStr(
            selectedDriver,
            'groups[0].name'
          )
          context.message.error(
            `Unable to request document. ${selectedDriverName} is no longer in ${selectedDriverGroupName}`
          )
        } else {
          context.message.error(getErrorMessage(err))
        }
      } finally {
        setTimeout(() => {
          setSavingOrDispatching(false)
        }, 3888)
      }
    }

    const getActiveDrivers = async (text) => {
      try {
        set_drivers([])
        setErrorFetchDriver(null)
        setLoadingDriver(true)
        const resp = await customAxios(token).get(
          `${
            apiRoute.drivers.ACTIVE_DRIVERS
          }text=${text}&groups=${listIdCheckedGroups.join(',')}`
        )
        set_drivers(resp.data.data)
      } catch (err) {
        //
        // context.message.error(getErrorMessage(err))
        setErrorFetchDriver(err)
      } finally {
        setLoadingDriver(false)
      }
    }

    const resetFields = () => {
      setIdLoad(null)
      set_idDriver(null)
      set_drivers([])
      setTextsearchdriver('')
      setIdDocumentType(null)
      setIsChosen(false)
    }

    const isShowLoadNumberField = useMemo(() => {
      if (_.isEmpty(documentTypeList) || !idDocumentType) {
        return true
      }

      const find = documentTypeList.find(
        (o) => o.idDocumentType === idDocumentType
      )
      if (find === undefined) {
        return true
      }
      return find.loadDocument
    }, [documentTypeList, idDocumentType])

    useEffect(() => {
      if (showRequestDoc === true) {
        getDocumentTypes(token, setDocumentTypeList)
        setTimeout(() => {
          let el = document.querySelectorAll(
            '.wr_drawer_upload_doc .ant-drawer-body input'
          )[0]
          if (el) {
            // el.focus()
          }
        }, 200)
      } else {
        resetFields()
      }
    }, [showRequestDoc])

    const isDisable = useMemo(() => {
      if (!idDocumentType) {
        return true
      }
      if (!idDriver) {
        return true
      }
      if (isShowLoadNumberField && !isChosen) {
        return true
      }
      return false
    }, [isChosen, idDriver, idDocumentType])

    useEffect(() => {
      setIdLoad(null)
      setTextsearchloadnumber('')
      setIsChosen(false)
    }, [idDriver])

    return (
      <Drawer
        bodyStyle={{ paddingBottom: 80 }}
        className={classnames(cls.wr_drawer_upload_doc, 'wr_drawer_upload_doc')}
        destroyOnClose
        footer={
          <div
            className={`wr_footer_add_load ${
              savingOrDispatching ? 'disabled1' : ''
            }`}
          >
            <Button
              onClick={savingOrDispatching ? null : onClose}
              stateClass={savingOrDispatching ? 'Disabled' : 'Secondary'}
              width={74}
            >
              Cancel
            </Button>
            <div className="wr_save_dispatch">
              <Button
                onClick={
                  isDisable || savingOrDispatching ? null : handleClickSave
                }
                stateClass={
                  isDisable || savingOrDispatching ? 'Disabled' : 'Primary'
                }
                width={116}
              >
                Send Request
              </Button>
            </div>
          </div>
        }
        maskClosable={false}
        onClose={onClose}
        title="Request Document"
        visible={showRequestDoc}
        width={426}
      >
        {/* begin input driver */}
        <div className={classnames(cls.form_group, 'driver_group_select')}>
          <label htmlFor="inputupload_document_file">
            <Small>Driver</Small>
          </label>
          <InputSearch
            error={errorFetchDriver}
            getListFn={getActiveDrivers}
            idData={idDriver}
            idField="id_driver"
            list={drivers}
            loadingData={loadingDriver}
            nameField="full_name"
            noDataText="No driver"
            setIdData={set_idDriver}
            setValue={setTextsearchdriver}
            showPopup={showRequestDoc}
            value={textsearchdriver}
          >
            {({
              item,
              o,
              index,
              setIdData,
              setShow,
              setValue,
              idField,
              nameField,
            }) => (
              <ItemDriver
                driver={item}
                idField={idField}
                index={index}
                nameField={nameField}
                o={o}
                setShow={setShow}
                setValue={setValue}
                set_idDriver={setIdData}
              />
            )}
          </InputSearch>
        </div>
        {/* end input driver */}
        {/* begin input Load# */}
        {isShowLoadNumberField && (
          <div className={cls.form_group}>
            <label htmlFor="inputupload_load_number">
              <Small>Load#</Small>
            </label>
            <InputSearchLoadNumberWhenUploadDoc
              disabled={!idDriver}
              idLoad={idLoad}
              idTrip={idTrip}
              isChosen={isChosen}
              setIdLoad={setIdLoad}
              setIdTrip={setIdTrip}
              setIsChosen={setIsChosen}
              setValue={setTextsearchloadnumber}
              showUploadDoc={showRequestDoc}
              showUploadDocPrev={showRequestDocPrev}
              value={textsearchloadnumber}
              token={token}
              listIdCheckedGroups={listIdCheckedGroups}
              idDriver={idDriver}
            />
          </div>
        )}

        {/* enddd input Load# */}
        {/* begin radio group document type */}
        <div className={cls.form_group}>
          <label className="mb16" htmlFor="inputupload_load_number">
            <Small>Request Document</Small>
          </label>
          <Radio.Group
            onChange={(e) => {
              setIdDocumentType(e.target.value)
            }}
            value={idDocumentType}
          >
            {documentTypeList.map((o) => {
              return (
                <Radio key={o.idDocumentType} value={o.idDocumentType}>
                  {o.name}
                </Radio>
              )
            })}
          </Radio.Group>
        </div>
        {/* enddd radio group document type */}
      </Drawer>
    )
  }
)

export default DrawerRequestDocument
;((_data) => {})([React])
