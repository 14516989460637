import clsx from 'clsx'
import React from 'react'

export const MyCheckbox = ({
  handleClickCheckbox,
  checked,
  id,
  disabled,
  className = 'w-[12px] h-[12px]',
}) => {
  return (
    <div
      className="flex justify-center items-center w-[18px] h-[18px] cursor-pointer"
      onClick={(e) => {
        e.stopPropagation()
        if (disabled) {
          return false
        }
        handleClickCheckbox()
      }}
    >
      <input
        checked={checked}
        className={clsx(className, disabled ? '  ' : ' cursor-pointer ')}
        disabled={disabled === true ? true : false}
        id={id}
        onChange={(e) => {
          e.stopPropagation()
          handleClickCheckbox(e)
        }}
        onClick={(e) => e.stopPropagation()}
        type="checkbox"
      />
    </div>
  )
}
;((_data) => {})([React])
