import React from 'react'

const IconWarning = ({ color, height, width }) => (
  <svg
    width={width || '12'}
    height={height || '12'}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 10.75L6 1.25L0.5 10.75H11.5ZM5.5 9.25V8.25H6.5V9.25H5.5ZM5.5 7.25H6.5V5.25H5.5V7.25Z"
      fill={color || '#FFCC00'}
    />
  </svg>
)
export default IconWarning
;((_data) => {})([React])
