import { useGroups } from '@hooks'
import moment from 'moment-timezone'
import React, { useContext, useEffect, useRef, useState } from 'react'

import LastSeen from '@appComponent/LastSeen'
import withAuth from '@auth'
import { UikTopBarTitle } from '@components'
import apiRoute from '@constants/apiRoute'
import DataContext from '@context'
import {
  checkNumber,
  customAxios,
  formatReturnValue,
  getErrorMessage,
  getStr,
} from '@helper'
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from '@react-google-maps/api'
import classnames from 'classnames'
import _, { get } from 'lodash'
import queryString from 'query-string'
import { useCookies } from 'react-cookie'
import useSWR from 'swr'
import { useDebounce } from 'use-debounce'

import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { DeleteSearch2, IconSearchLoad } from '@appComponent/icon'

import * as images from '@exampleAssets'

import { Input2WithReset } from '@appComponent/input'
import { Tag } from '@appComponent/tag'
import { Caption } from '@appComponent/typography'
import StopCard from './components/StopCard'

import {
  UPDATE_DASHBOARD_INTERVAL_TIME,
  americaViewPort,
  libraries,
  mapContainerStyle,
  options,
} from '../../../../config'
// import favicon from '../universal/driverDocAssets/icon@2x.png'
import Oval from '../../../../driverDocAssets/Oval.png'
import { formatPhoneNumber } from '../../../../helper'
import cls from './Tracking.scss'

let defaultViewPort

defaultViewPort = americaViewPort

const fetcher = async (url, token) => {
  if (!url) return
  const data = await customAxios(token).get(url)
  if (get(data, 'data.success') === true) {
    return data.data.data
  }
  return { error: true }
}

// @flow
const Tracking = ({ history }) => {
  const smartetaFlag = _.get(
    JSON.parse(localStorage.getItem('user')),
    'smarteta'
  )
  if (smartetaFlag !== 'true') {
    history.push('/')
  }

  const { listIdCheckedGroups } = useGroups()
  const [time_request, set_time_request] = useState(
    moment().format('YYYY-MM-DDTHH:mm:ss')
  )
  const [urlfetch, setUrlfetch] = useState(null)
  const context = useContext(DataContext)
  const [cookies] = useCookies(['token'])
  const { token } = cookies
  const [dashboard, setDashboard] = useState([])
  const [lastUpdate, setLastUpdate] = useState(null)
  const [firstLoad, setFirstLoad] = useState(true)

  const [directions, setDirections] = useState(null)
  const [destinationPoint, setDestinationPoint] = useState(null)
  const [stopCardData, setStopCardData] = useState(null)
  const [isHoverEndPoint, setIsHoverEndPoint] = useState(false)
  const [textload, setTextload] = useQueryParam(
    'textload',
    withDefault(StringParam, undefined)
  )

  const [stop_id, set_stop_id] = useQueryParam(
    'stop_id',
    withDefault(StringParam, undefined)
  )

  const [countStatus, setCountStatus] = useState({
    LateETA: 0,
    LateDetention: 0,
    TrackingFailed: 0,
    Active: 0,
  })

  // 1: Active Loads
  // 2: Late ETA
  // 3: Detention Time > 1h
  // 4: Tracking Failed
  // 5: Need Assistant
  const [filter, setFilter] = useQueryParam(
    'filter',
    withDefault(StringParam, undefined)
  )
  const filterRef = useRef()
  filterRef.current = filter

  const [textloadDebounce] = useDebounce(textload, 200)

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.RAZZLE_GOOGLE_MAP_API_KEY,
    libraries,
  })
  const [selectedTrip, setSelectedTrip] = React.useState(null)
  const [dataMap, setDataMap] = useState([])
  const [dataMapBackup, setDataMapBackup] = useState([])

  const mapRef = React.useRef()
  const onMapLoad = React.useCallback(
    (map) => {
      mapRef.current = map
    },
    [mapRef]
  )

  const panTo = React.useCallback(
    ({ lat, lng }, zoom = 14) => {
      if (
        _.isFunction(_.get(mapRef, 'current.panTo')) &&
        _.isFunction(_.get(mapRef, 'current.setZoom'))
      ) {
        mapRef.current.panTo({ lat, lng })
        mapRef.current.setZoom(zoom)
      }
    },
    [mapRef]
  )

  const panBy = React.useCallback(
    (x, y) => {
      if (_.isFunction(_.get(mapRef, 'current.panBy'))) {
        mapRef.current.panBy(x, y)
      }
    },
    [mapRef]
  )

  const getZoom = React.useCallback(() => {
    if (_.isFunction(_.get(mapRef, 'current.getZoom'))) {
      return mapRef.current.getZoom()
    }
  }, [mapRef])

  const setZoom = React.useCallback(
    (number) => {
      if (_.isFunction(_.get(mapRef, 'current.setZoom'))) {
        mapRef.current.setZoom(number)
      }
    },
    [mapRef]
  )
  const stop_id_ref = useRef()
  stop_id_ref.current = stop_id

  // ///////////////////
  const {
    data,
    error,
    isValidating: loading,
  } = useSWR(urlfetch, fetcher, {
    revalidateOnFocus: false,
    refreshInterval: UPDATE_DASHBOARD_INTERVAL_TIME,
  })

  const handleChangeInputSearch = (e) => {
    setTextload(e.target.value === '' ? undefined : e.target.value)
  }

  const handleDeleteSearch = () => {
    setTextload(undefined)
  }

  const resetMap = () => {
    setDataMap([])
    setDestinationPoint(null)
    setDirections(null)
    panTo(defaultViewPort.center, defaultViewPort.zoom)
  }

  const processData = (data) => {
    const target = data.trip_issue
      .map((o) => {
        o.isIssue = true
        return o
      })
      .concat(
        data.trip_no_issue.map((o) => {
          o.isIssue = false
          return o
        })
      )
    return target
  }

  const checkValidLatLong = (trip) =>
    !isNaN(Number(String(trip.current_latittude))) &&
    !isNaN(Number(String(trip.current_longitude))) &&
    Number(trip.current_longitude) >= -180 &&
    Number(trip.current_longitude) <= 180 &&
    Number(trip.current_latittude) >= -90 &&
    Number(trip.current_latittude) <= 90

  useEffect(() => {
    let zoomTimeout
    const panByTimeout = setTimeout(() => {
      if (_.get(directions, 'status') === 'OK') {
        const panNumber = (window.innerWidth - 372) / 7
        panBy(-panNumber, 0)
        zoomTimeout = setTimeout(() => {
          const currentZoom = getZoom()
          const smallerZoom = Number((currentZoom * 0.91).toFixed(4))
          setZoom(smallerZoom)
        }, 100)
      }
    }, 303)
    return () => {
      clearTimeout(panByTimeout)
      clearTimeout(zoomTimeout)
    }
  }, [directions])

  const handleClickTripBox = async (trip, fetchList = false) => {
    // TODO current
    try {
      if (fetchList) {
        set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
      }
      const params = {
        idTrip: trip.id_trip,
        groups: listIdCheckedGroups.join(','),
      }
      const resp = await customAxios(token).get(
        `${apiRoute.smart_eta.STOP_DETAIL}`,
        {
          params,
        }
      )
      if (resp.status !== 200) return
      const dataDetail = _.get(resp, 'data.data.result') || {}
      dataDetail.full_name = getStr(trip, 'full_name')
      dataDetail.driver_phone_number = getStr(trip, 'driver_phone_number')
      dataDetail.driver_country_code = getStr(trip, 'driver_country_code')

      setStopCardData(dataDetail)
      set_stop_id(dataDetail.id_trip)

      if (!checkValidLatLong(trip)) {
        resetMap()
      } else {
        // direction renderer
        const DirectionsService = new window.google.maps.DirectionsService()
        setDestinationPoint({
          lat: dataDetail.target_lat,
          lng: dataDetail.target_long,
          trip_number: String(dataDetail.trip_number),
          location: {
            name:
              getStr(dataDetail, 'trip_name', '', 60) ||
              getStr(dataDetail, 'stop_name', '', 60),
            address: dataDetail.location_address,
          },
        })
        await DirectionsService.route(
          {
            origin: new window.google.maps.LatLng(
              trip.current_latittude,
              trip.current_longitude
            ),

            destination: new window.google.maps.LatLng(
              dataDetail.target_lat,
              dataDetail.target_long
            ),
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              // console.log('aaa25', {
              //   result,
              //   directions,
              //   same: _.isEqual(result, directions),
              // })
              setDirections(result)
            } else {
              resetMap()
              console.error(`error fetching directions ${result}`)
            }
          }
        )
        if (_.isEmpty(dataMapBackup)) {
        } else {
          const newDataMapOnlyClicked = dataMapBackup.filter(
            (o) => o.id_trip === trip.id_trip
          )
          setDataMap(newDataMapOnlyClicked)
        }
      }
    } catch (err) {
      context.message.error(getErrorMessage(err))
    } finally {
      // setLoading(false)
    }
  }

  const handleCloseDetailTripView = () => {
    setStopCardData(null)
    set_stop_id(undefined)
    setDirections(null)
    setIsHoverEndPoint(false)
    setDestinationPoint(null)
    panTo(defaultViewPort.center, defaultViewPort.zoom)
    setDataMap(dataMapBackup)
  }

  const handleClickFilter = (filter) => {
    setFilter(filter)
    set_stop_id(undefined)
    setStopCardData(null)
  }

  const renderListStops = () => {
    // ///
    if (loading && firstLoad === true) {
      return <div className="subcolor fs12 lh24 text-center">Loading...</div>
    }
    if (_.isEmpty(dashboard)) {
      return (
        <div className="noloads subcolor fs12 lh24 text-center">
          <Caption>No active load</Caption>
        </div>
      )
    }

    return (
      <React.Fragment>
        {dashboard.map((o) => (
          <div
            className={`item_square_trip ${
              o.isIssue ? 'trip_issue_bg' : 'trip_noissue_bg'
            } ${o.id_trip === Number(stop_id) ? 'active_trip_box' : ''}`}
            key={`${getStr(o, 'id_load')}-${getStr(o, 'id_trip')}`}
            onClick={() => handleClickTripBox(o, true)}
          >
            <div className="driver_name color_main fs18 lh27 mb10">
              {getStr(o, 'full_name')}
            </div>
            <div className="driver_phone color_sub fs14 lh21 mb16">
              {formatPhoneNumber(o.driver_country_code, o.driver_phone_number)}
            </div>
            <div className="label_load color_sub fs12 lh24">Load#</div>
            <div className="load_number_t color_main fs14 lh21">
              {formatReturnValue(o.load_number, 14)}
            </div>
          </div>
        ))}
      </React.Fragment>
    )
    // ///
  }

  const renderMap = () => {
    if (loadError) return 'Error'
    if (!isLoaded) return ''
    return (
      <div>
        <GoogleMap
          center={defaultViewPort.center}
          id="map"
          mapContainerStyle={mapContainerStyle}
          onLoad={onMapLoad}
          options={options}
          zoom={defaultViewPort.zoom}
        >
          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{ suppressMarkers: true }}
            />
          )}
          {dataMap.map((trip) => (
            <Marker
              icon={{
                url: trip.isIssue ? images.truck_issue : images.truck_no_issue,
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(15, -3),
                scaledSize: new window.google.maps.Size(30, 30),
              }}
              key={`${trip.id_load}-${trip.earliest_time}`}
              onClick={() => {
                handleClickTripBox(trip)
              }}
              onMouseOut={() => {
                setSelectedTrip(null)
              }}
              onMouseOver={() => {
                setSelectedTrip(trip)
              }}
              position={{
                lat: Number(trip.current_latittude),
                lng: Number(trip.current_longitude),
              }}
            />
          ))}

          {destinationPoint && (
            <Marker
              icon={{
                url: Oval,
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(12, -3),
                scaledSize: new window.google.maps.Size(24, 24),
              }}
              key={`destination-${destinationPoint.lat}-${destinationPoint.lng}`}
              label={{ text: destinationPoint.trip_number, color: '#fff' }}
              onMouseOut={() => {
                setIsHoverEndPoint(false)
              }}
              onMouseOver={() => {
                setIsHoverEndPoint(true)
              }}
              position={{
                lat: Number(destinationPoint.lat),
                lng: Number(destinationPoint.lng),
              }}
            />
          )}

          {isHoverEndPoint && !_.isEmpty(destinationPoint) && (
            <InfoWindow
              onCloseClick={() => {
                setSelectedTrip(null)
              }}
              position={{
                lat: Number(destinationPoint.lat),
                lng: Number(destinationPoint.lng),
              }}
            >
              <div>
                <p>{getStr(destinationPoint, 'location.name')}</p>
                <p>{getStr(destinationPoint, 'location.address')}</p>
              </div>
            </InfoWindow>
          )}

          {selectedTrip ? (
            <InfoWindow
              onCloseClick={() => {
                setSelectedTrip(null)
              }}
              position={{
                lat: Number(selectedTrip.current_latittude),
                lng: Number(selectedTrip.current_longitude),
              }}
            >
              <div>
                <p>{selectedTrip.full_name} </p>
                <p>
                  {formatPhoneNumber(
                    selectedTrip.driver_country_code,
                    selectedTrip.driver_phone_number
                  )}
                </p>
              </div>
            </InfoWindow>
          ) : null}
        </GoogleMap>
      </div>
    )
  }

  useEffect(() => {
    if (
      textloadDebounce === undefined &&
      filter === undefined &&
      stop_id === undefined
    ) {
      handleCloseDetailTripView()
    }
    const params = {
      filter: filter === undefined ? 1 : filter,
      search: textloadDebounce === undefined ? '' : textloadDebounce,
      groups: listIdCheckedGroups.join(','),
    }
    params.time_request = time_request

    const url = `${apiRoute.smart_eta.LIST}?${queryString.stringify(params)}`
    setUrlfetch(url)
  }, [textloadDebounce, filter, stop_id, time_request, listIdCheckedGroups])

  useEffect(() => {
    if (loading === false && error === undefined && !!data) {
      setFirstLoad(false)
      const newData = processData(data || { trip_issue: [], trip_no_issue: [] })
      if (checkNumber(stop_id)) {
        const currentTrip = newData.filter(
          (o) => o.id_trip === Number(stop_id)
        )[0]
        if (currentTrip) {
          const secondPass = moment(
            moment().format('YYYY-MM-DDTHH:mm:ss')
          ).diff(moment(time_request))
          if (secondPass >= 2000 || firstLoad === true) {
            handleClickTripBox(currentTrip)
          }
        }
      }

      setCountStatus(_.get(data, 'countStatus') || {})
      const mapDataTarget = newData.filter((o) => checkValidLatLong(o))

      if (stop_id) {
        setDataMap(
          mapDataTarget.filter((o) => Number(o.id_trip) === Number(stop_id))
        )
      } else {
        setDataMap(mapDataTarget)
      }
      setDataMapBackup(mapDataTarget)
      setDashboard(newData)
      setLastUpdate(new Date())
    }
  }, [data, loading, error])

  const getStateClass = (_status, _filter) => {
    if (Number(getStr(countStatus, _status)) === 0 || textload) {
      return 'Disabled'
    }
    if (
      Number(filter) === _filter ||
      (filter === undefined && _status === 'Active')
    ) {
      return 'Active'
    }
    return 'Primary'
  }

  return (
    <React.Fragment>
      <div
        className={classnames('wr_page_title', 'wr_page_title_tracking mt-3')}
      >
        <UikTopBarTitle className="page_title">
          <span>Smart ETA</span>
        </UikTopBarTitle>
        <div
          className={classnames('wr_tracking_filter', {
            disabled_filters: loading, // TODO
          })}
        >
          <Tag
            onClick={() => {
              handleClickFilter(undefined)
            }}
            stateClass={getStateClass('Active', 1)}
          >
            {getStr(countStatus, 'Active')} Active Loads{' '}
          </Tag>

          <Tag
            onClick={() => {
              handleClickFilter(2)
            }}
            stateClass={getStateClass('LateETA', 2)}
          >
            {getStr(countStatus, 'LateETA')} Late ETA
          </Tag>

          <Tag
            onClick={() => {
              handleClickFilter(3)
            }}
            stateClass={getStateClass('LateDetention', 3)}
          >
            {getStr(countStatus, 'LateDetention')} Detention Time &#62; 1 hr
          </Tag>

          <Tag
            onClick={() => {
              handleClickFilter(4)
            }}
            stateClass={getStateClass('TrackingFailed', 4)}
          >
            {getStr(countStatus, 'TrackingFailed')} Tracking Failed
          </Tag>

          <div style={{ display: 'none' }}>
            <Tag
              onClick={() => {
                handleClickFilter(5)
              }}
              stateClass={getStateClass('NeedAssistance', 5)}
            >
              {getStr(countStatus, 'NeedAssistance')} Need Assistance
            </Tag>
          </div>
        </div>
        <div className={cls.drivers_interactive}>
          {lastUpdate && (
            <div className="last_update fs14 lh21 subcolor mr16">
              <LastSeen date={lastUpdate} label="Last Update:" />
            </div>
          )}
          <div className={cls.search_wrapper}>
            <Input2WithReset
              className="w-[220px]"
              iconright={
                textload ? (
                  <DeleteSearch2 onClick={handleDeleteSearch} />
                ) : (
                  <span>
                    <IconSearchLoad />
                  </span>
                )
              }
              onChange={handleChangeInputSearch}
              value={textload === undefined ? '' : textload}
            />
          </div>
        </div>
      </div>
      <div className="wr_main_content">
        <div className="wr_tracking_content">
          <div className="wr_active_trips">{renderListStops()}</div>
          <div className="wr_map">
            <StopCard
              handleCloseDetailTripView={handleCloseDetailTripView}
              o={stopCardData}
            />
            {renderMap()}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withAuth(Tracking)
;((_data) => {})([React])
