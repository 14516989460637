import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

export default function Modal({ children, selector }) {
  const ref = useRef()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    let modalContainer = document.querySelector(selector)

    if (!modalContainer) {
      modalContainer = document.querySelector('#modal') // default
    }

    ref.current = modalContainer
    setMounted(true)
  }, [])

  return mounted ? createPortal(children, ref.current) : null
}
;((_data) => {})([React])
