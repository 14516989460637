import { ArchivedBadge } from '@appComponent/ArchivedBadge'
import { useGroups } from '@hooks'
import { Tooltip } from 'antd'
import classnames from 'classnames'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import _, { identity } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { tokenDataAtom } from '@atoms/atoms'
import moment from 'moment-timezone'
import { useCookies } from 'react-cookie'

// import Modal from 'react-modal'
import PdfViewerComponent from '@appComponent/pdf/PdfViewerComponent'
import {
  checkAppointmentTime,
  customAxios,
  databaseDateFormat,
  databaseTimeFormat,
  displayDateFormat,
  displayTimeFormat,
  equalStr,
  formatDetentionTimeShort,
  formatReturnValue,
  getDateTime,
  getStr,
  getTripTypeText,
  splitDate,
  splitTime,
} from '@helper'
import { Modal } from 'antd'

import {
  DeleteLoadIcon,
  EditIconSquare,
  EditLoadIcon,
  IconTrackingInactive,
  IconTrackingOff,
  MarkerIcon,
} from '@appComponent/icon'
import apiRoute from '@constants/apiRoute'
import { ACTIVE, COMPLETE, INCOMPLETE, PENDING } from '@constants/load_status'

import { StatusButton } from '@appComponent/StatusButton'
import { Button } from '@appComponent/button'
import { SpinnerIcon } from '@appComponent/spinnericon'
import { Caption, Small } from '@appComponent/typography'
import IconClose from '../../../svg/IconClose'
import { PickDate, TimePickerComponent } from './components/common'
import cls from './loadsnew.scss'
import { PreservesLineBreak } from '@@/App/@components/PreservesLineBreak'
import IconCheck from '../../../svg/IconCheck'
import ReactModal from '../../components/MyModal/ReactModal'
import IconWarning from '../../../svg/IconWarning'
import { theme } from '../../../../../../tailwind.config'

const getFlattenList = (data) => {
  let flattenList = []
  const loadDoc = _.get(data, 'loadDocuments') || []
  flattenList = flattenList.concat(loadDoc)
  Object.entries(_.get(data, 'tripDocuments') || []).forEach(([, value]) => {
    flattenList = flattenList.concat(value || [])
  })
  return flattenList
}

if (typeof window !== 'undefined') {
  window.moment = moment
  window.displayDateFormat = displayDateFormat
  window.displayTimeFormat = displayTimeFormat
  window.databaseDateFormat = databaseDateFormat
  window.databaseTimeFormat = databaseTimeFormat
}

const initData = {
  loadDocuments: [],
  tripDocuments: {},
  allTripsComplete: false,
}

const LoadCard = identity(
  ({
    currentId,
    o,
    handleCloseLoadCard,
    context,
    location,
    setIdForEdit,
    handleShowDeleteLoad,
    listRecentlyEdittedIdLoad,
    set_time_request,
  }) => {
    const [tokenData] = useAtom(tokenDataAtom)
    const user = JSON.parse(localStorage.getItem('user'))
    const { listIdCheckedGroups } = useGroups()
    const documentFlag = _.get(user, 'document')
    const [updatingTime, setUpdatingTime] = useState(false)
    const [cookies] = useCookies(['token'])
    const { token } = cookies
    const [listTrips, setListTrips] = useState([])
    const [data, setData] = useState(initData)
    const [timeError, setTimeError] = useState('')
    const [arrivalTime, setArrivalTime] = useState({
      date: moment(moment().format(displayDateFormat), displayDateFormat),
      time: moment(moment().format(displayTimeFormat), displayTimeFormat),
    })
    const [idDocumentSelected, setIdDocumentSelected] = useState(null)
    const [depTime, setDepTime] = useState({
      date: moment(moment().format(displayDateFormat), displayDateFormat),
      time: moment(moment().format(displayTimeFormat), displayTimeFormat),
    })

    const [idEditTime, setIdEditTime] = useState(null)
    const [tab, setTab] = useState('loadinfo')
    const [loadingDoc, setLoadingDoc] = useState(true)
    const [loadingSmartETA, setLoadingSmartETA] = useState(true)
    const [showModalConfirm, setShowModalConfirm] = useState(false)

    useEffect(() => {
      if (_.isEmpty(listRecentlyEdittedIdLoad)) {
        return
      }

      getStopById(o.id_load)
    }, [listRecentlyEdittedIdLoad])

    const getStopById = async (id) => {
      try {
        // setListTrips([])
        setLoadingSmartETA(true)

        const url = `${
          apiRoute.loads.TRIPSBYLOADID
        }/${id}?groups=${listIdCheckedGroups.join(',')}`

        const { data: dataResponse } = await customAxios(token).get(url)
        if (!dataResponse.success) return
        setListTrips(dataResponse.data)
      } catch (err) {
        console.log(err)
      } finally {
        setLoadingSmartETA(false)
      }
    }

    const getDocumentsByIdLoad = async (id) => {
      try {
        // setData(initData)
        setLoadingDoc(true)
        // current
        const url = `${
          apiRoute.loads.DOCUMENTSBYLOADID
        }/${id}?groups=${listIdCheckedGroups.join(',')}`
        const { data: dataResponse } = await customAxios(token).get(url)
        if (!dataResponse.success) return
        setData(dataResponse.data)

        if (idDocumentSelected === null) {
          const flattenList = getFlattenList(dataResponse.data)
          const flattenListWithoutPending = flattenList.filter(
            (o) => o.status === 'Success' || o.status === 'Archive'
          )
          setIdDocumentSelected(
            getStr(flattenListWithoutPending, '[0].idDocument')
          )
        }
      } catch (err) {
        console.log(err)
      } finally {
        setLoadingDoc(false)
      }
    }

    const handleClickSmartETA = (id) => {
      getStopById(id)
      setTab('smarteta')
    }

    const handleClickDocument = (id) => {
      if (_.isEmpty(data.loadDocuments) && _.isEmpty(data.tripDocuments)) {
        // not call API when data no empty
        getDocumentsByIdLoad(id)
      }

      // getDocumentsByIdLoad(id)
      setTab('documents')
    }

    const handleUpdateTime = async (id_load, _location) => {
      setTimeError('')
      const formattedDate = (data) => {
        console.log(data.time)
        const target = `${dayjs(data.date).format(databaseDateFormat)} ${
          data.time ? data.time.concat(':00') : '00:00:00'
        }`
        return target
      }

      const requestData = {
        latitude: `${_location.lat}`,
        longitude: `${_location.long}`,
      }

      let arrivalDateTime
      let depDateTime
      if (arrivalTime.date === null && arrivalTime.time === null) {
      } else {
        arrivalDateTime = formattedDate(arrivalTime)
        requestData.arrivalTime = arrivalDateTime
      }

      if (depTime.date === null && depTime.time === null) {
      } else {
        depDateTime = formattedDate(depTime)
        requestData.departureTime = depDateTime
      }

      if (depDateTime && arrivalDateTime) {
        if (moment(depDateTime).isBefore(arrivalDateTime)) {
          setTimeError('Departure Time must be later than Arrival Time.')
          return
        }
      }

      try {
        setUpdatingTime(true)
        const apiLink = `${
          apiRoute.loads.UPDATE_DATE
        }/${idEditTime}?groups=${listIdCheckedGroups.join(',')}`
        const { data } = await customAxios(token).put(apiLink, requestData)
        if (data.success) {
          context.message.success('Updated successfully')
          // TODO if need to refresh
          setIdEditTime(null)
          setTimeout(() => {
            getStopById(id_load)
          }, 200)
        }
      } catch (e) {
        context.message.error(e.response.data.messages)
      } finally {
        setUpdatingTime(false)
      }
    }

    const openModalConfirm = () => {
      setShowModalConfirm(true)
    }

    const cancelModalConfirm = () => {
      setShowModalConfirm(false)
    }

    const handleCompleteLoad = async () => {
      try {
        const { data: dataResponse } = await customAxios(token).put(
          `${apiRoute.loads.COMPLETE}/${o.id_load}`
        )
        if (dataResponse.success) {
          setShowModalConfirm(false)
          context.message.success('Update Successfully')
          set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))
        }
      } catch (err) {
        console.log('err update complete', err)
      }
    }

    const renderMain = () => {
      const renderListSmartETA = () => {
        if (loadingSmartETA) {
          return (
            <tr>
              <td colSpan="7">
                <SpinnerIcon />
              </td>
            </tr>
          )
        }
        if (_.isEmpty(listTrips)) {
          return (
            <tr>
              <td colSpan="7">
                <div className={cls.wr_no_smart_eta}>
                  <Caption>No Data.</Caption>
                </div>
              </td>
            </tr>
          )
        }
        return (
          <React.Fragment>
            {listTrips.map((trip) => (
              <Trip
                arrivalTime={arrivalTime}
                context={context}
                depTime={depTime}
                getStopById={getStopById}
                handleUpdateTime={handleUpdateTime}
                idEditTime={idEditTime}
                key={trip.id_trip}
                location={location}
                o={o}
                setArrivalTime={setArrivalTime}
                setDepTime={setDepTime}
                setIdEditTime={setIdEditTime}
                setTimeError={setTimeError}
                setUpdatingTime={setUpdatingTime}
                timeError={timeError}
                token={token}
                trip={trip}
                updatingTime={updatingTime}
              />
            ))}
          </React.Fragment>
        )
      }

      return (
        <React.Fragment>
          {/* loadinfo tab */}
          <div
            className={classnames(
              'loadcard_mid_12 loadcard_mid_12_loadinfo',
              [cls.loadcard_mid_12],
              {
                hidden: tab !== 'loadinfo',
              }
            )}
          >
            <div className={classnames('loadcard_left', [cls.loadcard_left])}>
              {tab === 'loadinfo' && (
                <div className="myflex">
                  <div className="item">
                    <div className="s">Driver</div>
                    <div className="c">{getStr(o, 'driverName')}</div>
                  </div>
                  <div className="item">
                    <div className="s">Carrier</div>
                    <div className="c">
                      {getStr(o, 'vendorName') || getStr(o, 'companyName')}
                    </div>
                  </div>
                  <div className="item">
                    <div className="s">Truck#</div>
                    <div className="c">{getStr(o, 'truck')}</div>
                  </div>
                  <div className="item">
                    <div className="s">Trailer#</div>
                    <div className="c">{getStr(o, 'trailer')}</div>
                  </div>
                  <div className="item">
                    <div className="s">Note</div>
                    <PreservesLineBreak text={getStr(o, 'note', '')} />
                  </div>
                </div>
              )}
            </div>
            <div className={classnames('loadcard_right', [cls.loadcard_right])}>
              {o.loadSheetUrl ? (
                <PdfViewerComponent
                  actionFns={{}}
                  document={o.loadSheetUrl}
                  id="pspdfkit_sheet_wr"
                  rightSideButtonsType="none"
                />
              ) : (
                <div className={cls.wr_no_load_sheet}>
                  <Caption>No load sheet available</Caption>
                </div>
              )}
            </div>
          </div>
          {/* loadinfo tab end */}

          {/* smart eta tab */}
          <div
            className={classnames('loadcard_mid_3', [cls.loadcard_mid_3], {
              hidden: tab !== 'smarteta',
            })}
          >
            <React.Fragment>
              <div
                className={classnames(
                  'table-responsive',
                  cls.tableResponsive,
                  cls.wr_table_smarteta
                )}
              >
                <div>
                  <table className={classnames(cls.tableContainer)}>
                    <thead className={cls.headTable}>
                      <tr>
                        <th className={classnames('h1', [cls.h1])}>
                          STOP# / STATUS
                        </th>
                        <th className={classnames('h2', [cls.h2])}>
                          STOP NAME / ADDRESS
                        </th>
                        <th className={classnames('h3', [cls.h3])}>ORDER#</th>
                        <th className={classnames('h3', [cls.h3])}>
                          APPOINTMENT#
                        </th>
                        <th className={classnames('h4', [cls.h4])}>
                          APPOINTMENT
                        </th>
                        <th className={classnames('h5', [cls.h5])}>TIME</th>
                        <th className={classnames('h6', [cls.h6])}>
                          DETENTION
                        </th>
                        {/* <th className={classnames('h7', [cls.h7])}>NOTE</th> */}
                        <th className={classnames('h7', [cls.h7])}>
                          SMART ETA
                        </th>
                      </tr>
                    </thead>
                    <tbody className={cls.bodyTable}>
                      {renderListSmartETA()}
                    </tbody>
                  </table>
                </div>
              </div>
            </React.Fragment>
          </div>
          {/* smart eta tab end */}

          <DocumentsTabView
            data={data}
            documentFlag={documentFlag}
            idDocumentSelected={idDocumentSelected}
            loadingDoc={loadingDoc}
            o={o}
            setIdDocumentSelected={setIdDocumentSelected}
            tab={tab}
          />
        </React.Fragment>
      )
    }

    useEffect(() => {
      setTab('loadinfo')
      setIdDocumentSelected(null)
    }, [o.id_load])

    useEffect(() => {
      if (idEditTime === null) {
        setTimeError('')
      }
    }, [idEditTime])

    useEffect(() => {
      setListTrips([])
      setData(initData)
    }, [currentId])

    if (!o) return null
    return (
      <div className={classnames('loadcard', [cls.loadcard])}>
        {/* Modal Edit time */}
        <Modal
          closable={false}
          footer={null}
          keyboard
          maskClosable={false}
          onCancel={() => {
            setIdEditTime(null)
          }}
          title={null}
          visible={!!idEditTime}
          width={306}
          wrapClassName="wr_modal_time"
        >
          <div className="foo1">Edit Time</div>
          {timeError && <div className="time_error">{timeError}</div>}
          <div className="foo2 foo2.1">
            <div className="foo2_title">Arrival</div>
            <div className="foo2_content">
              <div className="wr_date">
                <div className="label">Date</div>
                <PickDate
                  className="arrival_date"
                  onChange={(date) => {
                    console.log(date)
                    setArrivalTime({ ...arrivalTime, date })
                  }}
                  // format={displayDateFormat}
                  popperContainerSelector=".wr_modal_time .ant-modal-body"
                  reset={() => {
                    setArrivalTime({ ...arrivalTime, date: null })
                  }}
                  value={arrivalTime.date}
                />
              </div>
              <div className="wr_time1">
                <div className="w80">
                  <div className="label">Time</div>
                  <TimePickerComponent
                    onHandleChangeTime={(time) => {
                      setArrivalTime({ ...arrivalTime, time })
                    }}
                    timeValue={arrivalTime.time}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="foo2 foo2.2">
            <div className="foo2_title">Departure</div>
            <div className="foo2_content">
              <div className="wr_date">
                <div className="label">Date</div>
                <PickDate
                  className={classnames({ red_border: !!timeError })}
                  disabled={
                    arrivalTime.date === null || arrivalTime.time === null
                  }
                  onChange={(date) => {
                    setDepTime({ ...depTime, date })
                  }}
                  popperContainerSelector=".wr_modal_time .ant-modal-body"
                  reset={() => {
                    setDepTime({ ...depTime, date: null })
                  }}
                  value={depTime.date}
                />
              </div>
              <div className="wr_time1">
                <div className="w80">
                  <div className="label">Time</div>
                  <TimePickerComponent
                    className={classnames({ red_border: !!timeError })}
                    disabled={
                      arrivalTime.date === null || arrivalTime.time === null
                    }
                    onHandleChangeTime={(time) => {
                      setDepTime({ ...depTime, time })
                    }}
                    timeValue={depTime.time}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="wr_close_save_1">
            <Button
              onClick={() => setIdEditTime(null)}
              stateClass="Secondary"
              width={74}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleUpdateTime(o.id_load, location)}
              stateClass={
                updatingTime ||
                arrivalTime.date === null ||
                arrivalTime.time === null
                  ? 'Disabled'
                  : 'Primary'
              }
              width={61}
            >
              Save
            </Button>
          </div>
        </Modal>
        {/* Modal Edit time end */}
        <div className={classnames('loadcard_top', [cls.loadcard_top])}>
          <div
            className={classnames('loadcard_title load', [cls.loadcard_title])}
          >
            Load# {formatReturnValue(o.load_number, 50)}
          </div>
          <div className={classnames('wr_tabs', [cls.wr_tabs])}>
            <div className="bg-LightestGray flex h-11 gap-x-6 items-center px-4 rounded">
              <div
                className={classnames('onetab', [cls.onetab], {
                  tab_active: tab === 'loadinfo',
                  [cls.tab_active]: tab === 'loadinfo',
                })}
                onClick={() => setTab('loadinfo')}
              >
                Load Info
              </div>
              <div
                className={classnames('onetab', [cls.onetab], {
                  tab_active: tab === 'smarteta',
                  [cls.tab_active]: tab === 'smarteta',
                })}
                onClick={() => handleClickSmartETA(o.id_load)}
              >
                Smart ETA
              </div>
              {documentFlag === 'true' && (
                <div
                  className={classnames('onetab', [cls.onetab], {
                    tab_active: tab === 'documents',
                    [cls.tab_active]: tab === 'documents',
                  })}
                  onClick={() => handleClickDocument(o.id_load)}
                >
                  Documents
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-x-5">
            {tokenData.role !== 'Staff' && (
              <Tooltip
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                style={{ color: 'red' }}
                placement="bottom"
                title="Delete"
              >
                <div onClick={() => handleShowDeleteLoad(o.id_load)}>
                  <DeleteLoadIcon width={24} height={24} />
                </div>
              </Tooltip>
            )}

            {o.load_status === 'Incomplete' && (
              <Tooltip
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                placement="bottom"
                title="Complete"
              >
                <div onClick={() => openModalConfirm(data.id_load)}>
                  <IconCheck width={24} height={24} />
                </div>
              </Tooltip>
            )}

            <Tooltip
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              placement="bottom"
              title="Edit"
            >
              <div onClick={() => setIdForEdit(o.id_load)}>
                <EditLoadIcon width={24} height={24} />
              </div>
            </Tooltip>
          </div>
          <div
            className={classnames('close_load_card', [cls.close_load_card])}
            onClick={handleCloseLoadCard}
          >
            <IconClose />
          </div>
        </div>

        {renderMain()}

        <ReactModal
          open={showModalConfirm}
          headerText="Complete Load"
          Content="Are you sure you want to complete this load?"
          ButtonContent={
            <div className="flex justify-between">
              <Button
                className="bg-[#E3E7ED] hover:bg-[#0063a0] hover:text-white"
                stateClass="Secondary"
                width={74}
                onClick={cancelModalConfirm}
              >
                Cancel
              </Button>

              <Button
                stateClass={'Primary'}
                width={74}
                onClick={handleCompleteLoad}
              >
                Complete
              </Button>
            </div>
          }
        />
      </div>
    )
  }
)

export default LoadCard

const Trip = ({ trip, o, setArrivalTime, setDepTime, setIdEditTime }) => {
  const arrivalIconWrapperRef = useRef(null)

  const allowToEditArrivalTime = (trip) => {
    const status = getStr(trip, 'trip_status')
    return (
      equalStr(status, ACTIVE) ||
      equalStr(status, INCOMPLETE) ||
      equalStr(status, COMPLETE)
    )
  }

  const handleClickModifyDate = (trip) => {
    // if (!trip.arrival_timezone) {
    //   context.message.error('Arrival timezone not available')
    //   return
    // }

    setIdEditTime(trip.id_trip)
    const status = getStr(trip, 'trip_status')
    // const currentTime = {
    //   date: moment(
    //     moment()
    //       .tz(trip.arrival_timezone)
    //       .format(displayDateFormat),
    //     displayDateFormat,
    //   ),
    //   time: moment(
    //     moment()
    //       .tz(trip.arrival_timezone)
    //       .format(displayTimeFormat),
    //     displayTimeFormat,
    //   ),
    // }

    const nullTime = {
      date: null,
      time: null,
    }

    let newArrivalTime = null
    if (
      (!equalStr(status, ACTIVE) &&
        !equalStr(status, PENDING) &&
        trip.realtime_arrival) ||
      (equalStr(status, ACTIVE) && trip.realtime_arrival)
    ) {
      newArrivalTime = {
        date: moment(splitDate(trip.realtime_arrival))._d,
        time: splitTime(trip.realtime_arrival),
      }
    } else {
      newArrivalTime = { ...nullTime }
    }

    let newDepTime = null
    if (
      (!equalStr(status, ACTIVE) &&
        !equalStr(status, PENDING) &&
        trip.realtime_departure) ||
      (equalStr(status, ACTIVE) && trip.realtime_departure)
    ) {
      newDepTime = {
        date: moment(splitDate(trip.realtime_departure))._d,
        time: splitTime(trip.realtime_departure),
      }
    } else {
      newDepTime = { ...nullTime }
    }
    setArrivalTime(newArrivalTime)
    setDepTime(newDepTime)
  }

  const _renderAppointmentTime = (o) => {
    const earliest_time = getDateTime(o.earliest_time)
    const latest_time = getDateTime(o.latest_time)

    if (checkAppointmentTime(o.earliest_time, o.latest_time) === 'window') {
      if (splitDate(o.latest_time) === splitDate(o.earliest_time)) {
        return (
          <React.Fragment>
            {earliest_time} to {splitTime(o.latest_time)}
          </React.Fragment>
        )
      }
      return (
        <React.Fragment>
          {earliest_time} to {latest_time}
        </React.Fragment>
      )
    }
    return <React.Fragment>{earliest_time}</React.Fragment>
  }

  const renderTime = (trip, i) => {
    let etaOrArrDepTime = null
    if (
      equalStr(trip.trip_status, ACTIVE) &&
      trip.arrival_eta &&
      !trip.realtime_arrival
    ) {
      etaOrArrDepTime = (
        <StatusButton
          status={trip.eta > 0 ? 'Inactive' : 'active'}
          text={`ETA: ${getDateTime(trip.arrival_eta)}`}
        />
      )
    } else {
      etaOrArrDepTime = (
        <React.Fragment>
          <div className={cls.wr_infotime}>
            <div className="flex items-center">
              {trip.realtime_arrival && (
                <span
                  className={classnames('flex whitespace-nowrap', [cls.arr])}
                >
                  ARR: {getDateTime(getStr(trip, 'realtime_arrival'))}
                </span>
              )}
            </div>

            {trip.realtime_departure && (
              <span className={cls.arr}>
                DEP: {getDateTime(getStr(trip, 'realtime_departure'))}
              </span>
            )}
          </div>

          {trip.is_manual_time && (
            <Tooltip placement="bottom" title="Manual input">
              <div className="ml-2">
                <IconWarning />
              </div>
            </Tooltip>
          )}
        </React.Fragment>
      )
    }

    return (
      <div>
        {trip.realtime_arrival === '' ? (
          <div>
            {allowToEditArrivalTime(trip) && (
              <div
                className={`${cls.icon_edit_wrapper}`}
                id={`arrival_icon_wrapper_${o.load_number}_${i}`}
                onClick={handleClickModifyDate.bind(null, trip)}
                ref={arrivalIconWrapperRef}
              >
                <EditIconSquare height={16} width={16} />
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center">
            {etaOrArrDepTime}
            {allowToEditArrivalTime(trip) && (
              <div
                className={`${cls.icon_wrapper}`}
                id={`arrival_icon_wrapper_${o.load_number}_${i}`}
                onClick={handleClickModifyDate.bind(null, trip)}
                ref={arrivalIconWrapperRef}
              >
                <EditIconSquare height={16} width={16} />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  const renderTrackingIcon = (trip) => {
    if (trip.tracking !== true) {
      return (
        <Tooltip
          className="cursor-not-allowed"
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
          placement="left"
          title="Tracking Off"
        >
          <div className="cursor-not-allowed flex justify-center">
            <IconTrackingOff />
          </div>
        </Tooltip>
      )
    }
    if (equalStr(ACTIVE, trip.trip_status)) {
      return (
        <div className={`${cls.wr_mark_icon}`} style={{ cursor: 'normal' }}>
          <Link
            to={`/page/tracking?textload=${o.load_number}&stop_id=${trip.id_trip}`}
          >
            <Tooltip
              className=""
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              placement="left"
              title="Tracking Active"
            >
              <div className="cursor-not-allowed flex justify-center">
                <MarkerIcon />
              </div>
            </Tooltip>
          </Link>
        </div>
      )
    }

    return (
      <Tooltip
        className="cursor-not-allowed"
        mouseEnterDelay={0}
        mouseLeaveDelay={0}
        placement="left"
        title="Tracking Inactive"
      >
        <div className="cursor-not-allowed flex justify-center">
          <IconTrackingInactive />
        </div>
      </Tooltip>
    )
  }

  const address = getStr(trip, 'location.address')
  let addressAndName = ''
  let name = ''
  if (address === '') {
  } else {
    name = getStr(trip, 'location.name')
    addressAndName = address.includes(name) ? address : `${name}, ${address}`
  }
  const mouseOver = (tripId) => {
    let selector = document.getElementsByClassName(`background-hover-${tripId}`)
    for (let i = 0; i < selector.length; i++) {
      selector[i].style.backgroundColor = theme.extend.colors.LightestGray
    }
  }
  const mouseOut = (tripId) => {
    let selector = document.getElementsByClassName(`background-hover-${tripId}`)
    for (let i = 0; i < selector.length; i++) {
      selector[i].style.backgroundColor = ''
    }
  }

  return (
    <React.Fragment>
      <tr className={classnames([cls.spacer])} />
      <tr
        onMouseOver={() => mouseOver(trip.id_trip)}
        onMouseOut={() => mouseOut(trip.id_trip)}
        className={` ${
          trip.note ? 'border-0 ' : 'border-b '
        } background-hover-${trip.id_trip}`}
      >
        <td className={classnames('d1', [cls.d1])}>
          <span className={cls.status_text}>
            #{getStr(trip, 'trip_number')}
            {trip.trip_type !== ''
              ? `- ${getTripTypeText(trip.trip_type)}`
              : null}
          </span>
          <div className="h-[4px] "></div>
          <StatusButton status={getStr(trip, 'trip_status')} />
        </td>
        <td className={cls.d2}>
          <span className={(cls.address_main, 'breakword')}>
            {getStr(trip, 'trip_name', '', 125)}
          </span>
          <span className={cls.address_sub}>{addressAndName}</span>
        </td>
        <td className={cls.d3}>
          <span className={cls.order}>{getStr(trip, 'order')}</span>
        </td>
        <td className={cls.d3}>
          <span className={cls.appt}>{getStr(trip, 'appointment_number')}</span>
        </td>
        <td className={cls.d4}>
          <span className={cls.appointment}>
            {_renderAppointmentTime(trip)}
          </span>
        </td>
        <td className={cls.d5}>
          <div className={classnames([cls.wr_time], 'justify-between pr-8')}>
            {renderTime(trip)}
          </div>
        </td>
        <td className={cls.d6}>
          {!getStr(trip, 'detentionTime')
            ? ''
            : formatDetentionTimeShort(Number(trip.detentionTime), true)}
        </td>
        <td>{renderTrackingIcon(trip)}</td>
      </tr>
      {trip.note === null
        ? ''
        : trip.note.trim() && (
            <tr
              onMouseOver={() => mouseOver(trip.id_trip)}
              onMouseOut={() => mouseOut(trip.id_trip)}
              className={`border-b background-hover-${trip.id_trip}`}
            >
              <td className={classnames([cls.spacer])} />
              <td colSpan="7" className="py-4">
                <div className="bg-LightestGray py-2 px-4">
                  <div className="pb-4 text-DarkGray text-xs">Note</div>
                  <div className="whitespace-pre-line">{trip.note}</div>
                </div>
              </td>
            </tr>
          )}
    </React.Fragment>
  )
}

const DocumentsTabView = identity(
  ({
    o,
    data,
    tab,
    idDocumentSelected,
    setIdDocumentSelected,
    loadingDoc,
    documentFlag,
  }) => {
    const urlDocSelected = useMemo(() => {
      if (
        !!idDocumentSelected &&
        (!_.isEmpty(data.loadDocuments) || !_.isEmpty(data.tripDocuments))
      ) {
        const flattenList = getFlattenList(data)
        let target
        const docSelected = flattenList.find(
          (o) => o.idDocument === idDocumentSelected
        )
        if (docSelected) {
          target = docSelected.url
        }
        return target
      }
      return ''
    }, [data, idDocumentSelected])

    const renderLoadDocuments = () => (
      <TitleAndTable
        array={data.loadDocuments.filter((o) => o.status !== 'Pending')}
        idDocumentSelected={idDocumentSelected}
        setIdDocumentSelected={setIdDocumentSelected}
        title=""
      />
    )

    const renderTripDocuments = () => (
      <React.Fragment>
        {Object.entries(data.tripDocuments).map(([, value]) => (
          <TitleAndTable
            key={JSON.stringify(value)}
            array={value.filter((o) => o.status !== 'Pending')}
            idDocumentSelected={idDocumentSelected}
            setIdDocumentSelected={setIdDocumentSelected}
            title={getStr(value, '[0].documentTrip.tripName')}
          />
        ))}
      </React.Fragment>
    )

    const flatDocumentArray = useMemo(() => getFlattenList(data), [data])

    if (!documentFlag) {
      return null
    }

    if (loadingDoc) {
      return (
        <div
          className={classnames({
            hidden: tab !== 'documents',
          })}
        >
          <SpinnerIcon />
        </div>
      )
    }

    if (_.isEmpty(data.loadDocuments) && _.isEmpty(data.tripDocuments)) {
      return (
        <div
          className={classnames(cls.wr_no_doc, {
            hidden: tab !== 'documents',
          })}
        >
          {equalStr(o.load_status, INCOMPLETE) &&
          data.allTripsComplete === true ? (
            <Caption className={cls.Red}>Missing document</Caption>
          ) : (
            <Caption>No document available</Caption>
          )}
        </div>
      )
    }

    if (flatDocumentArray.every((o) => o.status === 'Pending')) {
      return (
        <div
          className={classnames(cls.doc_in_process, {
            hidden: tab !== 'documents',
          })}
        >
          <Caption>Pending document upload ...</Caption>
        </div>
      )
    }

    return (
      <React.Fragment>
        {/* documents tab */}
        <div
          className={classnames(
            'loadcard_mid_12 loadcard_mid_12_documents',
            [cls.loadcard_mid_12],
            {
              hidden: tab !== 'documents',
            }
          )}
        >
          <div
            className={classnames('loadcard_left aaa2', [cls.loadcard_left])}
          >
            {tab === 'documents' && (
              <React.Fragment>
                {/**/}
                {renderLoadDocuments()}
                {renderTripDocuments()}
                {/**/}
              </React.Fragment>
            )}
          </div>
          <div className={classnames('loadcard_right', [cls.loadcard_right])}>
            <PdfViewerComponent
              actionFns={{}}
              document={urlDocSelected}
              id="pspdfkit_documenttab_wr"
              rightSideButtonsType="none"
            />
          </div>
        </div>
        {/* documents tab end */}
      </React.Fragment>
    )
  }
)

const TitleAndTable = ({
  title,
  array,
  setIdDocumentSelected,
  idDocumentSelected,
}) => {
  if (_.isEmpty(array)) {
    return null
  }

  const getUploadedDateTime = (dateStr) => {
    if (!dateStr) {
      return ''
    }
    return dateStr
      ? dayjs(dateStr).format(`${displayDateFormat} - ${displayTimeFormat}`)
      : ''
  }

  const renderBody = () => (
    <React.Fragment>
      {array.map((document) => (
        <React.Fragment key={document.idDocument}>
          <tr className={classnames([cls.spacer])} />
          <tr
            className={classnames(cls.document_row, {
              [cls.selected_document_row]:
                idDocumentSelected === document.idDocument,
            })}
            onClick={() => {
              setIdDocumentSelected(document.idDocument)
            }}
          >
            <td className="breakword">
              {getStr(document, 'documentType.name', '', 60)}
            </td>
            <td className="breakword">
              {getStr(document, 'documentNumber', '', 35)}
            </td>
            <td className="breakword">
              {getUploadedDateTime(document.uploadTime)}
            </td>
            <td className="breakword">
              {getStr(document, 'uploadedBy', '', 25)}
            </td>
            <td className="breakword">
              {equalStr(document.status, 'Archive') && <ArchivedBadge />}
            </td>
          </tr>
        </React.Fragment>
      ))}
    </React.Fragment>
  )

  return (
    <div className={cls.wr_title_and_table}>
      {!!title && (
        <div className={cls.title}>
          <Small block>Stop Name</Small>
          <Caption block className={cls.mb17}>
            {title}
          </Caption>
        </div>
      )}
      <div
        className={classnames(
          'table-responsive',
          cls.tableResponsive,
          cls.pb25,
          cls.wr_table_documents
        )}
      >
        <table className={classnames(cls.tableContainer)}>
          <thead className={cls.headTable}>
            <tr>
              <th className={classnames('h1', [cls.h1])}>DOCUMENT TYPE</th>
              <th className={classnames('h2', [cls.h2])}>DOCUMENT#</th>
              <th className={classnames('h3', [cls.h3])}>UPLOADED DATE/TIME</th>
              <th className={classnames('h4', [cls.h4])}>UPLOADED BY</th>
              <th className={classnames('h5', [cls.h5])} />
            </tr>
          </thead>
          <tbody className={cls.bodyTable}>{renderBody()}</tbody>
        </table>
      </div>
    </div>
  )
}
;((_data) => {})([React])
