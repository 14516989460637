import { tokenDataAtom } from '@atoms/atoms'
import { useAtom } from 'jotai'
import React from 'react'

import withAuth from '@auth'
import { handleRedirect } from '@helper'

const Dashboard = ({ history }) => {
  const [tokenData] = useAtom(tokenDataAtom)
  handleRedirect(tokenData, history)
  return null
}

export default withAuth(Dashboard)
;((_data) => {})([React])
