import { toastSuccess } from '@helper'
import React from 'react'

export const Copier = ({ children, className }) => {
  const copyToClipboard = (str) => {
    const el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false
    el.select()
    const success = document.execCommand('copy')
    document.body.removeChild(el)
    if (selected) {
      document.getSelection().removeAllRanges()
      document.getSelection().addRange(selected)
    }
    return success
  }
  return (
    <div
      className={`cursor-copy ${className}`}
      onClick={(e) => {
        const copyResult = copyToClipboard(e.target.innerHTML)
        if (!copyResult) {
          return
        }
        toastSuccess('Copied')
      }}
    >
      {children}
    </div>
  )
}
;((_data) => {})([React])
