import apiRoute from '@@/constants/apiRoute'
import { getErrorMessage } from '@@/helper'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'

export const useVerifyAuthToken = (token) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!token) {
      return
    }
    const action = async () => {
      try {
        setData(null)
        setError(null)
        setIsLoading(true)
        const axios = Axios.create({
          headers: { authorization: `Bearer ${token}` },
        })
        const resp = await axios.get(apiRoute.verifyAuthToken)
        setData(resp.data)
      } catch (error) {
        setError(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    }
    action()
  }, [token])

  return { data, error, isLoading }
}
;((_data) => {})([React])
