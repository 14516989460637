import React from 'react'

const Step3 = () => (
  <svg
    fill="none"
    height="33"
    viewBox="0 0 32 33"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="17" r="14.5" stroke="#33BEAD" strokeWidth="3" />
    <path
      d="M15.622 8.918C16.3553 8.918 17.0263 9.028 17.635 9.248C18.2437 9.46067 18.7643 9.765 19.197 10.161C19.6297 10.557 19.967 11.0337 20.209 11.591C20.451 12.1483 20.572 12.768 20.572 13.45C20.572 14.0367 20.484 14.5793 20.308 15.078C20.1393 15.5767 19.9083 16.0533 19.615 16.508C19.329 16.9627 18.9917 17.4027 18.603 17.828C18.2143 18.2533 17.8073 18.686 17.382 19.126L13.796 22.789C14.148 22.6863 14.4927 22.6093 14.83 22.558C15.1673 22.4993 15.49 22.47 15.798 22.47H19.879C20.1723 22.47 20.4033 22.5543 20.572 22.723C20.748 22.8843 20.836 23.1007 20.836 23.372V25H9.924V24.098C9.924 23.9147 9.96067 23.724 10.034 23.526C10.1147 23.328 10.2393 23.1447 10.408 22.976L15.237 18.136C15.6403 17.7253 15.9997 17.333 16.315 16.959C16.6377 16.585 16.9053 16.2147 17.118 15.848C17.338 15.4813 17.503 15.111 17.613 14.737C17.7303 14.3557 17.789 13.956 17.789 13.538C17.789 13.1567 17.734 12.823 17.624 12.537C17.514 12.2437 17.3563 11.998 17.151 11.8C16.9457 11.602 16.7 11.4553 16.414 11.36C16.1353 11.2573 15.82 11.206 15.468 11.206C14.8153 11.206 14.2763 11.371 13.851 11.701C13.433 12.031 13.1397 12.4747 12.971 13.032C12.8903 13.3107 12.7693 13.5123 12.608 13.637C12.4467 13.7543 12.2413 13.813 11.992 13.813C11.882 13.813 11.761 13.802 11.629 13.78L10.199 13.527C10.309 12.7643 10.5217 12.097 10.837 11.525C11.1523 10.9457 11.5447 10.4653 12.014 10.084C12.4907 9.69533 13.0333 9.40567 13.642 9.215C14.258 9.017 14.918 8.918 15.622 8.918Z"
      fill="#33BEAD"
    />
  </svg>
)

export default Step3
;((_data) => {})([React])
