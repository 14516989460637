import { useEffect, useState } from 'react'
const publicIp = require('public-ip')

const usePublicIp = () => {
  const [ipPublic, setIpPublic] = useState('')
  useEffect(() => {
    const getIp = async () => {
      let ip = await publicIp.v4()
      setIpPublic(ip)
    }
    getIp()
  }, [])
  return [ipPublic]
}

export default usePublicIp
