import React, { useEffect, useState } from 'react'

import classnames from 'classnames'
import _ from 'lodash'
import { useCookies } from 'react-cookie'

import { withContext } from '@hoc'

import { UikButton } from '@components'

import { Redirect } from 'react-router-dom'

import { clientId, provider } from '../../constants'
import route from '../../constants/route'
import DriverDocMainLogo from '../../driverDocAssets/DriverDocMainLogo.png'
import Footer from '../@components/Footer'
import { H3 } from '../@components/typography/index'
import createAuthContext from '../lib/createAuthContext'
import { getTokenFromLocation } from '../lib/helpers/getTokenFromLocation'
import cls from './login.scss'

// @flow
const { AuthContext, Authenticated } = createAuthContext({
  clientId,
  provider,
  // tokenEndpoint: 'http://localhost:3020/token' // If token endpoint is not "provider + '/token'"
})
function ProtectedStuff() {
  return <Authenticated>This would be visible only if logged in.</Authenticated>
}

const Login = ({ history }) => {
  const [showProtected, setShowProtected] = useState(false)
  const handleSignup = () => {
    history.push('/start')
  }
  const handleLogin = () => {
    setShowProtected(!showProtected)
  }
  const [cookies] = useCookies(['token'])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    console.log('aaa Login did mount beg')
    const objectToken = getTokenFromLocation({ location: window.location })
    const { token, accessToken } = cookies

    console.log('aaa token ', token ? 'abc' : token)
    console.log('aaa accessToken ', accessToken ? 'abc' : accessToken)
    console.log('aaa objectToken ', objectToken)

    if (!_.isEmpty(token)) {
      console.log('aaa co token => redirect to dashboard')
      setLoading(false)
    } else if (!_.isEmpty(accessToken)) {
      console.log('aaa co accesstoken => redirect to dashboard')
      setLoading(false)
    } else if (objectToken) {
      console.log('aaa co objectToken => chay AuthContext')
      setLoading(false)
      setRedirect(true)
    } else {
      console.log('aaa else => chay AuthContext')
      setLoading(false)
      setRedirect(true)
    }
    console.log('aaa Login did mount end')
  }, [])

  if (loading) {
    return null
  }
  if (!redirect) {
    return <Redirect to={route.PAGE.SUB_ROUTE.DASHBOARD} />
  }
  return (
    <AuthContext history={history}>
      <div className={cls.container}>
        <div className={cls.imageBackground} />
        <div className={cls.formLoginContainer}>
          <div className={classnames(cls.imgContainer, { 'wr-left': true })}>
            <img
              alt="logo"
              className={cls.logoImg}
              height="100px"
              src={DriverDocMainLogo}
              width="177.8px"
            />
          </div>
          <div>
            <H3>Try driverDOC FREE for 14 days</H3>
          </div>
          <div className={cls.title_desc}>
            Full access. No credit card needed.
          </div>
          <div className={cls.link_signup} onClick={handleSignup}>
            Sign Up Now
          </div>
          <div className={cls.ready_account}>Already have an account?</div>
          <div onClick={handleLogin}>
            <UikButton className={cls.loginBtn}>Log in</UikButton>
            {showProtected && <ProtectedStuff />}
          </div>
          <div className={cls.footer_info}>
            <Footer />
          </div>
        </div>
      </div>
    </AuthContext>
  )
}

export default withContext(Login)
;((_data) => {})([React])
