import React from 'react'

const IconUnlock = ({ color }) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.58325 9.24968C7.58325 8.92752 7.32209 8.66635 6.99992 8.66635C6.67775 8.66635 6.41659 8.92752 6.41659 9.24968V10.9997C6.41659 11.3218 6.67775 11.583 6.99992 11.583C7.32209 11.583 7.58325 11.3218 7.58325 10.9997V9.24968Z"
      fill={color || '#50565E'}
    />
    <path
      d="M6.79132 2.26171C8.00448 2.14047 9.10503 2.94861 9.34516 4.11868C9.40993 4.43427 9.71827 4.63759 10.0339 4.57283C10.3495 4.50806 10.5528 4.19972 10.488 3.88413C10.1257 2.11897 8.47395 0.921071 6.6753 1.10083C4.87638 1.28061 3.4988 2.78173 3.49992 4.58359L3.49992 6.3469H2.62492C1.98059 6.3469 1.45825 6.86923 1.45825 7.51357V12.7636C1.45825 13.4079 1.98059 13.9302 2.62492 13.9302H11.3749C12.0193 13.9302 12.5416 13.4079 12.5416 12.7636V7.51357C12.5416 6.86923 12.0193 6.3469 11.3749 6.3469H4.66659L4.66659 4.58379C4.66538 3.39116 5.57883 2.38289 6.79132 2.26171ZM2.62492 7.51357H11.3749V12.7636H2.62492V7.51357Z"
      fill={color || '#50565E'}
    />
  </svg>
)
export default IconUnlock
;((_data) => {})([React])
