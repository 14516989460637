/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { MyInputPhone } from '@appComponent/MyInputPhone'
import { Button } from '@appComponent/button'
import apiRoute from '@constants/apiRoute'
import { customAxios, formatToNumber, getStr } from '@helper'
import { withContext } from '@hoc'
import {
  AmericaAndCanadaStates,
  AmericaZipCodeRegx,
  CanadaZipCodeRegx,
  checkValidEmail,
} from '@utils'
import { Drawer } from 'antd'
import classnames from 'classnames'
import _, { isEmpty, toLower, trim } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'

import { PlacesAutoComplete } from '@appComponent/PlacesAutoComplete'
import { ArrowDown, IconDeleteAddLoad } from '@appComponent/icon'
import { Input2 } from '@appComponent/input'
import { UikFormInputGroup, UikInput } from '@components'
import {
  getCity,
  getShortNameState,
  getZipcode,
} from '../../../Loadsnew/components/common/helper'

import cls from './add-vendor.scss'
import { isEmptyTrimmed } from '@@/helper'

// @flow
function AddVendor({ context, onCancel, onClickAdd, showAdd }) {
  const initialVendor = {
    displayValue: '',
    name: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    mainPhoneNumber: '',
    country: 'us',
    countryCode: '1',
    emailAddress: '',
    contacts: [
      {
        contactName: '',
        phoneNumber: '',
        isoCode: 'US',
        countryCode: '1',
        ext: '',
        email: '',
        index: 1,
      },
    ],
  }
  const errorContact = [
    {
      contactName: '',
      phoneNumber: '',
      ext: '',
      email: '',
      index: 1,
    },
    {
      contactName: '',
      phoneNumber: '',
      ext: '',
      email: '',
      index: 2,
    },
    {
      contactName: '',
      phoneNumber: '',
      ext: '',
      email: '',
      index: 3,
    },
  ]
  const initialErrors = { ...initialVendor, contacts: errorContact }

  const [cookies, setCookie, removeCookie] = useCookies(['token'])
  const { token } = cookies

  const [value, setValue] = useState('')
  const [vendor, setVendor] = useState(initialVendor)
  const [isShowState, setShowState] = useState(false)
  const [stateCursor, setStateCursor] = useState(null)
  const [arrowDirection, setArrowDirection] = useState(null)

  const [listState, setListState] = useState(AmericaAndCanadaStates)
  const [isDispatching, setDispatching] = useState(false)
  const [close, setClose] = useState(false)

  const [errors, setErrors] = useState(initialErrors)

  const holdVendorValueRef = useRef(initialVendor)
  const stateRef = useRef([])
  const addressRef = useRef(null)
  const vendorNameRef = useRef(null)
  const iconDropdownRef = useRef(null)
  const listStateRef = useRef(null)
  const [place, setPlace] = useState(null)

  useEffect(() => {
    setStateCursor(-1)
  }, [listState])
  useEffect(() => {
    const buttonDropDown = document.querySelector(
      '.containerClass .flag-dropdown'
    )
    if (isEmpty(buttonDropDown)) {
      return
    }
    buttonDropDown.addEventListener('click', async (a, b) => {
      // ////////////////////////////
      let countries = document.querySelectorAll(
        '.containerClass .country-list .country'
      )
      var interval = setInterval(() => {
        if (isEmpty(countries)) {
          countries = document.querySelectorAll(
            '.containerClass .country-list .country'
          )
        } else {
          clearInterval(interval)
          countries.forEach((o, i) => {
            const oldText = o.innerHTML
            const reg = />\+\d{1,100}/
            const match = oldText.match(reg)
            if (match !== null) {
              const match2 = match[0]

              const match3 = match2.replace('+', '(+')
              const match4 = `${match3})`
              const newText = oldText.replace(reg, match4)
              o.innerHTML = newText
            }
          })
        }
      }, 30)
      // ////////////////////////////
    })

    return () => {}
  }, [])
  useEffect(() => {
    if (vendorNameRef.current) {
      const vendorNode = document.getElementById(vendorNameRef.current.props.id)
      vendorNode.focus()
    }
  }, [])
  useEffect(() => {
    if (showAdd === true) {
      setTimeout(() => {
        const el = document.querySelectorAll('input#add-vendor_name')[0]
        if (el) {
          el.focus()
        }
      }, 200)
    } else {
      resetFields()
    }
  }, [showAdd])
  useEffect(() => {
    if (
      listStateRef.current &&
      stateRef &&
      stateRef.current[stateCursor] &&
      isShowState &&
      stateCursor % 5 === 0 &&
      arrowDirection === 'down'
    ) {
      listStateRef.current.scrollTo(0, stateRef.current[stateCursor].offsetTop)
    } else if (
      listStateRef.current &&
      stateRef &&
      stateRef.current[stateCursor] &&
      isShowState &&
      (stateCursor + 1) % 5 === 0 &&
      arrowDirection === 'up'
    ) {
      listStateRef.current.scrollTo(
        0,
        stateRef.current[stateCursor].offsetTop - 152
      )
    }
    const locationAutocomplete =
      document.getElementsByClassName('pac-container')
    for (const address of locationAutocomplete) {
      address.style.width = '301px'
    }
    window.addEventListener('mousedown', handleClickOutside)
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  useEffect(() => {
    const updatedListState = AmericaAndCanadaStates.filter((state) => {
      const lowercaseState = state.short_name.toLowerCase()
      const lowercaseTextSearch = vendor.state.toLowerCase()
      return lowercaseState.includes(lowercaseTextSearch)
    })
    setListState(updatedListState)
  }, [vendor.state])

  const handleClickOutside = (e) => {
    if (
      (listStateRef.current && listStateRef.current.contains(e.target)) ||
      (iconDropdownRef.current && iconDropdownRef.current.contains(e.target))
    ) {
      return
    }
    if (isShowState) {
      setShowState(false)
    }
  }

  const handleKeyDown = (e) => {
    const addButton = document.getElementById('addVendor')
    if (isShowState) {
      if (e.keyCode === 40 && stateCursor < AmericaAndCanadaStates.length - 1) {
        e.preventDefault()
        if (arrowDirection !== 'down') {
          setArrowDirection('down')
        }
        setStateCursor(stateCursor + 1)
      }
      if (e.keyCode === 38 && stateCursor > 0) {
        e.preventDefault()
        if (arrowDirection !== 'up') {
          setArrowDirection('up')
        }
        setStateCursor(stateCursor - 1)
      }
      if (e.keyCode === 9 || (e.keyCode === 13 && isShowState)) {
        if (_.isEmpty(listState)) {
          //
        } else {
          setVendor({
            ...vendor,
            state: getStr(listState, `[${stateCursor}].short_name`),
          })
        }

        setShowState(false)
      }
    }
    if (e.target.id === 'add_cancelVendor' && e.keyCode === 13) {
      handleCancel()
    }
    if (
      e.target.id === 'add_cancelVendor' &&
      e.keyCode === 9 &&
      !e.shiftKey &&
      addButton.disabled
    ) {
      e.preventDefault()
      document.getElementById('add-vendor_name').focus()
    }
    if (
      e.target.id === 'add-vendor_name' &&
      e.keyCode === 9 &&
      e.shiftKey &&
      addButton.disabled
    ) {
      document.getElementById('add_cancelVendor').focus()
    }
  }

  const phoneFormation = (inputValue) => {
    let result = ''
    const input = inputValue.replace(/\D/g, '').substring(0)
    const zip = input.substring(0, 3)
    const middle = input.substring(3, 6)
    const last = input.substring(6)
    if (input.length > 6) {
      result = `(${zip}) ${middle}-${last}`
    } else if (input.length > 3) {
      result = `(${zip}) ${middle}`
    } else if (input.length > 0) {
      result = `(${zip}`
    }
    return result
  }

  useEffect(() => {
    setErrors({ ...errors, zipCode: '' })
  }, [vendor.zipCode])

  const handleChange = (e) => {
    e.persist()
    const eventValue = e.target.value
    const nodeName = e.target.name
    let newVendor = {}
    switch (nodeName) {
      case 'mainPhoneNumber': {
        if (errors.mainPhoneNumber !== '') {
          setErrors({ ...errors, mainPhoneNumber: '' })
        }
        break
      }
      case 'emailAddress': {
        if (errors.emailAddress !== '') {
          setErrors({ ...errors, emailAddress: '' })
        }
        break
      }
    }
    if (nodeName === 'mainExt') {
      newVendor = {
        ...vendor,
        mainExt: eventValue.replace(/\D/g, ''),
      }
    } else if (nodeName === 'zipCode') {
      const zipCodeValue = eventValue
        // .trim()
        .toUpperCase()
        .replace(/\s/g, 'RANDOM1658444776')
        .replace(/\W/g, '')
        .replace(/RANDOM1658444776/g, ' ')

      newVendor = {
        ...vendor,
        zipCode: zipCodeValue,
      }
    } else if (nodeName === 'mainPhoneNumber') {
      const regExPhoneNumber = /[0-9]{10}/g
      const regExRandom10 = /[^0-9\(\)\s\-]{10}/g
      let phoneValue = ''
      // prevent user from copying a group of string doesnt match the pattern of phonenumber only input
      if (regExRandom10.test(eventValue)) {
        phoneValue = ''
      } else if (regExPhoneNumber.test(eventValue)) {
        phoneValue = `(${eventValue.slice(0, 3)}) ${eventValue.slice(
          3,
          6
        )}-${eventValue.slice(6, 10)}`
      } else {
        phoneValue = phoneFormation(eventValue)
      }
      newVendor = { ...vendor, mainPhoneNumber: phoneValue }
    } else if (nodeName === 'emailAddress') {
      newVendor = { ...vendor, emailAddress: toLower(eventValue) }
    } else {
      newVendor = { ...vendor, [nodeName]: eventValue }
    }
    holdVendorValueRef.current = newVendor
    setVendor(newVendor)
    if (nodeName === 'state' && !isShowState) {
      setShowState(true)
    }
  }

  const handleBlur = (e) => {
    if (place === null && vendor.address) {
      setVendor({ ...vendor, address: '' })
    }
    const trimValue = e.target.value.trim()
    // if (e.target.name === 'state') {
    //   const newErrorState = !checkStateExist(trimValue) ? 'Invalid state' : ''
    //   setErrors({ ...errors, state: newErrorState })
    // }
    updateVendor(e.target.name, trimValue)
  }

  const updateVendor = (nodeName, value) => {
    if (vendor[nodeName] !== value) {
      setVendor({ ...vendor, [nodeName]: value })
    }
  }
  const resetFields = () => {
    setVendor(initialVendor)
    setErrors(initialErrors)
  }
  const handleFocusAddress = (e) => {
    if (place === null && vendor.address) {
      setVendor({ ...vendor, address: '' })
    }
    handleAutocompleteGoogleMap()
  }
  const handleFocusState = () => {
    setErrors({ ...errors, state: '' })
    setShowState(true)
    if (listState.length === 1) {
      setStateCursor(0)
    } else {
      setStateCursor(-1)
    }
  }

  const handleChangeLocation = (event) => {
    event.persist()
    const { value } = event.target
    setPlace(null)
    setVendor({ ...vendor, address: value })
    customAxios(token).post(`${apiRoute.google.COUNT}`)
  }

  const handleAutocompleteGoogleMap = () => {
    const { google } = window
    const autocomplete = new google.maps.places.Autocomplete(addressRef.current)
    autocomplete.addListener('place_changed', () => {
      const latestVendorValue = holdVendorValueRef.current
      const place = autocomplete.getPlace()
      if (place) {
        setPlace(place)
      } else {
        setPlace(null)
      }
      let addressAndName = getStr(place, 'formatted_address').includes(
        getStr(place, 'name')
      )
        ? getStr(place, 'formatted_address')
        : `${getStr(place, 'name')}, ${getStr(place, 'formatted_address')}`

      if (!getStr(place, 'formatted_address')) {
        addressAndName = place.name
      }
      const newVendor = {
        ...latestVendorValue,
        address: addressAndName,
        city: getCity(place.address_components),
        state: getShortNameState(place.address_components),
        zipCode: getZipcode(place.address_components),
      }
      setVendor(newVendor)
      customAxios(token).post(`${apiRoute.google.COUNT}`)
    })
  }

  const updateFunctionOnChange = (location, resetCityStateZip) => {
    setPlace(null)
    if (resetCityStateZip) {
      setVendor({
        ...vendor,
        address: location.displayValue,
        displayValue: location.displayValue,
        // current
        city: location.city,
        state: location.shortNameState,
        zipCode: location.zipcode,
      })
    } else {
      setVendor({
        ...vendor,
        address: location.displayValue,
        displayValue: location.displayValue,
      })
    }
  }

  const updateFunctionOnSelect = (location) => {
    const latestVendorValue = holdVendorValueRef.current
    const newVendor = {
      ...latestVendorValue,
      address: location.displayValue,
      displayValue: location.displayValue,
      city: location.city,
      state: location.shortNameState,
      zipCode: location.zipcode,
    }
    setVendor(newVendor)
  }

  const handleBlurContact = (index, e) => {
    e.persist()
    const trimValue = e.target.value.trim()
    const nodeName = e.target.name
    const oldValue = _.get(vendor, `contacts[${index}].${nodeName}`)
    const newContacts = vendor.contacts

    if (oldValue !== trimValue) {
      _.set(newContacts, `[${index}]${nodeName}`, trimValue)
      setVendor({ ...vendor, contacts: newContacts })
    }
  }

  const handleChangeContact = (index, e) => {
    const i = index
    e.persist()
    const eventValue = e.target.value
    const nodeName = e.target.name
    const newContacts = vendor.contacts.slice()
    const newErrorContacts = errors.contacts.slice()

    switch (nodeName) {
      case 'phoneNumber': {
        if (errors.contacts[i].phoneNumber !== '') {
          _.set(newErrorContacts, `[${index}]${nodeName}`, '')
        }
        break
      }
      case 'email': {
        if (errors.contacts[i].email !== '') {
          _.set(newErrorContacts, `[${index}]${nodeName}`, '')
        }
        break
      }
    }
    if (nodeName === 'ext') {
      _.set(newContacts, `[${index}]${nodeName}`, eventValue.replace(/\D/g, ''))
    } else if (nodeName === 'phoneNumber') {
      const regExPhoneNumber = /[0-9]{10}/g
      const regExRandom10 = /[^0-9\(\)\s\-]{1,}/g
      let phoneValue = ''
      // prevent user from copying a group of string doesnt match the pattern of phonenumber only input
      if (regExRandom10.test(eventValue)) {
        phoneValue = ''
      } else if (regExPhoneNumber.test(eventValue)) {
        phoneValue = `(${eventValue.slice(0, 3)}) ${eventValue.slice(
          3,
          6
        )}-${eventValue.slice(6, 10)}`
      } else {
        phoneValue = phoneFormation(eventValue)
      }
      _.set(newContacts, `[${index}]${nodeName}`, phoneValue)
      // set phone number
    } else {
      _.set(
        newContacts,
        `[${index}]${nodeName}`,
        nodeName === 'email' ? toLower(eventValue) : eventValue
      )
    }

    // update state
    holdVendorValueRef.current = {
      ...vendor,
      contacts: newContacts,
    }
    setVendor({
      ...vendor,
      contacts: newContacts,
    })
  }

  const checkRequire = (name) => {
    const result = _.map(vendor.contacts, `${name}`)

    return result.length === _.compact(result).length
  }

  const disableVerification = () => {
    if (isEmptyTrimmed(vendor.name)) {
      return true
    }

    return false
  }

  const checkStateExist = (state) =>
    _.findIndex(AmericaAndCanadaStates, ['short_name', state]) >= 0

  const checkValidate = () => {
    let isValid = true
    const newError = { ...errors }

    if (trim(vendor.name)) {
      isValid = true
    }

    let checkPhoneValid = false
    let checkEmailValid = false
    vendor.contacts.forEach((item, index) => {
      const checkPhone = item.phoneNumber.charAt(0)
      const emailTest = checkValidEmail(item.email)

      if (item.phoneNumber && item.phoneNumber.length < 10) {
        checkPhoneValid = true
        newError.contacts[index].phoneNumber =
          'Please enter a valid phone number'
      }
      if (checkPhone === '0') {
        checkPhoneValid = true
        newError.contacts[index].phoneNumber = 'Phone number does not exist'
      }
      if (!emailTest && item.email !== '') {
        checkEmailValid = true
        newError.contacts[index].email = 'Please enter a valid email address'
      }
    })
    const ok = vendor.mainPhoneNumber.charAt(0)

    const formattedEmail = checkValidEmail(vendor.emailAddress)
    if (vendor.mainPhoneNumber && vendor.mainPhoneNumber.length < 10) {
      newError.mainPhoneNumber = 'Please enter a valid phone number'
      isValid = false
    }
    if (ok === '0') {
      newError.mainPhoneNumber = 'Phone number does not exist'
      isValid = false
    }
    if (!formattedEmail && vendor.emailAddress) {
      newError.emailAddress = 'Please enter a valid email address'
      isValid = false
    }

    if (
      !!vendor.zipCode &&
      !AmericaZipCodeRegx.test(vendor.zipCode) &&
      !CanadaZipCodeRegx.test(vendor.zipCode)
    ) {
      newError.zipCode = 'Please enter a valid zip code'
      isValid = false
    }

    if (checkPhoneValid) {
      isValid = false
    }

    if (checkEmailValid) {
      isValid = false
    }
    setErrors(newError)
    return isValid
  }

  const handleAdd = async () => {
    const isAllowed = checkValidate()

    if (isAllowed && !isDispatching) {
      const bodyRequest = {
        name: trim(vendor.name),
        address: vendor.displayValue,
        city: vendor.city,
        state: vendor.state,
        zipcode: vendor.zipCode,
        mainPhoneNumber: formatToNumber(vendor.mainPhoneNumber),
        isoCode: vendor.country.toUpperCase(),
        countryCode: vendor.countryCode,
        emailAddress: vendor.emailAddress,
        contacts: vendor.contacts,
      }

      try {
        setDispatching(true)
        const { data: dataResponse } = await customAxios(token).post(
          `${apiRoute.vendors.BASE_VENDORS}`,
          bodyRequest
        )
        if (dataResponse.success) {
          setDispatching(false)
          onClickAdd('Added Successfully')
        }
      } catch (err) {
        if (err.response.status === 400) {
          context.message.error('bad request')
        }
        setDispatching(false)
        // context.message.error(
        //   getErrorMessage(err)
        // )
      }
    }
  }

  const handleToggleState = () => {
    setShowState(!isShowState)
  }

  const handleClickState = (shortname_state) => () => {
    setErrors({ ...errors, state: '' })
    setVendor({ ...vendor, state: shortname_state })
    setShowState(false)
  }

  const handleMouseOverstate = (index) => () => {
    setStateCursor(index)
  }

  const handleCancel = () => {
    setClose(true)
    onCancel()
  }

  const handleKeyDownSave = (e) => {
    if (e.keyCode === 9 && !e.shiftKey) {
      e.preventDefault()
      const vendorNode = document.getElementById(vendorNameRef.current.props.id)
      vendorNode.focus()
    }
  }

  const handleKeyDownName = (event) => {
    // press shift + tab
    if (event.shiftKey && event.keyCode === 9) {
      event.preventDefault()
      const saveNode = document.getElementById('addVendor')
      saveNode.focus()
    }
  }

  const handleAddMore = () => {
    const newContacts = vendor.contacts.map((item) => ({ ...item }))
    const length = newContacts.length + 1
    if (newContacts.length >= 3) return
    newContacts.push({
      contactName: '',
      phoneNumber: '',
      ext: '',
      email: '',
      isoCode: 'US',
      countryCode: '1',
      index: length,
    })
    setVendor({ ...vendor, contacts: newContacts })
    setTimeout(() => {
      const el = document.getElementById(
        `vendor_contactName_${newContacts.length - 1}`
      )
      if (el) {
        el.focus()
      }

      // scroll to end
      const antDrawerBody = Array.from(
        document.querySelectorAll('.wr_drawer_add3 .ant-drawer-body')
      )[0]
      if (antDrawerBody) {
        antDrawerBody.scrollTop = antDrawerBody.scrollHeight
      }
    }, 100)
  }

  const handleRemoveContact = (index, e) => {
    const newContacts = vendor.contacts.slice()
    newContacts.splice(index, 1)
    setVendor({
      ...vendor,
      contacts: newContacts,
    })
  }

  const contactRender = () =>
    vendor.contacts.map((item, index) => (
      <div key={index}>
        <div className={cls.dashed_line} />
        <div className={cls.trip_number_wrapper}>
          <h4 className={cls.trip_number}>
            {`Contact ${index + 1}`} (Optional)
          </h4>
          {vendor.contacts.length > 1 && index > 0 && (
            <button
              className={cls.btn_remove_trip}
              onClick={handleRemoveContact.bind(this, index)}
              tabIndex="0"
              type="button"
            >
              <IconDeleteAddLoad />
            </button>
          )}
        </div>
        <UikFormInputGroup
          className={classnames(cls.common_input_wrapper, cls.name_container)}
        >
          <label className={cls.label} htmlFor={`vendor_contactName_${index}`}>
            Contact Name
          </label>
          <UikInput
            autoComplete="off"
            className={cls.input_form}
            id={`vendor_contactName_${index}`}
            maxLength="70"
            name="contactName"
            onBlur={handleBlurContact.bind(this, index)}
            onChange={handleChangeContact.bind(this, index)}
            placeholder=""
            tabIndex="0"
            type="text"
            value={item.contactName}
          />
        </UikFormInputGroup>
        <section className={cls.contact_info}>
          <UikFormInputGroup
            className={classnames(
              cls.common_input_wrapper,
              cls.phoneNumber_container_contact
            )}
          >
            <label
              className={cls.label}
              htmlFor={`vendor_phoneNumber_${index}`}
            >
              Phone Number
            </label>
            <MyInputPhone
              className={classnames('w-[222px]')}
              containerClass={`vendor_phoneNumber_${index}`}
              countryDefault={
                vendor.contacts
                  ? vendor.contacts[index].isoCode.toLowerCase()
                  : 'us'
              }
              id={`vendor_phoneNumber_${index}`}
              onChange={(value, data, event, formattedValue) => {
                const newContact = vendor.contacts.slice()
                const newErrorContacts = errors.contacts.slice()

                if (errors.contacts[index].phoneNumber !== '') {
                  _.set(newErrorContacts, `[${index}]phoneNumber`, '')
                }

                _.set(
                  newContact,
                  `[${index}]isoCode`,
                  data.countryCode.toUpperCase()
                )
                _.set(newContact, `[${index}]countryCode`, data.dialCode)
                _.set(
                  newContact,
                  `[${index}]phoneNumber`,
                  value.slice(data.dialCode.length)
                )
                setVendor({ ...vendor, contacts: newContact })
              }}
              value={item.countryCode + item.phoneNumber}
            />
            {errors.contacts[index].phoneNumber && (
              <p className={cls.errorMsg}>
                {errors.contacts[index].phoneNumber}
              </p>
            )}
          </UikFormInputGroup>
          <UikFormInputGroup
            className={classnames(
              cls.common_input_wrapper,
              cls.extension_container
            )}
          >
            <label
              className={cls.label}
              htmlFor={`vendor_ext_${index}`}
              style={{ marginLeft: '8px' }}
            >
              Ext.
            </label>
            <UikInput
              autoComplete="off"
              className={cls.input_form_right}
              id={`vendor_ext_${index}`}
              name="ext"
              onBlur={handleBlurContact.bind(this, index)}
              onChange={handleChangeContact.bind(this, index)}
              tabIndex="0"
              type="text"
              value={item.ext}
            />
          </UikFormInputGroup>
        </section>
        <UikFormInputGroup
          className={classnames(cls.common_input_wrapper, cls.email_container)}
        >
          <label className={cls.label} htmlFor={`vendor_email_${index}`}>
            Email Address
          </label>
          <UikInput
            autoComplete="off"
            className={classnames(cls.input_form, {
              [cls.errorDetection]: errors.contacts[index].email !== '',
            })}
            id={`vendor_email_${index}`}
            maxLength="50"
            name="email"
            onBlur={handleBlurContact.bind(this, index)}
            onChange={handleChangeContact.bind(this, index)}
            tabIndex="0"
            type="text"
            value={item.email}
          />
          {errors.contacts[index].email && (
            <p className={cls.errorMsg}>{errors.contacts[index].email}</p>
          )}
        </UikFormInputGroup>
      </div>
    ))
  const valueInputLocation = vendor.displayValue
  return (
    <Drawer
      bodyStyle={{ paddingBottom: 80 }}
      className="wr_drawer_add3"
      destroyOnClose
      footer={
        <div className="wr_footer_add_load ">
          <Button onClick={handleCancel} stateClass="Secondary" width={74}>
            Cancel
          </Button>

          <Button
            id="addVendor"
            onClick={handleAdd}
            onKeyDown={handleKeyDownSave}
            stateClass={disableVerification() ? 'Disabled' : 'Primary'}
            width={58}
          >
            Add
          </Button>
        </div>
      }
      maskClosable={false}
      onClose={handleCancel}
      title="Add Vendor"
      visible={showAdd}
      width={380}
    >
      <div
        className={classnames([cls.content], { [cls.close]: close === true })}
      >
        <form className={cls.form_add_vendor}>
          <UikFormInputGroup
            className={classnames(cls.common_input_wrapper, cls.name_container)}
          >
            <label className={cls.label} htmlFor="add-vendor_name">
              Name
            </label>
            <UikInput
              autoComplete="off"
              className={cls.input_form}
              id="add-vendor_name"
              maxLength="70"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyDown={handleKeyDownName}
              ref={vendorNameRef}
              tabIndex="0"
              type="tel"
              value={vendor.name}
            />
          </UikFormInputGroup>
          <div className={cls.location_container}>
            <label className={cls.label} htmlFor="vendor_address">
              Address (Optional)
            </label>
            <PlacesAutoComplete
              allowManual
              context={context}
              noCityStateZipcode
              updateFunctionOnChange={updateFunctionOnChange}
              updateFunctionOnSelect={updateFunctionOnSelect}
              valueInputLocation={valueInputLocation}
            />
          </div>
          <section className={cls.location__info}>
            <UikFormInputGroup
              className={classnames(
                cls.common_input_wrapper,
                cls.city_container
              )}
            >
              <label className={cls.label} htmlFor="edit_vendor_city">
                City
              </label>
              <Input2
                autoComplete="off"
                className={(cls.input_form_city, cls.border_input)}
                id="edit_vendor_city"
                maxLength="200"
                name="city"
                onBlur={handleBlur}
                onChange={handleChange}
                tabIndex="0"
                type="text"
                value={vendor.city}
              />
            </UikFormInputGroup>
            <UikFormInputGroup
              className={classnames(
                cls.common_input_wrapper,
                cls.state_container
              )}
            >
              <label className={cls.label} htmlFor="edit_vendor_state">
                State
              </label>
              <Input2
                autoComplete="off"
                className={classnames(cls.input_form_state, cls.border_input, {
                  [cls.error_border]: !!errors.state,
                })}
                id="edit_vendor_state"
                maxLength="50"
                name="state"
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocusState}
                tabIndex="0"
                type="text"
                value={vendor.state}
              />
              <span
                className={cls.arrow_drd}
                id="edit-vendor_iconDropdown"
                onClick={handleToggleState}
                ref={iconDropdownRef}
              >
                <ArrowDown />
              </span>
              {isShowState && listState.length > 0 && (
                <ul
                  className={cls.america_state_container}
                  id="edit-vendor_listState"
                  ref={listStateRef}
                >
                  {listState.map((state, index) => (
                    <li
                      className={classnames(cls.state_name, {
                        [cls.active]: stateCursor === index,
                      })}
                      id={`edit-vendor_${state}`}
                      key={index}
                      onClick={handleClickState(state.short_name)}
                      onMouseOver={handleMouseOverstate(index)}
                      ref={(el) => (stateRef.current[index] = el)}
                    >
                      {state.short_name}
                    </li>
                  ))}
                </ul>
              )}
            </UikFormInputGroup>
            <UikFormInputGroup
              className={classnames(
                cls.common_input_wrapper,
                cls.zipCode_container
              )}
            >
              <label className={cls.label} htmlFor="edit_vendor_zipCode">
                Zip Code
              </label>
              <Input2
                autoComplete="off"
                className={(cls.input_form_zipCode, cls.border_input)}
                error={!!errors.zipCode}
                id="edit_vendor_zipCode"
                maxLength="7"
                name="zipCode"
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={(e) => {
                  const validRegx = /[\w\s]/
                  if (e.ctrlKey) {
                    return true
                  }
                  if (!validRegx.test(e.key)) {
                    e.preventDefault()
                    return false
                  }
                  return true
                }}
                tabIndex="0"
                type="text"
                value={vendor.zipCode}
              />
              {errors.zipCode && (
                <p className={cls.errorMsg}>{errors.zipCode}</p>
              )}
            </UikFormInputGroup>
          </section>

          <UikFormInputGroup
            className={classnames(
              cls.common_input_wrapper,
              cls.phoneNumber_container
            )}
          >
            <label className={cls.label} htmlFor="vendor_mainPhoneNumber">
              Main Phone Number (Optional)
            </label>
            {/* <UikInput
              autoComplete="off"
              className={classnames(cls.input_form, {
                [cls.errorDetection]: errors.mainPhoneNumber !== '',
              })}
              id="vendor_mainPhoneNumber"
              maxLength="14"
              name="mainPhoneNumber"
              onBlur={handleBlur}
              onChange={handleChange}
              tabIndex="0"
              type="text"
              value={vendor.mainPhoneNumber}
            /> */}
            <MyInputPhone
              className={classnames('w-[320px]')}
              containerClass="vendor_mainPhoneNumber"
              countryDefault={
                vendor.country ? vendor.country.toLowerCase() : 'us'
              }
              id="vendor_mainPhoneNumber"
              onChange={(value, data, event, formattedValue) => {
                const newVendor = {
                  ...vendor,
                  country: data.countryCode,
                  countryCode: data.dialCode,
                  mainPhoneNumber: value.slice(data.dialCode.length),
                }
                setVendor(newVendor)
              }}
              value={vendor.countryCode + vendor.mainPhoneNumber}
            />
            {errors.mainPhoneNumber && (
              <p className={cls.errorMsg}>{errors.mainPhoneNumber}</p>
            )}
          </UikFormInputGroup>
          <UikFormInputGroup
            className={classnames(
              cls.common_input_wrapper,
              cls.phoneNumber_container
            )}
          >
            <label className={cls.label} htmlFor="vendor_emailAddress">
              Email Address (Optional)
            </label>
            <UikInput
              autoComplete="off"
              className={classnames(cls.input_form, {
                [cls.errorDetection]: errors.emailAddress !== '',
              })}
              id="vendor_emailAddress"
              name="emailAddress"
              onBlur={handleBlur}
              onChange={handleChange}
              tabIndex="0"
              type="text"
              value={vendor.emailAddress}
            />
            {errors.emailAddress && (
              <p className={cls.errorMsg}>{errors.emailAddress}</p>
            )}
          </UikFormInputGroup>

          <div className={cls.trip_container}>
            {contactRender()}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 300,
              }}
            />
          </div>
        </form>
        <div className={cls.add_trip_btn_wrapper}>
          <Button
            data-focusable="true"
            havePrefixIcon
            onClick={handleAddMore}
            stateClass="Link"
            tabIndex="0"
          >
            Add Contact
          </Button>
        </div>
        <div>
          <h3 className={cls.trip_type_header} />
        </div>
      </div>
    </Drawer>
  )
}

export default withContext(AddVendor)
;((_data) => {})([React])
