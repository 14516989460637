import React from 'react'
const IconLocation = () => (
  <svg
    fill="none"
    height="15"
    viewBox="0 0 14 15"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99935 1.08301C9.73777 1.08301 11.9577 3.30292 11.9577 6.04134C11.9577 7.78292 11.154 9.54405 9.81031 11.2625C9.33343 11.8724 8.8148 12.4411 8.28347 12.9591C8.2397 13.0017 8.19666 13.0433 8.15439 13.0837L7.91059 13.3124L7.68804 13.5129L7.48926 13.6846L7.36786 13.7852C7.15483 13.9588 6.84953 13.9602 6.63494 13.7885L6.50801 13.6834L6.31014 13.5125L6.088 13.3123L5.8443 13.0837L5.71522 12.9591C5.1839 12.4411 4.66527 11.8724 4.18839 11.2625C2.84473 9.54405 2.04102 7.78292 2.04102 6.04134C2.04102 3.30292 4.26093 1.08301 6.99935 1.08301ZM6.99935 2.24967C4.90526 2.24967 3.20768 3.94725 3.20768 6.04134C3.20768 7.4741 3.90931 9.01152 5.10746 10.5439C5.54982 11.1097 6.03373 11.6402 6.52961 12.1237L6.76453 12.3484C6.84193 12.421 6.91944 12.4937 6.99935 12.5636L7.23414 12.3484L7.46909 12.1237C7.96497 11.6402 8.44887 11.1097 8.89124 10.5439C10.0894 9.01152 10.791 7.4741 10.791 6.04134C10.791 3.94725 9.09344 2.24967 6.99935 2.24967ZM6.99935 3.70801C8.28801 3.70801 9.33268 4.75268 9.33268 6.04134C9.33268 7.33 8.28801 8.37467 6.99935 8.37467C5.71069 8.37467 4.66602 7.33 4.66602 6.04134C4.66602 4.75268 5.71069 3.70801 6.99935 3.70801ZM6.99935 4.87467C6.35502 4.87467 5.83268 5.39701 5.83268 6.04134C5.83268 6.68567 6.35502 7.20801 6.99935 7.20801C7.64368 7.20801 8.16602 6.68567 8.16602 6.04134C8.16602 5.39701 7.64368 4.87467 6.99935 4.87467Z"
      fill="#50565E"
    />
  </svg>
)
export default IconLocation
;((_data) => {})([React])
