import classnames from 'classnames'
import { useAtom } from 'jotai'
import React from 'react'
import { currentStepOfFlow } from '../../atoms/atoms'
import { UikButton } from '../../components'
import cls from './common.scss'
import { H6 } from './typography'

export const ModalDoneUser = () => {
  const [, setStep] = useAtom(currentStepOfFlow)
  const handleStart = () => {
    setStep((pre) => pre + 1)
  }
  return (
    <div className=" fixed bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[628px] h-[197px] p-6">
      <H6>Congratulations!</H6>
      <div className="text-sm text-center mt-[22px]">
        Your team is added! They will receive a welcome email message from
        driverDOC to complete their account setup.
      </div>
      <div className="float-right mt-6">
        <UikButton
          className={classnames(cls.button__done, 'p-0')}
          onClick={handleStart}
        >
          Done
        </UikButton>
      </div>
    </div>
  )
}
;((_data) => {})([React])
