import {
  UikButton,
  UikFormInputGroup,
  UikHeadline,
  UikHeadlineDesc,
  UikInput,
} from '@components'
import { usePublicIp } from '@hooks'
import { checkValidEmail } from '@utils'
import classnames from 'classnames'
import React, { useState } from 'react'

import axios from 'axios'
import { useCookies } from 'react-cookie'
import apiRoute from '../../../constants/apiRoute'
import logo from '../../../driverDocAssets/DriverDocMainLogo.png'
import stroke from '../../../driverDocAssets/stroke.png'
import { withContext } from '../../../hoc'
import Footer from '../../@components/Footer'
import { Caption, CaptionBold, H6, Small } from '../../@components/typography'
import cls from './forget-password.scss'
// forget password page
function ForgetPassword({ ...props }) {
  const [toggle, setToggle] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const emailResult = checkValidEmail(email.trim())
  const [ipPublic] = usePublicIp()
  const [cookies, setCookie] = useCookies([
    'token',
    'user_name',
    'company_name',
    'rs',
  ])
  const { token } = cookies

  const handleChange = (e) => {
    setEmail(e.target.value)
    if (email.length !== '') {
      setError('')
      setEmail(e.target.value)
    }
  }
  const handleDisabled = () => {
    if (error || email === '') {
      return true
    }
    return false
  }
  const handleReset = () => {
    const _checkEmail = document.getElementById('email')
    const bodyRequest = {
      toEmail: email.trim(),
      browserVersion: navigator.userAgent,
      ipPublic,
    }
    if (email === '' || email === undefined) {
      _checkEmail.focus()
      setError("E-mail can't be blank")
      return false
    }
    if (!emailResult) {
      setError('Invalid Email Address')
      return false
    }
    try {
      setLoading(true)
      axios
        .post(`${apiRoute.Reset}`, bodyRequest)
        .then(() => {
          setToggle(false)
          setLoading(false)
          console.log(' aaaa2 ')
          setCookie('token', '', {
            path: '/',
          })
        })
        .catch((err) => {
          if (err.response.status === 404) {
            _checkEmail.focus()
            setError(`${err.response.data.messages}`)
            setLoading(false)
            return false
          }
        })
    } catch (err) {}
    setLoading(true)
  }
  const BackToSignIn = () => {
    props.history.push('/')
    setCookie('token', '', {
      path: '/',
    })
    setLoading(true)
    setTimeout(() => {
      setToggle(true)
      setLoading(false)
    }, 1000)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleReset()
    }
  }

  return (
    <div className={classnames(cls.Forget, cls.p)}>
      <div className={classnames(cls.img_left, cls.c, cls.c1)}>
        <img alt="logo" className={cls.img_logo} src={logo} />
      </div>
      <div className={classnames(cls.forget_wrapper, cls.c, cls.c2)}>
        <div className={cls.content_forget}>
          <div className={cls.form_right}>
            <UikHeadline className={cls.headline}>
              <H6>Reset Password</H6>{' '}
            </UikHeadline>
            {toggle && (
              <UikFormInputGroup className={cls.form_forget}>
                {token ? (
                  <CaptionBold className={cls.message_expired}>
                    Your option to reset password has expired
                  </CaptionBold>
                ) : (
                  ''
                )}
                {error && (
                  <CaptionBold className={cls.message_expired}>
                    {error}
                  </CaptionBold>
                )}
                <Caption className={cls.message_reset_headline}>
                  Enter your email address below and we'll send you instructions
                  on how to reset your password.
                </Caption>
                <Small className={cls.label} htmlFor="input">
                  Email Address
                </Small>
                <UikInput
                  autoComplete="off"
                  className={cls.ip_email}
                  id="email"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  type="text"
                  value={email}
                />

                <UikButton
                  className={
                    handleDisabled() ? cls.btn_reset_disabled : cls.btn_reset
                  }
                  disabled={handleDisabled()}
                  isLoading={isLoading}
                  onClick={handleReset}
                >
                  <span className={cls.value}>Submit</span>
                </UikButton>
              </UikFormInputGroup>
            )}
            {!toggle && (
              <React.Fragment>
                <UikHeadlineDesc className={cls.headline_desc}>
                  Keep an eye out on your inbox—we just emailed you a link to
                  reset your password. If you don't receive anything, first
                  check your spam folder and then try again.
                </UikHeadlineDesc>
                <div className={cls.form_back}>
                  {' '}
                  <UikButton
                    className={cls.btn_reset}
                    isLoading={isLoading}
                    onClick={BackToSignIn}
                    style={{ background: '#023F62', width: 144 }}
                  >
                    <span
                      className={cls.value}
                      style={{
                        color: '#FFFFFF',
                        display: 'inline-flex',
                      }}
                    >
                      <img className={cls.icon_back} src={stroke} /> Back to log
                      in
                    </span>
                  </UikButton>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <div className={classnames(cls.footer_reset, cls.c, cls.c3)}>
        <Footer />
      </div>
    </div>
  )
}

export default withContext(ForgetPassword)
;((_data) => {})([React])
