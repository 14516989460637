import React from 'react'

const Step1 = () => (
  <svg
    fill="none"
    height="33"
    viewBox="0 0 32 33"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="17" r="14.5" stroke="#33BEAD" strokeWidth="3" />
    <path
      d="M11.959 22.965H15.182V13.692C15.182 13.3327 15.193 12.955 15.215 12.559L12.927 14.473C12.8317 14.5537 12.7327 14.6087 12.63 14.638C12.5347 14.6673 12.4393 14.682 12.344 14.682C12.1973 14.682 12.0617 14.6527 11.937 14.594C11.8197 14.528 11.7317 14.4547 11.673 14.374L10.815 13.197L15.666 9.072H17.899V22.965H20.759V25H11.959V22.965Z"
      fill="#33BEAD"
    />
  </svg>
)

export default Step1
;((_data) => {})([React])
