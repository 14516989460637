import clsx from 'clsx'
import { upperFirst } from 'lodash'
import React from 'react'

export const StatusButton = ({ status, text, classNameWidth = 'w-[80px]' }) => {
  const renderStatus = {
    PENDING: 'text-LightBlue bg-LightBlue border-LightBlue bg-custom-gradient',
    ACTIVE: 'text-Green bg-Green border-Green bg-custom-gradient',
    INCOMPLETE: 'text-Red bg-Red border-Red bg-custom-gradient',
    INACTIVE: 'text-Red bg-Red border-Red bg-custom-gradient',
    COMPLETE: 'text-Black bg-Black border-Black bg-custom-gradient',
    DRAFT: 'text-Purple bg-Purple border-Purple bg-custom-gradient',
    LOCKED: 'text-Orange bg-Orange border-Orange bg-custom-gradient',
  }

  return (
    <React.Fragment>
      <button
        className={clsx(
          'border text-[12px] leading-[150%] h-[24px] rounded-[4px] flex items-center justify-center cursor-auto min-w-max px-[8px] py-[3px] ',
          classNameWidth,
          renderStatus[status.toUpperCase()]
        )}
      >
        {text || upperFirst(status)}
      </button>
    </React.Fragment>
  )
}
;((_data) => {})([React])
