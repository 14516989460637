import { Button } from '@appComponent/button'
import { ArrowSelect, DeleteSearch2 } from '@appComponent/icon'
import { Input2, Textarea2 } from '@appComponent/input'
import PdfViewerComponent from '@appComponent/pdf/PdfViewerComponent'
import { Small } from '@appComponent/typography'
import usePrevious from '@appComponent/usePrevious'
import apiRoute from '@constants/apiRoute'
import { customAxios, getErrorMessage, getStr, getTimeOffset } from '@helper'
import { useGroups } from '@hooks'
import { Drawer, Select } from 'antd'
import classnames from 'classnames'
import dayjs from 'dayjs'
import _, { identity, isFunction, size } from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { getDocumentTypes } from '../../../../service'
import cls from './documents.scss'
import { InputSearchLoadNumberWhenUploadDoc } from '@@/App/@components/input'
import { ModalConfirmArchiveOrininalDocs } from './ModalConfirmArchiveOrininalDocs'
import ReactModal from '../../components/MyModal/ReactModal'

const { Option } = Select

const DrawerMergeDocument = identity(function DrawerMergeDocument({
  setCurrentId,
  listDocForMerge,
  setListDocForMerge,
  token,
  context,
  set_time_request,
  location,
  setListDocumentSelected,
}) {
  const { listIdCheckedGroups, groups } = useGroups()
  const [isChosen, setIsChosen] = useState(false)
  const [idLoad, setIdLoad] = useState(null)
  const [idTrip, setIdTrip] = useState(null)
  const [documentTypeList, setDocumentTypeList] = useState([])
  const [idDocumentTypeSelected, setIdDocumentTypeSelected] =
    useState(undefined)
  const [documentNumber, setDocumentNumber] = useState('')

  const [documentFile, setDocumentFile] = useState(undefined)
  const [value, setValue] = useState('')
  const [note, setNote] = useState('')

  const [savingOrDispatching, setSavingOrDispatching] = useState(false)

  const [confirmComplete, setConfirmComplete] = useState(undefined)
  const [disableBtnsModalConfirm, setDisableBtnsModalConfirm] = useState(false)
  const [showOutOfData, setShowOutOfData] = useState(false)
  const [idGroup, setIdGroup] = useState(null)

  const [isInEditorMode, setIsInEditorMode] = useState(false)
  const [isInCropMode, setIsInCropMode] = useState(false)
  const [isProcessingDocument, setIsProcessingDocument] = useState(false)
  const [merging, setMerging] = useState(!_.isEmpty(listDocForMerge))
  const [
    isOpenModalConfirmArchiveOriginalDocs,
    setIsOpenModalConfirmArchiveOriginalDocs,
  ] = useState(false)

  const isArchiveOriginalDocsRef = useRef(null)

  const isLoadDocument = useMemo(() => {
    // load or non-load document
    if (_.isEmpty(documentTypeList) || !idDocumentTypeSelected) {
      return true
    }
    let find = documentTypeList.find((o) => {
      return o.idDocumentType === idDocumentTypeSelected
    })
    if (find) {
      return find.loadDocument
    } else {
      return false
    }
  }, [idDocumentTypeSelected, documentTypeList])

  const onClose = () => {
    // resetFields()
    setListDocForMerge([])
  }

  const resetFields = () => {
    setIdDocumentTypeSelected(undefined)
    setValue('')
    setIdLoad(null)
    setIdTrip(null)
    setDocumentNumber('')
    setDocumentFile(undefined)
    setIsChosen(false)
    setIdGroup(null)
    // reset scroll to top
    const antDrawerBody = Array.from(
      document.querySelectorAll('.wr_drawer_upload_doc .ant-drawer-body')
    )[0]
    if (antDrawerBody) {
      antDrawerBody.scrollTop = 0
    }
  }

  const handleCallAPIMerge = async () => {
    setSavingOrDispatching(true)

    const checkCanComplete = async (idLoad) => {
      try {
        const resp = await customAxios(token).get(
          `${
            apiRoute.loads.CAN_COMPLETE
          }?idLoads=${idLoad}&groups=${listIdCheckedGroups.join(',')}`
        )
        if (!resp.data.success) {
          return
        }
        if (_.get(resp, 'data.data[0].canComplete') === true) {
          setConfirmComplete(true)
        } else {
          uploadDoc(-1)
        }
      } catch (err) {
        uploadDoc(-1)
      } finally {
        isArchiveOriginalDocsRef.current = null
      }
    }
    if (idLoad) {
      isArchiveOriginalDocsRef.current = null
      checkCanComplete(idLoad)
    } else {
      uploadDoc(-1)
    }
  }

  const uploadDoc = async (confirm) => {
    try {
      setDisableBtnsModalConfirm(true)
      setSavingOrDispatching(true)
      const formData = new FormData()

      const loc = {
        latitude: location.lat,
        longitude: location.long,
      }

      formData.append(
        'documentFile',
        documentFile,
        `DriverDocsPDF${dayjs().format('YYYY.MM.DD_HH.mm.ss')}.pdf`
      )
      formData.append('note', String(note))
      formData.append('mergeDocumentsList', listIdSourceDocForMerge.join(','))

      if (isLoadDocument) {
        formData.append('documentNumber', String(documentNumber))
        // handle load number filed
        if (!!idLoad) {
          formData.append('idLoad', String(idLoad))
        } else {
          formData.append('loadNumber', value ? String(value) : '')
          if (size(groups) === 0) {
            //
          } else if (size(groups) === 1) {
            formData.append('idGroup', String(groups[0].idGroup))
          } else {
            formData.append('idGroup', String(idGroup))
          }

          // both is empty => loadNumber: ""
        }
        if (!!idTrip) {
          formData.append('idTrip', String(idTrip))
        }
      } else {
        if (size(groups) === 0) {
          //
        } else if (size(groups) === 1) {
          formData.append('idGroup', String(groups[0].idGroup))
        } else {
          formData.append('idGroup', String(idGroup))
        }
      }

      formData.append('idDocumentType', String(idDocumentTypeSelected))
      formData.append('location', JSON.stringify(loc))
      formData.append('timeOffset', getTimeOffset())

      if (confirm !== -1) {
        formData.append('isConfirm', confirm)
      }

      // return

      const resp = await customAxios(token).post(
        `${apiRoute.documents.MERGE_DOCUMENT}?groups=${listIdCheckedGroups.join(
          ','
        )}&isArchiveOriginalDoc=${
          isArchiveOriginalDocsRef.current === true ? 'true' : 'false'
        }`,
        formData
      )
      if (!resp.data.success) {
        context.message.error('Unable to merge documents. Try again.')
        return
      }
      const outOfData = getStr(resp, 'data.data.loadCompleteErrorMessage')

      if (!!outOfData) {
        setShowOutOfData(true)
      } else {
        setListDocForMerge([])
      }
      context.message.success('Documents merged successfully')

      set_time_request(moment().format('YYYY-MM-DDTHH:mm:ss'))

      resetFields()
      setCurrentId(null)
      setListDocumentSelected([])
    } catch (err) {
      context.message.error(getErrorMessage(err))
    } finally {
      setConfirmComplete(false)
      isArchiveOriginalDocsRef.current = null
      setTimeout(() => {
        setSavingOrDispatching(false)
        setDisableBtnsModalConfirm(false)
      }, 3888)
    }
  }

  useEffect(() => {
    getDocumentTypes(token, setDocumentTypeList)
    setTimeout(() => {
      const el = document.querySelectorAll(
        '.wr_drawer_upload_doc .ant-drawer-body input'
      )[0]
      if (el) {
        el.focus()
      }
    }, 200)
  }, [])

  useEffect(() => {
    if (confirmComplete === true) {
      setTimeout(() => {
        let el = document.getElementById('upload_confirm_yes')
        if (el) {
          el.focus()
        }
      }, 200)
    }
  }, [confirmComplete])

  const isShowGroupField = useMemo(() => {
    if (_.isEmpty(documentTypeList)) {
      return false
    }
    const listIdNonLoadType = documentTypeList
      .filter((o) => o.loadDocument === false)
      .map((o) => o.idDocumentType)
    if (idLoad && !listIdNonLoadType.includes(idDocumentTypeSelected)) {
      return false
    }
    if (size(groups) <= 1) {
      return false
    }
    return true
  }, [idLoad, idDocumentTypeSelected, groups, documentTypeList])

  const isDisable = useMemo(() => {
    if (documentFile === undefined) {
      return true
    }
    if (isInEditorMode || isInCropMode) {
      return true
    }

    if (!idDocumentTypeSelected || !documentFile) {
      return true
    }

    if (isShowGroupField && !idGroup) {
      return true
    }
    return false
  }, [
    idDocumentTypeSelected,
    documentFile,
    idGroup,
    isShowGroupField,
    isInEditorMode,
    isInCropMode,
  ])

  const showMergeDoc = useMemo(() => {
    return _.size(listDocForMerge) >= 2
  }, [listDocForMerge])

  const listUrlForMerge = useMemo(() => {
    return listDocForMerge.map((o) => o.url).filter((o, i) => i !== 0)
  }, [listDocForMerge])
  const listIdSourceDocForMerge = useMemo(() => {
    return listDocForMerge.map((o) => o.idDocument)
  }, [listDocForMerge])

  const showMergeDocPrev = usePrevious(showMergeDoc)

  const renderSelectGroup = () => {
    if (!isShowGroupField) {
      return null
    }
    return (
      <div className={cls.form_group}>
        <label htmlFor="inputupload_document_file">
          <Small>Group</Small>
        </label>
        <Select
          clearIcon={<DeleteSearch2 />}
          id="inputupload_document_type"
          onChange={(value) => setIdGroup(value)}
          style={{ width: '100%' }}
          suffixIcon={<ArrowSelect />}
          value={idGroup ? Number(idGroup) : undefined}
        >
          {groups.map((group) => (
            <Option key={group.idGroup} value={group.idGroup}>
              {group.name}
            </Option>
          ))}
        </Select>
      </div>
    )
  }

  const getWidthDrawer = () => {
    if ('undefined' !== typeof window) {
      return window.innerWidth - 32
    } else {
      return 368
    }
  }

  useEffect(() => {
    console.log('aaa isDisable', isDisable)
  }, [isDisable])

  useEffect(() => {
    console.log('aaa documentFile', documentFile)
  }, [documentFile])

  useEffect(() => {
    if (isOpenModalConfirmArchiveOriginalDocs === false) {
      isArchiveOriginalDocsRef.current = null
    }
  }, [isOpenModalConfirmArchiveOriginalDocs])

  return (
    <Drawer
      bodyStyle={{ paddingBottom: 0 }}
      className={classnames(cls.wr_drawer_upload_doc, 'wr_drawer_upload_doc', {
        widedrawer: getWidthDrawer() !== 368,
      })}
      destroyOnClose
      footer={
        <div
          className={`wr_footer_add_load ${
            savingOrDispatching ? 'disabled1' : ''
          }`}
        >
          <Button
            onClick={savingOrDispatching ? null : onClose}
            stateClass={savingOrDispatching ? 'Disabled' : 'Secondary'}
            width={74}
          >
            Cancel
          </Button>
          <div className="wr_save_dispatch">
            {isProcessingDocument && (
              <div className="font-bold pr-4 flex justify-center items-center ">
                Processing, please wait ...
              </div>
            )}
            <Button
              onClick={() => {
                if (
                  isDisable ||
                  savingOrDispatching ||
                  merging ||
                  isProcessingDocument
                ) {
                  return false
                }
                if (isArchiveOriginalDocsRef.current === null) {
                  setIsOpenModalConfirmArchiveOriginalDocs(true)
                } else {
                  handleCallAPIMerge()
                }
              }}
              stateClass={
                isDisable ||
                savingOrDispatching ||
                merging ||
                isProcessingDocument
                  ? 'Disabled'
                  : 'Primary'
              }
              width={61}
            >
              Save
            </Button>
          </div>
        </div>
      }
      maskClosable={false}
      onClose={onClose}
      title="Merge Documents"
      visible={true}
      width={getWidthDrawer()}
    >
      <div className={cls.wr_body_drawer}>
        {isOpenModalConfirmArchiveOriginalDocs && (
          <ModalConfirmArchiveOrininalDocs
            loading={savingOrDispatching}
            open={true}
            onClose={() => {}}
            isArchiveOriginalDocsRef={isArchiveOriginalDocsRef}
            callback={handleCallAPIMerge}
          />
        )}

        <div className={cls.wr_body_drawer_left}>
          {confirmComplete && (
            <ReactModal
              headerText={'Load Complete'}
              Content={'Are you done with this Load?'}
              open={true}
              onClose={() => {}}
              ButtonContent={
                <div className="flex justify-end items-center gap-2">
                  <Button
                    onClick={() => {
                      uploadDoc(false)
                    }}
                    stateClass={
                      disableBtnsModalConfirm ? 'Disabled' : 'Secondary'
                    }
                    width={74}
                  >
                    No
                  </Button>

                  <Button
                    id="edit_confirm_yes"
                    onClick={() => {
                      // setConfirmComplete(false)
                      // return
                      uploadDoc(true)
                    }}
                    stateClass={
                      disableBtnsModalConfirm ? 'Disabled' : 'Primary'
                    }
                    width={74}
                  >
                    Yes
                  </Button>
                </div>
              }
            />
          )}
          {showOutOfData && (
            <ReactModal
              headerText={''}
              Content={'Out of Data'}
              open={true}
              onClose={() => {}}
              ButtonContent={
                <div className="w-full flex justify-center items-center ">
                  <Button
                    onClick={() => {
                      setShowOutOfData(false)
                      setListDocForMerge([])
                    }}
                    stateClass="Primary"
                    width={74}
                  >
                    Ok
                  </Button>
                </div>
              }
            />
          )}

          <div className={cls.form_group}>
            <label htmlFor="inputupload_document_type">
              <Small>Document Type</Small>
            </label>
            <Select
              clearIcon={<DeleteSearch2 />}
              id="inputupload_document_type"
              onChange={(value) => setIdDocumentTypeSelected(value)}
              style={{ width: '100%' }}
              suffixIcon={<ArrowSelect />}
              value={
                idDocumentTypeSelected
                  ? Number(idDocumentTypeSelected)
                  : undefined
              }
            >
              {documentTypeList.map((doc_type) => (
                <Option
                  key={doc_type.idDocumentType}
                  value={doc_type.idDocumentType}
                >
                  {doc_type.name}
                </Option>
              ))}
            </Select>
          </div>

          {isLoadDocument && (
            <div className={cls.form_group}>
              <label htmlFor="inputupload_doc_number">
                <Small>Document# (Optional)</Small>
              </label>
              <Input2
                id="inputupload_doc_number"
                onChange={(e) => {
                  setDocumentNumber(e.target.value)
                }}
                value={documentNumber}
              />
            </div>
          )}

          {/*TODO*/}
          {isLoadDocument && (
            // load or non-load document
            <div className={cls.form_group}>
              <label htmlFor="inputupload_load_number">
                <Small>Load# (Optional)</Small>
              </label>
              <InputSearchLoadNumberWhenUploadDoc
                idLoad={idLoad}
                idTrip={idTrip}
                isChosen={isChosen}
                setIdLoad={setIdLoad}
                setIdTrip={setIdTrip}
                setIsChosen={setIsChosen}
                setValue={setValue}
                showUploadDoc={showMergeDoc}
                showUploadDocPrev={showMergeDocPrev}
                value={value}
                token={token}
                listIdCheckedGroups={listIdCheckedGroups}
              />
            </div>
          )}

          {renderSelectGroup()}
          <div className={cls.form_group}>
            <label htmlFor="inputupload_document_note">
              <Small>Note (Optional)</Small>
            </label>
            <Textarea2
              id="inputmerge_document_note"
              onChange={(e) => setNote(e.target.value)}
              value={note}
            />
          </div>
        </div>
        <div className={cls.wr_body_drawer_right}>
          <PdfViewerComponent
            setIsProcessingDocument={setIsProcessingDocument}
            isProcessingDocument={isProcessingDocument}
            actionFns={{}}
            document={listDocForMerge[0].url}
            hideSplitIcon={true}
            id="pspdfkit_merge_wr"
            listUrlForMerge={listUrlForMerge}
            merging={merging}
            rightSideButtonsType="edit"
            setDocumentFileMergerFn={async ({ instance, fn }) => {
              try {
                console.log(' drawer merge setDocumentFile begin')
                setDocumentFile(undefined)
                const arrayBuffer = await instance.exportPDF()
                const blob = new Blob([arrayBuffer], {
                  type: 'application/pdf',
                })
                setDocumentFile(blob)
                console.log(' drawer merge setDocumentFile(blob)', blob)
                if (isFunction(fn)) {
                  fn()
                }
              } catch (err) {
                //
              }
            }}
            setIsInCropMode={setIsInCropMode}
            setIsInEditorMode={setIsInEditorMode}
            setMerging={setMerging}
          />
        </div>
      </div>
    </Drawer>
  )
})

export default DrawerMergeDocument
;((_data) => {})([React])
