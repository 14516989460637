import { StatusButton } from '@appComponent/StatusButton'
import { IconTrackingFailed } from '@appComponent/icon'
import { Body, Caption, Small } from '@appComponent/typography'
import {
  checkAppointmentTime,
  checkNumber,
  displayDateFormat,
  displayTimeFormat,
  formatDetentionTimeLong,
  formatReturnValue,
  getDateTime,
  getStr,
  getTripTypeText,
  renderSingleLink,
  splitDate,
  splitTime,
} from '@helper'
import { Tooltip } from 'antd'
import classnames from 'classnames'
import moment from 'moment-timezone'
import React from 'react'
import { formatPhoneNumber } from '../../../../../helper'
import IconClose from '../../../../svg/IconClose'
import IconLocation from '../../../../svg/IconLocation'
import IconTime from '../../../../svg/IconTime'

function isInt(n) {
  return n % 1 === 0
}

const StopCard = ({ o, handleCloseDetailTripView }) => {
  if (!o) return null

  const renderETA = () => {
    if (o.trackingFailed === true) {
      return null
    }
    if (o.arrival_estimate && o.arrival_eta && !o.realtime_arrival) {
      return (
        <Stoprow label="ETA">
          <div className="flex gap-x-[8px] items-center ">
            <span className={`${o.eta > 0 ? 'text-Red' : 'text-Green'}`}>
              {getDateTime(o.arrival_eta)}
            </span>
            <StatusButton
              classNameWidth=""
              status={o.eta > 0 ? 'inactive' : 'active'}
              text={(function () {
                return `${
                  o.arrival_estimate > 0 ? '+' : '-'
                } ${formatDetentionTimeLong(o.arrival_estimate / 60)}`
              })()}
            />
          </div>
        </Stoprow>
      )
    }
    return null
  }

  const _renderAppointmentTime = (o) => {
    const earliest_time = getDateTime(o.earliest_time)
    const latest_time = getDateTime(o.latest_time)

    if (checkAppointmentTime(o.earliest_time, o.latest_time) === 'window') {
      if (splitDate(o.latest_time) === splitDate(o.earliest_time)) {
        return (
          <React.Fragment>
            {earliest_time} to {splitTime(o.latest_time)}
          </React.Fragment>
        )
      }
      return (
        <React.Fragment>
          {earliest_time} to {latest_time}
        </React.Fragment>
      )
    }
    return <React.Fragment>{earliest_time}</React.Fragment>
  }

  const renderLKL = () => {
    if (!o.LKL) return null
    return <Stoprow label="LKL">{getStr(o, 'LKL')}</Stoprow>
  }

  const renderArrivalTime = () => {
    if (!o.realtime_arrival) {
      return null
    }
    return (
      <React.Fragment>
        <Stoprow label="Arrival Time">
          {/* TODO */}
          <span
            className={`${
              checkNumber(o.lateArrival) ? 'dangercolor' : 'safecolor'
            }`}
          >
            {getDateTime(o.realtime_arrival)}
          </span>
        </Stoprow>
        {/* TODO on time */}
        {!!o.lateArrival && (
          <Stoprow label="Late By">
            <span>{formatDetentionTimeLong(o.lateArrival / 60)}</span>
          </Stoprow>
        )}

        {!!o.earlyArrival && (
          <Stoprow label="Early By">
            <span>{formatDetentionTimeLong(o.earlyArrival / 60)}</span>
          </Stoprow>
        )}
      </React.Fragment>
    )
  }

  const renderDrivingSpeed = () => {
    if (!checkNumber(o.drivingSpeed) || o.trackingFailed === true) {
      return null
    }
    let speed = o.drivingSpeed
    if (speed < 10) {
      speed = 0
    } else if (!isInt(speed)) {
      speed = speed.toFixed(2)
    }
    return <Stoprow label="Driving Speed">{speed} MPH</Stoprow>
  }

  const renderDetentionTime = () => {
    if (!o.wait_time) return null
    return (
      <Stoprow label="Detention Time">
        <span
          className={classnames({
            dangercolor: o.wait_time >= 3600,
          })}
        >
          {formatDetentionTimeLong(o.wait_time / 60)}
        </span>
      </Stoprow>
    )
  }

  const renderAll = () => (
    // arrival_status true thay bang realtime_arrival khong bang null

    <React.Fragment>
      {renderETA()}
      {renderArrivalTime()}
      {renderDetentionTime()}
      {renderLKL()}
      {renderDrivingSpeed()}
    </React.Fragment>
  )

  return (
    <div className="wr_trip_detail">
      <div className="inner">
        <div className="close_trip_detail" onClick={handleCloseDetailTripView}>
          <IconClose />
        </div>

        <div className="wr_name_phone_driver">
          <span className="name_driver fs20 lh30">
            {getStr(o, 'full_name')}
          </span>
          <span className="phone_driver fs14 lh21 subcolor">
            {formatPhoneNumber(o.driver_country_code, o.driver_phone_number)}
          </span>
          {o.trackingFailed === true && <ToolTipTrackingFailed />}
        </div>
        <div className="wr_load_truck_trailer">
          <div className="load">
            <div className="label">Load#</div>

            <div className="value breakword">
              {renderSingleLink(
                '/page/loads?textload=',
                formatReturnValue(o.load_number, 35),
                14
              )}
            </div>
          </div>
          <div className="truck">
            <div className="label">Truck#</div>
            <div className="value breakword">{getStr(o, 'truck', '', 30)}</div>
          </div>
          <div className="trailer">
            <div className="label">Trailer#</div>
            <div className="value breakword">
              {getStr(o, 'trailer', '', 30)}
            </div>
          </div>
        </div>
        <div className="wr_stop_info">
          <div className="wr_title_last_update breakword">
            <Body>
              Stop #{o.trip_number}
              {getTripTypeText(o.trip_type)
                ? ` - ${getTripTypeText(o.trip_type)}`
                : ''}
            </Body>
            {!!o.latest_time_request && (
              <Small>
                Last Update:{' '}
                {moment(o.latest_time_request).format(
                  `${displayDateFormat} - ${displayTimeFormat}`
                )}
              </Small>
            )}
          </div>
          <div className="wr_location mb16">
            <div className="wr_icon">
              <IconLocation />
            </div>

            <div className="wr_detail">
              <Caption block className="breakword">
                {getStr(o, 'trip_name', '', 60) ||
                  getStr(o, 'stop_name', '', 60)}
              </Caption>
              <Small className="breakword">
                {getStr(o, 'location_address')}
              </Small>
            </div>
          </div>
          <div className="wr_time">
            <div className="wr_icon">
              <IconTime />
            </div>
            <div className="wr_detail">
              {/* Appointment */}
              <Stoprow label="Appointment">{_renderAppointmentTime(o)}</Stoprow>

              <React.Fragment>{renderAll()}</React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StopCard

const Stoprow = ({ label, children }) => (
  <div className="rowstop">
    <div className="row_label">{label}</div>
    <div className="row_value">{children}</div>
  </div>
)

const ToolTipTrackingFailed = () => {
  return (
    <Tooltip placement="right" title="Tracking Failed">
      <div className="tkf">
        <IconTrackingFailed />
      </div>
    </Tooltip>
  )
}
;((_data) => {})([React])
